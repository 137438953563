import React, { useEffect, useState } from 'react'
import { Navbar } from '../../Components/Navbar'
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from "../../redux/actionTypes";
import AddIcon from '@mui/icons-material/Add';

//! MUI
import Card from '@mui/material/Card';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography, Select, FormControl, MenuItem, InputLabel, Checkbox, IconButton, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { getPreset, savePreset, creatingProject, getTB } from '../../services/api/StandAlone/preset';
import swal from 'sweetalert';
import "./presetStyle.css";
import LoaderTwo from '../../Components/Common/Loader/LoaderTwo';
import SelectSheetType from '../../Components/Preset/SelectSheetType';
import SingleFIleFormat from '../../Components/Preset/SingleFIleFormat';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const Preset = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedPresets = useSelector((initialState) => initialState.PresetReducer.selectedPresets);
    const createProjectData = useSelector((initialState) => initialState.PresetReducer.createProjectData);
    const [presetOpt, setPresetOpt] = useState({ preset: [], coa: [], upload_tb_options: [], uploadTbId: null, loading: false });
    const [open, setOpen] = useState(false);
    const [year, setYear] = useState({ cy_value: '', py_coa: '', cy_coa: '', py_value: '' });
    const [singleFormat, setsingleFormat] = useState(false);
    const [draggedObject, setDraggedObject] = useState(null);

    const [coaDropData, setCoaDropData] = useState({
        key: '',
        name: "",
        id: '',
        years: [],
        cy_coa: false,
        cy_checked: false,
        py_key: '',
        py_name: "",
        py_id: '',
        py_years: [],
        py_coa: false,
        py_checked: false,
    });
    const [dropValue, setDropValue] = useState({
        key: '',
        name: "",
        id: '',
        years: [],
        cy_value: false,
        cy_checked: false,
        py_key: '',
        py_name: "",
        py_id: '',
        py_years: [],
        py_value: false,
        py_checked: false,
    });
    useEffect(() => {
        setPresetOpt({ ...presetOpt, loading: true });
        getPreset({ presetId: params?.presetId }).then(res => {
            setPresetOpt({
                ...presetOpt,
                coa: res.data?.coa_options,
                preset: res.data?.tb_options,
                upload_tb_options: res.data?.upload_tb_options,
                uploadTbId: res.data?.uploadTBId,
                loading: false
            })
        }).catch(err => { setPresetOpt({ ...presetOpt, loading: false }); swal("Error", err?.response?.data?.error || "Error happening at the time of fetching preset data.", "error") })
    }, []);

    const handleDrop = (e, KEY) => {
        e.preventDefault();
        if (!draggedObject) return;
        if (KEY === 'current') {
            if (draggedObject.key === 'chat_of_account') {
                setCoaDropData({
                    ...coaDropData,
                    cy_coa: true, cy_checked: false,
                    name: draggedObject?.label,
                    id: draggedObject?.value,
                    years: draggedObject?.year,
                    key: draggedObject?.key
                });
                setDropValue({ ...dropValue, cy_value: false });
            };
            if (draggedObject.key === 'imported_preset') {
                setCoaDropData({
                    ...coaDropData,
                    cy_coa: false,
                    cy_checked: false,
                    id: draggedObject?.value,
                    key: draggedObject?.key,
                    name: draggedObject?.label,
                    years: draggedObject?.year,
                });
            };
        } else {
            if (coaDropData.key === "chat_of_account") {
                if (coaDropData.name !== draggedObject?.label) {
                    toast.warning("The selected sheet is different from the current year's COA. Mapping will be based only on the current year's COA.")
                }
            }
            if (draggedObject.key === 'chat_of_account') {
                setCoaDropData({
                    ...coaDropData,
                    py_coa: true,
                    py_checked: false,
                    py_name: draggedObject?.label,
                    py_id: draggedObject?.value,
                    py_years: draggedObject?.year,
                    py_key: draggedObject?.key
                }
                );
                setDropValue({ ...dropValue, py_value: false });
            };
            if (draggedObject.key === 'imported_preset') {
                setCoaDropData({
                    ...coaDropData,
                    py_coa: false,
                    py_checked: false,
                    py_id: draggedObject?.value,
                    py_key: draggedObject?.key,
                    py_name: draggedObject?.label,
                    py_years: draggedObject?.year,
                });
            };

            if (draggedObject.key === 'uploaded_tb') {
                setCoaDropData({
                    ...coaDropData,
                    py_coa: false,
                    py_checked: false,
                    py_id: draggedObject?.value,
                    py_key: draggedObject?.key,
                    py_name: draggedObject?.label,
                    py_years: draggedObject?.year,
                });
            };
        }
    };

    const handleDropValue = (e, KEY) => {
        e.preventDefault();
        if (!draggedObject) return;
        if (KEY === 'current') {
            if (coaDropData.key === 'chat_of_account' && ['imported_preset', 'uploaded_tb'].includes(draggedObject.key)) {
                setDropValue({
                    ...dropValue,
                    cy_checked: true,
                    cy_value: false,
                    name: draggedObject?.label,
                    id: draggedObject?.value,
                    years: draggedObject?.year,
                    key: draggedObject?.key
                });
            }
            if (coaDropData.key === 'imported_preset') {
                setDropValue({
                    ...dropValue,
                    cy_checked: false,
                    cy_value: false,
                    name: draggedObject?.label,
                    id: draggedObject?.value,
                    years: draggedObject?.year,
                    key: draggedObject?.key
                });
            }
        } else {
            if (coaDropData.py_key === 'chat_of_account' && ['imported_preset', 'uploaded_tb'].includes(draggedObject.key)) {
                setDropValue({
                    ...dropValue,
                    py_checked: true,
                    py_value: false,
                    py_name: draggedObject?.label,
                    py_id: draggedObject?.value,
                    py_years: draggedObject?.year,
                    py_key: draggedObject?.key
                });
            }
            if (coaDropData.py_key === 'imported_preset') {
                setDropValue({
                    ...dropValue,
                    py_checked: false,
                    py_value: false,
                    py_name: draggedObject?.label,
                    py_id: draggedObject?.value,
                    py_years: draggedObject?.year,
                    py_key: draggedObject?.key
                });
            }
        }
    }

    const HandleClear = (key_name) => {
        if (key_name === 'cy_coa') {
            setCoaDropData({ ...coaDropData, cy_coa: false, cy_checked: false, name: '', id: '', years: [], key: '' });
            setDropValue({ ...dropValue, cy_value: false })
            setYear(prev => ({ ...prev, cy_coa: "" }));
        }
        if (key_name === 'cy_value') {
            setDropValue({ ...coaDropData, cy_checked: false, cy_value: false, name: '', id: '', years: [], key: '' });
            setYear(prev => ({ ...prev, cy_value: "" }));
        }
        if (key_name === 'py_coa') {
            setCoaDropData({ ...coaDropData, py_checked: false, py_coa: false, py_name: '', py_id: '', py_years: [], py_key: '' });
            setDropValue({ ...dropValue, py_value: false })
            setYear(prev => ({ ...prev, py_coa: "" }));
        }
        if (key_name === 'py_value') {
            setDropValue({ ...coaDropData, py_checked: false, py_value: false, py_name: '', py_id: '', py_years: [], py_key: '' });
            setYear(prev => ({ ...prev, py_value: "" }));
        }
    };

    const HandleCreatePreset = () => {
        const data = {
            presetId: params?.presetId,
            uploadTbId: presetOpt.uploadTbId,

            // yearCp : year.cy_coa ? year.cy_coa : year.cy_value,
            yearPp: year.py_coa ? year.py_coa : year.py_value,

            // idCp: year.cy_coa ? coaDropData.id : year.cy_value ? dropValue.id : null ,
            idPp: year.py_coa ? coaDropData.py_id : year.py_value ? dropValue.py_id : null,
            // keyCp:  year.cy_coa ? coaDropData.key : year.cy_value ? dropValue.key : null ,
            keyPp: year.py_coa ? coaDropData.py_key : year.py_value ? dropValue.py_key : null,

            coaIdCp: coaDropData.key === "chat_of_account" ? coaDropData.id : dropValue.key === "chat_of_account" ? dropValue.id : null,
            coaIdPP: coaDropData.py_key === "chat_of_account" ? coaDropData.py_id : dropValue.py_key === "chat_of_account" ? dropValue.py_id : null,

            keys: {
                coaKeyCP: coaDropData.key,
                valueKeyCP: dropValue.key,

                coaKeyPP: coaDropData.py_key,
                valueKeyPP: dropValue.py_key,
            },
            ids: {
                coaIdCP: coaDropData.id,
                valueIdCP: dropValue.id,

                coaIdPP: coaDropData.py_id,
                valueIdPP: dropValue.py_id,
            },
            years: {
                coaYearCP: year.cy_coa,
                valueYearCP: year.cy_value,

                coaYearPP: year.py_coa,
                valueYearPP: year.py_value,
            }
        }

        savePreset(data).then(res => {
            if (res.data.success) {
                swal("Success", `Data saved successfully, Click Ok to redirect for mapping data.`, {
                    icon: "success",
                }).then(() => {
                    creatingProject({ presetId: params?.presetId, coaProjectId: res.data.coaProjectId }).then(res => {
                        dispatch({
                            type: actionTypes.SET_TB_ID,
                            payload: res.data.tb_id,
                        });

                        window.localStorage.setItem("tb_id", res.data.tb_id);
                        window.localStorage.setItem("project_id", res.data.projectId);

                        history.push(`/createProject/DataMapping/${res.data?.projectId}`);
                    }).catch(err => swal("Error", "Error happening creating project.", "error"))
                })
            }
        }).catch(err => {
            swal("Error", err?.response?.data?.error || "Error happening save preset mapping.", "error")
        });
    };

    const HandleYearChange = (e, KEY_NAME) => {
        if (KEY_NAME === 'COA-CY') {
            if (coaDropData.key === 'imported_preset') {
                setYear(prev => ({ ...prev, cy_coa: e.target.value }));
                setDropValue(prev => ({ ...prev, cy_checked: false, cy_value: true }));
                setCoaDropData(prev => ({ ...prev, cy_checked: true, cy_coa: false }));
            } else { setYear(prev => ({ ...prev, cy_coa: e.target.value })); }
        };
        if (KEY_NAME === 'VALUE-CY') {
            if (coaDropData.key === 'imported_preset') {
                setYear(prev => ({ ...prev, cy_value: e.target.value }));
                setCoaDropData(prev => ({ ...prev, cy_checked: false, cy_coa: true }));
                setDropValue(prev => ({ ...prev, cy_checked: true, cy_value: false }));
            } else { setYear(prev => ({ ...prev, cy_value: e.target.value })); }
        };

        if (KEY_NAME === 'COA-PY') {
            if (['imported_preset', 'uploaded_tb'].includes(coaDropData.py_key)) {
                setYear(prev => ({ ...prev, py_coa: e.target.value }));
                setDropValue(prev => ({ ...prev, py_checked: false, py_value: true }));
                setCoaDropData(prev => ({ ...prev, py_checked: true, py_coa: false }));
            }
            else { setYear(prev => ({ ...prev, py_coa: e.target.value })); }
        };
        if (KEY_NAME === 'VALUE-PY') {
            if (coaDropData.py_key === 'imported_preset') {
                setYear(prev => ({ ...prev, py_value: e.target.value }));
                setCoaDropData(prev => ({ ...prev, py_checked: false, py_coa: true }));
                setDropValue(prev => ({ ...prev, py_checked: true, py_value: false }));
            } else { setYear(prev => ({ ...prev, py_value: e.target.value })); }
        };
    };

    return (
        <>
            <Navbar text='Preset' />
            <div>
                <div className="px-5 w-100 py-3">
                    <div className="row justify-content-between">
                        <div className="col-md-8 col-sm-12 mb-3">
                            <button
                                className="d-flex custom-font-600 custom-common-theme-text-color border-0 bg-color-inherit"
                                onClick={() => {
                                    if (!createProjectData?.workspace_id) return toast.warning("Workspace id is missing.")
                                    history.push(`/createProject/QuestionnaireNew/${createProjectData.workspace_id}`)
                                }}
                            >
                                <ArrowBackIcon className="mr-1" /> Back
                            </button>
                        </div>
                        <div className="col-md-4 col-sm-12 d-flex " style={{ gap: "30px" }}>
                            <button className="bg-white border-color custom-common-theme-text-color custom-font-600 font-size-16 w-25 radius-6px" onClick={() => setOpen(true)}>Upload TB</button>
                            <button
                                className="bg-white w-75 d-flex justify-content-center align-items-center border-color custom-common-theme-text-color custom-font-600 font-size-16 radius-6px"
                                onClick={() => HandleCreatePreset()}
                            >
                                <AddIcon className='mr-1' />
                                Create financial statement
                            </button>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className="row">
                            <div class="col-sm-8 scroll-y-preset">
                                <Card className='bg-white custom-card radius-12px'>
                                    <div className='d-flex align-items-start bg-light-blue'>
                                        <h6 className='mt-2 p-2 ml-3 font-size-16 custom-font-600'>Current period</h6>
                                    </div>
                                    <div
                                        className='p-2 mt-1'
                                        onDragEnter={(e) => e.preventDefault()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => handleDrop(e, 'current')}
                                        onDragLeave={(e) => e.preventDefault()}
                                    >
                                        <h6 className='ml-3 font-size-14 custom-font-600'>Chart of accounts</h6>
                                        {
                                            coaDropData?.name &&
                                            <div className='d-flex ml-4'>
                                                <Typography className='text-secondary custom-font-500' >{coaDropData?.name}</Typography>
                                                <IconButton aria-label="Delete" className='ml-2' onClick={() => HandleClear('cy_coa')} > <ClearIcon /> </IconButton>
                                            </div>
                                        }
                                        <div className='d-flex align-items-center ml-3'>
                                            <Checkbox checked={coaDropData.cy_checked} disabled={coaDropData.cy_coa}  {...label} />
                                            <p className={`m-0 ${coaDropData.cy_coa ? "include-value-d" : "include-value"} font-sans`}>
                                                Include values
                                            </p>

                                            <div>
                                                <FormControl sx={{ m: 1, minWidth: 200 }} className='custom-bg-white-150' size="small">
                                                    <InputLabel className='text-secondary' id="demo-select-small-label">Select Year</InputLabel>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        className='radius-8px text-secondary'
                                                        value={year.cy_coa}
                                                        label="Select"
                                                        disabled={coaDropData.cy_coa}
                                                        onChange={(e) => HandleYearChange(e, "COA-CY")}
                                                    >
                                                        {
                                                            coaDropData?.years && coaDropData?.years.length > 0 && coaDropData?.years.map((year, i) => (
                                                                year && <MenuItem className='text-secondary' key={i} value={year}>{year}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='p-2'
                                        onDragEnter={(e) => e.preventDefault()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => handleDropValue(e, 'current')}
                                        onDragLeave={(e) => e.preventDefault()}
                                    >
                                        <h6 className='ml-3  font-size-14 custom-font-600'>Values</h6>
                                        {
                                            dropValue?.name &&
                                            <div className='d-flex ml-4'>
                                                <Typography className='text-secondary custom-font-500'>{dropValue?.name}</Typography>
                                                <IconButton aria-label="Delete" className='ml-2' onClick={() => HandleClear('cy_value')} > <ClearIcon /> </IconButton>
                                            </div>
                                        }

                                        <div className='d-flex align-items-center ml-3'>
                                            <Checkbox checked={dropValue.cy_checked} disabled={dropValue.cy_value} {...label} />
                                            <Typography className='font-sans'>Include values</Typography>

                                            <FormControl sx={{ m: 1, minWidth: 200 }} className='custom-bg-white-150' size="small">
                                                <InputLabel className='text-secondary' id="demo-select-small-label">Select Year</InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    className='radius-8px text-secondary'
                                                    value={year.cy_value}
                                                    label="Year"
                                                    disabled={dropValue.cy_value}
                                                    onChange={(e) => HandleYearChange(e, "VALUE-CY")}
                                                >
                                                    {
                                                        dropValue?.years && dropValue?.years.length > 0 && dropValue?.years.map((year, i) => (
                                                            year && <MenuItem className='text-secondary' key={i} value={year}>{year}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div
                                        className='p-2'
                                    >
                                        <h6 className='ml-3 font-size-14 custom-font-600'>Additional Journals</h6>
                                        <div className='d-flex align-items-center ml-3'>
                                            <Checkbox disabled={true}   {...label} />
                                            <Typography className='font-sans'>Include values</Typography>

                                            <FormControl sx={{ m: 1, minWidth: 200 }} className='custom-bg-white-150' size="small">
                                                <InputLabel className='text-secondary' id="demo-select-small-label">Select Year</InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    className='radius-8px text-secondary'
                                                    label="Year"
                                                    disabled
                                                >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    <MenuItem value={"3/31/2022"}>3/31/2022</MenuItem>
                                                    <MenuItem value={"3/31/2023"}>3/31/2023</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </Card>
                                <Card className='bg-white mt-5 custom-card radius-12px'>
                                    <div className='d-flex align-items-start bg-light-blue'>
                                        <h6 className='mt-2 p-2 ml-3 font-size-16 custom-font-600'>Comparitive period</h6>
                                    </div>
                                    <div
                                        className='p-2 mt-1'
                                        onDragEnter={(e) => e.preventDefault()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => handleDrop(e, 'comparitive')}
                                        onDragLeave={(e) => e.preventDefault()}
                                    >
                                        <h6 className='ml-3 font-size-14 custom-font-600'>Chart of accounts</h6>
                                        {
                                            coaDropData?.py_name &&
                                            <div className='d-flex ml-4'>
                                                <Typography className='text-secondary custom-font-500'>{coaDropData?.py_name}</Typography>
                                                <IconButton aria-label="Delete" className='ml-2' onClick={() => HandleClear('py_coa')} > <ClearIcon /> </IconButton>
                                            </div>
                                        }

                                        <div className='d-flex align-items-center ml-3'>
                                            <Checkbox checked={coaDropData.py_checked} disabled={coaDropData.py_coa}  {...label} /> <p className={`m-0 ${coaDropData.py_coa ? "include-value-d" : "include-value"}`}>Include values</p>
                                            <FormControl sx={{ m: 1, minWidth: 200 }} className='custom-bg-white-150' size="small">
                                                <InputLabel className='text-secondary' id="demo-select-small-label">Select Year</InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    className='radius-8px text-secondary'
                                                    value={year.py_coa}
                                                    label="Year"
                                                    disabled={coaDropData.py_coa}
                                                    onChange={(e) => HandleYearChange(e, "COA-PY")}
                                                >
                                                    {
                                                        coaDropData?.py_years && coaDropData?.py_years.length > 0 && coaDropData?.py_years.map((year, i) => (
                                                            year && <MenuItem className='text-secondary' key={i} value={year}>{year}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div
                                        className='p-2'
                                        onDragEnter={(e) => e.preventDefault()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => handleDropValue(e, 'comparitive')}
                                        onDragLeave={(e) => e.preventDefault()}
                                    >
                                        <h6 className='ml-3  font-size-14 custom-font-600'>Values</h6>
                                        {
                                            dropValue?.py_name &&
                                            <div className='d-flex ml-4'>
                                                <Typography className='text-secondary custom-font-500'>{dropValue?.py_name}</Typography>
                                                <IconButton aria-label="Delete" className='ml-2' onClick={() => HandleClear('py_value')} > <ClearIcon /> </IconButton>
                                            </div>
                                        }
                                        <div className='d-flex align-items-center ml-3'>
                                            <Checkbox checked={dropValue.py_checked} disabled={dropValue.py_value} {...label} />
                                            <Typography className='font-sans'>Include values</Typography>
                                            <FormControl sx={{ m: 1, minWidth: 200 }} className='custom-bg-white-150' size="small">
                                                <InputLabel className='text-secondary' id="demo-select-small-label">Select Year</InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    className='radius-8px text-secondary'
                                                    value={year.py_value}
                                                    label="Year"
                                                    disabled={dropValue.py_value}
                                                    onChange={(e) => HandleYearChange(e, 'VALUE-PY')}
                                                >
                                                    {
                                                        dropValue?.py_years && dropValue?.py_years.length > 0 && dropValue?.py_years.map((year, i) => (
                                                            year && <MenuItem className='text-secondary' key={i} value={year}>{year}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='ml-3 font-size-14 custom-font-600'>Additional Journals</h6>
                                        <div className='d-flex align-items-center ml-3'>
                                            <Checkbox disabled {...label} />
                                            <Typography className='font-sans'>Include values</Typography>
                                            <FormControl sx={{ m: 1, minWidth: 200 }} className='custom-bg-white-150' size="small">
                                                <InputLabel className='text-secondary' id="demo-select-small-label">Select Year</InputLabel>
                                                <Select
                                                    disabled
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    className='radius-8px text-secondary'
                                                    label="Year"
                                                >
                                                    <MenuItem className='text-secondary' value=""><em>None</em></MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-sm-4 scroll-y-preset">
                                <Card className='bg-white custom-card radius-12px'>
                                    <div className='d-flex align-items-start custom-bg-warning-200'>
                                        <h6 className='mt-2 p-2 ml-3 font-size-16 custom-font-600'>Imported Presets</h6>
                                    </div>
                                    <div className='p-2 preset-map-div'>
                                        {
                                            presetOpt?.loading ? <div className='d-flex justify-content-center'><LoaderTwo /></div> :
                                                presetOpt?.preset.length > 0 ? presetOpt?.preset.map((coaEle, cx) => (
                                                    <div
                                                        key={cx}
                                                        draggable
                                                        className="d-flex align-items-center mt-2 p-2 bg-white cursor-pointer border shadow radius-8px"
                                                        onDragStart={(e) => setDraggedObject({ ...coaEle, key: "imported_preset" })}
                                                    >
                                                        <DragIndicatorIcon className='mr-1' style={{ color: "#DFE2E7" }} />
                                                        <div>
                                                            <p className="m-0 p-0 font-size-16 custom-font-400" style={{ color: "#ABA9A9" }}>{coaEle?.label}</p>
                                                            {/* <p className="m-0 p-0 font-size-12 text-secondary d-flex justify-content-center align-items-center custom-font-500 font-inter">
                                                            <CompanySVGComponent color={"#ABA9A9"} width={11} height={15} />
                                                            <span className='ml-1' >{coaEle?.label}</span>
                                                            <CalendarMonthIcon className="ml-1" style={{ height: "15px" }} />
                                                        </p> */}
                                                        </div>
                                                    </div>
                                                )) : <Typography>Preset data not found.</Typography>
                                        }
                                    </div>
                                </Card>
                                <Card className='bg-white mt-3 custom-card radius-12px'>
                                    <div className='d-flex align-items-start custom-bg-warning-200'>
                                        <h6 className='mt-2 p-2 ml-3 font-size-16 custom-font-600'>Chart of accounts</h6>
                                    </div>
                                    <div className='p-2 preset-map-div'>
                                        {
                                            presetOpt?.loading ? <div className='d-flex justify-content-center'><LoaderTwo /></div> :
                                                presetOpt?.coa.length > 0 ? presetOpt?.coa.map((coaEle, cx) => (
                                                    <>
                                                        {
                                                            coaEle?.label ?
                                                                <div
                                                                    key={cx}
                                                                    draggable
                                                                    onDragStart={(e) => setDraggedObject({ ...coaEle, key: "chat_of_account" })}
                                                                    className="d-flex align-items-center mt-2 p-2 bg-white cursor-pointer border shadow radius-8px"
                                                                >
                                                                    <DragIndicatorIcon className='mr-1' style={{ color: "#DFE2E7" }} />
                                                                    <div>
                                                                        <p className="m-0 p-0 font-size-16 custom-font-400" style={{ color: "#ABA9A9" }}>{coaEle?.label}</p>
                                                                    </div>
                                                                </div>
                                                                : <></>
                                                        }
                                                    </>
                                                )) : <Typography>COA data not found.</Typography>
                                        }
                                    </div>
                                </Card>
                                <Card className='bg-white mt-3 custom-card radius-12px'>
                                    <div className='d-flex align-items-start custom-bg-warning-200'>
                                        <h6 className='mt-2 p-2 ml-3 font-size-16 custom-font-600'>Uploaded TB</h6>
                                    </div>
                                    <div className='p-2 preset-map-div'>
                                        {
                                            presetOpt?.loading ? <div className='d-flex justify-content-center'><LoaderTwo /></div> :
                                                presetOpt?.upload_tb_options?.length > 0 ? presetOpt?.upload_tb_options?.map((coaEle, cx) => (
                                                    <div
                                                        key={cx}
                                                        draggable
                                                        onDragStart={(e) => setDraggedObject({ ...coaEle, key: "uploaded_tb" })}
                                                        className="d-flex align-items-center mt-2 p-2 bg-white cursor-pointer border shadow radius-8px"
                                                    >
                                                        <DragIndicatorIcon className='mr-1' style={{ color: "#DFE2E7" }} />
                                                        <div>
                                                            <p className="m-0 p-0 font-size-16 custom-font-400" style={{ color: "#ABA9A9" }}>{coaEle?.label}</p>
                                                        </div>
                                                    </div>
                                                )) :
                                                    <div className='p-2 mt-1 text-center' >
                                                        <div className='mt-5'>
                                                            <p className="m-0 p-0 font-size-16 custom-font-400" style={{ color: "#ABA9A9" }}>Please Upload TB</p>
                                                        </div>
                                                    </div>
                                        }
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SelectSheetType setOpen={setOpen} setsingleFormat={setsingleFormat} open={open} presetId={params?.presetId} />
            <SingleFIleFormat setsingleFormat={setsingleFormat} singleFormat={singleFormat} />
        </>
    )
}

export default Preset;
