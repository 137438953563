import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function NewOptionModal({
    open, 
    setOpen, 
    setWorkingOptDetail, 
    workingOptDetail, 
    addNewOption
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" className='text-secondary'>
                    {"Add New Option"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className='d-flex flex-column' style={{width:"20rem"}}>
                            <label className='custom-font-600'>Name : </label>
                            <input 
                                className='custom-bg-blue-200 p-2 border-0 rounded text-secondary'
                                value={workingOptDetail.newOptName}
                                onChange={(e) => {
                                    setWorkingOptDetail(prev => ({...prev, newOptName: e.target.value}))
                                }}
                            />
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='mb-2  mr-3'>
                    <button onClick={handleClose}  className='bg-danger text-white border-0 px-3 py-1 rounded'>
                        Cancel
                    </button>
                    <button 
                        onClick={addNewOption}
                        className='custom-common-theme-bg-color text-white border-0 px-3 py-1 rounded'
                    >
                        Save
                    </button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}