const PFCfTypes = {
    PFCf_ACTION_START:"PFCf_ACTION_START",
    PFCf_FETCH_DATA:"PFCf_FETCH_DATA",
    PFCf_FETCH_DATA_FAILED:"PFCf_FETCH_DATA_FAILED",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
}

const PFSCashFlow = (state=Initial_state, action) => {
    switch(action.type){
        case PFCfTypes.PFCf_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case PFCfTypes.PFCf_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
            }

        case PFCfTypes.PFCf_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action?.payload?.error_message
            }

        default:
            return state;
    }
};

export default PFSCashFlow;