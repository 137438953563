const CompanyTypes = {
    PRESETS_ACTION_START:"PRESETS_ACTION_START",
    PRESETS_FETCH_DATA:"PRESETS_FETCH_DATA",
    PRESETS_FETCH_DATA_FAILED:"PRESETS_FETCH_DATA_FAILED",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
}

const AllPresetsReducer = (state=Initial_state, action) => {
    switch(action.type){
        case CompanyTypes.PRESETS_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case CompanyTypes.PRESETS_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload,
            }

        case CompanyTypes.PRESETS_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default AllPresetsReducer;