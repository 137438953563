import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useState } from "react";
import { cities, getApprover, state } from "../ApiServices/apiHandler";
import { useEffect } from "react";

const stylemodal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

const EditCompany = (props) => {
  const { open, handleClose,editData,handleChnageEdit,submitEdit,handleApproverChange} = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [allcities, setCitiesData] = useState([]);
  const [allstate, setStateData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [approverId,setApproverId]=useState([]);
  const [id,setId]=useState('');

  useEffect(() => {
    getApproverIds();
  }, [])
  
  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };
  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };
  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };
  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const getApproverIds = async()=>{
    const parent_id = user.parent_id;
      getApprover(parent_id)
        .then(response => {
          setApproverId(response.data.result);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="wp-modal-box wp-modal-box-add-company-form">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h5 className="wp-add-comp-modal-header">Edit company</h5>
          </Typography>
          <div style={{ marginTop: "30px" }}>
            <label>
              <strong>Enter your organization name</strong>
            </label>
            <TextField
              className="wp-add-comp-modal-input"
                value={editData.company_name}
                disabled={editData.user_id===user.parent_id || user.responsibilities==="approver"||user.responsibilities==="preparer"&&editData.created_by==="user"&&editData.user_id!==user._id}
                onChange={(e) =>
                  handleChnageEdit(e.target.value, "company_name")
                }
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>
              <strong>CIN No.</strong>
            </label>
            <TextField
              className="wp-add-comp-modal-input"
              value={editData.ci_no}
              disabled={editData.user_id===user.parent_id || user.responsibilities==="approver"||user.responsibilities==="preparer"&&editData.created_by==="user"&&editData.user_id!==user._id}
              onChange={(e) => handleChnageEdit(e.target.value, "ci_no")}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>
              <strong>PAN/TAN No.</strong>
            </label>
            <TextField
              className="wp-add-comp-modal-input"
              value={editData.pan_no}
              disabled={editData.user_id===user.parent_id || user.responsibilities==="approver"||user.responsibilities==="preparer"&&editData.created_by==="user"&&editData.user_id!==user._id}
              onChange={(e) => handleChnageEdit(e.target.value, "pan_no")}
            />
          </div>
          <div className="row">
            <div className="col-6" style={{ marginTop: "20px" }}>
              <label>
                <strong>Nature of company</strong>
              </label>
              
              <select
                className="wp-add-comp-modal-input"
               
                style={{
                  height: "60px",
                }}
                disabled={editData.user_id===user.parent_id || user.responsibilities==="approver"||user.responsibilities==="preparer"&&editData.created_by==="user"&&editData.user_id!==user._id}
                onChange={(e) =>
                  handleChnageEdit(e.target.value, "nature_of_company")
                }
              >
                <option style={{ color: "black", backgroundColor: "white" }} >
                  {"Select..."}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={editData?.nature_of_company === 'Manufacturing'}>
                  {"Manufacturing"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={editData?.nature_of_company === 'NBFC'}>
                  {"NBFC"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={editData?.nature_of_company === 'Banking'}>
                  {"Banking"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={editData?.nature_of_company === 'Trading'}>
                  {"Trading"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={editData?.nature_of_company === 'Other Specify'}>
                  {"Other Specify"}
                </option>
              </select>
            </div>
            <div className="col-6" style={{ marginTop: "20px" }}>
              <label>
                <strong>Functional currency</strong>
              </label>
              {/* <TextField
                className="wp-add-comp-modal-input"
                placeholder="Type a company name or search"
                multiline
              /> */}
              <select
                className="wp-add-comp-modal-input"
                
                style={{
                  height: "60px",
                }}
                disabled={editData.user_id===user.parent_id || user.responsibilities==="approver"||user.responsibilities==="preparer"&&editData.created_by==="user"&&editData.user_id!==user._id}
                onChange={(e) =>
                  handleChnageEdit(e.target.value, "functional")
                }
              >
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Select..."}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={editData?.functional === 'INR'}>
                  {"INR"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={editData?.functional === 'USD'}>
                  {"USD"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={editData?.functional === 'LKR'}>
                  {"LKR"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={editData?.functional === 'AUD'}>
                  {"AUD"}
                </option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-6" style={{ marginTop: "20px" }}>
              <label>
                <strong>Select City</strong>
              </label>
              {/* <TextField
                  className="wp-add-comp-modal-input"
                  placeholder="Type a company name or search"
                  multiline
                /> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                // options={allcities?allcities:[]}
                value={editData?.city}
                options={!allcities ? [] : allcities}
                onInputChange={onInputChangecity}
                disabled={editData.user_id===user.parent_id || user.responsibilities==="approver"||user.responsibilities==="preparer"&&editData.created_by==="user"&&editData.user_id!==user._id}
                onChange={(e, value) => handleChnageEdit(value, "city")}
                sx={{ width: 300, backgroundColor: "white" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=<LocationOnIcon />
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="col-6" style={{ marginTop: "20px" }}>
              <label>
                <strong>Select State</strong>
              </label>
              {/* <TextField
                className="wp-add-comp-modal-input"
                placeholder="Type a company name or search"
                multiline
              /> */}
              <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={editData?.state}
              options={!allstate ? [] : allstate}
              disabled={editData.user_id===user.parent_id || user.responsibilities==="approver"||user.responsibilities==="preparer"&&editData.created_by==="user"&&editData.user_id!==user._id}
              onChange={(e, value) => handleChnageEdit(value, "state")}
              onInputChange={onInputChange}
              sx={{ width: 300, backgroundColor: "white" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=<LocationOnIcon />
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      border: "none",
                    },
                  }}
                />
              )}
            />
            </div>
          </div>
          <div className="blankspace30"></div>
          {editData.created_by==="admin"&&editData.admin_id===user._id || editData.created_by==="user"&&editData.admin_id===user._id ?
          <>
          <Button className="wp-btn-fill" onClick={()=>submitEdit(editData._id)}>Save</Button>
          &nbsp; &nbsp;
          <Button className="wp-btn-no-fill" onClick={handleClose}>
            Cancel
          </Button>
          </>
          :editData.created_by==="user"&&editData.user_id===user._id  ?
          <>
          <Button className="wp-btn-fill" onClick={()=>{
          handleOpenModal()
          setId(editData._id);
          }}>Send for Approval</Button>
          &nbsp; &nbsp;
          <Button className="wp-btn-no-fill" onClick={handleClose}>
            Cancel
          </Button>
          </>:<></>
        }
        </Box>
      </Modal>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={stylemodal}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => handleApproverChange(e.target.value, "approver_id")}
            // value={editData.approver_id}
          >
          {approverId && approverId.length>0 && approverId.map((data)=>
            <MenuItem key={data._id} value={data._id}>{data.name}</MenuItem>
          )}
          </Select>
          </FormControl>
          <div className="d-flex flex-row justify-content-center mt-3 ">
          <Button style={{
              backgroundColor: "#03565a",
              color: "white",
              textTransform: "none",
              margin:"10px",
              height:"36px",
              width:"50%"
            }}
            onClick={handleCloseModal}
            >
              Return
          </Button>
          <Button
            style={{
              backgroundColor: "#03565a",
              color: "white",
              textTransform: "none",
              width:"50%",
              height:"36px",
              marginTop:"10px"
            }}
            onClick={() => {
              submitEdit(id);
              setOpenModal(false);
            }}
          >
            Send for approval
          </Button>
        </div>
        </Box>
      </Modal>
    </>
  );
};
export default EditCompany;
