/*
    Author - DJ Parida
    This action is meant for the soft deletion of Notes Data
*/

export const selectNotesForSD = (data) => {  // data is the note data
    return dispatch => { 
        dispatch({type:"ADD_NOTES_FOR_SOFT_DELETE", payload: data})       
    }
}

export const removeSelectedNotesForSD = (data) => {
    return dispatch => {
        dispatch({type:"REMOVE_NOTES_FOR_SOFT_DELETE", payload: data})
    }
}

export const clearSoftDeleteNotes = () => {
    return dispatch => {
        dispatch({type: "CLEAR_SOFT_DELETE"})
    }
}