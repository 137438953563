import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import uploadimage from "../../../assets/imageupload.svg"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const UploadImageComponent = ({
  images, 
  setImages
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    setImages(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  return (
    <div
      {...getRootProps()}
      className='w-100 h-100 borderdashed'
    >
      <input {...getInputProps()} />
      <img src={uploadimage} alt="upload" className="d-flex justify-content-center align-items-center w-100" height={50} width={50} />
      <p className="text-center font-sans font-size-16 custom-font-700 m-0 text-black">Select files or drag them here</p>
      <div className="w-100 d-flex align-items-center justify-content-center">
        <ErrorOutlineIcon style={{ color: "#939393" }} />
        <p className="text-center font-sans custom-font-500 m-0 ml-1 font-size-14 " style={{ color: "#939393" }}>If you have any images to describe your problem well</p>
      </div>
      <p className="m-0 mt-2 p-0 text-secondary font-size-12 d-flex justify-content-center">
        {images?.map(image => image?.name).join(', ')}
      </p>
    </div>
  )
}

export default UploadImageComponent;