import React from 'react'

function RenameSVGComponent({height,width,color, className,style}) {
  return (
    <>
        <svg width={width} height={height} className={className} style={style} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M18.0243 5.99926L14.6121 2.58788C14.4987 2.47442 14.364 2.38441 14.2158 2.323C14.0676 2.26159 13.9087 2.22998 13.7482 2.22998C13.5878 2.22998 13.4289 2.26159 13.2807 2.323C13.1324 2.38441 12.9978 2.47442 12.8843 2.58788L3.46942 12.0028C3.35549 12.1158 3.26516 12.2503 3.20369 12.3986C3.14221 12.5468 3.11082 12.7058 3.11133 12.8663V16.2785C3.11133 16.6024 3.24004 16.9132 3.46914 17.1423C3.69824 17.3714 4.00896 17.5001 4.33296 17.5001H17.16C17.322 17.5001 17.4774 17.4357 17.5919 17.3212C17.7064 17.2066 17.7708 17.0513 17.7708 16.8893C17.7708 16.7273 17.7064 16.5719 17.5919 16.4574C17.4774 16.3428 17.322 16.2785 17.16 16.2785H9.47293L18.0243 7.7271C18.1378 7.61366 18.2278 7.47897 18.2892 7.33074C18.3506 7.18251 18.3822 7.02363 18.3822 6.86318C18.3822 6.70273 18.3506 6.54385 18.2892 6.39562C18.2278 6.24739 18.1378 6.11271 18.0243 5.99926ZM7.7451 16.2785H4.33296V12.8663L11.0519 6.14739L14.464 9.55953L7.7451 16.2785ZM15.3276 8.696L11.9162 5.28385L13.7486 3.45142L17.16 6.86356L15.3276 8.696Z" 
                fill={color} 
                stroke={color} 
                stroke-width="0.610811"
            />
        </svg>
    </>
  )
}

export default RenameSVGComponent