const presetType = {
    NOTE_BS_NUMBER: "NOTE_BS_NUMBER",
    NOTE_KEY: "NOTE_KEY",
}

const initialState = {
    noteBsNo: 0,
    noteKey: 0,
};

const HandlePopulateFsNotesReducer = (state=initialState, action) => {
    switch(action.type){
        case presetType.NOTE_BS_NUMBER:
            return {
                ...state,
                noteBsNo:action.payload
            }
        case presetType.NOTE_KEY:
            return {
                ...state,
                noteKey:action.payload
            }
        default:
            return state;
    }
};

export default HandlePopulateFsNotesReducer;
