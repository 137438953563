import { useState, useEffect } from 'react';
import { updateTicket, getTicketById } from '../ApiServices/apiHandler';
import Card from '@mui/material/Card';

import {
  Button,
  InputLabel, FormControl, Select, TextField, MenuItem,
} from '@mui/material';
import { useParams,useHistory } from 'react-router';
import { Navbar } from '../../../Components/Navbar';



export default function Newticket() {



  const history = useHistory()
  const { id } = useParams()


  

  const [ticketData, setTicketData] = useState({
    subject: '',
    description: '',
    image: null,
    status: '',
    priority: '',
    type: '',
  });





  useEffect(() => {
    fetchTicket()
  }, []);



  const fetchTicket = async () => {
    try {
      const response = await getTicketById(id);
      
      if (response.data.data) {
        
        setTicketData(response.data.data);
      }
    } catch (error) {
      console.log("error fetching ticket details:", error);
    }
  }


  const handleUpdateTicket = async () => {
    try {
      const response = await updateTicket(id, ticketData);
      
      if (response.status === 200) {
      
        history.push("/supports");
      }
    } catch (error) {
      console.error('Error updating ticket:', error);
    }
  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicketData({ ...ticketData, [name]: value });
  };





  return (
    <>
    <div><Navbar/></div>
      <div className='px-3 mt-3 '>
        {/* <h6>Tickets <i class="bi bi-arrow-right"></i> New tickets</h6> */}
        <Card className='px-4 mt-4 '>
          <div className='ml-4'>

            <div className='px-3 mt-2'>
              <div className="row">    
                <div className="col-sm-2">Edit Ticket</div>
             
              </div>
            </div>

            <div className='px-3 mt-2'>
              <div className="row align-items-center">
                <div className="col-sm-2">Subject*</div>
                <div className="col-sm-10">
                  <TextField
                    fullWidth
                    placeholder='Enter Title Here'
                    value={ticketData.subject}
                    onChange={handleChange}
                    name="subject"
                  />
                </div>
              </div>
            </div>

            <div className='px-3 mt-2'>
              <div className="row align-items-center">
                <div className="col-sm-2">Description*</div>
                <div className="col-sm-10">
                  <textarea style={{border:"1px solid lightgrey",borderRadius:'5px'}} className="form-control" id="textAreaExample" rows="4" placeholder='Enter Description' name='description' value={ticketData.description}
                    onChange={handleChange}  ></textarea>

                </div>
              </div>
            </div>

            <hr />


          

            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-5">
                <div className='p-2 mt-2'>
                  <input type='text' id='image' name='image' value={ticketData.image} onChange={handleChange} />

                </div>

                <div className='p-2 mt-5'>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="priority"
                      name='priority'
                      value={ticketData.priority}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Critical"}>Critical</MenuItem>
                      <MenuItem value={"Normal"}>Normal</MenuItem>
                    </Select>
                  </FormControl>
                </div>


              </div>

              <div class="col-sm-5 ">
                <div className='p-3'>

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">status*</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="status"
                      name='status'
                      value={ticketData.status}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Open"}>Open</MenuItem>
                      <MenuItem value={"Re-Open"}>Re-Open</MenuItem>
                      <MenuItem value={"Close"}>Close</MenuItem>
                    </Select>
                  </FormControl>

                </div>
                <div className='p-3 mt-1'>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="type"
                      name='type'
                      value={ticketData.type}
                      onChange={handleChange}
                    >
                      <MenuItem value={"New Feature"}>New Feature</MenuItem>
                      <MenuItem value={"Support"}>Support</MenuItem>
                      <MenuItem value={"Setup"}>Setup</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div style={{ justifyContent: "flex-end", display: "flex", paddingRight: "17px", marginTop: "10px", marginBottom: "20px" }}>
              <Button variant="contained" style={{ backgroundColor: "#03565A", paddingRight: "10x" }} onClick={handleUpdateTicket}>UPDATE <span style={{ marginLeft: "10px" }}><i class="bi bi-arrow-right-circle" ></i></span> </Button>
            </div> 


          </div>
        </Card>


      </div>





    </>
  )
}
