import React, { useEffect, useState } from 'react'
import { Navbar } from '../../../Navbar'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router';
import { editAdjustment, getAdjLog } from '../../../../services/api/StandAlone/cashFlow';
import swal from 'sweetalert';
import "../../cashFlow.css"
import AdjustmentLogsEditModal from './AdjustmentLogsEditModal';

const AdjustmentLogPage = () => {
    const project_id = localStorage.getItem('project_id');
    const history = useHistory();
    const [logsData, setLogsData] = useState([]);
    const [open, setOpen] = useState(false);
    const [element, setElement] = useState({});

    useEffect(() => {
        getAdjLog(project_id).then(res => {
            setLogsData(res.data.logsData)
        }).catch(err => swal("Error", err, "error"))
    }, [])

    const EditSubmit = (value) => {
        editAdjustment(project_id, {value}).then(res => {
            setLogsData(res.data.adjustment);
            setOpen(false)
        }).catch(err => swal("Error", err, "error") )
    }

    return (
        <>
            <Navbar text="Financial Year: 2021 - 2022" />

            <div>
                <button className='ml-2 mt-3 rounded back-btn' style={{ border: "none", backgroundColor: "inherit" }}
                    onClick={() => { history.push(`/financialstatement/cashflow/working`) }}
                >
                    <div className='d-flex px-3' style={{ color: "#03565a", fontWeight: "600" }}><ArrowBackIcon /> Back</div>
                </button>
            </div>

            {
                logsData && logsData.length > 0 ? logsData.map((ele, ix) => (
                    <div className='d-flex flex-column justify-content-center align-items-center' key={ix} >
                        <p className='my-1'>Description: <span> {ele?.description} </span></p>
                        <div className='d-flex'>
                            <div>
                                <div className='d-flex' style={{ gap: "1rem" }}>
                                    <p className='my-1'>Adjustment to be shown under</p>
                                    <p className='my-1 adjustment-p' style={{width:"24rem", minHeight:"3rem"}}>{ele?.header}</p>
                                    <p className='my-1 adjustment-p' style={{width:"24rem", minHeight:"3rem"}}>{ele?.subHeader}</p>
                                    <p className='my-1 font-weight-bold'>{ele?.amount}</p>
                                </div>
                                <div className='d-flex' style={{ gap: "1rem" }}>
                                    <p className='my-1'>Adjustment to be shown under</p>
                                    <p className='my-1 adjustment-p' style={{width:"24rem", minHeight:"3rem"}}>{ele?.header1}</p>
                                    <p className='my-1 adjustment-p' style={{width:"24rem", minHeight:"3rem"}}>{ele?.subHeader1}</p>
                                    <p className='my-1 font-weight-bold'>{ele?.amount1}</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center ml-4'>
                                <button className='my-2 rounded deleteBtn mt-2' style={{ border: "none", backgroundColor: "white" }}
                                    onClick={() => {setElement({...ele, index:ix}); setOpen(true);}}
                                >
                                    <div className='px-4 py-2' style={{ color: "#03565a", fontWeight: "600" }}>Edit</div>
                                </button>
                            </div>
                        </div>
                    </div>
                )) : (<p className='d-flex justify-content-center'> Cash Flow Logs Is Empty</p>)
            }

            {
                open && <AdjustmentLogsEditModal open={open} setOpen={setOpen} element={element} HandleSubmit={(value) => EditSubmit(value)} />
            }
        </>
    )
}

export default AdjustmentLogPage