const coaType = {
    COA_DATA: "COA_DATA",
}

const Initial_state = {
    coaData: [],
};

const CoaReducer = (state=Initial_state, action) => {
    switch(action.type){
        case coaType.COA_DATA:
            console.log("coa data -----", action.payload)
            return {
                ...state,
                coaData:action.payload
            }
        default:
            return state;
    }
};

export default CoaReducer;
