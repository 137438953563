import axios from "axios"

const auth = localStorage.getItem("auth_token");
const headers = {
    "x-auth-token": auth,
};

const ratioCreateNewInput = async (url, data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, { headers })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject({
                    success: false,
                    message: "Create new input failed, Please try after some time.",
                });
            });
    });
};

const financialInstrumentUpdate = async (url, data) => {
    return new Promise((resolve, reject) => {
        axios
            .put(url, data, { headers })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                let errorText = err.response.data.error ? 
                    `Gating Error, Please try after some time. Error: ${err.response.data.error}` : 
                    "Facing some issues, Please try after some time."
                return reject({
                    success: false,
                    message: errorText,
                });
            });
    });
};

export {ratioCreateNewInput,financialInstrumentUpdate};