import { getAllWorkspaces } from "../../../WorkSpace/Components/ApiServices/apiHandler";
export const fetchWorkspace = (aid) => {
    return dispatch => {
        dispatch({type:"AllWS_ACTION_START"})
        getAllWorkspaces().then((result)=>{
            dispatch({
                type: "AllWS_FETCH_DATA",
                payload: result.data.workspaces
            }) 
        }).catch((error)=>{
            dispatch({
                type: "AllWS_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}




