import React, { useState } from 'react';
import { Navbar } from '../../Components/Navbar';
import { Typography, Button, IconButton, TextField } from "@mui/material";
import SingleFIleFormat from '../../Components/Preset/SingleFIleFormat';
import SelectSheetType from '../../Components/Preset/SelectSheetType';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Dropzone from 'react-dropzone';
import Card from '@mui/material/Card';
import "./presetStyle.css"
import { uploadPresetTb, deletePresetTempTB, mappingPreset, createPresetTB, checkTBNameAvailable } from '../../services/api/StandAlone/preset';
import HeaderMapping from '../../Components/Preset/HeaderMapping';
import { useParams } from 'react-router';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import InfoIcon from '@mui/icons-material/Info';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { toast } from 'react-toastify';

const UploadPresetTb = () => {
  const history = useHistory();
  const params = useParams();

  const [singleFormat, setsingleFormat] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMaping, setIsMaping] = useState(false);
  const [header, setHeader] = useState({ fileName: "", headers: [], isShow: false, tempTBId: "" });
  const [error, setError] = useState({
    isError: false,
    text: ""
  });
  const [tbPresetName, setTbPresetName] = useState({
    name: "",
    error: false
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isMapped, setIsMapped] = useState(false);

  const HandleFileUpload = (acceptedFiles) => {
    setError({ ...error, isError: false, text: '' });
    if (acceptedFiles[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setError({ ...error, isError: true, text: `${acceptedFiles[0].type} type of file not accepted.` });
      return
    }
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    uploadPresetTb(formData).then(res => {
      setHeader({ ...header, fileName: res.data?.fileName, headers: res.data?.headers, isShow: true, tempTBId: res.data?.presetTempTB_id });
    })
      .catch(err => console.log("getting error--", err))
  }

  const DeleteTempTB = (tempTB_id) => {
    deletePresetTempTB(tempTB_id).then(res => {
      setHeader({ ...header, isShow: false });
      setIsMapped(false);
    }).catch(err => {
      setHeader({ ...header, isShow: false });
      console.log("delete temp error", err)
    });

  }

  const MappingTB = (value) => {
    let data = { ...value, presetTempTbId: header.tempTBId }
    mappingPreset(data).then(res => {
      setIsMapped(true);
      setIsDisabled(false);
    }).catch(err => console.log("error---", err));
  }

  const HandleContinueClick = () => {
    if (!tbPresetName.name) {
      setTbPresetName({ ...tbPresetName, error: true });
      toast.warning("Please enter preset name.")
      return;
    }
    const data = {
      presetTempTbId: header.tempTBId,
      presetId: params.presetId,
      name: tbPresetName.name,
    }
    createPresetTB(data).then(res => {
      history.push(`/preset/${params.presetId}`);
    }).catch(err => {
      swal("Error", err.response?.data?.error ? err.response?.data?.error : "Error happing at the time of create upload TB.", "error")
    })
  }

  const CheckTBNameAvailable = () => {
    checkTBNameAvailable().then(response => {

    }).catch(error => {

    })
  }

  return (
    <>
      <Navbar text='Preset' />

      <div className='px-4 py-3'>
        <button 
          className="d-flex custom-font-600 custom-common-theme-text-color border-0 bg-color-inherit"
          onClick={() => history.push(`/preset/${params.presetId}`)}
        >
          <ArrowBackIcon className="mr-1" /> Back
        </button>
        <div>
          <p className='custom-font-600 font-size-24'>Upload Trial Balances</p>
          <p className='custom-font-500 font-size-16'>File you upload must contain Ledger code, ledger name, and current year amount.</p>
          <p className='custom-font-500 font-size-16 mb-0 mt-0' >
            <span 
              className='font-weight-bold font-size-16 custom-common-theme-text-color cursor-pointer'
              onClick={() => setsingleFormat(true)}
            >
              Click here
            </span> to view format
          </p>
        </div>
        <div className='mt-4'>
          <Card className='radius-8px' style={{ minHeight: "460px" }}>
            <div className="d-flex justify-content-between px-3">
              <p className='custom-font-500 font-size-16'>Preset Name <span className='text-danger'>*</span> </p>
              <p> <ChangeCircleIcon  className='mr-1 ' style={{color:"#03565A "}}/>
                <span 
                  onClick={() => setOpen(true)}
                  className='custom-font-500 font-size-16  custom-common-theme-text-color cursor-pointer'
                >
                  Change upload mode
                </span>
              </p>
            </div>

            <div className='px-3'>
              <TextField 
                id="outlined-basic" 
                placeholder='Enter preset name' 
                variant="outlined" 
                className='custom-bg-white-150 text-secondary border-danger'
                value={tbPresetName.name} 
                name='name'
                onChange={(e) => setTbPresetName({...tbPresetName, error:false, name:e.target.value})}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: tbPresetName.error && 'red',
                    },
                    '&:hover fieldset': {
                      borderColor: tbPresetName.error && 'red',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: tbPresetName.error && 'red',
                    },
                  },
                }}
              />
            </div>

            <div className='px-3 mt-4'>
                <Dropzone onDrop={acceptedFiles => HandleFileUpload(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div className='preset-section' {...getRootProps()}>
                        <input {...getInputProps()} />

                        <div className='preset-drop'>
                          <div className='d-flex align-items-center justify-content-center rounded-circle custom-bg-warning' style={{  height: "90px", width: "90px", }}>
                            <div><FolderCopyIcon className='drop-folder' /></div>
                          </div>
                          {
                            error.isError && <Typography className='text-danger' fontSize={"14px"}>{error.text}</Typography>
                          }
                          <p className='custom-font-600 font-size-16 m-0'>Upload files here to prepare financial statements</p>
                          <p className='custom-font-500 font-size-12 m-0'><span><InfoIcon style={{ color: "#D6D6D6" }} /></span> File must contain Ledger Code, Ledger Name, Net Balance for Current Year</p>
                        </div>

                      </div>
                    </section>
                  )}
                </Dropzone>
            </div>

          </Card>
        </div>

        
        {
          header.isShow && 
          <>
          <div className='d-flex mt-3 justify-content-between bg-white p-2 radius-8px'>
            <div className='d-flex'>
              <div 
                className='mt-2 d-flex align-items-center justify-content-center' 
                style={{ height: "40px", width: "40px", borderRadius: "50%", backgroundColor: "#F8F8F8" }}
                onClick={() => DeleteTempTB(header.tempTBId)}
              >
                <DeleteOutlineIcon />
              </div>
              <div 
                className='mt-1 ml-3 d-flex align-items-center justify-content-center px-3 radius-10px' 
                style={{ backgroundColor: "#F8F8F8" }}
              >
                <InsertDriveFileIcon />
                <p className='m-0 font-size-16 ml-2 custom-font-600 '>{header?.fileName}</p>
              </div>
            </div>
            {
              isMapped ? <CheckCircleOutlineIcon className='custom-common-theme-text-color' /> :
              <Button
                className='custom-common-theme-text-color custom-font-600 font-size-16 text-capitalize '
                variant="outlined"
                style={{ border: "2px solid #03565A" }}
                onClick={() => setIsMaping(true)}
              >
                Select columns
              </Button>
            }
          </div>
          </>
        }

        <button 
          className='text-capitalize custom-common-theme-bg-color font-size-16 custom-font-600 text-white mt-4 border-0 radius-6px px-3 py-2 shadow'
          variant="contained"
          onClick={() => HandleContinueClick()} 
          disabled={isDisabled}
        >
          Save and Continue
        </button>
      </div> 


      <SingleFIleFormat setsingleFormat={setsingleFormat} singleFormat={singleFormat} />
        <SelectSheetType setOpen={setOpen} setsingleFormat={setsingleFormat} open={open} />
      <HeaderMapping header={header} isMaping={isMaping} setIsMaping={setIsMaping} HandleConfirm={(value) => MappingTB(value)} />
    </>
  )
}

export default UploadPresetTb