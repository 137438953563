import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { motion } from "framer-motion";
import "./pnl.css";
import Loading from '../Common/Loader/Loading';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { setNoteNumber } from "../../helper/calculation";
import { consoleNotesData } from "../../Console/Component/services/mockHandler";
import { fetchTTPNLData } from "../../redux/actions/TemplateTabs/actionPNL";
import moment from "moment";
import { PNLTableDND } from "../TableDND/Table";
import { bsPnlReferenceCheck } from "../../services/api/apiHandler";
import swal from 'sweetalert';

const PNLStatement = (props) => {
  const { projectData } = props;
  const [no, setNo] = useState([]);
  const dispatch = useDispatch();

  const project_id = localStorage.getItem("project_id");
  const tb_id = localStorage.getItem("tb_id");
  const [fotterdata, setFooterData] = useState("");

  const [consoleNotes, setConsoleNotes] = useState([])


  const { isSuccess, response, error_message, loading, set_payload } = useSelector((state) => state.TempTabPNLReducer);
  const rows = response?.pnl_statement ?? [];
  const fsRows = Object.keys(rows);

  const fetchPNLSheetData = async () => {
    if (!isSuccess || set_payload !== JSON.stringify({ project_id, tb_id })) {
      dispatch(fetchTTPNLData(project_id, tb_id))
    }
  }

  useEffect(() => {
    (async () => {
      await fetchPNLSheetData();
    })()
  }, [])


  useEffect(() => {
    setNo(setNoteNumber(fsRows, rows));
    !consoleNotes.length && consoleNotesData().then((response) => {
      setConsoleNotes(response.data)
    }).catch((err) => {

    });
  }, []);

  const getIndexNumber = (line) => {
    try {
      let index = no.findIndex(
        (note) =>
          note?.notes_grp.toLowerCase() === line?.notes_grp.toLowerCase()
      );
      return index;
    } catch (err) {
      return "00";
    }
  };

  const getNoteNoIndex = (notes_no) => {
    try {
      let resultIndex = 0;
      Object.keys(consoleNotes).forEach(function (key, index2) {
        let index = consoleNotes[key].find(
          (data) => data.note_number === notes_no
        );
        if (index !== undefined) {
          resultIndex = index.index;
        }
      });
      return resultIndex;
    } catch (error) {
      console.log("error", error.message);
      return "00";
    }
  };

  const TotalNoteGroupsValue = (group, type) => {
    if (!["cy", "py"].includes(type)) return 0;
    return response.pnl_statement[group].reduce((num, acc) => num + acc[type], 0);
  }
  
  function handleSelect(key) {
    dispatch({ type: "NOTE_KEY", payload: 6 });
    dispatch({ type: "NOTE_PNL_NUMBER", payload: key });
  }

  function handleFooter(e,editor) {
    const data = editor.getData();
    saveFooter(data);
  }

  const saveFooter = (val) => {
    const auth = localStorage.getItem("auth_token");
    const project_id = localStorage.getItem("project_id");
    let headers = {
      "x-auth-token": auth,
    };
    let data = {
      name: "p&lstatement",
      footer_name: val
    }
    axios
      .post(`/api/v1/pnl/save-footer-pl/${project_id}`, data, { headers })
      .then((response) => {
        // setRows(response.data.balance_sheet);
        // getFooter();
      })
      .catch((error) => {
        console.log(error.response, "error");
      });
  }

  function removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  const HandleReference = (event, actionKey, key, index) => {
    const data = {
      value : event.target.value,
      actionKey,
      key,
      index
    }
    bsPnlReferenceCheck(project_id, tb_id, data).then(res => {
      dispatch({
        type: "TT_PNL_FETCH_DATA",
        payload: {data: res.data, set_payload: JSON.stringify({project_id, tb_id})}
      })
    }).catch(error => {
      swal("Error", error.response?.data?.error || "PNL Statement calculation failed !" , "error");
    })
  }

  return (
    <>
    {loading ? <Loading /> :
      <>
      <motion.div 
        className="tableSheet"
        initial={{ y: "-5vh" }}
        animate={{ y: 0 }}
      >
        <PNLTableDND 
          headerData={['','Particulars', 'Notes', `As at ${moment(projectData.current_year).format("DD MMMM YYYY")}`, `As at ${moment(projectData.previous_year).format("DD MMMM YYYY")}`, 'References', 'IND AS References', 'Checklist References']}
          groupData = {fsRows}
          tableData={rows}
          handleClick={handleSelect}
          TotalNoteGroupsValue={TotalNoteGroupsValue}
          companyType={response.company_type}
          HandleReference={HandleReference}
        />
      </motion.div>
      <div style={{marginTop: "20px"}}>
        <CKEditor
          style={{minHeight:'70vh',zIndex:'0'}}
          editor={ ClassicEditor }
          // data={fotterdata}
          onReady={editor=>{}}
          onChange={(e, editor) => {
            handleFooter(e,editor);
          }}
          />
            <h4 style={{marginLeft:"2rem"}}>
              <strong>
                {/* <div dangerouslySetInnerHTML={{_html:fotterdata}} className='editor'></div> */}
                {
                  removeTags(fotterdata)
                }
              </strong>
            </h4>
          </div>
        </>
      }
    </>
  );
};

export default PNLStatement;
