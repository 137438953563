import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import { useParams } from 'react-router';
import { getTicketById, postNotes, getNotes} from '../ApiServices/apiHandler';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Navbar } from '../../../Components/Navbar';
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';

export default function Viewticket() {
    const history = useHistory()
    const [ticketData, setTicketData] = useState({})
    const [creatorId, setCreatorId] = useState('')
    const [note, setNote] = useState('');
    const [chats, setChats] = useState([])
    const [update, setUpdate] = useState(true)
    const [status, setStatus] = useState(true)



    const Back = () => {
        history.push("/supports")
    }

    // const Editbutton=()=>{
    //     navigate("../")
    // }

    const { id } = useParams();
   

    useEffect(() => {
        fetchTicket();
    }, [status]);

    useEffect(() => {
        handleGetNotes()
    }, [update])



    const fetchTicket = async () => {

        try {
            const response = await getTicketById(id)
           
            if (response.data.data) {
               
                setTicketData(response.data.data)
                setCreatorId(response.data.data.customer_id);
            }
        }
        catch (error) {
            console.log("erro fetching ticket deatails")
        }

    }

    const handleCancelStatus = async () => {
        try {
            const response = await axios.put(`/api/v1/superadmin-ticket/close-status/${id}`,{
                customer_id:creatorId
            });
            if (response.status === 200) {
               
                setStatus(!status)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleReopenStatus = async () => {
        try {
            const willDelete = await swal({
                title: "Are you sure?",
                text: "Are you sure that you want to Re-open this ticket?",
                icon: "warning",
                dangerMode: true,
            });
            if (willDelete) {
                const response = await axios.put(`/api/v1/superadmin-ticket/reopen/${id}`,{ 
                   customer_id:creatorId

                 });
                if (response.status === 200) {
                    swal("Re-Opened", "Your imaginary ticket has been Re-opened!", "success")
                   
                    setStatus(!status)
                }

            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleCreateNote = async () => {
        const bodydata = {
            ticket_id: id,
            creator_id: creatorId,
            has_internal: 'yes',
            images: 'C:\\fakepath\\invoice-pdf (44).pdf',
            notes: note,
            added_by: 'admin'
        }


        try {

            const response = await postNotes(bodydata);
            if (response.status === 200) {
               
                setNote('')
                setUpdate(!update)
            }

        } catch (error) {
            console.log(error, 'error')
        }
    }

    const handleGetNotes = async () => {

        try {
            // const response = await getNotes();
            const response = await getNotes(id)
            
            if (response.status === 200) {
                setChats(response.data.data);
            }

        } catch (error) {

        }
    }





    return (
        <>
            <div><Navbar /></div>
            <div className='px-3 mt-4' >
                <span className='fs-6' style={{ color: 'black' }}>Ticket Details:</span>
                <Card className='px-4' >


                    <div className='mt-4'>

                        <div style={{ display: "flex", gap: "55px" }}>
                            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ color: "black", fontWeight: "bold",fontSize:'16px' }}>Title:</p>

                            </div> */}

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ fontWeight: "bold", textAlign: "justify", fontSize: '16px' }}>{ticketData.subject}</p>

                            </div>

                        </div>


                        <div style={{ display: "flex", justifyContent: "space-between" }}>



                            <div style={{ display: "flex", gap: "30px", marginRight: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{
                                        color: "black", fontWeight: "bold", fontSize: '16px', marginRight: '17px'
                                    }}>Type:</p>
                                    <p style={{ color: "black", fontWeight: "bold", fontSize: '16px', textAlign: 'start' }}>Priority:</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: '16px' }}>{ticketData.type}</p>
                                    <p style={{ fontSize: '16px' }}>{ticketData.priority}</p>
                                </div>
                            </div>



                            <div style={{ display: "flex", }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ color: "black", fontWeight: "bold", fontSize: '16px' }}>Created At:</p>
                                    <p style={{ color: "black", fontWeight: "bold", fontSize: '16px' }}>Updated At:</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: '16px', marginLeft: '10px' }}>{moment(ticketData.createdAt).format("DD/MM/YYYY")}</p>
                                    <p style={{ fontSize: '16px', marginLeft: '10px' }}>{moment(ticketData.updatedAt).format("DD/MM/YYYY")}</p>
                                </div>
                            </div>


                            <div style={{ display: "flex", paddingLeft: "30px" }}>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ color: "black", fontWeight: "bold", fontSize: '16px' }}>Status:</p>

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: '16px', marginLeft: '10px' }}>{ticketData.status}</p>

                                </div>

                            </div>


                        </div>

                        <div style={{ display: "flex", }}>
                            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ color: "black", fontWeight: "bold" }}>Description:</p>

                            </div> */}

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ fontSize: '16px' }}>{ticketData.description}</p>

                            </div>

                        </div>

                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "20px" }}>
                        <Button variant="contained" style={{ backgroundColor: "#03565A" }} onClick={Back}>BACK</Button>
                        {ticketData.status === 'Close' && (
                            <Button variant="contained" style={{ backgroundColor: "#03565A" }} onClick={() => handleReopenStatus()}>RE-OPEN TICKET</Button>
                        )}
                        {ticketData.status === 'Open' && (
                            <>
                                <Button variant="contained" style={{ backgroundColor: "red" }} onClick={() => handleCancelStatus()}>CLOSE</Button>
                                <Button variant="contained" style={{ backgroundColor: "#03565A" }} onClick={() => history.push(`/support-editticket/${ticketData._id}`)}>EDIT TICKET</Button>
                            </>
                        )}
                        {ticketData.status === 'Reopen' && (
                            <Button variant="contained" style={{ backgroundColor: "red" }} onClick={() => handleCancelStatus()}>CLOSE</Button>
                        )}
                    </div>


                </Card>

                <Card className='mt-5 px-3 pt-4' style={{ height: "250px" }} >
                    <p style={{ color: "black", fontWeight: "500" }}>Conversations</p>


                    <div data-mdb-input-init className="form-outline">
                        <textarea style={{ border: '1px solid lightgrey', borderRadius: '5px' }} className="form-control" id="textAreaExample" rows="4" placeholder='Give your reply......' value={note} onChange={(e) => setNote(e.target.value)}></textarea>
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button variant="contained" style={{ backgroundColor: "#03565A", }} onClick={() => handleCreateNote()} >SEND<span style={{ marginLeft: "10px" }}><i class="bi bi-arrow-right-circle" ></i></span></Button>
                    </div>

                </Card>



                <div className=' mt-5' style={{ height: '100%' }}>
                    <Card sx={{ height: '100%' }} className='p-4'>
                        {chats.map((chat) => (
                            chat.added_by === 'superadmin' ?
                                (
                                    <div className="d-flex flex-row justify-content-start mb-4">
                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                                            alt="avatar 1" style={{ width: '45px', height: '100%', marginTop: '25px' }} />
                                        <div>
                                            <sub className='ml-2 text-grey'>{chat.added_by === 'admin' ? 'Myfinalyst' : chat.added_by} {moment(chat.createdAt).format("DD/MM/YYYY")} {moment(chat.createdAt).fromNow()}</sub>
                                            <div className="p-3 ms-3" style={{ borderRadius: "15px", backgroundColor: "rgba(57, 192, 237,.2)", }}>
                                                <p className="small m-1">{chat.notes}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-row justify-content-end">

                                        <div>
                                            <sub className='ml-2 text-grey'>{chat.added_by === 'admin' ? 'Myfinalyst' : chat.added_by} {moment(chat.createdAt).format("DD/MM/YYYY")} {moment(chat.createdAt).fromNow()}</sub>
                                            <div className="p-3 ms-3" style={{ borderRadius: "15px", backgroundColor: "rgba(57, 192, 237,.2)", }}>
                                                <p className="small m-1">{chat.notes}</p>
                                            </div>
                                        </div>

                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp" alt="avatar 1" style={{ width: '45px', height: '100%', marginLeft: "10px" }} />
                                    </div>
                                )))}




                        {/* <div className="d-flex flex-row justify-content-end">

                            <div>
                                <sub className='ml-2 text-grey'>{chat.added_by === 'admin' ? 'Myfinalyst' : chat.added_by} {moment(chat.createdAt).format("DD/MM/YYYY")} {moment(chat.createdAt).fromNow()}</sub>
                                <div className="p-3 ms-3" style={{ borderRadius: "15px", backgroundColor: "rgba(57, 192, 237,.2)", }}>
                                    <p className="small m-1">{chat.added_by}</p>
                                </div>
                            </div>

                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp" alt="avatar 1" style={{ width: '45px', height: '100%', marginLeft: "10px" }} />
                        </div> */}

                    </Card>
                </div>




            </div>
        </>
    )
}