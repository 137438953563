export default function WorkspaceHeader(props){
    return (
        <>
            <div className="row mt-5">
                <div className="col-5">
                    <p className="wp-header-step">STEP {props.currentStep} OF 6</p>
                </div>
                <div className="col-7 text-right">
                    <p className="wp-header-help"> Lost or having trouble?<b style={{ color: "#03565A", marginLeft: "4px", fontWeight: "650" }}> Get help.</b></p>
                </div>
                </div>

                <div className="row">
                <div className="col-12">
                    <div className="wp-header-title font-sans" style={{color:"#03565A"}}>{props.title}</div>
                    <p className="wp-header-sub-title"> &nbsp;</p>
                </div>
            </div>
        </>
    )
}