import * as React from "react";

import Sidebar from "./Sidebar";
import GettingStarted from "../Components/WorkspaceCompany/GettingStarted";
// import WorkspaceSetup from "./WorkspaceSetup";
import { Link, useParams } from "react-router-dom";

import { useEffect } from "react";


const WorkSpaceLayout = () => {
  const { workspaceId } = useParams();

  return (
    <>
          <div className="row ml-0 mr-0">
            <div className="col-3 bg-white">
              <Sidebar activePage = "1" id={workspaceId} />
            </div>
            <div className="col-9 main-workspace-content">
              <GettingStarted />
            </div>
          </div>
    </>
  );
};

export default WorkSpaceLayout;
