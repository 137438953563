import React from 'react';
import PrimaryButton from '../../Components/Common/PrimaryButton';
import moment from 'moment';
import { useHistory } from 'react-router';
import "./linkRegister.css";
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';


const LinkRegisterTable = ({
    keyElement,
    data,
    AddNewTableRow,
    handleDrop,
    HandleChange,
    HandleMapping,
}) => {
    const history = useHistory();

    return (
        <React.Fragment key={keyElement}>
            <div className='mt-3'>
                <Card className='radius-12px' style={{ border: "2px solid #D6D6D6" }}>
                    <p className="header font-size-16 custom-font-600 text-capitalize font-sans m-0 p-3 bg-light-blue" >{keyElement === "lease" ? "Import Registers" : keyElement}</p>

                    <table >
                        <thead>
                            {
                                keyElement === "lease" &&
                                <tr>
                                    <th className="w-25 font-size-14 custom-font-600 border-top-0 border-bottom-0 font-inter lease-table font-sans text-uppercase text-secondary" >
                                        Map Register
                                    </th>
                                    <th className="w-25 font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 font-inter lease-table font-sans  text-secondary " >
                                        Link Amounts
                                    </th>
                                    <th className="w-25 font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 font-inter lease-table font-sans text-secondary">
                                        Tag Assets
                                    </th>
                                    <th className="w-25  border-top-0 border-bottom-0 lease-table font-sans text-uppercase text-secondary" ></th>
                                </tr>
                            }
                            {
                                keyElement === "loans" &&
                                <>
                                    <tr>
                                        <th className="w-25 font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 lease-table font-sans text-secondary">
                                            Current Year
                                        </th>
                                        <th className="w-25 font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 lease-table font-sans text-secondary">
                                            Previous Year
                                        </th>
                                        <th className="w-25 font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 lease-table font-sans text-secondary">
                                            Preceding Year
                                        </th>
                                        <th className="w-25  border-top-0 border-bottom-0 lease-table font-sans text-uppercase text-secondary"></th>
                                    </tr>
                                </>
                            }
                            {
                                (keyElement === "payable" || keyElement === "receivable") &&
                                <>
                                    <tr>
                                        <th className="w-25 font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 lease-table font-sans text-secondary">
                                            Current Year
                                        </th>
                                        <th className="w-25 font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 lease-table font-sans text-secondary">
                                            Previous Year
                                        </th>
                                        <th className="w-25  border-top-0 border-bottom-0 lease-table font-sans text-uppercase text-secondary"></th>
                                    </tr>
                                </>
                            }
                        </thead>

                        <tbody className=" text-left bg-white ">
                            {
                                keyElement === "lease" && data[keyElement].length > 0 &&
                                data[keyElement].map((leaseData, ix) => (
                                    <tr key={ix}>
                                        <td
                                            onDragEnter={(e) => e.preventDefault()}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, keyElement, ix)}
                                            onDragLeave={(e) => e.preventDefault()}
                                            className="h-100 pl-0 p-2 font-size-14 border-top-0 border-bottom-0 d-flex justify-content-center align-items-center"
                                        >
                                            {
                                                leaseData?.mapRegister && leaseData?.mapRegister.length > 0 ?
                                                    leaseData?.mapRegister.map((name, index) => (
                                                        <React.Fragment key={name}>
                                                            {index > 0 && ', '}
                                                            {name}
                                                        </React.Fragment>
                                                    )) :
                                                    <div className='d-flex justify-content-center w-75 ' style={{ height: "70px" }}>
                                                        <div className='d-flex justify-content-between align-items-center dashed-border w-100 py-2 px-3'>
                                                            <AddIcon className="mr-0 text-secondary ml-2" />
                                                            <h6 style={{ lineHeight: "14.52px" }} className="text-secondary text-center font-inter custom-font-600 font-size-12 text-uppercase m-0 mr-3">
                                                                <span className='mr-3 font-inter '>Drop your</span> <br /><span className='ml-1 font-inter '>register here</span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                            }
                                        </td>
                                        <td className="border-top-0 border-bottom-0">
                                            <input
                                                type="checkbox"
                                                name='cy'
                                                value={leaseData?.cy}
                                                onChange={(e) => HandleChange(e, keyElement, ix)}
                                                checked={leaseData?.cy}
                                                id="checkbox1"
                                            />
                                            <label htmlFor="checkbox1">CY</label>
                                            <input
                                                type="checkbox"
                                                name='py'
                                                value={leaseData?.py}
                                                onChange={(e) => HandleChange(e, keyElement, ix)}
                                                checked={leaseData?.py}
                                                id="checkbox2"
                                            />
                                            <label htmlFor="checkbox2">PY</label>
                                        </td>
                                        <td className="border-top-0 border-bottom-0 " >
                                            <Button
                                                variant="outlined"
                                                className="font-size-16 radius-6px text-capitalize w-50  font-sans primaryy-border custom-common-theme-text-color font-size-16 custom-font-600"
                                                onClick={() => history.push(`/lease-register-mapping/${ix}`)}
                                            >
                                                Tag Assets
                                            </Button>


                                        </td>
                                        <td className="border-top-0 border-bottom-0 " >
                                            <Button
                                                variant="outlined"
                                                className="font-size-16 font-sans radius-6px text-capitalize  w-50  font-sans primaryy-border custom-common-theme-text-color font-size-16 custom-font-600"
                                                onClick={() => HandleMapping(ix, keyElement)}
                                            >
                                                {"Map"}
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }

                            {
                                keyElement === "loans" && data[keyElement].length > 0 &&
                                data[keyElement].map((loansData, ix) => (
                                    <tr key={ix}>
                                        <td
                                            onDragEnter={(e) => e.preventDefault()}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, keyElement, ix, "cy")}
                                            onDragLeave={(e) => e.preventDefault()}
                                            className={`${ix === 0 ? "text-center" : "text-center"} p-1 px-3 font-size-14 border-top-0 border-bottom-0`}
                                        >
                                            {
                                                loansData?.cy && Array.isArray(loansData?.cy) ? loansData?.cy.length > 0 ?
                                                    loansData?.cy.map((name, index) => (
                                                        <React.Fragment key={name}>
                                                            {index > 0 && ', '}
                                                            {name}
                                                        </React.Fragment>
                                                    )) :
                                                    <div className='d-flex justify-content-center' style={{ height: "70px" }}>
                                                        <div className='d-flex justify-content-between align-items-center dashed-border w-100 py-2 px-3'>
                                                            <AddIcon className="mr-0 text-secondary ml-2" />
                                                            <h6 style={{ lineHeight: "14.52px" }} className="text-secondary text-center font-inter custom-font-600 font-size-12 text-uppercase m-0 mr-3">
                                                                <span className='mr-3 font-inter '>Drop your</span> <br /><span className='ml-1 font-inter '>register here</span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='d-flex justify-content-center align-items-center ml-1'>
                                                        <div className='link-icon'>
                                                            <CalendarMonthIcon className='mt-2 calndr' />
                                                        </div>
                                                        <div>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                                                <DemoContainer components={['DatePicker', 'MobileDatePicker']}   >
                                                                    <MobileDatePicker disabled value={dayjs(loansData?.cy)} />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div className='link-iconn' >
                                                            <ExpandMoreIcon className='mt-2 arrow-icon' />
                                                        </div>
                                                    </div>
                                            }
                                        </td>
                                        <td
                                            onDragEnter={(e) => e.preventDefault()}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, keyElement, ix, "py")}
                                            onDragLeave={(e) => e.preventDefault()}
                                            className={`${ix === 0 ? "text-center" : "text-center"} p-1 px-3 font-size-14 border-top-0 border-bottom-0`}
                                        >
                                            {
                                                loansData?.py && Array.isArray(loansData?.cy) ? loansData?.py.length > 0 ?
                                                    loansData?.py.map((name, index) => (
                                                        <React.Fragment key={name}>
                                                            {index > 0 && ', '}
                                                            {name}
                                                        </React.Fragment>
                                                    )) :
                                                    <div className='d-flex justify-content-center ' style={{ height: "70px" }}>
                                                        <div className='d-flex justify-content-between align-items-center dashed-border w-100 py-2 px-3'>
                                                            <AddIcon className="mr-0 text-secondary ml-2" />
                                                            <h6 style={{ lineHeight: "14.52px" }} className="text-secondary text-center font-inter custom-font-600 font-size-12 text-uppercase m-0 mr-3">
                                                                <span className='mr-3 font-inter '>Drop your</span> <br /><span className='ml-1 font-inter '>register here</span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='d-flex justify-content-center align-items-center ml-1'>
                                                        <div className='link-icon'>
                                                            <CalendarMonthIcon className='mt-2 calndr' />
                                                        </div>
                                                        <div>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                                                <DemoContainer components={['DatePicker', 'MobileDatePicker']}   >
                                                                    <MobileDatePicker disabled value={dayjs(loansData?.py)} />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div className='link-iconn' >
                                                            <ExpandMoreIcon className='mt-2 arrow-icon' />
                                                        </div>
                                                    </div>
                                            }
                                        </td>
                                        <td
                                            onDragEnter={(e) => e.preventDefault()}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, keyElement, ix, "preceding_year")}
                                            onDragLeave={(e) => e.preventDefault()}
                                            className={`${ix === 0 ? "text-center" : "text-center "} p-1 px-3 font-size-14 border-top-0 border-bottom-0`}
                                        >
                                            {
                                                loansData?.preceding_year && Array.isArray(loansData?.preceding_year) ? loansData?.preceding_year.length > 0 ?
                                                    loansData?.preceding_year.map((name, index) => (
                                                        <React.Fragment key={name}>
                                                            {index > 0 && ', '}
                                                            {name}
                                                        </React.Fragment>
                                                    )) :
                                                    <div className='d-flex justify-content-center ' style={{ height: "70px" }}>
                                                        <div className='d-flex justify-content-between align-items-center dashed-border w-100 py-2 px-3'>
                                                            <AddIcon className="mr-0 text-secondary ml-2" />
                                                            <h6 style={{ lineHeight: "14.52px" }} className="text-secondary text-center font-inter custom-font-600 font-size-12 text-uppercase m-0 mr-3">
                                                                <span className='mr-3 font-inter '>Drop your</span> <br /><span className='ml-1 font-inter '>register here</span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='d-flex justify-content-center align-items-center ml-1'>
                                                        <div className='link-icon'>
                                                            <CalendarMonthIcon className='mt-2 calndr' />
                                                        </div>
                                                        <div>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                                                <DemoContainer components={['DatePicker', 'MobileDatePicker']}   >
                                                                    <MobileDatePicker
                                                                        onChange={(e) => HandleChange(e, keyElement, ix, "preceding_year")}
                                                                        value={dayjs(loansData?.preceding_year)}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div className='link-iconn' >
                                                            <ExpandMoreIcon className='mt-2 arrow-icon' />
                                                        </div>
                                                    </div>

                                            }
                                        </td>
                                        <td className="border-top-0 border-bottom-0" >
                                            {
                                                ix !== 0 &&
                                                <Button
                                                    variant="outlined"
                                                    className="font-size-16 font-sans radius-6px text-capitalize  w-50  font-sans primaryy-border custom-common-theme-text-color font-size-16 custom-font-600"
                                                    onClick={() => HandleMapping(ix, keyElement)}
                                                >
                                                    {"Map"}
                                                </Button>
                                            }

                                        </td>
                                    </tr>
                                ))
                            }

                            {
                                (keyElement === "payable" || keyElement === "receivable") &&
                                data[keyElement].length > 0 &&
                                data[keyElement].map((dataEle, ix) => (
                                    <tr key={ix}>
                                        <td
                                            onDragEnter={(e) => e.preventDefault()}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, keyElement, ix, "cy")}
                                            onDragLeave={(e) => e.preventDefault()}
                                            className={`${ix === 0 ? "text-center" : "text-center"} p-1 px-3 font-size-14 border-top-0 border-bottom-0`}
                                        >
                                            {
                                                dataEle?.cy && Array.isArray(dataEle?.cy) ? dataEle?.cy.length > 0 ?
                                                    dataEle?.cy.map((name, index) => (
                                                        <React.Fragment key={name}>
                                                            {index > 0 && ', '}
                                                            {name}
                                                        </React.Fragment>
                                                    )) :
                                                    <div className='d-flex justify-content-center' style={{ height: "70px", }}>
                                                        <div className='d-flex justify-content-between align-items-center dashed-border  py-2 px-3 register-here'>
                                                            <AddIcon className="mr-0 text-secondary ml-2" />
                                                            <h6 style={{ lineHeight: "14.52px" }} className="text-secondary text-center font-inter custom-font-600 font-size-12 text-uppercase m-0 mr-3">
                                                                <span className='mr-3 font-inter '>Drop your</span> <br /><span className='ml-1 font-inter '>register here</span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='d-flex justify-content-center align-items-center ml-1'>
                                                        <div className='link-icon'>
                                                            <CalendarMonthIcon className='mt-2 calndr' />
                                                        </div>
                                                        <div>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                                                <DemoContainer components={['DatePicker', 'MobileDatePicker']}   >
                                                                    <MobileDatePicker disabled value={dayjs(dataEle?.cy)} />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div className='link-iconn' >
                                                            <ExpandMoreIcon className='mt-2 arrow-icon' />
                                                        </div>
                                                    </div>
                                            }
                                        </td>
                                        <td
                                            onDragEnter={(e) => e.preventDefault()}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, keyElement, ix, "py")}
                                            onDragLeave={(e) => e.preventDefault()}
                                            className={`${ix === 0 ? "text-center" : "text-center"} p-1 px-3 font-size-14 border-top-0 border-bottom-0`}
                                        >
                                            {
                                                dataEle?.py && Array.isArray(dataEle?.cy) ? dataEle?.py.length > 0 ?
                                                    dataEle?.py.map((name, index) => (
                                                        <React.Fragment key={name}>
                                                            {index > 0 && ', '}
                                                            {name}
                                                        </React.Fragment>
                                                    )) :
                                                    <div className='d-flex justify-content-center ' style={{ height: "70px" }}>
                                                        <div className='d-flex justify-content-between align-items-center dashed-border register-here py-2 px-3'>
                                                            <AddIcon className="mr-0 text-secondary ml-2" />
                                                            <h6 style={{ lineHeight: "14.52px" }} className="text-secondary text-center font-inter custom-font-600 font-size-12 text-uppercase m-0 mr-3">
                                                                <span className='mr-3 font-inter '>Drop your</span> <br /><span className='ml-1 font-inter '>register here</span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='d-flex justify-content-center align-items-center ml-1'>
                                                        <div className='link-icon'>
                                                            <CalendarMonthIcon className='mt-2 calndr' />
                                                        </div>
                                                        <div>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                                                <DemoContainer components={['DatePicker', 'MobileDatePicker']}   >
                                                                    <MobileDatePicker disabled value={dayjs(dataEle?.py)} />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div className='link-iconn' >
                                                            <ExpandMoreIcon className='mt-2 arrow-icon' />
                                                        </div>
                                                    </div>
                                            }
                                        </td>
                                        <td className="border-top-0 border-bottom-0" >
                                            {
                                                ix !== 0 &&
                                                <Button
                                                    variant="outlined"
                                                    className="font-size-16 font-sans radius-6px text-capitalize  w-50  font-sans primaryy-border custom-common-theme-text-color font-size-16 custom-font-600"
                                                    onClick={() => HandleMapping(ix, keyElement)}
                                                >
                                                    {"Map"}
                                                </Button>
                                            }

                                        </td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <td className='pr-4 pl-3 pb-1 border-top-0 border-bottom-0'>
                                    <p
                                        className='text-secondary font-size-12 custom-font-500 font-inter cursor-pointer'
                                        onClick={() => AddNewTableRow(keyElement)}
                                    >
                                        + Add more items
                                    </p>
                                </td>
                                <td className='border-top-0 border-bottom-0'></td>
                                <td className='border-top-0 border-bottom-0'></td>
                                {
                                    !["payable", "receivable"].includes(keyElement) &&
                                    <td className='border-top-0 border-bottom-0'></td>
                                }
                            </tr>

                        </tbody>
                    </table>
                </Card>
            </div>

        </React.Fragment>
    );
};

export default LinkRegisterTable;
