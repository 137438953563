import { getAllOtherDetailsAdditional } from "../../../WorkSpace/Components/ApiServices/apiHandler";


export const fetchAdditionalDetailsData = (id) => {
    return dispatch => {
        dispatch({type:"ADDITIONALDETAILS_ACTION_START"})
        const allDetailsId=id;
        getAllOtherDetailsAdditional(allDetailsId).then((result)=>{
            dispatch({
                type: "ADDITIONALDETAILS_FETCH_DATA",
                payload: result.data?.other_items
            }) 
        }).catch((error)=>{
            dispatch({
                type: "ADDITIONALDETAILS_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}