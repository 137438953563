import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import { useDropzone } from 'react-dropzone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from "@mui/material/Button";
import swal from 'sweetalert';
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import SupportNav from "./SupportNav";
import uploadimage from "../../assets/imageupload.svg"
import { getProfile, removeSupportImage, ticketImageUpload, updateSupportTicket } from "../../services/api/apiHandler";
import { createTicket, getTicketById } from "../../WorkSpace/Components/ApiServices/apiHandler";
import "./support.css";
import LoaderTwo from "../../Components/Common/Loader/LoaderTwo";
import uploadgallery from "../../assets/uploadgallery.svg"
import AddMoreImagesModal from "./components/AddMoreImagesModal";
import ViewImageModal from "./components/ViewImageModal";
import CancelIcon from '@mui/icons-material/Cancel';
import SupportData from "./SupportDeatails";

const SupportContact = () => {
    const history = useHistory();
    const { contactId } = useParams();
    const dispatch = useDispatch();

    const [contactData, setContactData] = useState({
        userId: "",
        name: "",
        email: "",
        subject: "",
        description: "",
        organisation:"",
        images: []
    });
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isAddMoreImageModal, setIsAddMoreImageModal] = useState(false);
    const [moreImages, setMoreImages] = useState([]);
    const [isViewImage, setIsViewImages] = useState(false);

    useEffect(() => {
        if (contactId) {
            GetTicketById();
        } else {
            getProfileId();
        }
    }, []);

    const HandleChange = (event) => {
        const { value, name } = event.target;
        setContactData(prev => ({ ...prev, [name]: value }))
    }

    const getProfileId = () => {
        getProfile().then(response => {
            setContactData(prev => ({
                ...prev,
                userId: response.data?.user?.data?._id,
                email: response.data?.user?.data?.email,
                name: response.data?.user?.data?.name,
                organisation: response.data?.user?.data?.organisation
            }))
        }).catch(error => {
            swal("Error", error.response?.data?.error || "Error: User Data Not Found!", "error");
        })
    }

    const GetTicketById = () => {
        getTicketById(contactId).then(response => {
            const contactData = response.data?.data;
            console.log(contactData,"contactData---")
            const assign = contactData.assign_to
            if (contactData?.assign_to) {
                history.push(`/support-chats/${contactId}`);
            }
            setContactData(prev => ({
                ...prev,
                name: contactData?.name,
                description: contactData?.description,
                email: contactData?.email,
                subject: contactData?.subject,
                userId: contactData?.customer_id,
                images: contactData?.images,
            }))
        }).catch(error => {
            swal("Error", error.response?.data?.error || "Error: Contact Data Not Found!", "error");
        })
    }

    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles, "acceptedFiles-----")
        setImages(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

    const CreateTicket = () => {
        const formData = new FormData();
        formData.append('customer_id', contactData.userId);
        formData.append('name', contactData.name);
        formData.append('email', contactData.email);
        formData.append('subject', contactData.subject);
        formData.append('description', contactData.description);
        formData.append('status', 'Open');
        formData.append('added_by', 'customer');
        formData.append('priority', 'critical');
        formData.append('type', 'setup');
        images.forEach((image) => {
            formData.append('images', image);
        });

        setLoading(true);
        createTicket(formData).then(response => {
            toast.success("Ticket Created Successfully.");
            history.push("/support")
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            swal("Error", error.response?.data?.error || "Error: Ticket Create Failed!", "error");
        })
    };

    const UploadMoreImage = () => {
        const formData = new FormData();
        moreImages.forEach((image) => {
            formData.append('images', image);
        });
        formData.append('contactId', contactId);

        dispatch({ type: "SUPPORT_MORE_IMAGE_UPLOAD_LOADER", payload: true });
        ticketImageUpload(formData).then(response => {
            toast.success("Image Uploaded Successfully.");
            dispatch({ type: "SUPPORT_MORE_IMAGE_UPLOAD_LOADER", payload: false });
            setIsAddMoreImageModal(false);
            GetTicketById();
            setMoreImages([]);
        }).catch(error => {
            dispatch({ type: "SUPPORT_MORE_IMAGE_UPLOAD_LOADER", payload: false });
            setMoreImages([]);
            setIsAddMoreImageModal(false);
            swal("Error", error.response?.data?.error || "Error: Image Upload Failed!", "error");
        })
    }

    const RemoveSupportImage = (imageName, index) => {
        removeSupportImage({
            contactId,
            filename: imageName,
            index
        }).then(response => {
            toast.success("Image Removed Successfully.");
            GetTicketById();
        }).catch(error => {
            swal("Error", error.response?.data?.error || "Error: Remove Image Failed!", "error");
        })
    }

    const UpdateSupportTicket = () => {
        updateSupportTicket({
            contactId,
            name: contactData?.name,
            subject: contactData?.subject,
            description: contactData?.description,
        }).then(response => {
            toast.success("Support Contact Updated Successfully.");
            history.push("/support")
        }).catch(error => {
            swal("Error", error.response?.data?.error || "Error: Remove Image Failed!", "error");
        })
    }

    const handleDeleteImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    return (
        <>
            <SupportNav handleBack={() => history.push("/support")} />
             {
                 !contactData?.assign_to &&
                
            <div className='mb-4 d-flex flex-column align-items-center justify-content-center mt-4'>
                <label className='font-weight-bold font-size-20 font-sans d-flex justify-content-center'>Contact Us</label>
                <div style={{ width: "900px" }} className='bg-white radius-16px mb-4 p-4 mt-2'>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <label className='custom-text-black font-size-14 custom-font-700 mt-0 mb-0 font-sans'>Full name <span className="text-danger">*</span></label>
                            <input
                                className='new-textinput text-gray p-2 mt-2 w-100'
                                name="name"
                                value={contactData.name || contactData.organisation }
                                onChange={HandleChange}
                            />
                        </div>
                        <div className='col-12 col-md-6'>
                            <label className='custom-text-black font-size-14 custom-font-700 mt-0 mb-0 font-sans'>Email ID <span className="text-danger">*</span></label>
                            <input
                                className='new-textinput text-gray p-2 mt-2 w-100'
                                disabled
                                value={contactData.email}
                            />
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-12'>
                            <label className='custom-text-black font-size-14 custom-font-700 mt-0 mb-0 font-sans'>Subject <span className="text-danger">*</span></label>
                            <input
                                className='new-textinput text-gray p-2 mt-2 w-100'
                                name="subject"
                                placeholder='Enter Title Here'
                                value={contactData.subject}
                                onChange={HandleChange}
                            />
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-12'>
                            <label className=' font-size-14 custom-font-700 mt-0 mb-0 font-sans'>Describe problem <span className="text-danger">*</span></label>
                            <div className='mt-2'>
                                <textarea
                                    class="form-control"
                                    id="exampleFormControlTextarea1"
                                    className='w-100  p-3 text-gray describeproblem'
                                    placeholder='Enter Description'
                                    name='description'
                                    value={contactData.description}
                                    onChange={HandleChange}>
                                </textarea>
                            </div>
                        </div>
                    </div>


                    <div className='row mt-2'>
                        <div className='col-12'>
                            {  
                                contactId ? (
                                    <div className='w-100 radius-12px col-12 p-3 mt-3 '>
                                        <div className="d-flex align-items-center justify-content-between p-3">
                                            <div className="d-flex flex-column align-items-center" style={{ gap: "10px" }}>
                                                {
                                                    contactData.images.length > 0 && contactData.images.map((image, index) => (
                                                        <div className="d-flex justify-content-center align-items-center" key={index}>
                                                            <button
                                                                className="d-flex justify-content-center align-items-center rounded-circle border-0 custom-bg-white-150"
                                                                style={{ width: '45px', height: '45px' }}
                                                                onClick={() => RemoveSupportImage(image, index)}
                                                            >
                                                                <DeleteIcon style={{ width: "24px", height: "24px" }} />
                                                            </button>
                                                            <div
                                                                className="d-flex align-items-center ml-2 radius-10px custom-bg-white-150"
                                                                style={{ width: "300px", height: "60px" }}
                                                                onClick={() => {
                                                                    dispatch({ type: "SUPPORT_VIEW_DETAILS", payload: image });
                                                                    setIsViewImages(true);
                                                                }}
                                                            >
                                                                <span className='ml-2'><img src={uploadgallery} alt="uploadimage" style={{ width: "24px", height: "24px" }} /></span>
                                                                <p className='mt-0 mb-0 ml-2 font-size-12 text-secondary custom-font-500 font-inter'>{image?.filename.replace(/^rixosys\//, '')}</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <Button
                                                className='custom-common-theme-text-color font-size-16 text-capitalize custom-font-700  radius-12px font-sans custom-border'
                                                style={{ width: "170px", height: "60px" }}
                                                onClick={() => setIsAddMoreImageModal(true)}
                                            >
                                                Add more Images
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    images.length === 0 ?
                                        <div
                                            {...getRootProps()}
                                            className='w-100 h-100 borderdashed'
                                        >
                                            <input {...getInputProps()} />
                                            <img src={uploadimage} alt="upload" className="d-flex justify-content-center align-items-center w-100" height={50} width={50} />
                                            <p className="text-center font-sans font-size-16 custom-font-700 m-0 text-black">Select files or drag them here</p>
                                            <div className="w-100 d-flex align-items-center justify-content-center">
                                                <ErrorOutlineIcon style={{ color: "#939393" }} />
                                                <p className="text-center font-sans custom-font-500 m-0 ml-1 font-size-14" style={{ color: "#939393" }}>If you have any images to describe your problem well</p>
                                            </div>
                                        </div>
                                        :
                                        <div

                                            className='w-100 h-100 borderdashed2'
                                        >  
                                            {images.map((file, index) => (
                                                <div className="d-flex">
                                                    <img
                                                    key={index}
                                                    src={URL.createObjectURL(file)}
                                                    alt={`Selected ${index}`}
                                                    className="w-100 p-1"
                                                    style={{borderRadius:'16px'}}
                                                    height={"300px"}
                                                    // width={"180px"}

                                                />
                                                <CancelIcon style={{cursor:'pointer',padding:'1px'}} onClick={()=>handleDeleteImage(index)}/>
                                                </div>
                                                
                                            ))}
                                        </div>

                                )
                            }
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                        <Button
                            variant="contained"
                            className="font-size-16 custom-font-700 font-sans text-white text-capitalize radius-10px "
                            style={{
                                width: "360px",
                                height: "60px",
                                backgroundColor: "#467578",
                            }}
                            onClick={() => {
                                if (contactId) {
                                    UpdateSupportTicket();
                                } else {
                                    CreateTicket();
                                }
                            }}
                        >
                            {loading ? <LoaderTwo /> : contactId ? "Update" : "Send"}
                        </Button>
                    </div>
                </div>
            </div>
}
            <AddMoreImagesModal
                isAddMoreImageModal={isAddMoreImageModal}
                setIsAddMoreImageModal={setIsAddMoreImageModal}
                images={moreImages}
                setImages={setMoreImages}
                UploadMoreImage={UploadMoreImage}
            />

            <ViewImageModal
                setIsViewImages={setIsViewImages}
                isViewImage={isViewImage}
            />
                        
        </>
    )
}

export default SupportContact
