import React from 'react';
import { useSelector } from 'react-redux';
import moment from "moment";
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';



export function NBFC_31p3({data, subIndex, HandleChange, dis_index_id}) {
    const reduxProjectData = useSelector((initialState) => initialState.DisclosureReducer.projectData);


    return (
        <>
            <thead>
                <tr>
                    <th className='text-left w-75 pl-3 border-top-0 border-bottom-0 font-sans'>Particulars</th>
                    <th className='w-auto font-sans border-top-0 border-bottom-0 ' style={{minWidth:"110px"}}> Year ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto font-sans border-top-0 border-bottom-0 ' style={{minWidth:"110px"}} > Year ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <DisclosureNBFCTableHead isEmpty={false} />
                </tr>
            </thead>

            <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            <tr >
                                <td className='border-top-0 border-bottom-0' >
                                    <input 
                                        style={{backgroundColor:"inherit"}}
                                        className="p-0 m-0 w-100 border-0 pl-3 text-left font-sans"
                                        type={"text"}
                                        value={subDis?.header}
                                        name="header"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>

                                <td  className='border-top-0 border-bottom-0'>
                                    <input 
                                        style={{backgroundColor:"inherit"}}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right font-sans'
                                        type={"text"}
                                        value={formatIndianCurrency(subDis?.cy)}
                                        name="cy"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                    

                                </td>
                                <td  className='border-top-0 border-bottom-0'>
                                    <input 
                                        style={{backgroundColor:"inherit"}}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right font-sans'
                                        type={"text"}
                                        readOnly={typeof subDis?.py !== "number"}
                                        value={formatIndianCurrency(subDis?.py)}
                                        name="py"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                    isReference={subDis?.isReference}  keyValue={4} index={subIndex}  sub_index={subDisIndex} />
                            </tr>
                        </>
                    ))
                }
            </tbody>
        </>

    );
}