import React from 'react';
import PropTypes from 'prop-types';
import './commonCssSheet.css';

const CommonToolTip = ({ variant, placement, title, children }) => {
    const getClassName = () => {
        return `commontooltiptext ${variant} ${placement}`;
    };

    return (
        <div className="commontooltip">
            {children}
            <span className={getClassName()}> {title}</span>
        </div>
    );
};

CommonToolTip.propTypes = {
    variant: PropTypes.oneOf(['error', 'success', 'warning', 'primary']).isRequired,
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default CommonToolTip;
