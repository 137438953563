import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getBCTbRows, setIsAddSubGroupLabel3Modal } from '../../../redux/actions/BookClosure/actionBookClosure';
import AddSubGroupLabel3Modal from './AddSubGroupLabel3Modal';
import { useParams } from 'react-router';
import { mapLedger } from '../../Services/ApiHandler';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

const ThirdLabelData = ({
    dragData
}) => {
    const dispatch = useDispatch();
    const { bookClosureTbId } = useParams();

    const {
        thirdLabelData
    } = useSelector((initialState) => initialState.BookClosureTbcReducer);
    const {mappingChecked} = useSelector((state) => state.BookClosureReducer);

    const [addSubGroupRrquirement, setAddSubGroupRrquirement] = useState({
        level: null,
        levelOneName: "",
        levelTwoName: ""
    });

    const OnDropItem = (e, subGrp,targetColumn) => {
        e.preventDefault();
        const payload = {
            ledgerID: dragData._id,
            ...(mappingChecked && targetColumn === 'CY' ? {
                FSGroupCY: thirdLabelData?.groupCatName,
                notesGroupCY: thirdLabelData?.name,
                subGroupCY: subGrp?.name
            } : {}),
            ...(mappingChecked && targetColumn === 'PY' ? {
                FSGroupPY: thirdLabelData?.groupCatName,
                notesGroupPY: thirdLabelData?.name,
                subGroupPY: subGrp?.name
            } : {}),
            ...(!mappingChecked ? {
                FSGroupCY: thirdLabelData?.groupCatName,
                notesGroupCY: thirdLabelData?.name,
                subGroupCY: subGrp?.name,
                FSGroupPY: thirdLabelData?.groupCatName,
                notesGroupPY: thirdLabelData?.name,
                subGroupPY: subGrp?.name
            } : {})
        };

        mapLedger(bookClosureTbId, payload).then(response => {
            toast.success("Ledgers mapped successfully.");
            dispatch(getBCTbRows(bookClosureTbId));
        }).catch(error => {
            swal("Error", error?.response?.data?.error || "Mapping Ledger Failed!", "error");
        })

    };

    const onDragOverItem = (e) => {
        e.preventDefault();
        // This is necessary to allow a drop event
    };

    return (
        <>
            <table>
                <thead>
                    <tr className='bg-secondary-400' >
                        <th> {`Particulars (${thirdLabelData?.name})`} </th>
                        <th>As at June 1st, 2024</th>
                        <th>As at June 1st, 2023</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3} className='text-left pl-3 custom-bg-warning' > {thirdLabelData?.groupCatName} </td>
                    </tr>
                    {
                        thirdLabelData && thirdLabelData?.subgroup.length > 0 ?
                            thirdLabelData?.subgroup.map((subGrp, sx) => (
                                <React.Fragment key={sx}>
                                    <tr
                                        onDragOver={onDragOverItem}
                                        // onDrop={(e) => OnDropItem(e, subGrp)}
                                    >
                                        <td className='text-left pl-3' onDrop={(e) => OnDropItem(e, subGrp, 'CY')}> {subGrp?.name} </td>
                                        <td className='text-right pr-3' onDrop={(e) => OnDropItem(e, subGrp, 'CY')} > {subGrp?.currentYearAmount} </td>
                                        <td className='text-right pr-3'onDrop={(e) => OnDropItem(e, subGrp, 'PY')} > {subGrp?.previousYearAmount} </td>
                                    </tr>
                                    {
                                        sx === (thirdLabelData?.subgroup.length - 1) && (
                                            <>
                                                <tr>
                                                    <td className='text-left pl-3 custom-bg-gray-200'> Total </td>
                                                    <td className='text-right pr-3 custom-bg-gray-200' > { } </td>
                                                    <td className='text-right pr-3 custom-bg-gray-200' > { } </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colSpan={3}
                                                        className='text-left pl-3 custom-common-theme-text-color font-size-14 custom-font-700 cursor-pointer'
                                                        onClick={() => {
                                                            setAddSubGroupRrquirement(prev => ({
                                                                ...prev,
                                                                level: subGrp?.level,
                                                                levelOneName: thirdLabelData?.groupCatName,
                                                                levelTwoName: thirdLabelData?.name
                                                            }));
                                                            dispatch(setIsAddSubGroupLabel3Modal(true));
                                                        }}
                                                    >
                                                        + Add sub group
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                                </React.Fragment>
                            ))
                            : <p> 3rd Label Data Not Found. </p>
                    }
                </tbody>
            </table>

            <AddSubGroupLabel3Modal
                requirements={addSubGroupRrquirement}
                setRequirements={() => setAddSubGroupRrquirement({ ...addSubGroupRrquirement, level: null, levelOneName: "", levelTwoName: "" })}
            />
        </>
    )
}

export default ThirdLabelData;
