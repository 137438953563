import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setNoteNumber } from "../../helper/calculation";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { PNLTableDND } from "./TableDND/Table";

const PNLStatement = (props) => {
  const { projectData, setTempValue, setAddNoteGrp } = props;
  const [no, setNo] = useState([]);
  const { isSuccess, response, error_message, loading } = useSelector((state) => state.TempTabPNLReducer);
  const rows = response?.pnl_statement ?? [];
  const fsRows = Object.keys(rows);
  const [consoleNotes, setConsoleNotes] = useState([])


  useEffect(() => {
    setNo(setNoteNumber(fsRows, rows));
  }, []);

  const getIndexNumber = (line) => {
    try {
      let index = no.findIndex(
        (note) =>
          note?.notes_grp.toLowerCase() === line?.notes_grp.toLowerCase()
      );
      return index;
    } catch (err) {
      console.log("error", err.message);
      return "00";
    }
  };

  const getNoteNoIndex = (notes_no) => {
    try {
      if(response.company_type === 'NBFC'){
        return parseInt(notes_no)
      }
      let resultIndex = 0;
      Object.keys(consoleNotes).forEach(function (key, index2) {
        let index = consoleNotes[key].find(
          (data) => data.note_number === notes_no
        );
        if (index !== undefined) {
          resultIndex = index.index;
        }
      });
      return resultIndex;
    } catch (error) {
      console.log("error", error.message);
      return "00";
    }
  };

  const TotalNoteGroupsValue = (group, type) => {
    try{
      if(!["cy", "py"].includes(type))return 0;
      return response.pnl_statement[group].reduce((num, acc)=> num + acc[type], 0);
    }catch(err){
      console.log("err total notegrp", err);
      return 0
    }
   
  }

  function handleClick(key) {
    setTempValue(key);
  }

  return (
    <>
    {loading ? (
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="grow" size="sm" />
        <Spinner animation="grow" style={{ margin: "1rem" }} />
        <Spinner animation="grow" size="sm" />
      </div>
    ) : isSuccess ? (
      <div className="tableSheet">
        <PNLTableDND
          headerData={['Particulars', 'Notes', `As at ${moment(projectData.current_year).format("DD MMMM YYYY")}`, `As at ${moment(projectData.previous_year).format("DD MMMM YYYY")}`]}
          groupData = {fsRows}
          tableData={rows}
          handleClick={handleClick}
          TotalNoteGroupsValue={TotalNoteGroupsValue}
          companyType={response.company_type}
          setAddNoteGrp={setAddNoteGrp}
        />
      </div>
    ) :
    (
      <p className="text-danger">
        {error_message || "Something went wrong!!!"}
      </p>
    )
  }
    </>
  );
};

export default PNLStatement;

