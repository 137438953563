import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../../Components/Notes/notes.css";
import { useDispatch, useSelector } from "react-redux";
import { setNoteNumber } from "../../../helper/calculation";
// import Note4B from './Note4B'
// import Note3 from './Note3'
import ConsolePattern1 from "../consoleTabs/ConsolePattern1";
import ConsolePattern2 from "./ConsolePattern2";
import ConsolePattern3 from "./ConsolePattern3";
import { fetchPCData } from "../../../redux/actions/actionPreset";
import { getAllIR } from "../../../WorkSpace/Components/ApiServices/apiHandler";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    minHeight: "50vh",
  },
  tabs: {
    maxHeight: "100vh",
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: "3rem",
    paddingBottom: "3rem",
    background: "linear-gradient(45deg, transparent, #E5EEEE)",
    color: "Black",
    fontSize: "1rem",
    width: "260px",
  },
  tab: {
    padding: "12px 12px",
    overflow: "hidden",
    position: "relative",
    fontSize: "5px",
    boxSizing: "border-box",
    minHeight: "55px",
    textAlign: "center",
    flexShrink: 0,
    fontWeight: "500",
    letterSpacing: "0.02857em",
    textTransform: "none",
  },
  TabPanel: {
    width: "100%",
  },
}));


const ConsoleBalanceNotes = (props) => {
  const {
    consoleNotes,
    balanceSheetData,
    groupingData,
    consoleData,
    allcheckdata,
    tab,
    pnlStatementData,
    currentCompProjectId,
    checkKey,
    subsiaryData,
    id,
    CompanyName
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [no, setNo] = useState([]);
  const [value, setValue] = useState("");
  const [math, setMath] = useState("");
  const NoteNo = useSelector((initialState) => initialState.reducer.blNoteNo);
  const subsidiaryData = useSelector((state) => state.SubsidiaryConsoleReducer);

  const handleChange = (event, newValue) => {
    // console.log(newValue, "tab index", event);
    setValue(newValue);
  };

  const { loading, isSuccess, response, error_message } = balanceSheetData;
  const currentProjectBs = response?.data?.balance_sheet?.balance_sheet ?? [];
  const fsRows = Object.keys(currentProjectBs);
  const fetchPrepareConsole = useSelector((state) => state.PCReducer);
  const [allIrList, setAllIr] = useState([]);
  const [irOtherDetails, setIrOtherDetails] = useState([]);

  useEffect(() => {
    dispatch(fetchPCData(id));
    AllIrData();
    setValue(checkKey);
    setNo(setNoteNumber(fsRows, currentProjectBs));
  }, [checkKey]);

  const AllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIr(res.data.fetchIR);
      setIrOtherDetails(res.data?.IrOtherDetails);
    }
  };
  const FindIr = (ir_id, isOtherDetails = false) => {
    if (isOtherDetails) {
      const IrOtherDetailData = irOtherDetails.filter((data) => data._id === ir_id);
      return IrOtherDetailData;
    }
    const IrData = allIrList.filter((data) => data._id === ir_id);
    return IrData;
  };
  const browse_fs =
    fetchPrepareConsole?.response?.getOnePreCompany[0]?.consolidation.map(
      (data) => FindIr(data.Ir_id)
    );


  const getIndexNumber = (line) => {
    try {
      let index = no.findIndex(
        (note) =>
          note?.notes_grp.toLowerCase() === line?.notes_grp.toLowerCase()
      );
      return (index += 2);
    } catch (err) {
      // console.log("error", err.message);
      return "00";
    }
  };
  return (
    <>
      <div className={classes.root}>
        <Tabs
          style={{ minWidth: "260px",float:"left" }}
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          {fsRows.map((group, groupIndex) => {
            return currentProjectBs[group].map((noteGroup, noteGroupIndex) => (
              <Tab
                className={classes.tab}
                label={"Note - " + noteGroup.notes_grp}
                value={Number(getIndexNumber(noteGroup))}
                {...a11yProps(noteGroupIndex)}
              />
            ));
          })}
        </Tabs>

        {Object.keys(consoleNotes).map((data, key) => (
          <div key={key}>
            {data === "pattern_1" ? (
              <>
                {consoleNotes[data].map((data, key) => (
                  <TabPanel
                    key={key}
                    className={classes.tab}
                    value={value}
                    index={data.index}
                  >
                    <ConsolePattern1
                      subsidiaryData={subsidiaryData}
                      note_no={data.note_number}
                      groupingData={groupingData}
                      rows={balanceSheetData?.balance_sheet?.balance_sheet}
                      currentCompany={consoleData}
                      // headingData={browse_fs.map(
                      //   (data) => data.all_data.company
                      // )}
                      headingData={browse_fs.map(
                        (data) => data[0]?.company
                      )}
                      subsiary={balanceSheetData?.balance_sheet?.balance_sheet}
                      sybsidiary={[
                        balanceSheetData?.balance_sheet?.balance_sheet,
                      ]}
                      finalSubsidiaryData={balanceSheetData?.project_ids_bs?.map(
                        (data) => data.balance_sheet
                      )}
                      subsiaryData={allcheckdata}
                      browsFs={[]}
                      current_tab={tab}
                      pnlStatementData={pnlStatementData}
                      currentCompProjectId={currentCompProjectId}
                      FindIr={FindIr}
                      CompanyName={CompanyName}
                    />
                  </TabPanel>
                ))}
              </>
            ) : data === "pattern_2" ? (
              <>
                {consoleNotes[data].map((data, key) => (
                  <>
                    <TabPanel key={key} value={value} index={data.index}>
                      <ConsolePattern2
                        note_no={data.note_number}
                        group={0}
                        groupingData={groupingData}
                        currentCompany={consoleData}
                        subsiaryData={allcheckdata}
                        headingData={browse_fs.map(
                          (data) => data[0]?.company
                        )}
                        currentCompProjectId={currentCompProjectId}
                        current_tab={tab}
                        FindIr={FindIr}

                      />
                    </TabPanel>
                  </>
                ))}
              </>
            ) : data === "pattern_3" ? (
              <>
                {consoleNotes[data].map((data, key) => (
                  <TabPanel key={key} value={value} index={data.index}>
                    <ConsolePattern3
                      note_no={data.note_number}
                      groupingData={groupingData}
                      currentCompProjectId={currentCompProjectId}
                      currentCompany={consoleData}
                      subsiaryData={allcheckdata}
                      headingData={browse_fs.map(
                        (data) => data[0]?.company
                      )}
                      subsidiaryGrouping={
                        groupingData?.response?.data?.browse_fs_grouping ?? []
                      }
                      browsFsGrouping={
                        groupingData?.response?.data?.browse_fs_grouping ?? []
                      }
                      finalSubsidiaryData={
                        groupingData?.response?.data?.project_ids_grouping ?? []
                      }
                      pnlStatementData={pnlStatementData}
                      current_tab={tab}
                      FindIr={FindIr}

                    />
                  </TabPanel>
                ))}
              </>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ConsoleBalanceNotes;
