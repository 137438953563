import { useEffect, useState } from 'react'
import axios from 'axios'

const useFetchApi = (url) => {
    const [loading, setLoading] = useState(true)
    const [response, setResponse] = useState(null)
    const [error, setError] = useState(null)
    const auth = localStorage.getItem('auth_token')
    let headers = {
        'x-auth-token': auth,
    }
    const fetchApi = () => {
        setLoading(true)
        axios.get(url, { headers }).then((res) => {
            // console.log(res.data)
            setLoading(false);
            setResponse(res)
            
        }).catch((err) => {
            let errorData = err?.response?.data?.error ? err?.response?.data?.error :
                                err?.response?.data?.message ? err?.response?.data?.message : 
                                    "Error happening at the time of getting data, please try after some time.";
            setError(errorData)
            setLoading(false);
        })
    };
    useEffect(() => {
        fetchApi();
    }, []);
    return { loading, response, error }
}

export default useFetchApi