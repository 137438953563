import React from 'react'
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {Dialog,DialogContent,Typography} from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const SelectSheetType = ({ open, setOpen , setsingleFormat, presetId}) => {
  let history = useHistory();
  
  const SelectType = (type) => {
    setOpen(false);
    setsingleFormat(true);
    history.push(`/preset/upload-tb/${type}/${presetId}`);
  };

  return (
    <>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <div className="d-flex justify-content-center mt-4 font-sans"><h1>Choose One</h1></div>
          <div className="d-flex justify-content-between mt-3">
            <button onClick={()=>SelectType('single')} className="card ml-5 w-50 mr-3 py-3">
              <div className="card-body ">
                <Typography className="card-text text-center">Uploaded as Multiple excel files</Typography>
              </div>
            </button>
            <button onClick={()=>SelectType("multi")} className="card mr-5 w-50 ml-3 py-3">
              <div className="card-body">
                <Typography className="card-text text-center">Uploaded as Multiple excel Sheets</Typography>
              </div>
            </button>
          </div>

          <Typography align='center' className='custom-common-theme-text-color mb-4'>
            <i className="fas fa-info-circle icon-info mr-2 mt-5"></i>
            Make sure only one sheet is present if one file is uploaded at a time
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  )
}

export default SelectSheetType