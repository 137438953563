// import { red } from "@mui/material/colors";
import "../../SignUp2.css";
import { Stack, Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
// import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import { Navbar } from "../../../Components/Navbar";
import {
  cities,
  createCompany,
  getCompany,
  multiCompany,
  state,
} from "../ApiServices/apiHandler";
import useFetchApi from "../../../services/CustomHook/useFetchApi";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import swal from "sweetalert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import EmptyCompanyModal from "../Modal/emptyCompanyModal";
import { InputAdornment } from "@material-ui/core";
import AddNewCompany from "../Modal/addNewCompany";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style5 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 589,
  height: 751,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const Page3 = (props) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const permissions = JSON.parse(localStorage.getItem('permission'));
  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };
  const [searchTerm, setSearchTerm] = useState([]);
  const [findData, setFindData] = useState(false);
  const [multipleCompany, setMultipleCompany] = useState([]);
  const [company, setAllCompany] = useState([]);
  const [checkContinueBotton, setCheckContinueBotton] = useState(false);
  const [Open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [newcompany, setCompany] = useState({
    org_name: "",
    ci_no: "",
    pan_no: "",
    nature_of_company: "",
    functional: "",
    city: "",
    state: "",
    address: "",
  });
  const [approver,setApprover]=useState({
    approver_id:""
  });
  const [allcities, setCitiesData] = useState([]);
  const [allstate, setStateData] = useState([]);
  const [searchcomapny,setSearchComapny]=useState([]);
  useEffect(() => {
    allCompany();
    allMultiCompany();
  }, []);

  const allMultiCompany = async () => {
    const res = await getCompany();
    if (res.status === 200) {
      const allCompanyData = res?.data?.getCompany?.map(
        (data) => data.company_name
      );
      setMultipleCompany(allCompanyData);
    } else {
      console.log("error");
    }
  };

  const handleChange = (value,field) => {
    if (value.length > 0) {
      setCheckContinueBotton(true);
    }
    const checkData = multipleCompany.filter((det) => det.toLowerCase() === value.toLowerCase());
    if (checkData.length > 0) {
      setFindData(true);
      setSearchComapny(checkData);
    } else {
      setFindData(false);
    }
    setSearchTerm(value);
  };

  const allCompany = async () => {
    const dataAll = await getCompany();
    console.log(dataAll);
    const allCompanyData = dataAll?.data?.getCompany?.map(
      (data) => data.company_name
    );
    setAllCompany(allCompanyData);
  };
  const handleSave = async () => {
    // let data = {
    //   companyName: searchTerm,
    // };

    // const res = await multiCompany(data);
    // if (res.status === 200) {
    //   props.history.push("/dashboard");
    // } else {
    //   console.log("failed");
    // }
    props.history.push("/dashboard");
  };
  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };
  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };
  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };
  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };
  const skipNow = async () => {
    props.history.push("/dashboard");
  };

  const onInputChangeCompany = (event, value) => {
    if (value && value.length > 3) {
      // allCompany();
    } else {
      setAllCompany([]);
    }
  };
  const handleApproverChange = (value,field) => {
    setApprover({...approver,[field]: value});
  };

  const handleChangeData = (value, field) => {
    setCompany({ ...newcompany, [field]: value });
  };
  const submitCompany = async () => {
    let company_code =
      newcompany.org_name.slice(0, 3) + Math.floor(1000 + Math.random() * 9000);
      let data={}
      if(user.responsibilities==="preparer"){
        data = {
          companyName: newcompany.org_name,
          companyCode: company_code,
          ci_no: newcompany.ci_no,
          pan_no: newcompany.pan_no,
          nature_of_company: newcompany.nature_of_company,
          functional: newcompany.functional,
          city: newcompany.city,
          state: newcompany.state,
          address: newcompany.address,
          has_published:"draft",
          status:"waiting",
          approver_id:approver.approver_id,
          preparer_id:user._id
        };
      }else{
         data = {
          companyName: newcompany.org_name,
          companyCode: company_code,
          ci_no: newcompany.ci_no,
          pan_no: newcompany.pan_no,
          nature_of_company: newcompany.nature_of_company,
          functional: newcompany.functional,
          city: newcompany.city,
          state: newcompany.state,
          address: newcompany.address,
          has_published:"published",
        };
      }
    const result = await createCompany(data);
    if (result.status === 200) {
      handleClose();
      allCompany();
    }
    console.log(result.data);
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  };

  return (
    <>
      <div>
        {/* <NavBarV2 /> */}
        <Navbar />
        <div className="backk">
          <ArrowBackIcon className="iconn" />
          <p>Back</p>
        </div>
        <div className="companies">
          <h2 className="text">What companies do you</h2>
          <h2 className="text">work with?</h2>
        </div>
        <div className="paragraph">
          <div className="fontsize">
            With MyFinalyst, you can easily create and audit financial
          </div>
          <div className="fontsize">
            statements for your company or clients.
          </div>
        </div>
        <div className="searchbar">
          <TextField
            className="searchbox"
            id="search"
            type="search"
            placeholder="Type a company name or search"
            // defaultValue={searchTerm}
            onChange={(e) => handleChange(e.target.value, "search")}
            multiline
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {searchTerm.length > 0 ?
        findData === false ? (
          <div className="figma">
            <p style={{ Color: "#03565A", fontSize: 16 }}>
            "{searchTerm}" Does not exist. Click to add a new entry
            </p>
            <AddIcon
              style={{
                height: 35,
                width: 35,
                color: "#03565A",
                marginTop: 5,
                cursor: "pointer",
              }}
              onClick={() => {
                if (user.responsibilities === "approver") {
                  swal("Warning", "You Don't have Access to Create Company ", "warning");
                }else if(permissions?.unrestrictedAcessData[0]?.permission?.create === true || permissions === null ){
                  setOpen(true);
                }else{
                  swal("Warning", "You Don't have Access to Create Company ", "warning");
                }
              }}
            />
          </div>
        ) : (
          <div className="figma">
          {/* "{searchTerm}" already exist. */}
          <Chip
              className="font-sans"
              label={searchcomapny}
             />
        </div>
        ):""}

        <div
          style={{
            marginLeft: "5%",
            marginTop: 10,
          }}
        >
          {company.length > 0 ? (
            <h1 style={{ fontSize: 15 }}> You’ve added companies so far -</h1>
          ) : (
            ""
          )}
        </div>

        <div className="ml-5">
          <div className="chip w-100 p-2 mt-1">
            <Stack
              direction="row"
              spacing={1}
              style={{ marginLeft: "18px", flexWrap: "wrap" }}
            >
              {company &&
                company.slice(-5).map((data) => (
                  <Chip
                    key={data}
                    label={data}
                    // onDelete={handleDelete}
                    className="mb-2 font-sans"
                  />
                ))}
                {company&&company.length>5?
            <div className="mt-1 font-sans font-weight-bold">& {company.length-5} more. </div>:""}
            </Stack>
          </div>
        </div>


        <div className="button">
          {checkContinueBotton ? (
            <Button
              className="continue"
              variant="contained"
              onClick={handleSave}
            >
              Continue
            </Button>
          ) : (
            <Button className="continue2" variant="contained">
              Continue
            </Button>
          )}

          <Button
            style={{
              border: "2px solid #03565A",
              fontWeight: 540,
              borderRadius: "7px",
            }}
            className="btnsize"
            variant="contained"
            onClick={skipNow}
          >
            Skip for now
          </Button>
        </div>
        {/* modal */}
        {/* <Modal
          open={Open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style5}>
           

            <center>
              <strong>Enter Company Details</strong>
            </center>
            <div
              className="password-signin"
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#696F79",
              }}
            >
              <strong>Enter your organization name</strong>
              <input
                name="org_name"
                type="text"
                onChange={(e) => handleChangeData(e.target.value, "org_name")}
              />
            </div>
            <div
              className="password-signin"
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#696F79",
              }}
            >
              <strong>CIN No.</strong>
              <input
                type="text"
                onChange={(e) => handleChangeData(e.target.value, "ci_no")}
              />
            </div>
            <div
              className="password-signin"
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#696F79",
              }}
            >
              <strong>PAN/TAN No.</strong>
              <input
                type="text"
                onChange={(e) => handleChangeData(e.target.value, "pan_no")}
              />
            </div>
            <div
              className="password-signin"
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#696F79",
              }}
            >
              <strong>Funtional currency</strong>
              <select
                onChange={(e) =>
                  handleChangeData(e.target.value, "nature_of_company")
                }
                style={{
                  height: "60px",
                }}
              >
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Select..."}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Manufacturing"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"NBFC"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Banking"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Trading"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Other Specify"}
                </option>
              </select>
            </div>
            <div
              className="password-signin"
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#696F79",
              }}
            >
              <strong>Funtional currency</strong>

              <select
                onChange={(e) => handleChangeData(e.target.value, "functional")}
                style={{
                  height: "60px",
                }}
              >
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Select..."}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"INR"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"USD"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"LKR"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"AUD"}
                </option>
              </select>
            </div>
            <div className="d-flex mt-4" style={{ color: "#696F79" }}>
              <strong>Select City</strong>
              <strong style={{ marginLeft: "221px" }}>Select State</strong>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "12px",
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={!allcities ? [] : allcities}
                onChange={(e, value) => handleChangeData(value, "city")}
                onInputChange={onInputChangecity}
                sx={{ width: 350, backgroundColor: "white" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=<LocationOnIcon />
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        border: "none",
                      },
                    }}
                  />
                )}
              />

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={!allstate ? [] : allstate}
                onChange={(e, value) => handleChangeData(value, "state")}
                onInputChange={onInputChange}
                sx={{ width: 350, marginLeft: 5, backgroundColor: "white" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=<LocationOnIcon />
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div
              className="password-signin"
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#696F79",
              }}
            >
              <strong>Address</strong>
              <textarea
                onChange={(e) => handleChangeData(e.target.value, "address")}
              />
            </div>
            <div className="mt-2">
              <input
                type="button"
                className="btn btn-primary1"
                value="Submit"
                onClick={submitCompany}
              />
              <input
                type="button"
                className="btn btn-defult"
                value="Close"
                onClick={handleClose}
              />
            </div>
          </Box>
        </Modal> */}

        <AddNewCompany
          open={Open}
          handleClose={handleClose}
          handleChangeDataSubmit={handleChangeData}
          submitCompany={submitCompany}
          searchTerm={searchTerm}
          handleApproverChange={handleApproverChange}
        />
      </div>
    </>
  );
};

export default Page3;
