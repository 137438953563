const PCTypes = {
    PC_ADD_DATA: "PC_ADD_DATA",
    PC_ADD_COMPANY: "PC_ADD_COMPANY",
    PC_FETCH_DATA:"PC_FETCH_DATA",
    PC_FETCH_DATA_FAILED:"PC_FETCH_DATA_FAILED",
    PC_ACTION_START:"PC_ACTION_START"
}


const Initial_state = {
    loading:false,
    checkData: [],
    companyData: [],
    isSuccess: false,
    response: []
}

const PCReducer = (state=Initial_state, action) => {
    switch(action.type){
        case PCTypes.PC_ADD_DATA:{
            return {
                ...state, 
                checkData: action.payload
            };
        }
        case PCTypes.PC_ADD_COMPANY: {
            return {
                ...state,
                companyData: action.payload
            }
        }
        case PCTypes.PC_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case PCTypes.PC_FETCH_DATA: 
        return {
            ...state,
            loading: false,
            isSuccess: true,
            response: action.payload.data,
            set_payload: action.payload.data
        }
        case PCTypes.PC_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }
        default:
            return {...state}
    }
}

export default PCReducer;