import React, { useEffect, useRef, useMemo } from 'react';
import { useTable, useRowSelect } from "react-table";
import { columnBaseTable } from './tableHeaderTbc';
import { useDispatch } from 'react-redux';
import { setSelectedFlatRows } from '../../../redux/actions/BookClosure/actionBookClosure';

const TbcTableData = React.memo(({ lineItems, setDragData }) => {
    const dispatch = useDispatch();
    const data = useMemo(() => lineItems, [lineItems]);
    const columns = useMemo(() => columnBaseTable, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ]);
        }
    );

    const selectedRowsMemo = useMemo(() => selectedFlatRows.map(row => row.original), [selectedFlatRows]);

    useEffect(() => {
        dispatch(setSelectedFlatRows(selectedRowsMemo));
    }, [selectedRowsMemo, dispatch]);

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    key={column.id}
                                    className="font-inter"
                                    style={column.Style}
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, ind) => {
                        prepareRow(row);
                        return (
                            <tr 
                                {...row.getRowProps()} className={row.original.deleted ? "bg-danger" : row.original?.FSGroupCY ? "bg-warning-100" : ""} 
                                draggable
                                onDragStart={(e) => {
                                    setDragData(row.original);
                                }}
                            >
                                {row.cells.map((cell, i) => (
                                    <td
                                        key={cell.column.id}
                                        {...cell.getCellProps()}
                                        className={`${
                                            ['ledgerCode', 'ledgerName'].includes(cell.column.id) ? "text-left pl-3" : 
                                            "selection" === cell.column.id ? "text-center" :
                                            "text-right pr-3"
                                        }`}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
});

export default TbcTableData;

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <input type="checkbox" ref={resolvedRef} {...rest} />
        );
    }
);
