import { getPrepareConsole } from "../../Console/Component/services/apiHandler";

export const fetchPCData = (c_id) => {
    return dispatch => {
        dispatch({type:"PC_ACTION_START"})
        getPrepareConsole(c_id).then((result)=>{
            console.log(result.data,"result.data");
            dispatch({
                type: "PC_FETCH_DATA",
                payload: {data: result.data}
            })
        }).catch((err)=>{
            dispatch({
                type:"PC_FETCH_DATA_FAILED",
                payload: err?.response?.data?.error || 'Something went wrong'
            })
        })        
    }
}



export const setAllChecksPreset = (data) => {
    return {
        type: "PC_ADD_DATA",
        payload: data
    }
}