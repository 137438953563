import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Select from "react-select";
import "./templateTabs.css";
import BalanceSheet from "./BalanceSheet";
import PnlStatement from "./PnlStatement";
import Pattern2 from "./Pattern2";
// import { filterOptionsData } from "../../Console/Component/services/mockHandler";
import { useSelector, useDispatch } from "react-redux";
import { fetchTTBSData } from "../../redux/actions/TemplateTabs/actionBS";
import { fetchTTPNLData } from "../../redux/actions/TemplateTabs/actionPNL";
import { getAllProjects } from "../../services/api/apiHandler";
import { fetchNotesData } from "../../redux/actions/actionCN";
import { fetchTTGroupData } from "../../redux/actions/TemplateTabs/actionGrouping";


const shortcut = {
  color: "var(--clr-accent)",
  fontWeight: "600",
  fontSize: "1rem",
  marginLeft: "1rem",
  cursor: "pointer",
  borderRadius: "50%",
  border: "2px solid var(--clr-accent)",
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const TemplateTabs = (props) => {
  // const {checkYearly, selectedRow} = useSelector((state)=> state.selectedDragReducer);
  const { setAddNoteGrp } = props;
  const [group, setGroup] = useState();
  const [rows, setRows] = useState([]);
  const [hideData, setHideData] = useState([]);
  const [hideChildData, sethideChildData] = useState([]);

  const [filterData, setFilterData] = useState([]);
  const [ notes, setNotes ] = useState([]);
  const [projectData, setProjectData] = useState({});
  const bsData = useSelector((state) => state.TempTabBSReducer);
  const pnlData = useSelector((state)=>state.TempTabPNLReducer);
  const consoleNotes = useSelector((state) => state.CNReducer);
  const groupingData = useSelector((state) => state.TTGroupingReducer)
  const dispatch = useDispatch()

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const [value, setValue] = useState(0);
  const R_tb_id = useSelector((initialState) => initialState.reducer.tb_id);
  const LOCAL_tb_id = localStorage.getItem("tb_id");
  const project_id = localStorage.getItem("project_id");
  let tb_id = R_tb_id ? R_tb_id : LOCAL_tb_id;

  const [isHide, setHide] = useState(false);

  useEffect(()=> {
    (async() => {
      // await getFilterNotesData();
      await fetchBalanceSheetData();
      await fetchPNLSheetData();
      await allProjectsData();
      await fetchGroupingData();
    })()
  },[])

  useEffect(()=> {
    if(groupingData.isSuccess){
      const res = groupingData?.response?.fs_grp?.map((grpData)=>grpData?.notes_grp.map(note => { return {
        'value': note.notes_index,
        'label': note.notes_grp
      }}))
      setFilterData(res.flat())
    }
  }, [groupingData])

  
  useEffect(() => {
    setRows([]);
    setHide(false);

  }, [value, props.updateTabs]);


  useEffect(()=> {
    if(!consoleNotes.isSuccess){
      dispatch(fetchNotesData(project_id));
    }else{
      setNotes([...consoleNotes.response])
    }
  }, [consoleNotes.response])

  // const getFilterNotesData = async () => {
  //   if (filterData.length === 0) {
  //     const filter_res = await filterOptionsData();
  //     setFilterData(filter_res.data);
  //   }
  // }

  const fetchBalanceSheetData = async () => {
    if(!bsData.isSuccess || bsData.set_payload !== JSON.stringify({project_id, tb_id})){
      dispatch(fetchTTBSData(project_id, tb_id))
    }
  }

  const fetchPNLSheetData = async () => {
    if(!pnlData.isSuccess || pnlData.set_payload !== JSON.stringify({project_id, tb_id})){
      dispatch(fetchTTPNLData(project_id, tb_id))
    }
  }

  const fetchGroupingData = async () => {
    if(!groupingData.isSuccess || groupingData.set_payload !== JSON.stringify({project_id, tb_id})){
      dispatch(fetchTTGroupData(project_id, tb_id))
    }
  }

  const allProjectsData = async () => {
    try {
      if(projectData && Object.keys(projectData).length > 0) return;
      const result = await getAllProjects();
      const resultData = result.data.project_list.find((item) => item._id === project_id);
      setProjectData(resultData);
    } catch (error) {
      console.log("issue", error.message)
    }
  }

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  function handleSelect(e) {
    if (e == null) {
      return;
    }
    setValue(e.value);
  }

  useEffect(() => {
    // setRows([])
    if (hideData.length > 0) {
      hideData.map((hidedata) => {
        delete rows[hidedata];
      });

    
    } else {
    }
  }, [isHide]);

  const handleEye = () => {
    if (isHide) {
      setHide(false);
      // fetchBalanceSheet();
    } else {
      setHide(true);
    }
  };

  const handleOptions = (o) => {
    setRows([]);
    if (o === 0) {
      setValue(0);
    } else {
      setValue(1);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div>
        <table>
          <thead>
            <tr>
              <th style={{ width: "60%", height: "60px" }}>
                <Select
                  as="select"
                  options={filterData}
                  placeholder="Select the notes -"
                  className="react-abc"
                  isClearable
                  onChange={(e) => {
                    handleSelect(e);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: "6px",
                    zIndex: "0",
                    colors: {
                      ...theme.colors,
                      borderColor: "white",
                      backgroundColor: "white",
                      primary25: "#03565a98",
                      primary: "#03565A",
                    },
                    spacing: {
                      ...theme.spacing,
                      // controlHeight: 20,
                    },
                  })}
                />
              </th>
              <th style={{}}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    onClick={(e) => {
                      handleOptions(0);
                      // setValue(0);
                    }}
                    style={{...shortcut, backgroundColor: value === 0 ? '#17a2b8' : 'inherit'}}
                  >
                    BS
                  </div>
                  <div
                    onClick={(e) => {
                      handleOptions(1);
                      // setValue(1);
                    }}
                    style={{...shortcut, backgroundColor: value === 1 ? '#17a2b8' : 'inherit'}}
                  >
                    PNL
                  </div>
                  {/* <div
                    onClick={(e) => {
                      handleEye();
                    }}
                    style={shortcut}
                  >
                    {!isHide ? (
                      <i class="far fa-eye-slash"></i>
                    ) : (
                      <i class="far fa-eye"></i>
                    )}
                  </div> */}
                  <div></div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    <Box className="MuiBox-rootTab" sx={{ width: "100%" }}>
        <TabPanel style={{ margin: "0", padding: "0" }} value={value} index={0}>
          <BalanceSheet
            setTempValue={setValue}
            setGroup={setGroup}
            rows={bsData?.response?.balance_sheet}
            projectData={projectData}
            setHideData={setHideData}
            hideData={hideData}
            hideChildData={hideChildData}
            setAddNoteGrp={setAddNoteGrp}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PnlStatement
            setTempValue={setValue}
            rows={pnlData?.response?.pnl_statement}
            projectData={projectData}
            setHideData={setHideData}
            hideData={hideData}
            hideChildData={hideChildData}
            setAddNoteGrp={setAddNoteGrp}
          />
        </TabPanel>
        {
          notes.map((data, key) => (
            <TabPanel key={key} value={value} index={data.note_index}>
                <Pattern2
                  SetNewSg={props.SetNewSg}
                  setAddGrouping={props.setAddGrouping}
                  note_no={data.note_number}
                  note_name={data.note_name}
                />
            </TabPanel>
          ))
        }
    </Box>
    </div>
  );
};

export default TemplateTabs;
