import React from 'react'
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Table } from 'react-bootstrap';
import IssuedCapitaltable from './IssuedCapitaltable';
import KeyTable from './KeyTable';





const ShareCapital = () => {

  
    return (
        <>
            <div className="mt-3 container">
                <Card className="w-100 mb-4 radius-8px">
                    <div className='px-3 '>
                        <div className='d-flex justify-content-between w-75 mt-3'>
                            <p className='mt-0 mb-0 ml-2 font-size-16 custom-font-600'>Authorized Capital</p>
                            <Button
                                variant="contained"
                                className='bg-white custom-border radius-6px custom-common-theme-text-color  font-size-16 custom-font-600 text-capitalize radius-6px font-sans'
                                style={{ width: "102px", height: "40px" }}

                            >
                                Add/Edit
                            </Button>
                        </div>
                        {/* card and table */}
                        <div className='mt-2 w-75'>
                            <Card className='w-100 radius-8px' style={{ border: "2px solid #D6D6D6", height: "90px" }}>
                                <Table>
                                    <thead style={{ backgroundColor: "#F4F6F6" }}>
                                        <tr>
                                            <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter'>Class of share</th>
                                            <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter'>Number of shares</th>
                                            <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter'>Face value</th>
                                            <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter'>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ backgroundColor: "white" }}>
                                            <td className='text-left font-size-12 custom-font-600'>
                                            </td>
                                            <td className='text-left font-size-12 custom-font-600'></td>
                                            <td className='text-left font-size-12 custom-font-600'></td>
                                            <td className='text-left font-size-12 custom-font-600'></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card>

                        </div>

                    </div>
                    {/* 2nd table */}
                    <div className='px-3 mb-3'>
                        <div className='d-flex justify-content-between w-100 mt-3'>
                            <p className='mt-0 mb-0 ml-2 font-size-16 custom-font-600'>Issued Capital</p>
                            <Button
                                variant="contained"
                                className='bg-white custom-border radius-6px custom-common-theme-text-color  font-size-16 custom-font-600 text-capitalize radius-6px font-sans'
                                style={{ width: "102px", height: "40px" }}

                            >
                                Add/Edit
                            </Button>
                        </div>
                        {/* card and table */}
                        <IssuedCapitaltable/>
                        <KeyTable/>

                    </div>
                </Card>


            </div>
        </>
    )
}

export default ShareCapital