
import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navbar } from "../../../../Components/Navbar";
import { CreateCompanyDataIR, getCompany, RegisterCompanyData } from "../../ApiServices/apiHandler";
import { useEffect } from "react";
import { useState } from "react";
import "../../../../WorkSpace/font.css";
import "../../../../WorkSpace/Workspace2/sidebar.css";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";

const CreateIRPage = () => {
  const history = useHistory();
  const location = useLocation();
  const a = location.pathname;
  const b = a.split("/");

  const [allCompany, setAllCompany] = useState([]);
  const [irData, setIrData] = useState({
    ParentCompany: "",
    InvestRegister: ""
  });

  useEffect(() => {
    GetCompany();
  }, []);

  const handleChange = (e) => {
    setIrData({ ...irData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    const data = {
      InvestRegister: irData.InvestRegister,
      ParentCompany: irData.ParentCompany,
      investment_date: "",
      investment_value: "",
      shares_bought: "",
      total_no_shares: "",
      holding: "",
      non_controlling_interest: "",
      nature_of_relationship: "",
      investment_company_id:"",
      investment_company_code:""
    };

    CreateCompanyDataIR(data).then(response => {
      toast.success("Investment Register Created Successfully");
      history.push(`/dashboard`);
    }).catch(error => {
      swal("Error", error?.response?.data?.error, "error")
    })
  };

  const GetCompany = () => {
    getCompany().then(response => {
      setAllCompany(response.data.getCompany);
    }).catch(error => {
      console.log("Getting error when fetch company====", error)
    })
  };

  return (
    <>
      <Navbar />
      <div className="pb-2">
        <div className="container mt-4">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <button
              onClick={() => history.push(`/dashboard`)}
              className="border-0 d-flex bg-color-inherit custom-common-theme-text-color"
            >
              <ArrowBackIcon />
              &nbsp;
              <div>Go Back</div>
            </button>
            <button
              onClick={handleSubmit}
              className="px-3 py-1 rounded custom-border custom-common-theme-text-color"
            >
              Create IR
            </button>
          </div>

          <div className="d-flex">
            <p className="fs-inter">
              HOME / INVESTMENT / REGISTERS /&nbsp;
              <span className="custom-font-600" >
                CREATE REGISTER
              </span>
            </p>
          </div>
          <h5 className="font-size-24 custom-font-600">Create Investment Register</h5>

          <div className="mt-4 d-flex align-items-center">

            <div className="d-flex flex-column w-25">
              <label className="dm-sans iw-label">Name of investment register </label>
              <input
                className="rounded mt-0"
                type="text"
                name="InvestRegister"
                placeholder="Enter investment register"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="d-flex flex-column ml-2">
              <label className="dm-sans iw-label">Parent company</label>
              <select
                style={{
                  minWidth: "350px",
                  minHeight: "54px",
                }}
                className="rounded"
                name="ParentCompany"
                onChange={(e) => handleChange(e)}
              >
                <option>Select</option>
                {allCompany.length > 0 &&
                  allCompany.map((det, index) =>
                  (
                    <option key={index} value={det._id}>{det.company_name}</option>
                  )
                  )}
              </select>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default CreateIRPage;
