import { React, useEffect, useState } from "react";
import Navbarv3 from "./Navbarv3";
import Worklogo from "../../assets/dashboardworkspace.svg";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import CloudIcon from "@mui/icons-material/Cloud";
import DashboardLogo from "../../assets/dashboard1.svg";
import PropTypes from "prop-types";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
// import Logo5 from "../../../assets/user.svg";
import Logo5 from "../../assets/user.svg";
// import * as React from "react";
// import "../../../WorkSpace/SignUp2.css";
import "../../WorkSpace/SignUp2.css";
import CardContent from "@mui/material/CardContent";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Typography from "@mui/material/Typography";

// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import "../../WorkSpace/font.css";
import Radio from "@mui/material/Radio";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DashboardWorkspace = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  // const [openWorkspace, setOpenWorkspace] = useState(false);

  const opentab = Boolean(anchorEl);
  const id = opentab ? "simple-popover" : undefined;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //

  const handleClicktab = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosetab = () => {
    setAnchorEl(null);
  };
  // const handleClick = () => {
  //   setOpenWorkspace(true);
  // };

  return (
    <>
      <div className="pb-2">
        <div className="d-flex">
          <Navbarv3 />
        </div>
        <div className="container dbwcontainer mt-4">
          <h5 className="colv2 fs-inter dbwhead">Myworkspace</h5>
          <div className="card w-25 colv2 mt-3">
            <center>
              <div class="card-body">
                <img src={Worklogo} style={{ height: "50px" }} alt="logo" />
                <p className="dm-sans">Create workspace now</p>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#03565A",
                    textTransform: "none",
                    marginLeft: "9px",
                    width: "220px",
                    height: "50px",
                    border: "2px solid #03565A",
                  }}
                  onClick={handleClickOpen}
                >
                  <span>
                    <AddIcon />
                  </span>
                  <span style={{ marginLeft: "10px" }}>Create workspace</span>
                </Button>
              </div>
            </center>
          </div>
        </div>
        <div className="row dbwscontain mt-5">
          <div className="col-md-7">
            <h5 className="fs-inter dbwcardhead">Data registers </h5>

            <p className="dm-sans dbwcardpar">
              Centralize and organize your financial registers for seamless
              analytics and reporting
            </p>
            <Card style={{ height: "324px" }}>
              <Card.Header>
                <Nav variant="pills" defaultActiveKey="#first">
                  <Nav.Item className="dashboardheader fs-inter">
                    REGISTER NAME
                  </Nav.Item>
                  <Nav.Item className="dashboardchead fs-inter">
                    COMPANY NAME
                  </Nav.Item>
                  <Nav.Item className="dashboardchead fs-inter">
                    CURRENT YEAR
                  </Nav.Item>
                  <Nav.Item className="dashboardchead fs-inter">
                    PREVIOUS YEAR
                  </Nav.Item>

                  <Nav.Item className="dashboardchead fs-inter">
                    ACTION
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>
                  <img src={DashboardLogo} alt="logo" />
                </Card.Title>
                <Card.Text style={{ textAlign: "center" }}>
                  There are no registers created
                </Card.Text>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#03565A",
                      color: "white",
                      border: "2px solid #03565A",
                      textTransform: "none",
                    }}
                  >
                    <AddIcon />
                    Create Register
                  </Button>

                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#03565A",
                      border: "2px solid #03565A",
                      marginLeft: "35px",
                      textTransform: "none",
                    }}
                  >
                    <CloudIcon /> Upload Register
                  </Button>
                  {/* popupstart */}

                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <div>
                        <div
                          className="d-flex"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div>Select register type</div>
                          <div>
                            <Button onClick={handleClose} autoFocus>
                              Close
                            </Button>
                          </div>
                        </div>

                        <p style={{ color: "gray" }}>
                          Select from registers to prepare reporting and
                          consolidating financial statements
                        </p>
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <div
                          className="maincard-v2"
                          style={{ width: "465px", marginLeft: "40px" }}
                        >
                          <Card
                            style={{
                              borderRadius: "20px",
                              height: "94px",
                              width: "150px",
                            }}
                          >
                            <CardContent>
                              <Radio
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                              <div
                                className="chekbox-v2"
                                style={{ marginLeft: "13px" }}
                              >
                                investment
                              </div>
                              <div className="d-flex flex-column ml-2">
                                {/* <small>
                                    CY:{" "}
                                    {yearData.standalone_cy
                                      ? moment(yearData.standalone_cy).format(
                                          "DD-MM-YYYY"
                                        )
                                      : ""}
                                  </small>
                                  <small>
                                    PY:{" "}
                                    {yearData.standalone_py
                                      ? moment(yearData.standalone_py).format(
                                          "DD-MM-YYYY"
                                        )
                                      : ""}
                                  </small> */}
                              </div>
                            </CardContent>
                          </Card>

                          {/* console modal */}
                          <Card
                            className="card-v2"
                            style={{
                              borderRadius: "20px",
                              height: "94px",
                              width: "150px",
                            }}
                            // onClick={handleClickConsol}
                          >
                            <CardContent>
                              <Radio
                                // checked={selectedValue === "a"}
                                // onChange={handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                              {/* <Checkbox checked={isCheckedconsole} /> */}
                              <div
                                className="chekbox-v2"
                                style={{ marginLeft: "13px" }}
                              >
                                Sales
                              </div>
                              <div className="d-flex flex-column ml-2">
                                {/* <small>
                                    CY:{" "}
                                    {yearData.console_cy
                                      ? moment(yearData.console_cy).format(
                                          "DD-MM-YYYY"
                                        )
                                      : ""}
                                  </small> */}
                                {/* <small>
                                    PY:{" "}
                                    {yearData.console_py
                                      ? moment(yearData.console_py).format(
                                          "DD-MM-YYYY"
                                        )
                                      : ""}
                                  </small> */}
                              </div>
                            </CardContent>
                          </Card>

                          {/* end console modal */}
                          <Card
                            className="card-v2"
                            style={{
                              borderRadius: "20px",
                              height: "94px",
                              width: "150px",
                            }}
                          >
                            <CardContent>
                              <Radio
                                // checked={selectedValue === "a"}
                                // onChange={handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                              {/* <Checkbox /> */}
                              <div
                                className="chekbox-v2"
                                style={{ marginLeft: "13px" }}
                              >
                                Purchase
                              </div>
                            </CardContent>
                          </Card>
                        </div>

                        <div
                          className="maincard-v2"
                          style={{ width: "511px", marginLeft: "-6px" }}
                        >
                          <Card
                            className="card-v2"
                            style={{
                              borderRadius: "20px",
                              height: "94px",
                              width: "150px",
                            }}
                          >
                            <CardContent>
                              {/* <Checkbox /> */}
                              <Radio
                                // checked={selectedValue === "a"}
                                // onChange={handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                              <div
                                className="chekbox-v2"
                                style={{ marginLeft: "13px" }}
                              >
                                Lease
                              </div>
                            </CardContent>
                          </Card>

                          <Card
                            className="card-v-2"
                            style={{
                              borderRadius: "20px",
                              height: "94px",
                              width: "150px",
                            }}
                          >
                            <CardContent>
                              <Radio
                                // checked={selectedValue === "a"}
                                // onChange={handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                              {/* <Checkbox /> */}
                              <div
                                className="chekbox-v2"
                                style={{ marginLeft: "13px" }}
                              >
                                Far
                              </div>
                            </CardContent>
                          </Card>
                          <Card
                            className="card-v-2"
                            style={{
                              borderRadius: "20px",
                              height: "94px",
                              width: "150px",
                            }}
                          >
                            <CardContent>
                              {/* <Checkbox /> */}
                              <Radio
                                // checked={selectedValue === "a"}
                                // onChange={handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                              <div
                                className="chekbox-v2"
                                style={{ marginLeft: "13px" }}
                              >
                                Receiable
                              </div>
                            </CardContent>
                          </Card>
                        </div>

                        <div className="card-vv2"></div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        style={{
                          backgroundColor: "#03565A",
                          color: "white",
                          marginRight: "246px",
                        }}
                      >
                        Continue
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* enpopup */}
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4" style={{ marginTop: "19px" }}>
            <h5 className="fs-inter dbwcard2head">Company List (3)</h5>
            <h6 className="dm-sans dbwcardpar">
              Track the list of companies you work with in one place
            </h6>
            <Card className="dahboardCard2V2">
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}></Card.Title>
                <div>
                  <div
                    className="row"
                    style={{ overflowY: "scroll", maxHeight: "16rem" }}
                  >
                    <div className="col-12">
                      <div>
                        <div className="list_img_box">
                          {/* <img src="images/workspace-prof.svg" alt="wdwew" /> */}
                        </div>

                        <div className="list_name">
                          <span>
                            <BusinessCenterIcon style={{ color: "#03565A" }} />
                          </span>
                          &nbsp;
                          <strong>ddss</strong>
                        </div>
                        <div className="list_id">
                          <small
                            style={{
                              color: "gray",
                              marginLeft: "30px",
                            }}
                          >
                            D: #124321
                          </small>
                        </div>
                      </div>
                      <hr style={{ width: "100%", marginTop: "3px" }} />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        {/* else card */}
        <div className="dbwcontain2 mt-5">
          <div className="d-flex ">
            <div>
              <h3>
                <strong>Data registers</strong>
              </h3>
            </div>
            <div className="dbwbtn">
              <Button
                style={{
                  backgroundColor: "white",
                  color: "#03565A",
                  textTransform: "none",
                  marginLeft: "20px",
                  width: "220px",
                  height: "50px",
                  border: "2px solid #03565A",
                }}
                onClick={handleClickOpen}
              >
                <AddIcon />
                Uploade register
              </Button>
            </div>
          </div>
          <p style={{ color: "gray" }}>
            Centralize and organize your financial registers for seamless
            analytics and reporting
          </p>
          <div className="row">
            <div className="col-md-7">
              <Box>
                <Card style={{ marginTop: "10px" }} className="tabtoggle">
                  <CardContent>
   
                        <Box sx={{}}>
                          <Tabs
                            className="IR-tabs"
                            sx={{
                              "& button": {
                                borderRadius: 1,
                                color: "black",
                                fontWeight: "500",
                              },
                              "& button.Mui-selected": {
                                backgroundColor: "#03565A",
                                color: "white",
                                opacity:"1"
                              },
                            }}
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                          >
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontWeight: "400",
                              }}
                              label="Sales"
                              {...a11yProps(0)}
                            />
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontWeight: "400",
                              }}
                              label="Purchase"
                              {...a11yProps(1)}
                            />
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontWeight: "400",
                              }}
                              label="Lease"
                              {...a11yProps(2)}
                            />
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontWeight: "400",
                              }}
                              label="Investment"
                              {...a11yProps(3)}
                            />
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontWeight: "400",
                              }}
                              label="Far"
                              {...a11yProps(4)}
                            />
                            <Tab
                              sx={{
                                textTransform: "none",
                                width: "214px",
                                fontWeight: "400",
                              }}
                              label="Receivable and payable"
                              {...a11yProps(5)}
                            />
                          </Tabs>
                        </Box>

                  </CardContent>
                </Card>
                <CustomTabPanel value={value} index={0}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>REGISTER NAME</TableCell>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center">CURRENT YEAR</TableCell>
                          <TableCell align="center">PREVIOUS YEAR</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_1
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023{" "}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022{" "}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>YOUR NAME </TableCell>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center">YOUR AGE</TableCell>
                          <TableCell align="center">YOUR NUMBER</TableCell>
                          <TableCell align="center">YOUR CITY</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            DEEPAK
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            RIXOSYS
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            23
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            4567890
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            ASHIS
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            RIXOSYS
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            25{" "}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            123456789
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>YOUR SCHOOL NAME</TableCell>
                          <TableCell align="left">COLLAGE NAME</TableCell>
                          <TableCell align="center"> YEAR</TableCell>
                          <TableCell align="center">PASSOUT YEAR</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_1
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            UCP ENGINEERING SCHOOL
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            PMEC BERHAMPUR
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023{" "}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            2022{" "}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>PARENT COMPANY</TableCell>
                          <TableCell align="left">INVESTEES</TableCell>
                          <TableCell align="center">
                            {" "}
                            NO OF SHARES (CY)
                          </TableCell>
                          <TableCell align="center">
                            NO OF SHARES (PY)
                          </TableCell>
                          <TableCell align="center">
                            PERCENTAGE HOLDING
                          </TableCell>
                          <TableCell align="center">
                            NATURE OF RELATIONSHIP
                          </TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ backgroundColor: "white" }}
                            ></TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="left"
                            ></TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              80
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              100
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              80
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              Subsidiary Company
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              <MoreVertIcon onClick={handleClicktab} />
                              <Popover
                                style={{ marginInline: "-21px" }}
                                id={id}
                                open={opentab}
                                anchorEl={anchorEl}
                                onClose={handleClosetab}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  Edit
                                  <br />
                                  Delete
                                </Typography>
                              </Popover>
                            </TableCell>
                          </TableRow>
                        </>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>PARENT COMPANY</TableCell>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center"> YEAR</TableCell>
                          <TableCell align="center">PREVIOUS YEAR</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_1
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023{" "}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022{" "}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>REGISTER NAME</TableCell>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center"> YEAR</TableCell>
                          <TableCell align="center">PREVIOUS YEAR</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_1
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023{" "}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022{" "}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            <MoreVertIcon onClick={handleClicktab} />
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ backgroundColor: "white" }}
                          >
                            Reg_2
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="left"
                          >
                            ABC
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            May 1 2023
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            April 31 2022
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "white" }}
                            align="center"
                          >
                            {/* <MoreVertIcon onClick={handleClick} /> */}
                            <Popover
                              style={{}}
                              id={id}
                              open={opentab}
                              anchorEl={anchorEl}
                              onClose={handleClosetab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                Edit
                                <br />
                                Delete
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardWorkspace;
