import { Card } from 'react-bootstrap'
import { useState } from "react";
import * as React from 'react';
import "./supportTwo.css"
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { Rotate90DegreesCcw } from '@mui/icons-material';
import moment from 'moment';
import { useParams } from 'react-router';
// import { getTicketById, postNotes, getNotes, closeTicketStatus, reopenTicketStatus } from '
import { getTicketById, postNotes, getNotes, closeTicketStatus, reopenTicketStatus, getTeam } from '../../WorkSpace/Components/ApiServices/apiHandler';
import swal from 'sweetalert';
import axios from 'axios';
import { useEffect } from 'react';
import SupportNav from './SupportNav';
import useBackNavigation from './CustomBackhook';


const style = {
    position: 'absolute',
    top: '30%',
    left: '87%',
    transform: 'translate(-50%, -50%)',
    width: 120,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '12px',
    // p: 1,
    paddingLeft: "20px"
};

const SupportData = () => {


    const [open, setOpen] = React.useState(false);

    const [ticketData, setTicketData] = useState({})
    const [value, setValue] = useState(ticketData.status);

    // const handleOpen = () => {
    //     setOpen(true);
    //     setValue(status); // Set value to current status when modal opens
    //   };

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    


  
    const [chats, setChats] = useState([]);
    const [update, setUpdate] = useState(false)
    const [note, setNote] = useState('')
    const [status, setStatus] = useState(ticketData.status);
    const [team, setTeam] = useState([]);
    const [name, setName] = useState('');
    const [org, setOrg] = useState('')
    const [creatorId, setCreatorId] = useState('')

    
    useEffect(() => {
        fetchTicket();
    }, [status]);

    useEffect(() => {
        setValue(ticketData.status);
        setStatus(ticketData.status);  // Ensure the value state is updated when ticketData changes
      }, [ticketData]);

    const handleBack = useBackNavigation('/support');

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(event.target.value); // Update the radio button selection
      };

    const { id } = useParams();
    console.log(id)


    useEffect(() => {
        handleGetNotes()
    }, [update])



    const fetchTicket = async () => {

        try {
            const response = await getTicketById(id)
            console.log("get ticket by id show", response)
            if (response.data.data) {
                console.log(response.data.data, "ticket----------------------------------")
                setTicketData(response.data.data)
                setCreatorId(response.data.data.customer_id);
            }
        }
        catch (error) {
            console.log("erro fetching ticket deatails")
        }

    }

    const handleCancelStatus = async () => {
        try {
            const willDelete = await swal({
                title: "Are you sure?",
                text: "Are you sure that you want to cancel this ticket?",
                icon: "warning",
                dangerMode: true,
            });
            if (willDelete) {
                const response = await axios.put(`/api/v1/superadmin-ticket/close-status/${id}`, {
                    customer_id: creatorId

                })
                if (response.status === 200) {
                    swal("Cancelled", "Your imaginary ticket has been canceled!", "success")
                    console.log('successfully updated');
                    handleClose();
                    setStatus('Closed');
                    setValue('Closed');
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleReopenStatus = async () => {
        try {
            const willDelete = await swal({
                title: "Are you sure?",
                text: "Are you sure that you want to Re-open this ticket?",
                icon: "warning",
                dangerMode: true,
            });
            if (willDelete) {
                const response = await axios.put(`/api/v1/superadmin-ticket/reopen/${id}`, {
                    customer_id: creatorId

                });
                if (response.status === 200) {
                    swal("Re-Opened", "Your imaginary ticket has been Re-opened!", "success")
                    console.log('successfully updated');
                    handleClose()
                    setStatus('Open');
                    setValue('Open');
                }

            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getAgent()
    }, [])


    const getAgent = async () => {
        try {
            const res = await getTeam();
            console.log(res, "resteam")
            setTeam(res.data.data)

        } catch (error) {
            console.log(error)
        }
    }

    const handleCreateNote = async () => {
        const bodydata = {
            ticket_id: id,
            creator_id: creatorId,
            has_internal: 'yes',
            images: 'C:\\fakepath\\invoice-pdf (44).pdf',
            notes: note,
            added_by: 'admin'
        }


        try {

            const response = await postNotes(bodydata);
            if (response.status === 200) {
                console.log('Notes created:', response);
                setNote('')
                setUpdate(!update)
            }

        } catch (error) {
            console.log(error, 'error')
        }
    }

    const handleGetNotes = async () => {

        try {
            // const response = await getNotes();
            const response = await getNotes(id)
            console.log(response, 'response>>>>>>>')
            if (response.status === 200) {
                setChats(response.data.data);
            }

        } catch (error) {

        }
    }

    const groupChatsByDate = (chats) => {
        return chats.reduce((groups, chat) => {
            const date = moment(chat.createdAt).startOf('day').format('YYYY-MM-DD');
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(chat);
            return groups;
        }, {});
    };

    const groupedChats = groupChatsByDate(chats);

    // Function to get the date label
    const getDateLabel = (date) => {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const chatDate = moment(date);

        if (chatDate.isSame(today, 'day')) {
            return 'Today';
        } else if (chatDate.isSame(yesterday, 'day')) {
            return 'Yesterday';
        } else {
            return chatDate.format('DD/MM/YYYY');
        }
    };




    return (
        <>  <div>
            <SupportNav handleBack={handleBack} />
        </div>

            <div>
                <div className="m-4 d-flex justify-content-center " style={{ gap: "20px" }}>




                    <div className="ticketcard px-3 py-3  " >

                        <div className="d-flex justify-content-between  align-items-center">
                            <div><p className=" font-size-20 custom-font-700 mt-0 mb-0 font-sans">Ticket Details:</p></div>
                            <div><p className="font-size-16 text-gray custom-font-600 mt-0 mb-0 font-sans">48454555</p></div>
                        </div>

                        <div className="d-flex mt-3" >
                            <div >
                                <span className="text-gray font-size-12 custom-font-700 mt-0 mb-0 font-sans">Open date:</span>
                                <span style={{ marginLeft: "5px" }}><input className='new-textinput text-gray p-2  ' type="text" style={{ width: "48%", height: "25px" }} value={moment(ticketData.createdAt).format('DD/MM/YYYY')} readOnly /></span>
                            </div>

                            <div>
                                <span className="text-gray font-size-12 custom-font-700 mt-0 mb-0 font-sans">Last Update:</span>
                                <span style={{ marginLeft: "5px" }}><input className='new-textinput text-gray p-2  ' type="text" style={{ width: "48%", height: "25px" }} value={moment(ticketData.updatedAt).format('DD/MM/YYYY')} readOnly /></span>
                            </div>
                        </div>

                        <div className="mt-2">
                            <span className="text-gray font-size-12 custom-font-700 mt-0 mb-0 font-sans">Status:</span>
                            <span style={{ marginLeft: "5px" }}><input className='new-textinput text-gray p-2 mt-2 ' type="text" style={{ width: "15%", height: "25px" }} value={ticketData.status} readOnly /></span>

                        </div>

                        <div className="mt-2">
                            <label className='text-gray font-size-12 custom-font-700 mt-0 mb-0 font-sans'>Subject:</label>
                            <input className='new-textinput text-gray p-2 mt-2 w-100' type="email" value={ticketData.subject} readOnly />
                        </div>


                        <div className="mt-2">
                            <label className='text-gray font-size-12 custom-font-700 mt-0 mb-0 font-sans'>Email ID:</label>
                            <input className='new-textinput text-gray p-2 mt-2 w-100' type="email" value={ticketData.email} readOnly />
                        </div>

                        <div className="mt-2">
                            {ticketData.status === 'Closed' ? <button className=" custom-font-700 closeticket font-sans w-100 mt-2 bg-white " disabled>Ticket Closed</button> : <button className=" custom-font-700 closeticket font-sans w-100 mt-2 bg-white" onClick={() => handleCancelStatus()}>Close Ticket</button>}
                        </div>
                    </div>

                    <Card style={{ width: "900px" }}>

                        <Card.Header className="fixed-header">
                            <div className="d-flex justify-content-between align-items-center ">
                                <div className="logo-container">
                                    <div className="logo-box admin-bg">
                                        <span className="font-size-20 custom-font-700 font-sans text-white">{(ticketData.name ? ticketData.name.charAt(0).toUpperCase() :
                                            ticketData.organisation ? ticketData.organisation.charAt(0).toUpperCase() :
                                                ticketData.email ? ticketData.email.charAt(0).toUpperCase() :
                                                    '')}</span>
                                    </div>
                                    <span className="logo-text font-sans custom-font-700">{ticketData.name ? ticketData.name : (ticketData.organisation || ticketData.email)}</span>
                                </div>

                                <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                                    <span className=" font-sans custom-font-700 font-size-14" style={{ color: "#717171" }}>Ticket Status:</span>

                                    <div className="custom-border radius-10px d-flex align-items-center justify-content-center" style={{ width: "100px", height: "40px", gap: "10px" }}>
                                        <span className="custom-font-700  font-size-14 font-sans">{ticketData.status}</span>
                                        <span className="d-flex align-items-center justify-content-center border-5" style={{ backgroundColor: "#D6D6D6", width: "25px" }}>
                                            <KeyboardArrowDownIcon onClick={handleOpen} />
                                        </span>

                                        <div>

                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                    <RadioGroup
                                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="controlled-radio-buttons-group"
                                                        value={value}
                                                        onChange={handleChange}
                                                    >
                                                        <FormControlLabel
                                                            value="Open"
                                                            control={<Radio sx={{ color:"#03565A", '&.Mui-checked': { color:"#03565A" } }}/>}
                                                            label={
                                                                <span className='Table-headerText custom-font-500 font-size-16 '>
                                                                    Open
                                                                </span>
                                                            }

                                                            onClick={status !== 'Open' ? handleReopenStatus : null}
                                                            disabled={status === 'Open'}
                                                        />
                                                        <FormControlLabel
                                                            value="Closed"
                                                            control={<Radio sx={{ color:"#03565A", '&.Mui-checked': { color:"#03565A" } }}/>}
                                                            label={
                                                                <span className='Table-headerText custom-font-500 font-size-16 '>
                                                                    Close
                                                                </span>
                                                            }
                                                            onClick={status !== 'Closed' ? handleCancelStatus : null}
                                                            disabled={status === 'Closed'}
                                                        />
                                                    </RadioGroup>

                                                </Box>
                                            </Modal>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </Card.Header>

                        <div className="scrollable-chat-content">
                            <div style={{ marginLeft: '15px' }}>
                                {Object.keys(groupedChats).map((date) => (
                                    <div key={date}>
                                        <div className="date-label-container">
                                            <hr />
                                            <span className="date-label">{getDateLabel(date)}</span>
                                        </div>
                                        {groupedChats[date].map((chat) => (
                                            <div key={chat._id} className="d-flex mt-3">
                                                <div className={`logo-box ${chat.added_by === 'superadmin' ? 'superadmin-bg' : 'admin-bg'}`}>
                                                    <span className="font-size-20 custom-font-700 font-sans text-white">
                                                        {chat.added_by === 'superadmin'
                                                            ? team.find((teamMember) => teamMember._id === ticketData.assign_to)?.name?.charAt(0).toUpperCase() || 'U'
                                                            : (ticketData.name?.charAt(0).toUpperCase() || 'U')}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="logo-text font-sans custom-font-700 mt-2">
                                                        {chat.added_by === 'superadmin'
                                                            ? team.find((teamMember) => teamMember._id === ticketData.assign_to)?.name
                                                            : ticketData.name}
                                                        <span className="font-sans text-gray" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                                            {moment(chat.createdAt).format('h:mm A')}
                                                        </span>
                                                    </span>
                                                    <div>
                                                        <span className="text-gray custom-font-500 font-size-16">{chat.notes}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>


                        {ticketData.status === 'Closed' ? <div className='w-100 text-center mb-3'>Ticket Closed!</div> : (
                            <div className='p-4'>
                                <div className="message-input">
                                    <button className="icon-button">
                                        <AddIcon />
                                    </button>
                                    <div><hr style={{ width: "20px", transform: "rotate(90deg)", }} /></div>
                                    <input type="text" placeholder="Message" value={note} onChange={(e) => setNote(e.target.value)} />
                                    <div className="border-0">
                                        <SendIcon style={{ fill: '#03565A', cursor: 'pointer' }} onClick={() => note && handleCreateNote()} />
                                    </div>
                                </div>
                            </div>)}




                    </Card>

                </div>
            </div>

        </>
    )
}

export default SupportData