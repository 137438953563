import React, { useEffect, useState } from 'react';
import { Navbar } from '../../Navbar';
import { useHistory } from 'react-router';
import { toast } from "react-toastify";
import useFetchApi from '../../../services/CustomHook/useFetchApi';
import swal from 'sweetalert';
import CashFlowWorkingTable from './CashFlowWorkingTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loading from '../../Common/Loader/Loading';
import { socket } from '../../../services/socket/socket';
import { useDispatch, useSelector } from 'react-redux';
import ScrollTopTab from '../../Common/ScrollTopTab';
import PrimaryButton from "../../Common/PrimaryButton";
import { convertStrToNumber } from '../../../helper/ConvertValue';
import { cashflowAddNewLineItem } from '../../../services/api/apiHandler';
import NewOptionModal from './NewOptionModal';
import { addNewWorkingOption } from '../../../services/api/StandAlone/cashFlow';

const CashFlowWorking = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const project_id = localStorage.getItem('project_id');
    const tb_id = localStorage.getItem('tb_id');
    const { loading, response, error } = useFetchApi(`api/v1/cashflow/${project_id}/${tb_id}`);

    const { fcRawData } = useSelector((state) => state.CashflowReducer);

    const [cashFlowWorking, setCashFlowWorking] = useState([]);
    const [project, setProject] = useState({});
    const [workingOpt, setWorkingOpt] = useState([]);
    const [faceValueData, setFaceValueData] = useState([]);
    const [open, setOpen] = useState(false);
    const [workingOptDetail, setWorkingOptDetail] = useState({
        newOptName: ''
    });

    useEffect(() => {
        if (response && Object.keys(response).length > 0) {
            dispatch({ type: "CASHFLOW_FV_RAW_DATA", payload: response.data.rawFaceValueData || [] });
            setWorkingOpt(response?.data?.cashflowWorkingOptions);
            setCashFlowWorking(response?.data?.working);
            setFaceValueData(response?.data?.data);
            setProject(response?.data?.project);
        }
        if (error) {
            swal("Error", error, "error");
        }
    }, [response || error])

    const HandleChange = async (working_id, index, event) => {
        const copy = [...cashFlowWorking]
        // const nbfc_working_id_set = new Set([15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,27, 28, 30, 32]);
        // const working_id_set = new Set([15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,27]);

        // const WORKING_ID_SET = project?.company_type === "NBFC" ? nbfc_working_id_set : working_id_set;
        const getWorkingTable = copy.find(ele => ele.workingId === working_id);
        const tableName = getWorkingTable.tableName;
        const workingLabel1 = getWorkingTable?.data;
        workingLabel1[index][event.target.name] = convertStrToNumber(event.target.value);
        const key_amount = event.target.name === "cy" ? "cy_amount" : "py_amount";

        if(project?.company_type === "NBFC" && working_id === 32 && workingLabel1[index].adjustmentsName === "Less: Amortization"){
            workingLabel1[index][key_amount] = -1 * convertStrToNumber(event.target.value);
        }else{
            workingLabel1[index][key_amount] = 
                tableName === "lability" ? convertStrToNumber(event.target.value) : 
                -1 * convertStrToNumber(event.target.value);
        }

        const newFormula = (formula) => formula.split('-').map(Number).map(num => num - 1);

        const computeValue = (data, indices, field) => {
            if (!Array.isArray(data) || data.length === 0) return 0; // or handle the case when data is not an array or is empty
            const result = indices.reduce((acc, index, idx) => acc - (data[indices[idx + 1]]?.[field] || 0), data[indices[0]]?.[field] || 0);
            return { result, sign_result: 
                tableName === "lability" ? result : -result 
            };
        };

        if(working_id === 1){
            workingLabel1.forEach(workEle => {
                if(workEle.internalFormulaReference && workEle.internalFormulaReference !== "NA"){
                    let calculation_value;
                    if (
                        ['Net book value of sale madee', 'Sale value'].includes(workEle.adjustmentsName)
                    ) {
                        const indices = workEle.adjustmentsName === 'Sale value' ? 
                            workEle.internalFormulaReference.split("+").map(Number).map(num => num - 1) : [2, 4] ;

                        let calculated_value = workEle.adjustmentsName !== 'Sale value'
                            ? -(workingLabel1[2][event.target.name] || 0) - (workingLabel1[4][event.target.name] || 0)
                            : indices.reduce((accumulator, index) => accumulator + workingLabel1[index][event.target.name], 0);
                        calculated_value = calculated_value === -0 ? 0 : calculated_value
                        calculation_value = { result: calculated_value, sign_result: calculated_value }
                    }else{
                        calculation_value = computeValue(workingLabel1, newFormula(workEle.internalFormulaReference), event.target.name);
                    }
                    workEle[event.target.name] = calculation_value?.result;
                    workEle[key_amount] = calculation_value?.sign_result;
                }
            })
        }else{
            const filterByFormula = workingLabel1.find(ele => ele.internalFormulaReference && ele.internalFormulaReference !== "NA");
            const indexByFormula = workingLabel1.findIndex(ele => ele === filterByFormula);

            const calculation_value = computeValue(workingLabel1, newFormula(filterByFormula.internalFormulaReference), event.target.name);
            workingLabel1[indexByFormula][event.target.name] = calculation_value?.result;
            workingLabel1[indexByFormula][key_amount] = calculation_value?.sign_result;
        }   

        setCashFlowWorking(copy);

        //? Socked code for update and error handeling
        socket.emit("auto-save-cashflow-working", {
            project_id: `${project_id}`,
            contents: copy,
        });
    }
    socket.on('auto-save-cashflow-working', (data) => {
        // console.log('Message from server:', data);
        if(!data.success){
            swal("Error", data.message, "error")
        }
    });

    const AddNewLineItem = (index) => {
        cashflowAddNewLineItem({project_id, index}).then(response => {
            toast.success(response.data?.message || "New Item Added Successfully");
            setCashFlowWorking(response.data?.workingData)
            setFaceValueData(response.data?.fvData)
        }).catch(error => {
            swal("Error", error, "error");
        });
    }

    const HandleSelect = (e, index, sub_index) => {
        const {value, name} = e.target;
        let copy = [...cashFlowWorking];
        const deepCopy = JSON.parse(JSON.stringify(cashFlowWorking));
        if(value === "add-new-option"){
            setWorkingOptDetail(prev => ({...prev, index, sub_index, name }))
            setOpen(true);
            return;
        }

        const convertValue = name === "adjustmentCashFlowStatement" ? Number(value) : value;

        if(name === "nameOfItemCashFlowStatement"){
            const wantToRemoveWorkingId = deepCopy[index].workingId;
            const prevCSName = deepCopy[index].data[sub_index].nameOfItemCashFlowStatement;
            // ? Update Working ID
            faceValueData.forEach((ele, fvIndex) => {
                const findPreviousFD = ele.faceValueData.find(fd => fd.particular === prevCSName);
                const findFD = ele.faceValueData.find(fd => fd.particular === convertValue);
                
                if(findFD && findFD?.workingId){
                    findFD.workingId.push(copy[index]?.workingId || "");
                }
                if(findPreviousFD && findPreviousFD?.workingId){
                    const rawDataWorkingIds = fcRawData[fvIndex].faceValueData.find(fd => fd.particular === prevCSName).workingId ?? [];
                    const indexOfWA = findPreviousFD.workingId.indexOf(wantToRemoveWorkingId);
                    findPreviousFD.workingId = findPreviousFD.workingId.filter((ele, ex) => ex !== indexOfWA ); //? remove existing working id
                }
            })
            setFaceValueData(faceValueData);
        }

        copy[index].data[sub_index][name] = convertValue;

        setCashFlowWorking(copy);

        socket.emit("auto-save-cashflow-working", {
            project_id: `${project_id}`,
            contents: copy,
            faceValueData
        });
    }

    const addNewOption = () => {
        addNewWorkingOption({...workingOptDetail, project_id}).then(response => {
            setCashFlowWorking(response?.data?.working);
            setWorkingOpt(response?.data?.options);
            setWorkingOptDetail(prev => ({...prev, newOptName:"" }));
            setOpen(false);
        }).catch(error => {
            swal("Error", error?.response?.data?.error || "New Working Option Add failed.", "error");
        })
    }

    return (
        <>
            <Navbar text="Financial Year: 2021 - 2022" />
            <div className='custom-common-secondary-bg'>
                <div className='d-flex w-100'>
                    <button className='ml-2 my-2 rounded back-btn' style={{ border: "none", backgroundColor: "inherit" }}
                        onClick={() => {dispatch({ type: "NOTE_KEY", payload: 2 }); history.push(`/financialstatement`) }}
                    >
                        <div className='d-flex px-3' style={{ color: "#03565a", fontWeight: "600" }}><ArrowBackIcon /> Back</div>
                    </button>

                    <div className='d-flex justify-content-center w-100 mt-2' style={{ gap: "1rem" }}>
                         <PrimaryButton name="Adjustment log" variant={"outlined"}  onClick={() => history.push('/financialstatement/cashflow/adjustment-log')} />
                         <PrimaryButton name="Add new adjustment" variant={"outlined"} onClick={() => history.push('/financialstatement/cashflow/add-new-adjustment')} />
                    </div>
                </div>
                {
                    loading ? <Loading /> :
                        <div className='p-2'>
                            {cashFlowWorking && cashFlowWorking.length > 0 && cashFlowWorking.map((ele, ix) => (
                                <div key={ele.workingId} className='nbfc-box-shadow bg-white'>
                                    <div className='w-100 py-2 ml-0 custom-bg-warning d-flex justify-content-between align-items-center'>
                                        <p className='font-size-14 custom-font-600 p-0 m-0 pl-2' >{ele.name}</p>
                                        <button 
                                            className='font-size-14 custom-font-600 custom-common-theme-text-color bg-color-inherit border-0 pr-2' 
                                            onClick={() => AddNewLineItem(ix)}
                                        >
                                            New line item +
                                        </button>
                                    </div>
                                    
                                    <CashFlowWorkingTable 
                                        data={ele.data}
                                        HandleSelect={HandleSelect}
                                        addNewOption={addNewOption}
                                        workingOpt={workingOpt}
                                        HandleChange={(index, event) => { HandleChange(ele.workingId, index, event) }}
                                        index={ix}
                                    />
                                </div>
                            ))}
                        </div>
                }
                <ScrollTopTab/>
            </div>
            <NewOptionModal
                open={open}
                setOpen={setOpen}
                setWorkingOptDetail={setWorkingOptDetail}
                workingOptDetail={workingOptDetail}
                addNewOption={addNewOption}
            />
        </>
    )
}

export default CashFlowWorking