import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LockResetIcon from '@mui/icons-material/LockReset';
import swal from "sweetalert";
import { toast } from "react-toastify";
import "./addmin.css";
import { deleteUserData, getMeber, resetPassword,getActivePlan } from "../WorkSpace/Components/ApiServices/apiHandler";
import AdminNavbar from "./Components/AdminNavbar";
import { generatePassword } from "../helper/accessibility";
import Loading from "../Components/Common/Loader/Loading";

const Admin = () => {
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userNo ,setUserno] = useState('')


  useEffect(() => {
    fetchMember();
    activePlandata();
  }, [])


  const fetchMember = async () => {
    setLoading(true);
    getMeber().then(response => {
      setAllUsers(response.data.fetchMember);
      setUsers(response.data.fetchMember);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      swal(
        "Error",
        error.response?.data?.error || "Getting member data failed!",
        "error"
      );
    })
  };

  const activePlandata = async() => {
    try {
          const res = await getActivePlan()
          console.log(res,'activeplandata')
          setUserno(res.data.categorizedBenefits.noOfUsers[0]);
          
          console.log(res.data.users,'no of users')
    } catch (error) {
       console.log(error,'errorr')
    }
  } 

  const ResetPassword = (userId) => {
    const tempPassword = generatePassword();
    swal({
      title: 'Are you sure?',
      text: 'Once Reset, User will not be able to login with previous password!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(() => {
      resetPassword({
        tempPassword,
        userId
      }).then(response => {
        swal({
          title: 'Reset!',
          text: 'Password has been reset. Check your mail for your new password!',
          icon: 'success'
        });
        fetchMember();
      }).catch(error => {
        swal(
          "Error",
          error.response?.data?.error || "Getting member data failed!",
          "error"
        );
      })
    });
  }

  const DeleteMember = (userId) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUserData(userId).then(response => {
          toast.success("Team Member Deleted Successfully.");
          fetchMember();
        }).catch(error => {
          swal(
            "Error",
            error.response?.data?.error || "Team member delete failed!",
            "error"
          );
        });
      } else {
        toast.info("Action cancelled.");
      }
    });
  };
  

  const HandleSearch = (targetEvent) => {
    const { value } = targetEvent;
    if( value.length > 0 ){
      const filteredData = allUsers.filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.email.toLowerCase().includes(value.toLowerCase())
      );
      setUsers(filteredData);
    }else{
      setUsers(allUsers);
    }
  }

  return (
    <>
      <AdminNavbar />
      <div className="px-3">
        <div className="d-flex justify-content-between mt-3">
          <p className="mt-0 mb-0 custom-font-600 font-size-20 font-sans">
            My Team
          </p>
          <span className="mt-3">You can create maximum <span style={{color:'red'}}>{userNo}</span> Team Members! </span>
          <div className="d-flex">
            <div className="search-btnv2 d-flex flex-row jusify-content-center align-items-center radius-10px">
              <SearchIcon style={{ color: "gray" }} />
              <input
                className="w-100 border-0 text-secondary"
                placeholder="Search by User name or Email ID"
                onChange={(e) => HandleSearch(e.target)}
              />
            </div>
            <Button
              variant="outlined"
              className="font-size-16 custom-font-600 primary-border custom-common-theme-text-color text-capitalize font-sans radius-6px ml-2"
              style={{ height: "48px" }}
              onClick={() => history.push("/deleted-Ids")}
            >
              Deleted Members
            </Button>
            {users.length >= userNo ? (
              <Button
              variant="outlined"
              className="font-size-16 custom-font-600 primary-border custom-common-theme-text-color text-capitalize font-sans radius-6px ml-2"
              style={{ height: "48px" }}
              onClick={() => swal("","You have reached maximum no of create Team Members","warning")}
            >
              Add teammate
            </Button>):(<Button
              variant="outlined"
              className="font-size-16 custom-font-600 primary-border custom-common-theme-text-color text-capitalize font-sans radius-6px ml-2"
              style={{ height: "48px" }}
              onClick={() => history.push("/add-teamate")}
            >
              Add teammate
            </Button>)}
            
          </div>
        </div>

        {
          loading ? <Loading /> :
          <table className="custom-table mt-2 w-100">
            <thead className="custom-bg-white-150" >
              <tr>
                <th className="text-uppercase font-size-12 custom-font-600 font-inter border-top-left-radius text-left text-secondary">
                  SL.NO
                </th>
                <th className="text-uppercase font-size-12 custom-font-600 font-inter text-left text-secondary" style={{ minWidth: "150px" }}>
                  User Name
                </th>
                <th className="text-uppercase font-size-12 custom-font-600 font-inter text-left text-secondary" style={{ minWidth: "150px" }}>
                  Email
                </th>
                <th className="text-uppercase font-size-12 custom-font-600 font-inter text-left text-secondary" style={{ minWidth: "120px" }}>
                  Temp Password
                </th>
                <th className="text-uppercase font-size-12 custom-font-600 font-inter text-left text-secondary" style={{ minWidth: "150px" }}>
                  Responsibilities
                </th>
                <th className="text-uppercase font-size-12 custom-font-600 font-inter text-center text-secondary" style={{ width: "22rem" }} >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {
                users.length > 0 ? users.map((user, userIndex) => (
                  <tr
                    // className="last-row" 
                    key={userIndex}
                  >
                    <td className="bg-white text-center text-secondary font-size-14"> {userIndex + 1} </td>
                    <td className="bg-white text-left pl-2 text-secondary font-size-14 text-capitalize"> {user.name} </td>
                    <td className="bg-white text-left pl-2 text-secondary font-size-14"> {user.email} </td>
                    <td className="bg-white text-left pl-2 text-secondary font-size-14"> {user.temp_password} </td>
                    <td className="bg-white text-left pl-2 text-secondary font-size-14"> {user.responsibilities} </td>
                    <td className="bg-white text-center pl-2 text-secondary d-flex justify-content-center h-100">
                      <button 
                        className="d-flex justify-content-center align-items-center bg-color-inherit border-0"
                        onClick={() => history.push(`add-teamate/${user._id}`)}
                      >
                        <div>
                        <DriveFileRenameOutlineIcon
                          className="custom-common-theme-text-color h-50"
                        /></div>
                        <p className="mt-0 mb-0 font-size-14 custom-common-theme-text-color custom-font-600">
                          Edit
                        </p>
                      </button>

                      <button 
                        className="d-flex justify-content-center align-items-center bg-color-inherit border-0 ml-2"
                        onClick={() => DeleteMember(user._id)}
                      >
                        <div>
                        <DeleteOutlineIcon
                          className="text-danger h-50"
                        /></div>
                        <p className="mt-0 mb-0 font-size-14 custom-font-600 text-danger">
                          Delete
                        </p>
                      </button>

                      <button
                        className="d-flex justify-content-center align-items-center bg-color-inherit border-0 ml-2"
                        onClick={() => ResetPassword(user._id)}
                      >
                        <div>
                        <LockResetIcon
                          className="text-success h-50"
                        /></div>
                        <p className="mt-0 mb-0 font-size-14 custom-font-600 text-success">
                          Reset Password
                        </p>
                      </button>
                    </td>
                  </tr>
                )) : 
                <tr > 
                  <td colSpan={6} className="text-secondary font-size-14" > Data Not Found  </td>
                </tr>
              }
            </tbody>
          </table>
        }
      </div>
    </>
  );
};

export default Admin;
