import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CkEditor from '../../Common/CkEditor';
import { socket } from '../../../services/socket/socket';
import swal from 'sweetalert';

const NoteRemark = ({ NoteResponse }) => {
    const CommentRemarkChange = (comment) => {
        socket.emit("notes-comment-auto-save", {
            project_id: NoteResponse?.project_id,
            note_no: NoteResponse?.notes_no,
            comment
        });
    }

    socket.on('notes-comment-auto-save', (data) => {
        if(!data.success){
            swal("Error", data.message, "error")
        }
    });

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    className='w-100 px-2'
                >
                    <Typography className='text-secondary custom-font-500' >Note Here</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {/* <input 
                            className='w-100 mb-2 p-2 text-secondary'
                            placeholder='Enter Title Here'
                        /> */}
                        <CkEditor value={NoteResponse?.comment} onChange={(data) => CommentRemarkChange(data)} />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default NoteRemark