import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// import "./sidebarv2.css"
import "./../../Workspace-v2/sidebarv2.css";

import Modal from "@mui/material/Modal";
import {  GetProject } from "../ApiServices/apiHandler";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "10px",
  p: 4,
};
const CompareFs = (props) => {
  const { open, handleClose, w_id, allpreconsoldata } = props;
  let history = useHistory();
  const [allProject, setAllProject] = useState([]);
  const [dataId, setDataID] = useState({
    c1: "",
    c2: "",
  });

  useEffect(() => {
    AllProject();
  }, []);

  const AllProject = async () => {
    const res = await GetProject();
    if (res.status === 200) {
      setAllProject(res.data.project);
    }
  };

  const handleChange = (e, field) => {
    
    setDataID({ ...dataId, [field]: e.target.value });
  };
  const nextCompare = () => {
    history.push(`/comparefs/${dataId.c1}/${dataId.c2}`);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ borderRadius: "10px" }}>
            <p
              style={{ marginLeft: "1436px", cursor: "pointer" }}
              onClick={handleClose}
            >
              X
            </p>
            <TableContainer component={Paper} className="mb-4 w100">
              <Table sx={{}} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>UPLOAD PRESET</TableCell>
                    <TableCell align="center">UPLOAD PRESET</TableCell>
                    <TableCell align="center">VALIDATION CHECKS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ backgroundColor: "white" }}
                    >
                      <select
                        style={{
                          height: "50px",
                          width: "100%",
                          border: "1px solid gray",
                          marginTop: "10px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => handleChange(e, "c1")}
                      >
                        <option>Select...</option>
                        {allProject.length > 0 &&
                          allProject.map((det) => {
                            return (
                              <>
                                <option value={det._id}>
                                  {det.project_name}
                                </option>
                              </>
                            );
                          })}
                        {/* {allpreconsoldata.length > 0 &&
                          allpreconsoldata.map((det) => {
                            return (
                              <>
                                <option value={det._id}>
                                  {det.prepare_consol_company_name}
                                </option>
                              </>
                            );
                          })} */}
                      </select>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ backgroundColor: "white" }}
                    >
                      <select
                        style={{
                          height: "50px",
                          width: "100%",
                          border: "1px solid gray",
                          marginTop: "10px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => handleChange(e, "c2")}
                      >
                        <option>Select...</option>
                        {/* {allProject.length > 0 &&
                          allProject.map((det) => {
                            return (
                              <>
                                <option value={det._id}>
                                  {det.project_name}
                                </option>
                              </>
                            );
                          })} */}
                        {allpreconsoldata.length > 0 &&
                          allpreconsoldata.map((det) => {
                            return (
                              <>
                                <option value={det._id}>
                                  {det.prepare_consol_company_name}
                                </option>
                              </>
                            );
                          })}
                      </select>
                    </TableCell>
                    <TableCell align="center" className="chek_btn">
                      {dataId.c1 !== "" && dataId.c2 !== "" ? (
                        <Button
                          className="btn_chek43"
                          variant="contained"
                          onClick={() => nextCompare()}
                        >
                          Compare
                        </Button>
                      ) : (
                        <Button
                          className="btn_chek43"
                          variant="contained"
                          disabled
                        >
                          Compare
                        </Button>
                      )}

                      <Button className="btn_chek53v2" variant="contained">
                        Export
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CompareFs;
