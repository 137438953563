const CMTypes = {
    CM_ACTION_START:"CM_ACTION_START",
    CM_FETCH_DATA:"CM_FETCH_DATA",
    CM_FETCH_DATA_FAILED:"CM_FETCH_DATA_FAILED",
    CM_ADD_DATA:"CM_ADD_DATA",
    CM_UPDATING_DATA: "CM_UPDATING_DATA",
    CM_DELETE_LINE_ITEM: "CM_DELETE_LINE_ITEM",
    CM_RESTORE_LINE_ITEM: "CM_RESTORE_LINE_ITEM",
    CM_FILTERED_LINE_ITEM: "CM_FILTERED_LINE_ITEM",
    CM_SOCKET_UPDATE_DATA: "CM_SOCKET_UPDATE_DATA"
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
    set_payload: "",
    filteredData: []
}

const ConventionModeReducer = (state=Initial_state, action) => {
    switch(action.type){
        case CMTypes.CM_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case CMTypes.CM_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                set_payload: action.payload.set_payload
            }

        case CMTypes.CM_UPDATING_DATA:
            return {
                ...state,
                loading: false,
                response: { 
                    ...state.response,
                    data: {
                        ...state.response.data,
                        line_items: action.payload.data
                    }
                }
            };
        
        case CMTypes.CM_DELETE_LINE_ITEM:
            const deletedLineItems = action.payload.data;
            const filterData = deletedLineItems.map((data)=> data.lid);
            return {
                ...state,
                response: {
                    ...state.response,
                    data: {
                        ...state.response.data,
                        line_items: state.response.data.line_items.filter(data=> !filterData.includes(data.lid))
                    }
                }
            }
        
        case CMTypes.CM_RESTORE_LINE_ITEM:
            const newData = [...state.response.data.line_items, action.payload.data];
            return {
                ...state,
                response: {
                    ...state.response,
                    data: {
                        ...state.response.data,
                        line_items: [...newData.sort((a, b)=> parseInt(a.ledger_code)-parseInt(b.ledger_code))]
                    }
                }
            }
        
        case CMTypes.CM_FILTERED_LINE_ITEM:
            return {
                ...state,
                filteredData: [
                    ...action.payload.data
                ]
            }

        case CMTypes.CM_SOCKET_UPDATE_DATA:
            const { data, set_payload } = action.payload;
            if(state.set_payload !== set_payload){
                return {
                    ...state
                }
            }
            return {
                ...state,
                response: { 
                    ...state.response,
                    data: {
                        ...state.response.data,
                        line_items: data
                    }
                }
            };

        case CMTypes.CM_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        default:
            return state;
    }
};

export default ConventionModeReducer;