//Updated console balance sheet

import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import moment from "moment";
import {
  lineGroupStyle,
  totalStyle,
  totaledStyle,
  NoteStyle,
} from "../Styles/style";
import CircularProgress from "@mui/material/CircularProgress";

function NewConsoleBalanceSheet(props) {
  const {
    balanceSheetData,
    setTempValue,
    currentCompany,
    headingData,
    subsiaryData,
    // browsFs,
    // notes,
  } = props;
  const [no, setNo] = useState([]);

  const { loading, isSuccess, response, error_message } = balanceSheetData;
  const rows = response?.data?.balance_sheet?.balance_sheet ?? [];
  const subsiary = response?.data?.balance_sheet?.balance_sheet ?? [];
  // const sybsidiary = response?.data?.balance_sheet?.balance_sheet ?? [];
  const finalSubsidiaryData =
    response?.data?.project_ids_bs?.map((data) => data.balance_sheet) ?? [];
  // const finalBrowsFsData =
  //   response?.browse_fs_bs?.map((data) => data.balance_sheet) ?? [];

  const fsRows = Object.keys(rows);
  const fsSubRows = Object.keys(subsiary);

  useEffect(() => {
    let noArr = [];
    // eslint-disable-next-line array-callback-return
    fsRows.map((row, i) => {
      // eslint-disable-next-line array-callback-return
      rows[row].map((line) => {
        if (
          noArr.length > 0 &&
          noArr.some(
            (user) =>
              user.notes_grp.toLowerCase() === line.notes_grp.toLowerCase()
          )
        ) {
        } else {
          noArr.push({ notes_grp: line.notes_grp });
        }
      });
    });
    setNo(noArr);
    let noArrs = [];
    // eslint-disable-next-line array-callback-return
    fsSubRows.map((rows) => {
      // eslint-disable-next-line array-callback-return
      subsiary[rows].map((lines) => {
        if (
          noArrs.length > 0 &&
          noArrs.some(
            (user) =>
              user.notes_grp.toLowerCase() === lines.notes_grp.toLowerCase()
          )
        ) {
        } else {
          noArrs.push({ notes_grp: lines.notes_grp });
        }
      });
    });
  }, []);

  function handleClick(key) {
    setTempValue(key);
    console.log(key);
  }

  const calculateCyPy = (ele) => {
    return {
      cy: rows[ele] ? rows[ele].reduce((cy, acc) => cy + acc.cy, 0) : "",
      py: rows[ele] ? rows[ele].reduce((py, acc) => py + acc.py, 0) : "",
      cy_nca: rows["Non Current Assets"].reduce((cy, acc) => cy + acc.cy, 0),
      py_nca: rows["Non Current Assets"].reduce((py, acc) => py + acc.py, 0),
      cy_ca: rows["Current Assets"].reduce((cy, acc) => cy + acc.cy, 0),
      py_ca: rows["Current Assets"].reduce((py, acc) => py + acc.py, 0),
      cy_ncl: rows["Non Current Liabilities"].reduce(
        (cy, acc) => cy + acc.cy,
        0
      ),
      py_ncl: rows["Non Current Liabilities"].reduce(
        (py, acc) => py + acc.py,
        0
      ),
      cy_cl: rows["Current Liabilities"].reduce((cy, acc) => cy + acc.cy, 0),
      py_cl: rows["Current Liabilities"].reduce((py, acc) => py + acc.py, 0),
    };
  };

  // const calculateCyPyForBrowsFs = (ele) => {
  //   return {
  //     cyB: browsFs[ele] ? browsFs[ele].reduce((cy, acc) => cy + acc.cy, 0) : '',
  //     pyB: browsFs[ele] ? browsFs[ele].reduce((py, acc) => py + acc.py, 0) : '',
  //     cyB_nca: browsFs["Non Current Assets"].reduce((cy, acc) => cy + acc.cy,0),
  //     pyB_nca: browsFs["Non Current Assets"].reduce((py, acc) => py + acc.py,0),
  //     cyB_ca: browsFs["Current Assets"].reduce((cy, acc) => cy + acc.cy,0),
  //     pyB_ca: browsFs["Current Assets"].reduce((py, acc) => py + acc.py,0),
  //     cyB_ncl: browsFs["Non Current Liabilities"].reduce((cy, acc) => cy + acc.cy,0),
  //     pyB_ncl: browsFs["Non Current Liabilities"].reduce((py, acc) => py + acc.py,0),
  //     cyB_cl: browsFs["Current Liabilities"].reduce((cy, acc) => cy + acc.cy,0),
  //     pyB_cl: browsFs["Current Liabilities"].reduce((py, acc) => py + acc.py,0)
  //   }
  // }

  const getScenario = (final_sub_index) => {
    const currentDate = moment(currentCompany.current_financial_year).format(
      "YYYY-04-01"
    );
    const previousDate = moment(currentCompany.current_financial_year)
      .subtract(1, "year")
      .format("YYYY-03-01");
    const testDate = moment(
      subsiaryData[final_sub_index].date_Of_investment
        ? subsiaryData[final_sub_index].date_Of_investment
        : ""
    ).format("YYYY-MM-DD");
    //2nd scenario
    const py_date = moment(previousDate)
      .subtract(1, "year")
      .format("YYYY-03-01");
    const first_scenario = moment(testDate).isBetween(
      previousDate,
      currentDate
    );
    const second_scenario = moment(testDate).isBetween(py_date, previousDate);
    return {
      first_scenario: first_scenario,
      second_scenario: second_scenario,
    };
  };

  const calculateBsCy = (final_sub_index, final_sub_ele, fs_row) => {
    const { first_scenario, second_scenario } = getScenario(final_sub_index);
    const result = final_sub_ele[fs_row].reduce((cy, acc) => cy + acc.cy, 0);
    if (first_scenario) {
      return result;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  };

  const calculateBsPy = (final_sub_index, final_sub_ele, fs_row) => {
    const { first_scenario, second_scenario } = getScenario(final_sub_index);
    const result = final_sub_ele[fs_row].reduce((py, acc) => py + acc.py, 0);
    if (first_scenario) {
      return 0;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  };

  const calculateBsCyTotal = (fs_row, cy) => {
    try {
      const final_cy = finalSubsidiaryData.map((data, index) =>
        calculateBsCy(index, data, fs_row)
      );
      final_cy.push(cy);
      return final_cy.reduce((num, acc) => num + acc, 0);
    } catch (err) {
      console.log("total cy sum error", err);
      return "error";
    }
  };

  const calculateBsPyTotal = (fs_row, py) => {
    try {
      const final_py = finalSubsidiaryData.map((data, index) =>
        calculateBsPy(index, data, fs_row)
      );
      final_py.push(py);
      return final_py.reduce((num, acc) => num + acc, 0);
    } catch (err) {
      console.log("total py sum error", err);
      return "error";
    }
  };

  const calculateBsCy_2 = (
    final_sub_index,
    final_sub_ele,
    fs_row,
    row_index
  ) => {
    const { first_scenario, second_scenario } = getScenario(final_sub_index);
    const result = final_sub_ele[fs_row][row_index].cy;
    if (first_scenario) {
      return result;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  };

  const calculateBsPy_2 = (
    final_sub_index,
    final_sub_ele,
    fs_row,
    row_index
  ) => {
    const { first_scenario, second_scenario } = getScenario(final_sub_index);
    const result = final_sub_ele[fs_row][row_index].py;
    if (first_scenario) {
      return 0;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  };

  const calculateBsCyTotal_2 = (fs_row, row_index, line_cy) => {
    try {
      const res = finalSubsidiaryData.map((data, index) =>
        calculateBsCy_2(index, data, fs_row, row_index)
      );
      return res.reduce((data, acc) => data + acc, 0) + line_cy;
    } catch (err) {
      console.log("total cy sum error", err);
      return "0 err";
    }
  };

  const calculateBsPyTotal_2 = (fs_row, row_index, line_py) => {
    try {
      const res = finalSubsidiaryData.map((data, index) =>
        calculateBsPy_2(index, data, fs_row, row_index)
      );
      return res.reduce((data, acc) => data + acc, 0) + line_py;
    } catch (err) {
      console.log("total cy sum error", err);
      return "0 err";
    }
  };
  const particlarTab = { 
    width: "300px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem"
  }
  return (
    <>
      {loading ? (
        <CircularProgress disableShrink />
      ) : isSuccess ? (
        <>
          <div className="tableSheet">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th
                    rowSpan="2"
                    style={particlarTab}
                  >
                    <strong>Particulars </strong>
                  </th>
                  <th rowSpan="2"style={particlarTab}>
                    <strong>Notes</strong>
                  </th>
                  <th
                    colSpan="2"
                    style={particlarTab}
                  >
                    <strong>
                      {currentCompany.prepare_consol_company_name}
                    </strong>
                  </th>

                  {headingData.length > 0 &&
                    headingData.map((company, i) => (
                      <th
                        colSpan={2}
                        style={particlarTab}
                      >
                        <strong>{company}</strong>
                      </th>
                    ))}
                  <th colSpan="3" style={particlarTab}>
                    <strong>ADJUSTMENT</strong>
                  </th>
                  <th colSpan="3" style={particlarTab}>
                    <strong>TOTAL</strong>
                  </th>
                </tr>

                <tr style={{ backgroundColor: "#A2C0C2" }}>
                  <th style={particlarTab}>
                    {dateFormat(
                      currentCompany.current_financial_year,
                      "mmmm dS, yyyy"
                    )}
                  </th>
                  <th style={particlarTab}>
                    {dateFormat(
                      currentCompany.previous_financial_year,
                      "mmmm dS, yyyy"
                    )}
                  </th>
                  {/* <th style={{width:'25%',textAlign:'left',paddingLeft:'3rem'}}>Guidances</th> */}
                  {headingData.length > 0 &&
                    headingData.map((row, i) => (
                      <>
                        <th style={particlarTab}>
                          {dateFormat(
                            currentCompany.current_financial_year,
                            "mmmm dS, yyyy"
                          )}
                        </th>
                        <th style={particlarTab}>
                          {dateFormat(
                            currentCompany.previous_financial_year,
                            "mmmm dS, yyyy"
                          )}
                        </th>
                      </>
                    ))}

                  <th style={particlarTab}>
                    {dateFormat(
                      currentCompany.current_financial_year,
                      "mmmm dS, yyyy"
                    )}
                  </th>
                  <th style={particlarTab}>
                    {dateFormat(
                      currentCompany.previous_financial_year,
                      "mmmm dS, yyyy"
                    )}
                  </th>
                  <th style={particlarTab}>OPENING</th>
                  <th style={particlarTab}>
                    {dateFormat(
                      currentCompany.current_financial_year,
                      "mmmm dS, yyyy"
                    )}
                  </th>
                  <th style={particlarTab}>
                    {dateFormat(
                      currentCompany.previous_financial_year,
                      "mmmm dS, yyyy"
                    )}
                  </th>
                  <th style={particlarTab}>OPENING</th>
                </tr>
              </thead>
              <tbody>
                {fsRows.map((row, i) => {
                  const {
                    cy,
                    py,
                    cy_nca,
                    py_nca,
                    cy_ca,
                    py_ca,
                    cy_ncl,
                    py_ncl,
                    cy_cl,
                    py_cl,
                  } = calculateCyPy(row);
                  const total_assets_py = py_nca + py_ca;
                  const total_assets_cy = cy_nca + cy_ca;
                  const total_liabilities_py = py_ncl + py_cl;
                  const total_liabilities_cy = cy_ncl + cy_cl;
                  // const index = i + 1;

                  // subsidiary
                  // const cyS = subsiary[row]?subsiary[row].reduce((totalCys, i) => totalCys + i.cy, 0):'';
                  // const pyS = subsiary[row]?subsiary[row].reduce((totalPys, i) => totalPys + i.py, 0):'';

                  // let cyS = 0;
                  // let pyS = 0;

                  let noncurrentTotal = 0;
                  let noncurrentTotal_py = 0;
                  //browsfs
                  // const { cyB, pyB, cyB_nca, pyB_nca, cyB_ca, pyB_ca, cyB_ncl, pyB_ncl, cyB_cl, pyB_cl } = calculateCyPyForBrowsFs(row)
                  // const cyB = browsFs[row] ? browsFs[row].reduce((totalCyb, i) => totalCyb + i.cy, 0) : '';
                  // const pyB = browsFs[row] ? browsFs[row].reduce((totalPyb, i) => totalPyb + i.py, 0) : '';
                  // let cyb_0 = 0;
                  // let pyb_0 = 0;
                  // if (browsFs["Non Current Assets"]) {
                  //   cyb_0 = browsFs["Non Current Assets"].reduce(
                  //     (totalCyb, i) => totalCyb + i.cy,
                  //     0
                  //   );
                  //   pyb_0 = browsFs["Non Current Assets"].reduce(
                  //     (totalPys, i) => totalPys + i.cy,
                  //     0
                  //   );
                  // }

                  // let cyb_1 = 0;
                  // let pyb_1 = 0;

                  // if (browsFs["Current Assets"]) {
                  //   cyb_1 = browsFs["Current Assets"].reduce(
                  //     (totalCyb, i) => totalCyb + i.cy,
                  //     0
                  //   );
                  //   pyb_1 = browsFs["Current Assets"].reduce(
                  //     (totalPyb, i) => totalPyb + i.cy,
                  //     0
                  //   );
                  // }

                  // const total_assets_pyb = pyB_nca + pyB_ca;
                  // const total_assets_cyb = cyB_nca + cyB_ca;
                  // let cyb_3 = 0;
                  // let pyb_3 = 0;
                  // let cyb_4 = 0;
                  // let pyb_4 = 0;
                  // cyb_3 = browsFs["Non Current Liabilities"] ? browsFs["Non Current Liabilities"].reduce(
                  //   (totalCyb, i) => totalCyb + i.cy,
                  //   0
                  // ) : '';
                  // pyb_3 = browsFs["Non Current Liabilities"] ? browsFs["Non Current Liabilities"].reduce(
                  //   (totalPyb, i) => totalPyb + i.cy,
                  //   0
                  // ) : '';
                  // cyb_4 = browsFs["Current Liabilities"] ? browsFs["Current Liabilities"].reduce(
                  //   (totalCyb, i) => totalCyb + i.cy,
                  //   0
                  // ) : '';
                  // pyb_4 = browsFs["Current Liabilities"] ? browsFs["Current Liabilities"].reduce(
                  //   (totalPyb, i) => totalPyb + i.cy,
                  //   0
                  // ) : '';

                  let totalCyBsData = 0;
                  let totalPyBsData = 0;

                  return (
                    <>
                      <tr key={i}>
                        <td style={totaledStyle}>{row}</td>
                        <td style={totaledStyle}></td>
                        <td style={totaledStyle}>
                          {cy ? cy.toLocaleString("en-IN") : "-"}
                        </td>
                        <td style={totaledStyle}>
                          {" "}
                          {py ? py.toLocaleString("en-IN") : "-"}
                        </td>

                        {finalSubsidiaryData.length > 0 &&
                          finalSubsidiaryData.map((ele, ind) => {
                            return (
                              <>
                                <td style={totaledStyle}>
                                  {calculateBsCy(ind, ele, row).toLocaleString(
                                    "en-IN"
                                  )}
                                </td>
                                <td style={totaledStyle}>
                                  {calculateBsPy(ind, ele, row).toLocaleString(
                                    "en-IN"
                                  )}
                                </td>
                              </>
                            );
                          })}
                        <td style={totaledStyle}></td>
                        <td style={totaledStyle}></td>
                        <td style={totaledStyle}></td>
                        <td style={totaledStyle}>
                          {calculateBsCyTotal(row, cy)}
                        </td>
                        <td style={totaledStyle}>
                          {calculateBsPyTotal(row, py)}
                        </td>
                        <td style={totaledStyle}></td>
                      </tr>

                      {rows[row].map((line, ix) => {
                        const index = no.findIndex(
                          (x) =>
                            x.notes_grp.toLowerCase() ===
                            line.notes_grp.toLowerCase()
                        );
                        if (
                          no.length > 0 &&
                          no.some(
                            (user) =>
                              user.notes_grp.toLowerCase() ===
                              line.notes_grp.toLowerCase()
                          )
                        ) {
                          return (
                            <>
                              <tr>
                                <td style={lineGroupStyle}>{line.notes_grp}</td>
                                <td
                                  onClick={() => {
                                    handleClick(Number(index + 2));
                                  }}
                                  style={NoteStyle}
                                >
                                  {/* {getIndexNumber(line.notes_no)} || */}
                                  {index + 2}
                                </td>
                                <td>
                                  {line.cy
                                    ? line.cy.toLocaleString("en-IN")
                                    : "-"}
                                </td>
                                <td>
                                  {line.py
                                    ? line.py.toLocaleString("en-IN")
                                    : "-"}
                                </td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map((ele, ind) => {
                                    return (
                                      <>
                                        <td>
                                          {/* {ScenarioCalculateSubCYBs(ele[row],ix)} */}
                                          {calculateBsCy_2(ind, ele, row, ix).toLocaleString("en-IN")}
                                        </td>
                                        <td>
                                          {calculateBsPy_2(ind, ele, row, ix).toLocaleString("en-IN")}
                                        </td>
                                      </>
                                    );
                                  })}
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  {calculateBsCyTotal_2(row, ix, line.cy).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {calculateBsPyTotal_2(row, ix, line.py).toLocaleString("en-IN")}
                                </td>
                                <td></td>
                              </tr>
                            </>
                          );
                        }
                      })}

                      {i === 1 && (
                        <tr>
                          <td style={totalStyle}>
                            Total Non Current & Current Assets
                          </td>
                          <td style={totalStyle}></td>
                          <td style={totalStyle}>
                            {" "}
                            {total_assets_cy
                              ? total_assets_cy.toLocaleString("en-IN")
                              : "-"}
                          </td>
                          <td style={totalStyle}>
                            {total_assets_py
                              ? total_assets_py.toLocaleString("en-IN")
                              : "-"}
                          </td>
                          {finalSubsidiaryData.length > 0 &&
                            finalSubsidiaryData.map((ele, ind) => {
                              const { cy_nca, py_nca, cy_ca, py_ca } =
                                calculateCyPy(ele);
                              const total_assets_pys = py_nca + py_ca;
                              const total_assets_cys = cy_nca + cy_ca;

                              const { first_scenario, second_scenario } =
                                getScenario(ind);
                              if (first_scenario === true) {
                                totalCyBsData += total_assets_cys;
                                totalPyBsData += 0;
                                return (
                                  <>
                                    <td style={totalStyle}>
                                      {total_assets_cys.toLocaleString("en-IN")}
                                    </td>
                                    <td style={totalStyle}>0</td>
                                  </>
                                );
                              } else if (second_scenario === true) {
                                totalCyBsData += total_assets_cys;
                                totalPyBsData += total_assets_pys;
                                return (
                                  <>
                                    <td style={totalStyle}>
                                      {total_assets_cys}
                                    </td>
                                    <td style={totalStyle}>
                                      {" "}
                                      {total_assets_pys}
                                    </td>
                                  </>
                                );
                              } else {
                                totalCyBsData += total_assets_cys;
                                totalPyBsData += total_assets_pys;
                                return (
                                  <>
                                    <td style={totalStyle}>
                                      {total_assets_cys}
                                    </td>
                                    <td style={totalStyle}>
                                      {" "}
                                      {total_assets_pys}
                                    </td>
                                  </>
                                );
                              }
                            })}
                          <td style={totalStyle}></td>
                          <td style={totalStyle}></td>
                          <td style={totalStyle}></td>
                          <td style={totalStyle}>
                            {total_assets_cy + totalCyBsData}
                          </td>
                          {/* (total_assets_cy + totalSub).toLocaleString("en-IN") */}
                          <td style={totalStyle}>
                            {total_assets_py + totalPyBsData}
                          </td>
                          <td style={totalStyle}></td>
                        </tr>
                      )}
                      {i === 4 && (
                        <tr>
                          <td style={totalStyle}>
                            Total Non Current & Current Liabilities
                          </td>
                          <td style={totalStyle}></td>
                          <td style={totalStyle}>
                            {total_liabilities_cy
                              ? total_liabilities_cy.toLocaleString("en-IN")
                              : "-"}
                          </td>
                          <td style={totalStyle}>
                            {total_liabilities_py
                              ? total_liabilities_py.toLocaleString("en-IN")
                              : "-"}
                          </td>
                          {finalSubsidiaryData.length > 0 &&
                            finalSubsidiaryData.map((ele, ind) => {
                              const { cy_ncl, py_ncl, cy_cl, py_cl } =
                                calculateCyPy(ele);
                              
                              const total_liabilities_pys = py_ncl + py_cl;
                              const total_liabilities_cys = cy_ncl + cy_cl;

                              const { first_scenario, second_scenario } =
                                getScenario(ind);

                              if (first_scenario === true) {
                                noncurrentTotal =
                                  noncurrentTotal + total_liabilities_cys;
                                noncurrentTotal_py = noncurrentTotal_py + 0;
                                return (
                                  <>
                                    <td style={totalStyle}>
                                      {total_liabilities_cys.toLocaleString("en-IN")}
                                    </td>
                                    <td style={totalStyle}>0</td>
                                  </>
                                );
                              } else if (second_scenario === true) {
                                noncurrentTotal =
                                  noncurrentTotal + total_liabilities_cys;
                                noncurrentTotal_py =
                                  noncurrentTotal_py + total_liabilities_pys;
                                return (
                                  <>
                                    <td style={totalStyle}>
                                      {total_liabilities_cys.toLocaleString("en-IN")}
                                    </td>
                                    <td style={totalStyle}>
                                      {total_liabilities_pys.toLocaleString("en-IN")}
                                    </td>
                                  </>
                                );
                              } else {
                                noncurrentTotal =
                                  noncurrentTotal + total_liabilities_cys;
                                noncurrentTotal_py =
                                  noncurrentTotal_py + total_liabilities_pys;
                                return (
                                  <>
                                    <td style={totalStyle}>
                                      {total_liabilities_cys.toLocaleString("en-IN")}
                                    </td>
                                    <td style={totalStyle}>
                                      {total_liabilities_pys.toLocaleString("en-IN")}
                                    </td>
                                  </>
                                );
                              }
                            })}

                          <td style={totalStyle}></td>
                          <td style={totalStyle}></td>
                          <td style={totalStyle}></td>
                          <td style={totalStyle}>
                            {(
                              total_liabilities_cy + noncurrentTotal
                            ).toLocaleString("en-IN")}
                          </td>
                          <td style={totalStyle}>
                            {(
                              total_liabilities_py + noncurrentTotal_py
                            ).toLocaleString("en-IN")}
                          </td>
                          <td style={totalStyle}></td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <p className="text-danger">
          {error_message || "Something went wrong!!!"}
        </p>
      )}
    </>
  );
}

export default NewConsoleBalanceSheet;
