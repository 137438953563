import React, { useEffect, useState } from 'react';
import { MatrixNav } from './MatrixNav';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getOneBookClosure } from '../Services/ApiHandler';
import swal from "sweetalert";
import { getBCMatrix } from '../../redux/actions/BookClosure/actionBookClosure';
import ViewMatrixTasks from '../Components/ViewMatrixTasks';
import DynamicTable from '../Components/MatrixDynamicTable';

const Matrix = () => {
    const { workspaceId, companyId } = useParams();
    const dispatch = useDispatch();

    const {
        bookClosureId,
    } = useSelector((state) => state.BookClosureReducer);

    const {
        matrices,
        bcmLoading,
        bcmErrorMessage,
        bcmApiFetched
    } = useSelector((state) => state.BookClosureReducer);

    const [isViewModule, setIsViewModule] = useState(false);
    const [moduleDetails, setModuleDetails] = useState({});

    useEffect(() => {
        getBookClosureData();
        if (!bcmApiFetched) {
            dispatch(getBCMatrix(bookClosureId, companyId));
        }
    }, [dispatch]);

    const getBookClosureData = () => {
        const data = {
            // companies: [],
            // assignees: [],
            // approvers: [userId]
        }
        if (!bookClosureId) {
            getOneBookClosure(workspaceId, data).then(response => {
                dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
                const book_closure_id = response.data.bookClosure._id;
                dispatch(getBCMatrix(book_closure_id, companyId));
            }).catch(error => {
                swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
            })
        }
    }

    return (
        <>
            <MatrixNav />
            <div>
                {
                    bcmLoading ? <p className='w-100 text-center custom-common-theme-text-color'>Loading ....</p> :
                        bcmErrorMessage ? <p className='text-danger' > {bcmErrorMessage} </p> :
                            matrices ?
                                <>
                                    <DynamicTable matrices={matrices} />
                                </>
                                : <></>
                }
            </div>

            <ViewMatrixTasks
                isViewModule={isViewModule}
                setIsViewModule={setIsViewModule}
                setModuleDetails={setModuleDetails}
                moduleDetails={moduleDetails}
            />
        </>
    );
};

export default Matrix;

