import axios from 'axios';
const auth = localStorage.getItem("auth_token");

const headers = {
    "x-auth-token": auth,
};

export const getTrialBalance = async (project_ids, browse_fs_ids,current_comp_project_id) => { 
    return axios.get(`api/v1/tb-mapping/console-get-tb?project_ids=${JSON.stringify(project_ids)}&browse_fs_ids=${JSON.stringify(browse_fs_ids)}&current_comp_project_id=${current_comp_project_id}`,{
        headers
    })
}

export const getTraialBalanceId = async (project_id) => {
    return axios.get(`/api/v1/tb-mapping/get-tb/${project_id}`,{
        headers
    })
}

export const getBalanceSheetData = async (project_id, tb_id) => { 
    return axios.get(`api/v1/bs/generate-bs/${project_id}/${tb_id}`,{
        headers
    })
}

export const getPrepareConsole = async (prepare_console_id) => {
    if(!prepare_console_id)return;
    return axios.get(`api/v1/prepare-consol/get-prepare-console/${prepare_console_id}`,{
        headers
    })
}

export const getWorkspacePrepareConsole = async (prepare_console_id) => {
    if(!prepare_console_id)return;
    return axios.get(`api/v1/prepare-consol/fetch-preConsol-data/${prepare_console_id}`,{
        headers
    })
}



export const getSubsidiaryConsole = async (link_id) => {
    return axios.get(`api/v1/prepare-consol/get-subdisiary-console/${link_id}`,{
        headers
    })
}

export const getProjects = async (workspace_id) => {
    return axios.get(`/api/v1/project/get-project?workspace_id=${workspace_id}`,{
        headers
    })
}

export const getRegisterData = async (company_id) => {
    return axios.get(`/api/v1/prepare-consol/get-registerData/${company_id}`,{
        headers
    })
}

export const getfsData = async (company_id) => {
    return axios.get(`/api/v1/prepare-consol/get-fsData/${company_id}`,{
        headers
    })
}

export const getCompanybyId = async (company_id) => {
    return axios.get(`/api/v1/company/get-onecomapny/${company_id}`,{
        headers
    })
}

export const getProjectDetailById = async (project_id) => {
    if(!project_id)return;
    return axios.get(`/api/v1/project/get-project-details/${project_id}`,{
        headers
    })
}

export const setPresetValue = async (prepare_console_id,project_id) => {
    return axios.post(`/api/v1/prepare-consol/set-period/${prepare_console_id}`, {
        upload_preset: project_id
    }, {headers})
}

export const updateCompanyData = async (comp_id,preset_id,sub_id) => {
    headers["Content-Type"]= "application/json"
    return axios.post(`/api/v1/prepare-consol/update-consolidate/${comp_id}`, {
        subsidiary_upload_preset :{prepare_console_id:sub_id,project_id:preset_id}
    }, {headers})
}

export const updateAssociateData = async (comp_id,preset_id,sub_id) => {
    headers["Content-Type"]= "application/json"
    return axios.post(`/api/v1/prepare-consol/update-associate-data/${comp_id}`, {
        associate_upload_preset :{prepare_console_id:sub_id,project_id:preset_id}
    }, {headers})
}
export const getConsoleBsData = async (project_ids, browse_fs_ids, current_comp_project_id) => {
    return axios.post(`/api/v1/tb-mapping/console-get-bs`, {
        project_ids: project_ids,
        browse_fs_ids: browse_fs_ids,
        current_comp_project_id: current_comp_project_id,
    }, {headers})
}

export const getConsolePnlData = async (project_ids, browse_fs_ids, current_comp_project_id) => {
    return axios.post(`/api/v1/tb-mapping/console-get-pnl`, {
        project_ids: project_ids,
        browse_fs_ids: browse_fs_ids,
        current_comp_project_id: current_comp_project_id
    }, {headers})
}

export const getConsoleGroupingData = async (project_ids, browse_fs_ids, current_comp_project_id) => {
    return axios.post(`/api/v1/tb-mapping/console-get-grouping`, {
        project_ids: project_ids,
        browse_fs_ids: browse_fs_ids,
        current_comp_project_id: current_comp_project_id
    }, {headers})

}

export const updateRegisterData = async (id,data) => {
    return  axios.post(`api/v1/company-data/update-company-data/${id}`,data,{
        headers,
    })

}

export const getSubGrpFs = async (project_id) => {
    return axios.get(`/api/v1/grouping/fs/${project_id}`,{
        headers
    })
}

export const IrRegister = async (data) => {
    return axios.get(`api/v1/company-data/register-company-data`,data,{
        headers
    })
}

export const editRegister = async (rid) => {
    return axios.get(`api/v1/company-data/edit-register/${rid}`,{
        headers
    })
}

export const CreateAdjustment = async (data) => {
    return  axios.post(`api/v1/adjustment-console/create-adjustment`,data,{
        headers,
    })
}

export const GetAdjustment = async (aid) => {
    return  axios.get(`api/v1/adjustment-console/fetch-adjustment/${aid}`,{
        headers,
    })
}

export const DeleteAdjustment = async (aid) => {
    return  axios.get(`api/v1/adjustment-console/delete-adjustment/${aid}`,{
        headers,
    })
}

export const FindOneAdjustment = async (aid) => {
    return  axios.get(`api/v1/adjustment-console/findone-adjustment/${aid}`,{
        headers,
    })
}

export const UpdateAdjustmentDAta = async (aid,data) => {
    return  axios.post(`api/v1/adjustment-console/update-adjustment/${aid}`,data,{
        headers,
    })
}

export const fetchSocie = async (project_ids, browse_fs_ids, current_comp_project_id) => { 
    return axios.get(`/api/v1/socie/console-fetch-socie?project_ids=${JSON.stringify(project_ids)}&browse_fs_ids=${JSON.stringify(browse_fs_ids)}&current_comp_project_id=${current_comp_project_id}`,{
        headers
    })
}

export const CreateIr = async (data) => {
    return  axios.post(`api/v1/company-data/register-company-data`,data,{
        headers,
    })
}

export const getAutoPopulationData = async (aid) => {
    return  axios.get(`api/v1/adjustment-console/fetch-autoAdjustment/${aid}`,{
        headers,
    })
}

export const CreateAutoPopulationData = async (data) => {
    return  axios.post(`api/v1/adjustment-console/create-update-autoAdjustment`,data,{
        headers,
    })
}

export const UpdateAutoPopulationData = async (data) => {
    return  axios.post(`api/v1/adjustment-console/update-autoAdjustment`,data,{
        headers,
    })
}

export const UpdatePreset = async (data,preset_id) => {
    return  axios.post(`api/v1/prepare-consol/preset-data-save/${preset_id}`,data,{
        headers,
    })
}

export const UpdatePresetAssociate = async (data,preset_id) => {
    return  axios.post(`api/v1/prepare-consol/preset-associateData-save/${preset_id}`,data,{
        headers,
    })
}
