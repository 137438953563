import axios from "axios"

const auth = localStorage.getItem("auth_token");
const headers = {
    "x-auth-token": auth,
};

const getAPI = async (url) => {
    return new Promise((resolve, reject) => {
        axios
            .get(url, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export {getAPI};