import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ReturnDataRegisterLogModal({
    setReturnLogModal,
    returnLogModal,
    OnClick
}) {

    const handleClose = () => {
        setReturnLogModal(prev => ({...prev, isOpen: false, returnText: "", registerId: "", title:"" }))
    };

    return (
        <React.Fragment>
            <Dialog
                open={returnLogModal.isOpen}
                onClose={handleClose}
            >
                <DialogTitle minWidth={420} >Reason</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText minWidth={420} className='text-capitalize'>
                        {returnLogModal.title}
                    </DialogContentText> */}
                    <div className='d-flex flex-column mt-2' >
                        {/* <label className='text-secondary'>Reason</label> */}
                        <textarea 
                            className='p-2 rounded shadow text-secondary font-size-14'
                            placeholder='Enter reason....'
                            onChange={(e)=> {
                                setReturnLogModal(prev => ({...prev, returnText: e.target.value }))
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='bg-color-inherit px-3 py-1 rounded shadow text-danger border border-danger' onClick={handleClose}>Cancel</button>
                    <Button
                        className='bg-color-inherit px-3 py-1 rounded shadow text-info border border-info'
                        onClick={OnClick}
                    >
                        Return
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
