import React from "react";
import { Navbar } from "../../../Components/Navbar";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";

const CustomSelect = styled(Select)({
  "& .MuiButtonBase-root": {
    padding: "none",
    justifyContent: "start",
  },
});

const AuthorizedCapital = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const history = useHistory();

  const [shares, setShares] = React.useState("");

  const handleChangeShares = (event) => {
    setShares(event.target.value);
  };
  const [classification, setClassification] = React.useState("");

  const handleChangeClassification = (event) => {
    setClassification(event.target.value);
  };

  const handleSave = () => {
    localStorage.setItem("name", JSON.stringify({ name: "bhabesh" }));
    history.push("/detailsIR/666b1219731e4e35e8776fba");
  };

  return (
    <>
      <div>
        <Navbar />
        <div className="p-4 mt-3">
          <div className="d-flex">
            <p className="mt-0 mb-0 font-size-16 custom-font-600 custom-common-theme-text-color">
              <span className="mr-1 custom-common-theme-text-color">
                <ArrowBackIcon />
              </span>{" "}
              Back
            </p>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <p className="mt-0 mb-0 font-size-16 custom-font-600 text-dark">
              Authorized Capital
            </p>
            <Button
              variant="contained"
              className="custom-common-theme-bg-color text-white font-size-16 custom-font-600 text-capitalize radius-6px font-sans"
              style={{ width: "80px", height: "44px" }}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </div>

          <div style={{ overflowX: "auto", width: "100%" }}>
            <table className="custom-table mt-2" style={{ minWidth: "1300px" }}>
              <thead style={{ backgroundColor: "#F8F8F8" }}>
                <tr>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter border-top-left-radius text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Date
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Class of shares
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Classification
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Number of shares
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Face value per share
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Authorized Capital
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Remarks
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Attached file
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-white text-left">
                    <input
                      type="date"
                      id="date"
                      name="date"
                      placeholder="Select date"
                      style={{
                        border: "none ",
                        width: "90%",
                        color: "#B8B8B8",
                      }}
                      className=" ml-2"
                    />
                  </td>
                  <td className="bg-white text-left">
                    <Select
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="w-100"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em style={{ color: "#B8B8B8" }}>Select shares</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </td>
                  <td className="bg-white text-left">
                    <Select
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="w-100"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em style={{ color: "#B8B8B8" }}>
                          Select Classification
                        </em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </td>
                  <td className="bg-white text-left">
                    <input
                      type="text"
                      id="text"
                      name="date"
                      placeholder="type"
                      style={{ border: "none ", width: "90%" }}
                      className=" ml-2"
                    />
                  </td>
                  <td className="bg-white text-left">
                    <input
                      type="text"
                      id="text"
                      name="date"
                      placeholder="type"
                      style={{ border: "none ", width: "90%" }}
                      className=" ml-2"
                    />
                  </td>
                  <td className="bg-white text-left">
                    <input
                      type="text"
                      id="text"
                      name="date"
                      placeholder="type"
                      style={{ border: "none ", width: "90%" }}
                      className=" ml-2"
                    />
                  </td>
                  <td className="bg-white text-left">
                    <input
                      type="text"
                      id="text"
                      name="date"
                      placeholder="type"
                      style={{ border: "none ", width: "90%" }}
                      className=" ml-2"
                    />
                  </td>
                  <td className="bg-white text-left">
                    <div className="d-flex align-items-center ml-2">
                      <UploadFileIcon
                        className="custom-common-theme-text-color"
                        style={{ width: "18px", height: "18px" }}
                      />
                      <p className=" mt-0 mb-0   font-size-14 custom-font-500 custom-common-theme-text-color">
                        Upload file
                      </p>
                    </div>
                  </td>
                  <td className="bg-white text-left">
                    <div className="d-flex align-items-center ml-2">
                      <DeleteOutlineIcon
                        className="text-danger"
                        style={{ width: "18px", height: "18px" }}
                      />
                      <p className=" mt-0 mb-0  font-size-14 custom-font-500 text-danger">
                        Delete
                      </p>
                    </div>
                  </td>
                </tr>
                <tr className="last-row">
                  <td className="bg-white text-left" colSpan={12}>
                    <div className="d-flex align-items-center justify-content-center">
                      <AddIcon  className="header-text" style={{ width:"20px",height:"20px" }}/>
                      <p className="mt-0 mb-0 ml-1 custom-font-500 font-size-16 header-text">Add New Row</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*  */}
          <div className="d-flex justify-content-between mt-3">
            <p className="mt-0 mb-0 font-size-16 custom-font-600 text-dark">
              Issued Capital
            </p>
            <Button
              variant="contained"
              className="custom-common-theme-bg-color text-white font-size-16 custom-font-600 text-capitalize radius-6px font-sans"
              style={{ width: "80px", height: "44px" }}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </div>
          {/*  */}
          <div className="mt-3" style={{ overflowX: "auto", width: "100%" }}>
            <table className="custom-table mt-2" style={{ minWidth: "1300px" }}>
              <thead style={{ backgroundColor: "#F8F8F8" }}>
                <tr>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter border-top-left-radius text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Shareholder
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Class of share
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Number of share
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Issue price
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    amount
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    face value per share
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Securities Premium per share
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Share capital
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Securities Premium
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Remarks
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Attached file
                  </th>
                  <th
                    className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                    style={{
                      minWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-white text-left">
                    <input
                      type="date"
                      id="date"
                      name="date"
                      placeholder="Select year"
                      style={{
                        border: "none ",
                        width: "90%",
                        color: "#B8B8B8",
                      }}
                      className=" ml-2"
                    />
                  </td>
                  <td className="bg-white text-left">
                    <Select
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="w-100"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em style={{ color: "#B8B8B8" }}>Select shares</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </td>
                  <td className="bg-white text-left">
                    <Select
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="w-100"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em style={{ color: "#B8B8B8" }}>
                          Select Classification
                        </em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </td>
                  <td className="bg-white text-left">
                    <input
                      type="text"
                      id="text"
                      name="date"
                      placeholder="type"
                      style={{ border: "none ", width: "90%" }}
                      className=" ml-2"
                    />
                  </td>
                  <td className="bg-white text-left">
                    <input
                      type="text"
                      id="text"
                      name="date"
                      placeholder="type"
                      style={{ border: "none ", width: "90%" }}
                      className=" ml-2"
                    />
                  </td>
                  <td className="bg-white text-left"></td>
                  <td className="bg-white text-left"></td>
                  <td className="bg-white text-left"></td>
                  <td className="bg-white text-left"></td>
                  <td className="bg-white text-left">
                    <input
                      type="text"
                      id="text"
                      name="date"
                      placeholder="type"
                      style={{ border: "none ", width: "90%" }}
                      className=" ml-2"
                    />
                  </td>
                  <td className="bg-white text-left">
                    <div className="d-flex align-items-center ml-2">
                      <UploadFileIcon
                        className="custom-common-theme-text-color"
                        style={{ width: "18px", height: "18px" }}
                      />
                      <p className=" mt-0 mb-0  font-size-14 custom-font-500 custom-common-theme-text-color">
                        Upload file
                      </p>
                    </div>
                  </td>
                  <td className="bg-white text-left">
                    <div className="d-flex align-items-center ml-2">
                      <DeleteOutlineIcon
                        className="text-danger"
                        style={{ width: "18px", height: "18px" }}
                      />
                      <p className=" mt-0 mb-0  font-size-14 custom-font-500 text-danger">
                        Delete
                      </p>
                    </div>
                  </td>
                </tr>
                <tr className="last-row">
                  <td className="bg-white text-left" colSpan={12}>
                    <div className="d-flex align-items-center justify-content-center">
                      <AddIcon  className="header-text" style={{ width:"20px",height:"20px" }}/>
                      <p className="mt-0 mb-0 ml-1 custom-font-500 font-size-16 header-text">Add New Row</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorizedCapital;
