import axios from "axios"
const auth = localStorage.getItem("auth_token");
const headers = {
    "x-auth-token": auth,
};

export const createPreset = async (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/v1/presets/create-preset`, data, { headers })
            .then((res) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
    });
};

export const getPreset = async (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/v1/presets/get-presets`, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const uploadPresetTb = async (data) => {
    const config = {
        onUploadProgress: progressEvent => {
            let percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
            console.log("percentComplete----", percentComplete);
            if(percentComplete === 100){
                console.log("file uploaded successfully");
            }
        }
    }
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/v1/presets/upload-tb`, data, { headers }, config)
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const deletePresetTempTB = async (temp_id) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/api/v1/presets/delete-temp-tb/${temp_id}`, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const mappingPreset = async (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/v1/presets/preset-tb-mapping`, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const createPresetTB = async (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/v1/presets/create-preset-tb`, data, { headers })
            .then((res) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
    });
};

export const savePreset = async (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/v1/presets/save-preset`, data, { headers })
            .then((res) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
    });
};

export const creatingProject = async (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/v1/presets/create-project`, data, { headers })
            .then((res) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
    });
};

export const checkTBNameAvailable = async (tbName) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/v1/presets/check-tb-name-available?tbName=${tbName}`, { headers })
            .then((res) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
    });
};