import React from "react";
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';


export function NBFC_46p1_46p2({ data, subIndex, HandleChange, dis_index_id }) {
  const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});
  return (
    <>
      <thead>
        <tr>
          <th className="w-auto text-left pl-3 border-top-0 border-bottom-0" rowSpan={2}>
            Sr.No.
          </th>
          <th className="text-left pl-3 w-50 border-top-0 border-bottom-0" rowSpan={2}>
            Ratios
          </th>
          <th className="w-auto border-top-0 border-bottom-0" colSpan={3}>
            As at {""}{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}
          </th>
          <th className="w-auto border-top-0 border-bottom-0" colSpan={3}>
            As at {""} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
          </th>
          <th className="w-auto border-top-0 border-bottom-0" rowSpan={2}>
            Variance
          </th>
          <th className="w-auto border-top-0 border-bottom-0" style={{ maxWidth: "7rem" }} rowSpan={2}>
            Reason for Variance (if above 25%)
          </th>
          <DisclosureNBFCTableHead isEmpty={false} />
        </tr>
        <tr>
          <th className="w-auto border-top-0 border-bottom-0">Numerator</th>
          <th className="w-auto border-top-0 border-bottom-0">Denominator</th>
          <th className="w-auto border-top-0 border-bottom-0">Ratio</th>
          <th className="w-auto border-top-0 border-bottom-0">Numerator</th>
          <th className="w-auto border-top-0 border-bottom-0">Denominator</th>
          <th className="w-auto border-top-0 border-bottom-0">Ratio</th>
          <DisclosureNBFCTableHead isEmpty={true} />
        </tr>
      </thead>

      <tbody>
        {data &&
          data.length > 0 &&
          data.map((subDis, subDisIndex) => (
            <tr>
              <td className="text-left pl-3 border-top-0 border-bottom-0"> {subDis?.sr_no} </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pl-3 text-left"
                  type={"text"}
                  value={subDis?.header}
                  name="header"
                  onChange={(event) =>
                    HandleChange(event, subIndex, subDisIndex)
                  }
                />
              </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  readOnly={typeof subDis?.cy_numerator !== "number"}
                  value={formatIndianCurrency(subDis?.cy_numerator)}
                  name="cy_numerator"
                  onChange={(event) =>
                    HandleChange(event, subIndex, subDisIndex)
                  }
                />
              </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  readOnly={typeof subDis?.cy_denominator !== "number"}
                  value={formatIndianCurrency(subDis?.cy_denominator)}
                  name="cy_denominator"
                  onChange={(event) =>
                    HandleChange(event, subIndex, subDisIndex)
                  }
                />
              </td>

              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  readOnly={typeof subDis?.cy_ratio !== "number"}
                  value={formatIndianCurrency(subDis?.cy_ratio)}
                  name="cy_ratio"
                  onChange={(event) =>
                    HandleChange(event, subIndex, subDisIndex)
                  }
                />
              </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  readOnly={typeof subDis?.py_numerator !== "number"}
                  value={formatIndianCurrency(subDis?.py_numerator)}
                  name="py_numerator"
                  onChange={(event) =>
                    HandleChange(event, subIndex, subDisIndex)
                  }
                />
              </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  readOnly={typeof subDis?.py_denominator !== "number"}
                  value={formatIndianCurrency(subDis?.py_denominator)}
                  name="py_denominator"
                  onChange={(event) =>
                    HandleChange(event, subIndex, subDisIndex)
                  }
                />
              </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  readOnly={typeof subDis?.py_ratio !== "number"}
                  value={formatIndianCurrency(subDis?.py_ratio)}
                  name="py_ratio"
                  onChange={(event) =>
                    HandleChange(event, subIndex, subDisIndex)
                  }
                />
              </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  readOnly={typeof subDis?.variance !== "number"}
                  value={formatIndianCurrency(subDis?.variance)}
                  name="variance"
                  onChange={(event) =>
                    HandleChange(event, subIndex, subDisIndex)
                  }
                />
              </td>{" "}
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  readOnly={typeof subDis?.reason_for_variance !== "number"}
                  value={formatIndianCurrency(subDis?.reason_for_variance)}
                  name="reason_for_variance"
                  onChange={(event) =>
                    HandleChange(event, subIndex, subDisIndex)
                  }
                />
              </td>
              <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                    isReference={subDis?.isReference}  keyValue={21} index={subIndex}  sub_index={subDisIndex} />
            </tr>
          ))}
      </tbody>
    </>
  );
}
