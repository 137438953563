import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function AddNewOptionsModal({
  isOptionsModalOpen,
  setIsOptionsModalOpen,
  setOptions,
  saveData
}) {
  
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isOptionsModalOpen}
        onClose={() => setIsOptionsModalOpen(false)}
      >
        <DialogTitle>Add New Data</DialogTitle>
        <DialogContent>
          <div>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setOptions(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOptionsModalOpen(false)}  className="bg-white shadow rounded custom-border custom-common-theme-text-color text-capitalize w-20">
            Close
          </Button>
          <Button onClick={saveData}  className="text-white shadow rounded custom-border custom-common-theme-bg-color text-capitalize w-20 mr-3">Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
