import { useHistory } from 'react-router-dom';

const useBackNavigation = (defaultRoute) => {
  const history = useHistory();

  const handleBack = () => {
    history.push(defaultRoute); // Replace with your desired default route
  };

  return handleBack;
};

export default useBackNavigation;