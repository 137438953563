/*
userPermission function takes four parameters
companyData = which is the permission data comes from redux
company_id = the id of company
type = create | delete | share
*/
export const userPermission = (companyData, company_id, module, type=null) => {
    const companyBasedData = companyData.find(comp => comp.company === company_id);
    if(companyBasedData){
        const companyComponents = companyBasedData.components.find((each) => each.function === module);
        if(companyComponents){
            {
                if(type){
                    return companyComponents.permission[type];
                } else {
                    return companyComponents.permission
                }
            }
        } else {
            return false
        }
    } else {
        return false
    }
}

export const generatePassword = (length = 9) =>{
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@";
    let generatedPassword = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        generatedPassword += charset[randomIndex];
    }
    return generatedPassword;
}