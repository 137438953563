import React from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const GeneralNoteEditor = ({
    data,
    onChange
}) => {
    return (
        <>
            <CKEditor
                style={{ minHeight: '70vh', zIndex: '0' }}
                editor={ClassicEditor}
                data={data}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChange(data);
                }}
            />
        </>
    )
}

export default GeneralNoteEditor