const WSTypes = {
    WS_ACTION_START:"WS_ACTION_START",
    WS_FETCH_DATA:"WS_FETCH_DATA",
    WS_FETCH_DATA_FAILED:"WS_FETCH_DATA_FAILED",
    WS_ADD_DATA:"WS_ADD_DATA",
    WS_DELETE_DATA:"WS_DELETE_DATA",
    WS_UPDATE_DATA:"WS_UPDATE_DATA",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: "",
}

const WSReducer = (state=Initial_state, action) => {
    switch(action.type){
        case WSTypes.WS_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case WSTypes.WS_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload,
            }

        case WSTypes.WS_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default WSReducer;