import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
    return (
        <>
            <div style={{ height: '80vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner animation="grow" size="sm" />
                <Spinner animation="grow" />
                <Spinner animation="grow" size="sm" />
            </div>
        </>
    )
}

export default Loading