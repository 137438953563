import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Section = ({ title, isActive, onClick }) => {
  return (
    <button
      className={`w-100 border-0 d-flex align-items-center justify-content-between py-2 px-3 radius-10px ${
        isActive ? "active-section" : "inactive-section"
      }`}
      onClick={onClick}
    >
      <p
        className={`mb-0 mt-0 custom-font-500 font-size-14 ${
          isActive ? "custom-font-600" : ""
        }`}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
          textTransform:"capitalize"
        }}
      >
        {title}
      </p>

      <span
        className="radius-10px"
        style={{
          height: "25px",
          width: "25px",
          backgroundColor: isActive ? "white" : "#F4F6F6",
        }}
      >
        <ArrowForwardIosIcon
          className="ml-1"
          style={{ width: "18px", height: "18px", color: "#717171" }}
        />
      </span>
    </button>
  );
};

export default Section;
