import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import UploadImageComponent from './UploadImageComponent';
import LoaderTwo from '../../../Components/Common/Loader/LoaderTwo';

export default function AddMoreImagesModal({
    isAddMoreImageModal,
    setIsAddMoreImageModal,
    images,
    setImages,
    UploadMoreImage
}) {

    const { 
        isMoreImageUploadLoading
    } = useSelector((state) => state.SupportReducer);

    return (
        <React.Fragment>
            <Dialog
                open={isAddMoreImageModal}
                onClose={() => setIsAddMoreImageModal(false)}
            >
                <DialogTitle>Upload Image</DialogTitle>
                <DialogContent>
                    <UploadImageComponent 
                        images={images}
                        setImages = {setImages}
                    />
                </DialogContent>
                <DialogActions>
                    <button 
                        onClick={() => {
                            setIsAddMoreImageModal(false);
                            setImages([]);
                        }}
                        className='border border-danger py-2 px-3 bg-white text-danger font-weight-bold radius-6px shadow'
                    >
                        Cancel
                    </button>
                    <button 
                        className='py-2 px-3 custom-common-theme-bg-color text-white font-weight-bold radius-6px shadow border-0'
                        onClick={UploadMoreImage}
                    >
                        { isMoreImageUploadLoading ? <div style={{ height:"24px", width:"60px" }} > <LoaderTwo /> </div> : "Upload"}
                    </button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
