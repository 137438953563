import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function BrowsFsModal({
    setIsBrowsModalOpen,
    isBrowsModalOpen,
    allProjects,
    details,
    setDetails,
    handleSubmitFs
}) {

    return (
        <React.Fragment>
            <Dialog
                open={isBrowsModalOpen}
                onClose={() => setIsBrowsModalOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Browse control date FS"}
                </DialogTitle>
                <DialogContent style={{ minWidth: "420px" }}>
                    <select
                        className="w-100 px-2 py-2 rounded mt-2"
                        onChange={(e) => setDetails(prev => ({...prev, browseFsCompanyId: e.target.value}))}
                        value={details.browseFsCompanyId}
                    >
                        <option>select</option>
                        {
                            allProjects.length > 0 && allProjects?.map(project => (
                                <option key={project?._id} value={project?._id} >{project?.project_name}</option>
                            ))
                        }
                    </select>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setIsBrowsModalOpen(false)}
                        className="bg-white py-1 shadow rounded custom-border custom-common-theme-text-color text-capitalize w-25"
                    >
                        Close
                    </Button>
                    <Button
                        onClick={handleSubmitFs}
                        className="text-white shadow rounded custom-border custom-common-theme-bg-color text-capitalize w-25 mr-3"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
