import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import IconButton from "@material-ui/core/IconButton";
// import Snackbar from "@material-ui/core/Snackbar";
// import Button from "@material-ui/core/Button";
import times from "../../assets/times.svg";
import tick from "../../assets/confirmTb.svg";
import { Form } from "react-bootstrap";
// import {groupings} from '../BalanceSheet/mockGrouping'
import swal from "sweetalert";
import { motion } from "framer-motion";

// import CloseIcon from "@material-ui/icons/Close";

import { batchUpdateConventionalMode } from "../../redux/actions/TemplateTabs/actionCM";

const card = {
  position: "fixed",
  bottom: "3%",
  right: "2%",
  width: "300px",
  // height:'55px',
  backgroundColor: "white",
  zIndex: "99",
  borderRadius: "2px",
  boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
  // boxShadow: '5px 10px #888888'
};
const action = {
  borderRadius: "2px",
  height: "50px",
  backgroundColor: "var(--clr-bg)",
  zIndex: "99",
  display: "flex",
  alignItems: "center",
};
const form = {
  borderRadius: "2px",
  height: "170px",
  backgroundColor: "white",
  zIndex: "99",
};

const MultiSelect = (props) => {
  const dispatch = useDispatch();
  const {multiSelectedRows} = useSelector((state) => state.selectedDragReducer);
  const multiSelectedRows_ = useMemo(()=>multiSelectedRows, [multiSelectedRows])
  const TB_ID = useSelector((initialState) => initialState.reducer.tb_id);
  const groupData = useSelector((state) => state.TTGroupingReducer);
  const [open, setOpen] = React.useState(false);
  const [menu, setMenu] = React.useState(false);
  // const [groupings, setGroupings] = useState(group);
  const [selectedFs, setSelectFs] = useState("");
  const [selectedNoteGroup, setSelectNoteGroup] = useState("");
  const [selectedSubGroup, setSelectSubGroup] = useState("");
  const conventionModeData = useSelector(
    (state) => state.ConventionModeReducer
  );


  const handleClose = () => {
    setOpen(false);
    setMenu(false);
  };

  useEffect(() => {
    if (multiSelectedRows_.length > 0) {
      setOpen(true);
    }else{
      setOpen(false);
      setMenu(false);
    }
  }, [multiSelectedRows_]);

  const handlePost = () => {
    swal({
      title: "Are you sure?",
      text: "clicking on ok, will change the the groupings of the selected rows",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        const {notes_grp, notes_no} = selectedNoteGroup ? JSON.parse(selectedNoteGroup) : { notes_grp: '', notes_no: '' }; 
        const updatedData = multiSelectedRows_.map((data)=>{
          return {
            ...data,
            fs_grp: selectedFs,
            note_grp: notes_grp,
            note_no: notes_no,
            sub_grp: selectedSubGroup
          }
        })
        dispatch(batchUpdateConventionalMode(conventionModeData.set_payload, updatedData))
        swal("Groupings have been added to the selected rows!", {
          icon: "success",
        });
      } else {
        swal("Ledger not deleted!");
      }
    });
  }

  function handleAddOptions() {
    if ((selectedFs === "") | (selectedNoteGroup === "")) {
      return swal("Warning", "Please select the Fs and Notes groupings", "warning");
    }
    
    props.setAddGrouping(true);
    const newSg = {};
    newSg.fs_grp = selectedFs;
    newSg.note_grp = selectedNoteGroup;
    props.SetNewSg(newSg);
    setMenu(false);
  }

  const handleGroupData = (type) => {
    const groupDataRes = groupData.response ?? [];
    switch(type){
      case "fs_grp":
        if(groupData.isSuccess){
          return groupDataRes?.fs_grp?.map(fsGrp=> fsGrp.fs_grp)
        }else{
          return []
        }
      case "note_grp":
        if(groupData.isSuccess && selectedFs){
          return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === selectedFs)
          .notes_grp.map(notes => {return {notes_grp: notes.notes_grp, notes_no: notes.notes_no}})
        }else{
          return []
        }
      case "sub_grp":
        if(groupData.isSuccess && selectedFs && selectedNoteGroup){
          return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === selectedFs)
          ?.notes_grp.find(note => note.notes_grp === JSON.parse(selectedNoteGroup).notes_grp)?.sub_grp.map(subGrp => subGrp.sub_grp)
        }else{
          return []
        }
      default:
        return []
    }
  }

  return (
    <>
      {open && (
        <div style={card}>
          <div style={action}>
            <p style={{ marginRight: "auto", marginLeft: "10px" }}>
              Multi select Menu
            </p>
            {selectedFs !== "" || selectedNoteGroup !== "" || selectedSubGroup !== "" ? (
              <img
                style={{
                  marginLeft: "auto",
                  marginRight: "0px",
                  transform: "scale(1)",
                }}
                src={tick}
                alt="tick"
                onClick={() => {
                  handlePost();
                }}
              />
            ) : null}
            <i
              style={{
                marginLeft: "auto",
                transform: "scale(1.5)",
                cursor: "pointer",
              }}
              className={menu ? "fas fa-angle-down" : "fas fa-angle-up"}
              onClick={() => {
                setMenu(!menu);
              }}
            />
            <img
              style={{ margin: "10px", transform: "scale(0.6)" }}
              alt="times"
              src={times}
              onClick={() => {
                handleClose();
              }}
            />
          </div>
          {menu && (
            <motion.div
              style={form}
              // initial={{y:'-1vh'}}
              // animate={{y:0}}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <div>
                <Form.Control
                  size="lg"
                  as="select"
                  value={selectedFs}
                  onChange={(e) => {
                    setSelectFs(e.target.value)
                  }}
                >
                  <option key="blankChoice" hidden value>
                    {"Select Fs grouping"}
                  </option>
                  {handleGroupData('fs_grp')?.map((fsGrp, idx) => {
                    return <option value={fsGrp} key={idx}>{fsGrp}</option>;
                  })}
                </Form.Control>
              </div>
              <div>
                <Form.Control
                  size="lg"
                  as="select"
                  value={selectedNoteGroup}
                  onChange={(e) => {
                    setSelectNoteGroup(e.target.value)
                  }}
                >
                  <option key="blankChoice" hidden value>
                    {"Select Notes grouping"}
                  </option>
                  {handleGroupData('note_grp')?.map((opt, idx) => {
                    return <option value={JSON.stringify(opt)} key={idx}>{opt.notes_grp}</option>;
                  })}
                </Form.Control>
              </div>
              <div>
                <Form.Control
                  size="lg"
                  as="select"
                  value={selectedSubGroup}
                  onChange={(e) => {
                    setSelectSubGroup(e.target.value)
                  }}
                >
                  <option key="blankChoice" hidden value>
                    {"Select Sub grouping"}
                  </option>
                  {handleGroupData('sub_grp')?.map((opt, idx) => {
                    return <option value={opt} key={idx}>{opt}</option>;
                  })}
                </Form.Control>
              </div>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "left",
                  paddingLeft: "1.1rem",
                  color: "var(--clr-accent)",
                }}
                onClick={() => {
                  handleAddOptions();
                }}
              >
                + add options
              </div>
            </motion.div>
          )}
        </div>
      )}
    </>
  );
};

export default MultiSelect;
