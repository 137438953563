import { Checkbox, CardContent, Typography, TextField } from "@mui/material";
import ImageUpload from "./ImageUpload";
import CloseIcon from "@mui/icons-material/Close";

const AddHeaders = ({
  sampleHeaders,
  headers,
  handleCheckBox,
  handleHeaders,
  uploadImages,
  close,
  popHeaderCount,
  key
}) => {

  let remainingHeaders = close !== 0 ? sampleHeaders.filter(ele => !headers.slice(0, close).map(item => item.sheets).flat().includes(ele)) : sampleHeaders;
  if(remainingHeaders.length === 0 || (remainingHeaders.length===1 && remainingHeaders.includes('All Pages'))){
    popHeaderCount(close);
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChecked = (close, header) => {
    const headerSheet = headers.find(ele => ele.header === close);
    if(headerSheet === undefined){
      return false
    }
    let RefinedSampleHeaders = remainingHeaders.filter((head)=> head !== 'All Pages');
    let all_present = RefinedSampleHeaders.every(ele => headerSheet.sheets.includes(ele)) 
    if(headerSheet){
      return headerSheet.sheets.includes(header) || headerSheet.sheets.includes('All Pages') || all_present || false;
    } else {
      return false
    }
  }

  return (
    <CardContent>
      <div
            style={{
                display: "flex",
                justifyContent: "space-between", // Distributes space between text and icon
                alignItems: "center", // Vertically centers the content
                padding: "0 10px", // Adds padding on the left and right
            }}
        >
            <Typography
                className="font-size-16 custom-font-600"
                sx={{
                    fontSize: 16,
                    lineHeight: "20px",
                    color: "#000000",
                }}
                gutterBottom
            >
                Add Header to:
            </Typography>
            {close !== 0 &&  <CloseIcon 
                className="custom-redcolor ml-1 mt-1" 
                style={{ cursor: "pointer", backgroundColor: 'lightpink', borderRadius: '50%' }} 
                onClick={()=>{popHeaderCount(close)}}
            /> }
        </div>
      <div className="d-flex flex-wrap justify-content-between">
        {remainingHeaders.map((header, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              flex: "0 1 100px", // Adjust the width as needed
              marginBottom: "5px", // Adds spacing between rows
            }}
          >
            <Checkbox
              {...label}
              name="sheet1"
              value={header}
              onChange={(e)=>handleCheckBox(e, close)}
              checked={
                handleChecked(close, header)
              }
              sx={{
                "&.Mui-checked": {
                  color: "#03565A ", // Color when checked
                },
              }}
            />
            <h6 className="mr-2 mt-2">{header}</h6>
          </div>
        ))}
      </div>
      <div className="mt-2 ">
        <TextField
          onChange={(e)=>handleHeaders(e, close)}
          multiline
          maxRows={5}
          name="header1"
          placeholder="Header Data"
          variant="outlined"
          className="w-100 finalise-textcolor rounded"
        />
      </div>
      <div>
        <ImageUpload uploadImages={uploadImages} headerNumber={close} name={"image1"} />
      </div>
    </CardContent>
  );
};

export default AddHeaders;
