export const unrestrictedAccess_ = [
    {
        "title": "Creation of company",
        "function": "creationOfCompany",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },

]

export const rawCompanyData = [
    {
        "title": "Workspace",
        "function": "creationOfWorkspace",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },
    {
        "title": "Investment Register",
        "function": "investmentRegister",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },
    {
        "title": "Far",
        "function": "far",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },
    {
        "title": "Leases",
        "function": "leases",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },
    {
        "title": "Loan Register",
        "function": "loanRegister",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },
    {
        "title": "Payable",
        "function": "payable",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },
    {
        "title": "Receivable",
        "function": "receivable",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },
    {
        "title": "Sales",
        "function": "sales",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },
    {
        "title": "Purchases",
        "function": "purchases",
        "permission": {
            "create": false,
            "delete": false,
            "share": false,
            "all_access_granted": false,
        }
    },
]