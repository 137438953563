import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert';
import { toast } from "react-toastify";

import filter from '../../assets/filter.svg'
import download from '../../assets/download.svg'
import { Navbar } from '../../Components/Navbar'
import arrow from '../../assets/arrowLeftGreen.svg'
import { fetchDeletedAdj, fetchSAAdjustment } from '../../redux/actions/actionStandaloneAdj';
import { restoreAdjustment } from '../../services/api/apiHandler';
import Loading from '../../Components/Common/Loader/Loading';
import { socket } from '../../services/socket/socket';

const adjustmentsTable = {
  minWidth: '1045px',
  height: '100vh'
}

const AdjustmentsDeleted = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const project_id = localStorage.getItem('project_id');
  const tb_id = localStorage.getItem("tb_id");

  const {
    dltAdjLoading,
    dle_adj_error_message,
    dltAdjIsSuccess,
    dleAdjData
  } = useSelector((initialState) => initialState.ADJStandaloneReducer);

  useEffect(() => {
    if (!dltAdjIsSuccess) {
      dispatch(fetchDeletedAdj(project_id, tb_id));
    }
  }, [dltAdjIsSuccess]);

  const handleRestore = (item, index) => {
    const data = {
      item,
      index
    }
    swal({
      title: "Are you sure?",
      text: "Click Ok to restore adjustment item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          restoreAdjustment(project_id, tb_id, data).then(response => {
            toast.success(response.data?.message || "Item Restored Successfully");
            dispatch(fetchDeletedAdj(project_id, tb_id));
            dispatch(fetchSAAdjustment(project_id, tb_id));
            socket.emit("request-conventional-mode", { project_id, tb_id })
            socket.emit("request-mapped-grouping", { project_id, tb_id });
            socket.emit("request-balance-sheet", { project_id, tb_id })
            socket.emit("request-pnl-statement", { project_id, tb_id });
          }).catch(error => {
            swal("Error", error.response?.data?.error || "Internal Server Error", "error")
          })
        }
      });

  }

  const nf = new Intl.NumberFormat();


  return (
    <div className='adjustmentsTable' style={adjustmentsTable}>
      <Navbar text='Adjustments' />
      <div className="adjustmentsMenu">
        <div className="adjustment ml-0"
          onClick={() => { history.push('/adjustments') }}
        >
          <img src={arrow} alt='back' style={{ transform: 'scale(0.7)', marginRight: '5px' }} />
          <p style={{ fontWeight: '600', color: 'var(--clr-accent)' }}>Back</p>
        </div>
        <div className="adjustment">
          <img src={filter} alt='filter' />
          <p>Filter</p>
        </div>
        <div className="adjustment">
          <img src={download} alt='download' />
          <p style={{ color: 'var(--clr-accent q)' }}>Export Logs</p>
        </div>
      </div>
      {
        dltAdjLoading ? <Loading /> :
          <table
            className='px-3'
          >
            <thead className='custom-font-600' >
              <tr>
                <th className='text-left pl-3' style={{ width: "5%" }} >S no</th>
                <th style={{ width: "8%" }} >Ledger Code</th>
                <th className='text-left pl-3' style={{ width: "32%" }} >Ledger Name</th>
                <th style={{ width: "10%" }} >Branch</th>
                <th className='text-right pr-3' style={{ width: "10%" }} >31 Mar 2021</th>
                <th style={{ width: "10%" }} className='text-right pr-3' >Adjustments</th>
                <th style={{ width: "15%" }} >Remark</th>
                <th style={{ width: "10%" }} className='text-center pl-3'>Action</th>
              </tr>
            </thead>
            <tbody>
              {dleAdjData?.length > 0 && dleAdjData.map((adj, i) => {
                const sortedKeys = Object.keys(adj);
                const first = adj[sortedKeys[0]];
                return first.map((val, ix) => {
                  return (
                    <tr className='bg-white' key={ix}>
                      {
                        ix === 0 ?
                          <td className='text-left pl-3' rowSpan={ix === 0 && first.length}>{i + 1}</td> : <></>
                      }

                      <td className='text-center'>{val?.ledger_code}</td>
                      <td className='text-left pl-3'>{val?.ledger_name}</td>
                      <td className='text-center'>{val?.branch}</td>
                      <td className='text-right pr-3'>{nf.format(val.cy)}</td>
                      <td className='text-right pr-3'>{nf.format(val.adjusted_amount)}</td>
                      {
                        ix === 0 ?
                          <td className='text-center' rowSpan={ix === 0 && first.length}>{adj?.remarks}</td> : <></>
                      }
                      {
                        ix === 0 ?
                          <td className='text-left' rowSpan={ix === 0 && first.length}>
                            <div className='d-flex justify-content-center align-items-center pl-3' >
                              <div className='cursor-pointer' onClick={() => { handleRestore(adj, i) }}>
                                <p className='custom-font-600 font-size-16 m-0 custom-common-theme-text-color'>Restore</p>
                              </div>
                            </div>
                          </td> : <></>
                      }
                    </tr>
                  )
                })
              })}
            </tbody>
          </table>
      }
      {
        dleAdjData?.length <= 0 &&
        <p className="w-100 text-center text-secondary" > Deleted Adjustment Data Is Empty. </p>
      }
    </div>
  )
}

export default AdjustmentsDeleted
