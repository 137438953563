import React, { useState } from 'react';
import { Box, Button, Modal } from '@mui/material';
import swal from 'sweetalert';
import { addDeleteDraftWorkspace, createRequest } from '../ApiServices/apiHandler';
import { socket } from '../../../services/socket/socket';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

const CancelDeleteWorkspace = ({openCancelDeleteModal,handleCloseCancelDeleteModal,GetRequests,requestId,getdeleteDraftWorkspaces,deleteCompanyId,preparer_id,sendUser}) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [reason, setReason] = useState('');
  
  const declineRequestForAccess=async()=>{
    if(deleteCompanyId){
    const data={
      request_id:requestId,
      status:"declined",
      cancel_reason:reason
    }
    const response = await createRequest(data)
    if(response.status === 200){
      GetRequests();
      handleCloseCancelDeleteModal();
      swal("", "Request Declined", "warning");
      socket.emit("add-notification", {
        receiver_id: sendUser,
        description: "Deletion of workspace",
        type:"cancelled request",
        path:"/Notification"
      });
      socket.emit('get-notification');
    }
   }else{
    const data={
        draft_id:requestId,
        status:"cancelled",
        cancel_reason:reason,
        approver_id:user._id
      }
      addDeleteDraftWorkspace(data)
          .then(response => {
          getdeleteDraftWorkspaces();
          handleCloseCancelDeleteModal();
          swal("", "Cancelled the approval successfully", "warning");
          socket.emit("add-notification", {
            receiver_id: preparer_id,
            description: "Deletion of workspace",
            type:"cancelled approval",
            path:"/Notification"
          });
          socket.emit('get-notification');
          })
          .catch(error => {
            console.error('Error:', error);
          });
   }
  }

  return (
    <div>
      <Modal
        open={openCancelDeleteModal}
        onClose={handleCloseCancelDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
          <div>
            <label>Cancel Reason:</label>
            <textarea 
              className="w-100"
              value={reason}
              onChange={(event) => setReason(event.target.value)}
            />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Button
              style={{
                backgroundColor: "#03565a",
                color: "white",
                textTransform: "none",
                width: "50%",
                marginTop: "10px",
              }}
              onClick={declineRequestForAccess}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CancelDeleteWorkspace;
