import React from 'react';
import { useSelector } from 'react-redux';
import './nbfcDisclosureNotes.css';
import moment from "moment";
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';




export function NBFC_12_17({data, subIndex, dis_index_id,HandleChange}) {
 const reduxProjectData = useSelector((initialState) => initialState.DisclosureReducer.projectData);

    return (
        <>
            <thead>
                <tr>
                    <th className='text-left w-75 pl-3 border-top-0 border-bottom-0' >Particulars</th>
                    <th className='w-auto border-top-0 border-bottom-0'> Year ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0'> Year ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <DisclosureNBFCTableHead isEmpty={false} />
                </tr>
            </thead>
        
            <tbody>
            {
                data && 
                data.length > 0 && 
                data.map((subDis, subDisIndex) => (
                    <>
                        <tr>
                            <td className='border-top-0 border-bottom-0'>
                                <input
                                    style={{backgroundColor:"inherit",fontSize:"16px"}}
                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                    type={"text"}
                                    value={subDis?.header}
                                    name="header"
                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                />
                            </td>

                            <td className='border-top-0 border-bottom-0' style={{minWidth:"110px"}}>
                                <input
                                    style={{backgroundColor:"inherit"}}
                                    className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                    readOnly={typeof subDis?.cy !== "number"}
                                    type={"text"}
                                    value={formatIndianCurrency(subDis?.cy)}
                                    name="cy"
                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                />
                            </td>
                            <td className='border-top-0 border-bottom-0'style={{minWidth:"110px"}}>
                                <input
                                    style={{backgroundColor:"inherit"}}
                                    className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                    type={"text"}
                                    readOnly={typeof subDis?.py !== "number"}
                                    value={formatIndianCurrency(subDis?.py)}
                                    name="py"
                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                />
                            </td>
                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} isReference={subDis?.isReference} keyValue={1} index={subIndex}  sub_index={subDisIndex}/>
                        </tr>  
                        {/* {
                            [2, 5].includes(subDisIndex) && (
                                <>
                                    <tr>
                                        <td className="font-weight-bold pl-3 text-left border-top-0 border-bottom-0 ">
                                            {
                                                subDisIndex === 2 ? "Weighted average number of equity shares for Basic Earnings per share" :
                                                subDisIndex ===  5 ? "Dilution effect on EPS after ESOP and Preference Shares" : ""
                                            }
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </>
                            )
                        } */}
                        
                    </>
                ))
            }
            </tbody>
        </>      
    );
}