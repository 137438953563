import React, { useEffect, useRef, useState } from "react";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function Note1() {
  const editor = useRef();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  
  useEffect(() => {
    console.log(editorState);
  }, [editorState]);

  return (
    <div>
      <div style={{ padding: '0 25px', display: 'flex' }}>
        <div style={{ width: '300px' }}>
          <input type='text' style={{ border: 'none', fontWeight: '600', backgroundColor: 'transparent' }} placeholder='enter title here'></input>
        </div>
      </div>

      <div style={{ padding: '0 20px', minHeight: '500px', zIndex: '0' }}>

        <CKEditor
          style={{ minHeight: '70vh', zIndex: '0' }}
          editor={ClassicEditor}
          // data="<p>Hello from CKEditor 5!</p>"
          onReady={editor => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
      </div>
    </div>
  );
}