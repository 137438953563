import React,{useState,useEffect} from 'react'
import './authStyles.css'
import accountant from '../assets/g10.svg'
import Logo from '../assets/Logo-my.svg'
import { Form } from "react-bootstrap";
import ForgotPwd from './ForgotPwd';
import {Link} from 'react-router-dom'
import axios from 'axios'
import {GoogleLogin} from 'react-google-login'
import {Submit} from '../Components/Button'
import {useHistory} from 'react-router-dom'
import swal from 'sweetalert';



const CreatePassword = ({ match: {params}}) => {
    let history = useHistory()
    const [error, seterror] = useState(false)
    const [password, setPassword] = useState('')
    const [confrmpwd ,setConfrmpwd] = useState('')
    const [ShowPwd, setShowPwd] = useState(false)
    const [ShowCmpwd, setShowCmpwd] = useState(false)

    const { id } = params;

    // function handleChange(e){
    //     setValue({...Value,[e.target.name]:e.target.value})
    //     console.log(Value,error,'values and errors')
    // }
    
    // function validation(){
        
    //     const err = (Value.NewPassword === Value.RetypePassword | Value.RetypePassword.length === 0 ? false : true)
    //     seterror(err)
        
    // }
    
    // useEffect(() => {
    //     validation()
        
    // },[Value])
    
    
    // const responseGoogle = (res) => {
    //     console.log(res,'response from google')
    // };

    async function handleSignUp(){

        console.log('api call',password)
        const fd = {
            password:password,
            confrmpwd:confrmpwd,
            userId:id
        }
        console.log(fd);
        if(!fd.password && !fd.confrmpwd){
            return swal("All fields should be filled!")
        }
        if(fd.password.length < 6){
           return swal("You should create password with atleast 7 characters")
        }

        if(fd.password === fd.confrmpwd){

          await  axios.post('api/v1/superadmin/createpassword',fd)
            .then(res =>{
                console.log(res,'api call')
                setPassword('')
                setConfrmpwd('')
                swal("Your Password Created Successfully,Now you can login with new password! Go to Signin").then(() => {
                    history.push("/");
                });
            })
            .catch(err=>{
                console.log(err.response)
                swal(err.response)
                // swal(err.response.data.error)
                // swal("", err.response.data.error , "error");
      
            })

        }
        else{
             
            swal("Password and Confirm password not match!Try again carefully")
            return (
                setConfrmpwd('')
            )
        }
    }


    return (
        <div className='conatiner'>
            <div className="column-1">
                <div className="logo" style={{display:'flex'}}><img src={Logo}></img></div>
                <div className="pg-title" style={{display:'flex'}}><h1>Create Password</h1></div> 
                <div className="password-signin" style={{display:'flex',flexDirection:'column'}}>
                    <h3>Enter New Password</h3>
                        <input type="text" id="fname"  onChange={event => setPassword(event.target.value)} value={password} name="NewPassword" placeholder=""  type={!ShowPwd? "password" :"text"}/>
                        <i onClick={()=>setShowPwd(!ShowPwd)} class= {ShowPwd ? "fas fa-eye" : "far fa-eye-slash"} />
                </div>  
                <div className="password-signin" style={{display:'flex',flexDirection:'column'}}>
                    <h3>Retype Password</h3>
                        <input type="text" id="fname"  onChange={event => setConfrmpwd(event.target.value)} value={confrmpwd} name="RetypePassword" placeholder=""  type={!ShowCmpwd? "password" :"text"}/>
                        <i onClick={()=>setShowCmpwd(!ShowCmpwd)} class= {ShowCmpwd ? "fas fa-eye" : "far fa-eye-slash"} />
                        {error ? <p style={{color:'red',marginRight:'auto',fontSize:'15px'}}>password doesn't match</p> : null}

                </div>  
            
                <div className='submit' onClick={()=>handleSignUp()} >{error? <Submit  disable value="Create password"/> : <Submit   value="Create password"/> }</div>
            
            </div>
            <div className="column-2"><img src={accountant} alt='accountant working' ></img></div>
        </div>
    )
}

export default CreatePassword
