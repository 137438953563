import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';
import { filterOptionsData, consoleNotesData } from "../services/mockHandler";
import { Box } from '@mui/material';
import BSModalView from './bsModalView';
import { bsModalStyle } from "../Styles/style";
import CircularProgress from '@mui/material/CircularProgress';
import { fetchGroupData } from "../../../redux/actions/actionGrouping";
import { fetchBSData } from '../../../redux/actions/actionBS';
import { fetchPNLData } from '../../../redux/actions/actionPNL';
import { fetchSocieData } from '../../../redux/actions/actionSocie';
import { useDispatch, useSelector } from 'react-redux';


const BsModal = (props) => {
    const { open, handleClose, allcheckdata, consoleData, currentCompProjectId } = props;
    const [filterData, setFilterData] = useState([]);
    const [ notes, setNotes ] = useState([]);
    const balanceSheet = useSelector((state) => state.BSReducer);
    const pnlStatement = useSelector((state) => state.PNLReducer);
    const getSocie = useSelector((state)=>state.SoccieReducer);
    const groupingData = useSelector((state)=>state.GroupingReducer);

    const dispatch =  useDispatch()

    useEffect(()=>{
        (async()=>{
            if(open){
                // await Promise.all([fetchConsolePnlData()])
                if (filterData.length === 0) {
                    const filter_res = await filterOptionsData();
                    setFilterData(filter_res.data);
                }
                if(notes.length === 0){
                    const notes_data = await consoleNotesData();
                    setNotes(notes_data.data);
                }
            }
            const project_ids = allcheckdata.map(data => data.project_id);
            const browse_fs_ids = allcheckdata.map(data =>  data?.all_data?.browse_control_dateFS);
            if(!groupingData.isSuccess || groupingData.set_payload !== JSON.stringify({project_ids, browse_fs_ids, currentCompProjectId})){
                dispatch(fetchGroupData(project_ids, browse_fs_ids, currentCompProjectId))
            }
            if(!balanceSheet.isSuccess || balanceSheet.set_payload !== JSON.stringify({project_ids, browse_fs_ids, currentCompProjectId})){
                dispatch(fetchBSData(project_ids, browse_fs_ids, currentCompProjectId))
            }
            if(!pnlStatement.isSuccess || pnlStatement.set_payload !== JSON.stringify({project_ids, browse_fs_ids, currentCompProjectId})){
                dispatch(fetchPNLData(project_ids, browse_fs_ids, currentCompProjectId))
            }
            const payload = { project_ids:  project_ids, browse_fs_ids: browse_fs_ids, current_comp_project_id: currentCompProjectId}
            if(!getSocie.isSuccess || getSocie.payload !== JSON.stringify(payload)){
                dispatch(fetchSocieData(payload));
              }
        })()
    }, [open])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={bsModalStyle}>
                    {balanceSheet.loading
                        ? <CircularProgress disableShrink/>
                        : balanceSheet.isSuccess
                            ?
                            <div class="card-body">
                                <button class="btn-close float-end" type="button" data-bs-dismiss="modal" aria-label="Close"></button>

                                <div class="col-sm-12">
                                    <div class="card">
                                        <div style={{ maxHeight: "77vh", overflowY: "scroll" }}>
                                            <div
                                                style={{ minHeight: "77vh", width: "100vw" }}
                                            >
                                                {balanceSheet.isSuccess
                                                    ?
                                                    <BSModalView
                                                        balanceSheetData = { balanceSheet }
                                                        pnlStatementData = { pnlStatement }
                                                        groupingData = {groupingData }
                                                        filterData={filterData}
                                                        consoleData={consoleData}
                                                        allcheckdata={allcheckdata}
                                                        currentCompProjectId={currentCompProjectId}
                                                        consoleNotes={notes}
                                                    />
                                                    :
                                                    ""
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <p className='text-danger'>{balanceSheet.error_message}</p>
                    }
                </Box>
            </Modal>
        </>
    )
}
export default BsModal;