import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';


export function NBFC_44({ data, subIndex, HandleChange, dis_index_id }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});

    return (
        <>
            <thead>
                <tr>
                    <th className='w-auto border-top-0 border-bottom-0' >Sr.No</th>
                    <th className='text-left w-50 pl-3 border-top-0 border-bottom-0' colSpan={2}>Particulars</th>
                    <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>As at {""}{moment(reduxProjectData.current_year).format("DD MMMM YYYY")} </th>
                    <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>As at {""} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <DisclosureNBFCTableHead isEmpty={false} />
                </tr>
            </thead>
            <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            <tr>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pr-0 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.sr_no !== "number"}
                                        value={formatIndianCurrency(subDis?.sr_no)}
                                        name="sr_no"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>

                                <td className='border-top-0 border-bottom-0' colSpan={2}>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                        type={"text"}
                                        value={subDis?.header}
                                        name="header"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pr-0 text-right pr-3'
                                        type={"text"}
                                        readOnly={typeof subDis?.cy !== "number"}
                                        value={formatIndianCurrency(subDis?.cy)}
                                        name="cy"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pr-0 text-right pr-3'
                                        type={"text"}
                                        readOnly={typeof subDis?.py !== "number"}
                                        value={formatIndianCurrency(subDis?.py)}
                                        name="py"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                 isReference={subDis?.isReference} keyValue={19} index={subIndex}  sub_index={subDisIndex}/>
                            </tr>
                        </>
                    ))
                }
            </tbody>
        </>
    );
}