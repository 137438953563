import React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";

const SelectResponsibility = ({
    company,
    onChange
}) => {
    return (
        <>
            <Accordion>
                <AccordionSummary
                    className="text-uppercase font-inter"
                    expandIcon={
                        <ExpandMoreIcon
                            style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "48px",
                                backgroundColor: "#F8F8F8",
                                color: "#717171",
                            }}
                        />
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    {Array.isArray(company) ? "Access Granted" : company.company.company_name}
                </AccordionSummary>
                <AccordionDetails>
                    <div className="container w-75">
                        <p className="mt-0 mb-0  font-size-12 custom-font-600 font-sans">
                            Access to:
                        </p>
                        <Card
                            className="mt-2"
                            style={{
                                border: "1px solid #D6D6D6",
                                borderRadius: "10px",
                            }}
                        >
                            <table>
                                <thead className='custom-bg-white-150'>
                                    <tr>
                                        <th
                                            style={{
                                                borderTopLeftRadius: "10px",
                                            }}
                                            className="text-uppercase font-inter font-size-12 custom-font-600 text-left"
                                        >
                                            Data
                                        </th>
                                        <th className="text-uppercase font-inter font-size-12 custom-font-600">
                                            Create
                                        </th>
                                        <th className="text-uppercase font-inter font-size-12 custom-font-600">
                                            Delete
                                        </th>
                                        <th className="text-uppercase font-inter font-size-12 custom-font-600">
                                            Share
                                        </th>
                                        <th
                                            style={{ borderTopRightRadius: "10px" }}
                                            className="text-uppercase font-inter font-size-12 custom-font-600"
                                        >
                                            All Access
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Array.isArray(company) ? 
                                            company.length > 0 && company.map((component, index) => (
                                                <TableRowPermission 
                                                    component={component}
                                                    index={index}
                                                    onChange={onChange}
                                                />
                                            ))
                                        : company.components.length > 0 && company.components.map((component, index) => (
                                            <TableRowPermission 
                                                component={component}
                                                index={index}
                                                onChange={onChange}
                                            />
                                        ))
                                    }
                                </tbody>
                            </table>
                        </Card>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default SelectResponsibility

const CheckBoxPermission = ({
    index,
    checked,
    name,
    onChange
}) => {
    return <input
        type="checkbox"
        style={{ width: "20px", height: "20px" }}
        name={name}
        checked={checked}
        onChange={(event) => onChange(event.target, index)}
    />
}

const TableRowPermission = ({
    index,
    component,
    onChange
}) => {
    return <tr key={index}>
        <td
            className="text-left border-top-0 border-bottom-0 mt-0 mb-0 pl-2 font-size-16 custom-font-500 font-sans"
        >
            {component.title}
        </td>
        <td className="border-top-0 border-bottom-0">
            <CheckBoxPermission
                index={index}
                checked={component.permission.create}
                name="create"
                onChange={onChange}
            />
        </td>
        <td className="border-top-0 border-bottom-0">
            <CheckBoxPermission
                index={index}
                checked={component.permission.delete}
                name="delete"
                onChange={onChange}
            />
        </td>
        <td className="border-top-0 border-bottom-0">
            <CheckBoxPermission
                index={index}
                checked={component.permission.share}
                name="share"
                onChange={onChange}
            />
        </td>
        <td className={`border-top-0 border-bottom-0`}>
            <CheckBoxPermission
                index={index}
                checked={component.permission.all_access_granted}
                name="all_access_granted"
                onChange={onChange}
            />
        </td>
    </tr>
}