import React, { useState } from 'react'
import GeneralEditor from './GeneralEditor';
import swal from 'sweetalert';
import { postAPI, updateAPI } from '../../services/api/StandAlone/generalNote';
import { socket } from '../../services/socket/socket';
import { alphaBitArr } from '../../helper/MathematicsCalculation';
import LoaderTwo from '../Common/Loader/LoaderTwo';

const input = {
    // boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    border: "none", height: "2.2rem", padding: "0.3rem"
}
const shadow = {
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
}

const GeneralEditorNote2 = ({ HandleEditorChange, note2, setNote2, value, value2, ind }) => {
    const project_id = localStorage.getItem('project_id');
    const [note2Loader, setNote2Loader] = useState(false);

    const HandleCreateForm = () => {
        let data = {}
        setNote2Loader(true);
        postAPI(`api/v1/general-notes/sub/${project_id}`, data).then(res => {
            setNote2Loader(false);
            setNote2(res?.data?.data);
        }).catch(err => {
            console.log("create response ---", err);
            setNote2Loader(false);
            let errorData = err.response?.data?.message ? err.response?.data?.message : "Error happing at the time of calculation, please try after some time.";
            swal("Error", errorData, "error");
        })
    }

    const HandleChange = (ind, data, name) => {
        let copy = [...note2]
        copy.forEach((ele, i) => {
            if (i === ind) {
                if (name === "text") {
                    ele.text1 = data
                } else if (name === "text1") {
                    ele.text2 = data
                } else {
                    ele.message = data
                }
            }
        })
        setNote2(copy);
        socket.emit("save-general-note", {
            project_id: `${project_id}`,
            contents: copy,
            contentType: "general_note_1",
        });
    }

    const HandleDelete = (ind) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let data = {index:ind}
                updateAPI(`api/v1/general-notes/sub/${project_id}`, data).then(res => {
                    setNote2(res?.data?.data);
                    swal("Deleted successfully!", {
                        icon: "success",
                    });
                }).catch(err => {
                    console.log("create response ---", err);
                    let errorData = err.response?.data?.message ? err.response?.data?.message : "Error happing at the time of calculation, please try after some time.";
                    swal("Error", errorData, "error");
                })
            }
        });
    }

    return (
        <>
            <div className='px-3 mt-3'><strong className='font-sans' style={{ fontSize: "1.3rem" }} >Significant accounting policies</strong></div>
            <GeneralEditor
                text={{
                    text1: "2.1 Basis of preparation",
                    text2: "Ind AS 1.16"
                }}
                value={value}
                onChange={(data) => {
                    HandleEditorChange(data, ind, value2);
                }}
            />
            <div className="d-flex justify-content-between align-items-center mt-3">
                <p className="mt-0 mb-0 pl-3 font-sans">2.3 Summary of significant accounting policies</p>
                <small className="pr-3 font-sans">{"Ind AS 1.117(b) Ind AS 1.112"}</small>
            </div>

            {
                note2 && note2.length > 0 && note2.map((ele, ix) => (
                    <div key={ix} className='mx-5 my-2 p-1 pb-2' style={shadow}>
                        <div className='d-flex justify-content-between align-items-center mx-3'>
                            <p>{alphaBitArr[ix] + "."}</p>
                            <div className='d-flex justify-content-between ml-2 mt-2 w-100' style={{ gap: "1rem" }}>
                                <input className='w-50' style={input} value={ele?.text1} onChange={(e) => HandleChange(ix, e.target.value, "text")} />
                                <input className='w-50' style={input} value={ele?.text2} onChange={(e) => HandleChange(ix, e.target.value, "text1")} />
                            </div>
                            <div onClick={() => HandleDelete(ix)} style={{ cursor: "pointer" }} className="financialInstrument-deleteIconHover fas fa-trash-alt mt-3 px-3" />
                        </div>
                        <GeneralEditor
                            text={null}
                            value={ele?.message}
                            onChange={(data) => {
                                HandleChange(ix, data, "text2");
                            }}
                        />
                    </div>
                ))
            }

            {
                note2Loader ? <div className='d-flex justify-content-center ml-5 py-1' style={{width:"10rem"}}> <LoaderTwo /> </div> : 
                <button className='my-2 deleteBtn' style={{ border: "none", backgroundColor: "white", marginLeft: "3rem" }}
                    onClick={() => { HandleCreateForm() }}
                >
                    <div className='px-4 py-2 font-sans' style={{ color: "#03565a", fontWeight: "600" }}>+ Create New Note</div>
                </button>
            }

            <GeneralEditor
                text={{
                    text1: "2.4 Changes in accounting policies and disclosures",
                    text2: ""
                }}
                value={value2}
                onChange={(data) => {
                    HandleEditorChange(value, ind, data);
                }}
            />
        </>
    )
}

export default GeneralEditorNote2