// import {React,useState}from 'react';
import NavBarV2 from "../../NavBarV2";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "../../../WorkSpace/font.css";
import "../../Workspace2/sidebar.css";
import {
  VerifyOtp,
  cities,
  country,
  getTrialBalance,
  signupV2,
  state,
} from "../ApiServices/apiHandler";
import swal from "sweetalert";
import { useLocation } from "react-router";

const Validation = (props) => {
  const location = useLocation();
  let a = location.pathname;
  let w_id = a.split("/");
  const [error, seterror] = useState({
    org_name: true,
    email: true,
    password: true,
    confirmPwd: true,
  });
  const [Value, setValue] = useState("");
  useEffect(() => {}, []);

  function handleChange(field, value) {
    setValue(value);
  }

  const handleVerify = async () => {
    let data = {
      otp: Value,
      user_id: w_id[2],
    };
    const result = await VerifyOtp(data);
    if (result.status === 200) {
      // localStorage.setItem("auth_token", result.data.token);
      props.history.push("/");
      // window.location.reload();
    } else {
      swal("", "Wrong OTP", "danger");
    
    }
  };

  // return (
  //   <div style={{ backgroundColor: "#f5f5f5", padding: "25px" }}>
  //     <NavBarV2 />
  //     <div style={{ marginTop: "90px" }}>
  //       <center>
  //         <h1>
  //           <h3 className="fs-inter db-v2-head">Validate Your OTP!</h3>
  //         </h1>
  //         <p className="dm-sans dbv2-dhead">
  //           With MyFinalyst, you can easily create and audit financial
  //           <br />
  //           statements for your company or clients.
  //         </p>
  //       </center>

  //       <div
  //         style={{
  //           width: "518px",
  //           height: "64px",
  //           marginLeft: "600px",
  //           marginTop: "130px",
  //         }}
  //       >
  //         <div
  //           className="password-signin"
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             color: "#696F79",
  //           }}
  //         >
  //           <strong>Enter your OTP</strong>
  //           <input
  //             name="org_name"
  //             type="text"
  //             onChange={(e) => handleChange("otp", e.target.value)}
  //           />
  //         </div>
  //         <Button
  //           style={{
  //             backgroundColor: "#03565A",
  //             color: "white",
  //             height: "65px",
  //             width: "100%",
  //             marginTop: "30px",
  //             fontSize: "20px",
  //           }}
  //           onClick={handleVerify}
  //         >
  //           Verify OTP
  //         </Button>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <NavBarV2 />
      <div style={{ marginTop: "90px" }}>
        <center>
          <h1>
            <h3 className="fs-inter db-v2-head">Validate Your OTP!</h3>
          </h1>
          <p className="dm-sans dbv2-dhead">
            With MyFinalyst, you can easily create and audit financial
            <br />
            statements for your company or clients.
          </p>
        </center>

        <div className="input_box">
          <div
            className="password-signin"
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#696F79",
            }}
          >
            <strong>Enter your OTP</strong>
            <input
              name="org_name"
              type="text"
              onChange={(e) => handleChange("otp", e.target.value)}
            />
          </div>
          <Button
            style={{
              backgroundColor: "#03565A",
              color: "white",
              height: "65px",
              width: "100%",
              marginTop: "30px",
              fontSize: "20px",
            }}
            onClick={handleVerify}
          >
            Verify OTPts
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Validation;
