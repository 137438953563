import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionItem = ({ task, index }) => {
  return (
    <>
      <Accordion
        className="mt-1"
        style={{ borderRadius: "12px", overflow: "hidden",}}
      >
        <AccordionSummary
          className=" w-100 "
          style={{ borderRadius: "12px" ,padding: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              style={{
                backgroundColor: "#F2F3F4",
                height: "24px",
                width: "24px",
                borderRadius: "6px",
              }}
            />
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
         <p className="mt-0 mb-0 font-sans  custom-font-500 font-size-16"> {task?.name}</p>
        </AccordionSummary>
        <AccordionDetails className="mx-3" style={{borderTop:"3px solid #F8F8F8"}}>
          <p className="m-0 mb-3 custom-font-500 font-size-12 text-secondary text-left">
            {task?.description}
          </p>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AccordionItem;
