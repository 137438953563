import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Container from "@mui/material/Container";

import Button from "@mui/material/Button";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Mylogo from "./../../assets/LogoWhit2.svg";
import LogoutIcon from "@mui/icons-material/Logout";

const pages = ["Profile", "Settings", "Support", "Companies"];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Navbarv3() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "#03565A" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{ marginLeft: "40px" }}>
          <img
            src={Mylogo}
            alt="Logov2"
            style={{ height: "60px", width: "100px" }}
          />

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", justifyContent: "center" },
            }}
          >
            <p style={{ fontSize: "14px" }}>
              Home{" "}
              <span>
                <ArrowDropDownIcon />
              </span>
            </p>

            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  textTransform: "none",
                }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, marginRight: "40px" }}>
            <LogoutIcon style={{ height: "30px", width: "30px" }} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbarv3;
