import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import TaskIcon from '@mui/icons-material/Task';


const BookClosureModuleTasks = ({
    ViewTaskMore
}) => {
    const {
        bookClosureModules,
    } = useSelector((state) => state.BookClosureReducer);

    return (
        <>
            <table>
                <thead className="text-white custom-bg-white-150">
                    <tr style={{ height: "48px", backgroundColor: "#D6D6D6" }}>
                        <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter" style={{ width: "200px" }}>Tasks</th>
                        <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter" style={{ width: "100px" }}>Execute</th>
                        <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter" style={{ width: "150px" }}>  Assignee</th>
                        <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter" style={{ width: "100px" }}>Approver</th>
                        <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter" style={{ width: "100px" }}>Due</th>
                        <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter" style={{ width: "100px" }}>Notification</th>
                        <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter" style={{ width: "100px" }}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        bookClosureModules &&
                            bookClosureModules.length > 0
                            ?
                            bookClosureModules.map((module, index) => (
                                <React.Fragment key={index}>
                                    <tr style={{ backgroundColor: "#E6EEEF" }}>
                                        <td className='text-left pl-2 text-capitalize text-black font-size-16 custom-font-500' colSpan={7} > {module.module} </td>
                                    </tr>
                                    {module?.tasks && module?.tasks.length > 0 && module?.tasks.map((task, index) => (
                                        <tr key={index}>
                                            <td className="text-left pl-2 custom-font-500 font-size-16 font-sans mt-1">
                                                {task?.name}
                                            </td>
                                            <td className="text-left pl-2">
                                                <button
                                                    className='d-flex justify-content-center align-items-center mt-2 border-0 bg-color-inherit'
                                                    onClick={() => ViewTaskMore(task._id)}
                                                >
                                                    <TaskIcon style={{ width: '14px', height: '14px', color: '#03565A', marginTop: '-3px' }} />
                                                    <p className='mt-0 mb-0 custom-font-500 font-size-16 ml-1 custom-common-theme-text-color font-sans'>View more</p>
                                                </button>
                                            </td>
                                            <td className="text-left pl-2 custom-font-500 font-size-16 font-sans">
                                                {task.assigneeData.map(assign => assign?.name).join(', ')}
                                            </td>
                                            <td className="text-left pl-2 custom-font-500 font-size-16 font-sans">
                                                {task.approverData?.name}
                                            </td>
                                            <td className="text-left pl-2 custom-font-500 font-size-16 font-sans">
                                                {task?.dueDate ? dayjs(task?.dueDate).format("DD-MM-YYYY") : ""}
                                            </td>
                                            <td className="text-left pl-2 custom-font-500 font-size-16 font-sans"></td>
                                            <td className="text-left pl-2 custom-font-500 font-size-16 font-sans">
                                                {task.status}
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            )) :
                            <tr>
                                <td colSpan={7} className='text-secondary font-size-16 custom-font-600 font-inter' > Once you add tasks, you'll see them here.</td>
                            </tr>
                    }
                </tbody>
            </table>
        </>
    )
}

export default BookClosureModuleTasks