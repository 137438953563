import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { Modal, Button } from '@material-ui/core';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    backgroundColor: "#F8F8F8",
    border: 'none',
    borderRadius: 2,
    p: 2,
}
const units = [ "Whole Numbers", "Hundreds", "Thousands", "Lakhs", "Crores" ];

const SelectColumn = ({
    isSelectColumnModal,
    tbHeaders,
    headersMappedData,
    onChangeTB,
    TBModalCleanUp,
    SetTbHeaders
}) => {

    return (
        <Modal
            open={isSelectColumnModal}
            onClose={TBModalCleanUp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal}>
                <div className='p-2'>
                    <div>
                        <p className='mt-0 mb-0 font-size-24 custom-font-600 font-sans'>Map your Trial Balance</p>
                        <p className=' font-size-16 custom-font-500 text-secondary font-sans'>Map column headers to the below tags </p>
                    </div>
                    <div>
                        <Card className='radius-8px bg-white'>
                            <div className="container">
                                <div className="row mb-3 mt-3">
                                    <div className="col-md-4">
                                        <InputLabel className='font-sans' htmlFor="outlined-basic ">Ledger code<span className='text-danger'>*</span></InputLabel>
                                        <SelectMenu
                                            key={"ledgerCode"}
                                            data={tbHeaders}
                                            name={"ledgerCode"}
                                            value={headersMappedData["ledgerCode"]}
                                            onChange={onChangeTB}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <InputLabel className='font-sans' htmlFor="outlined-basic">Ledger Name<span className='text-danger'>*</span></InputLabel>
                                        <SelectMenu
                                            key={"ledgerName"}
                                            data={tbHeaders}
                                            name={"ledgerName"}
                                            value={headersMappedData["ledgerName"]}
                                            onChange={onChangeTB}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <InputLabel className='font-sans' htmlFor="outlined-basic">Amount in<span className='text-danger'>*</span></InputLabel>
                                        <SelectMenu
                                            key={"amountIn"}
                                            data={units}
                                            name={"amountIn"}
                                            value={headersMappedData["amountIn"]}
                                            onChange={onChangeTB}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <InputLabel className='font-sans' htmlFor="outlined-basic">Period End Balance<span className='text-danger'>*</span></InputLabel>
                                        <SelectMenu
                                            key={"periodEndingBalance"}
                                            data={tbHeaders}
                                            name={"periodEndingBalance"}
                                            value={headersMappedData["periodEndingBalance"]}
                                            onChange={onChangeTB}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <InputLabel className='font-sans' htmlFor="outlined-basic">Period Beginning Balance<span className='text-danger'>*</span></InputLabel>
                                        <SelectMenu
                                            key={"periodBeginingBalance"}
                                            data={tbHeaders}
                                            name={"periodBeginingBalance"}
                                            value={headersMappedData["periodBeginingBalance"]}
                                            onChange={onChangeTB}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <InputLabel className='font-sans' htmlFor="outlined-basic">Import Mapping from</InputLabel>
                                        <TextField className="w-100" id="outlined-basic" variant="outlined" />
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div className='d-flex mt-3 exit-btnv1' style={{ gap: "15px" }}>
                            <Button className='primary-border text-capitalize custom-common-theme-text-color custom-font-600 font-size-16 radius-6px font-sans btn-exit ' onClick={TBModalCleanUp}>Exit</Button>
                            <Button className='custom-common-theme-bg-color text-white text-capitalize custom-font-600 font-size-16 radius-6px font-sans btn-exit' onClick={SetTbHeaders} >Confirm</Button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default SelectColumn;

const SelectMenu = ({
    data,
    name,
    value,
    onChange
}) => {
    return (
        <>
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                className='w-100'
                placeholder={`Select ${name}`}
                name={name}
                value={value}
                onChange={onChange}
            >
                <MenuItem value={"select"} > Select </MenuItem>
                {
                    data && data.length > 0 && data.map((ele, ix) => (
                        <MenuItem key={ix} value={ele} > {ele} </MenuItem>
                    ))
                }
            </Select>
        </>
    )
}