const PNLTypes = {
    PNL_ACTION_START:"PNL_ACTION_START",
    PNL_FETCH_DATA:"PNL_FETCH_DATA",
    PNL_FETCH_DATA_FAILED:"PNL_FETCH_DATA_FAILED",
    PNL_ADD_DATA:"PNL_ADD_DATA",

    NOTE_PNL_NUMBER: "NOTE_PNL_NUMBER",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
    set_payload: "",

    notePnlNo: 0,
}


const PNLReducer = (state=Initial_state, action) => {
    switch(action.type){
        case PNLTypes.PNL_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case PNLTypes.PNL_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                set_payload: action.payload.set_payload
            }

        case PNLTypes.PNL_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }
        case PNLTypes.NOTE_PNL_NUMBER: 
            return {
                ...state,
                notePnlNo: action.payload
            }
        default:
            return state;
    }
};

export default PNLReducer;