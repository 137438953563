import { Box, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from "axios";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const DeleteDuplicate = ({ CMResponse, duplicateItems, openDuplicateModal, setDuplicateModal, fetchConvential }) => {

  const handleDuplicateDelete = (lid) => {

    let rows = {
      lid: lid
    }

    console.log(rows);
    const auth = localStorage.getItem("auth_token");


    const project_id = localStorage.getItem("project_id");
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };

    axios
      .delete(`api/v1/conventional-mode/duplicate-entry/${project_id}`, {
        headers: headers,
        data: { rows },
      })
      .then((response) => {
        console.log(
          response.data,
          " duplicate response ---------------------------------"
        );
        fetchConvential(true);
        // props.setDeleteRow(true);

      })
      .catch((error) => {
        console.log(error.response, "error");
      });
  }


  return (
    <Modal
      open={openDuplicateModal}
      onClose={() => setDuplicateModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div className="custom-font-600">
            <Stack spacing={2} >
              <Item className="font-weight-bold text-danger" >TB NOT BALANCING CY BY{" "}
                <span> {CMResponse?.cy_balance ? CMResponse?.cy_balance.toLocaleString("en-IN") : ""}</span>
              </Item>
              <Item className="font-weight-bold text-danger" >TB NOT BALANCING PY BY{" "}
                <span> {CMResponse?.py_balance ? CMResponse?.py_balance.toLocaleString("en-IN") : ""}</span>
              </Item>
            </Stack>
          </div>

          {
            duplicateItems.length > 0 && 
            <div className='tableDeleted' id='containerDiv' >
              <div className="mb-2">
                <h1 className="font-size-24 custom-font-600 mt-3 mb-0">Duplicate items</h1>
                <small className="text-muted">Remove duplicate Items from here.</small>
              </div>
              <table className="w-100">
                <thead>
                  <tr>
                    <th>LEDGER CODE</th>
                    <th>LEDGER NAME</th>
                    <th>PY</th>
                    <th>CY</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {duplicateItems.map((duplicates, i) => (
                      <React.Fragment key={i}>
                        <tr key={duplicates.lid}>
                          <td>{duplicates.ledger_code}</td>
                          <td>{duplicates.ledger_name}</td>
                          <td>{duplicates.py}</td>
                          <td>{duplicates.cy}</td>
                          <td className="cursor-pointer" onClick={() => handleDuplicateDelete(duplicates.lid)}>
                            <RemoveCircleIcon />
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
      </Box>
    </Modal>
  )
}

export default DeleteDuplicate;