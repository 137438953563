import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { useHistory, useLocation} from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import swal from "sweetalert";
import { toast } from "react-toastify";
import "./addmin.css";
import { getUserPermission, updateUserPermission } from "../WorkSpace/Components/ApiServices/apiHandler";
import SelectResponsibility from "./Components/SelectResponsibility";
import AdminNavbar from "./Components/AdminNavbar";

const GeneralDetails = () => {
    const history = useHistory(); 
    const { childUserId } = useParams();

    const [userPermissionData, setUserPermissionData] = useState([]);
    const [unrestrictedAcessData, setUnrestrictedAcessData] = useState([]);

    useEffect(() => {
      getUserPermissionData();
    }, [])

    const getUserPermissionData = () => {
      getUserPermission(childUserId).then(response => {
        setUserPermissionData(response.data.result.companyData);
        setUnrestrictedAcessData(response.data.result.unrestrictedAcessData);
      }).catch(error => {
        swal(
          "Error",
          error.response?.data?.error_message || "Selected Company Added Failed!",
          "error"
        );
      });
    }

    const onCheckBoxChange = (e, companyIndex, componentIndex) => {
      const { name } = e;
      if(companyIndex === "unrestrictedAcess" ){
        setUnrestrictedAcessData(prevData => {
          const newData = [...prevData];
          const permissionsToUpdate = newData[componentIndex]?.permission;
          if (!permissionsToUpdate) return prevData;
          const newValue = !permissionsToUpdate[name];
          if (name === "all_access_granted") {
            Object.keys(permissionsToUpdate).forEach(key => {
              if (!["all_access_granted", "_id"].includes(key)) {
                permissionsToUpdate[key] = newValue;
              }
            });
          }
          permissionsToUpdate[name] = newValue;
          return newData;
        })
      }else{
        setUserPermissionData(prevData => {
          const newData = [...prevData];
      
          const permissionsToUpdate = newData[companyIndex]?.components[componentIndex]?.permission;
          
          if (!permissionsToUpdate) return prevData;

          const newValue = !permissionsToUpdate[name];

          if (name === "all_access_granted") {
            Object.keys(permissionsToUpdate).forEach(key => {
              if (!["all_access_granted", "_id"].includes(key)) {
                permissionsToUpdate[key] = newValue;
              }
            });
          }
          permissionsToUpdate[name] = newValue;
      
          return newData;
        });
      }
    };

    const updatePermissionData = () => {
      updateUserPermission(childUserId, {
        userPermissionData: userPermissionData.map(role => ({
          company_id: role.company._id,
          components: role.components,
        })),
        unrestrictedAccessData: unrestrictedAcessData
      }).then(res => {
        toast.success("Responsibility updated successfully.");
        history.push("/admin");
      }).catch(error => {
        swal(
          "Error",
          error.response?.data?.error_message || "Responsibility Updated Failed!",
          "error"
        );
      })
    }

  return (
    <>
      <AdminNavbar />
      <div className="px-4 py-3">
        <Card className="w-100 radius-12px">
          <div className="px-3 py-3">
            <div>
              <p className="mt-0 mb-0 font-size-18 custom-font-600 font-sans ">
                Add Teamate
              </p>
              <p className="mt-0 mb-2 font-size-16 custom-font-500 font-sans">
                Fill out all the requirements to create new Teammate
              </p>
            </div>
            <div className="mt-3 general-box">
              <div className="d-flex flex-wrap p-2">
                <div className="d-flex justify-content-center align-items-center mt-1 Primary-circle">
                  {/* <p className="mt-0 mb-0 font-size-12 custom-font-600 font-sans">
                    1
                  </p> */}
                  <CheckIcon className="text-white" />
                </div>
                <div className="flex-grow-1">
                  <p className="mt-2 p-1 mb-0 font-size-12 custom-font-600 font-sans custom-common-theme-text-color">
                    General Details
                    <span className="text-secondary">
                      -------------------------------------------------------
                    </span>
                  </p>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-1 orange-circle">
                  <p className="mt-0 mb-0 font-size-12 custom-font-600 font-sans">
                    2
                  </p>
                </div>
                <div className="flex-grow-1">
                  <p
                    className="mt-2 p-1 mb-0 font-size-12 custom-font-600 font-sans"
                    style={{ color: "brown" }}
                  >
                    Select Companies
                    <span className="text-secondary">
                      -------------------------------------------------------
                    </span>
                  </p>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center mt-1 white-circle"
                  style={{
                    border: "1px solid #8692A6",
                  }}
                >
                  <p className="mt-0 mb-0 font-size-12 custom-font-600 font-sans">
                    3
                  </p>
                </div>
                <div className="flex-grow-1">
                  <p className="mt-2 p-1 mb-0 font-size-12 custom-font-600 font-sans text-secondary">
                    Responsibilities
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-3 px-1 py-2">
              <SelectResponsibility
                company={unrestrictedAcessData}
                onChange={(e, index) => onCheckBoxChange(e, "unrestrictedAcess", index)}
              />
              {userPermissionData && userPermissionData.length > 0 &&
                userPermissionData.map((company, cx) => (
                  <SelectResponsibility 
                    key={cx}
                    company={company}
                    onChange={(e, index) => onCheckBoxChange(e, cx, index)}
                  />
                ))
              }
            </div>

            <div
              className="d-flex justify-content-end mt-4"
              style={{ gap: "10px" }}
            >
              <Button
                variant="contained"
                style={{ width: "330px", height: "50px" }}
                className="bg-white custom-common-theme-text-color primary-border radius-10px font-size-16 custom-font-500 text-capitalize  font-sans "
                onClick={() => history.push("/admin-user")}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{ width: "330px", height: "50px" }}
                className="custom-common-theme-bg-color radius-10px font-size-16 custom-font-500 text-capitalize  font-sans"
                onClick={updatePermissionData}
              >
                Next
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default GeneralDetails;
