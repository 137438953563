import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Navbar } from '../../Components/Navbar';
import { useSelector } from 'react-redux';
// import * as actionTypes from "../../redux/actionTypes";

const CoaDetails = () => {
    
    const coaData = useSelector((initialState) => initialState.CoaReducer.coaData);
    // const [coaArr, setCoaArr] = useState(coaData);

    return (
        <>
        <Navbar />

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">LEDGER CODE</TableCell>
                        <TableCell align="left">LEDGER NAME</TableCell>
                        <TableCell align="left">{"FS GROUPING (CY)"}</TableCell>
                        <TableCell align="left">{"NOTES GROUPING (CY)"}</TableCell>
                        <TableCell align="left">{"SUB GROUPING (CY)"}</TableCell>
                        <TableCell align="left">{"FS GROUPING (PY)"}</TableCell>
                        <TableCell align="left">{"NOTES GROUPING (PY)"}</TableCell>
                        <TableCell align="left">{"SUB GROUPING (PY)"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {coaData.map((coa, cx) => (
                    <TableRow key={cx} >
                        <TableCell align="left">{coa?.ledger_code}</TableCell>
                        <TableCell align="left">{coa?.ledger_name}</TableCell>
                        <TableCell align="left">{coa?.map_data?.cy_map?.fs_grp}</TableCell>
                        <TableCell align="left">{coa?.map_data?.cy_map?.note_grp}</TableCell>
                        <TableCell align="left">{coa?.map_data?.cy_map?.sub_grp}</TableCell>
                        <TableCell align="left">{coa?.map_data?.py_map?.fs_grp}</TableCell>
                        <TableCell align="left">{coa?.map_data?.py_map?.note_grp}</TableCell>
                        <TableCell align="left">{coa?.map_data?.py_map?.sub_grp}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    );
}
export default CoaDetails;