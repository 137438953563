import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";

export default function GetPresetNameModal({
    isNameModal,
    setIsNameModal,
    SaveAsPreset,
    isSavePresetLoading,
    name,
    setName
}) {

    const handleClose = () => {
        setIsNameModal(false);
    };

    return (
        <React.Fragment>
            <Dialog open={isNameModal} onClose={handleClose}>
                <DialogTitle>Preset Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter Preset Name</DialogContentText>
                    <input
                        style={{ width: "20rem" }}
                        className="rounded"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        name="name"
                        type="text"
                        placeholder="preset name"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button style={{ width: "5rem", height: "2rem" }} onClick={SaveAsPreset}>
                        {
                            isSavePresetLoading ? <CircularProgress style={{height:"1rem", width:"1rem"}} /> : "Save"
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
