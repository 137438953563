import React from 'react'
import { Navbar } from '../../Components/Navbar'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

const SubscribedPage = () => {
  const {search} = useLocation();
  const params = new URLSearchParams(search)
  const reference = params.get('reference')
  console.log(reference,'reference------------->')
  return (
    <>
    <Navbar/>
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
    <h4>Thankyou your payment successfull on id:---<i style={{textDecoration:'underline'}}>{reference}</i></h4></div>
    </>
    
  )
}

export default SubscribedPage