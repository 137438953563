const supportType = {
    SUPPORT_MORE_IMAGE_UPLOAD_LOADER: "SUPPORT_MORE_IMAGE_UPLOAD_LOADER",
    SUPPORT_VIEW_DETAILS: "SUPPORT_VIEW_DETAILS",
}

const Initial_state = {
    isMoreImageUploadLoading: false,
    viewDetails:{},
};

const SupportReducer = (state=Initial_state, action) => {
    switch(action.type){
        case supportType.SUPPORT_MORE_IMAGE_UPLOAD_LOADER:
            return {
                ...state,
                isMoreImageUploadLoading: action.payload,
            }
        case supportType.SUPPORT_VIEW_DETAILS:
            return {
                ...state,
                viewDetails: action.payload,
            }
        default:
            return state;
    }
};

export default SupportReducer;
