export const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 620,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  
export const wrapper = {
    color: "red",
  };
  
export const inputFiles = {
    borderRadius: "6px",
    border: "2px solid lightGrey",
    width: "13rem",
    height: "8rem",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  };
  
export const headStyle = {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "3rem",
  };
  
export const headerStyle = {
    fontSize: "2.2rem",
    fontWeight: "700",
  };
export const centerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  
export const totalDiff = {
    color: "#e65308",
    fontWeight: "bold",
    marginLeft: "35px",
  };

export const stickyStyle = {
    position: "sticky",
    bottom: "0rem",
    width: "100%",
    padding: "5px 20px",
    backgroundColor: "var(--clr-font-light)",
}