import * as React from 'react';
import { Typography, Select, MenuItem, FormControl, DialogTitle, DialogContentText, DialogContent, Dialog, Button, Box, DialogActions } from '@mui/material';
import { useState } from 'react';
import "../../PrivatePages/Preset/presetStyle.css"
export default function HeaderMapping({isMaping, setIsMaping, header, HandleConfirm}) {
    const units = [
        "Whole Numbers",
        "Hundreds",
        "Thousands",
        "Lakhs",
        "Crores",
    ];

    const [mapeedObj, setMapeedObj] = useState({
        branch:'',
        ledger_code:"",
        ledger_name:"",
        cy:"",
        py:"",
        tbAmount:""
    })

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={isMaping}
                onClose={() => setIsMaping(false)}
            >
                <DialogTitle>{header?.fileName}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You can set my maximum width and whether to adapt or not.
                    </DialogContentText>
                    <Box>
                        <FormControl sx={{ m: 1, minWidth: 265 }}>
                            <Typography className='w-100' >Name of the Branch</Typography>
                            <Select
                                value={mapeedObj.branch}
                                onChange={(e) => setMapeedObj({...mapeedObj, branch:e.target.value})}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    header.headers && header.headers.length > 0 && header.headers.map((head, hx) => (
                                        <MenuItem key={hx} value={head}>{head}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 265 }}>
                            <Typography className='w-100' >Ledger Code <span style={{ color: 'red' }}>*</span></Typography>
                            <Select
                            value={mapeedObj.ledger_code}
                            onChange={(e) => setMapeedObj({...mapeedObj, ledger_code:e.target.value})}
                            required
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {
                                header.headers && header.headers.length > 0 && header.headers.map((head, hx) => (
                                    <MenuItem key={hx} value={head}>{head}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 265 }}>
                            <Typography className='w-100' >Ledger Name <span style={{ color: 'red' }}>*</span></Typography>
                            <Select
                            value={mapeedObj.ledger_name}
                            onChange={(e) => setMapeedObj({...mapeedObj, ledger_name:e.target.value})}
                            required
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {
                                header.headers && header.headers.length > 0 && header.headers.map((head, hx) => (
                                    <MenuItem key={hx} value={head}>{head}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 265 }}>
                            <Typography className='w-100' >Current year Net Balance Column <span style={{ color: 'red' }}>*</span></Typography>
                            <Select
                            value={mapeedObj.cy}
                            onChange={(e) => setMapeedObj({...mapeedObj, cy:e.target.value})}
                            required
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {
                                header.headers && header.headers.length > 0 && header.headers.map((head, hx) => (
                                    <MenuItem key={hx} value={head}>{head}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                        
                        <FormControl sx={{ m: 1, minWidth: 265 }}>
                            <Typography className='w-100' >Previous year Net Balance Column <span style={{ color: 'red' }}>*</span></Typography>
                            <Select
                            value={mapeedObj.py}
                            onChange={(e) => setMapeedObj({...mapeedObj, py:e.target.value})}
                            required
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {
                                header.headers && header.headers.length > 0 && header.headers.map((head, hx) => (
                                    <MenuItem key={hx} value={head}>{head}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                        
                        <FormControl sx={{ m: 1, minWidth: 265 }}>
                            <Typography className='w-100' >Amount in Trial Balance</Typography>
                            <Select
                            value={mapeedObj.tbAmount}
                            onChange={(e) => setMapeedObj({...mapeedObj, tbAmount:e.target.value})}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                units.map((unit, hx) => (
                                    <MenuItem key={hx} value={unit}>{unit}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>

                <DialogActions className='justify-content-center mb-2'>
                    <Button variant='text' className='px-3 py-2 rounded' onClick={() => setIsMaping(false)}>Close</Button>
                    <Button variant='contained' className='custom-common-theme-bg-color text-white px-4 py-2 rounded' onClick={() => { HandleConfirm(mapeedObj); setIsMaping(false); }} >Confirm & proceed</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}