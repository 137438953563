import React, { useMemo } from "react";
import { Navbar } from "../../Components/Navbar";
import LibraryCard from "../../Components/Library/LibraryCard";


const Library = () => {
    const items = useMemo(() => [
        {
            label: 'IND AS 1',
            lucked:false,
            herf:"/library-indas-1"
        },
        {
            label: 'Companies Act, 2013',
            lucked:true,
            herf:""
        },
        {
            label: 'Income tax ACT',
            lucked:true,
            herf:""
        },
        {
            label: 'GST Act',
            lucked:true,
            herf:""
        },
        {
            label: 'SEBI Reporting',
            lucked:true,
            herf:""
        },
        {
            label: 'Other Acts',
            lucked:true,
            herf:""
        },
    ], []);

    return (
        <>
            <Navbar />
            <div className="px-2 mb-3">
                <div className="mt-3 px-4">
                    <div className="Main_div">
                        {
                            items.map(item => (
                                <LibraryCard item={item} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Library;
