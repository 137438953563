const authType = {
    USER_MIDDLEWARE_DATA: "USER_MIDDLEWARE_DATA",
}

const Initial_state = {
    userData: {},
};

const AuthReducer = (state=Initial_state, action) => {
    switch(action.type){
        case authType.USER_MIDDLEWARE_DATA:
            return {
                ...state,
                userData:action.payload
            }
        default:
            return state;
    }
};

export default AuthReducer;
