export const columnBaseTable = [

    {
        Header: 'LEDGER CODE',
        accessor : 'ledger_code',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 50,
            width: 250, 
        }
    },
    {
        Header: 'LEDGER NAME',
        accessor : 'ledger_name',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 250,
            width: 250, 
        }
    },
    {
        Header: 'BRANCH',
        accessor : 'branch',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 150,
            width: 250, 
        }
    },
    {
        Header: 'CURRENT YEAR',
        accessor : 'cy',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 150,
            width: 250, 
        }
    },
    {
        Header: 'ADJUSTMENT AMT',
        accessor : 'adjusted_amount',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 150,
            width: 250, 
        }
    },
    {
        Header: 'FINAL AMT',
        accessor : '',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 100,
            width: 250, 
        }
    },
    {
        Header: 'PREVIOUS YEAR',
        accessor : 'py',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 100,
            width: 250, 
        }
    },
    {
        Header: 'FS GROUPING (CY)',
        accessor : 'fs_grp_cy',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        } 
    },
    {
        Header: 'NOTES GROUPING (CY)',
        accessor : 'note_grp_cy',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'SUB GROUPING (CY)',
        accessor : 'sub_grp_cy',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        } 
    },
    {
        Header: 'FS GROUPING (PY)',
        accessor : 'fs_grp_py',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'NOTES GROUPING (PY)',
        accessor : 'note_grp_py',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'SUB GROUPING (PY)',
        accessor : 'sub_grp_py',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
]

export const column = [

    {
        Header: 'LEDGER CODE',
        accessor : 'ledger_code',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'LEDGER NAME',
        accessor : 'ledger_name',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'BRANCH',
        accessor : 'branch',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'CURRENT YEAR',
        accessor : 'cy',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'ADJUSTMENT AMT',
        accessor : 'adjusted_amount',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'FINAL AMT',
        accessor : '',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'PREVIOUS YEAR',
        accessor : 'py',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'FS GROUPING',
        accessor : 'fs_grp',
        maxWidth: 400,
        minWidth: 200,
        width: 250, 
    },
    {
        Header: 'NOTES GROUPING',
        accessor : 'note_grp',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    {
        Header: 'SUB GROUPING',
        accessor : 'sub_grp',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 200,
            width: 250, 
        }
    },
    // {
    //     Header: 'ADJUSTED AMT',
    //     accessor : 'adjusted_amount',
    //     maxwidth: 250,
    //     minWidth: 200,
    //     width: 250, 
    // }
]