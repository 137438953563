import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAddSubGroupModal, singleNotesData } from '../../../redux/actions/BookClosure/actionBookClosure';
import ThirdLabelData from './ThirdLabelData';
import AddSubGroupModal from './AddSubGroupModal';

const CategoryBsAndPnl = ({
  headers,
  rows,
  dragData
}) => {
  const dispatch = useDispatch();
  const {
    switchTab2ndTo3rdLabel
  } = useSelector((initialState) => initialState.BookClosureTbcReducer);

  const [addSubGroupRrquirement, setAddSubGroupRrquirement] = useState({
    level: null,
    levelOneName: ""
  })

  return (
    <>
      <div style={{ height: "29rem", overflowY: "auto" }} >
        {
          switchTab2ndTo3rdLabel ? (
            <ThirdLabelData dragData={dragData} />
          )
            :
            <table>
              <thead>
                <tr className='bg-secondary-400'>
                  {
                    headers.map(header => (
                      <th key={header} > {header} </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  rows && rows.length > 0 &&
                  rows.map((row, rx) => (
                    <React.Fragment key={rx}>
                      <tr>
                        <td colSpan={4} className='text-left pl-3 custom-bg-warning'> {row?.name} </td>
                      </tr>
                      {
                        row?.subgroup && row?.subgroup.length > 0 &&
                        row?.subgroup.map((subGrp, sx) => (
                          <React.Fragment key={sx}>
                            <tr >
                              <td className='text-left pl-3' > {subGrp?.name} </td>
                              <td
                                className='text-center cursor-pointer custom-common-theme-text-color'
                                onClick={() => {
                                  dispatch(singleNotesData({ subGrp, groupCatName: row?.name }))
                                }}
                              >
                                {subGrp?.notes}
                              </td>
                              <td className='text-right pr-3' > { subGrp?.currentYearAmount } </td>
                              <td className='text-right pr-3' > { subGrp?.previousYearAmount } </td>
                            </tr>

                            {
                              sx === (row?.subgroup.length - 1) &&
                              <tr>
                                <td
                                  colSpan={4}
                                  className='text-left pl-3 custom-common-theme-text-color font-size-14 custom-font-700 cursor-pointer'
                                  onClick={() => {
                                    setAddSubGroupRrquirement(prev => ({...prev, level:subGrp?.level, levelOneName: row?.name}))
                                    dispatch(setIsAddSubGroupModal(true));
                                  }}
                                >
                                  + Add sub group
                                </td>
                              </tr>
                            }
                          </React.Fragment>
                        ))
                      }

                    </React.Fragment>
                  ))
                }
              </tbody>
            </table>
        }
      </div>
      <AddSubGroupModal 
        requirements={addSubGroupRrquirement} 
        setRequirements={() => setAddSubGroupRrquirement({...addSubGroupRrquirement, level:null, levelOneName:""})} 
      />
    </>
  )
}

export default CategoryBsAndPnl