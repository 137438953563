import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';

export default function ViewImageModal({
  setIsViewImages,
  isViewImage
}) {
  const dispatch = useDispatch();
  const {
    viewDetails
  } = useSelector((state) => state.SupportReducer);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };


  return (
    <React.Fragment>
      <Dialog
        open={isViewImage}
        onClose={() => setIsViewImages(false)}
      >
        <DialogTitle className='text-capitalize' >{viewDetails?.filename ? viewDetails?.filename.replace(/^rixosys\//, '') : ""}</DialogTitle>
        <DialogContent>
          <img
            alt='detail_contact_image'
            src={viewDetails?.filepath}
            className='rounded'
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              objectFit: 'contain'
            }}
          />
          {/* {loading && <CircularProgress />}
          <img
            alt='detail_contact_image'
            src={viewDetails?.filepath}
            style={{
              display: loading ? 'none' : 'block',
              maxWidth: '100%',
              maxHeight: '80vh',
              objectFit: 'contain'
            }}
            onLoad={handleImageLoad}
          /> */}

        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              dispatch({ type: "SUPPORT_VIEW_DETAILS", payload: {} });
              setIsViewImages(false);
            }}
            className='border border-danger py-2 px-3 bg-white text-danger font-weight-bold radius-6px shadow'
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
