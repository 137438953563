import React, { useEffect, useState } from "react";
import Header from "./header";
import logo from "../assets/images/logo/Logo.svg";
import BsModal from "../Component/modal/bsModal";
import PreSetModal from "../Component/modal/presetModal";
import AssociateBrowseModal from "../Component/modal/associateModal";
import { Link, useParams } from "react-router-dom";
import {
  getPrepareConsole,
  getProjects,
  getRegisterData,
  getCompanybyId,
  UpdatePreset,
  UpdatePresetAssociate,
} from "../Component/services/apiHandler";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setAllChecksPreset } from "../../redux/actions/actionPreset";

const AdditionPresetMode = () => {
  const { id } = useParams();
  const [consoldata, setConsolData] = useState("");
  const [company, setCompany] = useState("");
  const [investmentRegister, SetInvestmentRegister] = useState([]);
  const [preset, openPreset] = useState(false);
  // const [optionentry, setOptionEntry] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  // const [investregsisterdata, setInvestRegisterData] = useState("");
  const [openuploadpreset, setOpenUploadPreset] = useState(false);
  const [uploaddata, setUploadData] = useState("");

  const [openassociate, setOpenAssociate] = useState(false);
  const [associateId, setAssociateId] = useState("");
  const [natureofData, setNatureOfData] = useState("");

  const [setconsoleworking, setConsoleWorking] = useState(false);
  const [alertconsole, setConsoleAlert] = useState(false);
  // const [setnameproject,setNameProject] = useState("");
  const [allcheckdata, setAllcheckData] = useState([]);
  const [allAssociatecheckdata, setAllcheckAssociateData] = useState([]);

  const [selectcheckdata, setSelectData] = useState([]);
  const [selectAssociatecheckdata, setSelectAssociateData] = useState([]);

  
  const [selectedPresetProject, setSelectedPresetProject] = useState(null);
  const [projectPreset, setProjectPreset] = useState(null);
  let history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    setOpen(true);
    let data = {
      Consolidate: selectcheckdata,
    };
    const res = await UpdatePreset(data, id);
    if (res.status === 200) {
      console.log("saved successfully");
    }
    dispatch(setAllChecksPreset(selectcheckdata));
  };

  useEffect(() => {
    fetchPreConsole();
    getCompanyData();
    getPrepareConsole();
  }, []);

  useEffect(() => {
    if (projectPreset) {
      getProjectName(projectPreset);
    }
  }, [projectPreset, projectsData]);

  const uploadPresetToSubsidiary = (project_id, prepare_console_id) => {
    const objIndex = consoldata.subsidiary_upload_preset.findIndex(
      (data) => data.prepare_console_id === prepare_console_id
    );
    if (objIndex === -1) {
      consoldata.subsidiary_upload_preset.push({
        project_id: project_id,
        prepare_console_id: prepare_console_id,
      });
    } else {
      consoldata.subsidiary_upload_preset[objIndex]["project_id"] = project_id;
    }
    setConsolData(consoldata);
  };

  const handleClose = () => {
    openPreset(false);
    setOpenUploadPreset(false);
    setConsoleWorking(false);
    setConsoleAlert(false);
    setOpen(false);
    setOpenAssociate(false);
  };

  const fetchPreConsole = async () => {
    try {
      let response = await getPrepareConsole(id);
      fetchInvestCompany(response.data.getOnePreCompany[0].link_company);
      getCompany(response.data.getOnePreCompany[0].link_company);
      setConsolData(response.data.getOnePreCompany[0]);
      // getProjectName(response.data.getOnePreCompany[0].upload_preset)
      setProjectPreset(response.data.getOnePreCompany[0].upload_preset);
      // setCurrentProId(response.data.getOnePreCompany[0].upload_preset)
    } catch (err) {
      console.log("err", err.response);
    }
  };

  const getCompanyData = async () => {
    try {
      const response = await getProjects();
      setProjectsData(response.data.project);
    } catch (err) {
      console.log("err", err.response);
    }
  };

  const fetchInvestCompany = async (id) => {
    try {
      const response = await getRegisterData(id);
      console.log("fetchInvestCompany", response);
      SetInvestmentRegister(response.data.getCompanyData);
    } catch (err) {
      console.log(err.response);
    }
  };

  const getCompany = async (company_id) => {
    try {
      const response = await getCompanybyId(company_id);
      setCompany(response.data.getOneCompany[0].company_name);
    } catch (error) {
      console.log("getCompany", error.response);
    }
  };

  const getProjectName = async (p_id) => {
    try {
      const project = projectsData.find((data) => data._id === p_id);
      setSelectedPresetProject({
        project_name: project.project_name,
        project_id: project._id,
      });
    } catch (error) {
      console.log("error", error.message);
      return "None";
    }
  };

  const uploadPreset = () => {
    openPreset(true);
  };

  const handleChangeInvest = (e) => {
    // setInvestRegisterData(e.target.value);
  };

  const browseConsol = (project_id, nature) => {
    setAssociateId(project_id);
    setOpenAssociate(true);
    setNatureOfData(nature);
  };

  // const handleChangeUpload = (e) => {
  //   setUploadData(e.target.value);
  // };

  // const handleSubmitUpload = () => {
  //   // getProjectSub();
  //   // submitSubData();
  // };

  // const handleChangeUploadData = (e) => {
  //   setUploadAssociateData(e.target.value);
  // };

  // const browseConsoleWorking = () => {
  //   setConsoleWorking(true);
  // };

  // const handleSubmitClose = () => {
  //   setConsoleWorking(false);
  // };

  // const alertMessage = () => {
  //   setConsoleAlert(true);
  // };

  // const handleCloseDAta = () => {
  //   setConsoleAlert(false);
  // };

  const consolidateChange = (e, data, project_id, nature,assciate_p_id) => {
    if (nature === "Subsidiary Company") {
      console.log(data);
      if (e.target.checked) {
        let dataValue = {
          all_data: data,
          project_id: project_id,
        };
        setAllcheckData([...allcheckdata, dataValue]);

        // save data
        let checkdata = {
          Ir_id: data._id,
          project_id: project_id,
        };
        setSelectData([...selectcheckdata, checkdata]);
      } else {
        setAllcheckData(
          allcheckdata.filter((item) => item.project_id !== project_id)
        );
      }
    } else {
      console.log(data);
      if (e.target.checked) {
        let dataValue = {
          all_data: data,
          project_id: assciate_p_id,
        };
        setAllcheckAssociateData([...allAssociatecheckdata, dataValue]);

        // save data
        let checkdata = {
          Ir_id: data._id,
          project_id: assciate_p_id,
        };
        setSelectAssociateData([...selectAssociatecheckdata, checkdata]);
      } else {
        setAllcheckAssociateData(
          allAssociatecheckdata.filter((item) => item.project_id !== project_id)
        );
      }
    }
  };

  const ViewFsNext = async () => {
    let data = {
      Consolidate: selectcheckdata,
    };
    let associateData = {
      AssociateConsolidate :selectAssociatecheckdata
    }
    if (selectcheckdata.length > 0) {
      const res = await UpdatePreset(data, id);
      if (res.status === 200) {
        console.log("saved successfully");
      }
    }
   if (selectAssociatecheckdata .length > 0) {
    const res = await UpdatePresetAssociate(associateData, id);
    if (res.status === 200) {
      console.log("saved successfully");
    }
   }

    
    dispatch(setAllChecksPreset(selectcheckdata));
    history.push(`/view-fs/${id}`);
  };

  return (
    <div>
      <div className="tap-top">
        <i data-feather="chevrons-up"></i>
      </div>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          {/* Page Sidebar Start */}
          <div className="sidebar-wrapper">
            <div>
              <div className="logo-wrapper">
                <Link href="index.html">
                  <img className="img-fluid for-light" src={logo} alt="" />
                  {/* <img className="img-fluid for-dark" src={logo_dark} alt="" /> */}
                </Link>
              </div>
              <div className="logo-icon-wrapper">
                <Link href="index.html">
                  {/* <img className="img-fluid" src={logo_icon} alt="" /> */}
                </Link>
              </div>
            </div>
          </div>
          {/* Page Sidebar Ends */}
        </div>
        <div className="page-body">
          <div className="container-fluid">
            <div className="page-title">
              <div className="row">
                <div className="col-6">
                  <h3>Preset Mode</h3>
                </div>
                <div className="col-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link href="index.html">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                      </Link>
                    </li>
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active">Preset Mode </li>
                    <li className="breadcrumb-item active">
                      Addition Preset Mode{" "}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row second-chart-list third-news-update">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <button
                      className="btn btn-primary1 float-end"
                      onClick={ViewFsNext}
                    >
                      ViewFs Page
                    </button>
                    <button
                      className="btn btn-primary1 float-end"
                      style={{ marginRight: "11px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#compare"
                    >
                      Compare
                    </button>
                    {/* <button className="btn btn-primary1 float-end me-1" style={{marginRight: "15px"}} onClick={() => browseConsoleWorking()}>View FS</button> */}
                    <button
                      className="btn btn-primary1 float-end me-1"
                      style={{ marginRight: "15px" }}
                      onClick={handleOpen}
                    >
                      View FS
                    </button>

                    <p>
                      <strong>Name of Consol Preset: </strong>
                      {consoldata?.prepare_consol_company_name}
                    </p>
                    <div className="row">
                      <div className="form-group col-8">
                        <p>
                          <strong>
                            Upload standalone present of parent company:{" "}
                          </strong>
                        </p>
                        <input
                          type="button"
                          className="btn btn-primary1"
                          value="Upload Preset"
                          onClick={uploadPreset}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <small>{selectedPresetProject?.project_name}</small>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="form-group col-4">
                        <p>
                          <strong>Current Financial Year</strong>
                        </p>
                        <input
                          type="date"
                          className="form-control"
                          disabled
                          value={consoldata?.current_financial_year}
                        />
                      </div>
                      <div className="form-group col-4">
                        <p>
                          <strong>Previous Financial Year</strong>
                        </p>
                        <input
                          type="date"
                          className="form-control"
                          disabled
                          value={consoldata?.previous_financial_year}
                        />
                      </div>
                    </div>
                  </div>
                  <CompanyTableHeader
                    tableName={"Subsidiary Companies"}
                    tableData={investmentRegister}
                    companyData={company}
                    browseConsol={browseConsol}
                    consolidateChange={consolidateChange}
                    projects={projectsData}
                    consoldata={consoldata}
                    selectcheckdata={selectcheckdata}
                  />
                  <CompanyTableHeader
                    tableName={"Associates and Joint ventures"}
                    tableData={investmentRegister}
                    companyData={company}
                    browseConsol={browseConsol}
                    consolidateChange={consolidateChange}
                    projects={projectsData}
                    consoldata={consoldata}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BsModal
        handleClose={handleClose}
        open={open}
        allcheckdata={allcheckdata}
        consoleData={consoldata}
        currentCompProjectId={selectedPresetProject?.project_id}
      />
      <PreSetModal
        handleClose={handleClose}
        open={preset}
        prepareConsoleId={id}
        selectedPresetProject={selectedPresetProject?.project_id}
        setPresetHandler={setSelectedPresetProject}
        projectsData={projectsData}
        consoldata={consoldata}
      />
      <AssociateBrowseModal
        handleClose={handleClose}
        open={openassociate}
        selectedAssociateId={associateId}
        setPresetHandler={uploadPresetToSubsidiary}
        projectsData={projectsData}
        prepareConsoleId={id}
        consoldata={consoldata}
        workspaceData={""}
        nature={natureofData}
      />
    </div>
  );
};

const CompanyTableHeader = (props) => {
  const { tableName, tableData, consoldata, selectcheckdata } = props;
  return (
    <div className="card-body">
      <div className="card-block">
        <div className="mt-3">
          <h4>{tableName}</h4>
          <div className="table-responsive table-bordered">
            <table className="table">
              <thead className="bg-primary1">
                <tr>
                  <th scope="col">Name&nbsp;of&nbsp;the&nbsp;Company</th>
                  <th scope="col">Upload&nbsp;preset</th>
                  <th scope="col">Validation&nbsp;check</th>
                  <th scope="col">Consolidate</th>
                  <th scope="col">View&nbsp;Consol&nbsp;Workings</th>
                  <th scope="col">
                    Translate&nbsp;to&nbsp;functional&nbsp;curreny
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableName === "Subsidiary Companies" ? (
                  <>
                    {tableData
                      ?.filter(
                        (name) =>
                          name.final_holdings > "50" &&
                          name.nature_of_relationship === "Subsidiary Company"
                      )
                      .map((filteredName, key) => (
                        <CompanyTableBody
                          key={key}
                          filteredName={filteredName}
                          {...props}
                          consoldata={consoldata}
                          tableData={tableData}
                          selectcheckdata={selectcheckdata}
                          nature={"Subsidiary Company"}
                        />
                      ))}
                  </>
                ) : (
                  <>
                    {tableData
                      ?.filter(
                        (name) => name?.holdings <= "50" && name?.holdings >= 20
                      )
                      .map((filteredName, key) => (
                        <CompanyTableBody
                          key={key}
                          filteredName={filteredName}
                          {...props}
                          consoldata={consoldata}
                          nature={"Asssociate Company"}
                        />
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanyTableBody = (props) => {
  const {
    filteredName,
    companyData,
    browseConsol,
    consolidateChange,
    projects,
    consoldata,
    tableData,
    selectcheckdata,
    nature,
  } = props;
  useEffect(() => {
    newGetProjectName();
  }, []);
  const getProjectName = (p_id) => {
    try {
      if (!p_id) return "";
      return projects.find((project) => project._id === p_id).project_name;
    } catch (err) {
      console.log("err", err.message);
    }
  };

  const newGetProjectName = (subsidiary_id, getId = false) => {
    const subsidiary_upload_preset = consoldata?.subsidiary_upload_preset;
    let projectdata = subsidiary_upload_preset?.find(
      (obj) => obj.prepare_console_id === subsidiary_id
    );
    if (getId) {
      return projectdata?.project_id;
    }
    return getProjectName(projectdata?.project_id);
  };

  const newGetAssociateName = (subsidiary_id, getId = false) => {
    const associate_upload_preset = consoldata?.associate_upload_preset;
    let projectdata = associate_upload_preset?.find(
      (obj) => obj.prepare_console_id === subsidiary_id
    );
    if (getId) {
      return projectdata?.project_id;
    }
    return getProjectName(projectdata?.project_id);
  };

  return (
    <tr>
      <th scope="row">
        {filteredName?.company ? filteredName?.company : companyData}
      </th>
      <td>
        <button
          className="btn btn-primary1"
          onClick={() => browseConsol(filteredName._id, nature)}
        >
          Browse
        </button>
        <p>
          {nature === "Subsidiary Company"
            ? newGetProjectName(filteredName._id)
            : newGetAssociateName(filteredName._id)}
        </p>
      </td>
      <td>
        <b>
          {nature === "Subsidiary Company" ? (
            newGetProjectName(filteredName._id) ? (
              <i
                class="fa fa-check-square"
                aria-hidden="true"
                style={{ fontSize: "30px", color: "green" }}
              ></i>
            ) : (
              <i
                class="fa fa-exclamation-circle"
                aria-hidden="true"
                style={{ fontSize: "30px", color: "red" }}
              ></i>
            )
          ) : newGetAssociateName(filteredName._id) ? (
            <i
              class="fa fa-check-square"
              aria-hidden="true"
              style={{ fontSize: "30px", color: "green" }}
            ></i>
          ) : (
            <i
              class="fa fa-exclamation-circle"
              aria-hidden="true"
              style={{ fontSize: "30px", color: "red" }}
            ></i>
          )}
        </b>
      </td>
      <td>
        <input
          type="checkbox"
          style={{ height: "24px", width: "30px", cursor: "pointer" }}
          // defaultValue={selectcheckdata}
          onChange={(e) =>
            consolidateChange(
              e,
              filteredName,
              newGetProjectName(filteredName._id, true),
              nature,
              newGetAssociateName(filteredName._id, true)
            )
          }
          // checked={consoldata?.consolidation.filter(eve => eve.Ir_id === filteredName._id).length > 0}
        />{" "}
      </td>
      <td>
        {" "}
        <button
          className="btn btn-primary1"
          data-bs-toggle="modal"
          data-bs-target="#view-consol"
        >
          View&nbsp;Consol&nbsp;Workings
        </button>{" "}
      </td>
      <td>
        {" "}
        <button className="btn btn-primary1">Translate</button>
      </td>
    </tr>
  );
};

export default AdditionPresetMode;
