import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContentText } from "@mui/material";

export default function ConsoleEntryModal({
  isConsoleEntryOpen,
  setIsConsoleEntryOpen,
  allConsoleEntry,
  allRegisterData,
  handleChangeInputs,
  consoleInvestmentValue,
  handleNext,
  showFirstTable,
  setShowFirstTable,
  additinalData,
  isAdditional
}) {
  const handleClose = () => {
    setIsConsoleEntryOpen(false);
  };

  const handleBack =()=>{
    setShowFirstTable(true)
  }


  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isConsoleEntryOpen}
        onClose={handleClose}
      >
        <DialogTitle>Create Console Entry</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
                        September, 04, 2024
                    </DialogContentText> */}
          { additinalData && additinalData?.length===1&&isAdditional===true ? (
            <div>
              {showFirstTable ? (
                <table>
                  <thead>
                    <tr>
                      <th className="text-left pl-3 border-top-0 border-left-0 border-right-0">
                        Particular
                      </th>
                      <th className="text-right pr-3 border-top-0 border-left-0 border-right-0">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left pl-3 border-top-0 border-bottom-0 border-left-0 border-right-0">
                        Investment
                      </td>
                      <td className="text-right pr-3 border-top-0 border-bottom-0 border-left-0 border-right-0">
                        <input
                          name="consoleInvestmentValue"
                          value={consoleInvestmentValue}
                          onChange={(e) => {
                            handleChangeInputs(e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left pl-3 border-top-0 border-bottom-0 border-left-0 border-right-0">
                        Gain on fair valuation of Investment
                      </td>
                      <td className="text-right pr-3 border-top-0 border-bottom-0 border-left-0 border-right-0">
                        <input value={-consoleInvestmentValue} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th className="text-left pl-3 border-top-0 border-left-0 border-right-0">
                        Particular
                      </th>
                      <th className="text-right pr-3 border-top-0 border-left-0 border-right-0">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allConsoleEntry &&
                      allConsoleEntry.length > 0 &&
                      allConsoleEntry.map((particular, index) => (
                        <tr key={index}>
                          <td className="text-left pl-3 border-top-0 border-bottom-0 border-left-0 border-right-0">
                            {particular?.sub_grp}
                          </td>
                          <td className="text-right pr-3 border-top-0 border-bottom-0 border-left-0 border-right-0">
                            {particular?.amount}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="text-left pl-3 border-top-0 border-left-0 border-right-0">
                    Particular
                  </th>
                  <th className="text-right pr-3 border-top-0 border-left-0 border-right-0">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {allConsoleEntry &&
                  allConsoleEntry.length > 0 &&
                  allConsoleEntry.map((particular, index) => (
                    <tr key={index}>
                      <td className="text-left pl-3 border-top-0 border-bottom-0 border-left-0 border-right-0">
                        {particular?.sub_grp}
                      </td>
                      <td className="text-right pr-3 border-top-0 border-bottom-0 border-left-0 border-right-0">
                        {particular?.amount}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </DialogContent>
        <DialogActions>
          { additinalData && additinalData?.length===1&&isAdditional===true ? (
            showFirstTable ? (
                <Button 
                onClick={() => handleNext(additinalData[0])}
                className="text-white shadow rounded custom-border custom-common-theme-bg-color text-capitalize w-20 mr-3">
                Next
                </Button>
            ) : (
                <Button 
                onClick={handleBack} 
                className="text-white shadow rounded custom-border custom-common-theme-bg-color text-capitalize w-20 mr-3">
                Back
                </Button>
            )
            ) : null}
          <Button onClick={handleClose} className="bg-white  shadow rounded custom-border custom-common-theme-text-color text-capitalize w-20">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
