import {Navbar} from "../../Components/Navbar"
import Button from "@mui/material/Button";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Paper from '@mui/material/Paper';
// import Tabsystem from "../../Workspace-v2/Tabsystem";
// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
//   }
  
//   const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
//   ];
const WorkSpacehome = ()=>{
    return(
        <div>
        <div>
     <Navbar/>
     </div>

     <div className="d-flex">
          
          <div className="container">
            <div className="d-flex justify-content-between mt-5">
              <span style={{ fontWeight: "600",color:"#03565A" }}><ArrowBackIcon /> Back to Lousi Vuitton</span>
             
              <Button style={{ border: "2px solid #03565A", width: "200px", height: "40px", borderRadius: "5px" }} disabled>
                <AddIcon style={{ color: "#03565A" }} />
                <span
                  style={{
                    color: "#03565A",
                    textTransform: "none",
                    fontWeight: "550",
                  }}
                >
                 
                 Create investment
                </span>
              </Button>
             
             
            </div>
            {/* <Tabsystem/> */}
            <TableContainer className="mt-3">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>investees</TableCell>
            <TableCell align="right">Investment value</TableCell>
            <TableCell align="right">No of shares</TableCell>
            <TableCell align="right">Percentage holding</TableCell>
            <TableCell align="right">Nature of relatonship</TableCell>
            <TableCell align="right">ACTION</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
            <TableRow
            //   key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{backgroundColor:"#F5F5F5"}}>
               Brand 1
              </TableCell>
              <TableCell align="right">1000</TableCell>
              <TableCell align="right">80</TableCell>
              <TableCell align="right">88%</TableCell>
              <TableCell align="right">Subsidiary company</TableCell>
              <TableCell align="right"><MoreVertIcon/></TableCell>
            </TableRow>
            <TableRow
            //   key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{backgroundColor:"#F5F5F5"}}>
              Brand 1
              </TableCell>
              <TableCell align="right">1000</TableCell>
              <TableCell align="right">70</TableCell>
              <TableCell align="right">70%</TableCell>
              <TableCell align="right">Subsidiary company</TableCell>
              <TableCell align="right"><MoreVertIcon/></TableCell>
            </TableRow>
            <TableRow
            //   key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{backgroundColor:"#F5F5F5"}}>
              Brand 1
              </TableCell>
              <TableCell align="right">1000</TableCell>
              <TableCell align="right">60</TableCell>
              <TableCell align="right">60%</TableCell>
              <TableCell align="right">Subsidiary company</TableCell>
              <TableCell align="right"><MoreVertIcon/></TableCell>
            </TableRow>
            <TableRow
            //   key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{backgroundColor:"#F5F5F5"}}>
              Brand 1
              </TableCell>
              <TableCell align="right">1000</TableCell>
              <TableCell align="right">50</TableCell>
              <TableCell align="right">50%</TableCell>
              <TableCell align="right">Subsidiary company</TableCell>
              <TableCell align="right"><MoreVertIcon/></TableCell>
            </TableRow>
            <TableRow
            //   key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{backgroundColor:"#F5F5F5"}}>
              Brand 1
              </TableCell>
              <TableCell align="right">1000</TableCell>
              <TableCell align="right">40</TableCell>
              <TableCell align="right">40%</TableCell>
              <TableCell align="right">Subsidiary company</TableCell>
              <TableCell align="right"><MoreVertIcon/></TableCell>
            </TableRow>

            <TableRow
            //   key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            </TableRow>
          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
    <div className="mt-4" style={{textAlign:"center"}}>
    <Button style={{ border: "2px solid #03565A", width: "150px", height: "40px", borderRadius: "5px" }} disabled>
                
                <span
                  style={{
                    color: "#03565A",
                    textTransform: "none",
                    fontWeight: "550",
                  }}
                >
                 
                 View SH Pattern
                </span>
              </Button>
              <Button style={{ border: "2px solid #03565A", width: "150px", height: "40px", borderRadius: "5px"}} className="ml-4" disabled>
                
                <span
                  style={{
                    color: "#03565A",
                    textTransform: "none",
                    fontWeight: "550",
                  }}
                >
                 
                 Export IR
                </span>
              </Button>
              </div>
          </div>
        </div>
        </div>
    )
}
export default WorkSpacehome