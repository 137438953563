import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { 
  adminAddUserPermission, 
  getCompany, 
  getUserPermission,
  updateUserPermission
} from "../WorkSpace/Components/ApiServices/apiHandler";
import { 
  unrestrictedAccess_, 
  rawCompanyData 
} from "../data/rolePermissionRawData";
import AdminNavbar from "./Components/AdminNavbar";

const AdminUser = () => {
  const { childUserId, isUpdate } = useParams();
  const history = useHistory(); 
  
  const [activeCompaniesIds, setActiveCompaniesIds] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [unrestrictedAcessData, setUnrestrictedAcessData] = useState([]);
  const [isPermissionCreated, setIsPermissionCreated] = useState(true);

  useEffect(() => {
    if(isUpdate){
      getUserPermissionData();
    }
    getCompanies();
  }, []);

  const getUserPermissionData = () => {
    getUserPermission(childUserId).then(response => {
      const responseResult = response.data.result;
      if(responseResult){
        setIsPermissionCreated(false);
        const filteredCompanyData = responseResult.companyData.map(item => {
          return { company: item.company._id, components: item.components, type: item.type, _id:item._id }
        })
        setCompaniesData(filteredCompanyData);
        setActiveCompaniesIds(responseResult.selectCompanies);
        setUnrestrictedAcessData(responseResult.unrestrictedAcessData);
      }
    }).catch(error => {
      swal(
        "Error",
        error.response?.data?.error_message || "Selected Company Data Not Found!",
        "error"
      );
    });
  }

  const getCompanies = async () => {
    getCompany().then(response => {
      setCompanies(response.data.getCompany);
    }).catch(error =>{
      swal(
        "Error",
        error.response?.data?.error || "Company data get failed!",
        "error"
      );
    })
  };

  const handleButtonClick = (_id) => {
    if (activeCompaniesIds.includes(_id)) {
      setActiveCompaniesIds(prev => prev.filter(item => item !== _id));
      setCompaniesData(prev => prev.filter(item => item.company !== _id));
    } else {
      setActiveCompaniesIds([...activeCompaniesIds, _id]);
      setCompaniesData(prev => ([...prev, {
        company: _id,
        components: rawCompanyData,
        type: "single"
      }]));
    }
  };

  const SubmitSelectedCompany = () =>{
    adminAddUserPermission({ 
      user: childUserId, 
      companyData: companiesData,
      unrestrictedAcessData: unrestrictedAccess_,
      selectCompanies: activeCompaniesIds
    }).then(response => {
      toast.success("Selected Company Added Successfully.");
      history.push(`/add-responsibility/${childUserId}`);
    }).catch(error => {
      swal(
        "Error",
        error.response?.data?.error_message || "Selected Company Added Failed!",
        "error"
      );
    })
  }

  const updatePermissionData = () => {
    if(isPermissionCreated){
      SubmitSelectedCompany();
    }else{
      updateUserPermission(childUserId, {
        userPermissionData: companiesData,
        unrestrictedAccessData: unrestrictedAcessData,
        selectCompanies: activeCompaniesIds,
        step:"selectedCompany"
      }).then(res => {
        toast.success("Selected companies updated successfully.");
        history.push(`/add-responsibility/${childUserId}`)
      }).catch(error => {
        swal(
          "Error",
          error.response?.data?.error_message || "Responsibility Updated Failed!",
          "error"
        );
      })
    }
  }

  return (
    <>
      <AdminNavbar />
      <div className="px-4 py-3">
        <Card className="w-100 radius-12px">
          <div className="px-3 py-3">
            <div>
              <p className="mt-0 mb-0 font-size-18 custom-font-600 font-sans ">
                Add Teamate
              </p>
              <p className="mt-0 mb-2 font-size-16 custom-font-500 font-sans">
                Fill out all the requirements to create new Teammate
              </p>
            </div>
            <div className="mt-3 general-box">
              <div className="d-flex flex-wrap p-2">
                <div className="d-flex justify-content-center align-items-center mt-1 Primary-circle">
                  <CheckIcon className="text-white" />
                </div>
                <div className="flex-grow-1">
                  <p className="mt-2 p-1 mb-0 font-size-12 custom-font-600 font-sans custom-common-theme-text-color">
                    General Details
                    <span className="text-secondary">
                      -------------------------------------------------------
                    </span>
                  </p>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-1 orange-circle">
                  <p className="mt-0 mb-0 font-size-12 custom-font-600 font-sans">
                    2
                  </p>
                </div>
                <div className="flex-grow-1">
                  <p
                    className="mt-2 p-1 mb-0 font-size-12 custom-font-600 font-sans"
                    style={{ color: "brown" }}
                  >
                    Select Companies
                    <span className="text-secondary">
                      -------------------------------------------------------
                    </span>
                  </p>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center mt-1 white-circle"
                  style={{
                    border: "1px solid #8692A6",
                  }}
                >
                  <p className="mt-0 mb-0 font-size-12 custom-font-600 font-sans">
                    3
                  </p>
                </div>
                <div className="flex-grow-1">
                  <p className="mt-2 p-1 mb-0 font-size-12 custom-font-600 font-sans text-secondary">
                    Responsibilities
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-3 px-2 py-2 radius-8px border border-secondary">
              <p className="mt-0 mb-0 font-size-16 custom-font-600 custom-common-theme-text-color font-sans">
                Induvial Entities
              </p>
              <div
                className="mt-2"
                style={{ maxHeight: "150px", overflowY: "auto" }}
              >
                {companies.map((company, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    className="text-dark font-sans"
                    onClick={() => handleButtonClick(company._id)}
                    style={{
                      borderRadius: "20px",
                      fontSize: "12px",
                      border: activeCompaniesIds.includes(company._id)
                        ? "2px solid #03565A"
                        : "2px solid grey",
                      color: activeCompaniesIds.includes(company._id)
                        ? "#03565A"
                        : "black",
                      margin: "5px",
                      backgroundColor: activeCompaniesIds.includes(company._id)
                        ? "#F8F8F8"
                        : "white",
                      textTransform: "capitalize",
                    }}
                  >
                    {company.company_name}
                  </Button>
                ))}
              </div>
            </div>
            
            <div className="d-flex justify-content-end mt-4" style={{gap:"10px"}}>
              <Button 
                variant="contained" 
                style={{ width:"330px",height:"50px" }} 
                className="bg-white custom-common-theme-text-color primary-border radius-10px font-size-16 custom-font-500 text-capitalize font-sans" 
                onClick={() => history.push("/add-teamate")}
              >
                Back
              </Button>
              <Button 
                variant="contained" 
                style={{ width:"330px",height:"50px" }} 
                className="custom-common-theme-bg-color radius-10px font-size-16 custom-font-500 text-capitalize font-sans" 
                onClick={() => {
                  if(isUpdate) {
                    updatePermissionData();
                  }else{
                    SubmitSelectedCompany();
                  } 
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AdminUser;
