import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";

import { useState } from "react";
import {
  GetAllCompanyData,
  GetProject,
  getCheckData,
  
} from "../ApiServices/apiHandler";
import { useEffect } from "react";

const natureOptions = [
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Trading", label: "Trading", isDisabled: true },
  { value: "NBFC", label: "NBFC", isDisabled: false },
  { value: "Banking", label: "Banking", isDisabled: true },
];

const gaapOptions = [
  { value: "Indian Gaap", label: "Indian GAAP" },
  { value: "UK Gaap", label: "UK GAAP", isDisabled: true },
  { value: "US Gaap", label: "US GAAP", isDisabled: true },
];

const EditProject = (props) => {
  const {
    open,
    handleClose,
    handleChangeDataSubmit,
    submitCompany,
    editData,
    handleChnageEdit,
    submitEdit,
    allProjectData,
  } = props;
  const [companyAll, setCompanyAll] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [presetAll, setPresetAll] = useState([]);
  const [allProject, setAllProject] = useState([]);

  useEffect(() => {
    getWorkSpaceCheck();
    companyData();
    projectData();
  }, [allProjectData]);

  const companyData = async () => {
    try {
      const res = await GetAllCompanyData();
      if (res.status === 200) {
        setAllCompany(res.data.getAllCompanyData);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const getWorkSpaceCheck = async () => {
    const res = await getCheckData(allProjectData.workspace_id);
    if (res.status === 200 && res.data.getIR && res.data.getIR.length > 0) {
      setCompanyAll(res.data.getIR[0]?.all_company_checked);
      setPresetAll(res.data.getIR[0]?.all_preset_checked);
    }
  };

  const projectData = async () => {
    try {
      const res = await GetProject();
      if (res.status === 200) {
        setAllProject(res.data.project);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const CompanyName = (c_id) => {
    try {
      const nameCompany = allCompany.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_name
        ? nameCompany[0]?.company_name
        : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  const PresetName = (c_id) => {
    try {
      const nameCompany = allProject.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.project_name
        ? nameCompany[0]?.project_name
        : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="wp-modal-box wp-modal-box-add-company-form">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h5 className="wp-add-comp-modal-header">Edit Project</h5>
          </Typography>
          <div style={{ marginTop: "30px" }}>
            <label>
              <strong>Company</strong>
            </label>
          </div>
          <div>
            <select style={{ width: "628px", height: " 57px" }}
            onChange={(e) => handleChnageEdit(e.target.value, "company")}
            >
              {companyAll?.map((det) => {
                return (
                  <>
                    <option
                      value={det}
                      selected={allProjectData.company === det}
                    >
                      {CompanyName(det)}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>
              <strong>Name of the financials</strong>
            </label>
            <TextField
              className="wp-add-comp-modal-input"
              defaultValue={allProjectData.project_name}
            onChange={(e) => handleChnageEdit(e.target.value, "name_finance")}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>
              <strong>Nature of the financials</strong>
            </label>
          </div>
          <div>
            <select style={{ width: "628px", height: " 57px" }}
            onChange={(e) => handleChnageEdit(e.target.value, "nature_finance")}
            >
              {natureOptions?.map((det) => {
                return (
                  <>
                    <option
                      value={det.value}
                      selected={allProjectData.company_type === det.value}
                    >
                      {det.label}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>
              <strong>Selection of GAAP</strong>
            </label>
          </div>
          <div>
            <select style={{ width: "628px", height: " 57px" }}
            onChange={(e) => handleChnageEdit(e.target.value, "gaap")}
            >
              {gaapOptions?.map((det) => {
                return (
                  <>
                    <option
                      value={det.value}
                      selected={allProjectData.gaap_selection === det.value}
                    >
                      {det.label}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>
              <strong>Select presets/Chart of accounts</strong>
            </label>
          </div>
          <div>
            <select style={{ width: "628px", height: " 57px" }}
            onChange={(e) => handleChnageEdit(e.target.value, "account")}
            >
              {presetAll?.map((det) => {
                return (
                  <>
                    <option value={det}
                    selected={allProjectData.preset_id === det}
                    >{PresetName(det)}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="blankspace30"></div>
          <Button
            className="wp-btn-fill"
            onClick={() => submitEdit(allProjectData._id)}
          >
            Save
          </Button>
          &nbsp; &nbsp;
          <Button className="wp-btn-no-fill" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
  );
};
export default EditProject;
