const SoccieTypes = {
    SOCCIE_ACTION_START:"SOCCIE_ACTION_START",
    SOCCIE_FETCH_DATA:"SOCCIE_FETCH_DATA",
    SOCCIE_FETCH_DATA_FAILED:"SOCCIE_FETCH_DATA_FAILED",
    SOCCIE_ADD_DATA:"SOCCIE_ADD_DATA",

    STANDALONE_SOCIE_DATA:"STANDALONE_SOCIE_DATA",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
    payload: "",

    standaloneSocieData : {},
}

const SoccieReducer = (state=Initial_state, action) => {
    switch(action.type){
        case SoccieTypes.SOCCIE_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case SoccieTypes.SOCCIE_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                payload: action.payload.payload
            }

        case SoccieTypes.SOCCIE_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        case SoccieTypes.STANDALONE_SOCIE_DATA: 
            return {
                ...state,
                standaloneSocieData: action.payload
            }

        default:
            return state;
    }
};

export default SoccieReducer;