const presetType = {
    CREATE_PROJECT: "CREATE_PROJECT",
    SELECTED_PRESETS: "SELECTED_PRESETS",
    PROJECT_DATA: "PROJECT_DATA",
}

const Initial_state = {
    createProjectData: {},
    selectedPresets: [],
    projectData : {}
};

const PresetReducer = (state=Initial_state, action) => {
    switch(action.type){
        case presetType.CREATE_PROJECT:
            return {
                ...state,
                createProjectData:action.payload
            }
        case presetType.SELECTED_PRESETS:
            return {
                ...state,
                selectedPresets:action.payload
            }
        case presetType.PROJECT_DATA:
                return {
                    ...state,
                    projectData:action.payload
                }
        default:
            return state;
    }
};

export default PresetReducer;
