import React,{useState,useEffect} from 'react'
import {useTable,useRowSelect} from 'react-table'
import {useDispatch,useSelector} from 'react-redux'
import {motion} from 'framer-motion'


import { deletedColumn } from '../deletedItems/deletedColumn'
import './deletedTable.css'
import { fetchDeletedLineItems, restoreDeletedAllLineItems, restoreDeletedLineItems } from '../../services/api/apiHandler'
import swal from 'sweetalert'
import { fetchConventionalMode, restoreLineItems, restoreMultiLineItems } from '../../redux/actions/TemplateTabs/actionCM'
import { Spinner } from 'react-bootstrap'



const screen= {
    width:'100%',
    height:'100%',
    position:'fixed',
    zIndex:'999',
    backgroundColor:'#00000036',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    overflow:'hidden',
   
}
const container= {
    minHeight:'75vh',
    borderRadius:'10px',
    maxHeight:'70vh',
    // overflowY:'scroll',
    minWidth:'560px',
    padding:'0',    
    zIndex:'15',
    backgroundColor:'white'
}

const header ={

    display:'flex',
    padding:'1rem 2rem 1rem 2rem',
    justifyContent:'space-between',
    backgroundColor:'#F1F2F5',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    position:'sticky',
    top:'0'
    
    
    // borderRadius:'10px',


}

const buttonDiv = {
    display:'flex',
    alignItems:'center'

}
const button = {
    marginLeft:'auto'

}

const para ={
    marginTop:'0px',
    fontWeight:'400',
    fontSize:'1rem',
    color:'var(--clr-font-mid)'
}


const DeletedFile = (props) => {
    const dispatch = useDispatch();
    const [deletedRow, setDeletedRow] = useState({
        loading:false,
        isSuccess: false,
        response: [],
        error_message: ''
    })
    const [change, setChange] = useState(0)
    const [delRows, setDelRows] = useState([])
    const tb_id = useSelector(initialState => initialState.reducer.tb_id) 
    const project_id = localStorage.getItem('project_id');
    
    useEffect(()=>{
        setDeletedRow({
            ...deletedRow,
            loading: true,
        })
        fetchDeletedLineItems(project_id, tb_id).then((response)=>{
            setDeletedRow({
                ...deletedRow,
                isSuccess: true,
                loading: false,
                response: response.data.deleted_items
            })
            setDelRows(response.data.deleted_items.map((row) => row.item));
        }).catch((err)=>{
            console.log("err", err)
            setDeletedRow({
                ...deletedRow,
                isSuccess: false,
                loading: false,
                error_message: err.message
            })
        })
    }, [change])

    const handleRestore = (row) => {
        restoreDeletedLineItems(project_id, row).then((response)=>{
            dispatch(fetchConventionalMode(project_id, tb_id));
            dispatch(restoreLineItems(row, project_id, tb_id));
            const newResultSet = deletedRow.response.filter(item=>item.item.lid !== row.lid);
            setDeletedRow({
                ...deletedRow,
                response: newResultSet
            });
            setDelRows(newResultSet.map((row) => row.item));
        }).catch((err)=>{
            console.log("err", err.message)
            swal("Something went wrong",{
                icon: "danger"
            })
        })
    }

    function handleClose(e){
        if (e.target.classList.contains('screenDiv')){
            props.setDeleteMenu(false)

        }
    }

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
          const defaultRef = React.useRef()
          const resolvedRef = ref || defaultRef
      
          React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
          }, [resolvedRef, indeterminate])
      
          return (
            <>
              <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
          )
        }
      )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { selectedRowIds },
    }= useTable({
        columns:deletedColumn,
        // data: TABLE_MOCK
        data: delRows
        // data: deleted_file
        },
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
              },
              ...columns,
            ])
          }
    
    )

    const RestoreSelected = () => {
      const data = { deletedLineItems : selectedFlatRows.map(ele => ele.original) }

      restoreDeletedAllLineItems(project_id, tb_id, data).then(response => {
        dispatch(fetchConventionalMode(project_id, tb_id));
        dispatch(restoreMultiLineItems(project_id, tb_id));
        const newResultSet = deletedRow.response.filter(item => !selectedFlatRows.some(item2 => item2.original.lid === item.item.lid));
        setDeletedRow({
            ...deletedRow,
            response: newResultSet
        });
        setDelRows(newResultSet.map((row) => row.item));

      }).catch(error => {
        swal("Error", error.response.data.error || "Items Restore Failed!", "error");
      })
    }
    
    return (
      <motion.div
        style={screen}
        className="screenDiv"
        onClick={handleClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <motion.div
          style={container}
          className="col-sm-10 col-md-6"
          initial={{ y: "-5vh" }}
          animate={{ y: 0 }}
        >
          <div style={header}>
            <div className="">
              <h1
                style={{
                  fontSize: "2.2rem",
                  fontWeight: "600",
                  marginTop: "1.5rem",
                }}
              >
                Deleted items
              </h1>
              <p style={para}>Restore deleted Items from here.</p>
            </div>
            <div className='d-flex' >
              {
                selectedFlatRows && selectedFlatRows?.length > 0 ? (
                  <div className='d-flex align-items-center'>
                    <p
                      className='custom-common-theme-text-color custom-font-500 cursor-pointer'
                      onClick={() => RestoreSelected()}
                    >
                      Restore Items
                    </p>
                  </div>
                ): <></>
              }
              <div className='d-flex align-items-center ml-3'>
                <p
                  className='custom-common-theme-text-color custom-font-500 cursor-pointer'
                  onClick={() => {
                    props.setDeleteMenu(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
          {
            deletedRow.loading 
            ?
            <div
                style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                }}
            >
                <Spinner animation="grow" size="sm" />
                <Spinner animation="grow" style={{ margin: "1rem" }} />
                <Spinner animation="grow" size="sm" />
            </div>
            : deletedRow.isSuccess && deletedRow?.response?.length < 1 ?
                <p className='text-left pl-4'>There are no deleted items</p>
            : !deletedRow.isSuccess ?
                <p className='text-left text-danger'>{deletedRow.error_message}</p>
            :
            <div className="tableDeleted" id="containerDiv">
            <table {...getTableProps()} style={{ width: "100%" }}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th className='text-left pl-2 font-inter' {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        if (cell.column.Header == "ACTION") {
                          return (
                            <td
                              {...cell.getCellProps({
                                // style: {
                                //   minWidth: cell.column.minWidth,
                                //   width: cell.column.width,
                                // },
                              })}
                              className="dropMapping"
                            >
                              <p
                                style={{ color: "#03565A", cursor: "pointer" }}
                                onClick={() => {
                                  handleRestore(row.original);
                                }}
                              >
                                {" "}
                                Restore{" "}
                              </p>
                            </td>
                          );
                        }

                        return (
                          <td className='text-left pl-2' {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          }
        </motion.div>
      </motion.div>
    );
}

export default DeletedFile
