import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ViewMatrixTasks({
    isViewModule,
    setIsViewModule,
    setModuleDetails,
    moduleDetails
}) {
    const handleClose = () => {
        setIsViewModule(false);
        setModuleDetails({});
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={isViewModule}
                onClose={handleClose}
            >
                <DialogTitle className='text-capitalize' >{moduleDetails?.module}</DialogTitle>
                <DialogContent>
                    <DialogContentText className='custom-font-600 text-secondary'>
                        Sub Total : {moduleDetails?.subtotal}
                    </DialogContentText>
                    <table>
                        <thead>
                            <tr>
                                <th className='text-left pl-3' >Name</th>
                                <th className='text-left pl-3' >Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                moduleDetails?.tasks && moduleDetails?.tasks.length > 0 &&
                                moduleDetails?.tasks.map(task => (
                                    <tr key={task?._id} >
                                        <td className='text-left pl-3' >{task?.name}</td>
                                        <td className='text-left pl-3' >{task?.amount}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
