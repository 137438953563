import React from 'react';
import "./../Lease/leasesidebar.css"
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Button from "@mui/material/Button";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FolderIcon from '@mui/icons-material/Folder';
import ArticleIcon from '@mui/icons-material/Article';
import DragHandleTwoToneIcon from '@mui/icons-material/DragHandleTwoTone';


function LeaseSidebar() {
    return (

        <div className='lease_sidebar'>
            <center>
                <div className='ne_sidebar'>
                    <Button className='ne_btn'>Ne</Button>
                    <MenuOpenIcon
                        style={{ height: "50px", width: "30px", marginTop: "20px" }} />
                </div>
            </center>
            <div className='px-4 mt-2 font-weight-bold'>Lease project</div>
            <div className='px-4 ' style={{ color: "#03565A" }}>NESCAFE</div>
            <div className='px-4 mt-2'>
                <input style={{ width: "250px", height: "40px", border: "2px solid gray", borderRadius: "5px" }} type="date" placeholder='hello' />
                <input style={{ width: "250px", height: "40px", border: "2px solid gray", borderRadius: "5px", marginTop: "10px" }} type="date" />
                <hr />
            </div>
            <div>
                <div className="department_v1">
                    <InsertPhotoIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", fontWeight: "bold" }}>Overview</div>
                </div>
                {/*  */}
                <div className="department_v1">
                    <UploadFileIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", fontWeight: "normal" }}>Summary movement</div>
                </div>
                {/*  */}
                <div className="department_v1">
                    <FolderIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", fontWeight: "normal" }}>Account entries</div>
                </div>
                {/*  */}
                <div className="department_v1">
                    <ArticleIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", fontWeight: "normal" }}>Leases</div>
                </div>
                <center>
                    <div style={{ width: "84%" }}><hr /></div>
                </center>
                {/*  */}
                <div className="department_v1">
                    <DragHandleTwoToneIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", fontWeight: "normal" }}>Repository</div>
                </div>

            </div>

        </div>

    );
}
export default LeaseSidebar;