import React from 'react';

const CommonInputCell = ({ name, type, value, style, readOnly, className, onChange }) => {
    return (
        <input
            name={name}
            type={type}
            value={value}
            style={style}
            readOnly={readOnly}
            className={`w-100 h-100 text-right pr-1 border-0 text-secondary ${className}`}
            onChange={onChange}
        />
    );
};

CommonInputCell.defaultProps = {
    type: 'text',
    readOnly: false,
    className: '',
};

export default CommonInputCell;
