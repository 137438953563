import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';


export function NBFC_37_41({ data, subIndex, HandleChange, dis_index_id, calculateTotal }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});
    const totalofNetcashOutflows = (type) => {
        const total1 = calculateTotal(data, type, { start: 3, end: 12 });
        const total2 = calculateTotal(data, type, { start: 12, end: 13 });

        return (total1 - total2) || 0;
    };
    const liquidityCoverageRatio = (type) => {
        const total1 = data[1]?.[type];
        const total2 = totalofNetcashOutflows(type);

        const ratio = (total1 / total2) || 0;
        const roundedRatio = ratio.toFixed(5);

        return parseFloat(roundedRatio);
    };


    return (
        <>
            <thead>
                <tr>
                    <th className='text-left w-50 pl-3 border-top-0 border-bottom-0' rowSpan={2}>Particulars</th>
                    <th className='w-auto border-top-0 border-bottom-0' colSpan={2} style={{ minWidth: "110px" }}>As at {""}{moment(reduxProjectData.current_year).format("DD MMMM YYYY")} </th>
                    <th className='w-auto border-top-0 border-bottom-0' colSpan={2} style={{ minWidth: "110px" }}>As at {""} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <DisclosureNBFCTableHead isEmpty={false} />
                </tr>
                <tr>
                    <th className='w-auto border-top-0 border-bottom-0' >Total Unweighted Value(average)</th>
                    <th className='w-auto border-top-0 border-bottom-0' >Total Weighted Value (average)</th>
                    <th className='w-auto border-top-0 border-bottom-0' >Total Unweighted Value(average)</th>
                    <th className='w-auto border-top-0 border-bottom-0' >Total Weighted Value (average)</th>
                    <DisclosureNBFCTableHead isEmpty={true} />
                </tr>
            </thead>
            <tbody>
                {data && data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            <tr>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                        type={"text"}
                                        value={subDis?.header}
                                        name="header"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.cy_total_unweighted !== "number"}
                                        value={formatIndianCurrency(subDis?.cy_total_unweighted)}
                                        name="cy_total_unweighted"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.cy_total_weighted !== "number"}
                                        value={formatIndianCurrency(subDis?.cy_total_weighted)}
                                        name="cy_total_weighted"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.py_total_unweighted !== "number"}
                                        value={formatIndianCurrency(subDis?.py_total_unweighted)}
                                        name="py_total_unweighted"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.py_total_weighted !== "number"}
                                        value={formatIndianCurrency(subDis?.py_total_weighted)}
                                        name="py_total_weighted"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                        isReference={subDis?.isReference}  keyValue={16} index={subIndex}  sub_index={subDisIndex} />
                            </tr>
                            {
                                subDis.sub_dis_index_id === 12 && (
                                    <tr>
                                        <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>TOTAL CASH OUTFLOWS</td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "cy_total_unweighted", { start: 3, end: 12 })} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "cy_total_weighted", { start: 3, end: 12 })} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "py_total_unweighted", { start: 3, end: 12 })} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "py_total_weighted", { start: 3, end: 12 })} </td>
                                        <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
                                    </tr>
                                )
                            }
                            {
                                subDis.sub_dis_index_id === 17 && (
                                    <tr>
                                        <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>TOTAL CASH INFLOWS</td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "cy_total_unweighted", { start: 14, end: 17 })} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "cy_total_weighted", { start: 14, end: 17 })} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "py_total_unweighted", { start: 14, end: 17 })} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "py_total_weighted", { start: 14, end: 17 })} </td>
                                        <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""}/>
                                    </tr>
                                )
                            }
                        </>
                    ))
                }
                <tr>
                    <td className='text-left pl-3 font-weight-bold text-dark border-top-0 border-bottom-0'>TOTAL HQLA</td>
                    <td className='text-right pr-3 font-weight-bold text-dark border-top-0 border-bottom-0'>  </td>
                    <td className='text-right pr-3 font-weight-bold text-dark border-top-0 border-bottom-0'>{data[1]?.cy_total_weighted} </td>
                    <td className='text-right pr-3 font-weight-bold text-dark border-top-0 border-bottom-0'>  </td>
                    <td className='text-right pr-3 font-weight-bold text-dark border-top-0 border-bottom-0'> {data[1]?.py_total_weighted} </td>
                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} 
                        isReference={""} />
                </tr>
                <tr>
                    <td className='text-left pl-3 font-weight-bold text-dark border-top-0 border-bottom-0'>TOTAL NET CASH OUTFLOWS</td>
                    <td className='text-right pr-3 font-weight-bold text-dark border-top-0 border-bottom-0'>  </td>
                    <td className='text-right pr-3 font-weight-bold text-dark border-top-0 border-bottom-0'>{totalofNetcashOutflows('cy_total_weighted')}  </td>
                    <td className='text-right pr-3 font-weight-bold text-dark border-top-0 border-bottom-0'>  </td>
                    <td className='text-right pr-3 font-weight-bold text-dark border-top-0 border-bottom-0'>{totalofNetcashOutflows('py_total_weighted')}  </td>
                    <DisclosureNBFCTableBody isEmpty={true} refData={[]}
                        isReference={""} />
                </tr>
                <tr>
                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200 border-top-0 border-bottom-0'>LIQUIDITY COVERAGE RATIO (%)</td>
                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 border-top-0 border-bottom-0'>  </td>
                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 border-top-0 border-bottom-0'> {liquidityCoverageRatio('cy_total_weighted')} </td>
                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 border-top-0 border-bottom-0'>  </td>
                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 border-top-0 border-bottom-0'> {liquidityCoverageRatio('py_total_weighted')} </td>
                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
                </tr>
            </tbody>
        </>
    );
}