const MSTypes = {
    MS_ADD_CONSOLE_DATA:"MS_ADD_CONSOLE_DATA",
}

const Initial_state = {
    prepareConsole: {}
}

const miscellaneousReducer = (state=Initial_state, action) => {
    switch(action.type){
        case MSTypes.MS_ADD_CONSOLE_DATA: 
            return {
                ...state,
                prepareConsole: action.payload
            }
        default:
            return state;
    }
};

export default miscellaneousReducer;