import { getNotesIndex } from "../../services/api/apiHandler";


export const fetchNotesData = (project_id) => {
    return dispatch => {
        dispatch({type:"CN_ACTION_START"})
        getNotesIndex(project_id).then((result)=>{
            dispatch({
                type: "CN_FETCH_DATA",
                payload: {data: result.data.result, set_payload: project_id }
            })
        }).catch((err)=>{
            dispatch({
                type:"CN_FETCH_DATA_FAILED",
                payload: err.response.data.error || "something went wrong!"
            })
        })        
    }
}

export const socketCNUpdate = (response, project_id) => {
    return dispatch => {
        dispatch({
            type: "CN_SOCKET_UPDATE_DATA",
            payload: {
                data: response, set_payload: project_id
            }
        })
    }
}