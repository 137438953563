import { getAllOtherDetails } from "../../../WorkSpace/Components/ApiServices/apiHandler";


export const fetchAllDetailsData = (id) => {
    return dispatch => {
        dispatch({type:"OTHERSDETAILS_ACTION_START"})
        const allDetailsId=id;
        getAllOtherDetails(allDetailsId).then((result)=>{
            dispatch({
                type: "OTHERSDETAILS_FETCH_DATA",
                payload: result.data?.other_items
            }) 
        }).catch((error)=>{
            dispatch({
                type: "OTHERSDETAILS_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}