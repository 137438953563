import Sidbarv2 from "../../Workspace-v2/Sidbarv2";
import React from "react";

import MuiToggleButton from "@mui/material/ToggleButton";
import { Navbar } from "../../../Components/Navbar";
import { styled, createTheme } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router";
import { useSelector} from "react-redux";
import swal from "sweetalert";
import ShareInvestRegister from "../WorkspaceCompany/Dashboard/shareInvestRegister";

import {
  GetAllCompany,
  GetUser,
  getAllIR,
  getAllInvestRegister,
  createDuplicate,
  getCheckData,
  GetLease,
  GetLoan,
} from "../ApiServices/apiHandler";

import { useState } from "react";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";

import IRTab from "../WorkspaceCompany/Dashboard/IRTab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const bull = (
  <Box 
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
const WorkspaceRegisterv2 = (props) => {

  const location = useLocation();
  let a = location.pathname;
  let w_id = a.split("/");
  const [loader, setLoder] = useState("");
  
  const [allcompany, setAllCompany] = useState([]);
  const [oneAccess, setOneAccess] = useState("");
  const history = useHistory();
  const [allUser, setAllUser] = useState([]);
  const userId = localStorage.getItem("user_id");
  const [allInvestRegister, setAllInvestRegister] = useState([]);
  const [popClose,setPopClose] = useState(false)


  const all_workSpace = useSelector((state) => state.AllWSReducer);


  
  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: selectedColor, 
    },
  }));

  const theme = createTheme({
    palette: {
      text: {
        primary: "#00ff00",
      },
    },
  });
  const [alignment, setAlignment] = React.useState("left");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [allAcessWorkspace, setAllAcessWorkspace] = useState([]);
  const [oneCheckWorkspace,setOneCheckWorkspace] = useState([]);
  const [checkLoandata,setCheckLoandata] = useState([]);
  const [checkLeasedata,setCheckLeasedata] = useState([]);
  const [checkReceivabledata,setCheckReceivabledata] = useState([]);
  const [checkPaybledata,setCheckPaybledata] = useState([]);
  const [checkInvestmentdata,setCheckInvestmentdata] = useState([]);
  const [allWorkspace, setWorkspace] = useState([]);
  const [shareScreen, SetShareScreen] = useState(false);
  const [irId, setIrId] = useState("");
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [allIr, setAllIR] = useState([]);
  const [loan, setLoan] = useState([]);
  const [lease, setLease] = useState([]);
  const [leasesData,setLeasesData] = useState({
    all_leases:[]
  });
  const [checkedLeases,setCheckedLeases] = useState({
    all_leases:[]
  });
  const [presetsData,setPresetsData] = useState({
    all_presets:[]
  });
  const [loansData,setLoansData] = useState({
    all_loans:[]
  });
  const [checkedLoans,setCheckedLoans] = useState({
    all_loans:[]
  });
  const [checkedInvestment,setCheckedInvestment] = useState({
    all_investments:[]
  });

  useEffect(() => {
    // AllIrData();
    allCompany();
    checkWorkspace();
    GetAllUser();
    CheckloanData();
    CheckleaseData();
    CheckpaybleData();
    CheckreceivableData();
    CheckinvestmentData();
    getAllIrData();
    userAccessIr();
    getAll();
    LoanData();
    LeaseData();
  }, []);

  // const handleAlignment = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  // };
  const GetAllUser = async () => {
    const res = await GetUser();
    if (res.status === 200) {
      setAllUser(res.data.fetchUser);
    } 
  };
  // const AllIrData = async () => {
  //   setLoder(true);
  //   const res = await getAllIR();
  //   if (res.status === 200) {
  //     console.log(res.data.fetchIR);
  //     setAllIr(res.data.fetchIR);
    
  //   }
  // };

  const allCompany = async () => {
    const res = await GetAllCompany();
    if (res.status === 200) {
      setAllCompany(res.data.getAllCompanyData);
    }
  };

  const getDate = (c_id) => {
    const result = allcompany.filter((adj) => adj._id === c_id);
   
    return result[0]?.company_name ? result[0]?.company_name : "-";
  };
  const getAll = async () => {
    const res = await getAllInvestRegister();
    if (res.status === 200) {
      setAllInvestRegister(res.data.fetchIR);
    }
  };
  const [anchorEl, setAnchorEl] = useState("null");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //

  const [value, setValue] = React.useState(0); 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // const getOneAccessWorkspace = async () => {
  //   const res = await getOneAccessWorkspacedata(w_id[2]);
  //   if (res.status === 200) {
  //     setOneAccess(res.data.getOneAccessWorkspace[0]);
  //   }
  // };

  const checkWorkspace = async()=>{
    const res = await getCheckData(w_id[2]);
    if (res.status === 200) {
      // setOneCheckWorkspace(res.data.getIR[0].all_preset_checked);
      setPresetsData({...presetsData, all_presets:res.data.getIR[0].all_preset_checked}) 
    }
  }
  
  const CheckloanData = async()=>{
    const res = await getCheckData(w_id[2]);
    if (res.status === 200) {
      // setCheckLoandata(res.data.getIR[0].all_checked_loans);
      setCheckedLoans({...checkedLoans, all_loans:res.data.getIR[0].all_checked_loans}) 
    }

  }
  const CheckleaseData = async()=>{
    const res = await getCheckData(w_id[2]);
    if (res.status === 200) {
      // setCheckLeasedata(res.data.getIR[0].all_checked_leases);
      setCheckedLeases({...checkedLeases, all_leases:res.data.getIR[0].all_checked_leases}) 
    }

  }
  const CheckpaybleData = async()=>{
    const res = await getCheckData(w_id[2]);
    if (res.status === 200) {
      setCheckPaybledata(res.data.getIR[0]?.all_checked_payable);
    }

  }
  const CheckreceivableData = async()=>{
    const res = await getCheckData(w_id[2]);
    if (res.status === 200) {
      setCheckReceivabledata(res.data.getIR[0]?.all_checked_receivable);
    }

  }

  const CheckinvestmentData = async()=>{
    const res = await getCheckData(w_id[2]);
    if (res.status === 200) {
      // setCheckInvestmentdata(res.data.getIR[0].all_checked_investment);
      setCheckedInvestment({...checkedInvestment, all_investments:res.data.getIR[0].all_checked_investment}) 
    }

  }


  const continueStep =()=>{
    history.push(`/insideworkspace`);
  }

 

  const handleClickData = (w_id) => {
    const statusSteps = all_workSpace.response.filter((adj) => adj._id === w_id);
    if (statusSteps[0]?.steps_status === "step1") {
      props.history.push(`/workspacesetup/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step2") {
      props.history.push(`/addCompanies/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step3") {
      props.history.push(`/importregister/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step4") {
      props.history.push(`/importPreset/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step5") {
      props.history.push(`/inviteteammember/${w_id}`);
    } else {
      props.history.push(`/overview/${w_id}`);
    }
  };

  const workspace = (wid, data) => {
    try {
      const nameWorkspace = allWorkspace?.filter((adj) => adj._id === wid);
      const result = nameWorkspace[0]?.workspace_name
        ? nameWorkspace[0]?.workspace_name
        : "unknown";
      if (data === "id") {
        return result;
      }
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  const nextWorkspace = () => {
    props.history.push(`/allWorkspace`);
  };

  const handleClicktab = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShareScreen = (id)=>{
    SetShareScreen(true);
    setIrId(id);
    setAnchorEl3(null)
  }

  const userAccessIr = ()=>{
    if (allUser.length > 0) {
      const user = allUser.filter((adj) => adj._id === userId);
    const data = user[0]?.access_investregister?.map((det)=>det.investId);
    const Ir_data = data && data.length > 0 ? data.map((red)=>allInvestRegister.filter((det)=>det.ir_id === red)) : [];
      return Ir_data;

    } 
  }

  const handleDuplicate =async (ir_id)=>{
    swal({
      title: "Are you sure?",
      text: " you want to Duplicate this workspace?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async(willDelete) => {
      if (willDelete) {
        const res =await createDuplicate(ir_id,userId);
        if (res.status === 200) {
          getAllIrData();
          setAnchorEl3(null)
          swal("", "Duplicate Created Successfully", "success");
        }
      }
    });
    
  }

  const getAllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIR(res.data.fetchIR);
    } 
  }; 

  const LoanData = async () => {
    try {
      const res = await GetLoan();
      if (res.status === 200) {
        // setLoan(res.data.loans);
        setLoansData({...loansData, all_loans:res.data.loans})
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const LeaseData = async () => {
    try {
      const res = await GetLease();
      if (res.status === 200) {
        // setLease(res.data.projects);
        setLeasesData({...leasesData, all_leases:res.data.projects}) 
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };
  

  

  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>
        <div className="d-flex">
          <div>
            <Sidbarv2 w_id={w_id} /> 
          </div>

          <div className="container">
            <div className="d-flex justify-content-between mt-5">
            </div>
            {/* <Tabsystem/> */}                
             {shareScreen ? <ShareInvestRegister SetShareScreen={SetShareScreen} isInvest={irId} /> : null}
             
             <div className={allIr?.length > 0 || userAccessIr()?.length > 0 || loansData.all_loans.length > 0 || leasesData.all_leases.length > 0? "col-md-12" : "col-md-7"}>
             <IRTab              
              allWorkspace={all_workSpace.response}
              nextStep={continueStep} 
              handleClickData={handleClickData}
              allAcessWorkspace={allAcessWorkspace}
              workspace={workspace}
              loader={loader}                      
              handleClick={handleClick}
              allIr={allIr} 
              nextWorkspace={nextWorkspace}
              value={value} 
              handleChange={handleChange}
              a11yProps={a11yProps}
              handleClicktab={handleClicktab}
              CustomTabPanel={CustomTabPanel}
              id={id}
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              setAllIR={allIr}
              // company={companydata}
              insideWorskpace={"yes"}
              workspaceID={w_id}
              handleShareScreen={handleShareScreen}
              shareScreen={shareScreen}
              oneCheckWorkspace={oneCheckWorkspace}
              userAccessIr={userAccessIr}
              checkInvestmentdata={checkInvestmentdata}
              allInvestRegister={allInvestRegister}
              handleDuplicate={handleDuplicate}
              popClose={popClose}
              setAnchorEl3={setAnchorEl3}
              anchorEl3={anchorEl3} 
              loan={loan}
              setLoan={setLoan}
              loansData={loansData}
              lease={lease}
              setLease={setLease}
              leasesData={leasesData}
              checkLoandata={checkLoandata}
              checkedLoans={checkedLoans}
              checkLeasedata={checkLeasedata}
              checkedLeases={checkedLeases}
              checkPaybledata={checkPaybledata}
              checkReceivabledata={checkReceivabledata}
              setOneCheckWorkspace={setOneCheckWorkspace}
              presetsData={presetsData}
              setCheckLoandata={setCheckLoandata}
              setCheckLeasedata={setCheckLeasedata}
              setCheckInvestmentdata={setCheckInvestmentdata}
              checkedInvestment={checkedInvestment}
             />

              </div>


          </div>
        </div>
      </div>
    </>
  );
};
export default WorkspaceRegisterv2;
