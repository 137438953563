import { fetchSocie } from "../../Console/Component/services/apiHandler";


export const fetchSocieData = (payload) => {
    return dispatch => {
        dispatch({type: "SOCCIE_ACTION_START"})
        fetchSocie(payload.project_ids, payload.browse_fs_ids, payload.current_comp_project_id).then((result)=>{
            dispatch({
                type: "SOCCIE_FETCH_DATA",
                payload: {data: result.data, payload: JSON.stringify(payload)}
            })
        }).catch((err)=>{
            dispatch({
                type:"SOCCIE_FETCH_DATA_FAILED",
                payload: err.response.data.error
            })
        })        
    }
} 
