import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import CommonToolTip from '../../Common/CommonToolTip';
import ErrorIcon from "@mui/icons-material/Error";
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';

export function NBFC_35({ data, subIndex, HandleChange, dis_index_id }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});

    function formatNumber(number) {
        if (Number.isInteger(number)) {
            return number.toString();
        } else {
            return number.toFixed(3);
        }
    }

    const calculateTotal = (singleData, key) => {
        const dataToCalculate = singleData.slice(0, -1);
        const calculatedVal = dataToCalculate.reduce((sum, item) => sum + (Number(item[key]) || 0), 0);
        return formatNumber(calculatedVal)
    };

    const checkCondition = (year, totalYear, dataArray, key) => {
        // if(!totalYear) return <></>;
        const Year = calculateTotal(dataArray, key);
        const isYearMismatch = totalYear !== undefined ? Year !== formatNumber(totalYear) : false;
        let isDataMismatch = false;
        if (key === "py" && dataArray?.length > 0) {
            isDataMismatch = formatNumber(Number(dataArray[0]?.cy)) !== calculateTotal(dataArray, "py");
        }

        return ( isYearMismatch || isDataMismatch ) &&
            <div className='d-flex align-items-center justify-content-center'>
                <CommonToolTip
                    variant="primary"
                    title={"The values are not matching." }
                    placement="top"
                >
                    <ErrorIcon
                        style={{
                            color: "darkorange",
                            cursor: "pointer",
                        }}
                        className="financialInstrument-hoverIcon"
                    />
                </CommonToolTip>
            </div>
    }

    return (
        <>
            <thead>
                <tr>
                    <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                        Particulars
                    </th>
                    <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}>
                        As at {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}
                    </th>
                    <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}>
                        As at {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
                    </th>
                    <DisclosureNBFCTableHead isEmpty={false} />
                </tr>
            </thead>

            <tbody>
                {data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex, dataArray) => (
                        <React.Fragment key={subDisIndex}>
                            <tr>
                                <td className='border-top-0 border-bottom-0'>
                                    <input style={{ backgroundColor: "inherit" }}
                                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                            }`}
                                        type={"text"}
                                        value={subDis?.header}
                                        name="header"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    {
                                        subDisIndex === data.length - 1 ?
                                            <div className='pr-3 text-right'>
                                                {calculateTotal(dataArray, "cy")}
                                                {checkCondition(subDis?.cy, subDis?.totalTagAssetsCy, dataArray, "cy")}
                                            </div> :
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                // readOnly={typeof subDis?.cy !== "number"}
                                                type={"text"}
                                                value={subDis?.cy}
                                                name="cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex, null, "lease")}
                                            />
                                    }
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    {
                                        subDisIndex === data.length - 1 ?
                                            <div className='pr-3 text-right'>
                                                {calculateTotal(dataArray, "py")}
                                                {checkCondition(subDis?.py, subDis?.totalTagAssetsPy, dataArray, "py")}
                                            </div> :
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                // readOnly={typeof subDis?.py !== "number"}
                                                value={subDis?.py}
                                                name="py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex, null, "lease")}
                                            />
                                    }
                                </td>
                                <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                   isReference={subDis?.isReference}  keyValue={12} index={subIndex}  sub_index={subDisIndex}/>
                            </tr>
                        </React.Fragment>
                    ))}
            </tbody>
        </>
    );
}