import { getAdjustmentLogs, getAllAdjustments, getDeletedAdjustments, getLedger } from "../../services/api/apiHandler";

export const fetchSAAdjustment = (project_id, tb_id) => {
    return dispatch => {
        dispatch({type:"SA_ADJ_ACTION_START"})
        getAllAdjustments(project_id, tb_id).then((result)=>{
            dispatch({
                type: "SA_ADJ_FETCH_DATA",
                payload: result.data
            }) 
        }).catch((error)=>{
            dispatch({
                type: "SA_ADJ_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}

export const fetchSALedger = (project_id, tb_id) => {
    return dispatch => {
        dispatch({type:"SA_ADJ_LEDGER_START"})
        getLedger(project_id, tb_id).then((result)=>{
            dispatch({
                type: "SA_ADJ_LEDGER_DATA",
                payload: result.data
            }) 
        }).catch((error)=>{
            dispatch({
                type: "SA_ADJ_LEDGER_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}

export const fetchDeletedAdj = (project_id, tb_id) => {
    return dispatch => {
        dispatch({type:"DLT_ADJ_START"})
        getDeletedAdjustments(project_id, tb_id).then((result)=>{
            dispatch({
                type: "DLT_ADJ_DATA",
                payload: result.data
            }) 
        }).catch((error)=>{
            dispatch({
                type: "DLT_ADJ_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}

export const fetchAdjLogs = (project_id, tb_id) => {
    return dispatch => {
        dispatch({type:"ADJ_LOG_START"})
        getAdjustmentLogs(project_id, tb_id).then((result)=>{
            dispatch({
                type: "ADJ_LOG_DATA",
                payload: result.data
            }) 
        }).catch((error)=>{
            dispatch({
                type: "ADJ_LOG_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}

