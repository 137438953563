import { Card } from '@mui/material';
import React from 'react';
import INDSPIC2 from "../../assets/Image/Library/INDIAS2.png";
import INDSPIC from "../../assets/Image/Library/IND as.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';
import { Link } from "react-router-dom";


const LibraryCard = ({ item }) => {
    return (
        <>
            <Card className="Main_Card">
                <div className="text-center pt-4 lock_btn">
                    <img className="Img_Style" src={item.lucked ? INDSPIC2 : INDSPIC} />
                    {item.lucked &&
                        <div className="lock-profile">
                            <a href="#" className="btn btn-light px_25">
                                <LockIcon className='mr-2' /><span className='mt-2'>Locked</span> </a>
                        </div>
                    }
                </div>
                <div className="Scnd_div">
                    <p className="h6 ml-4 m-0" style={{ fontWeight: "700", fontSize: "20px" }} >{item.label}</p>
                    <Link to={item?.herf}>
                        <div className="flot-right mr-4" >
                            <ArrowForwardIcon style={{ backgroundColor: "#D1E0E0", cursor: "pointer" }} className='custom-common-theme-text-color rounded p-1' />
                        </div>
                    </Link>
                </div>
            </Card>
        </>
    )
}

export default LibraryCard