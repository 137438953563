import * as React from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function ShowLibraryReference({isShowRef, setIsShowRef}) {
    return (
        <>
            <BootstrapDialog
                onClose={() => setIsShowRef({...isShowRef, isShow:false})}
                aria-labelledby="customized-dialog-title"
                open={isShowRef.isShow}
            >
                <div className='light-blue w-100 d-flex justify-content-between align-items-center'>
                    <DialogTitle sx={{ m: 0, p: 2, fontWeight:"600" }} id="customized-dialog-title">{isShowRef?.data?.reference}</DialogTitle>
                    <div>
                        <Button className='custom-common-theme-bg-color text-white mr-3 p-2'> Reference in financial </Button>
                        <IconButton
                            aria-label="close"
                            onClick={() => setIsShowRef({...isShowRef, isShow:false})}
                            sx={{
                                color: (theme) => theme.palette.grey[800],
                                marginRight:"10px",
                                backgroundColor:"white",
                                borderRadius:"7px"
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent dividers>
                    {
                        isShowRef?.data?.libraryReference && isShowRef?.data?.libraryReference.length > 0 && isShowRef?.data?.libraryReference.map((text, ix) => (
                            <Typography gutterBottom key={ix}>{text}</Typography>
                        ))
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setIsShowRef({...isShowRef, isShow:false})}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}