import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from "react-router-dom";

const AdminNavbar = () => {
    const history = useHistory(); 

    return (
        <div className='custom-common-theme-bg-color' style={{ height: "70px" }}>
            <div className='d-flex  px-3 py-2'>
                <button
                    className='d-flex align-items-center p-3 border-0 bg-color-inherit'
                    onClick={() => history.push('/dashboard')}
                >
                    <ArrowBackIcon className='text-white' />
                    <p className='mt-0 mb-0 font-size-16 custom-font-600 text-white ml-1'>Go Home</p>
                </button>
            </div>
        </div>
    )
}

export default AdminNavbar