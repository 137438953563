import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import "./Ratio.css"
import axios from 'axios';
import {ConvertDecimal, Variances} from "../../../helper/MathematicsCalculation";
import swal from 'sweetalert';
import {ratioCreateNewInput} from "../../../services/api/StandAlone/disclosuresTabAPI";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    border:"1.3px",
    // boxShadow: 24,
    p: 4,
    borderRadius: "0.3rem",

    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
};

const inputStyle = {
    color:"black",
    marginTop:"10px",
    borderRadius: "6px"
}
const text = {
    color:"#03565a"
}

const UpdateRatioModal = ({setIsOpen, isOpen, ratioId, setRatioData}) => {
    const project_id = localStorage.getItem('project_id');
    const auth = localStorage.getItem("auth_token");

    const [ratioUpdateData, setRatioUpdateData] = useState({
        ratio:"",
        numerator:"",
        denominator:"",
        cp:0,
        pp:0,
        ratioErr:false,
        numeratorErr:false,
        denominatorErr:false,
        cpErr:false,
        ppErr:false,
    });

    const HandleClick = () => {
        console.log("ratioUpdateData", ratioUpdateData)
        if(!ratioUpdateData.numerator){
            setRatioUpdateData({...ratioUpdateData, numeratorErr:true})
            return;
        }
        if(!ratioUpdateData.denominator){
            setRatioUpdateData({...ratioUpdateData, denominatorErr:true})
            return;
        }
        if(ratioUpdateData.cp === ""){
            setRatioUpdateData({...ratioUpdateData, cpErr:true})
            return;
        }
        if(ratioUpdateData.pp === ""){
            setRatioUpdateData({...ratioUpdateData, ppErr:true})
            return;
        }
        if(!ratioUpdateData.ratio){
            setRatioUpdateData({...ratioUpdateData, ratioErr:true})
            return;
        }

        let headers = {
            "x-auth-token": auth,
        };
        const data = {
            header: ratioUpdateData.ratio,
            numerator: ratioUpdateData.numerator,
            denominator: ratioUpdateData.denominator,
            cp: Number(ratioUpdateData.cp),
            pp: Number(ratioUpdateData.pp),
            variances: Variances(ratioUpdateData.cp, ratioUpdateData.pp),
            reason_variances: ""
        }

        ratioCreateNewInput(`api/v1/disclosure/create-new-item/${ratioId}`, data).then((res) => {
            if(res.success){
                setRatioData(res?.data)
                setIsOpen(false);
            }
        }).catch((err) => {
            swal("", err.message, "error");
            setIsOpen(false);
        })
    }

    return (
        <>
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Box sx={{ ...style, width: 550 }}>
                    <h2 id="parent-modal-title" style={text}>Create New Input</h2>
                    <div id="parent-modal-description">
                        <div className='d-flex' style={{gap:"0.5rem"}}>
                            <div className='w-50'>
                                <p className='mb-0 mt-1' >Numerator <span style={{color:'red'}}>*</span></p>
                                <input style={inputStyle} 
                                    className={`p-2 ${ratioUpdateData.numeratorErr ? "border-error" : "border-ratio"}`}
                                    type='text' 
                                    value={ratioUpdateData.numerator} 
                                    onChange={(e) => {setRatioUpdateData({...ratioUpdateData, numerator:e.target.value, numeratorErr:false})}} 
                                />
                            </div>
                            <div className='w-50'>
                                <p className='mb-0 mt-1' >Denominator <span style={{color:'red'}}>*</span></p>
                                <input style={inputStyle} 
                                    className={`p-2 ${ratioUpdateData.denominatorErr ? "border-error" : "border-ratio"}`}
                                    type='text' 
                                    value={ratioUpdateData.denominator} 
                                    onChange={(e) => {setRatioUpdateData({...ratioUpdateData, denominator:e.target.value, denominatorErr:false})}} 
                                />
                            </div>
                        </div>
                        <div className='d-flex' style={{gap:"0.5rem"}}>
                            <div className='w-50'>
                                <p className='mb-0 mt-1' >Current period <span style={{color:'red'}}>*</span></p>
                                <input style={inputStyle} 
                                    className={`p-2 ${ratioUpdateData.cpErr ? "border-error" : "border-ratio"}`}
                                    type='number' 
                                    value={ratioUpdateData.cp} 
                                    onChange={(e) => {setRatioUpdateData({...ratioUpdateData, cp:e.target.value, cpErr:false})}} 
                                />
                            </div>
                            <div className='w-50'>
                                <p className='mb-0 mt-1' >Previous period <span style={{color:'red'}}>*</span></p>
                                <input style={inputStyle} 
                                    className={`p-2 ${ratioUpdateData.ppErr ? "border-error" : "border-ratio"}`}
                                    type='number' 
                                    value={ratioUpdateData.pp} 
                                    onChange={(e) => {setRatioUpdateData({...ratioUpdateData, pp:e.target.value, ppErr:false})}} 
                                />
                            </div>
                        </div>
                        <div className='d-flex' style={{gap:"0.5rem"}}>
                            <div className='w-50'>
                                <p className='mb-0 mt-1' >Ratio Name<span style={{color:'red'}}>*</span></p>
                                <input style={inputStyle} 
                                    className={`p-2 ${ratioUpdateData.ratioErr ? "border-error" : "border-ratio"}`}
                                    type='text' 
                                    value={ratioUpdateData.ratio} 
                                    onChange={(e) => {setRatioUpdateData({...ratioUpdateData, ratio:e.target.value, ratioErr:false})}} 
                                />
                            </div>
                        </div>

                        <div className='mt-4' style={{textAlign:"center"}}>
                            <button 
                                className='px-4 py-3 border-ratio rounded-lg curtomBtn' 
                                style={{backgroundColor:"#03565a", color:"white", fontWeight:"600"}} 
                                onClick={() => HandleClick()}
                            > Create New Input </button>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal>
        </>
    )
}

export default UpdateRatioModal

