import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { BookClosureButton } from '../Pages/Bookclosure';

export default function CheckFieldDialog({isFieldDialog, setIsFieldDialog, setIsAddTaskModal, onClick}) {
    const handleClose = () => {
        setIsFieldDialog(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={isFieldDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className='font-weight-bold'>
                    {"Choose Filed"}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <BookClosureButton 
                            className={"px-4"}
                            onClick={onClick}
                        >
                            Select From Library
                        </BookClosureButton>
                        <BookClosureButton 
                            className={"px-4 ml-3"}
                            onClick={() => setIsAddTaskModal(true)}
                        >
                            Add Task
                        </BookClosureButton>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='contained' color="error" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
