import { getConsolePnlData } from "../../Console/Component/services/apiHandler";


export const fetchAssociateData = (project_ids, browse_fs_ids, currentCompProjectId) => {
    return dispatch => {
        dispatch({type:"ASSOCIATE_ACTION_START"})
        getConsolePnlData(project_ids, browse_fs_ids, currentCompProjectId).then((result)=>{
            dispatch({
                type: "ASSOCIATE_FETCH_DATA",
                payload: {data: result.data, set_payload: JSON.stringify({project_ids, browse_fs_ids, currentCompProjectId})}
            })
        }).catch((err)=>{
            dispatch({
                type:"ASSOCIATE_FETCH_DATA_FAILED",
                payload: err.response.data.error
            })
        })        
    }
}