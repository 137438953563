import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux';
import { socket } from "../../../services/socket/socket";
import { ConvertDecimal } from "../../../helper/ConvertValue"
import UpdateRatioModal from './UpdateRatioModal';
import { Variances } from "../../../helper/MathematicsCalculation"
import useFetchApi from '../../../services/CustomHook/useFetchApi';
import swal from 'sweetalert';
import Loading from '../../Common/Loader/Loading';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { financialInstrumentUpdate } from '../../../services/api/StandAlone/disclosuresTabAPI';


const inputDiv = { width: "100%", height: "100%", outline: "none", border: "none", backgroundColor: "#FFF9D4", textAlign: "left" }
const tdStyle = { textAlign: "left", paddingLeft: "1rem", width: "18%" }
const Ratios = () => {
    const project_id = localStorage.getItem('project_id');
    const TB_ID = useSelector(initialState => initialState.reducer.tb_id);
    const { loading, response, error } = useFetchApi(`api/v1/disclosure/gr/${project_id}/${TB_ID}`);

    const [ratioData, setRatioData] = useState([]);
    const [ratioId, setRatioId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [varianceReason, setVarianceReason] = useState("")

    useEffect(() => {
        if (response && Object.keys(response).length > 0) {
            setRatioData(response?.data?.data);
            setRatioId(response?.data?._id);
        }
        if (error) {
            swal("Error", error, "error");
        }
    }, [response || error])

    const HandleChange = (value, index, key) => {
        // console.log("handle change ----", value, index, key)
        let copyValue = Number(value);

        let copy = [...ratioData]
        copy.map((ele, eleInd) => {
            if (eleInd === index) {
                if (key === "cp") {
                    ele.cp = copyValue
                }
                if (key === "pp") {
                    ele.pp = copyValue
                }
            }
        })

        setRatioData(copy);
        console.log("ratioId", ratioId)
        socket.emit("auto-save-ratio", {
            project_id: `${project_id}`,
            ratioId: ratioId,
            contents: copy,
        });
    }

    const HandleVarianceChange = (value, ind) => {
        let copy = [...ratioData]

        copy.forEach((ele, index) => {
            if (ind === index) {
                ele.reason_variances = value
            }
        })

        setRatioData(copy)

        socket.emit("auto-save-ratio", {
            project_id: `${project_id}`,
            ratioId: ratioId,
            contents: copy,
        });
    }


    return (
        <>
            {
                loading ? <Loading /> :
                    <div className='h-100 w-100'>
                        <div className='d-flex mr-2 justify-content-end' style={{ gap: "1rem" }}>
                            <button className='my-2 rounded deleteBtn' style={{ border: "none", backgroundColor: "white" }}
                                onClick={() => { setIsOpen(true) }}
                            >
                                <div className='px-4 py-2' style={{ color: "#03565a", fontWeight: "600" }}>+ Create New Input</div>
                            </button>

                            {/* <div className='my-3 border rounded' style={{width:"fit-content", cursor: "pointer"}}>
                                <div className='px-4 py-2' style={{color:"#03565a", fontWeight:"600"}}>Export Working</div>
                            </div> */}
                        </div>
                        <table>
                            <thead>
                                <tr style={{ backgroundColor: "#A2C0C2" }}>
                                    <th>Ratios</th>
                                    <th>Numerator</th>
                                    <th>Denominator</th>
                                    <th>Current period</th>
                                    <th>Previous period</th>
                                    <th>Variances %</th>
                                    <th>Reason for Variances</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ratioData && ratioData.length > 0 && ratioData.map((ratioEle, i) => (
                                        <tr key={i} style={{ backgroundColor: "white" }}>
                                            <td style={tdStyle}>{ratioEle?.header}</td>
                                            <td style={tdStyle}>{ratioEle?.numerator}</td>
                                            <td style={tdStyle}>{ratioEle?.denominator}</td>
                                            <td style={{ backgroundColor: "#FFF9D4", textAlign: "left", paddingLeft: "1rem", width: "10%" }}>{
                                                [0, 1, 3, 4, 5, 6, 7, 8, 9].includes(i) ? ratioEle?.cp && ConvertDecimal(ratioEle?.cp, 3) :
                                                    <input
                                                        type='number'
                                                        style={inputDiv}
                                                        value={ratioEle?.cp}
                                                        onChange={(e) => HandleChange(e.target.value, i, "cp")}
                                                    />
                                            }</td>
                                            <td style={{ backgroundColor: "#FFF9D4", textAlign: "left", paddingLeft: "1rem", width: "10%" }}>{
                                                [0, 1, 3, 4, 5, 6, 7, 8, 9].includes(i) ? ratioEle?.pp && ConvertDecimal(ratioEle?.pp, 3) :
                                                    <input
                                                        type='number'
                                                        style={inputDiv}
                                                        value={ratioEle?.pp}
                                                        onChange={(e) => HandleChange(e.target.value, i, "pp")}
                                                    />
                                            }</td>
                                            <td style={{ backgroundColor: "#FFF9D4", textAlign: "left", paddingLeft: "1rem", width: "5%" }}>{
                                                // ratioEle?.variances && ConvertDecimal(ratioEle?.variances, 3)
                                                ratioEle?.variances && ConvertDecimal(Variances(ConvertDecimal(ratioEle?.cp, 3), ConvertDecimal(ratioEle?.pp, 3)), 3)
                                            }</td>
                                            <td style={{ backgroundColor: "#E9F0FD", textAlign: "left", width: "21%" }}>
                                                <div className='d-flex' style={{ height: "100%" }}>
                                                    <textarea
                                                        value={ratioEle?.reason_variances}
                                                        style={{ backgroundColor: "#E9F0FD", width: "-webkit-fill-available", border: "none", padding: "0 0.2rem" }}
                                                        onChange={(e) => HandleVarianceChange(e.target.value, i)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
            }

            {
                isOpen && <UpdateRatioModal setIsOpen={setIsOpen} isOpen={isOpen} ratioId={ratioId} setRatioData={setRatioData} />
            }
        </>
    )
}

export default Ratios