const presetType = {
    BC_PRESET_ACTION_START: "BC_PRESET_ACTION_START",
    BC_PRESET_FETCH_DATA: "BC_PRESET_FETCH_DATA",
    BC_PRESET_FETCH_DATA_FAILED: "BC_PRESET_FETCH_DATA_FAILED",
    UPDATE_PRESET_FETCH_DATA: "UPDATE_PRESET_FETCH_DATA",
}

const BC_PRESET_IS = {
    presetID: "",
    isPresetChecked: false,

    tasks: false,
    assignee: false,
    approver: false,
    template: false,
    templateAmount: false,
    FSCPEntries: false,
    FSCPEntriesAmount: false
}

const Initial_state = {
    presets: [],
    loading: null,
    errorMessage: null,
    apiFetched: false,
};

const BookClosurePresetReducer = (state = Initial_state, action) => {
    switch (action.type) {
        case presetType.BC_PRESET_ACTION_START:
            return {
                ...state,
                presets: [],
                loading: true,
                errorMessage: null,
                apiFetched: false,
            }
        case presetType.BC_PRESET_FETCH_DATA:
            return {
                ...state,
                presets: (action.payload ?? []).map(preset => ({
                    ...preset,
                    presetID: "",
                    isPresetChecked: false,
                    tasks: false,
                    assignee: false,
                    approver: false,
                    template: false,
                    templateAmount: false,
                    FSCPEntries: false,
                    FSCPEntriesAmount: false
                })),
                loading: false,
                errorMessage: null,
                apiFetched: true,
            }
        case presetType.BC_PRESET_FETCH_DATA_FAILED:
            return {
                ...state,
                presets: [],
                loading: false,
                errorMessage: action.payload,
                apiFetched: false,
            }
        case presetType.UPDATE_PRESET_FETCH_DATA:
            return {
                ...state,
                presets: action.payload,
            }
        default:
            return state;
    }
};

export default BookClosurePresetReducer;
