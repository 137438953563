import React,{useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {socket} from '../../services/socket/socket'
import {Spinner} from 'react-bootstrap'
import {motion} from 'framer-motion'
import Note1 from './Note1'
import axios from 'axios'
import { Tooltip } from "@mui/material";

const inputDiv={
  height:'100%',
  width:'100%'
}
const input={
  height:'100%',
  width:'100%',
  border:'none',
  textAlign:'end',
  background:'transparent'
}

const level3={
  color:'black',
  fontSize:'18px',
  fontWeight:'500',
  borderTop:'none'

}

const Note22B = (props) => {

  // const str = 'SET_NOTE'
  // const str1 = 'note'
  // const str2 = props.note.toString()
  // const finalStr = (str.concat('', str2))
  // const NoteStr = (str1.concat('', str2))
  const finalStr = props.FinalStr
  const NoteStr = props.NoteStr
  const dispatch = useDispatch();

  const [note, setNote] = useState([])
  const [dummy, setDummy] = useState(props.note4)
  const [nid, setNld] = useState('')
  const [loading, setLoading] = useState(false)
  const [noteOpen, setNoteOpen] = useState(false)

  const [totalAmt, setTotalAmt] = useState({
    opening_cy:0,
    opening_py:0,
    closing_cy:0,
    closing_py:0,
    subtraction_cy:0,
    subtraction_py:0,
    changeInInventories_cy : 0,
    changeInInventories_py : 0,
  })

  // console.log(nid,'note values')
  // const noteNo = 4
  // var myVar = eval(NoteStr);

  const TB_ID = useSelector(initialState => initialState.reducer.tb_id) || localStorage.getItem('tb_id');
//   const Note = useSelector(initialState => initialState.reducerNotes[NoteStr])
  const project_id = localStorage.getItem('project_id');
//   console.log(Note['Opening Balance'],'hey hey hey')
//   console.log(Note['Closing Balance'],'hey hey hey')
//   console.log(Note,'hey hey hey')

  
  useEffect(() => {
    const auth = localStorage.getItem('auth_token')    
    let headers = {
        'x-auth-token' : auth,
        // 'Content-Type' : 'application/json'
    }
    const fd = new FormData()
    setLoading(true)
    fd.append('notes_no',props.note);
    axios.post(`api/v1/notes/gcn/${project_id}/${TB_ID}`,fd,{headers})
    .then(res =>{
      setLoading(false)
    //   setNld(res.data.data.note22)    
      setNote(res.data.fetchNt)
      
    //   dispatch({type: actionTypes[finalStr],payload:res.data.fetchNt.data[0]})              
      
    }).catch(err=>{
      // console.log(err.response)
      // if(err.response.data){
          
          //   alert(err.response.data.error)  
      // }
    })
    return () => {
        // dispatch({type: actionTypes.SET_NOTE4,payload:note})              
    }    
  }, [])
  const HandleInpValue = (i, sub, val, year) => {
    // console.log("input data ==== ",{i:i, sub:sub, val:val, year:year})

    let value = parseFloat(val)
    let copy = {...note}
    
    copy.data[0]['Opening Balance'].map((ele, ind) => {
      if(ind === i){
        if(year === "cy"){
          ele.cy_amt = value
        }
        if(year === "py"){
          ele.py_amt = value
        }
      }
    })

    setNote(copy);
    
    let content = {
      sid:sub.sid ? sub.sid : "",
      data:copy
    }
    socket.emit("notes-auto-save-22", {
      project_id: `${project_id}`,
      tb_id: `${TB_ID}`,
      nlid: `${nid}`,
      note_no : props.note,
      contents: content,
    });
  }

  useEffect(() => {

    if(note.data && note.data.length > 0){
      let copyData = [...note.data];
      // console.log("closing===========", copyData[0]["Closing Balance"])
      let cy_0 = copyData[0]["Closing Balance"].reduce((totalCy, i) => totalCy +  Number(i.cy_amt), 0);
      let py_0 = copyData[0]["Closing Balance"].reduce((totalPy, i) => totalPy +  Number(i.py_amt), 0);

      // console.log("opening===========", copyData[0]["Opening Balance"])
      let cy_1 = copyData[0]["Opening Balance"].reduce((totalCy, i) => totalCy +  Number(i.cy_amt), 0);
      let py_1 = copyData[0]["Opening Balance"].reduce((totalPy, i) => totalPy +  Number(i.py_amt), 0);
      // console.log({cy_0:cy_0,cy_1:cy_1, py_0:py_0,py_1:py_1})

      let subtraction_cy = (Number(cy_1) - Number(cy_0));
      let subtraction_py = (Number(py_1) - Number(py_0));

      setTotalAmt({...totalAmt, 
        closing_cy:cy_0, 
        closing_py:py_0, 
        opening_cy:cy_1, 
        opening_py: py_1, 
        subtraction_cy:subtraction_cy, 
        subtraction_py:subtraction_py,
        changeInInventories_cy:copyData[0].changeInInventories?.cy,
        changeInInventories_py:copyData[0].changeInInventories?.py,
      })
    }
    
  }, [note])
    

    if(note == null | note == [] | loading  ){
      return(
        <div style={{height:'80vh',width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
          <Spinner animation="grow" size="sm" />
        </div>
      )
    }
    return (
        <div>
          <table>
                <thead >
                  <tr style={{backgroundColor:'#A2C0C2'}}>
                  <th style={{width:'40%',height:'90px',textAlign:'left',paddingLeft:'2rem'}}>Particulars</th>
                  <th style={{width:'10%'}}>As at March 31, 2021</th>
                  <th style={{width:'10%'}}>As at March 31, 2020</th>
                  <th style={{width:'25%',textAlign:'left',paddingLeft:'3rem'}}>Guidances</th>
                  </tr>
                </thead>

                <tbody >
                  <tr  style={level3}>
                    <td style={{width:'40%',height:'30px',height:'50px',textAlign:'left',paddingLeft:'2rem',color:'black',fontSize:'1.1rem'}}>Opening Balance</td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem',color:'black',fontSize:'1.1rem'}}></td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem',color:'black',fontSize:'1.1rem'}}></td>
                    <td style={{width:'25%',textAlign:'right',paddingLeft:'3rem'}}></td>
                  </tr>
                
                  {note.data && note.data[0]['Opening Balance'].map((sub,i)=>{
                    return(
                      <tr  style={level3}>
                        <td style={{width:'40%',height:'70px',textAlign:'left',paddingLeft:'2rem'}}>{sub.rm_b}</td>
                        <td style={{width:'10%',textAlign:'end'}}>
                          <input 
                            style={{width:"100%", height:"100%", outline:"none", border:"none", textAlign:"end"}}
                            value={sub.cy_amt}
                            type='number'
                            onChange={(e) => HandleInpValue(i, sub, e.target.value, "cy")}
                          /> 
                        </td>
                        <td style={{width:'10%',textAlign:'end'}}>
                          <input 
                            style={{width:"100%", height:"100%", outline:"none", border:"none", textAlign:"end"}}
                            value={sub.py_amt}
                            type='number'
                            onChange={(e) => HandleInpValue(i, sub, e.target.value, "py")}
                          /> 
                        </td>
                        <td style={{width:'25%',textAlign:'right',paddingLeft:'3rem'}}>
                        </td>
                      </tr>
                    )
                  })}
                  
                  <tr  style={{height:'50px',backgroundColor:' #F0DBC8',padding:'10px 0 0 2rem',alignContent:'center',fontSize:'1.1rem',fontWeight:'600'}}>
                    <td style={{width:'40%',height:'30px',height:'50px',backgroundColor:' #F0DBC8',textAlign:'left',paddingLeft:'2rem',color:'black',fontSize:'1.1rem'}}>Total Opening Balance</td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem',color:'black',fontSize:'1.1rem'}}>{totalAmt.opening_cy ? totalAmt.opening_cy.toLocaleString("en-IN") : 0}</td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem',color:'black',fontSize:'1.1rem'}}>{totalAmt.opening_py ? totalAmt.opening_py.toLocaleString("en-IN") : 0}</td>
                    <td style={{width:'25%',textAlign:'right',paddingLeft:'3rem'}}>
                    </td>
                  </tr>
                
                  <tr  style={level3}>
                    <td style={{width:'40%',height:'30px',textAlign:'left',paddingLeft:'2rem',color:'black',fontSize:'1.1rem'}}>Closing Balance</td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem',color:'black',fontSize:'1.1rem'}}></td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem',color:'black',fontSize:'1.1rem'}}></td>
                    <td style={{width:'25%',textAlign:'right',paddingLeft:'3rem'}}>
                    </td>
                  </tr>
                  {note.data && note.data[0]['Closing Balance'].map((sub,i)=>{                
                    return(
                      
                        <tr  style={level3}>
                        <td style={{width:'40%',height:'70px',textAlign:'left',paddingLeft:'2rem'}}>{sub.rm_b}</td>
                        <td style={{width:'10%',textAlign:'end',paddingRight:'1rem'}}>{sub.cy_amt}</td>
                        <td style={{width:'10%',textAlign:'end',paddingRight:'1rem'}}>{sub.py_amt}</td>
                        <td style={{width:'25%',textAlign:'right',paddingLeft:'3rem'}}>
                        </td>
                        </tr>
                    
                    )
                  })}
                
                  <tr style={{height:'50px',backgroundColor:' #F0DBC8',padding:'10px 0 0 2rem',alignContent:'center',fontSize:'1.1rem',fontWeight:'600'}}>
                    <td style={{width:'40%',height:'30px',backgroundColor:' #F0DBC8',textAlign:'left',paddingLeft:'2rem',color:'black',fontSize:'1.1rem'}}>Total Closing Balance</td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem',color:'black',fontSize:'1.1rem'}}>{totalAmt.closing_cy ? totalAmt.closing_cy.toLocaleString("en-IN") : 0}</td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem',color:'black',fontSize:'1.1rem'}}>{totalAmt.closing_py ? totalAmt.closing_py.toLocaleString("en-IN") : 0}</td>
                    <td style={{width:'25%',textAlign:'right',paddingLeft:'3rem'}}>
                    
                    </td>
                  </tr>
                
                  <tr  style={level3}>
                    <td style={{width:'40%',height:'70px',textAlign:'left',paddingLeft:'2rem'}}>Total changes in inventories of work-in-progress, stock-in-trade and finished goods</td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem'}}>
                      { totalAmt.subtraction_cy ? totalAmt.subtraction_cy.toLocaleString("en-IN") : 0 }
                    <span style={{marginLeft:"0.6rem"}}>
                      {
                        totalAmt?.subtraction_cy.toLocaleString("en-IN") != totalAmt.changeInInventories_cy.toLocaleString("en-IN") &&
                        (
                          <Tooltip title="The total value is not matching with 3rd level template value." placement="top">
                            <i
                              style={{
                                cursor:"pointer",
                                color: "darkorange",
                                transform: "scale(1.2)",
                              }}
                              className="fas fa-info-circle"
                            />
                          </Tooltip>
                        )
                      }
                    </span>

                    </td>
                    <td style={{width:'10%',textAlign:'end',paddingRight:'1rem'}}>{totalAmt.subtraction_py ? totalAmt.subtraction_py.toLocaleString("en-IN") : 0}
                      <span style={{marginLeft:"0.6rem"}}>
                        {
                          totalAmt?.subtraction_py.toLocaleString("en-IN") != totalAmt.changeInInventories_py.toLocaleString("en-IN") &&
                          (
                            <Tooltip title="The total value is not matching with 3rd level template value." placement="top">
                              <i
                                style={{
                                  cursor:"pointer",
                                  color: "darkorange",
                                  transform: "scale(1.2)",
                                }}
                                className="fas fa-info-circle"
                              />
                            </Tooltip>
                          )
                        }
                      </span>
                    </td>
                    <td style={{width:'25%',textAlign:'right',paddingLeft:'3rem'}}>
                      
                    </td>
                  </tr>
                </tbody>
          </table>

          <div style={{display:'flex',marginTop:'1rem',height:'50px',border:'2px solid #e8e8e8'}}>
            <div style={{ display:'flex',alignItems:'center',justifyContent:'center',paddingLeft:'1rem'}}><p>Note here</p></div>
            <div onClick={(e)=>{setNoteOpen(!noteOpen)}} style={{display:'flex',alignItems:'center',justifyContent:'center',width:'3rem',background:'#e8e8e8',marginLeft:'auto',marginRight:'2rem',cursor:'pointer'}}>
            <i className="fas fa-pencil-alt" />              {/* <img src/> */}

            </div>

          </div>
          {noteOpen && <motion.div style={{marginBottom:'1rem',padding:'0rem',backgroundColor:'white',border:'2px solid #e8e8e8',borderTop:'none'}}>
            {/* <h1>note 3  </h1> */}
            <Note1 color='grey' />
          </motion.div>}
          {/* <div >
          Net Carrying Amount</div> */}
            
        </div>
    )
}

export default Note22B
