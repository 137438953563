import LeaseSidebar2 from "./LeaseSidebar2";
import "../../../WorkSpace/font.css";
import "../Lease/leasesidebar.css";
import "../../../../src/WorkSpace/font.css";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const CreateLease = () => {
  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <LeaseSidebar2 />
          </div>
          <div className="d-flex flex-column w-100">
            <div className="px-5 mt-5" style={{ maxWidth: "60rem" }}>
              <p className="fs-inter createstep">STEP 1 OF 4</p>
              <h4 className="fs-inter createhead">
                Enter your lease details here
              </h4>
              <p className="dm-sans create-para">
                Why for this needs to be mentioned
              </p>
              <div className="row">
                <div className="col-md-12">
                  <p for="lease" className="dm-sans create-inp-lab ">
                    Lease ID <span className="text-danger">*</span>
                  </p>

                  <input
                    type="text"
                    style={{ marginTop: "-10px", minWidth: "876px" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p className="dm-sans create-inp-lab">Name of lessee</p>

                  <input
                    type="text"
                    style={{ marginTop: "-10px", minWidth: "426px" }}
                  />
                </div>
                <div className="col-md-6">
                  <p className="dm-sans create-inp-lab">
                    Select asset category
                  </p>

                  {/* <input
                  type="text"
                  style={{ width: "380px", marginLeft: "-6px" }}
                /> */}
                  <select className="createsel">
                    <option></option>
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p className="dm-sans create-inp-lab">
                    MyFinalyst ID <span className="text-danger">*</span>
                  </p>

                  <input
                    type="text"
                    style={{ minWidth: "876px", marginTop: "-10px" }}
                  />
                </div>
              </div>
              {/* <div className="d-flex justify-content-between mt-5">
              <div style={{ cursor: "pointer" }}>
                <ArrowBackIcon style={{ color: "#03565A" }} />
                &nbsp; &nbsp;
                <span style={{ color: "#03565A", fontWeight: "bold" }}>
                  Back to the previous step
                </span>
              </div>
              <div>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#03565A",
                    textTransform: "none",
                    width: "200px",
                    border: "2px solid #03565A",
                  }}
                >
                  Go to the next step
                </Button>
              </div>
            </div> */}
            </div>
            <div className="d-flex justify-content-between createbtn px-5">
              <div style={{ cursor: "pointer" }}>
                <ArrowBackIcon style={{ color: "#03565A" }} />
                &nbsp; &nbsp;
                <span style={{ color: "#03565A", fontWeight: "bold" }}>
                  Back to the previous step
                </span>
              </div>
              <div>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#03565A",
                    textTransform: "none",
                    width: "200px",
                    border: "2px solid #03565A",
                  }}
                >
                  Continue to next step
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateLease;
