import React from 'react'
import Card from '@mui/material/Card';
import "./finalisefs.css"



const Preview = () => {
    return (
        <>
            <div style={{ maxHeight: '40rem', overflowY: 'auto',borderRadius:"none" }}>
                <div className='px-3 py-3 mt-2'>
                    <h4 className='font-weight-bold font-size-18'>Company Name</h4>
                    <h6 className='custom-font-500 font-size-16'>Standalone Balance Sheet as at XXXX</h6>
                    <h6 className='mt-3 custom-font-500 font-size-12'>(All amount are in INR Crores otherwise stated)</h6>

                </div>
                <Card  style={{borderBottomLeftRadius:"15px",borderBottomRightRadius:"15px"}}>
                    <div className='px-0'>
                        <table>
                            <thead>
                                <tr>
                                    <th className="w-50 font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 lease-table font-sans text-secondary custom-common-secondary-bg">

                                    </th>
                                    <th className=" font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 lease-table font-sans text-secondary custom-common-secondary-bg" style={{ width: "16.66%" }}>
                                        Note
                                    </th>
                                    <th className=" font-size-14 text-uppercase custom-font-600 border-top-0 border-bottom-0 lease-table font-sans text-secondary custom-common-secondary-bg " style={{ width: "16.66%" }}>
                                        31 March 2021
                                    </th>
                                    <th className=" border-top-0 border-bottom-0 lease-table font-sans text-uppercase text-secondary custom-common-secondary-bg" style={{ width: "16.66%" }}>
                                        31 March 2022
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-left bg-white">
                                <tr>
                                    <td className='border-top-0 border-bottom-0 px-3 ' style={{ textAlign: 'start' }}>
                                        <h6 className='font-weight-bold font-size-18 '>Assets</h6>
                                    </td>
                                    <td className="border-top-0 border-bottom-0"></td>
                                    <td className="border-top-0 border-bottom-0">sss</td>
                                    <td className="border-top-0 border-bottom-0">sssss</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 coustom-textalign px-3'>Property Plant & Equipment</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 coustom-textalign px-3'>Capital Work-In Progress</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 coustom-textalign px-3'>Investment Properties</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 coustom-textalign px-3'>Goodwill</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 coustom-textalign px-3'>Goodwill</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 coustom-textalign px-3'>Goodwill</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr className='coustom-color'>
                                    <td className='border-top-0 coustom-color'></td>
                                    <td className='border-top-0 coustom-color font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0  coustom-color font-size-16 custom-font-500 coustom-blucolor'>0</td>
                                    <td className="border-top-0 coustom-color font-size-16 custom-font-500 coustom-blucolor">0</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0'>
                                        <h6 className='font-weight-bold font-size-18 coustom-textalign px-3 '>Assets</h6>
                                    </td>
                                    <td className="border-top-0 border-bottom-0"></td>
                                    <td className="border-top-0 border-bottom-0">sss</td>
                                    <td className="border-top-0 border-bottom-0">sssss</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 font-size-18 coustom-textalign px-3'>Property Plant & Equipment</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 font-size-18 coustom-textalign px-3'>Capital Work-In Progress</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 font-size-18 coustom-textalign px-3'>Investment Properties</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 font-size-18 coustom-textalign px-3'>Goodwill</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 font-size-18 coustom-textalign px-3'>Goodwill</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 border-bottom-0 font-size-18 coustom-textalign px-3'>Goodwill</td>
                                    <td className='border-top-0 border-bottom-0 font-size-16 custom-font-500 coustom-blucolor'>2</td>
                                    <td className='border-top-0 border-bottom-0'></td>
                                    <td className="border-top-0 border-bottom-0">hshshs</td>
                                </tr>
                            </tbody>
                        </table>




                    </div>
                </Card>

            </div>

        </>
    )
}

export default Preview