import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import '../../../Components/Notes/notes.css'
import GeneralEditor from '../../../Components/GeneralNotes/GeneralEditor';
import swal from 'sweetalert';
import useFetchApi from '../../../services/CustomHook/useFetchApi';
import { postAPI } from '../../../services/api/StandAlone/generalNote';
import LoaderTwo from '../../../Components/Common/Loader/LoaderTwo';
import { socket } from '../../../services/socket/socket';
import GeneralEditorNote2 from '../../../Components/GeneralNotes/GeneralEditorNote2';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '50vh',


    },
    tabs: {
        maxHeight: '100vh',
        borderRight: `1px solid ${theme.palette.divider}`,
        paddingTop: '3rem',
        paddingBottom: '3rem',
        background: 'linear-gradient(45deg, transparent, #E5EEEE)',

        color: 'black',
        fontSize: '2rem',
        width: '260px',
    },
    tab: {
        fontSize: '1rem',
        padding: '12px 12px',
        overflow: 'hidden',
        position: 'relative',
        fontSize: '1rem',
        // maxWidth: '300px',
        // width: '220px',
        boxSizing: 'border-box',
        minHeight: '55px',
        textAlign: 'center',
        flexShrink: 0,
        fontWeight: "500",
        lineHeight: '1.75',
        letterSpacing: '0.02857em',
        textTransform: 'none',
    },
    TabPanel: {
        width: '100%'
    },
}));

// const data1 = []

export default function PopulateFsPnlNotes({ setNoteNumber }) {
    const classes = useStyles();
    const project_id = localStorage.getItem('project_id');
    const { loading, response, error } = useFetchApi(`api/v1/general-notes/${project_id}`);
    const [value, setValue] = React.useState(0);
    const [totalNotes, setTotalNotes] = useState([]);
    const [note2, setNote2] = useState([]);
    const [createNodeLoader, setCreateNodeLoader] = useState(false);

    useEffect(() => {
        if (response && Object.keys(response).length > 0) {
            setTotalNotes(response?.data?.data?.data);
            setNote2(response?.data?.data?.noteOne);
        }
        if (error) {
            // swal("Error", error, "error");
        }
    }, [response || error])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const  HandelAdd = () => {
        let number = totalNotes.length + 1
        let data = {
            msg:`Note ${number}`
        }
        setCreateNodeLoader(true);
        postAPI(`api/v1/general-notes/${project_id}`, data).then(res => {
            console.log("create response ---", res)
            setCreateNodeLoader(false);
            setTotalNotes(res?.data?.data?.data);
        }).catch(err => {
            console.log("create response ---", err);
            setCreateNodeLoader(false);
            let errorData = err.response?.data?.message ? err.response?.data?.message : "Error happing at the time of calculation, please try after some time.";
            // swal("Error", errorData, "error");
        })
    }

    const HandleEditorChange = (data, index, dataTwo) => {
        let copy = [...totalNotes]
        copy.forEach((ele, i) => {
            if(i === index){
                if(i === 1){
                    ele.message = data
                    ele.disclosure = dataTwo
                    return
                }
                ele.message = data
            }
        })
        
        setTotalNotes(copy);

        socket.emit("save-general-note", {
            project_id: `${project_id}`,
            contents: copy,
            contentType:"general_note",
        });
    }

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                {totalNotes && totalNotes.length > 0 && totalNotes.map((note, ix) => {
                    const Name = `Note ${ix + 1}`
                    return (
                        <Tab key={ix} className={classes.tab} style={{color:"black", fontWeight:"600"}} label={Name} {...a11yProps(ix)} />
                    )
                })}
                <div style={{ height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {!createNodeLoader ? <p style={{ color: 'var(--clr-accent)', cursor: 'pointer', fontWeight: '600', fontSize: '16px' }} onClick={() => { HandelAdd() }}>
                        <span>+ </span>New Notes
                    </p> : 
                    <div>
                        <LoaderTwo />
                    </div>}
                </div>

            </Tabs>
            {totalNotes && totalNotes.length > 0 && totalNotes.map((note, ix) => {
                const Name = `Note ${ix + 1}`
                return (
                    <TabPanel key={ix} className={classes.TabPanel} value={value} index={ix}>
                        {
                            ix === 1 ? 
                            <GeneralEditorNote2
                                value={note?.message} 
                                value2={note?.disclosure}
                                HandleEditorChange={HandleEditorChange}
                                ind={ix}
                                note2={note2}
                                setNote2={setNote2}
                            /> : 
                            <GeneralEditor
                                text={{
                                    text1: "Corporate information",
                                    text2: "Ind AS 1.10(e) Ind AS 1.49 Ind AS 1.113 Ind AS 1.51(a) Ind AS 1.51(b) Ind AS 1.51(c) Ind 1.138(a) Ind AS1.138(b) Ind AS 1.138(C )"
                                }}
                                value={note.message}
                                onChange={(data) => {
                                    HandleEditorChange(data, ix, "");
                                }}
                            />
                        }
                    </TabPanel>
                )
            })}
        </div>
    );
}