import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';



export function NBFC_45_46({ data, HandleChange, subIndex, dis_index_id }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});

    return (
        <>
            <thead>
                <tr>
                    <td className='w-auto border-top-0 border-bottom-0'> Sr. No.</td>
                    <td className='text-left w-75 pl-3 border-top-0 border-bottom-0'>Particulars</td>
                    <td className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}> As at {""}{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</td>
                    <td className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>As at {""} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</td>
                    <DisclosureNBFCTableHead isEmpty={false} />
                </tr>
            </thead>

            <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            <tr>
                                <td className='border-top-0 border-bottom-0'>
                                    {subDis?.sr_no}
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pl-3 text-left'
                                        type={"text"}
                                        value={subDis?.header}
                                        name="header"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.cy !== "number"}
                                        value={formatIndianCurrency(subDis?.cy)}
                                        name="cy"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{ backgroundColor: "inherit" }}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.py !== "number"}
                                        value={formatIndianCurrency(subDis?.py)}
                                        name="py"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                     isReference={subDis?.isReference}  keyValue={20} index={subIndex}  sub_index={subDisIndex}/>
                            </tr>
                        </>
                    ))
                }
            </tbody>
        </>
    );
}