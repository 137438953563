import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Navbar } from '../../Components/Navbar'
import { GetSubscriptionPlansDetails } from '../../WorkSpace/Components/ApiServices/apiHandler'
import { Button, Typography } from '@mui/material';
import moment from 'moment';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PaymentCard from '../../WorkSpace/Components/paymentcard/PaymentCard';
import { Checkbox } from '../../Components/Table/Checkbox';
import axios from 'axios';
import { getProfile } from '../../services/api/apiHandler';

const SubscriptionMenuDetails = ({ match }) => {
  const details = {
    firstname: "",
    lastname: "",
    address: "",
    aprtment: "",
    city: "",
    country: "",
  }

  


  const [billingDetails, setBillingDetails] = useState(details);
  const [shippingDetails, setShippingDetails] = useState(details);
  const [sameAddress, setSameAddress] = useState(false);
  const [custId,setcustId] = useState('')

  const [orderId, setOrderId] = useState('');
  const [amount, setAmount] = useState(0);


  const handleCheckboxChange = () => {
    setSameAddress(!sameAddress); // Toggle checkbox state

    // If the checkbox is checked, copy billing details to shipping details
    if (!sameAddress) {
      setShippingDetails(billingDetails);
    } else {
      // If the checkbox is unchecked, reset shipping details to an empty object
      setShippingDetails(details);
    }
  };

  const saveCustomarDetails = (e) => {
    // e.preventDefault();
    console.log(billingDetails, 'customerdetails--------------');
    console.log(shippingDetails, "shippingDetails----------")


  }



  const history = useHistory();
  const { id } = match.params;
  console.log(match, '---------------')
  const [plan, setPlan] = useState({})

  useEffect(() => {
    // Fetch subscription plan details using the ID
    fetchSubscriptionPlanDetails();
    customerId();
  }, []);

  const customerId = async()=>{
    const res = await getProfile();
    console.log(res,'id---------------<<<')
    if(res.status === 200){
      console.log(res.data.user.data._id)
         setcustId(res.data.user.data._id)
    }
    else{
      console.log('id not found');
    }
  }

  const generateOrderId = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let orderIds = '';
    for (let i = 0; i < 10; i++) {
      orderIds += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return orderIds;
  };
  // Assume you have a function to handle the failed payment
const handleFailedPayment = async ( orderId) => {
  try {
      // Make a POST request to your /api/v1/payment/paymentFailed endpoint
      const response = await axios.post('/api/v1/payment/api/paymentFailed', {
          error:"payment cancel by User",
          razorpay_order_id: orderId
      });
      console.log('Failed payment handled successfully:', response.data);
  } catch (error) {
      console.error('Error handling failed payment:', error);
  }
};


  const handlePaymentVerification = async (response) => {
    try {
        const verificationResponse = await axios.post('/api/v1/payment/paymentverification', {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature
        });

        if (verificationResponse.data.success) {
            console.log('Payment verification successful');
            history.push('/subscribed');
        } else {
            console.error('Payment verification failed');

            // Handle payment verification failure if needed
        }
    } catch (error) {
        console.error('Error calling payment verification API:', error);
        // Redirect to an error page or display an error message
    }
};


  const fetchSubscriptionPlanDetails = async () => {
    const res = await GetSubscriptionPlansDetails(id);
    console.log(res, "res>>>>>>>>>>>>>>>");
    setPlan(res.data.data);
  };
  const urlPath = window.location.pathname;
  const pathParts = urlPath.split('/');
  const planid = pathParts[pathParts.length - 1];
  console.log(planid,'planId))))))))))))))')

  const calculateSubscriptionEndDate = (startDate, period) => {
    const date = new Date(startDate);
    switch (period) {
      case 'monthly':
        date.setMonth(date.getMonth() + 1);
        break;
      case 'quarterly':
        date.setMonth(date.getMonth() + 3);
        break;
      case 'yearly':
        date.setFullYear(date.getFullYear() + 1);
        break;
      default:
        break;
    }
    return date;
  };

   

  const checkoutHandler = async (amount)=>{
    const bodyData = {
        plan_price: amount,
        customer_id: custId,
        subscription_start_date: new Date(),
        subscription_end_date: calculateSubscriptionEndDate(new Date(),plan.planPeriod) ,
        plan_id: planid,
        users:plan.users,
        plan_name:plan.planName,
        categorizedBenefits:plan.categorizedBenefits, 
        benefits:plan.benefits,
        billingDetails,
        shippingDetails,
        receipt:generateOrderId(),
    };

    console.log(bodyData,"bodyData->>>>")
    

    try {
      const response = await axios.post('/api/v1/payment/createorder',bodyData);
      console.log(response);
      setOrderId(response.data.id);
      setAmount(response.data.amount);

      // Open Razorpay modal
      openRazorpayModal(response.data.id);
    } catch (error) {
      console.error('Failed to create order:', error);
    }
  };

  const openRazorpayModal = async(orderId) => {
    if (!orderId) {
      console.error('Order ID is missing.');
      return;
    }

    const options = {
      key: 'rzp_test_v4SoyN8HA9tubE', // Replace with your Razorpay key
      amount:amount, // Amount in paise
      currency: 'INR',
      name: 'MyFinalyst',
      description: 'Test payment',
      order_id: orderId,
      handler: async function (response) {
        console.log('Payment ID:', response.razorpay_payment_id);
        console.log('Signature:', response.razorpay_signature);
        console.log(response,'response><><>')
        // Handle payment success
        if (response.razorpay_payment_id) {
         await handlePaymentVerification(response);
        } else {
          // Payment failed
          alert('Payment failed. Please try again.');
        }
      },
      prefill: {
        name:billingDetails.firstname,
        email: 'biju1998@gmail.com',
      },
      theme: {
        color: '#3399cc',
      },
      modal: {
        ondismiss: function() {
            console.log('Payment cancelled by the user.');

            // Call the function to handle the cancellation of payment
            handleFailedPayment(orderId);
        }
    }
    };
    console.log(options);
    //return false;
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  
   



  return (
    <>
      <Navbar />
      <div>
        <div className='mt-1 text-center' style={{}}>
          <div style={{ maxWidth: '500px', margin: '20px auto', maxHeight: '400px', background: "linear-gradient(-45deg,#f403d1,#64b5f6)", borderRadius: '15px' }}>
            <div className='p-3'>
              <div><i class="fa fa-plane" aria-hidden="true" style={{ fontSize: '70px', transform: 'rotate(315deg)', padding: '10px', borderRadius: '20px', background: 'linear-gradient(-45deg,#f403d1,#64b5f6)', marginTop: '10px' }} ></i></div>
              <div className='d-flex justify-content-between'>
                <Typography sx={{ fontSize: '20px' }}><span style={{ color: 'wheat' }} >Plan Name</span><br></br>{plan.planName}</Typography>
                <Typography sx={{ fontSize: '20px' }}><span style={{ color: 'wheat' }} >Plan Price</span><br></br><sup style={{ fontSize: '20px' }}>$</sup>{plan.planPrice}</Typography>
              </div>
              <Typography sx={{ fontSize: '20px' }}><span style={{ color: 'wheat' }}>Features</span>
                <ul>
                  <li> <i class="fa fa-check" aria-hidden="true"></i>{plan.planDescription} </li>
                </ul>
              </Typography>
              <div className='d-flex justify-content-between'>
                <Typography sx={{ fontSize: '20px' }}><span style={{ color: 'wheat' }}>Start date</span><br></br>{moment().format('DD/MM/YYYY')}</Typography>
                <Typography sx={{ fontSize: '20px' }}><span style={{ color: 'wheat' }}>End date</span><br></br>{moment(calculateSubscriptionEndDate(new Date(), plan.planPeriod)).format('DD/MM/YYYY')}</Typography>
              </div>

            </div>

          </div>
        </div>
        <form onSubmit={() => saveCustomarDetails()}>
          <div className='p-2 d-flex justify-content-around'>
            <div>
              <h4>Billing Address</h4>
              <div className=''>
                <div>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField id="outlined-basic" label="First Name" variant="outlined" value={billingDetails.firstname} onChange={(e) => setBillingDetails({ ...billingDetails, firstname: e.target.value })} />
                    <TextField id="outlined-basic" label="Last Name" variant="outlined" value={billingDetails.lastname} onChange={(e) => setBillingDetails({ ...billingDetails, lastname: e.target.value })} />
                  </Box>

                </div>
                <div>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField id="outlined-basic" label="Address" variant="outlined" value={billingDetails.address} onChange={(e) => setBillingDetails({ ...billingDetails, address: e.target.value })} />
                    <TextField id="outlined-basic" label="Apt.,colony,etc(optional)" variant="outlined" value={billingDetails.aprtment} onChange={(e) => setBillingDetails({ ...billingDetails, aprtment: e.target.value })} />
                  </Box>

                </div>
                <div>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField id="outlined-basic" label="City" variant="outlined" value={billingDetails.city} onChange={(e) => setBillingDetails({ ...billingDetails, city: e.target.value })} />
                    <TextField id="outlined-basic" label="Country" variant="outlined" value={billingDetails.country} onChange={(e) => setBillingDetails({ ...billingDetails, country: e.target.value })} />
                  </Box>


                </div>
                <input type="checkbox" id="sameAddress" checked={sameAddress} onChange={handleCheckboxChange}/>
                <label htmlFor="sameAddress">My shipping address is the same as my billing address</label>

              </div>


            </div>
            <div>
              <h4>Shipping Address</h4>
              <div className=''>
                <div>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField id="outlined-basic" label="First Name" variant="outlined" value={shippingDetails.firstname} onChange={(e) => setShippingDetails({ ...shippingDetails, firstname: e.target.value })} />
                    <TextField id="outlined-basic" label="Last Name" variant="outlined" value={shippingDetails.lastname} onChange={(e) => setShippingDetails({ ...shippingDetails, lastname: e.target.value })} />
                  </Box>

                </div>
                <div>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField id="outlined-basic" label="Address" variant="outlined" value={shippingDetails.address} onChange={(e) => setShippingDetails({ ...shippingDetails, address: e.target.value })} />
                    <TextField id="outlined-basic" label="Apt.,colony,etc(optional)" variant="outlined" value={shippingDetails.aprtment} onChange={(e) => setShippingDetails({ ...shippingDetails, aprtment: e.target.value })} />
                  </Box>

                </div>
                <div>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField id="outlined-basic" label="City" variant="outlined" value={shippingDetails.city} onChange={(e) => setShippingDetails({ ...shippingDetails, city: e.target.value })} />
                    <TextField id="outlined-basic" label="Country" variant="outlined" value={shippingDetails.country} onChange={(e) => setShippingDetails({ ...shippingDetails, country: e.target.value })} />
                  </Box>


                </div>
              </div>


            </div>
          </div>

        </form>
        <div className=' mb-3 text-center' >
          <button style={{ backgroundColor: 'red', color: 'white', borderRadius: '5px', padding: '10px 30px', border: 'none' }} onClick={() => checkoutHandler(plan.planPrice)}>Subscribe Now</button>
        </div>
      </div>

    </>
  )
}



export default SubscriptionMenuDetails