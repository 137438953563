export const groupingColumn = [

    {
        Header: 'LEDGER CODE',
        accessor : 'ledger_code'
    },
    {
        Header: 'LEDGER NAME',
        accessor : 'ledger_name'
    },
    {
        Header: 'CURRENT YEAR',
        accessor : 'cy'
    },
    {
        Header: 'ADJUSTED AMT',
        accessor : 'adjusted_amount'
    },
    {
        Header: 'FINAL AMT',
        accessor : ''
    },
    {
        Header: 'PREVIOUS YEAR',
        accessor : 'py'
    },
    {
        Header: 'FS GROUPING (CY)',
        accessor : 'fs_grp_cy',
        maxWidth: 400,
        minWidth: 140,
        width: 200,  
    },
    {
        Header: 'NOTES GROUPING (CY)',
        accessor : 'note_grp_cy',
        maxWidth: 400,
        minWidth: 140,
        width: 200, 
    },
    {
        Header: 'SUB GROUPING (CY)',
        accessor : 'sub_grp_cy',
        maxWidth: 400,
        minWidth: 140,
        width: 200, 
    },
    {
        Header: 'FS GROUPING (PY)',
        accessor : 'fs_grp_py',
        maxWidth: 400,
        minWidth: 140,
        width: 200,  
    },
    {
        Header: 'NOTES GROUPING (PY)',
        accessor : 'note_grp_py',
        maxWidth: 400,
        minWidth: 140,
        width: 200, 
    },
    {
        Header: 'SUB GROUPING (PY)',
        accessor : 'sub_grp_py',
        maxWidth: 400,
        minWidth: 140,
        width: 200, 
    }
]