const WorkspacePrepareconsolTypes = {
    WorkspacePrepareconsol_ACTION_START:"WorkspacePrepareconsol_ACTION_START",
    WorkspacePrepareconsol_FETCH_DATA:"WorkspacePrepareconsol_FETCH_DATA",
    WorkspacePrepareconsol_FETCH_DATA_FAILED:"WorkspacePrepareconsol_FETCH_DATA_FAILED",
    WorkspacePrepareconsol_ADD_DATA:"WorkspacePrepareconsol_ADD_DATA",
    WorkspacePrepareconsol_SOCKET_UPDATE:"WorkspacePrepareconsol_SOCKET_UPDATE",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
    set_payload: ""
}

const WorkspacePrepareconsolReducer = (state=Initial_state, action) => {
    switch(action.type){
        case WorkspacePrepareconsolTypes.WorkspacePrepareconsol_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case WorkspacePrepareconsolTypes.WorkspacePrepareconsol_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                set_payload: action.payload.set_payload
            }

        case WorkspacePrepareconsolTypes.WorkspacePrepareconsol_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        case WorkspacePrepareconsolTypes.WorkspacePrepareconsol_SOCKET_UPDATE:
            const { data, set_payload } = action.payload;
            if(state.set_payload !== set_payload){
                return {
                    ...state
                }
            }
            return {
                ...state,
                response: {
                    ...state.response,
                    getOnePreCompany: data
                }
            }
        default:
            return state;
    }
};

export default WorkspacePrepareconsolReducer;