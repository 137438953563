import { Navbar } from "../../../../src/Components/Navbar";
// import LeaseSidebar from "./LeaseSidebar";
import "../../../WorkSpace/font.css";
import "../Lease/leasesidebar.css";
import LeaseSidebar from "../Lease/LeaseSidebar";

const AccountEntries = () => {
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="d-flex">
        <div>
          <LeaseSidebar />
        </div>

        <div className="px-4 w-100">
          <p className="dm-sans accounthead">Account entries</p>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="fs-inter ac-t-head"
                >
                  CATEGORY
                </th>
                <th
                  scope="col"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="fs-inter ac-t-head"
                >
                  Description
                </th>
                <th
                  scope="col"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="fs-inter ac-t-head"
                >
                  AMOUNT (DR)
                </th>
                <th
                  scope="col"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="fs-inter ac-t-head"
                >
                  AMOUNT (CR)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="dm-sans ac-t-content"
                >
                  Lease additions
                </th>
                <td className="dm-sans ac-t-content">ROU asset</td>
                <td className="dm-sans ac-t-content"></td>
                <td className="dm-sans ac-t-content"></td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="dm-sans ac-t-content"
                ></th>
                <td className="dm-sans ac-t-content">Lease liability</td>
                <td className="dm-sans ac-t-content"></td>
                <td className="dm-sans ac-t-content"></td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="dm-sans ac-t-content"
                >
                  Security deposit
                </th>
                <td className="dm-sans ac-t-content">Security deposit (Dr)</td>
                <td className="dm-sans ac-t-content"></td>
                <td className="dm-sans ac-t-content"></td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="dm-sans ac-t-content"
                ></th>
                <td className="dm-sans ac-t-content">ROU asset (Dr)</td>
                <td className="dm-sans ac-t-content"></td>
                <td className="dm-sans ac-t-content"></td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="dm-sans ac-t-content"
                ></th>
                <td className="dm-sans ac-t-content">To bank</td>
                <td className="dm-sans ac-t-content"></td>
                <td className="dm-sans ac-t-content"></td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="dm-sans ac-t-content"
                >
                  Depreciation charges
                </th>
                <td className="dm-sans ac-t-content">
                  Depreciation charges (Dr)
                </td>
                <td className="dm-sans ac-t-content"></td>
                <td className="dm-sans ac-t-content"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default AccountEntries;
