
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import moment from 'moment';

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1050,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow:'scroll',
    height:'100%'
};
const InvestAdjustModal = (props) => {
    const { entryopen,eqityshare,investvalue,handleClose,totaleqity,interest,setfinalsum,setFinalConsoleEntry,otherequityshare,otherreservesurplus,othereserve ,setEntryOpen,dateofinvest} = props;

    const handleEntrySave=()=>{
        let dataentry ={
            non_controlling_interest: Number(setInterest(eqityshare) * -(interest/100)).toFixed(2),
            investment: Number(investvalue*-1),
            otherinputs: Number(setfinalsum),
            goodwill: Number(setEquity(eqityshare,totalAmount) * -1).toFixed(2)
        }
        setFinalConsoleEntry(dataentry);
        setEntryOpen(false);
      }


      

      const setInterest = (shareData)=>{
        try {
            let res = shareData.map((data,i)=>(data.sub_grp.map((ele,i)=>ele.cy_amt)));
            return (res[0].reduce((data, acc) => data + acc, 0) + res[1].reduce((data, acc) => data + acc, 0) + res[2].reduce((data, acc) => data + acc, 0) +res[3].reduce((data, acc) => data + acc, 0)) * -1;

        } catch (error) {
            return error
        }
      }
    let totalAmount = totaleqity +(otherequityshare * -1)+(otherreservesurplus * -1)+(othereserve * -1)+(investvalue * -1)+(setfinalsum * 1) + (setInterest(eqityshare) * -(interest/100));
    const setEquity =(shareData,totaldata)=>{
        try {
            let res = shareData.map((data,i)=>(data.sub_grp.map((ele,i)=>ele.cy_amt)))
            return res[0].reduce((data, acc) => data + acc, 0) + res[1].reduce((data, acc) => data + acc, 0) + res[2].reduce((data, acc) => data + acc, 0) +res[3].reduce((data, acc) => data + acc, 0) + totaldata;
        } catch (error) {
            return error
        }
      }
    return (
        <>
             <Modal
                open={entryopen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <div className="col-12">
                        <button type="button" onClick={handleClose} class="btn-close float-end"></button>
                        <div class="table-responsive table-bordered">
                            <table class="table">
                                <thead>
                                    <tr style={{textAlign:"center"}}>
                                    <strong>  {moment(dateofinvest).format(
                                      "MMMM DD, YYYY"
                                    )}</strong>
                                    </tr>
                                    <tr>
                                        <td>Particulars</td>
                                        {/* <td>Calculation</td>
                                        <td>Calculation</td> */}
                                        <td>Amount</td>
                                    </tr>
                                </thead>
                                <tbody>

                                {eqityshare.slice(0,-1).map((data,i)=>{
                                    return(
                                       
                                        <>
                                            {data.sub_grp.map((ele,i)=>{
                                                return(
                                                    <>
                                                        <tr>
                                                            <td style={{textAlign:"left"}}><strong>{ele.sub_grp}</strong></td>
                                                            {/* <td>{ele.cy_amt* -1}</td>
                                                            <td>* -1</td> */}
                                                            <td>{ele.cy_amt * -1}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </>
                                        
                                    )
                                })

                                }
                                <tr>
                                        {/* <td>{dateofinvest}</td> */}
                                        <td style={{textAlign:"left"}}><strong>Non-controlling interest</strong></td>
                                        {/* <td>{setInterest(eqityshare)}</td>
                                        <td>* {-(interest / 100)}</td> */}
                                        <td>{(setInterest(eqityshare) * -(interest/100)).toLocaleString("en-IN")}</td>
                                    </tr>
                                    <tr>
                                        {/* <td>{dateofinvest}</td> */}
                                        <td style={{textAlign:"left"}}><strong>Investment</strong></td>
                                        {/* <td>{investvalue?investvalue:"0"}</td>
                                        <td>* -1</td> */}
                                        <td>{investvalue * -1}</td>
                                    </tr>
                                    
                                    <tr>
                                        {/* <td>{dateofinvest}</td> */}
                                        <td style={{textAlign:"left"}}><strong>Other inputs</strong></td>
                                        {/* <td>{setfinalsum?setfinalsum:0}</td>
                                        <td>* 1</td> */}
                                        <td>{setfinalsum * 1}</td>
                                    </tr>
                                    <tr>
                                        {/* <td>{dateofinvest}</td> */}
                                        <td style={{textAlign:"left"}}><strong>Goodwill</strong></td>
                                        {/* <td>Balancing Figure</td>
                                        <td>* -1</td> */}
                                        <td>{(setEquity(eqityshare,totalAmount) * -1).toLocaleString("en-IN")}</td>
                                    </tr>
                                </tbody>
                                <input type="button" className="btn btn-primary1" value="Submit" onClick={() => {handleEntrySave()}}/>
                            </table>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
export default InvestAdjustModal;