import moment from 'moment';
import React from 'react'
import { formatIndianCurrency } from '../../helper/ConvertValue';

const NbfcCashflowFaceValue = ({ workspaceDate, data, HandleReference, SwitchReferenceTab }) => {

    const calculateTotalSub = (cashflowData, faceValueIds, key) => {
        const copy = [...cashflowData];
        const filterByFaceValueId = copy.filter(ele => faceValueIds.includes(ele.faceValueId));
        const mergedArray = filterByFaceValueId.map(({ faceValueData }) => faceValueData).flat();

        const total = mergedArray.reduce((acc, item) => {
            const value = item[key];

            if (value !== null && value !== undefined) {
                if (typeof value === 'number' || (Array.isArray(value) && value.every(v => typeof v === 'number'))) {
                    return acc + (Array.isArray(value) ? value.reduce((sum, num) => sum + num, 0) : value);
                }
            }

            return acc;
        }, 0);

        return formatIndianCurrency(total);
    };

    return (
        <>
            <div>
                <table>
                    <thead >
                        <tr>
                            <th className='text-left pl-5  bg-white custom-font-600 border-top-0 border-bottom-0  text-uppercase font-inter' style={{ fontSize: "15px", width: "30%" }}>Particulars</th>
                            <th className='text-center pr-3 bg-white border-top-0 border-bottom-0' style={{width: "15%"}}>
                                <p className='m-0  custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px",  }}>FOR THE PERIOD ENDED  {moment(workspaceDate?.cy).format("DD MMMM YYYY")}</p>
                            </th>
                            <th className='text-center pr-3 bg-white border-top-0 border-bottom-0' style={{width: "15%"}}>
                                <p className='m-0  custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px"}}>FOR THE PERIOD ENDED  {moment(workspaceDate?.py).format("DD MMMM YYYY")}</p>
                            </th>
                            <th className='text-center pr-3 bg-white border-top-0 border-bottom-0'  style={{width: "15%"}}>
                                <p className='m-0 custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px",}}>REFERENCES</p>
                            </th>
                            <th className='text-center pr-3 bg-white border-top-0 border-bottom-0'  style={{width: "12.5%"}}>
                                <p className='m-0 custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px",}}>IND AS<br /> REFERENCES</p>
                            </th>
                            <th className='text-center pr-3 bg-white border-top-0 border-bottom-0'  style={{width: "12.5%"}}>
                                <p className='m-0 custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px"}}>CHECKLIST <br />REFERENCES</p>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.length > 0 && data.map((faceValue, index, originalArray, notesGrp) => (
                                <>
                                    {
                                        faceValue?.faceValueData.length > 0 && faceValue?.faceValueData.map((ele, i) => (
                                            <tr key={i}>
                                                <td className={`text-left pl-5 border-top-0 border-bottom-0 font-size-16 text-dark ${ele?.isHeading && "custom-font-600"} ${[1, 5, 6].includes(faceValue.faceValueId) && i === 0 && "custom-bg-warning"} font-sans`}>{ele?.particular}</td>
                                                <td className={`text-right pr-4 border-top-0 border-bottom-0 font-size-16 font-sans ${([1, 5, 6].includes(faceValue.faceValueId) && i === 0) && "custom-bg-warning"}`}>{formatIndianCurrency(ele?.cy, null, true)}
                                                </td>
                                                <td className={`text-right pr-4 border-top-0 border-bottom-0 font-size-16 ${([1, 5, 6].includes(faceValue.faceValueId) && i === 0) && "custom-bg-warning"} font-sans`}>
                                                    {formatIndianCurrency(ele?.py, null, true)}
                                                </td>
                                                <td 
                                                    className={
                                                        `text-left pl-1 border-top-0 border-bottom-0 font-size-16 
                                                        ${ele?.refData?.isReference ? "hyper-link-ref cursor-pointer" : ""}
                                                        ${([1, 5, 6].includes(faceValue.faceValueId) && i === 0) && "custom-bg-warning"} font-sans`
                                                    }
                                                    onClick={() => SwitchReferenceTab(ele)}
                                                >
                                                    {ele?.refData?.reference} 
                                                </td>
                                                <td className={`text-center border-top-0 border-bottom-0 font-size-16 ${[1, 5, 6].includes(faceValue.faceValueId) && i === 0 && "custom-bg-warning"} font-sans`}>
                                                    {(![1, 5, 6].includes(faceValue.faceValueId) || i !== 0) && 
                                                        <input 
                                                            type="checkbox" 
                                                            value={"ind_as_reference"}
                                                            disabled={!ele?.refData?.reference}
                                                            onChange={(e) => HandleReference(e, index, i)}
                                                            checked={ele?.refData?.isReference === "ind_as_reference"}
                                                        />
                                                    }
                                                </td>
                                                <td className={`text-center border-top-0 border-bottom-0 font-size-16  ${[1, 5, 6].includes(faceValue.faceValueId) && i === 0 && "custom-bg-warning"} font-sans`}>
                                                    {(![1, 5, 6].includes(faceValue.faceValueId) || i !== 0) && 
                                                        <input 
                                                            type="checkbox" 
                                                            value={"checklist_reference"}
                                                            disabled={!ele?.refData?.reference}
                                                            checked={ele?.refData?.isReference === "checklist_reference"}
                                                            onChange={(e) => HandleReference(e, index, i)}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }

                                    {
                                        !(faceValue?.faceValueId === 1 || faceValue?.faceValueId === 7) &&
                                        <tr>
                                            <td className='text-left pl-5 custom-font-600 border-top-0 border-bottom-0 font-size-14 font-sans'>
                                                {
                                                    faceValue?.faceValueId === 2 ? "Operating profit before working capital changes" :
                                                        faceValue?.faceValueId === 3 ? "Operating profit after working capital changes" :
                                                            faceValue?.faceValueId === 4 ? "Net cash flow from / (used) in operating activities (A)" :
                                                                faceValue?.faceValueId === 5 ? "Net cash  flow from / (used) in investing activities (B)" :
                                                                    faceValue?.faceValueId === 6 ? "Net Cash flow from / (used) in financing activities (C)" :
                                                                        faceValue?.faceValueId === 8 ? "Total Cash and Cash Equivalents" : ""
                                                }
                                            </td>
                                            <td className={`custom-font-600 font-size-14 text-right pr-4 border-top-0 border-bottom-0 ${(faceValue?.faceValueId === 2 || faceValue?.faceValueId === 3) && "border-bottom-0"}`}>
                                                {
                                                    faceValue?.faceValueId === 2 ? calculateTotalSub(originalArray, [1, 2], "cy") :
                                                        faceValue?.faceValueId === 3 ? calculateTotalSub(originalArray, [1, 2, 3], "cy") :
                                                            faceValue?.faceValueId === 4 ? calculateTotalSub(originalArray, [1, 2, 3, 4], "cy") :
                                                                faceValue?.faceValueId === 5 ? calculateTotalSub(originalArray, [5], "cy") :
                                                                    faceValue?.faceValueId === 6 ? calculateTotalSub(originalArray, [6], "cy") :
                                                                        faceValue?.faceValueId === 8 ? calculateTotalSub(originalArray, [8], "cy") : ""
                                                }
                                            </td>
                                            <td className={`custom-font-600 font-size-14 text-right pr-4 border-top-0 border-bottom-0 ${(faceValue?.faceValueId === 2 || faceValue?.faceValueId === 3) && "border-bottom-0"}`}>
                                                {
                                                    faceValue?.faceValueId === 2 ? calculateTotalSub(originalArray, [1, 2], "py") :
                                                        faceValue?.faceValueId === 3 ? calculateTotalSub(originalArray, [1, 2, 3], "py") :
                                                            faceValue?.faceValueId === 4 ? calculateTotalSub(originalArray, [1, 2, 3, 4], "py") :
                                                                faceValue?.faceValueId === 5 ? calculateTotalSub(originalArray, [5], "py") :
                                                                    faceValue?.faceValueId === 6 ? calculateTotalSub(originalArray, [6], "py") :
                                                                        faceValue?.faceValueId === 8 ? calculateTotalSub(originalArray, [8], "py") : ""
                                                }
                                            </td>
                                            <td className='border-top-0 border-bottom-0 text-right pr-3'></td>
                                            <td className='border-top-0 border-bottom-0 text-right pr-3'></td>
                                            <td  className='border-top-0 border-bottom-0 text-right pr-3'></td>
                                        </tr>

                                    }

                                    {
                                        faceValue?.faceValueId === 6 && (
                                            <tr>
                                                <td className='text-left pl-5 font-size-14 border-top-0 border-bottom-0 font-sans'>Net Increase/Decrease in cash and cash equivalent D = (A+B+C)</td>
                                                <td className=' border-top-0 border-bottom-0 text-right pr-4'>
                                                    {calculateTotalSub(originalArray, [1, 2, 3, 4, 5, 6], "cy")}
                                                </td>
                                                <td className=' border-top-0 border-bottom-0 text-right pr-4'>
                                                    {calculateTotalSub(originalArray, [1, 2, 3, 4, 5, 6], "py")}
                                                </td>
                                                <td  className=' border-top-0 border-bottom-0 text-right pr-3'></td>
                                                <td  className=' border-top-0 border-bottom-0 text-right pr-3'></td>
                                                <td  className=' border-top-0 border-bottom-0 text-right pr-3'></td>

                                            </tr>
                                        )
                                    }

                                    {
                                        faceValue?.faceValueId === 7 && (
                                            <tr>
                                                <td className='text-left pl-5 font-size-14 custom-font-600 border-top-0 border-bottom-0 font-sans'>Cash and Cash Equivalents - Closing Balance (D) + (E)</td>
                                                <td className=' border-top-0 border-bottom-0 text-right pr-4 font-size-16 custom-font-600'>
                                                    {calculateTotalSub(originalArray, [1, 2, 3, 4, 5, 6, 7], "cy")}
                                                </td>
                                                <td className=' border-top-0 border-bottom-0 text-right pr-4 font-size-16 custom-font-600'>
                                                    {calculateTotalSub(originalArray, [1, 2, 3, 4, 5, 6, 7], "py")}
                                                </td>
                                                <td  className=' border-top-0 border-bottom-0 text-right pr-3'></td>
                                                <td  className=' border-top-0 border-bottom-0 text-right pr-3'></td>
                                                <td  className=' border-top-0 border-bottom-0 text-right pr-3'></td>
                                            </tr>
                                        )
                                    }
                                </>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default NbfcCashflowFaceValue;