const OtherdDataTypes = {
    OTHERSDETAILS_ACTION_START:"OTHERSDETAILS_ACTION_START",
    OTHERSDETAILS_FETCH_DATA:"OTHERSDETAILS_FETCH_DATA",
    OTHERSDETAILS_FETCH_DATA_FAILED:"OTHERSDETAILS_FETCH_DATA_FAILED",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
}

const AllOtherDetailsReducer = (state=Initial_state, action) => {
    switch(action.type){
        case OtherdDataTypes.OTHERSDETAILS_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case OtherdDataTypes.OTHERSDETAILS_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload,
            }

        case OtherdDataTypes.OTHERSDETAILS_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default AllOtherDetailsReducer;