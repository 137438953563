import React, { useState } from 'react'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const WorkspaceSettingAccordion = ({ children, name, isEmpty }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isEmptyCheck = isEmpty ? isEmpty : false;

  return (
    <Accordion>
      <AccordionSummary className="p-1  w-100"
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="d-flex align-items-center">
          {
            isEmptyCheck ? 
              <CircleIcon className="toggl-icon text-secondary ml-3" /> :
            isOpen ?
              <div className="open-radio-outer toggl-icon toggl-icon">
                <div className="open-radio-inner"></div>
              </div>
            :  <CheckCircleIcon className='ml-3' style={{ color: "#03565A" }} />
          }
          <div className="ml-2"> {name} </div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ border: "2px solid #D6DBDF " }}>
        { children }
      </AccordionDetails>
    </Accordion>
  )
}

export default WorkspaceSettingAccordion