
import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useDispatch,useSelector} from 'react-redux'
import * as actionTypes from '../../redux/actionTypes'
import axios from 'axios'

import Note8 from './Note8';

export default function Pattern5(props) {
  const [value, setValue] = React.useState(0);
  const [nid, setNld] = useState('')
  const dispatch = useDispatch();
  const [note, setNote] = useState([])
  const [loading, setLoading] = useState(true)

  const finalStr = props.FinalStr
  const NoteStr = props.NoteStr

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const TB_ID = useSelector(initialState => initialState.reducer.tb_id) || localStorage.getItem('tb_id');
  const Note = useSelector(initialState => initialState.reducerNotes[NoteStr])
  const project_id = localStorage.getItem('project_id');

  useEffect(() => {
    const auth = localStorage.getItem('auth_token')    
    let headers = {
        'x-auth-token' : auth,
    }
    const fd = new FormData()
    if(props.note != "undefined" && props.note){
      fd.append('notes_no',props.note);
      // setLoading(true)
      axios.post(`api/v1/notes/gcn/${project_id}/${TB_ID}`,fd,{headers})
      .then(res =>{
          // setLoading(false)
          setNld(res.data.notes.nlid)    
          setNote(res.data.notes.data)
          dispatch({type: actionTypes[finalStr],payload:res.data.notes.data[0].sub_grp})              

      }).catch(err=>{
          // setLoading(false)

      })
    }
    return () => {
        // dispatch({type: actionTypes.SET_NOTE4,payload:note})              
    }    
  }, [])

  if(note){
    return (
      <div style={{paddingTop:'2rem'}}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Current"  />
              <Tab label="Non-Current" />   
          </Tabs>
          <TabPanel  value={value} index={0}>
            <Note8 noteData={note} check={"current"} data={note.length > 0 ? note[1] : []} nid={nid} setShowCreateInput={props.setShowCreateInput} note={props.note}  FinalStr='SET_NOTE8' NoteStr='note8' setSid={props.setSid} setNid={props.setNid} note4={props.note4} setNoteNum={props.setNoteNum}/>
          </TabPanel>
          <TabPanel  value={value} index={1}>
            <Note8 noteData={note} check={"non_current"} data={note.length > 0 ? note[0] : []} nid={nid} setShowCreateInput={props.setShowCreateInput} note={props.note}  FinalStr='SET_NOTE8' NoteStr='note8' setSid={props.setSid} setNid={props.setNid} note4={props.note4} setNoteNum={props.setNoteNum}/>
          </TabPanel>
        </Box>
      </div>
      );
      }
}