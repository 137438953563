const presetType = {
    DELETED_ITEMS: "DELETED_ITEMS",
    DISCLOSURE_DATA: "DISCLOSURE_DATA",
    COMPANY_TYPE:"COMPANY_TYPE",
    PROJECT_DATA:"PROJECT_DATA",
    FILTER_DISCLOSURE_DATA : "FILTER_DISCLOSURE_DATA"
}

const Initial_state = {
    companyType:"",
    projectData:{},
    deletedItems: [],
    disclosureData: [],
    filterDisclosureData: {}
};

const DisclosureReducer = (state=Initial_state, action) => {
    switch(action.type){
        case presetType.DELETED_ITEMS:
            return {
                ...state,
                deletedItems:action.payload
            }
        case presetType.DISCLOSURE_DATA:
            return {
                ...state,
                disclosureData:action.payload
            }
        case presetType.COMPANY_TYPE:
            return {
                ...state,
                companyType:action.payload
            }
        case presetType.PROJECT_DATA:
            return {
                ...state,
                projectData:action.payload
            }
        case presetType.FILTER_DISCLOSURE_DATA:
            return {
                ...state,
                filterDisclosureData:action.payload
            }
        default:
            return state;
    }
};

export default DisclosureReducer;
