import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";

import { fetchAdjLogs } from "../../redux/actions/actionStandaloneAdj";
import AdjustmentFilter from "./components/AdjustmentFilter";
import { applyAdjFilter } from "./adjustmentHelperFunction";
import filter from "../../assets/filter.svg";
import download from "../../assets/download.svg";
import { Navbar } from "../../Components/Navbar";
import arrow from "../../assets/arrowLeftGreen.svg";
import Loading from "../../Components/Common/Loader/Loading";

const adjustmentsTable = {
  minWidth: "1045px",
  height: "100vh",
};

const AdjustmentsLog = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project_id = localStorage.getItem("project_id");
  const tb_id = localStorage.getItem("tb_id");

  const {
    logLoading,
    log_error_message,
    logIsSuccess,
    logData,
    copyLogData
  } = useSelector((initialState) => initialState.ADJStandaloneReducer);
  const filterData = useSelector((initialState) => initialState.reducer.filter);

  const [exportAdjustments, setExportAdjustments] = useState([]);
  const [filterShow, setFilterShow] = useState(false);

  useEffect(() => {

    if(logData && logData?.length > 0){
      logData.forEach((element) => {
        element.data.forEach((row, index) => {
          setExportAdjustments((exportAdjustments) => [
            ...exportAdjustments,
            {
              modifiedDate: moment(element.timestamp).format("DD/MM/YYYY"),
              modifierEmail: element.email,
              adjustedLedger: row.ledger_name,
              cy: row.cy,
              adjustment: row.adjusted_amount,
              remarks: element.remarks,
            },
          ]);
        });
      });
    }
    
  }, [logData]);

  useEffect(() => {
    // if (!logIsSuccess) {
      dispatch(fetchAdjLogs(project_id, tb_id));
    // }
  }, []);


  const csvLink = {
    data: [...exportAdjustments],
    filename: "AdjustmentLogs.csv",
  };

  const handleApplyFilter = () => {
    dispatch(applyAdjFilter(copyLogData, filterData, "ADJ_LOG_FILTER"));
  }

  const nf = new Intl.NumberFormat();

  return (
    <div className="adjustmentsTable" style={adjustmentsTable}>
      <Navbar text="Adjustments" />

      <div className="adjustmentsMenu">
        <div
          className="adjustment m-0"
          onClick={() => history.push("/adjustments") }
        >
          <img
            src={arrow}
            className="mr-2"
            alt="arrow"
          />
          <p className="custom-font-600 custom-common-theme-text-color" >Back</p>
        </div>
        <div
          className="adjustment"
          onClick={() => setFilterShow(!filterShow) }
        >
          <img src={filter} alt="filter" />
          <p>Filter</p>
        </div>
        <div className="adjustment">
          <img src={download} alt="download" />
          <CSVLink {...csvLink}>
            <p style={{ color: "black" }}>Export Logs</p>
          </CSVLink>
        </div>
      </div>

      <AdjustmentFilter
        actionFrom={"log"}
        show={filterShow}
        handleApplyFilter={handleApplyFilter}
        setFilterShow={setFilterShow}
        setObject={() => { }}
      />

      {logLoading ? <Loading /> : 
        <table>
          <thead className="font-size-18" >
            <tr>
              <th className="text-left font-inter pl-3" style={{ width: "9%" }} > MODIFIED DATE </th>
              <th className="text-left font-inter pl-3" style={{ width: "14%" }}> MODIFIER ID </th>
              <th className="text-left font-inter pl-3" style={{ width: "9%" }}> LEDGER CODE </th>
              <th className="text-left font-inter pl-3" style={{ width: "30%" }}> LEDGER NAME </th>
              <th className="text-right font-inter pr-3" style={{ width: "9%" }}> CURRENT YEAR </th>
              <th className="text-right font-inter pr-3" style={{ width: "9%" }}> ADJUSTMENT </th>
              <th className="text-left font-inter pl-3" style={{ width: "20%" }}>Log Action</th>
            </tr>
          </thead>

          <tbody>
            {logData?.length > 0 && logData.map((adj, i) => {
              var email = adj.email;
              var date = adj.timestamp.split(",")[0];

              return adj?.data?.map((val, ix) => {
                return (
                  <tr className='bg-white' key={ix}>
                    {
                      ix === 0 ?
                        <td className='text-left pl-3' rowSpan={ix === 0 && adj.data.length}>{date}</td> : <></>
                    }
                    {
                      ix === 0 ?
                        <td className='text-left pl-3' rowSpan={ix === 0 && adj.data.length}>{email}</td> : <></>
                    }

                    <td className='text-center'>{val?.ledger_code}</td>
                    <td className='text-left pl-3'>{val?.ledger_name}</td>
                    <td className='text-right pr-3'>{nf.format(val.cy)}</td>
                    <td className='text-right pr-3'>{nf.format(val.adjusted_amount)}</td>
                    {
                      ix === 0 ?
                        <td className='text-left pl-3' rowSpan={ix === 0 && adj.data.length}>{adj?.logAction}</td> : <></>
                    }
                  </tr>
                )
              });
            })}
          </tbody>
        </table>
      }

      {logData?.length <= 0 &&
        <p className="w-100 text-center text-secondary" > Adjustment Logs data is empty. </p>
      }
    </div>
  );
};

export default AdjustmentsLog;
