import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { getSocieData, updateSocieReference } from '../../services/api/apiHandler';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import SocieTableBody from './SocieTableBody';
import './socie.css';
import { socket } from "../../services/socket/socket";
import Loading from '../Common/Loader/Loading';
import { fetchTTBSData } from '../../redux/actions/TemplateTabs/actionBS';
import { convertStrToNumber } from '../../helper/ConvertValue';
import { getChecklistData } from '../../services/api/StandAlone/disclosureChecklist';

const UpdatedSocie = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const project_id = localStorage.getItem("project_id");
  const tb_id = localStorage.getItem("tb_id");
  const reduxSocieData = useSelector((initialState) => initialState.SoccieReducer.standaloneSocieData);
  const reduxChecklistData = useSelector((initialState) => initialState.DisclosureChecklistReducer.disclosureChecklistData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(Object.keys(reduxSocieData).length <= 0){
      setLoading(true);
      getSocieData(project_id, tb_id).then(response => {
        dispatch({ type: "STANDALONE_SOCIE_DATA", payload: response.data?.socie_list[0]});
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        swal("Error", error.response?.data?.error || "Socie calculation failed !" , "error");
      });
    }
  }, []);

  useEffect(() => {
    if (reduxChecklistData.length <= 0) {
        getChecklistData(project_id, tb_id).then(response => {
            dispatch({ type: "DISCLOSURE_CHECKLIST_DATA", payload: response?.data?.data });
        }).catch(error => {
            swal("Error", error, "error");
        });
    }
}, [reduxChecklistData])

  const HandleChange = async (event, type, index) => {
    const copy = {...reduxSocieData}

    copy[type][index][event.target.name] = convertStrToNumber(event.target.value);

    dispatch({ type: "STANDALONE_SOCIE_DATA", payload: copy});
    
    socket.emit("request-updated-socie", {
      project_id,
      contents : [copy]
    });

    await dispatch(fetchTTBSData(project_id, tb_id, "financialstatement"));
  }

  socket.on('request-updated-socie', (data) => {
    if (!data.success) {
      return swal("Error", (data.message || "Socie updated value saved failed!"), "error")
    }
  });

  const HandleReference = (event, header, dataIndex) => {
    const data = {
      header,
      dataIndex,
      value: event.target.value
    }

    
    updateSocieReference(project_id, tb_id, data).then(response => {
      dispatch({ type: "STANDALONE_SOCIE_DATA", payload: response.data?.socie_list[0]});
    }).catch(error => {
      swal("Error", error.response?.data?.error || "Reference update failed !" , "error");
    })
  }

  const SwitchReferenceTab = (element) => {
    if(element?.refData?.isReference === "ind_as_reference"){
        dispatch({ type: "IND_AS_1_FROM_BS_PNL", payload: element.refData.name });
        history.push("/library-indas-1")
    }
    if(element?.refData?.isReference === "checklist_reference"){
        const index = reduxChecklistData.findIndex((item) => item.name === element.refData.name);
        dispatch({ type: "NOTE_KEY", payload: 8 });
        dispatch({ type: "NOTE_INDEX", payload: index });
    }
}

  return (
    <>
      {loading ? <Loading /> :
        <motion.div 
          style={{overflowX:"auto"}}
          initial={{ y: "-5vh" }}
          animate={{ y: 0 }}
        >
          <table className='bg-white '>
            <thead className='custom-bg-white-200'>
              <tr>
                <th className="socie-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2}></th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Share application money pending allotment</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Equity component of compounded financial instruments</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" colSpan={4} >Reserves and surplus</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Debt instruments through Other Comprehensive Income</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Equity Instruments through Other Comprehensive Income</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Effective portion of Cash Flow Hedges</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Revaluation Surplus</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Exchange differences on translating the financial statements of a foreign operation</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Money received against share warrants</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Total</th> 
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >References</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >IND AS References</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14" rowSpan={2} >Checklist References</th>
              </tr>
              <tr>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14 font-sans">Capital Reserve</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14 font-sans">Securities Premium</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14 font-sans">Other Reserves (specify nature)</th>
                <th className="socie-amount-header custom-bg-white-200 custom-font-600 font-size-14 font-sans">Retained earnings</th>
              </tr>
            </thead>
            {
              reduxSocieData && reduxSocieData?.previous_row_header && reduxSocieData?.previous_row_header.length > 0 && 
              <SocieTableBody  type={"previous_row_header"} data={reduxSocieData?.previous_row_header} HandleChange={HandleChange} HandleReference={HandleReference} SwitchReferenceTab={SwitchReferenceTab} />
            }
            {
              reduxSocieData && reduxSocieData?.current_row_header && reduxSocieData?.current_row_header.length > 0 && 
              <SocieTableBody  type={"current_row_header"} data={reduxSocieData?.current_row_header} HandleChange={HandleChange} HandleReference={HandleReference} SwitchReferenceTab={SwitchReferenceTab}/>
            }
          </table>
        </motion.div>
      }
    </>
  )
}

export default UpdatedSocie