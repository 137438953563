const presetType = {
    TBC_DATA_ACTION_START: "TBC_DATA_ACTION_START",
    TBC_DATA_FETCH_DATA: "TBC_DATA_FETCH_DATA",
    TBC_DATA_FETCH_DATA_FAILED: "TBC_DATA_FETCH_DATA_FAILED",
    SET_TAB_VALUE: "SET_TAB_VALUE",
    CATEGORY_ITEMS: "CATEGORY_ITEMS",
    CATEGORY_ITEMS_BY_NOTE_NO: "CATEGORY_ITEMS_BY_NOTE_NO",
    SET_IS_ADD_SUB_GROUP_MODAL: "SET_IS_ADD_SUB_GROUP_MODAL",
    SET_IS_ADD_SUB_GROUP_LABEL3_MODAL: "SET_IS_ADD_SUB_GROUP_LABEL3_MODAL",
    SET_SELECTED_FLAT_ROWS: "SET_SELECTED_FLAT_ROWS",
    SET_IS_DELETED_LEDGER_MODAL: "SET_IS_DELETED_LEDGER_MODAL",

    DL_DATA_ACTION_START: "DL_DATA_ACTION_START",
    DL_DATA_FETCH_DATA: "DL_DATA_FETCH_DATA",
    DL_DATA_FETCH_DATA_FAILED: "DL_DATA_FETCH_DATA_FAILED",
}

const Initial_state = {
    lineItems: [],
    groupings: [],
    filters: [],
    loading: false,
    error: false,
    errorMessage: null,
    tabValue: 0,
    catItems: [],
    thirdLabelData: {},
    switchTab2ndTo3rdLabel: false,

    isAddSubGroupModalOpen: false,
    isAddSubGroupLabel3ModalOpen: false,
    selectedFlatRows: [],
    isDeletedLedgerModalOpen: false,

    dLedgerLoading: false,
    dLedgerErrorMessage: null,
    deletedLedgerItems: [],
};

const BookClosureTbcReducer = (state = Initial_state, action) => {
    switch (action.type) {
        case presetType.TBC_DATA_ACTION_START:
            return {
                ...state,
                loading: true,
                lineItems: [],
                error: false,
                errorMessage: null
            }
        case presetType.TBC_DATA_FETCH_DATA:
            return {
                ...state,
                lineItems: action.payload?.bookClosureTB?.ledgers ?? [],
                groupings: action.payload?.bookClosureTB?.grouping ?? [],
                filters: action.payload?.filters ?? [],
                loading: false,
                error: false,
                errorMessage: null,
            }
        case presetType.TBC_DATA_FETCH_DATA_FAILED:
            return {
                ...state,
                lineItems: [],
                errorMessage: action.payload,
                error: true
            }
        case presetType.SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.payload,
            }
        case presetType.CATEGORY_ITEMS:
            return {
                ...state,
                switchTab2ndTo3rdLabel: false,
                catItems: action.payload || [],
            }
        case presetType.CATEGORY_ITEMS_BY_NOTE_NO:
            return {
                ...state,
                thirdLabelData: action.payload || {},
                switchTab2ndTo3rdLabel: true,
            }
        case presetType.SET_IS_ADD_SUB_GROUP_MODAL:
            return {
                ...state,
                isAddSubGroupModalOpen: action.payload
            }
        case presetType.SET_IS_ADD_SUB_GROUP_LABEL3_MODAL:
            return {
                ...state,
                isAddSubGroupLabel3ModalOpen: action.payload
            }
        case presetType.SET_SELECTED_FLAT_ROWS:
            return {
                ...state,
                selectedFlatRows: action.payload
            }
        case presetType.SET_IS_DELETED_LEDGER_MODAL:
            return {
                ...state,
                isDeletedLedgerModalOpen: action.payload
            }

        case presetType.DL_DATA_ACTION_START:
            return {
                ...state,
                dLedgerLoading: true,
                deletedLedgerItems: [],
                dLedgerErrorMessage: null
            }
        case presetType.DL_DATA_FETCH_DATA:
            return {
                ...state,
                deletedLedgerItems: action.payload?.deletedLedgers ?? [],
                dLedgerLoading: false,
                dLedgerErrorMessage: null,
            }
        case presetType.DL_DATA_FETCH_DATA_FAILED:
            return {
                ...state,
                deletedLedgerItems: [],
                dLedgerErrorMessage: action.payload,
                dLedgerLoading: false,
            }
        default:
            return state;
    }
};

export default BookClosureTbcReducer;
