const PNLTypes = {
    TT_PNL_ACTION_START:"TT_PNL_ACTION_START",
    TT_PNL_FETCH_DATA:"TT_PNL_FETCH_DATA",
    TT_PNL_FETCH_DATA_FAILED:"TT_PNL_FETCH_DATA_FAILED",
    TT_PNL_ADD_DATA:"TT_PNL_ADD_DATA",
    TT_PNL_SOCKET_UPDATE_DATA: "TT_PNL_SOCKET_UPDATE_DATA"
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
    set_payload: ""
}

const TempTabPNLReducer = (state=Initial_state, action) => {
    switch(action.type){
        case PNLTypes.TT_PNL_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case PNLTypes.TT_PNL_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                set_payload: action.payload.set_payload
            }

        case PNLTypes.TT_PNL_SOCKET_UPDATE_DATA:
            const { data, set_payload } = action.payload;
            if(state.set_payload !== set_payload){
                return {
                    ...state
                }
            }
            return {
                ...state,
                response: {
                    ...state.response,
                    pnl_statement : data
                }
            }

        case PNLTypes.TT_PNL_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        default:
            return state;
    }
};

export default TempTabPNLReducer;