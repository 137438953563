import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import swal from 'sweetalert';
import SupportVpage from "./Support";
import "./support.css";
import SupportNav from "./SupportNav";
import { getTicket } from "../../WorkSpace/Components/ApiServices/apiHandler";
import { getProfile } from "../../services/api/apiHandler";
import Loading from "../../Components/Common/Loader/Loading";

const SupportTicketView = () => {
    const history = useHistory();
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProfileId();
    }, []);

    const getProfileId = async () => {
        getProfile().then(response => {
            getTicket(response.data.user.data._id).then(res => {
                setLoading(false);
                setTickets(res.data.data);
            }).catch(err => {
                setLoading(false);
                swal("Error", err.response?.data?.error || "Error: Ticket Data Not Found!", "error");
            })
        }).catch(error => {
            setLoading(false);
            swal("Error", error.response?.data?.error || "Error: User Data Not Found!", "error");
        })
    }

    return (
        <>
            { loading ? <Loading /> :
            tickets.length > 0 ? (<>
                <SupportNav handleBack={() => history.push('/dashboard')} />
                <div className="px-4 py-4 mt-3">
                    <div className='d-flex justify-content-between align-items-center' >
                        <h5 className='mt-0 mb-0   font-size-20 font-sans custom-font-700' >Support Request</h5>
                        <div className="d-flex  align-items-center">
                            <button 
                                className="font-sans custom-font-700 font-size-20 border-0 bg-color-inherit" 
                                onClick={() => history.push("/support-faq")}
                            >
                                FAQ
                            </button>
                            <button 
                                className='custom-font-700 font-size-16  primary-border custom-common-theme-text-color font-sans ml-3 px-3 py-2 radius-8px' 
                                onClick={() => history.push("/support-contact")}  
                            >
                                Contact Us
                            </button>
                        </div>
                    </div>
                    <table className='mt-4 custom-table w-100'>
                        <thead className="custom-bg-white-150" >
                            <tr >
                                <th className="text-left text-uppercase  font-size-12 custom-font-600 font-inter border-top-left-radius custom-font-color" style={{ Width: '12%', }}>Req ID</th>
                                <th className="text-left text-uppercase  font-size-12 custom-font-600 font-inter custom-font-color" style={{ width: '18%' }}>Shot description</th>
                                <th className="text-left text-uppercase  font-size-12 custom-font-600 font-inter custom-font-color" style={{ width: '15%' }}>Open Date</th>
                                <th className="text-left text-uppercase  font-size-12 custom-font-600 font-inter custom-font-color" style={{ width: '15%' }}>last updated date</th>
                                <th className="text-left text-uppercase  font-size-12 custom-font-600 font-inter custom-font-color" style={{ width: '12%' }}>Status</th>
                                <th className="text-left text-uppercase  font-size-12 custom-font-600 font-inter custom-font-color" style={{ width: '28%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.map((ticket, index) => (
                                <tr key={ticket._id}>
                                    <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 text-break custom-font-color font-sans " >
                                        MyF-000{index + 1}
                                    </td>
                                    <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 font-sans custom-font-color " >
                                        {ticket.subject}
                                    </td>
                                    <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 font-sans custom-font-color " >
                                        {moment(ticket.createdAt).format('DD/MM/YYYY')}
                                    </td>
                                    <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 font-sans custom-font-color">
                                        {moment(ticket.updatedAt).format('DD/MM/YYYY')}
                                    </td>
                                    <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 font-sans custom-font-color" >
                                        {ticket.status}
                                    </td>

                                    <td
                                        className="text-right mt-0 mb-0 font-size-16 custom-font-700 custom-common-theme-text-color font-sans cursor-pointer"
                                        onClick={() => history.push(`/support-contact/${ticket._id}`)}
                                    >
                                        View Details
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>) : <SupportVpage />}
        </>
    );
};

export default SupportTicketView;
