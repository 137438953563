import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, MenuItem, Modal, Select, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { React, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import CheckIcon from '@mui/icons-material/Check';
import { GetAllCompanyData, GetCheckedIR, GetUser, getWorkspace,GetAllImportedCompanies } from "../ApiServices/apiHandler";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FunctionalityAccess = () => {
  let { uid,wid } = useParams();
  const user = JSON.parse(localStorage.getItem('user'));
  const modifyAccess_action = localStorage.getItem("modifyAccess");
  let history = useHistory();
  const [allUser, setAllUser] = useState([]);
  const [company, setCompany] =  useState([]);
  const [singleWorkspace, setSingleWorkspace] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [importedCompany, setImportedCompany] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    GetAllUser();
    GetAllCompany();
    getIr();
    getWorkspaceDetails();
    GetAllImportedCompany();
  }, []);

  const GetAllUser = async () => {
    const res = await GetUser();
    if (res.status === 200) {
      setAllUser(res.data.fetchUser);
    }
  };
  const GetAllCompany = async () => {
    const res = await GetAllCompanyData();
    if (res.status === 200) {
      setCompany(res.data.getAllCompanyData);
    }
  };
  const GetAllImportedCompany = async () => {
    const user_id=uid;
    const res = await GetAllImportedCompanies(user_id);
    if (res.status === 200) {
      setImportedCompany(res.data.result);
    }
  };
  const userName = (u_id,field) => {
     if(field === "name"){
      const nameUser = allUser.filter((adj) => adj._id === u_id);
      const result = nameUser[0]?.email ? nameUser[0]?.email : "unknown";
      return result;
     } else if(field === "role"){
      const nameUser = allUser.filter((adj) => adj._id === u_id);
      const result = nameUser[0]?.role ? nameUser[0]?.role : "unknown";
      return result;
     } else{
      const nameUser = allUser.filter((adj) => adj._id === u_id);
      const result = nameUser[0]?.responsibilities ? nameUser[0]?.responsibilities : "Admin";
      return result;
     }
     
  };
  const companyName = (c_id) => {
        const nameUser = company.filter((adj) => adj._id === c_id);
        const result = nameUser[0]?.company_name ? nameUser[0]?.company_name : "unknown";
        return result;
  };


  const getWorkspaceDetails = async () => {
    const res = await getWorkspace(wid);
    if (res.status === 200) {
      setSingleWorkspace(res.data?.getworkspace[0])
    }
  }
  const getIr = async () => {
    try {
      const res = await GetCheckedIR(wid);

      if (res.status === 200) {
            console.log(res,'---------')
        if(res.data.getIR[0].all_company_checked.length>0){
          setSelectedCompany(res.data.getIR[0].all_company_checked)
        } 
      }
    } catch (error) {}
  };
  
  return (
    <div className="row">
      <div className="col-md-3">
        <div
          className="container d-flex flex-column justify-content-between"
          style={{
            width: "300px",
            height: "95vh",
            backgroundColor: "white",
            marginLeft: "5px",
          }}
        >
          <div
            className="font-weight-bold cursor-pointer justify-content-center"
            onClick={userName(uid,"responsibilities") === "approver" ? null:() => history.push(`/modifyacess/${uid}/${wid}`)}
            style={{ marginTop: "100px" }}
          >
            Workspace Settings
          </div>
          <div
            className="font-weight-bold cursor-pointer fs-4 justify-content-center"
            style={{ marginBottom: "200px", backgroundColor: "#b2edc8" }}
          >
            Functionality Access
          </div>
        </div>
      </div>
      <div className="col-md-9">
        <div className="container">
          <div className="row m-5">
            <div className="col-md-2 d-flex justify-content-center align-items-center">
              MyFinalist ID:
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              {userName(uid,"name")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-auto" style={{ marginLeft: "83px" }}>
            Role:
          </div>
          <div className="col-auto" style={{ marginLeft: "110px" }}>
            <Button
              className="btn"
              style={{ backgroundColor: "#ebcab0", color: "#5c5b5a" }}
            >
              {userName(uid,"role")}
            </Button>
          </div>
          <div className="col-auto" style={{ marginTop: "-7px" }}>
            <Button
              className="btn m-2"
              style={{ backgroundColor: "#9ec3e6", color: "#5c5b5a" }}
            >
              {userName(uid,"responsibilities")}
            </Button>
          </div>
        </div>
        <div className="row m-3">
          <div className="ml-5 w-75">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                 Companies
                </AccordionSummary>
                <AccordionDetails>
                <Typography
                    style={{
                      overflowY: "scroll",
                      height: "15rem",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    <div style={{}}>
                      {selectedCompany.length > 0 && selectedCompany.map((comp) => {
                          return (
                            <>
                              <div className="d-flex ">
                              <Checkbox
                                sx={{
                                  color: "#03565A",
                                  '&.Mui-checked': {
                                    color: "#03565A",
                                  },
                                }}
                                checked={importedCompany.includes(comp)}
                                disabled
                                id={comp}
                              />
                                <span className="d-flex justify-content-between w-100 mt-2 font-sans">
                                  <h6>{companyName(comp)}</h6>
                                </span>
                                {/* <CheckIcon/> */}
                              </div>
                              
                            </>
                          );
                        })}
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5 mr-5">
          <Button
            className="btn"
            style={{ backgroundColor: "#03565A", color: "white" }}
            onClick={() => history.push(`/${modifyAccess_action}/${wid}`)}
          >
            Save and Proceed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FunctionalityAccess;
