const CompanyTypes = {
    PAYABLES_ACTION_START:"PAYABLES_ACTION_START",
    PAYABLES_FETCH_DATA:"PAYABLES_FETCH_DATA",
    PAYABLES_FETCH_DATA_FAILED:"PAYABLES_FETCH_DATA_FAILED",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
}

const PayablesReducer = (state=Initial_state, action) => {
    switch(action.type){
        case CompanyTypes.PAYABLES_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case CompanyTypes.PAYABLES_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload,
            }

        case CompanyTypes.PAYABLES_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default PayablesReducer;