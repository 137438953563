import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Navbar } from "../../Components/Navbar";
import Section from "../Components/Section";
import AccordionItem from "../Components/AccordionItem";
import "./../../Bookclosure/Styles/Bookclosure.css";
import {
  getLibraryList,
  getOneBookClosure,
  importTasksFromLibrary,
} from "../Services/ApiHandler";

const BookClosureLibrary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { workspaceId, companyId } = useParams();

  const { bookClosureId } = useSelector((state) => state.BookClosureReducer);

  const [activeSection, setActiveSection] = useState("");
  const [bookClosureLibrary, setBookClosureLibrary] = useState([]);
  const [taskList, setTaskList] = useState({});
  const [libraryCheckedTasks, setLibraryCheckedTasks] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isHeaderHovered, setIsHeaderHovered] = useState(false);

  useEffect(() => {
    getBookClosureData();
    if (bookClosureId) {
      GetLibraryList(bookClosureId, companyId);
    }
  }, []);

  const getBookClosureData = () => {
    const data = {
      // companies: [],
      // assignees: [],
      // approvers: [userId]
    };
    if (!bookClosureId) {
      getOneBookClosure(workspaceId, data)
        .then((response) => {
          dispatch({
            type: "BOOK_cLOSURE_DATA",
            payload: response.data.bookClosure || {},
          });
          const book_closure_id = response.data.bookClosure._id;
          GetLibraryList(book_closure_id, companyId);
        })
        .catch((error) => {
          swal(
            "Error",
            `Book Closure Error: ${error.response?.data?.error}`,
            "error"
          );
        });
    }
  };
  //
  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  //
  const handleHeaderMouseEnter = () => {
    setIsHeaderHovered(true);
  };

  const handleHeaderMouseLeave = () => {
    setIsHeaderHovered(false);
  };
  //
  const GetLibraryList = (bookClosureId, companyId) => {
    getLibraryList(bookClosureId, companyId).then(response => {
      const availableLibraries = response.data.bookClosureLibrary.filter(item => item.available);
      setBookClosureLibrary(availableLibraries);
      setTaskList(availableLibraries[0] ?? {});
      setActiveSection(availableLibraries[0].module ?? "")
      setLibraryCheckedTasks([])
    }).catch(error => {
      swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
    })
  }

  const ImportLibraryTasks = () => {
    const hasUncheckedTask = libraryCheckedTasks.some(task => !task.exist);
    if (hasUncheckedTask) {
      // Show warning toast if any task is unchecked (either template or exist)
      toast.warning("Some tasks are incomplete. Ensure all tasks have both 'template' and 'exist' checked.");
      return;
    }
    const data = {
      companyID: companyId,
      libraryTaskData: libraryCheckedTasks.map(task => ({
        libraryTaskID: task.libraryTaskID,
        template: task.template
      }))
    }
    if (libraryCheckedTasks.length > 0) {
      importTasksFromLibrary(bookClosureId, data).then(response => {
        toast.success("Tasks Imported Successfully.");
        GetLibraryList(bookClosureId, companyId);
      }).catch(error =>
        swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error')
      )
    }
  }

  const HandleStatusChange = (event, moduleName, ix, task) => {
    const { name } = event.target;

    const updateTaskStatus = (tasks, ix, name) => {
      return tasks.map((task, index) =>
        index === ix ? { ...task, [name]: !task[name] } : task
      );
    };

    setLibraryCheckedTasks(prevUpdateList => {
      if (task) {
        const oldTask = { libraryTaskID: task._id, template: task?.template, exist: task?.exist };
        const newTask = { ...oldTask, [name]: !task[name] }

        const taskExists = prevUpdateList.some(t => t.libraryTaskID === task._id);
        if (taskExists) {
          // If the task exists, update the respective field (template or exist)
          const updatedList = prevUpdateList.map(t =>
            t.libraryTaskID === task._id ? { ...t, [name]: !t[name] } : t
          );
          // Find the updated task after toggle
          const updatedTask = updatedList.find(t => t.libraryTaskID === task._id);
          // If both template and exist are false, remove the task from the list
          if (!updatedTask.template && !updatedTask.exist) {
            return updatedList.filter(t => t.libraryTaskID !== task._id);
          }
          return updatedList;
        } else {
          // If the task doesn't exist, push the newTask into the list
          return [...prevUpdateList, newTask];
        }
      }
      return prevUpdateList;
    });

    setBookClosureLibrary(prevLibrary => {
      return prevLibrary.map(module => {
        if (module.module === moduleName && module.tasks && module.tasks[ix]) {
          return {
            ...module,
            tasks: updateTaskStatus(module.tasks, ix, name),
          };
        }
        return module;
      });
    });

    setTaskList((prevTaskList) => {
      if (
        prevTaskList.module === moduleName &&
        prevTaskList.tasks &&
        prevTaskList.tasks[ix]
      ) {
        return {
          ...prevTaskList,
          tasks: updateTaskStatus(prevTaskList.tasks, ix, name),
        };
      }
      return prevTaskList;
    });
  };

  return (
    <>
      <div>
        <Navbar />
        <div className="py-2 px-5">
          <button
            className="d-flex align-items-center border-0 bg-color-inherit"
            onClick={() => history.push(`/bookclosure/${workspaceId}`)}
          >
            <span>
              <ArrowBackIcon className="custom-common-theme-text-color" />
            </span>
            <p className="m-0 custom-common-theme-text-color font-size-16 custom-font-600 ml-1">
              Back
            </p>
          </button>

          <div>
            <p className="custom-font-600 font-size-24">Book Closure Library</p>
            <p className="custom-font-500 font-size-16 text-secondary">
              Import MyFinalyst tasks from our Module library.
            </p>
          </div>

          <div className="mb-3">
            <Card className="radius-10px">
              <div className="px-3 ">
                <div className="d-flex justify-content-between align-items-center p-2">
                  <p className="font-size-18 custom-font-600 mt-0 mb-0">{`Modules (${bookClosureLibrary.length})`}</p>
                  <div className="mt-2 task-btn">
                    {/* <Button
                      variant="outlined"
                      className='font-size-16 custom-font-600 custom-common-theme-text-color primary-border btn-task font-sans radius-6px text-capitalize font-sans'
                      onClick={handleOpenGeneral}
                    >
                      Save Selection
                    </Button> */}
                    <Button
                      variant="contained"
                      className="custom-common-theme-bg-color font-size-16 custom-font-600 btn-task font-sans ml-3 radius-6px text-capitalize text-white font-sans"
                      onClick={() => ImportLibraryTasks()}
                    >
                      Import tasks
                    </Button>
                  </div>
                </div>

                <div className="row  mr-1">
                  <div className="col-3" style={{ height: "53.5vh", overflowY: "auto" }}>
                    {bookClosureLibrary &&
                      bookClosureLibrary.length > 0 &&
                      bookClosureLibrary.map((section, index) => (
                        <Section
                          key={index}
                          title={section.module}
                          isActive={section.module === activeSection}
                          onClick={() => {
                            setActiveSection(section.module);
                            const findLibData = bookClosureLibrary.find(
                              (ele) => ele.module === section.module
                            );
                            setTaskList(findLibData);
                          }}
                        />
                      ))}
                  </div>

                  <div
                    className="col-9 radius-10px custom-bg-white-150 d-flex mb-0 mt-0 " >
                    <div className="d-flex w-100 mb-3" style={{ height: "53.5vh", overflowY: "auto" }} >
                      <div className="d-flex p-1 w-100 ">
                        <table>
                          <thead>
                            <tr>
                              <th
                                className="text-capitalize text-left pl-1 border-0 cursor-pointer"
                                onMouseEnter={handleHeaderMouseEnter}
                                onMouseLeave={handleHeaderMouseLeave}
                                style={{
                                  backgroundColor: isHeaderHovered
                                    ? "#F8F8F8"
                                    : "transparent",
                                  width:"90%"
                                }}
                              >
                                {taskList?.module}
                              </th>
                              <th
                                className="border-0 cursor-pointer"
                                onMouseEnter={handleHeaderMouseEnter}
                                onMouseLeave={handleHeaderMouseLeave}
                                style={{
                                  backgroundColor: isHeaderHovered
                                    ? "#F8F8F8"
                                    : "transparent",
                                  width:"5%"
                                }}
                              >
                                Tasks
                              </th>
                              <th
                                className="border-0 cursor-pointer"
                                onMouseEnter={handleHeaderMouseEnter}
                                onMouseLeave={handleHeaderMouseLeave}
                                style={{
                                  backgroundColor: isHeaderHovered
                                    ? "#F8F8F8"
                                    : "transparent", // Apply hover color
                                  width:"5%"
                                }}
                              >
                                Templates
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {taskList?.tasks &&
                              taskList.tasks.length > 0 &&
                              taskList.tasks.map((task, ix) => (
                                <tr
                                  key={ix}
                                  onMouseEnter={() => handleMouseEnter(ix)}
                                  onMouseLeave={handleMouseLeave}
                                  style={{
                                    backgroundColor:
                                      hoveredRow === ix
                                        ? "#F8F8F8"
                                        : "transparent", // Change color on hover
                                  }}

                                >
                                  <td className="w-75 border-0 pb-2" style={{width:"90%"}}>
                                    <AccordionItem task={task} index={ix + 1} />
                                  </td>
                                  <td className="border-0 pb-2">
                                    <div className="mb-4 mt-2">
                                      <input
                                        checked={task.exist}
                                        name="exist"
                                        onChange={(event) =>
                                          HandleStatusChange(
                                            event,
                                            taskList?.module,
                                            ix,
                                            task
                                          )
                                        }
                                        type="checkbox"
                                        style={{ width: "25px", height: "25px" }}
                                      />
                                    </div>
                                  </td>
                                  <td className="border-0 pb-2">
                                    <div className="d-flex justify-content-center align-items-center mb-4 mt-2">
                                      <input
                                        checked={task.template}
                                        disabled={!task.hasTemplate}
                                        name="template"
                                        onChange={(event) =>
                                          HandleStatusChange(
                                            event,
                                            taskList?.module,
                                            ix,
                                            task
                                          )
                                        }
                                        type="checkbox"
                                        style={{ width: "25px", height: "25px" }}
                                      />
                                      <div className="oneline-icon"></div>
                                      <VisibilityIcon
                                        className="ml-1"
                                        style={{ width: "30px", height: "30px", color: "gray" }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookClosureLibrary;
