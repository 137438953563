import { getNoteNumber, getScenario, groupDataCal } from "./calculation";

export const CurrentProjectCy = (currentProjectPnl, group_name, total_tax=false) => {
    if(group_name === "Expenses"){
      let res = 0;
      if(total_tax){
        res = currentProjectPnl[group_name].filter((data) => data.notes_grp === "Current tax" || data.notes_grp ==="Deferred tax")
      }else{
        res = currentProjectPnl[group_name].filter((data) => data.notes_grp !== "Current tax" && data.notes_grp !=="Deferred tax")
      } 
      return res.map((data)=>data.cy).reduce((num, acc)=>num + acc, 0)
    }
    return currentProjectPnl[group_name]?.map((data)=>data.cy).reduce((num, acc)=>num + acc, 0)
  }

export const CurrentProjectPy = (currentProjectPnl, group_name, total_tax=false) => {
    if(group_name === "Expenses"){
      let res = 0;
      if(total_tax){
        res = currentProjectPnl[group_name].filter((data) => data.notes_grp === "Current tax" || data.notes_grp ==="Deferred tax")
      }else{
        res = currentProjectPnl[group_name].filter((data) => data.notes_grp !== "Current tax" && data.notes_grp !=="Deferred tax")
      } 
      return res.map((data)=>data.py).reduce((num, acc)=>num + acc, 0)
    }
    return currentProjectPnl[group_name]?.map((data)=>data.py).reduce((num, acc)=>num + acc, 0)
}


export const calCulateProfitBefore = (data, type) => {
  const income = data.company_type === 'NBFC' ? 'Revenue from operations' : 'Income';

  try {
    if(type === "cy"){
      return CurrentProjectCy(data.pnl_statement, income) - CurrentProjectCy(data.pnl_statement, "Expenses")
    }else if(type === "py"){
      return CurrentProjectPy(data.pnl_statement, income) - CurrentProjectPy(data.pnl_statement, "Expenses")
    }
  } catch (error) {
    console.log("err", error.message)
    return "**"
  }
}

export const calculateProfitOfTheYear = (data, type) => {
  if(type === 'cy') 
    return ( calCulateProfitBefore(data,'cy') - CurrentProjectCy(data.pnl_statement, "Expenses", true) ) || 0;
  else 
    return ( calCulateProfitBefore(data,'py') - CurrentProjectPy(data.pnl_statement, "Expenses", true) ) || 0;
}