import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Submit } from "../Components/Button";

const button = {
    padding: "8px",
    width: "10rem",
    // marginLeft:'auto'
  };
const ExportExcel = ({
  excelData,
  excelData2,
  excelData3,
  excelData4,
  excelData5,
  excelData6,
  excelData7,
  excelData8,
  fileName,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData, { skipHeader: true });

    var wscols = [
      { width: 80 }, // first column
      { width: 20 }, // second column
      { width: 20 }, //...
      { width: 20 },
    ];
    ws["!cols"] = wscols;

    ws["A1"].s = {
      // set the style for target cell
      font: {
        sz: 24,
        bold: true,
        color: { rgb: "BA2525" },
      },
    };
    ws["A2"].s = {
      alignment: {
        wrapText: true,
        color: { rgb: "BA2525" },
      },
    };
    //  Page 3
    var wscols3 = [
      { width: 30 }, // first column
      { width: 30 }, // second column
      { width: 20 }, //...
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    // page4
    var wscols4 = [
      { width: 70 }, // first column
      { width: 25 }, // second column
      { width: 25 }, //...
      { width: 25 },
    ];

    // page5

    var wscols5 = [
      { width: 70 }, // first column
      { width: 20 }, // second column
      { width: 20 }, //...
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    // page6
    var wscols6 = [
      { width: 60 }, // first column
      { width: 20 }, // second column
      { width: 20 }, //...
      { width: 20 },
      { width: 20 },
    ];

    // pag7
    var wscols7 = [
      { width: 60 }, // first column
      { width: 20 }, // second column
      { width: 20 }, // third column
    ];

    // page 8
    var wscols8 = [
      { width: 60 }, // first column
      { width: 20 }, // second column
      { width: 20 }, //...
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    const ws2 = XLSX.utils.json_to_sheet(excelData2, { skipHeader: true });
    ws2["!cols"] = wscols;

    const ws3 = XLSX.utils.json_to_sheet(excelData3, { skipHeader: true });
    ws3["!cols"] = wscols3;

    const ws4 = XLSX.utils.json_to_sheet(excelData4, { skipHeader: true });
    ws4["!cols"] = wscols4;

    const ws5 = XLSX.utils.json_to_sheet(excelData5, { skipHeader: true });
    ws5["!cols"] = wscols5;

    const ws6 = XLSX.utils.json_to_sheet(excelData6, { skipHeader: true });
    ws6["!cols"] = wscols6;

    const ws7 = XLSX.utils.json_to_sheet(excelData7, { skipHeader: true });
    ws7["!cols"] = wscols7;

    const ws8 = XLSX.utils.json_to_sheet(excelData8, { skipHeader: true });
    ws8["!cols"] = wscols8;

    const wb = {
      Sheets: {
        Bs: ws,
        "P&L": ws2,
        SoCE: ws3,
        CFS: ws4,
        PPE: ws5,
        "BS Notes": ws6,
        "PL Notes": ws7,
        Leases: ws8,
      },
      SheetNames: [
        "Bs",
        "P&L",
        "SoCE",
        "CFS",
        "PPE",
        "BS Notes",
        "PL Notes",
        "Leases",
      ],
    };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    FileSaver.saveAs(data, fileName + date + fileExtension);
  };

  return (
    <>
      {/* <button onClick={(e) => exportToExcel(fileName)}>Export Me</button> */}
      <div style={button}>
        <div onClick={(e) => exportToExcel(fileName)}>
          <Submit value="Export Fs" sm />
        </div>
      </div>
    </>
  );
};
export default ExportExcel;
