import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import LoaderTwo from '../Loader/LoaderTwo';
import { useState } from 'react';
import Button from "@mui/material/Button";

export default function DisclosureCreateModal({ setOpen, open, title, inpName, btnName, loading, HandleSubmit }) {

    const [value, setValue] = useState("")
    const [err, setErr] = useState(true);
    const shadow = {
        width: "100%",
        height: "3rem",
        outline: "none",
        borderRadius: "6px",
        outline: "none",
        border: "1px solid #D0D0D0",
        padding: "10px",
        fontSize: "20px",
        color: "var(--clr-font-dark)",
    }
    const shadowErr = {
        width: "100%",
        height: "3rem",
        outline: "none",
        borderRadius: "6px",
        outline: "none",
        border: "1px solid #f00",
        padding: "10px",
        fontSize: "20px",
        color: "var(--clr-font-dark)",
    }

    const SubmitData = () => {
        if (!value) {
            setErr(false);
            return
        }
        HandleSubmit(value);
    }

    const header = {
        fontSize: "2rem",
        fontWeight: "600",
    };

    return (
        <>

            <div>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div style={{ backgroundColor: "#F5F7FA", padding: "50px 30px 30px 30px", width: "500px" }}>
                        <h2 style={header}>{title}</h2>
                        <p style={{ fontSize: "14px", color: "#696f79", margin: "0px 15px 5px 0px" }}>{inpName}</p>
                        <input style={err ? shadow : shadowErr} value={value} onChange={(e) => { setValue(e.target.value); setErr(true); }} />

                        <div className="mt-5 d-flex justify-content-end">
                            <Button
                                className="datebtn font-sans mr-2 text-white custom-common-theme-bg-color font-size-16 radius-6px text-capitalize py-2"
                                onClick={() => setOpen(false)}
                                style={{width: "120px"}}
                            >
                                Cancel
                            </Button>
                            {
                                loading ? <div className='mb-2 mx-2'><LoaderTwo /></div>
                                    : (
                                        <Button
                                            className="datebtn font-sans text-white custom-common-theme-bg-color font-size-16 radius-6px text-capitalize py-2"
                                            style={{
                                                width: "120px",
                                            }}
                                            onClick={SubmitData}
                                        >
                                            {btnName}
                                        </Button>
                                    )
                            }
                        </div>

                    </div>
                </Dialog>
            </div>
        </>
    );
}