import React from 'react';
import Navbarv3 from '../Navbarv3';
import LeaseSidebar from './LeaseSidebar';

function SummaryMovement_v1() {
    return (
        <div>
            <div>
                <Navbarv3 />
            </div>
            <div className="d-flex">
                <div>
                    <LeaseSidebar />
                </div>
                <div className='px-4 w-100 mt-4'>
                    <div className='font-weight-bold'>ROU asset</div>
                    <div>
                        <table class="table table-bordered mt-2">
                            <thead>
                                <tr>
                                    <th scope="col" >ASSETS CLASS</th>
                                    <th scope="col">OPENING BALANCE</th>
                                    <th scope="col">ADDITION</th>
                                    <th scope="col">DELETIONS</th>
                                    <th scope="col">DEPRECIATION</th>
                                    <th scope="col">CLOSING BALANCE</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Total</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Furniture</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Land</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Building</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Vehicle</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*  */}
                    <div className='font-weight-bold'>Lease liability</div>
                    <div>
                        <table class="table table-bordered mt-2">
                            <thead>
                                <tr>
                                    <th scope="col" >ASSETS CLASS</th>
                                    <th scope="col">OPENING BALANCE</th>
                                    <th scope="col">ADDITION</th>
                                    <th scope="col">DELETIONS</th>
                                    <th scope="col">DEPRECIATION</th>
                                    <th scope="col">CLOSING BALANCE</th>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Total</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Furniture</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Land</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Building</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ backgroundColor: "white" }}>Vehicle</th>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                    <td style={{ backgroundColor: "white" }}>@mdo</td>
                                    <td style={{ backgroundColor: "white" }}>Mark</td>
                                    <td style={{ backgroundColor: "white" }}>Otto</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </div>
    );
}
export default SummaryMovement_v1;