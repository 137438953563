import React from 'react'

const CompanySVGComponent = ({color, height, width}) => {
    return (
        <div>
            <svg 
                width={width} 
                height={height} 
                viewBox="0 0 11 15" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M6.09373 2.7551V0.625H7.91771H10.0478V2.7551V14.375H0.889648V3.3801H5.46873H6.09373V2.7551Z" stroke={color} stroke-width="1.25" />
                <rect x="3.32568" y="5.20435" width="1.22449" height="1.22449" fill={color} />
                <rect x="3.32568" y="8.26562" width="1.22449" height="1.22449" fill={color} />
                <rect x="3.32568" y="11.3264" width="1.22449" height="1.22449" fill={color} />
                <rect x="6.38696" y="5.20435" width="1.22449" height="1.22449" fill={color} />
                <rect x="6.38721" y="8.26562" width="1.22449" height="1.22449" fill={color} />
                <rect x="6.38696" y="11.3264" width="1.22449" height="1.22449" fill={color} />
            </svg>

        </div>
    )
}

export default CompanySVGComponent