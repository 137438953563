import React from "react";
import Navbarv3 from "./Navbarv3";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from '@mui/material/Popover';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';





function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
//






const Myworkspace = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [alignment, setAlignment] = React.useState("left");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <div>
      <div>
        <Navbarv3 />
      </div>
      <div className=" h-100 w-100 px-5 mt-4">
        <div className="d-flex justify-content-between ">
          <div style={{ fontSize: "25px", fontWeight: "600" }}>
            My Workspace
          </div>
          <div>
            <Button style={{ backgroundColor: "white", color: "#03565A", border: "2px solid #03565A", textTransform: "none", fontWeight: "500", }}
              variant="contained">
              <span>
                <AddIcon />
              </span>
              Create new workspace
            </Button>
          </div>
        </div>
        <div>
          <Card style={{ width: "22%", borderRadius:"10px"}}>
            <CardContent>
              <div className="d-flex justify-content-between">
                <div style={{ fontWeight: "600" }}>FS_preparation_Trial</div>
                <div>
                  <MoreHorizIcon />
                </div>
              </div>
              <div style={{ fontSize: "12px" }}>Created 10 hours ago</div>
              <div style={{ marginTop: "15px", fontWeight: "600" }}>
                Team Members
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "25px",
                    backgroundColor: "yellow",
                  }}
                >
                  <div style={{ marginTop: "14px", marginLeft: "15px" }}>
                    AK
                  </div>
                </div>
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "25px",
                    backgroundColor: "yellow",
                  }}
                >
                  <div style={{ marginTop: "14px", marginLeft: "15px" }}>
                    AK
                  </div>
                </div>
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "25px",
                    backgroundColor: "yellow",
                  }}
                >
                  <div style={{ marginTop: "14px", marginLeft: "15px" }}>
                    AK
                  </div>
                </div>
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "25px",
                    backgroundColor: "yellow",
                  }}
                >
                  <div style={{ marginTop: "14px", marginLeft: "15px" }}>
                    AK
                  </div>
                </div>
              </div>
              <div className="font-inter"
                style={{ marginTop: "8px", fontWeight: "600", color: "gray" }}
              >
                LINKED REGISTERS
              </div>
              <div
                style={{
                  marginTop: "8px",
                  fontWeight: "600",
                  color: "gray",
                  width: "100px",
                }}
              >
                None
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div style={{ fontSize: "25px", fontWeight: "600" }}> Data registers</div>
          <div>
            <Button style={{ backgroundColor: "white", color: "#03565A", border: "2px solid #03565A", textTransform: "none", fontWeight: "500", }}
              variant="contained">
              <span>
                <AddIcon />
              </span>
              Create new register
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <Card style={{ marginTop: "10px",}} className="tabtoggle">
            <CardContent>
              <Box>
                <Tabs
                  style={{
                    marginTop:"7px",
                    backgroundColor: "#EAECEE",
                    width: "51%",
                    borderRadius: "5px",
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Box sx={{}}>
                    <Tabs
                      sx={{
                        "& button": {
                          borderRadius: 1,
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "500",
                        },
                        "& button.Mui-selected": {
                          backgroundColor: "#03565A",
                          color: "white",
                        },
                      }}
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        sx={{ textTransform: "none", fontWeight: "400" }}
                        label="Sales"
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{ textTransform: "none", fontWeight: "400" }}
                        label="Purchase"
                        {...a11yProps(1)}
                      />
                      <Tab
                        sx={{ textTransform: "none", fontWeight: "400" }}
                        label="Lease"
                        {...a11yProps(2)}
                      />
                      <Tab
                        sx={{ textTransform: "none", fontWeight: "400" }}
                        label="Investment"
                        {...a11yProps(3)}
                      />
                      <Tab
                        sx={{ textTransform: "none", fontWeight: "400" }}
                        label="Far"
                        {...a11yProps(4)}
                      />
                      <Tab
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                          width: "233px",
                        }}
                        label="Receivable and payable"
                        {...a11yProps(5)}
                      />
                    </Tabs>
                  </Box>
                </Tabs>
              </Box>
            </CardContent>
          </Card>
        </div>
        <div>
          <table class="table table-bordered"><thead>
            <tr>
              <th scope="col">REGISTER NAME</th>
              <th scope="col">COMPANY NAME</th>
              <th scope="col">LINKED WORKSPACES</th>
              <th scope="col">LINKing STATUS</th>
              <th scope="col">CURRENT YEAR</th>
              <th scope="col">PREVIOUS YEAR</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
            <tbody>
              <tr>
                <th scope="row" style={{ backgroundColor: "white" }}>Ashis</th>
                <td style={{ backgroundColor: "white" }}>Mark</td>
                <td style={{ backgroundColor: "white" }}>Otto</td>
                <td style={{ backgroundColor: "white" }}>@mdo</td>
                <td style={{ backgroundColor: "white" }}>Mark</td>
                <td style={{ backgroundColor: "white" }}>Otto</td>
                <td style={{ backgroundColor: "white" }}><MoreVertIcon onClick={handleClick} /></td>
              </tr>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className="d-flx justify-content-center" style={{ width: "60px", }}>
                  <div style={{ textAlign: "center", fontSize: "15px" }}>Edit</div>
                  <div style={{ textAlign: "center", fontSize: "15px" }}>Modify</div>
                  <div style={{ textAlign: "center", fontSize: "15px" }}>Delete</div>

                </div>
              </Popover>
              <tr>
                <th scope="row" style={{ backgroundColor: "white" }}> Deepak</th>
                <td style={{ backgroundColor: "white" }}>Mark</td>
                <td style={{ backgroundColor: "white" }}>Otto</td>
                <td style={{ backgroundColor: "white" }}>@mdo</td>
                <td style={{ backgroundColor: "white" }}> Mark</td>
                <td style={{ backgroundColor: "white" }}>Otto</td>
                <td style={{ backgroundColor: "white" }}><MoreVertIcon onClick={handleClick} /></td>
              </tr>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className="d-flx justify-content-center" style={{ width: "60px", }}>
                  <div style={{ textAlign: "center", fontSize: "15px" }}>Edit</div>
                  <div style={{ textAlign: "center", fontSize: "15px" }}>Modify</div>
                  <div style={{ textAlign: "center", fontSize: "15px" }}>Delete</div>

                </div>
              </Popover>
              <tr>
                <th scope="row" style={{ backgroundColor: "white" }}> Natia</th>
                <td style={{ backgroundColor: "white" }}>Mark</td>
                <td style={{ backgroundColor: "white" }}> Otto</td>
                <td style={{ backgroundColor: "white" }}>@mdo</td>
                <td style={{ backgroundColor: "white" }}>Mark</td>
                <td style={{ backgroundColor: "white" }}>Otto</td>
                <td style={{ backgroundColor: "white" }}><MoreVertIcon onClick={handleClick} /></td>
              </tr>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className="d-flx justify-content-center" style={{ width: "60px", }}>
                  <div style={{ textAlign: "center", fontSize: "15px" }}>Edit</div>
                  <div style={{ textAlign: "center", fontSize: "15px" }}>Modify</div>
                  <div style={{ textAlign: "center", fontSize: "15px" }}>Delete</div>
                </div>
              </Popover>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
};

export default Myworkspace;
