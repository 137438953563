import React from "react";
import AddIcon from '@mui/icons-material/Add';
import { Modal, Box, TextField, InputLabel } from '@mui/material';
import Card from '@mui/material/Card';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CompanySVGComponent from "../../Components/Common/SVGComponents/CompanySVGComponent";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Button from '@mui/material/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import dayjs from "dayjs";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useSelector } from "react-redux";
import "./../../Bookclosure/Styles/Bookclosure.css"
import { customStyles } from "./AddTaskModal";

const LeaseModal = ({
    isLeaseTaskModal,
    handleTaskModalClose,
    addTaskForm,
    setAddTaskForm,
    UpdateTask,
    SaveTask,
    userData,
}) => {
    const animatedComponents = makeAnimated();
    const { 
        assignOptions, 
        approveOptions, 
    } = useSelector((state) => state.BookClosureReducer);

    const handleSelectChange = (selectedOptions, { name }) => {
        if(name === "assigneeID"){
            setAddTaskForm(prev => ({...prev, selectedAssignId: selectedOptions}))
            setAddTaskForm(prev => ({...prev, [name]: selectedOptions.map(option => option.value)}))
        }else { 
            setAddTaskForm(prev => ({...prev, selectedApproverId: selectedOptions}))
            setAddTaskForm(prev => ({...prev, [name]: selectedOptions.value}))
        }
    };

    return (
        <Modal open={isLeaseTaskModal} onClose={() => handleTaskModalClose()}>
            <Box sx={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                width: '90%', bgcolor: 'background.paper', boxShadow: 24, p: 1, backgroundColor: "#F0DBC8", borderRadius: "10px",

            }}>
                <div>
                    <div className='d-flex justify-content-between p-2 pb-0'>
                        <p className='mt-0 font-size-24 custom-font-600'>Lease Template</p>
                        <p className='mt-0 font-size-16 custom-font-500' onClick={() => handleTaskModalClose()} >Close</p>
                    </div>

                    <Card className="p-3" style={{ overflowY: "auto", height: "600px" }}>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex flex-column'>
                                <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-secondary'>Task Name <span className='text-danger'>*</span></h3>
                                <input
                                    className='custom-bg-gray-300 radius-6px'
                                    placeholder="Enter task name"
                                    type={"text"}
                                    value={addTaskForm.name}
                                    onChange={(e) => setAddTaskForm(prev => ({ ...prev, name: e.target.value }))}
                                />
                            </div>
                            <div className='d-flex justify-content-center align-items-center ml-1'>
                                <div className='link-icon'>
                                    <CalendarMonthIcon className='mt-2 ml-2 calndr' style={{ width: "20px", height: "20px" }} />
                                </div>
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                        <DemoContainer components={['DatePicker', 'MobileDatePicker']}   >
                                            <MobileDatePicker
                                                className="datepicker-color"
                                                value={dayjs(addTaskForm.dueDate)}
                                                onChange={(value) => { setAddTaskForm(prev => ({ ...prev, dueDate: value })) }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                                <div className='link-iconn' >
                                    <ExpandMoreIcon className='mt-2 arrow-icon' />
                                </div>
                            </div>
                        </div>
                        <div className='mt-4 d-flex flex-column'>
                            <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-secondary'>Task Description  <span className='text-danger'>*</span></h3>
                            <textarea
                                rows={3}
                                style={{ resize: "none" }}
                                className='custom-bg-gray-300 radius-6px w-50 p-2 mt-1 '
                                placeholder="Enter task description"
                                value={addTaskForm.description}
                                onChange={(e) => setAddTaskForm(prev => ({ ...prev, description: e.target.value }))}
                            />
                        </div>
                        <div className='d-flex mt-4'>
                            <div className='d-flex flex-column w-25'>
                                <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-secondary'>Assignee <span className='text-danger'>*</span></h3>
                                <Select
                                    isMulti
                                    placeholder="Assign..."
                                    name="assigneeID"
                                    value={addTaskForm.selectedAssignId}
                                    onChange={handleSelectChange}
                                    options={assignOptions}
                                    className="basic-multi-select w-100 mt-1 custom-bg-gray-300"
                                    classNamePrefix="select"
                                    components={animatedComponents}
                                    closeMenuOnSelect={false}
                                    styles={customStyles}
                                />
                            </div>
                            <div className='d-flex flex-column ml-2 w-25'>
                                <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-secondary'>Approver <span className='text-danger'>*</span></h3>
                                <Select
                                    // isMulti'
                                    placeholder="Approve..."
                                    name="approverID"
                                    value={addTaskForm.selectedApproverId}
                                    onChange={handleSelectChange}
                                    options={approveOptions}
                                    className="basic-multi-select w-100 mt-1 custom-bg-gray-300"
                                    classNamePrefix="select"
                                    components={animatedComponents}
                                    // closeMenuOnSelect={false}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                        <div className="mt-3">
                            <label className="mt-0 mb-0 ml-1 custom-font-500 font-size-14 text-secondary">Select Imported Registers </label>
                            <div className='d-flex ' style={{ gap: "10px", marginTop: "-4px" }}>
                                <div
                                    draggable
                                    className="d-flex mt-2 p-2  cursor-pointer  radius-8px mt-2"
                                    style={{ width: "300px", backgroundColor: "#ECF0F1" }}
                                >
                                    <input
                                        className="m-2 cursor-pointer mt-2 "
                                        type="checkbox"
                                        style={{ height: "22px", width: "15px" }}
                                    />
                                    <div>
                                        <p className="m-0 p-0 font-size-14 text-black font-inter font-weight-bold">Register 1</p>
                                        <p className="m-0 p-0 font-size-12 text-secondary d-flex justify-content-center align-items-center custom-font-500 font-inter">
                                            <CompanySVGComponent color={"#ABA9A9"} width={11} height={15} />
                                            <span className='ml-1' >Company Name</span>
                                            <CalendarMonthIcon className="ml-1" style={{ height: "15px" }} />
                                            <span className="ml-1">
                                                <p className='mt-0 mb-0' style={{ fontSize: "12px" }}>22 may 2024</p>
                                            </span>
                                        </p>
                                    </div>
                                    <div className='ml-2 mt-2'>
                                        <span><MoreVertIcon style={{ color: "#808080" }} /></span>
                                    </div>
                                </div>
                                {/*  */}
                                <div
                                    draggable
                                    className="d-flex mt-2 p-2  cursor-pointer  radius-8px mt-2"
                                    style={{ width: "300px", backgroundColor: "#ECF0F1" }}
                                >
                                    <input
                                        className="m-2 cursor-pointer mt-2 "
                                        type="checkbox"
                                        style={{ height: "22px", width: "15px" }}
                                    />
                                    <div>
                                        <p className="m-0 p-0 font-size-14 text-black font-inter font-weight-bold">Register 1</p>
                                        <p className="m-0 p-0 font-size-12 text-secondary d-flex justify-content-center align-items-center custom-font-500 font-inter">
                                            <CompanySVGComponent color={"#ABA9A9"} width={11} height={15} />
                                            <span className='ml-1' >Company Name</span>
                                            <CalendarMonthIcon className="ml-1" style={{ height: "15px" }} />
                                            <span className="ml-1">
                                                <p className='mt-0 mb-0' style={{ fontSize: "12px" }}>22 may 2024</p>
                                            </span>
                                        </p>
                                    </div>
                                    <div className='ml-2 mt-2'>
                                        <span><MoreVertIcon style={{ color: "#808080" }} /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <label className="mt-0 mb-0 ml-1 custom-font-500 font-size-14 text-secondary">ROU Asset Reconciliation </label>
                                <div className="row mr-top">
                                    <div className="col-3">
                                        <div className="w-100" >
                                            <Card className="mt-3 w-100 radius-12px " style={{ border: "2px solid #D6D6D6", }}>
                                                <div className='w-100' style={{ backgroundColor: "#F0DBC8", }}>
                                                    <p className="font-size-16 custom-font-600 text-capitalize font-sans m-0 p-3" >Ledger Codes</p>
                                                </div>
                                                <div className='p-3'>
                                                    <div className='d-flex  bg-white cursor-pointer border shadow-btn' >
                                                        <div className='d-flex' style={{ gap: "5px" }}>
                                                            <span>
                                                                <DragIndicatorIcon className='mt-2' style={{ height: "30px", width: "30px", color: "#DFE2E7" }} />
                                                            </span>
                                                            <p className='mt-0 mb-0 custom-font-500 font-size-14'>Zero to One</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className='d-flex  bg-white cursor-pointer border shadow-btn'
                                                    >
                                                        <div className='d-flex' style={{ gap: "5px" }}>
                                                            <span>
                                                                <DragIndicatorIcon className='mt-2' style={{ height: "30px", width: "30px", color: "#DFE2E7" }} />
                                                            </span>
                                                            <p className='mt-0 mb-0 custom-font-500 font-size-14'>Zero to One</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className='d-flex  bg-white cursor-pointer border shadow-btn mb-2'
                                                    >
                                                        <div className='d-flex' style={{ gap: "5px" }}>
                                                            <span>
                                                                <DragIndicatorIcon className='mt-2' style={{ height: "30px", width: "30px", color: "#DFE2E7" }} />
                                                            </span>
                                                            <p className='mt-0 mb-0 custom-font-500 font-size-14'>Zero to One</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="w-100" >
                                            <Card className="mt-3 w-100 radius-12px" style={{ border: "2px solid #D6D6D6" }}>
                                                <div style={{ overflowX: "auto", maxWidth: "100%", display: "block" }}>
                                                    <table className="table" style={{ minWidth: "800px" }}>
                                                        <thead style={{ backgroundColor: "#F8F9F9" }}>
                                                            <tr>
                                                                <th scope="col" className="text-uppercase text-left custom-font-600 font-size-14 text-secondary font-inter w-25">Asset category</th>
                                                                <th scope="col" className="text-uppercase text-left custom-font-600 font-size-14 text-secondary font-inter w-25">Ledger Value</th>
                                                                <th scope="col" className="text-uppercase text-left custom-font-600 font-size-14 text-secondary font-inter w-25">Register Value</th>
                                                                <th scope="col" className="text-uppercase text-left custom-font-600 font-size-14 text-secondary font-inter w-50">Difference</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row" className="w-25" rowSpan={3}>
                                                                    <div className="bg-white">
                                                                        <TextField id="outlined-basic" variant="outlined" placeholder='Type...' />
                                                                    </div>
                                                                </th>
                                                                <td rowSpan={3} className="w-25 bg-white">
                                                                    <div className='d-flex bg-white cursor-pointer border shadow-btn' style={{ gap: "5px" }}>
                                                                        <DragIndicatorIcon className='mt-2' style={{ height: "30px", width: "30px", color: "#DFE2E7" }} />
                                                                        <p className='mt-0 mb-0 custom-font-500 font-size-14'>Zero to One</p>
                                                                    </div>
                                                                    <div>
                                                                        <Button style={{ borderTop: "none", border: "2px solid #DFE2E7", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }} variant="outlined" className="custom-font-500 font-size-14 text-dark">₹ 100,000</Button>
                                                                    </div>
                                                                </td>
                                                                <td rowSpan={3} className="w-25 bg-white">
                                                                    <div className='d-flex bg-white cursor-pointer border shadow-btn' style={{ gap: "5px" }}>
                                                                        <DragIndicatorIcon className='mt-2' style={{ height: "30px", width: "30px", color: "#DFE2E7" }} />
                                                                        <p className='mt-0 mb-0 custom-font-500 font-size-14'>Zero to One</p>
                                                                    </div>
                                                                    <div>
                                                                        <Button style={{ borderTop: "none", border: "2px solid #DFE2E7", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }} variant="outlined" className="custom-font-500 font-size-14 text-dark">₹ 100,000</Button>
                                                                    </div>
                                                                </td>
                                                                <td rowSpan={3} className="w-25 bg-white">
                                                                    <div>
                                                                        <p className='mt-0 mb-0 custom-font-500 font-size-14'>₹ 100,000</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="w-100" >
                                            <Card className="mt-3 w-100 radius-12px " style={{ border: "2px solid #D6D6D6", }}>
                                                <div className='w-100' style={{ backgroundColor: "#F0DBC8", }}>
                                                    <p className="font-size-16 custom-font-600 text-capitalize font-sans m-0 p-3" >Asset Tag</p>
                                                </div>
                                                <div className='p-3'>
                                                    <div
                                                        className='d-flex  bg-white cursor-pointer border shadow-btn'
                                                    >
                                                        <div className='d-flex' style={{ gap: "5px" }}>
                                                            <span>
                                                                <DragIndicatorIcon className='mt-2' style={{ height: "30px", width: "30px", color: "#DFE2E7" }} />
                                                            </span>
                                                            <p className='mt-0 mb-0 custom-font-500 font-size-14'>Zero to One</p>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex  bg-white cursor-pointer border shadow-btn'
                                                    >
                                                        <div className='d-flex' style={{ gap: "5px" }}>
                                                            <span>
                                                                <DragIndicatorIcon className='mt-2' style={{ height: "30px", width: "30px", color: "#DFE2E7" }} />
                                                            </span>
                                                            <p className='mt-0 mb-0 custom-font-500 font-size-14'>Zero to One</p>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex  bg-white cursor-pointer border shadow-btn mb-2'
                                                    >
                                                        <div className='d-flex' style={{ gap: "5px" }}>
                                                            <span>
                                                                <DragIndicatorIcon className='mt-2' style={{ height: "30px", width: "30px", color: "#DFE2E7" }} />
                                                            </span>
                                                            <p className='mt-0 mb-0 custom-font-500 font-size-14'>Zero to One</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3 w-50'>
                                    <Card style={{ border: "1px solid #D6D6D6", borderRadius: "6px" }}>
                                        <table className="table" style={{ border: 0 }} >
                                            <thead style={{ backgroundColor: "#F9F9F9" }}>
                                                <tr>
                                                    <th scope="col" className="text-left" style={{ border: 0 }}>
                                                        <p className='mt-0 mb-0 custom-font-500 font-size-14 text-secondary'>As per Trail balance</p>
                                                        <p className='mt-0 mb-0 custom-font-500 font-size-14 text-secondary'>Impact on ledger from other modules</p>
                                                    </th>
                                                    <th scope="col" className="text-center" style={{ border: 0 }}>
                                                        <p className='mt-0 mb-0 custom-font-500 font-size-14 text-secondary'>654,098</p>
                                                        <p className='mt-0 mb-0 custom-font-500 font-size-14 text-secondary'>654,098</p>
                                                    </th>
                                                    <th scope="col" className="text-center" style={{ border: 0 }}>
                                                        <p className='mt-0 mb-0 custom-font-600 font-size-14 custom-common-theme-text-color '>View Matrix</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="text-left custom-font-500 font-size-14 text-secondary" style={{ border: 0 }} >Revised ledger balance</th>
                                                    <td className="text-start custom-font-500 font-size-14 text-secondary" style={{ border: 0 }}>Mark</td>
                                                    <td className="text-start custom-font-500 font-size-14 text-secondary" style={{ border: 0 }}>Mark</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Card>
                                </div>
                                <div className='w-50 mt-3' style={{ height: "80px", border: "1px solid #D6D6D6", borderRadius: "8px" }}>
                                    <div className='d-flex justify-content-between p-4 mt-1 w-75'>
                                        <p className='mt-0 mb-0 font-size-14 custom-font-500 text-secondary'>
                                            As per Register
                                        </p>
                                        <p className='mt-0 mb-0 font-size-14 custom-font-500 text-secondary'>
                                            754,984
                                        </p>
                                    </div>
                                </div>
                                <div className='w-50 mt-3' style={{ height: "80px", border: "1px solid #D6D6D6", borderRadius: "8px" }}>
                                    <div className='d-flex justify-content-between p-4 mt-1 w-75'>
                                        <p className='mt-0 mb-0 font-size-14 custom-font-500 text-secondary'>
                                            Difference
                                        </p>
                                        <p className='mt-0 mb-0 font-size-14 custom-font-500 text-secondary'>
                                            -600
                                        </p>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <label className="mt-1 mb-2 ml-1 custom-font-500 font-size-14 text-secondary">Workings and Remarks </label>
                                    <Card className='w-100 radius-6px' style={{ border: "2px solid #F2F3F4", }}>
                                        <div className='w-100 p-1'>
                                            <CKEditor
                                                // disabled={isTaskAction}
                                                editor={ClassicEditor}
                                                data={addTaskForm.workingsAndRemarks || ""}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setAddTaskForm(prev => ({...prev, workingsAndRemarks: data}));
                                                }}
                                                config={{
                                                    placeholder: 'Type......',
                                                    // isReadOnly: isTaskAction
                                                }}
                                            />
                                        </div>
                                    </Card>
                                </div>
                                {/*  */}
                                <div className="mt-3">
                                    <Card style={{ borderRadius: "8px" }}>
                                        <div className="p-1 " style={{ borderRadius: "8px" }}>
                                            <table class="table rounded" >
                                                <thead style={{ backgroundColor: "#F2F3F4 " }}>
                                                    <tr>
                                                        <th class="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Date</th>
                                                        <th class="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Ledger Code</th>
                                                        <th class="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Ledger Name</th>
                                                        <th class="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Amount</th>
                                                        <th class="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Remarks</th>
                                                        <th class="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Export to FSCP Entries</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td></td>
                                                        <td ></td>
                                                        <td></td>
                                                        <td rowSpan={2}></td>
                                                        <td rowSpan={2}></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row" colSpan={4}>
                                                            <div className="d-flex align-items-center justify-content-center" style={{ gap: "6px" }}>
                                                                <span><AddIcon style={{ width: "16px", height: "16px" }} /></span>
                                                                <p className="mt-1 mb-0 font-size-12 custom-font-500 text-secondary">Add task</p>
                                                            </div>
                                                        </th>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card>
                                </div>
                                <div className='mt-3'>
                                    <label className="mt-1 mb-2 ml-1 custom-font-500 font-size-14 text-secondary">Comments </label>
                                    <Card className='w-100 radius-6px' style={{ border: "2px solid #F2F3F4", }}>
                                        <div className='w-100'>
                                            <div className='p-1' style={{ width: '100%', maxWidth: '100%', }}>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    // onChange={handleChange}
                                                    config={{
                                                        placeholder: 'Type......',

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-center my-4">
                            <Button
                                onClick={SaveTask}
                                variant="outlined"
                                className={`font-size-16 custom-font-600 text-capitalize font-sans radius-6px w-25
                                    ${userData?.responsibilities === "approver" ? "text-danger border-danger" : "custom-common-theme-text-color primary-border"}
                                `}
                            >
                                {userData?.responsibilities === "approver" ? "Return" : "Save"} 
                            </Button>
                            <Button
                                onClick={UpdateTask}
                                variant="contained"
                                className={`text-white font-size-16 custom-font-600 text-capitalize font-sans radius-6px ml-2 w-25
                                    ${userData?.responsibilities === "approver" ? "bg-success" : "custom-common-theme-bg-color"}
                                `}
                            >
                                {userData?.responsibilities === "approver" ? "Approve" : "Save and submit"}
                            </Button>
                        </div>
                    </Card>

                </div>
            </Box>
        </Modal>

    );
};

export default LeaseModal;
