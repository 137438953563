import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Submit } from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../services/socket/socket";
import swal from "sweetalert";
import { addNoteGroup } from "../../services/api/apiHandler";
import { fetchNotesData } from "../../redux/actions/actionCN";

const screen = {
  width: "100%",
  height: "100%",
  position: "fixed",
  zIndex: "999",
  backgroundColor: "#00000036",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
};
const container = {
  // width:'30%',
  minHeight: "20vh",
  borderRadius: "10px",
  // maxHeight:'90vh',
  padding: "2rem 4rem",

  zIndex: "999",
  backgroundColor: "#f5f7fa",
};

const head = {
  display: "flex",
};

const header = {
  fontSize: "2rem",
  fontWeight: "600",
};
const para = {
  fontSize: "14px",
  fontWeight: "400",
  marginTop: "0px",
  color: "#696F79",
};

const input = {
  // width: "20vw",
  height: "3rem",
  borderRadius: "6px",
  outline: "none",
  border: "1px solid #D0D0D0",
  padding: "10px",
  fontSize: "20px",
  margin: "15px",
  color: "var(--clr-font-dark)",
};
const footSelect = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  marginTop: "1.2rem",
};

const presetHeader = {
  fontSize: "14px",
  color: "var(--clr-font-mid)",
  margin: "0 15px 5px 0px",
  // width:'1px'
};

const grid = {
  display: "grid",
  gridTemplateColumns: "48% 48% ",
  gridColumnGap: "5%",
};

const AddNoteGroup = (props) => {

  const dispatch = useDispatch();
  const [field, setField] = useState({
    note_name: ''
  });
  // const [disableBtn, setDisableBtn] = useState(true);
  const project_id = localStorage.getItem("project_id");
  const { name, type } = useSelector((state)=> state.selectedDragReducer.AddNoteFsGroup);
  const R_tb_id = useSelector((initialState) => initialState.reducer.tb_id);
  const LOCAL_tb_id = localStorage.getItem("tb_id");
  let tb_id = R_tb_id ? R_tb_id : LOCAL_tb_id;  

  function handleClose(e) {
    if (e.target.classList.contains("screenDiv")) {
      props.setAddNoteGrp(false);
    }
  }

  function handleChange(e) {
    setField((prevValue)=>{
        return {
            ...prevValue,
            [e.target.name]: e.target.value
        }
    });
  }

  const handleSubmit = async () => {
    try{
        if(!field.note_name || !name || !type){
            return swal(`Please provide the correct input.`,{
                icon: 'warning'
            })
        }
        const newNoteGroup = {
            fs_grp: name,
            type: type,
            note_grp: {
                "notes_grp": field.note_name,
                "disclosures": false,
                "sub_grp": [
                ]
            }
        }
        await addNoteGroup(project_id, { newNoteGroup });
        socket.emit("request-mapped-grouping", {project_id, tb_id});
        socket.emit("request-balance-sheet", {project_id, tb_id})
        socket.emit("request-pnl-statement", {project_id, tb_id});
        dispatch(fetchNotesData(project_id));
        swal(`Notes data updated successfully`, {
            icon: 'success'
        })
        props.setAddNoteGrp(false);
    }catch(err){
        swal(`${err?.response?.data?.message || err.message}`,{
            icon: 'error'
        })
    }
  }

  return (
    <motion.div
      style={screen}
      className="screenDiv"
      onClick={handleClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div
        style={container}
        className="col-md-5 col-sm-8"
        initial={{ y: "-5vh" }}
        animate={{ y: 0 }}
      >
        <div style={head}>
          <div>
            <h2 style={header}>Create New Note Group</h2>
          </div>
          <div style={{ paddingTop: "13px", marginLeft: "auto" }}>
            <h5
              style={{
                color: "var(--clr-accent)",
                fontSize: "1rem",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => {
                props.setAddNoteGrp(false);
              }}
            >
              close
            </h5>
          </div>
        </div>
        <div style={grid}>
            <p style={presetHeader}>
              Add new Note Group to { name }<span style={{ color: "red" }}>*</span>
            </p>
        </div>
        <div>
          <div
            style={{ padding: "0 0", margin: "10px 0 ", display: "flex" }}
            className="presetName"
          >
            <input
              style={input}
              type="text"
              name='note_name'
              placeholder="Note Name"
              value={field.note_name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div style={footSelect}>
          <div
            onClick={() => {
                handleSubmit();
            }}
            >
            <Submit value="Create New Input" sm disable={!name || !type || !field.note_name} />
          </div>
        </div>
        <div></div>
      </motion.div>
    </motion.div>
  );
};

export default AddNoteGroup;
