import { getConsoleBsData } from "../../Console/Component/services/apiHandler";


export const fetchBSData = (project_ids, browse_fs_ids, currentCompProjectId) => {
    return dispatch => {
        dispatch({type:"BS_ACTION_START"})
        getConsoleBsData(project_ids, browse_fs_ids, currentCompProjectId).then((result)=>{
            dispatch({
                type: "BS_FETCH_DATA",
                payload: {data: result.data, set_payload: JSON.stringify({project_ids, browse_fs_ids, currentCompProjectId})}
            })
        }).catch((err)=>{
            dispatch({
                type:"BS_FETCH_DATA_FAILED",
                payload: err?.response?.data?.error || "something went wrong"
            })
        })        
    }
}