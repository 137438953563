import React from "react";
// import "../../Workspace2/sidebar.css/";
import logov2 from "../../../assets/companylogo.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const LeaseSidebar2 = () => {
  return (
    <>
      <div className="sidebar_v2">
        <div>
          <img className="img-v2" src={logov2} alt="logo" />
        </div>
        {/* <div className="workspace-v2">
          <div className="circle-v2 ">
            <FiberManualRecordIcon style={{ height: "10px", width: "10px" }} />
          </div>
          <div className="layout-v2">Getting started</div>
        </div> */}
        {/* <div className="vl-v2 "></div> */}
        {/* <div style={{ marginTop: "5px" }} className="vl-v2  "></div> */}

        <div className="workspace-v2">
          <div className="circle-v2 ">1</div>
          <div className="layout-v2">Basic lease details</div>
        </div>
        <div className="vl-v2 "></div>
        <div style={{ marginTop: "7px" }} className="vl-v2  "></div>
        <div className="workspace-v2">
          <div className="circle-v2 ">2</div>
          <div className="layout-v2">Financial parameters</div>
        </div>
        {/* <div className="vl-v2 "></div>
        <div style={{ marginTop: "7px" }} className="vl-v2"></div>
        <div className="workspace-v2">
          <div className="circle-v2 ">4</div>
          <div className="layout-v2">Import registers</div>
        </div> */}
        {/* <div className="vl-v2 "></div>
        <div style={{ marginTop: "7px" }} className="vl-v2"></div>
        <div className="workspace-v2">
          <div className="circle-v2 ">5</div>
          <div className="layout-v2">Import presets</div>
        </div> */}
        {/* <div className="vl-v2 "></div>
        <div style={{ marginTop: "7px" }} className="vl-v2"></div>
        <div className="workspace-v2">
          <div className="circle-v2 ">6</div>
          <div className="layout-v2">Invite team members</div>
        </div> */}
      </div>
    </>
  );
};

export default LeaseSidebar2;
