import React, { useState } from 'react'
import Button from '@mui/material/Button';
import leftarrow from "../../assets/Image/leftarrow.png"
import { useHistory } from "react-router";
import { useParams } from "react-router";
import { getEntriesExport } from '../Services/ApiHandler';
import swal from "sweetalert";
import { toast } from 'react-toastify';

const BookcluosureNav = ({
    bookClosureId
}) => {
    const history = useHistory();

    const { workspaceId, companyId } = useParams();

    const [loading, setLoading] = useState(false)

    const ExportToExcel = () => {
        const format = "excel"
        setLoading(true);
        getEntriesExport(bookClosureId, companyId, format).then((response) => {
            toast.success("Export to excel successfully.")
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'entris_data.xlsx');
            document.body.appendChild(link);

            link.click();

            window.URL.revokeObjectURL(url);
            link.remove();

        }).catch((error) => {
            swal("Error", `Export Error: ${error.response?.data?.error}`, 'error');
        }).finally(() => setLoading(false))
    }

    return (
        <>
            <div className='custom-common-theme-bg-color' style={{ height: "70px" }}>

                <div className='d-flex justify-content-between px-3 py-2'>
                    <button
                        className='d-flex align-items-center border-0 bg-color-inherit'
                        onClick={() => history.push(`/bookclosure/${workspaceId}`)}
                    >
                        <img src={leftarrow} />
                        <p className='mt-0 mb-0 font-size-24 custom-font-600 text-white ml-1'>Back</p>
                    </button>
                    <div style={{ marginTop: "2px" }}>
                        <Button
                            variant="contained"
                            className='custom-common-theme-bg-color text-capitalize font-size-16 custom-font-600 font-sans ext-white'
                            style={{ border: "1px solid white", width: "200px", height: "50px" }}
                            onClick={() => history.push(`/matrix/${workspaceId}/${companyId}`)}
                        >
                            View Matrix
                        </Button>
                        <Button
                            variant="outlined"
                            className='bg-white primary-border ml-2 font-size-16 custom-font-600 text-capitalize custom-common-theme-text-color font-sans'
                            style={{ width: "200px", height: "50px" }}
                            onClick={ExportToExcel}
                        >
                            {loading ? "Generating file ...." : "Export to Excel"}
                        </Button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BookcluosureNav