import React from "react";
import Sidbarv2 from "./Sidbarv2";
import Navbarv3 from "./Navbarv3";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CardContent from "@mui/material/CardContent";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import "./../Workspace-v2/sidebarv2.css";

const WorkspaceLayoutv2 = () => {
  return (
    <>
      <div>
        <div>
          <Navbarv3 />
        </div>
        <div className="d-flex">
          <div>
            <Sidbarv2 />
          </div>
          <div className="w-100 h-100 px-5 mt-5 ">
          <div className="d-flex justify-content-between w-100 ">
            <div style={{ width: "50%",marginTop:"60px" }}>
              <div style={{ fontSize: "25px", fontWeight: "600" }}>
                Welcome to your WorkSpace !
              </div>
              <div style={{ marginTop: "6px", fontSize: "14px", width: "90%" }}>
                Use this space to welcome team member collaborate and simplify
                your financial task.import registers from our centralized
                repository and effortlessly complete your reporting process.
              </div>
              <div style={{ marginTop: "25px" }}>
                <Button
                  style={{
                    backgroundColor: "#03565A",
                    color: "white",
                    textTransform: "none",
                    width: "220px",
                    height: "50px",
                  }}
                >
                  <span>
                    <AddIcon />
                  </span>
                  Create register
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    textTransform: "none",
                    marginLeft: "20px",
                    width: "220px",
                    height: "50px",
                    border: "2px solid #03565A",
                  }}
                >
                  <span>
                    <CloudUploadIcon />
                  </span>
                  <span style={{ marginLeft: "10px" }}> Uploade register</span>
                </Button>
              </div>
            </div>

            {/* <div> */}
            <div> 
              <Card className="w-100 h-100 rounded">
                <CardContent>
                  <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                    <div style={{ fontSize: "17px", fontWeight: "550" }}>
                      Here' s what's next{" "}
                      <span>
                        <ArrowDownwardIcon />
                      </span>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      <div className="workspace_v3">
                        <div
                          className="circle_V3 "
                          style={{ backgroundColor: "#03565A" }}
                        >
                          <span>
                            <CheckIcon style={{ color: "white" }} />
                          </span>
                        </div>
                        <div className="Layout_v3">Create Workspace </div>
                      </div>
                      <div className="vl_V3 "></div>
                      <div
                        style={{ marginTop: "5px" }}
                        className="vl_V3  "
                      ></div>
                      <div className="workspace_v3">
                        <div
                          className="circle_V3  "
                          style={{ backgroundColor: "#03565A" }}
                        >
                          <span>
                            <CheckIcon style={{ color: "white" }} />
                          </span>
                        </div>
                        <div className="Layout_v3">Invite team members</div>
                      </div>
                      <div className="vl_V3 "></div>
                      <div
                        style={{ marginTop: "5px" }}
                        className="vl_V3  "
                      ></div>
                      <div className="workspace_v3">
                        <div className="circle_V3  ">2</div>
                        <div className="Layout_v3">
                          Create / upload your register
                        </div>
                      </div>
                      <div className="vl_V3 "></div>
                      <div
                        style={{ marginTop: "5px" }}
                        className="vl_V3  "
                      ></div>
                      <div className="workspace_v3">
                        <div className="circle_V3  ">3</div>
                        <div className="Layout_v3">
                          Get Started on your financial tasks
                        </div>
                      </div>
                      <div className="vl_V3 "></div>
                      <div
                        style={{ marginTop: "5px" }}
                        className="vl_V3  "
                      ></div>
                      <div className="workspace_v3">
                        <div className="circle_V3  ">4</div>
                        <div className="Layout_v3">Export your report</div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
              {/* </div> */}
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspaceLayoutv2;
