import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Navbar } from "../../../Components/Navbar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  BrowsFs,
  getCompany,
  GetConsoleEntryData,
  GetProjectsBasedOnUserId,
  addAllOtherOptions,
  getAllOtherOptions,
  getAllOtherDetails,
  addOtherDetails,
  deleteAllOtherDetails,
  addAllOtherDetails,
} from "../ApiServices/apiHandler";
import BrowsFsModal from "./Modals/BrowsFSModal";
import { toast } from "react-toastify";
import ConsoleEntryModal from "./Modals/ConsoleEntryModal";
import AddOtherDetailsModal from "./Modals/AddOtherDetailsModal";
import AddNewOptionsModal from "./Modals/AddNewOptionsModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDetailsData } from "../../../redux/actions/InvestmentRegister/actionAllOthersData";
import { useParams } from "react-router";

const companyRelationShip = [
  "Subsidiary Company",
  "Associate Company",
  "Investment",
];

function AdditionInvestRegister() {
  const location = useLocation();
  let a = location.pathname;
  let b = a.split("/");
  let history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user_id = localStorage.getItem("user_id");

  const [allCompany, setAllCompany] = useState([]);
  const [investdata, setInvestData] = useState({
    investment_company_id:"",
    investment_company_code:"",
    no_of_shares:"",
    total_no_shares:"",
    holdings: 0,  
    non_controlling_interest:"",
    nature_of_relationship:"Investment",
    value_of_investment:null,
    date_Of_investment:null,
  });
console.log("hello world",investdata);
  const [details, setDetails] = useState({
    companyCode: "",
    browseFsCompanyId: "",
    details_id: "",
  });
  const [isBrowsModalOpen, setIsBrowsModalOpen] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [isConsoleEntryOpen, setIsConsoleEntryOpen] = useState(false);
  const [allConsoleEntry, setAllConsoleEntry] = useState([]);
  const [isOtherInputOpen, setIsOtherInputOpen] = useState(false);
  const [allOtherDetailsInputs, setOtherDetailsInputs] = useState([]);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [otherOptions, setOtherOptions] = useState([]);
  const [options, setOptions] = useState("");
  const [consoleInvestmentValue, setConsoleInvestmentValue] = useState(0);
  const [allDetailsId, setAllDetailsId] = useState("");
  const [showFirstTable, setShowFirstTable] = useState(true);

  useEffect(() => {
    GetCompany();
  }, []);

  const all_othersData = useSelector((state) => state.AllOtherDetailsReducer);

  function handleChangeDetails(e, field, i) {
    const { value } = e.target;
    if (value === "Add_new") {
      setIsOptionsModalOpen(true);
    } else {
      const newArray = allOtherDetailsInputs.map((item, index) => {
        if (i === index) {
          return { ...item, [field]: value };
        }
        return item;
      });
      setOtherDetailsInputs(newArray);
    }
  }

  const GetCompany = async () => {
    const res = await getCompany();
    if (res.status === 200) {
      setAllCompany(res.data.getCompany);
    }
  };

  // TODO :- New APIs
  const handleSubmitFs = () => {
    const data = {
      project_id: details.browseFsCompanyId,
      details_id: details.details_id,
    };

    BrowsFs(data)
      .then((response) => {
        toast.success("Brows FS mapped successfully.");
        setIsBrowsModalOpen(false);
      })
      .catch((error) => {
        console.log("browsfs error", error);
      });
  };


  const GetAllProjects = async () => {
    GetProjectsBasedOnUserId(user_id)
      .then((response) => {
        setIsBrowsModalOpen(true);
        setAllProjects(response.data?.result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllConsoleEntry = async (ir) => {
    const data = {
      non_controlling_interest: ir.non_controlling_interest,
      investment_value: -ir.value_of_investment,
    };

    GetConsoleEntryData(ir._id, data)
      .then((response) => {
        const result = response.data?.result;
        setIsConsoleEntryOpen(true);
        setAllConsoleEntry(result?.console_entry);

        setInvestData((prev) =>
          prev.map((item) => {
            if (item._id === result._id) {
              return { ...item, console_entry: result?.console_entry };
            }
            return item;
          })
        );
      })
      .catch((error) => {
        swal(
          "Error",
          error?.response?.data?.error || "something went wrong",
          "error"
        );
      });
  };

  const HandleChange = (e) => {
    const { name, value } = e.target;
  
    // Copy the current state
    setInvestData((prevData) => {
      // Make a copy of prevData (use destructuring)
      let updatedItem = { ...prevData, [name]: value };
  
      // Update investment_company_code if investment_company_id changes
      if (name === "investment_company_id") {
        const resultCompany = allCompany.find((det) => det?._id === value);
        updatedItem.investment_company_code = resultCompany?.company_code ?? "";
      }
  
      // Update holdings and related fields if no_of_shares or total_no_shares change
      if (name === "no_of_shares" || name === "total_no_shares") {
        const noOfShares =
          name === "no_of_shares"
            ? parseFloat(value)
            : parseFloat(updatedItem.no_of_shares);
        const totalNoShares =
          name === "total_no_shares"
            ? parseFloat(value)
            : parseFloat(updatedItem.total_no_shares);
  
        // Check if both values are valid numbers and totalNoShares is not zero
        if (!isNaN(noOfShares) && !isNaN(totalNoShares) && totalNoShares !== 0) {
          const holdings = (noOfShares / totalNoShares) * 100;
          updatedItem.holdings = holdings;
          updatedItem.non_controlling_interest = 100 - holdings;
  
          // Update nature_of_relationship based on holdings percentage
          if (holdings >= 51) {
            updatedItem.nature_of_relationship = "Subsidiary Company";
          } else if (holdings >= 20 && holdings < 51) {
            updatedItem.nature_of_relationship = "Associate Company";
          } else {
            updatedItem.nature_of_relationship = "Investment";
          }
        } else {
          updatedItem.holdings = 0;
          updatedItem.non_controlling_interest = 0;
          updatedItem.nature_of_relationship = "Investment";
        }
      }
  
      // Return the updated object to setInvestData
      return updatedItem;
    });
  
    // Optional: You can still log the value if you want to inspect it
    console.log(value);
  };
  

  // add Others Details new Options
  const addNewOptionsDetails = async () => {
    const data = {
      otherDatailsId: allDetailsId,
      options: options,
    };
    const res = await addAllOtherOptions(data);
    if (res.status === 201) {
      setIsOptionsModalOpen(false);
      getOthersDetailsOptions();
    }
  };
  //get other Details options
  const getOthersDetailsOptions = async () => {
    const res = await getAllOtherOptions(allDetailsId);
    if (res.status === 201) {
      setOtherOptions(res.data.options);
    }
  };
  //get all others Details
  const getOthersDetails = async () => {
    const res = await getAllOtherDetails(allDetailsId);
    if (res.status === 201) {
      setIsOtherInputOpen(true);
      setOtherDetailsInputs(res.data.other_items);
      getOthersDetailsOptions();
      dispatch(fetchAllDetailsData(allDetailsId));
    }
  };
  // add  new row in others details
  const addNewOthersDetails = async () => {
    const data = {
      otherDatailsId: allDetailsId,
      other_items: allOtherDetailsInputs,
    };
    const res = await addOtherDetails(data);
    if (res.status === 201) {
      getOthersDetails();
    }
  };
  //delete others details
  const deleteOthersDetails = async (index) => {
    const data = {
      otherDatailsId: allDetailsId,
      index: index,
    };
    const res = await deleteAllOtherDetails(data);
    if (res.status === 201) {
      getOthersDetails();
    }
  };

  // add all others details data
  const addAllOthersDetails = async () => {
    const data = {
      otherDatailsId: allDetailsId,
      other_items: allOtherDetailsInputs,
    };
    const res = await addAllOtherDetails(data);
    if (res.status === 201) {
      setIsOtherInputOpen(false);
      dispatch(fetchAllDetailsData(allDetailsId));
    }
  };

  const ProjectName = (p_id) => {
    const ProjectData = allProjects.find((adj) => adj._id === p_id);
    return ProjectData?.project_name || "";
  };

  const handleChangeInputs = (e) => {
    setConsoleInvestmentValue(e.target.value);
  };

  const handleNext = (ir) => {
    const data = {
      non_controlling_interest: ir.non_controlling_interest,
      investment_value: -(
        Number(ir.value_of_investment) + Number(consoleInvestmentValue)
      ),
    };

    GetConsoleEntryData(ir._id, data)
      .then((response) => {
        const result = response.data?.result;
        setShowFirstTable(false);
        setAllConsoleEntry(result?.console_entry);

        setInvestData((prev) =>
          prev.map((item) => {
            if (item._id === result._id) {
              return { ...item, console_entry: result?.console_entry };
            }
            return item;
          })
        );
      })
      .catch((error) => {
        swal(
          "Error",
          error?.response?.data?.error || "something went wrong",
          "error"
        );
      });
  };
  const handleBack = () => {
    history.push(`/investmentregister/${b[2]}`);
  };

  return (
    <div className="pb-2">
      <Navbar />
      <div className="container mt-4">
        <div className="d-flex">
          <p className="fs-inter" style={{ color: "#343434" }}>
            HOME / INVESTMENT /&nbsp;
            <span style={{ fontWeight: "bold", color: "#000000" }}>
              ADDTION REGISTER
            </span>
          </p>
        </div>
        <div>
          <h5 className="fs-inter ir-heading">Addition Investment Register</h5>
        </div>
        {/* <div className="row mt-4">
          <div className="col-md-4">
            <label className="dm-sans iw-label">
              Name of investment register
              <input
                type="text"
                name="register"
                placeholder="Enter Invest Register"
                disabled
              />
            </label>
          </div>
          <div className="col-md-4">
            <label className="dm-sans iw-label">Parent company</label>
            <select
              style={{
                minWidth: "350px",
                minHeight: "54px",
                marginTop: "-1px",
                borderRadius: "5px",
              }}
              disabled
            >
              <option>Select...</option>
              {allCompany.length > 0 &&
                allCompany.map((det) => {
                  return (
                    <>
                      <option value={det._id}>{det.company_name}</option>
                    </>
                  );
                })}
            </select>
          </div>
        </div>
        <hr style={{ width: "731px" }} /> */}
        <div className="row">
          <div className="col-md-8">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="d-flex justify-content-between">
                    <div className="font-weight-bold">
                      &nbsp;&nbsp;Addition Investment
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="text-secondary">
                        Investee company name
                        <select
                          style={{
                            width: "336px",
                            marginTop: "12px",
                            borderRadius: "5px",
                            height: "59px",
                          }}
                          onChange={(e) => HandleChange(e)}
                          name="investment_company_id"
                          value={investdata.investment_company_id}
                        >
                          <option>Select...</option>
                          {allCompany.length > 0 &&
                            allCompany.map((det) => {
                              return (
                                <>
                                  <option value={det._id}
                                  selected={
                                  investdata?.investment_company_id === det._id
                                  }
                                  >
                                    {det.company_name}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div className="col-md-6 text-secondary">
                      <label>
                        Company code
                        <input
                          type="text"
                          placeholder="Code"
                          name="investment_company_code"
                          value={investdata.investment_company_code}
                          disabled
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label className="text-secondary">
                        Date of investment
                        <input
                          type="date"
                          className="form-control"
                          name="date_Of_investment"
                          onChange={(e) => HandleChange(e)}
                          value={investdata.date_Of_investment}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label className="text-secondary">
                        Value of investment
                        <input
                          type="text"
                          name="value_of_investment"
                          style={{ width: "698px" }}
                          onChange={(e) => HandleChange(e)}
                          value={investdata.value_of_investment}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label className="text-secondary">
                        No of shares brought
                        <input
                          type="text"
                          name="no_of_shares"
                          onChange={(e) => HandleChange(e)}
                          defaultValue={investdata.no_of_shares}
                        />
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="text-secondary">
                        Total no shares in the company
                        <input
                          type="text"
                          name="total_no_shares"
                          onChange={(e) => HandleChange(e)}
                          defaultValue={investdata.total_no_shares}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label className="text-secondary">
                        Percentage holding
                        <input
                          type="text"
                          placeholder="%"
                          name="holdings"
                          value={investdata.holdings?.toLocaleString("en-IN")}
                          disabled
                        />
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="text-secondary">
                        Non-controling interest
                        <input
                          type="text"
                          name="non_controlling_interest"
                          value={investdata.non_controlling_interest?.toLocaleString(
                            "en-IN"
                          )}
                          disabled
                          placeholder="Add non-controlling interest"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label className="text-secondary">
                        Nature of relationship
                      </label>

                      <select
                        style={{
                          width: "100%",
                          height: "59px",
                          borderRadius: "5px",
                        }}
                      >
                        <option>Select</option>
                        {companyRelationShip.map((nr) => (
                          <option
                            value={nr}
                            selected={investdata.nature_of_relationship === nr}
                          >
                            {nr}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* modal  */}

                  {investdata.holdings > 50 && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <button
                          onClick={() => {
                            GetAllConsoleEntry(investdata);
                          }}
                          className="px-2 text-white shadow py-2 w-100 rounded custom-border custom-common-theme-bg-color"
                        >
                          Create consol entry
                        </button>
                      </div>

                      <div className="col-md-6">
                        <button
                          onClick={() => {
                            setDetails((prev) => ({
                              ...prev,
                              details_id: investdata._id,
                            }));
                            GetAllProjects();
                          }}
                          className="px-2 text-white shadow py-2 w-100 rounded custom-border custom-common-theme-bg-color"
                        >
                          Browse control date FS
                        </button>
                        <span> {ProjectName(details?.browseFsCompanyId)}</span>
                      </div>
                    </div>
                  )}

                  {investdata.holdings > 50 && (
                    <div className="row  mt-3">
                      <div className="col-md-12 ">
                        <label className="dm-sans custom-font-700 font-size-14 text-secondary mb-0">
                          Details of net-worth on the date of control{" "}
                        </label>
                        <input type="text" className="rounded" />
                      </div>

                      <div className="col-md-12 mt-1">
                        <button
                          onClick={() => {
                            setAllDetailsId(investdata._id);
                            getOthersDetails();
                          }}
                          className="border-0 bg-color-inherit mt-0 text-secondary d-flex justify-content-center align-items-center"
                        >
                          Add other details
                          <AddIcon />
                        </button>
                      </div>
                    </div>
                  )}
                  {investdata.holdings > 50 && (
                    <div className="row mt-2">
                      <div className="col-md-12 ">
                        <label className="text-secondary">
                          Whether transaction is covered as common control
                          transaction
                        </label>
                        <select
                          // onChange={(e) => handleChangeData("wticacct", e)}
                          style={{
                            width: "691px",
                            marginTop: "12px",
                            borderRadius: "5px",
                            height: "59px",
                          }}
                        >
                          <option>Select....</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>

                      <div className="col-md-12 mt-3">
                        <div class="table-responsive table-bordered">
                          <table class="table" style={{ marginBottom: "0" }}>
                            <tbody>
                              {all_othersData?.response.length > 0 &&
                                all_othersData?.response.map((item, v) => (
                                  <tr key={v}>
                                    <td>{item.column}</td>
                                    <td>{item.select}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <hr style={{ width: "731px" }} />
        <div className="mt-4 row">
          <div className="col-md-8">
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  <label
                    className="text-secondary"
                    style={{ fontSize: "16px" }}
                  >
                    Add remarks
                  </label>
                  <input type="text" style={{ height: "110px" }} />
                </Typography>
              </CardContent>
              <div
                style={{ color: "black", marginLeft: "18px" }}
                className="text-secondary"
              >
                Upload attachments
              </div>
              <CardActions>
                <Button
                  style={{
                    backgroundColor: "white",
                    // color: "black",
                    textTransform: "none",
                    marginLeft: "12px",
                    width: "220px",
                    height: "50px",
                    border: "2px solid #03565A",
                    borderRadius: "5px",
                    color: "#03565A",
                    fontWeight: "bold",
                  }}
                >
                  Upload attachment
                </Button>
              </CardActions>
            </Card>
          </div>
        </div>
        <div
          className="backicon"
          style={{ cursor: "pointer", marginLeft: "0px" }}
          onClick={() => handleBack()}
        >
          <ArrowBackIcon />
          &nbsp;
          <div>Go Back</div>
        </div>
        <div
          className="nextbtn-v2"
          style={{ marginLeft: "-325px", marginTop: "-42px" }}
        >
          <Button
            style={{
              backgroundColor: "white",
              // color: "black",
              textTransform: "none",

              marginRight: "12px",
              width: "220px",
              height: "50px",
              border: "2px solid #03565A",
              borderRadius: "5px",
              color: "#03565A",
              fontWeight: "bold",
            }}
            // onClick={handleSubmit}
          >
            Create Register
          </Button>
        </div>
      </div>
      <BrowsFsModal
        setIsBrowsModalOpen={setIsBrowsModalOpen}
        isBrowsModalOpen={isBrowsModalOpen}
        allProjects={allProjects}
        details={details}
        setDetails={setDetails}
        handleSubmitFs={handleSubmitFs}
      />

      <ConsoleEntryModal
        isConsoleEntryOpen={isConsoleEntryOpen}
        setIsConsoleEntryOpen={setIsConsoleEntryOpen}
        allConsoleEntry={allConsoleEntry}
        allRegisterData={investdata}
        handleChangeInputs={handleChangeInputs}
        consoleInvestmentValue={consoleInvestmentValue}
        handleNext={handleNext}
        showFirstTable={showFirstTable}
        setShowFirstTable={setShowFirstTable}
      />

      <AddOtherDetailsModal
        setIsOtherInputOpen={setIsOtherInputOpen}
        isOtherInputOpen={isOtherInputOpen}
        allOtherDetailsInputs={allOtherDetailsInputs}
        allotherdata={otherOptions}
        handleChangeDetails={handleChangeDetails}
        handleNew={addNewOthersDetails}
        handleDelete={deleteOthersDetails}
        handleSave={addAllOthersDetails}
      />

      <AddNewOptionsModal
        isOptionsModalOpen={isOptionsModalOpen}
        setIsOptionsModalOpen={setIsOptionsModalOpen}
        setOptions={setOptions}
        saveData={addNewOptionsDetails}
      />
    </div>
  );
}

export default AdditionInvestRegister;
