import React, { useEffect, useRef, useState } from "react";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function GeneralEditor({ 
    onChange, value, text, isGuidance, label, labelValue,
    note
}) {
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {note?.label && <p className="mt-0 mb-0 pl-3 font-sans font-size-18"> {note?.label}</p>}
                {note?.refData && 
                <div>
                    <small className="pr-3 font-sans">{note?.refData?.map(ele => ele.reference).join(", ")}</small>
                    <button> Add + </button>
                </div>
                }
            </div>

            { isGuidance &&
                <div className="d-flex px-3 justify-content-between align-items-center mt-3 w-100">
                    <input 
                        className="w-50 p-1"
                        placeholder="Enter name"
                        name="label"
                        value={label}
                        onChange={(e)=> onChange(e, true)}
                    />
                    <input 
                        className="w-50 ml-2 p-1" 
                        placeholder="Enter guidance"
                        name="value"
                        value={labelValue}
                        onChange={(e)=> onChange(e, true)}
                    />
                </div>
            }

            <div className="px-3 mt-1">
                <div>
                    <CKEditor
                        style={{ minHeight: '70vh', zIndex: '0' }}
                        editor={ClassicEditor}
                        data={value}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            onChange(data);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}