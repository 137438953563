const EditInvestment=(props)=>{
    const {registerdata,hidediv,name,totaldata,holding,interest,notInterest,natureinvest,browsproject,handleOpen1,handleSubmit,entryOpen,browseFs,hideDiv,handleChange,path}= props;
    return(
        <>
            <div className="default-according p-4" id="accordion1">
                    <div className="card">
                        <div style={{ backgroundColor: '#03565a' }} className="card-header" id="headingFive">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed text-white"data-bs-toggle="collapse" href="#collapseFive" onClick={hideDiv}> Tranch 1
                                    <br />
                                    <span><b>Date of Investment:</b> {registerdata.date_Of_investment}</span> <span
                                        className="ps-4"><b>Investment Value:</b> {registerdata.value_of_investment}</span>
                                        </button>
                            </h5>
                        </div>
                        {hidediv &&
                            <div className="collapse show" id="collapseFive" aria-labelledby="headingFive"
                            data-bs-parent="#accordion">
                            <form>
                                <div className="card-body">
                                    {/* <h3>Tranch 1</h3> */}
                                    <div className="row">
                                        <div className="col-6 form-group">
                                            <label>Date of Investment</label>
                                            <input type="date" className="form-control" defaultValue={registerdata.date_Of_investment} onChange={(e)=>handleChange(e,'doi')}/>
                                        </div>
                                        <div className="col-5 offset-1 form-group">
                                            <label>Company Name</label>
                                            <input type="text" className="form-control" placeholder="Enter company name" name='company_name' defaultValue={registerdata.company} onChange={(e)=>handleChange(e,'company_name')}/>
                                        </div>
                                    </div>
                                    <div className="row  mt-3">
                                        <div className="col-6 form-group">
                                            <label>Company Code</label>
                                            <input type="text" className="form-control" defaultValue={name.company_code} disabled/>
                                        </div>
                                        <div className="col-5 offset-1 form-group">
                                            <label>Value of Investment</label>
                                            <input type="text" className="form-control" name='voi' defaultValue={registerdata.value_of_investment} onChange={(e)=>handleChange(e,'voi')}/>
                                        </div>
                                    </div>
                                    <div className="row  mt-3">
                                        <div className="col-6 form-group">
                                            <label>No. of shares bought</label>
                                            <input type="text" className="form-control" defaultValue={registerdata?.no_of_shares} onChange={(e)=>handleChange(e,'no_of_bought')}/>
                                        </div>
                                        <div className="col-5 offset-1 form-group">
                                            <label>Total no. of shares in the company</label>
                                            <input type="text" className="form-control" name='total_share' defaultValue={registerdata.total_no_shares} onChange={(e)=>handleChange(e,'total_share')}/>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 form-group">
                                            <label>% Holding</label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type="text" className="form-control" value={(totaldata?totaldata:registerdata.holdings)} onChange={(e)=>handleChange(e,'holding')} disabled/>
                                                </div>
                                                {holding > 50 && (!totaldata || totaldata > 50) ?
                                                <div className="col-md-6 text-center" onClick={() => entryOpen()}>
                                                    <input type="button" className="btn btn-primary w-100" value="Create Console Entry" style={{background:"rgb(3, 86, 90)"}}/>
                                                </div>
                                                : path[1] === "edit-register" && (totaldata && totaldata > 50) ?
                                                <div className="col-md-6 text-center" onClick={() => entryOpen()}>
                                                    <input type="button" className="btn btn-primary w-100" value="Create Console Entry" style={{background:"rgb(3, 86, 90)"}}/>
                                                </div>
                                                : ""
                                                }
                                                {console.log(totaldata,"totaldata",holding,"holding",path[1])}
                                            </div>



                                        </div>
                                        <div className="col-5 offset-1 form-group">
                                            <div className="row">
                                                <div className='col-md-12'>
                                            <label>Non-controlling interest %</label>
                                            </div>
                                                <div className="col-md-6">

                                                    <input type="text" className="form-control" value={interest?interest:notInterest} disabled/>
                                                </div>
                                                {holding > 50 && (!totaldata || totaldata > 50) ?
                                                    <div className="col-md-6 text-center" onClick={() => browseFs()}>
                                                    <input type="button" className="btn btn-primary w-100" value="Browse control date FS" style={{background:"rgb(3, 86, 90)"}}/>
                                                    <small>{browsproject}</small>
                                                    </div>
                                                     : path[1] === "edit-register" && (totaldata && totaldata > 50) ?
                                                     <div className="col-md-6 text-center" onClick={() => browseFs()}>
                                                     <input type="button" className="btn btn-primary w-100" value="Browse control date FS" style={{background:"rgb(3, 86, 90)"}}/>
                                                     <small>{browsproject}</small>
                                                     </div>
                                                 : ""
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                    {holding > 50 && (!totaldata || totaldata > 50) ?
                                    <div className="row  mt-3">
                                        <div className="col-6 form-group">
                                            <label>Details of networth on the date of control</label>
                                            <input type="text" className="form-control" defaultValue={registerdata.details_networth} onChange={(e)=>handleChange(e,'doi')} />
                                        </div>
                                        <div className="col-5 offset-1 form-group">
                                            <label>Add other details </label>
                                            <input onClick={handleOpen1} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Intangible" value="+" style={{background:"rgb(3, 86, 90)"}}/>
                                            {/* <Link className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Intangible">+</Link> */}

                                        </div>
                                    </div>
                                     : path[1] === "edit-register" && (totaldata && totaldata > 50) ?
                                        <div className="row  mt-3">
                                            <div className="col-6 form-group">
                                                <label>Details of networth on the date of control</label>
                                                <input type="text" className="form-control" defaultValue={registerdata.details_networth} onChange={(e)=>handleChange(e,'doi')} />
                                            </div>
                                            <div className="col-5 offset-1 form-group">
                                                <label>Add other details </label>
                                                <input onClick={handleOpen1} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Intangible" value="+" style={{background:"rgb(3, 86, 90)"}}/>
                                                {/* <Link className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Intangible">+</Link> */}

                                            </div>
                                        </div>  
                                    : ""
                                    }
                                   {holding > 50 && (!totaldata || totaldata > 50) ?
                                    <div className="row mt-3">
                                        <div className="col-6 form-group">
                                            <label>Whether transaction is covered as common control
                                                transaction</label>
                                            <select className="form-control" defaultValue={registerdata.transaction_is_covered} onChange={(e)=>handleChange(e,'transaction')}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-5 offset-1 form-group">
                                        <div class="table-responsive table-bordered">
                                            <table class="table">
                                                <tbody>
                                                {(registerdata.other_items.length >  0  && registerdata.other_items.map((item=>(
                                                    <>
                                                        <tr>
                                                            <td>{item.column}</td>
                                                            <td>{item.select}</td>
                                                        </tr>  
                                                    </>
                                                    ))))}
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                     : path[1] === "edit-register" && (totaldata && totaldata > 50) ?
                                     <div className="row mt-3">
                                        <div className="col-6 form-group">
                                            <label>Whether transaction is covered as common control
                                                transaction</label>
                                            <select className="form-control" defaultValue={registerdata.transaction_is_covered} onChange={(e)=>handleChange(e,'transaction')}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-5 offset-1 form-group">
                                        <div class="table-responsive table-bordered">
                                            <table class="table">
                                                <tbody>
                                                {(registerdata.other_items.length >  0  && registerdata.other_items.map((item=>(
                                                    <>
                                                        <tr>
                                                            <td>{item.column}</td>
                                                            <td>{item.select}</td>
                                                        </tr>  
                                                    </>
                                                    ))))}
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div> 
                                   : ""
                                    }
                                        <div className="row mt-3">
                                        <div className="col-6 form-group">
                                            <label>Nature Of Relationship</label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <select className="form-control" name='nature_of_relation' defaultValue={natureinvest} onChange={(e) => handleChange(e,'nature_of_relation')} >
                                                        <option>Select</option>
                                                        <option value="Investment" selected={natureinvest === 'Investment'}>Investment</option>
                                                        <option value="Associate Company" selected={natureinvest === 'Associate Company'}>Associate Company</option>
                                                        <option value="Subsidiary Company" selected={natureinvest === 'Subsidiary Company'}>Subsidiary Company</option>
                                                    </select>
                                                </div>
                                                {/* <h6>{natureinvest}</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="card-body" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                    <input type="button" className="btn btn-primary" value="Submit" onClick={handleSubmit} style={{background:"rgb(3, 86, 90)"}}/>
                                </div>
                            </form>
                        </div>
                        }
                        
                    </div>
            </div>
        </>
    )
}

export default EditInvestment;