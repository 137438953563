import { GetReceivable } from "../../../WorkSpace/Components/ApiServices/apiHandler";

export const fetchAllReceivables = () => {
    return dispatch => {
        dispatch({type:"RECEIVABLES_ACTION_START"})
        GetReceivable().then((result)=>{
            dispatch({
                type: "RECEIVABLES_FETCH_DATA",
                payload: result.data.receivables
            }) 
        }).catch((error)=>{
            dispatch({
                type: "RECEIVABLES_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}