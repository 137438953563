import { getWorkspacePrepareConsole } from "../../Console/Component/services/apiHandler";


export const fetchWorkspacePrepareConsoleData = (prepare_console_id) => {
    return dispatch => {
        dispatch({type:"WorkspacePrepareconsol_ACTION_START"})
        getWorkspacePrepareConsole(prepare_console_id).then((result)=>{
            dispatch({
                type: "WorkspacePrepareconsol_FETCH_DATA",
                payload: {data: result.data, set_payload: prepare_console_id}
            })
        }).catch((err)=>{
            console.log(err,"error");
            dispatch({
                type:"WorkspacePrepareconsol_FETCH_DATA_FAILED",
                payload: err?.response?.data?.error || err.message
            })
        })        
    }
}