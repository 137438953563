import React from 'react'
import './commonCssSheet.css';

const PrimaryButton = ({ name, variant, className, style, onClick }) => {
    return (
        <div>
            <button
                variant={variant}
                className={`m-1 py-2 px-2 rounded text-capitalize primary-button font-sans ${variant === "outlined" ? "bg-white custom-common-theme-text-color primary-border" : "text-white custom-common-theme-bg-color border-0"} ${className && className}`}
                style={style}
                onClick={onClick}
            >
                {name}
            </button>

        </div>
    )
}

export default PrimaryButton