import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import moment from 'moment';
import Table from "react-bootstrap/Table";
import swal from 'sweetalert';
import { toast } from "react-toastify";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { deleteRestoreData, getDeletedMembers } from '../ApiServices/apiHandler';
import { Navbar } from '../../../Components/Navbar';
import Loading from '../../../Components/Common/Loader/Loading';

const DeletedIds = () => {
  const history = useHistory();
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDeletedMembers();
  }, [])

  const fetchDeletedMembers = () => {
    setLoading(true);
    getDeletedMembers().then(response => {
      setLoading(false);
      setDeletedUsers(response.data.fetchMembers)
    }).catch(error => {
      setLoading(false);
      swal(
        "Error",
        error.response?.data?.error || "Getting deleted members data failed!",
        "error"
      );
    })
  }

  const RestoreMember = async (u_id) => {
    swal({
      title: "Are you sure?",
      text: "You will  recover this imaginary file!",
      icon: "warning",
      buttons: ["Cancel", "Yes, restore it!"],
      closeOnClickOutside: false,
    }).then(() => {
        deleteRestoreData(u_id).then(response => {
          toast.success("Member Restored Successfully.");
          fetchDeletedMembers();
        }).catch(error => {
          swal(
            "Error",
            error.response?.data?.error || "Member Restored failed!",
            "error"
          );
        })
    });
  };

  return (
    <>
    <Navbar />
    <button 
      className='custom-common-theme-text-color border-0 bg-color-inherit d-flex p-2'
      onClick={() => history.push('/admin')}
    >
      <ArrowBackIosNewIcon className='w-50' />Back
    </button>
    <TableContainer className='px-3'>
      {
        loading ? <Loading /> :
        <Table aria-label="caption table">
          <TableHead className='custom-bg-gray-100' style={{ position: 'sticky', top: -1, zIndex: 1 }}>
            <TableRow>
              <TableCell align="left" className="font-sans custom-font-600 text-secondary">
                Modified Date
              </TableCell>
              <TableCell align="left" className="font-sans custom-font-600 text-secondary">
                Username
              </TableCell>
              <TableCell align="left" className="font-sans custom-font-600 text-secondary">
                Email
              </TableCell>
              <TableCell align="center" className="font-sans custom-font-600 text-secondary">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deletedUsers?.map((user, index) =>(
              <TableRow key={index}>
                <TableCell className="font-sans bg-white text-secondary" align="left" >
                  {moment(user.updatedAt).format('DD-MM-YYYY')}<br></br>
                  <sub>{moment(user.updatedAt).fromNow()}</sub>
                </TableCell>
                <TableCell className="font-sans bg-white text-secondary" align="left">
                  {user?.name}
                </TableCell>
                <TableCell className="font-sans bg-white text-secondary" align="left">
                  {user?.email}
                </TableCell>
                <TableCell className="font-sans bg-white" align="center">
                  <button
                    className="d-flex justify-content-center align-items-center bg-color-inherit border-0 ml-2 h-100 w-100"
                    onClick={() => RestoreMember(user._id)}
                  >
                    <SettingsBackupRestoreIcon
                      className="text-success h-50"
                    />
                    <p className="mt-0 mb-0 font-size-14 custom-font-600 text-success">
                      Restore
                    </p>
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      }
    </TableContainer>
    {/* {hasMore?<button style={{border:'none',color:'#03565A',float:'right',marginRight:'3px'}} onClick={fetchMoreData}>Load more...</button>:''} */}
    </>
  )
}

export default DeletedIds;