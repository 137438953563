import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import moment from "moment";
import {
  lineGroupStyle,
  totalStyle,
  totaledStyle,
  NoteStyle,
} from "../Styles/style";
import { CircularProgress } from "@mui/material";
import { getProjectDetailById } from "../services/apiHandler";
import {
  getGroupName,
  getNoteNumber,
  getPatternType,
  getRandomKey,
  getScenario,
  groupDataCal,
  manualAutoAdjustment,
  prepareAdjustmentData,
  prepareAutoPopulationData,
} from "../../../helper/calculation";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentProjectCy,
  CurrentProjectPy,
} from "../../../helper/pnlCalculation";
import { getAllIR } from "../../../WorkSpace/Components/ApiServices/apiHandler";
import { fetchIR } from "../../../redux/actions/actionIr";

function NewConsolePnlStatement(props) {
  const {
    pnlStatementData,
    subsiaryData,
    currentCompany,
    groupingData,
    consoleNotes,
    currentCompProjectId,
    setKey,
    setTab,
    allAssociateCheckData,
    associateStatement,
    CompanyName,
  } = props;
  const { loading, isSuccess, response, error_message } = pnlStatementData;

  const finalSubsidiaryData =
    response?.data?.project_ids_pnl?.map((data) => data.pnl_statement) ?? [];
  // const finalBrowsFsData =
  //   response?.data?.browse_fs_pnl?.map((data) => data.pnl_statement) ?? [];
  // const browsFs = response.data?.pnl_statement?.pnl_statement ?? [];
  const currentProjectPnl = response.data?.pnl_statement?.pnl_statement ?? [];
  // const currentProjectAssociate =
  //   associateStatement?.response?.data?.pnl_statement?.pnl_statement ?? [];

  const finalCurrentProjectAssociate =
    associateStatement?.response?.data?.project_ids_pnl?.map(
      (data) => data.pnl_statement
    ) ?? [];
  const AdjData = useSelector((state) => state.ADJReducer);
  const preAdjustment = useSelector((state) => state.PADJReducer.response);
  const preAdjustmentOther = useSelector(
    (state) => state.PADJReducer.otherResponse
  );
  const APAdjustment = useSelector((state) => state.APAReducer);
  const [no, setNo] = useState([]);
  const fsRows = Object.keys(currentProjectPnl);
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [allIrList, setAllIr] = useState([]);
  const fetchIr = useSelector((state) => state.IRReducer);
  const subsidiaryData = useSelector((state) => state.SubsidiaryConsoleReducer);
  const [irOtherDetails, setIrOtherDetails] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    getCurrentDataName(currentCompProjectId);
    AllIrData();
    let noArr = [];
    fsRows.map((row, i) => {
      currentProjectPnl[row].map((line, ix) => {
        if (
          noArr.length > 0 &&
          noArr.some(
            (user) =>
              user.notes_grp.toLowerCase() === line.notes_grp.toLowerCase()
          )
        ) {
        } else {
          noArr.push({ notes_grp: line.notes_grp });
        }
      });
    });
    setNo(noArr);
    dispatch(fetchIR());
  }, [currentProjectPnl]);

  function handleSelect(key) {
    // props.setKey('Notes2')
    // props.scrollToTop()
    // props.setNote(key)
    setKey("Notes2");
    props.setTempValue(key);
    setTab("pnl");
    // dispatch({type: actionTypes.SET_PLNO,payload:key})
  }

  const getCurrentDataName = async (currentCompProjectId) => {
    const res = await getProjectDetailById(currentCompProjectId);
    setCurrentProjectName(res?.data?.project?.project_name);
  };

  const calculateGroupPnlCy = (group, total_tax = false) => {
    try {
      return finalSubsidiaryData
        .map((pnl_statement, sub_index) =>
          grandTotalProjectPnlCy(group, sub_index, total_tax)
        )
        .reduce((data, num) => data + num, 0);
    } catch (error) {
      // console.log(error.message, "error gcy");
      return 0;
    }
  };

  const calculateGroupPnlPy = (group, total_tax = false) => {
    try {
      return finalSubsidiaryData
        .map((pnl_statement, sub_index) =>
          grandTotalProjectPnlPy(group, sub_index, total_tax)
        )
        .reduce((data, num) => data + num, 0);
    } catch (error) {
      // console.log(error.message, "error gcy");
      return 0;
    }
  };

  const getProjectCy = (BrowsFsData, project_data, sub_index) => {
    try {
      const { first_scenario, second_scenario } = getScenario(
        currentCompany,
        subsiaryData,
        sub_index,
        FindIr
      );
      const projectCy = project_data.cy_amt ?? 0;
      const browseFsCy = BrowsFsData.map((browse_item) => {
        if (browse_item.group === project_data.group) {
          return browse_item.cy_amt;
        } else {
          return 0;
        }
      }).reduce((num, acc) => num + acc, 0);
      // console.log(projectCy, "projectCy", browseFsCy);
      if (first_scenario) {
        return projectCy - browseFsCy;
      } else if (second_scenario) {
        return projectCy;
      } else {
        return projectCy;
      }
    } catch (error) { }
  };

  const getProjectPy = (BrowsFsData, project_data, sub_index) => {
    try {
      const { first_scenario, second_scenario } = getScenario(
        currentCompany,
        subsiaryData,
        sub_index,
        FindIr
      );
      const projectCy = project_data.py_amt ?? 0;
      const browseFsCy = BrowsFsData.map((browse_item) => {
        if (browse_item.group === project_data.group) {
          return (browse_item?.cy_amt ?? 0);
        } else {
          return 0;
        }
      }).reduce((num, acc) => num + acc, 0);
      if (first_scenario) {
        return 0;
      } else if (second_scenario) {
        return projectCy - browseFsCy;
      } else {
        return projectCy;
      }
    } catch (error) { }
  };

  const ProjectIdPnlCy = (
    sub_index,
    note_group,
    note_index,
    total_tax = false
  ) => {
    try {
      if (note_group) {
        let note_no = getNoteNumber(consoleNotes, note_index);
        if (note_no === "--") {
          return "...";
        }
        const { Projects, BrowsFsData } = groupDataCal(groupingData, note_no);
        const result = Projects[sub_index]
          .flat()
          .map((project_data) => {
            return getProjectCy(
              BrowsFsData[sub_index]?.flat(),
              project_data,
              sub_index
            );
          })
          .reduce((num, acc) => num + acc, 0);
        return result;
      }
      return "loading...";
    } catch (error) {
      // console.log("error project pnl", error.message);
      return "00";
    }
  };

  const ProjectIdPnlPy = (
    sub_index,
    note_group,
    note_index,
    total_tax = false
  ) => {
    try {
      if (note_group) {
        let note_no = getNoteNumber(consoleNotes, note_index);
        if (note_no === "--") {
          return "...";
        }
        const { Projects, BrowsFsData } = groupDataCal(groupingData, note_no);
        const result = Projects[sub_index]
          .flat()
          .map((project_data) => {
            return getProjectPy(
              BrowsFsData[sub_index]?.flat(),
              project_data,
              sub_index
            );
          })
          .reduce((num, acc) => num + acc, 0);
        return result;
      }
      return "loading py..";
    } catch (error) {
      // console.log("error project pnl", error.message);
      return "00";
    }
  };

  const calculateTotalCy = (noteGroup) => {
    return finalSubsidiaryData
      .map((project_pnl, sub_index) =>
        ProjectIdPnlCy(
          sub_index,
          noteGroup.notes_grp,
          getNoteNoIndex(noteGroup.notes_no)
        )
      )
      .reduce((data, num) => data + num, 0);
  };

  const calculateTotalPy = (noteGroup) => {
    return finalSubsidiaryData
      .map((project_pnl, sub_index) =>
        ProjectIdPnlPy(
          sub_index,
          noteGroup.notes_grp,
          getNoteNoIndex(noteGroup.notes_no)
        )
      )
      .reduce((data, num) => data + num, 0);
  };

  const grandTotalProjectPnlCy = (group, sub_index, total_tax = false) => {
    try {
      if (
        group === "Income" ||
        group === "Exceptional items" ||
        group === "Profit / (loss) from discontinued operations" ||
        group === "Other Comprehensive Income"
      ) {
        const result = currentProjectPnl[group]
          .map((noteGroup) => {
            return ProjectIdPnlCy(
              sub_index,
              noteGroup.notes_grp,
              getNoteNoIndex(noteGroup.notes_no)
            );
          })
          .reduce((num, acc) => num + acc, 0);
        return result;
      } else if (group === "Expenses") {
        const result = currentProjectPnl[group]
          .map((noteGroup) => {
            if (total_tax) {
              if (
                noteGroup.notes_grp === "Current tax" ||
                noteGroup.notes_grp === "Deferred tax"
              ) {
                return ProjectIdPnlCy(
                  sub_index,
                  noteGroup.notes_grp,
                  getNoteNoIndex(noteGroup.notes_no)
                );
              } else {
                return 0;
              }
            } else {
              if (
                noteGroup.notes_grp === "Current tax" ||
                noteGroup.notes_grp === "Deferred tax"
              ) {
                return 0;
              } else {
                return ProjectIdPnlCy(
                  sub_index,
                  noteGroup.notes_grp,
                  getNoteNoIndex(noteGroup.notes_no)
                );
              }
            }
          })
          .reduce((num, acc) => num + acc, 0);
        return result;
      }
      return "loading...";
    } catch (err) {
      return err.message;
    }
  };
  const grandTotalProjectPnlPy = (group, sub_index, total_tax = false) => {
    try {
      if (
        group === "Income" ||
        group === "Exceptional items" ||
        group === "Profit / (loss) from discontinued operations" ||
        group === "Other Comprehensive Income"
      ) {
        const result = currentProjectPnl[group]
          .map((noteGroup) => {
            return ProjectIdPnlPy(
              sub_index,
              noteGroup.notes_grp,
              getNoteNoIndex(noteGroup.notes_no)
            );
          })
          .reduce((num, acc) => num + acc, 0);
        return result;
      } else if (group === "Expenses") {
        const result = currentProjectPnl[group]
          .map((noteGroup) => {
            if (total_tax) {
              if (
                noteGroup.notes_grp === "Current tax" ||
                noteGroup.notes_grp === "Deferred tax"
              ) {
                return ProjectIdPnlPy(
                  sub_index,
                  noteGroup.notes_grp,
                  getNoteNoIndex(noteGroup.notes_no)
                );
              } else {
                return 0;
              }
            } else {
              if (
                noteGroup.notes_grp === "Current tax" ||
                noteGroup.notes_grp === "Deferred tax"
              ) {
                return 0;
              } else {
                return ProjectIdPnlPy(
                  sub_index,
                  noteGroup.notes_grp,
                  getNoteNoIndex(noteGroup.notes_no)
                );
              }
            }
          })
          .reduce((num, acc) => num + acc, 0);
        return result;
      }
      return "loading...";
    } catch (err) {
      return err.message;
    }
  };

  const getIndexNumber = (line) => {
    try {
      let index = no.findIndex(
        (note) =>
          note?.notes_grp.toLowerCase() === line?.notes_grp.toLowerCase()
      );
      return index;
    } catch (err) {
      // console.log("error", err.message);
      return "00";
    }
  };

  const getNoteNoIndex = (notes_no) => {
    try {
      let resultIndex = 0;
      Object.keys(consoleNotes).forEach(function (key, index2) {
        let index = consoleNotes[key].find(
          (data) => data.note_number === notes_no
        );
        if (index !== undefined) {
          resultIndex = index.index;
        }
      });
      return resultIndex;
    } catch (error) {
      // console.log("error", error.message);
      return "00";
    }
  };

  const adjustmentResult = (
    groupDataCal,
    adjustmentData,
    autoPopulateData,
    group_index,
    scenario,
    project_index,
    showManualOnly,
    pattern_type,
    groupName
  ) => {
    try {
      const { BrowsFsData } = groupDataCal;
      const scenarioResult = subsiaryData.map((sub_data, sub_index) => {
        return {
          scenario: getScenario(
            currentCompany,
            subsiaryData,
            sub_index,
            FindIr
          ),
          brows_fs: BrowsFsData[sub_index][group_index][project_index],
          adjustment: adjustmentData?.filter(
            (adj) => adj.company_id === sub_data?.all_data?._id
          ),
          autoPopulate: autoPopulateData?.filter(
            (adj) => adj.company_id === sub_data?.all_data?._id
          ),
        };
      });
      const browsDataRes = scenarioResult.filter(
        (data) => data.brows_fs !== undefined
      );
      const browsDataRes_other = scenarioResult.filter((data) => data);
      if (browsDataRes.length === 0) {
        return 0;
      }
      const pre_adjustment = getPreAdjustment(
        scenarioResult,
        browsDataRes,
        browsDataRes_other,
        scenario,
        pattern_type,
        groupName
      );
      const manual_auto_adjustment = manualAutoAdjustment(
        scenarioResult,
        browsDataRes_other,
        scenario
      );
      const signChangeGroup = [
        "Equity",
        "Non Current Liabilities",
        "Current Liabilities",
        "Income",
      ];
      if (signChangeGroup.includes(groupName)) {
        return pre_adjustment + manual_auto_adjustment * -1;
      } else {
        return pre_adjustment + manual_auto_adjustment;
      }
    } catch (error) {
      // console.log("err adjustmentResult", error.message);
      return 0;
    }
  };

  const getPreAdjustment = (
    scenarioResult,
    browsDataRes,
    browsDataRes_other,
    scenario,
    pattern_type,
    groupName
  ) => {
    let first_scenario_data = 0;
    let second_scenario_data = 0;
    let third_scenario_data = 0;

    if (preAdjustment.includes(browsDataRes[0].brows_fs.group)) {
      const first_scenario_res = scenarioResult.filter(
        (data) => data.scenario.first_scenario === true
      );
      const first_result = first_scenario_res
        .map((data) => data.brows_fs.cy_amt)
        .reduce((data, num) => data + num, 0);
      first_scenario_data += first_result;

      const second_scenario_res = scenarioResult.filter(
        (data) => data.scenario.second_scenario === true
      );
      const second_result = second_scenario_res
        .map((data) => data.brows_fs.cy_amt)
        .reduce((data, num) => data + num, 0);
      second_scenario_data += second_result;
      first_scenario_data += second_result;

      const third_scenario_res = scenarioResult.filter(
        (data) =>
          data.scenario.first_scenario !== true &&
          data.scenario.second_scenario !== true
      );
      const third_result = third_scenario_res
        .map((data) => data.brows_fs.cy_amt)
        .reduce((data, num) => data + num, 0);
      third_scenario_data += third_result;
      second_scenario_data += third_result;
      first_scenario_data += third_result;
    } else if (
      preAdjustmentOther
        .map((item) => item.sub_group)
        .includes(browsDataRes[0].brows_fs.group)
    ) {
      const cal_data = preAdjustmentOther.find(
        (item) => item.sub_group === browsDataRes[0].brows_fs.group
      ).cal_data;
      const first_scenario_res = scenarioResult.filter(
        (data) => data.scenario.first_scenario === true
      );
      const first_result = first_scenario_res
        .map((data) => Number(data[cal_data]))
        .reduce((data, num) => data + num, 0);
      first_scenario_data += first_result;

      const second_scenario_res = scenarioResult.filter(
        (data) => data.scenario.second_scenario === true
      );
      const second_result = second_scenario_res
        .map((data) => Number(data[cal_data]))
        .reduce((data, num) => data + num, 0);
      second_scenario_data += second_result;
      first_scenario_data += second_result;

      const third_scenario_res = scenarioResult.filter(
        (data) =>
          data.scenario.first_scenario !== true &&
          data.scenario.second_scenario !== true
      );
      const third_result = third_scenario_res
        .map((data) => Number(data[cal_data]))
        .reduce((data, num) => data + num, 0);
      third_scenario_data += third_result;
      second_scenario_data += third_result;
      first_scenario_data += third_result;
    }
    switch (scenario) {
      case 1: {
        if (pattern_type === "pattern_2") {
          return first_scenario_data;
        } else {
          if (
            browsDataRes_other[0].brows_fs.group === "Goodwill" ||
            browsDataRes_other[0].brows_fs.group === "Brands/trademarks" ||
            browsDataRes_other[0].brows_fs.group ===
            "Investments in Equity Instruments - Unquoted"
          ) {
            return first_scenario_data;
          } else {
            return first_scenario_data * -1;
          }
        }
      }
      case 2: {
        if (pattern_type === "pattern_2") {
          return second_scenario_data;
        } else {
          if (
            browsDataRes_other[0].brows_fs.group === "Goodwill" ||
            browsDataRes_other[0].brows_fs.group === "Brands/trademarks" ||
            browsDataRes_other[0].brows_fs.group ===
            "Investments in Equity Instruments - Unquoted"
          ) {
            return second_scenario_data;
          } else {
            return second_scenario_data * -1;
          }
        }
      }
      case 3: {
        if (pattern_type === "pattern_2") {
          return third_scenario_data;
        } else {
          if (
            browsDataRes_other[0].brows_fs.group === "Goodwill" ||
            browsDataRes_other[0].brows_fs.group === "Brands/trademarks" ||
            browsDataRes_other[0].brows_fs.group ===
            "Investments in Equity Instruments - Unquoted"
          ) {
            return third_scenario_data;
          } else {
            return third_scenario_data * -1;
          }
        }
      }
      default:
        return 0;
    }
  };

  const totalAdjustment = (
    note_group_index,
    note_group,
    scenario,
    note_index
  ) => {
    try {
      const GroupData = groupingData.response.data;
      if (
        note_group_index === 0 ||
        note_group_index === 1 ||
        note_group_index === 2 ||
        note_group_index === 3 ||
        note_group_index === 4
      ) {
        let note_no = getNoteNumber(consoleNotes, note_index);
        if (note_no === "--") {
          return "...";
        }
        const groupResult = groupDataCal(groupingData, note_no);
        const Groups = getGroupName(
          GroupData?.current_company_grouping?.fs_grp,
          note_no
        );
        const adjustmentData = prepareAdjustmentData(AdjData, note_no);
        const autoPopulateData = prepareAutoPopulationData(
          APAdjustment,
          note_no
        );
        const { currentProject } = groupResult;
        const finalResult = currentProject.map(
          (projectGroup, group_index) =>
            projectGroup
              .map((data, project_index) =>
                adjustmentResult(
                  groupResult,
                  adjustmentData,
                  autoPopulateData,
                  group_index,
                  scenario,
                  project_index,
                  false,
                  getPatternType(consoleNotes, note_no)
                )
              )
              .reduce((data, num) => data + num, 0),
          Groups[0]
        );
        return finalResult.reduce((num, acc) => Number(num) + acc, 0);
        //note_group === "Other Intangible Assets" || note_group === "Goodwill" ||
      } else {
        return "--";
      }
    } catch (err) {
      // console.log("err total adj", err.message);
      return "!!";
    }
  };

  const grandTotalAdjustment = (group, scenario, total_tax = false) => {
    try {
      const GroupData =
        groupingData?.response?.data?.current_company_grouping?.fs_grp;
      if (
        group === "Income" ||
        group === "Exceptional items" ||
        group === "Profit / (loss) from discontinued operations" ||
        group === "Other Comprehensive Income"
      ) {
        const CurrentGroup = GroupData.filter((data) => data.fs_grp === group);
        const finalResult = CurrentGroup[0].notes_grp.map((noteGroup) => {
          const res = totalAdjustment(
            0,
            noteGroup.notes_grp,
            scenario,
            getNoteNoIndex(noteGroup.notes_no)
          );
          if (isNaN(res)) return 0;
          else return res;
        });
        return finalResult.reduce((num, acc) => Number(num) + acc, 0);
        // return 1
      } else if (group === "Expenses") {
        const CurrentGroup = GroupData.filter((data) => data.fs_grp === group);
        const finalResult = CurrentGroup[0].notes_grp.map((noteGroup) => {
          if (total_tax) {
            if (
              noteGroup.notes_grp === "Current tax" ||
              noteGroup.notes_grp === "Deferred tax"
            ) {
              const res = totalAdjustment(
                1,
                noteGroup.notes_grp,
                scenario,
                getNoteNoIndex(noteGroup.notes_no)
              );
              if (isNaN(res)) return 0;
              else return res;
            } else {
              return 0;
            }
          } else {
            if (
              noteGroup.notes_grp === "Current tax" ||
              noteGroup.notes_grp === "Deferred tax"
            ) {
              return 0;
            } else {
              const res = totalAdjustment(
                1,
                noteGroup.notes_grp,
                scenario,
                getNoteNoIndex(noteGroup.notes_no)
              );
              if (isNaN(res)) return 0;
              else return res;
            }
          }
        });
        return finalResult.reduce((num, acc) => Number(num) + acc, 0);
      }
      return 0;
    } catch (err) {
      return err.message;
    }
  };
  const AllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIr(res.data.fetchIR);
      setIrOtherDetails(res.data?.IrOtherDetails);
    }
  };
  const FindIr = (ir_id, isOtherDetails = false) => {
    if (isOtherDetails) {
      const IrOtherDetailData = irOtherDetails.filter((data) => data._id === ir_id);
      return IrOtherDetailData;
    }
    const IrData = allIrList.filter((data) => data._id === ir_id);
    return IrData;
  };
  const browse_fs_associate = allAssociateCheckData.map((data) =>
    FindIr(data.Ir_id)
  );

  const AssociateCheck = (
    currentProjectAssociate,
    group_name,
    scenario,
    total_tax = false
  ) => {

    if (currentProjectAssociate.length > 0) {
      if (group_name === "Expenses") {
        let res = 0;
        if (total_tax) {
          const data = currentProjectAssociate.map((det) =>
            det[group_name]?.filter(
              (data) =>
                data.notes_grp === "Current tax" ||
                data.notes_grp === "Deferred tax"
            )
          );
          res = data.map((det) => det.map((ret) => ret.cy).reduce((num, acc) => num + acc, 0)).reduce((num, acc) => num + acc, 0);
        } else {
          const data = currentProjectAssociate.map((det) =>
            det[group_name]?.filter(
              (data) =>
                data.notes_grp !== "Current tax" &&
                data.notes_grp !== "Deferred tax"
            )
          );
          res = data.map((det) => det.map((ret) => ret.cy).reduce((num, acc) => num + acc, 0)).reduce((num, acc) => num + acc, 0);
        }
        return res ? res : 0;
      }

      const associateData = currentProjectAssociate.map((det) =>
        findResult(det, group_name)
      );

      const finalData = associateData?.reduce((num, acc) => num + acc, 0);
      const finals_subsiaryData = allAssociateCheckData.map((det) =>
        fetchIr?.response?.fetchIR?.filter((data) => data._id === det.Ir_id)
      );
      const scenarioResult = finals_subsiaryData.map((sub_data, sub_index) => {
        return {
          scenario: getScenario(
            currentCompany,
            subsiaryData,
            sub_index,
            FindIr
          ),
        };
      });

      const associateAdjustment = getAssociateData(
        scenarioResult,
        finalData,
        scenario
      );
      return associateAdjustment ? associateAdjustment : 0;
    } else {
      return 0;
    }
  };

  const findResult = (data, group_name) => {
    return (
      data[group_name]
        ?.map((data) => data?.cy)
        ?.reduce((num, acc) => num + acc, 0) *
      browse_fs_associate[0][0]?.holdings
    );
  };

  const getAssociateData = (scenarioResult, finalData, scenario) => {
    let first_scenario_data = 0;
    let second_scenario_data = 0;
    let third_scenario_data = 0;

    if (scenarioResult[0].scenario.first_scenario === true && scenario === 1) {
      first_scenario_data += finalData;
    } else if (
      scenarioResult[0].scenario.second_scenario === true &&
      scenario === 2
    ) {
      second_scenario_data += finalData;
      first_scenario_data += finalData;
    } else if (
      scenarioResult[0].scenario.first_scenario === false &&
      scenarioResult[0].scenario.second_scenario === false &&
      scenario === 3
    ) {
      third_scenario_data += finalData;
      second_scenario_data += finalData;
      first_scenario_data += finalData;
    }

    switch (scenario) {
      case 1: {
        return first_scenario_data;
      }
      case 2: {
        return second_scenario_data;
      }
      case 3: {
        return third_scenario_data;
      }
      default:
        return 0;
    }
  };
  return (
    <>
      {loading ? (
        <CircularProgress disableShrink />
      ) : isSuccess ? (
        <>
          <div>
            <table style={{ width: "100%" }}>
              <TableHeader
                currentCompany={currentCompany}
                subsiaryData={subsiaryData}
                currentProjectName={currentProjectName}
                browse_fs_ids={subsidiaryData?.response?.otherData?.map((data) =>
                  subsiaryData.some(item => item.Ir_id === data._id) ? data.investment_company_id : null
                ).filter(Boolean)}
                CompanyName={CompanyName}
              />
              <tbody>
                {fsRows.map((group, groupIndex) => (
                  <React.Fragment key={getRandomKey()}>
                    <tr style={totaledStyle} key={getRandomKey()}>
                      <td style={{ minWidth: "300px" }}>{group}</td>
                      <td>{ }</td>
                      <td>
                        {CurrentProjectCy(
                          currentProjectPnl,
                          group
                        ).toLocaleString("en-IN")}
                      </td>
                      <td>
                        {CurrentProjectPy(
                          currentProjectPnl,
                          group
                        ).toLocaleString("en-IN")}
                      </td>
                      {finalSubsidiaryData.length > 0 &&
                        finalSubsidiaryData.map((project_pnl, sub_index) => (
                          <React.Fragment>
                            <td>
                              {grandTotalProjectPnlCy(
                                group,
                                sub_index
                              ).toLocaleString("en-IN")}
                            </td>
                            <td>
                              {grandTotalProjectPnlPy(
                                group,
                                sub_index
                              ).toLocaleString("en-IN")}
                            </td>
                          </React.Fragment>
                        ))}
                      <td>{grandTotalAdjustment(group, 1)}</td>
                      <td>{grandTotalAdjustment(group, 2)}</td>
                      <td>{grandTotalAdjustment(group, 3)}</td>
                      <td>
                        {(
                          CurrentProjectCy(currentProjectPnl, group) +
                          calculateGroupPnlCy(group) +
                          grandTotalAdjustment(group, 1)
                        ).toLocaleString("en-IN")}
                      </td>
                      <td>
                        {(
                          CurrentProjectPy(currentProjectPnl, group) +
                          calculateGroupPnlPy(group) +
                          grandTotalAdjustment(group, 2)
                        ).toLocaleString("en-IN")}
                      </td>
                      <td>{grandTotalAdjustment(group, 3)}</td>
                    </tr>
                    {currentProjectPnl[group].map(
                      (noteGroup, noteGroupIndex) => (
                        <React.Fragment key={getRandomKey()}>
                          <tr style={lineGroupStyle} key={getRandomKey()}>
                            <td>{noteGroup.notes_grp}</td>
                            <td
                              style={NoteStyle}
                              onClick={() =>
                                handleSelect(getNoteNoIndex(noteGroup.notes_no))
                              }
                            >
                              {getIndexNumber(noteGroup) + 36}
                            </td>
                            <td>{noteGroup.cy.toLocaleString("en-IN")}</td>
                            <td>{noteGroup.py.toLocaleString("en-IN")}</td>
                            {finalSubsidiaryData.length > 0 &&
                              finalSubsidiaryData.map(
                                (project_pnl, sub_index) => (
                                  <React.Fragment>
                                    <td>
                                      {ProjectIdPnlCy(
                                        sub_index,
                                        noteGroup.notes_grp,
                                        getNoteNoIndex(noteGroup.notes_no)
                                      ).toLocaleString("en-IN")}
                                    </td>
                                    <td>
                                      {ProjectIdPnlPy(
                                        sub_index,
                                        noteGroup.notes_grp,
                                        getNoteNoIndex(noteGroup.notes_no)
                                      ).toLocaleString("en-IN")}
                                    </td>
                                  </React.Fragment>
                                )
                              )}
                            <td>
                              {totalAdjustment(
                                groupIndex,
                                noteGroup.notes_grp,
                                1,
                                getNoteNoIndex(noteGroup.notes_no)
                              )}
                            </td>
                            <td>
                              {totalAdjustment(
                                groupIndex,
                                noteGroup.notes_grp,
                                2,
                                getNoteNoIndex(noteGroup.notes_no)
                              )}
                            </td>
                            <td>
                              {totalAdjustment(
                                groupIndex,
                                noteGroup.notes_grp,
                                3,
                                getNoteNoIndex(noteGroup.notes_no)
                              )}
                            </td>
                            <td>
                              {(
                                noteGroup.cy +
                                calculateTotalCy(noteGroup, noteGroupIndex) +
                                totalAdjustment(
                                  groupIndex,
                                  noteGroup.notes_grp,
                                  1,
                                  getNoteNoIndex(noteGroup.notes_no)
                                )
                              ).toLocaleString("en-IN")}
                            </td>
                            <td>
                              {(
                                noteGroup.py +
                                calculateTotalPy(noteGroup, noteGroupIndex) +
                                totalAdjustment(
                                  groupIndex,
                                  noteGroup.notes_grp,
                                  2,
                                  getNoteNoIndex(noteGroup.notes_no)
                                )
                              ).toLocaleString("en-IN")}
                            </td>
                            <td>
                              {totalAdjustment(
                                groupIndex,
                                noteGroup.notes_grp,
                                3,
                                getNoteNoIndex(noteGroup.notes_no)
                              )}
                            </td>
                          </tr>
                          {groupIndex === 1 && noteGroupIndex === 9 ? (
                            <>
                              <tr style={totalStyle} key={getRandomKey()}>
                                <td>
                                  {
                                    "Profit before exceptional items, share of net profits of investments accounted for using equity method and tax"
                                  }
                                </td>
                                <td>{ }</td>
                                <td>
                                  {(
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses"
                                    )
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Expenses"
                                    )
                                  ).toLocaleString("en-IN")}
                                </td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td>
                                          {(
                                            grandTotalProjectPnlCy(
                                              "Income",
                                              sub_index
                                            ) -
                                            grandTotalProjectPnlCy(
                                              "Expenses",
                                              sub_index
                                            )
                                          ).toLocaleString("en-IN")}
                                        </td>
                                        <td>
                                          {(
                                            grandTotalProjectPnlPy(
                                              "Income",
                                              sub_index
                                            ) -
                                            grandTotalProjectPnlPy(
                                              "Expenses",
                                              sub_index
                                            )
                                          ).toLocaleString("en-IN")}
                                        </td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>
                                  {(
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Income",
                                      1
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      1
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      1,
                                      true
                                    )
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {(
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Income",
                                      2
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      2
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      2,
                                      true
                                    )
                                  ).toLocaleString("en-IN")}{" "}
                                </td>
                                <td>
                                  {(
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Income",
                                      3
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      3
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      3,
                                      true
                                    )
                                  ).toLocaleString("en-IN")}{" "}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses"
                                    ) +
                                    (calculateGroupPnlCy("Income") -
                                      calculateGroupPnlCy("Expenses")) -
                                    (CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses",
                                      true
                                    ) +
                                      calculateGroupPnlCy("Expenses", true)) +
                                    (AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Income",
                                      1
                                    ) -
                                      AssociateCheck(
                                        finalCurrentProjectAssociate,
                                        "Expenses",
                                        1
                                      ) -
                                      AssociateCheck(
                                        finalCurrentProjectAssociate,
                                        "Expenses",
                                        1,
                                        true
                                      ))
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>{(
                                  CurrentProjectCy(
                                    currentProjectPnl,
                                    "Income"
                                  ) -
                                  CurrentProjectCy(
                                    currentProjectPnl,
                                    "Expenses"
                                  ) +
                                  (calculateGroupPnlCy("Income") -
                                    calculateGroupPnlCy("Expenses")) -
                                  (CurrentProjectCy(
                                    currentProjectPnl,
                                    "Expenses",
                                    true
                                  ) +
                                    calculateGroupPnlCy("Expenses", true)) +
                                  (AssociateCheck(
                                    finalCurrentProjectAssociate,
                                    "Income",
                                    2
                                  ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      2
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      2,
                                      true
                                    ))
                                ).toLocaleString("en-IN")}</td>
                                <td>{"-"}</td>
                              </tr>
                              <tr style={totalStyle} key={getRandomKey()}>
                                <td>
                                  {
                                    "Share of net profit of associates and joint ventures accounted using equity method"
                                  }
                                </td>
                                <td>{ }</td>
                                <td>-</td>
                                <td>-</td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td>-</td>
                                        <td>-</td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>
                                  {(
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Income",
                                      1
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      1
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      1,
                                      true
                                    )
                                  ).toLocaleString("en-IN")}{" "}
                                </td>
                                <td>
                                  {(
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Income",
                                      2
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      2
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      2,
                                      true
                                    )
                                  ).toLocaleString("en-IN")}{" "}
                                </td>
                                <td>
                                  {(
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Income",
                                      3
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      3
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      3,
                                      true
                                    )
                                  ).toLocaleString("en-IN")}{" "}
                                </td>
                                <td>{
                                  ((
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Income",
                                      1
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      1
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      1,
                                      true
                                    )
                                  )).toLocaleString("en-IN")
                                }</td>
                                <td>{
                                  ((
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Income",
                                      1
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      1
                                    ) -
                                    AssociateCheck(
                                      finalCurrentProjectAssociate,
                                      "Expenses",
                                      1,
                                      true
                                    )
                                  ) +
                                    (
                                      AssociateCheck(
                                        finalCurrentProjectAssociate,
                                        "Income",
                                        2
                                      ) -
                                      AssociateCheck(
                                        finalCurrentProjectAssociate,
                                        "Expenses",
                                        2
                                      ) -
                                      AssociateCheck(
                                        finalCurrentProjectAssociate,
                                        "Expenses",
                                        2,
                                        true
                                      )
                                    )).toLocaleString("en-IN")
                                }</td>
                                <td>{"-"}</td>
                              </tr>
                              <tr style={totalStyle} key={getRandomKey()}>
                                <td>
                                  {"Profit before exceptional items and tax"}
                                </td>
                                <td>{ }</td>
                                <td>
                                  {(
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses"
                                    )
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Expenses"
                                    )
                                  ).toLocaleString("en-IN")}
                                </td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td>
                                          {(
                                            grandTotalProjectPnlCy(
                                              "Income",
                                              sub_index
                                            ) -
                                            grandTotalProjectPnlCy(
                                              "Expenses",
                                              sub_index
                                            )
                                          ).toLocaleString("en-IN")}
                                        </td>
                                        <td>
                                          {(
                                            grandTotalProjectPnlPy(
                                              "Income",
                                              sub_index
                                            ) -
                                            grandTotalProjectPnlPy(
                                              "Expenses",
                                              sub_index
                                            )
                                          ).toLocaleString("en-IN")}
                                        </td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>
                                  {grandTotalAdjustment("Income", 1) -
                                    grandTotalAdjustment("Expenses", 1)}
                                </td>
                                <td>
                                  {grandTotalAdjustment("Income", 2) -
                                    grandTotalAdjustment("Expenses", 2)}
                                </td>
                                <td>
                                  {grandTotalAdjustment("Income", 3) -
                                    grandTotalAdjustment("Expenses", 3)}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses"
                                    ) +
                                    (calculateGroupPnlCy("Income") -
                                      calculateGroupPnlCy("Expenses")) +
                                    (grandTotalAdjustment("Income", 1) -
                                      grandTotalAdjustment("Expenses", 1))
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Expenses"
                                    ) +
                                    (calculateGroupPnlPy("Income") -
                                      calculateGroupPnlPy("Expenses")) +
                                    (grandTotalAdjustment("Income", 2) -
                                      grandTotalAdjustment("Expenses", 2))
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {grandTotalAdjustment("Income", 3) -
                                    grandTotalAdjustment("Expenses", 3)}
                                </td>
                              </tr>
                            </>
                          ) : groupIndex === 1 && noteGroupIndex === 11 ? (
                            <React.Fragment key={getRandomKey()}>
                              <tr style={totaledStyle} key={getRandomKey()}>
                                <td>{"Total Tax"}</td>
                                <td>{ }</td>
                                <td>
                                  {CurrentProjectCy(
                                    currentProjectPnl,
                                    "Expenses",
                                    true
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {CurrentProjectPy(
                                    currentProjectPnl,
                                    "Expenses",
                                    true
                                  ).toLocaleString("en-IN")}
                                </td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td>
                                          {grandTotalProjectPnlCy(
                                            "Expenses",
                                            sub_index,
                                            true
                                          ).toLocaleString("en-IN")}
                                        </td>
                                        <td>
                                          {grandTotalProjectPnlPy(
                                            "Expenses",
                                            sub_index,
                                            true
                                          ).toLocaleString("en-IN")}
                                        </td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>
                                  {grandTotalAdjustment("Expenses", 1, true)}
                                </td>
                                <td>
                                  {grandTotalAdjustment("Expenses", 2, true)}
                                </td>
                                <td>
                                  {grandTotalAdjustment("Expenses", 3, true)}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses",
                                      true
                                    ) +
                                    calculateGroupPnlCy("Expenses", true) +
                                    grandTotalAdjustment("Expenses", 1, true)
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Expenses",
                                      true
                                    ) +
                                    calculateGroupPnlPy("Expenses", true) +
                                    grandTotalAdjustment("Expenses", 2, true)
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {grandTotalAdjustment("Expenses", 3, true)}
                                </td>
                              </tr>
                              <tr style={totalStyle} key={getRandomKey()}>
                                <td>{"Profit for the Year"}</td>
                                <td>{ }</td>
                                <td>
                                  {(
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses"
                                    ) -
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses",
                                      true
                                    )
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Expenses"
                                    ) -
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Expenses",
                                      true
                                    )
                                  ).toLocaleString("en-IN")}
                                </td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td>
                                          {(
                                            grandTotalProjectPnlCy(
                                              "Income",
                                              sub_index
                                            ) -
                                            grandTotalProjectPnlCy(
                                              "Expenses",
                                              sub_index
                                            ) -
                                            grandTotalProjectPnlCy(
                                              "Expenses",
                                              sub_index,
                                              true
                                            )
                                          ).toLocaleString("en-IN")}
                                        </td>
                                        <td>
                                          {(
                                            grandTotalProjectPnlPy(
                                              "Income",
                                              sub_index
                                            ) -
                                            grandTotalProjectPnlPy(
                                              "Expenses",
                                              sub_index
                                            ) -
                                            grandTotalProjectPnlPy(
                                              "Expenses",
                                              sub_index,
                                              true
                                            )
                                          ).toLocaleString("en-IN")}
                                        </td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>
                                  {grandTotalAdjustment("Income", 1) -
                                    grandTotalAdjustment("Expenses", 1) -
                                    grandTotalAdjustment("Expenses", 1, true)}
                                </td>
                                <td>
                                  {grandTotalAdjustment("Income", 2) -
                                    grandTotalAdjustment("Expenses", 2) -
                                    grandTotalAdjustment("Expenses", 2, true)}
                                </td>
                                <td>
                                  {grandTotalAdjustment("Income", 3) -
                                    grandTotalAdjustment("Expenses", 3) -
                                    grandTotalAdjustment("Expenses", 3, true)}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses"
                                    ) +
                                    (calculateGroupPnlCy("Income") -
                                      calculateGroupPnlCy("Expenses")) -
                                    (CurrentProjectCy(
                                      currentProjectPnl,
                                      "Expenses",
                                      true
                                    ) +
                                      calculateGroupPnlCy("Expenses", true)) +
                                    (grandTotalAdjustment("Income", 1) -
                                      grandTotalAdjustment("Expenses", 1)) -
                                    grandTotalAdjustment("Expenses", 1, true)
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {(
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Income"
                                    ) -
                                    CurrentProjectPy(
                                      currentProjectPnl,
                                      "Expenses"
                                    ) +
                                    (calculateGroupPnlPy("Income") -
                                      calculateGroupPnlPy("Expenses")) -
                                    (CurrentProjectPy(
                                      currentProjectPnl,
                                      "Expenses",
                                      true
                                    ) +
                                      calculateGroupPnlPy("Expenses", true)) +
                                    (grandTotalAdjustment("Income", 2) -
                                      grandTotalAdjustment("Expenses", 2)) -
                                    grandTotalAdjustment("Expenses", 2, true)
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td>
                                  {grandTotalAdjustment("Income", 3) -
                                    grandTotalAdjustment("Expenses", 3) -
                                    grandTotalAdjustment("Expenses", 3, true)}
                                </td>
                              </tr>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      )
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <p className="text-danger">
          {error_message || "Something went wrong!!!"}
        </p>
      )}
    </>
  );
}

export default NewConsolePnlStatement;

const TableHeader = (props) => {
  const {
    currentCompany,
    subsiaryData,
    currentProjectName,
    browse_fs_ids,
    CompanyName,
  } = props;
  const colStyle = {
    width: "30%",

    textTransform: "capitalize",
  };
  const particlarTab = {
    width: "300px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem"
  };
  const noteCol = {
    minWidth: "100px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem"
  }

  const thBgColor = { backgroundColor: "#A2C0C2" };

  return (
    <thead>
      <tr style={thBgColor} key={getRandomKey()}>
        <th rowSpan="2" style={particlarTab}>
          <strong>Particulars </strong>
        </th>
        <th rowSpan="2" style={noteCol}>
          <strong>Notes</strong>
        </th>
        <th colSpan="2" style={colStyle}>
          <strong className="text-center" style={{ marginRight: "138px" }}>{currentProjectName}</strong>
        </th>

        {browse_fs_ids.length > 0 &&
          browse_fs_ids.map((subData, index) => (
            <th colSpan={2} style={colStyle}>
              <strong style={{ marginRight: "138px" }}>{CompanyName(subData) ?? "Name Not found"}</strong>
            </th>
          ))}
        <th colSpan="3" style={particlarTab}>
          <strong>ADJUSTMENT</strong>
        </th>
        <th colSpan="3" style={particlarTab}>
          <strong>TOTAL</strong>
        </th>
      </tr>

      <tr style={thBgColor} key={getRandomKey()}>
        <th style={{ minWidth: "132px" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ minWidth: "132px" }}>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        {subsiaryData.length > 0 &&
          subsiaryData.map((row, i) => (
            <>
              <th style={{ minWidth: "132px" }}>
                {dateFormat(
                  currentCompany.current_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
              <th style={{ minWidth: "132px" }}>
                {dateFormat(
                  currentCompany.previous_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
            </>
          ))}

        <th style={{ minWidth: "93px" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{}}>OPENING</th>

        <th style={{ minWidth: "93px" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        <th>OPENING</th>
      </tr>
    </thead>
  );
};
