import React, { useState, useEffect } from 'react'
import { Navbar } from '../../../Components/Navbar'
import { Card } from '@mui/material'
import "bootstrap/dist/css/bootstrap.min.css";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "react-bootstrap/Table";
import { useHistory } from 'react-router'
import AddIcon from "@mui/icons-material/Add";
import { getMeber,deleteUserData,getActivePlan,resetPassword} from '../ApiServices/apiHandler'
import Button from "react-bootstrap/Button";
import swal from 'sweetalert'

const AddTeam2 = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [users, setUsers] = useState([])
    const [userNo ,setUserno] = useState('')


    useEffect(() => {
        activePlandata();
        fetchMember();
    }, [])

    // const savemembers = async (membersData) => {
    //     try {
    //         // Assuming you have an API endpoint called saveMemberData
    //         const res = await saveDeletedMembers(membersData);
    //         if (res.status === 200) {
    //             console.log("Members data saved successfully.");
    //         } else {
    //             console.error("Error saving members data:", res.data);
    //         }
    //     } catch (error) {
    //         console.error("Error saving members data:", error);
    //     }
    // };

    const generatePassword = (length = 9) =>{
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@";
        let generatedPassword = "";
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          generatedPassword += charset[randomIndex];
        }
        return generatedPassword;
    
      }

      const handleResetPassword = async (id) => {
        const newPassword = generatePassword();
    
        const data = {
          tempPassword:newPassword,
          userId:id
        }
        try {
            swal({
                title: 'Are you sure?',
                text: 'Once Reset, User will not be able to login with previous password!',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            }).then(async (willReset) => {
                if (willReset) {
                    await resetPassword(data);
                    swal({
                        title: 'Reset!',
                        text: 'Password has been reset. Check your mail for your new password!',
                        icon: 'success'
                    });
                    fetchMember();
                }
            });
        } catch (error) {
          console.error('Error resetting password', error);
        }
      };


    

    const fetchMember = async () => {
        const res = await getMeber();
       
        if (res.status === 200) {
            setUsers(res.data.fetchMember);
        }
    };

    const activePlandata = async() => {
        try {
              const res = await getActivePlan()
             
              setUserno(res.data.categorizedBenefits.noOfUsers[0]);
              
              
        } catch (error) {
           console.log(error,'errorr')
        }
      } 


    const history = useHistory();

    const deleteUser = async (u_id,deletedUserData) => {
        // handleClose();
        swal({
          title: "Are you sure?",
          text: "You will not be able to recover this imaginary file!",
          icon: "warning",
          buttons: ["Cancel", "Yes, delete it!"],
          closeOnClickOutside: false,
        }).then(async (result) => {
          if (result) { // Check if 'Yes, delete it!' button is clicked
            const res = await deleteUserData(u_id);
            if (res.status === 200) {
              swal("Deleted!", "Your imaginary file has been deleted.", "success");
              fetchMember(); // Move fetchMember inside the if block
             
            } else {
              swal("Error!", "Unable to delete the imaginary file.", "error");
            }
          } else {
            // Handle the case when 'Cancel' button is clicked
            // For example, display a message to the user
            console.log("Deletion canceled by the user");
          }
        });
      };


    return (
        <div>
            <Navbar />
            <div className='d-flex'>
                <div className="container custom-container d-flex flex-column justify-content-between mt-2 h-100">
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4  m-5">Application Overview Logins</div>
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 m-5" onClick={()=> history.push("/supports")}>Contact Support</div>
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 m-5" onClick={()=> history.push("/version-updates")}> Version details and Updates </div>
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 m-5"> </div>
                </div>

                <Card className="w-100 ml-3 mt-2 ">
                    <div>
                        <div className='d-flex justify-content-between align-items-center mt-4'>
                            <div className='d-flex justify-content-between w-25 align-items-center ml-1'>
                                <p>Admin Id:</p>
                                <a>{user.email}</a>
                                
                            </div>
                            <div>
                            {/* <p>created  No-{users.length}</p>
                                <p>Acive plan user No -{userNo}</p> */}
                                <p>You can create {userNo} team members:</p>
                                
                            </div>
                            <div>

                            <Button className='flex-grow-1 mr-1 ' style={{
                                    backgroundColor: "white",
                                    color: "#03565A",
                                    border: "2px solid #03565A",
                                    textTransform: "none",
                                    fontWeight: "500",
                                }}
                                    variant="contained" onClick={()=> history.push('/deleted-Ids')}>Deleted Ids</Button>
                                    
                                
                                <Button className='flex-grow-1 mr-5 ' style={{
                                    backgroundColor: "white",
                                    color: "#03565A",
                                    border: "2px solid #03565A",
                                    textTransform: "none",
                                    fontWeight: "500",
                                }}
                                    variant="contained" onClick={()=> history.push('/view-log')}>View Logs</Button>
                            </div>
                        </div>
                        <div className='mr-5 ml-1 mt-3'>
                            <TableContainer>
                                <Table sx={{}} aria-label="caption table">
                                    {users.length > 0 ? (
                                        <>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" className="font-sans">
                                                Sl No.
                                            </TableCell>
                                            <TableCell align="center" className="font-sans">
                                                UserId
                                            </TableCell>
                                            <TableCell align="center" className="font-sans">
                                                UserName
                                            </TableCell>
                                            <TableCell align="center" className="font-sans">
                                                Access control
                                            </TableCell>
                                            <TableCell align="center" className="font-sans">
                                                Responsibilities
                                            </TableCell>
                                            <TableCell align="center" className="font-sans">
                                                Action
                                            </TableCell>
                                            <TableCell align="center" className="font-sans">
                                                Setting
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((det,index) => {
                                                    return (
                                                        <>
                                        <TableRow>
                                            <TableCell className="font-sans"
                                                style={{ backgroundColor: "white" }}
                                                align="right"
                                            // onClick={() => history.push(`/edit-user/${det._id}`)}
                                            >
                                                {index+1}
                                            </TableCell>
                                            <TableCell className="font-sans"
                                                style={{ backgroundColor: "white" }}
                                                align="center"
                                            >
                                                {" "}
                                                {det.email}<br/>
                                                {det.temp_password?<sub style={{color:'blue'}}>Temp password: {det.temp_password}</sub>:null}
                                            </TableCell>
                                            <TableCell className="font-sans"
                                                style={{ backgroundColor: "white" }}
                                                align="center"
                                            >
                                                {det.name}
                                                
                                            </TableCell>
                                            <TableCell className="font-sans"
                                                style={{ backgroundColor: "white" }}
                                                align="center"
                                            >
                                            <span style={{cursor:'pointer'}}>View</span>/<span style={{cursor:'pointer'}} onClick={()=>history.push(`/edit-user/${det._id}`)}>Edit</span>
                                            </TableCell>
                                            <TableCell className="font-sans"
                                                style={{ backgroundColor: "white" }}
                                                align="center"
                                            >
                                                {det.responsibilities}
                                            </TableCell>
                                            <TableCell
                                                className="font-sans"
                                                style={{ backgroundColor: "white" }}
                                                align="center"
                                            >
                                              <span
                                          style={{ cursor: "pointer" ,color:'red'}}
                                          onClick={() => deleteUser(det._id)}
                                        >
                                          Delete Id access
                                        </span>

                                            </TableCell>
                                            <TableCell
                                                className="font-sans"
                                                style={{ backgroundColor: "white" }}
                                                align="center"
                                            >
                                              <span
                                          style={{ cursor: "pointer" ,color:'green'}}
                                          onClick={() => handleResetPassword(det._id)}
                                        >
                                          Reset Password
                                        </span>

                                            </TableCell>
                                        </TableRow>
                                        </>)})}
                                    </TableBody>
                                    </>):""}
                                </Table>
                            </TableContainer>
                        </div>
                        <div className='ml-1'>
                            {users.length >= userNo ? (
                                <Button
                                    style={{
                                        backgroundColor: "white",
                                        color: "#03565A",
                                        border: "2px solid #03565A",
                                        textTransform: "none",
                                        fontWeight: "500",
                                    }}
                                    variant="contained"
                                    onClick={() => swal("You have reached maximum no of create Team Members")}
                                    // disabled
                                >
                                    <span>
                                        <AddIcon />
                                    </span>
                                    Add Your Team
                                </Button>
                                
                            ) : (
                                <Button
                                    style={{
                                        backgroundColor: "white",
                                        color: "#03565A",
                                        border: "2px solid #03565A",
                                        textTransform: "none",
                                        fontWeight: "500",
                                    }}
                                    variant="contained"
                                    onClick={() => history.push("/addyourteam")}
                                >
                                    <span>
                                        <AddIcon />
                                    </span>
                                    Add Your Team
                                </Button>
                            )}
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default AddTeam2