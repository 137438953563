import { getMapTBBalanceSheet } from "../../../services/api/apiHandler" 


export const fetchTTBSData = (project_id, tb_id, bsQuery = null) => {
    return dispatch => {
        if(bsQuery){
            dispatch({type:"TT_BS_ACTION_START_FINANCIAL_STATEMENT"})
        }else{
            dispatch({type:"TT_BS_ACTION_START"})
        }
        getMapTBBalanceSheet(project_id, tb_id, bsQuery).then((result)=>{
            if(bsQuery){
                dispatch({
                    type: "TT_BS_FETCH_DATA_FINANCIAL_STATEMENT",
                    payload: {data: result.data, set_payload: JSON.stringify({project_id, tb_id})}
                })
            }else{
                dispatch({
                    type: "TT_BS_FETCH_DATA",
                    payload: {data: result.data, set_payload: JSON.stringify({project_id, tb_id})}
                })
            }
        }).catch((err)=>{
            if(bsQuery){
                dispatch({
                    type:"TT_BS_FETCH_DATA_FAILED_FINANCIAL_STATEMENT",
                    payload: err.response.data.error
                })
            }else{
                dispatch({
                    type:"TT_BS_FETCH_DATA_FAILED",
                    payload: err.response.data.error
                })
            }
        })        
    }
}

export const socketTTBSUpdate = (response, payload) => {
    return dispatch => {
        dispatch({
            type: "TT_BS_SOCKET_UPDATE_DATA", 
            payload: { 
                data: response, 
                set_payload: payload 
            }
        })
    }
}