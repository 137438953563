import { Box, Modal } from "@mui/material";
import { APModal } from "../../../Console/Component/Styles/style";
import { getProjects, getProjectDetailById } from "../../../Console/Component/services/apiHandler";
import { useState, useEffect } from "react";
import moment from 'moment';
import swal from "sweetalert";
import { updateFsData } from "../ApiServices/apiHandler";
const PreSetWorkspaceModal = (props) => {
  const { open, handleClose, prepareConsoleId, selectedPresetProject, setPresetHandler, projectsData, consoldata, workspaceData, fieldModal, setOpen, presetId } = props;

  const [projects, setProjects] = useState({
    loading: false,
    isSuccess: false,
    response: [],
    error_message: null,
  });
  const [selectedProject, setSelectProject] = useState("");

  useEffect(() => {
    (async () => {
      if (open) {
        if (projectsData) {
          setProjects({
            ...projects,
            loading: false,
            isSuccess: true,
            response: projectsData,
          });
        } else {
          fetchProjects();
        }
      }
    })();
  }, [open]);


  const fetchProjects = async () => {
    setSelectProject(selectedPresetProject);
    if (projects.response.length === 0) {
      setProjects({ ...projects, loading: true });
      try {
        const response = await getProjects();
        setProjects({
          ...projects,
          loading: false,
          isSuccess: true,
          response: response.data.project,
        });
      } catch (err) {
        console.log("error in modal", err);
        setProjects({
          ...projects,
          loading: false,
          isSuccess: false,
          response: err?.response?.data || "Something went Wrong",
        });
      }
    }
  }

  const SubmitPresetValue = async () => {
    try {
      if (!selectedProject) return alert("Please select an option");
      const checkProjectValid = await getProjectDetailById(selectedProject);
      const validCheck = moment(checkProjectValid.data.project.current_year).format("DD-MM-YYYY") === moment(workspaceData[0].console_cy).format("DD-MM-YYYY") && moment(checkProjectValid.data.project.previous_year).format("DD-MM-YYYY") === moment(workspaceData[0].console_py).format("DD-MM-YYYY");

      if (validCheck) {
        // const response = await setPresetValue(prepareConsoleId, selectedProject);
        // const {_id, project_name} = projects.response.find(data=>data._id===selectedProject);
        // setPresetHandler({project_name:project_name, project_id:_id})
        prepareConsoleId(selectedProject)
        if (fieldModal === "empty") {
          let data = {
            upload_preset: selectedProject,
          };
          const res = await updateFsData(presetId, data);
          if (res.status === 200) {
            setOpen(false);
            // setUploadPresetId();
          }
        }
        handleClose();
      } else {
        setSelectProject("")
        prepareConsoleId();
        swal("", "Upload Correct Preset", "error");
        handleClose();
      }
      return "ppp"

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={APModal}>
        <div class="card-body">
          <button
            class="btn-close float-end"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="col-md-12">
            {/* <label>Upload Preset </label> */}
            {projects.loading ? (
              "loading..."
            ) : (
              <>
                {projects.isSuccess ? (
                  <>
                    <select
                      className="form-control mt-2"
                      onChange={(e) => setSelectProject(e.target.value)}
                      value={selectedProject}
                    >
                      <option>select</option>
                      {projects.response.length > 0 &&
                        projects.response.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.project_name}
                          </option>
                        ))}
                      ;
                    </select>
                    <button
                      class="btn btn-primary mt-3"
                      style={{ background: "rgb(3, 86, 90)", float: "right" }}
                      onClick={SubmitPresetValue}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <p className="text-danger">
                    {projects?.error_message ?? "Something went wrong"}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PreSetWorkspaceModal;
