import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";

const DynamicTable = ({
  matrices
}) => {
  const history = useHistory();
  const { workspaceId } = useParams();
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleExpand = (id) => {
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((row) => row !== id) : [...prev, id]
    );
  };

  const ViewTask = () => {
    history.push(`/bookclosure/${workspaceId}`)
  }

  const isRowExpanded = (id) => expandedRows.includes(id);
  return (
    <table>
      <thead style={{ backgroundColor: "#E5E7E9" }}>
        <tr>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Level 1 Mapping</th>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Level 2 Mapping</th>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Level 3 Mapping</th>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Ledger Name</th>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Module</th>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Task</th>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Uploaded TB Balance</th>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Adjusted Amount</th>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Adjustment Amount</th>
          <th className="font-size-14 custom-font-600 font-inter text-uppercase text-secondary">Action</th>
        </tr>
      </thead>
      <tbody>
        {matrices.map((dataItem, i) => (
          <React.Fragment key={i}>
            <tr onClick={() => toggleExpand(`level1-${i}`)} className="cursor-pointer">
              <td className="border border-gray-300 text-left pl-2 font-size-14">{dataItem.name}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className='text-right pr-2 font-size-14'> {dataItem.uploadedTBBalance} </td>
              <td className='text-right pr-2 font-size-14'> {dataItem.adjustedAmount} </td>
              <td className='text-right pr-2 font-size-14'> {dataItem.adjustmentAmount} </td>
              <td></td>
            </tr>
            {isRowExpanded(`level1-${i}`) &&
              dataItem.subgroup.map((label2Item, j) => (
                <React.Fragment key={`${i}-${j}`}>
                  <tr onClick={() => toggleExpand(`level2-${i}-${j}`)} className="cursor-pointer">
                    <td></td>
                    <td className="border border-gray-300 pl-2 text-left font-size-14">{label2Item.name}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='text-right pr-2 font-size-14'> {label2Item.uploadedTBBalance} </td>
                    <td className='text-right pr-2 font-size-14'> {label2Item.adjustedAmount} </td>
                    <td className='text-right pr-2 font-size-14'> {label2Item.adjustmentAmount} </td>
                    <td></td>
                  </tr>
                  {isRowExpanded(`level2-${i}-${j}`) &&
                    label2Item.subgroup.map((label3Item, k) => (
                      <React.Fragment key={`${i}-${j}-${k}`}>
                        <tr onClick={() => toggleExpand(`level3-${i}-${j}-${k}`)} className="cursor-pointer">
                          <td></td>
                          <td></td>
                          <td className="border border-gray-300 pl-2 text-left font-size-14">{label3Item.name}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className='text-right pr-2 font-size-14'> {label3Item.uploadedTBBalance} </td>
                          <td className='text-right pr-2 font-size-14'> {label3Item.adjustedAmount} </td>
                          <td className='text-right pr-2 font-size-14'> {label3Item.adjustmentAmount} </td>
                          <td></td>
                        </tr>
                        {isRowExpanded(`level3-${i}-${j}-${k}`) &&
                          label3Item.ledgers.map((lager, l) => (
                            <React.Fragment key={`${i}-${j}-${k}-${l}`}>
                              <tr onClick={() => toggleExpand(`lager-${i}-${j}-${k}-${l}`)} className="cursor-pointer">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="border border-gray-300 pl-2 text-left font-size-14">{lager?.name}</td>
                                <td></td>
                                <td></td>
                                <td className='text-right pr-2 font-size-14'> {lager.uploadedTBBalance} </td>
                                <td className='text-right pr-2 font-size-14'> {lager.adjustedAmount} </td>
                                <td className='text-right pr-2 font-size-14'> {lager.adjustmentAmount} </td>
                                <td></td>
                              </tr>
                              {isRowExpanded(`lager-${i}-${j}-${k}-${l}`) &&
                                lager.modules.map((mod, m) => (
                                  <React.Fragment key={`${i}-${j}-${k}-${l}-${m}`}>
                                    <tr onClick={() => toggleExpand(`module-${i}-${j}-${k}-${l}-${m}`)} className="cursor-pointer">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="border border-gray-300 pl-2 text-left font-size-14">{mod.name}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className='text-right pr-2 font-size-14'> {mod.adjustmentAmount} </td>
                                      <td></td>
                                    </tr>
                                    {isRowExpanded(`module-${i}-${j}-${k}-${l}-${m}`) &&
                                      mod.tasks.map((task, n) => (
                                        <tr key={`${i}-${j}-${k}-${l}-${m}-${n}`}>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className="border border-gray-300 pl-2 text-left font-size-14">{task?.name}</td>
                                          <td className="border border-gray-300 pl-2"></td>
                                          <td className="border border-gray-300 pl-2"></td>
                                          <td className="border border-gray-300 pl-2">
                                            <button onClick={ViewTask} className="custom-common-theme-text-color p-1 border-0 bg-color-inherit">
                                              view task
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                  </React.Fragment>
                                ))}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;
