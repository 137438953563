import React from 'react'
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { CheckSign } from "../../../helper/ConvertValue"
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CommonToolTip from '../../Common/CommonToolTip';

const CashFlowWorkingTable = ({ data, HandleChange, index, HandleSelect, workingOpt }) => {

    const CheckSignReturn = (n1, n2, keyI, i) => {
        let cy = CheckSign(n1)
        let py = CheckSign(n2)

        if (keyI === 3 && i === 6) {
            return cy === false || py === false ? true : false
        }
        if ([4, 5].includes(keyI) && i === 2) {
            return cy === false || py === false ? true : false
        }

        if ([0, 3].includes(keyI)) {
            if ([1, 7].includes(i)) {
                //  -ve
                return cy === true || py === true ? true : false
            } else if ([3, 5, 8, 10].includes(i)) {
                // +ve
                return cy === false || py === false ? true : false
            } else {
                return false
            }
        } else if ([1, 2, 4, 5].includes(keyI)) {
            if ([1, 5].includes(i)) {
                // -ve
                return cy === true || py === true ? true : false
            } else if ([3, 6, 8].includes(i)) {
                // +ve
                return cy === false || py === false ? true : false
            } else {
                return false
            }
        } else if ([6].includes(keyI)) {
            if ([1].includes(i)) {
                // -ve
                return cy === true || py === true ? true : false
            } else if ([2, 3].includes(i)) {
                // +ve
                return cy === false || py === false ? true : false
            } else {
                return false
            }
        } else if (keyI === 21) {
            if ([2, 3].includes(i)) {
                // -ve
                return cy === true || py === true ? true : false
            } else {
                return false
            }
        } else if (keyI === 22) {
            if (i === 3) {
                // -ve
                return cy === true || py === true ? true : false
            } else if (i === 2) {
                // +ve
                return cy === false || py === false ? true : false
            } else {
                return false
            }
        } else if (keyI === 23) {
            if ([2, 3].includes(i)) {
                // -ve
                return cy === true || py === true ? true : false
            } else {
                return false
            }
        }
    }

    const RenderOptions = (element) => {
        const subData = workingOpt.find(ele => ele?.faceValue === element?.adjustmentCashFlowStatement)?.subData || [];
        return subData.map((option, index) => 
            <option className='text-secondary' key={index} value={option.value}>{option.name}</option>
        )
    }

    return (
        <>
            <table className='bg-white'>
                <thead>
                    <tr className='bg-white'>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left' style={{ minWidth: "50px" }}>S.no</th>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left' >Adjustments Name</th>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left'>Current Year</th>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left'>Previous Year</th>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left' >Adjustment in Cash flow statement</th>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left'>Amount to be reflected in Cash Flow (CY)</th>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left'>Amount to be reflected in Cash Flow (PY)</th>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left' >Name of the item in cash flow statement</th>
                        {/*  <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left' >Internal formula reference (inside cash flow)</th>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left' >Nature of Adjustment</th>*/}
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left' style={{ minWidth: "250px" }}>Remarks</th>
                        <th className='font-size-12 border-top-0 border-bottom-0 custom-font-600 text-left' style={{ minWidth: "50px" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.length > 0 && data.map((ele, i) => (
                            <tr key={i} className={ele.isColored ? "custom-font-600 custom-bg-gray-200" : ""}>
                                <td className={`text-left pl-3 border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>{i + 1}</td>
                                <td className={`text-left pl-3 border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>
                                    {ele?.isAdjustmentName ? 
                                        <textarea 
                                            value={ele?.adjustmentsName}
                                            name='adjustmentsName'
                                            className='h-100 w-100 border-0 bg-color-inherit text-secondary'
                                            onChange={(e) => HandleSelect(e, index, i)}
                                        />
                                    : ele?.adjustmentsName}
                                </td>
                                <td className={`border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>{ele?.manualCy ?
                                    <input
                                        name='cy'
                                        type='text'
                                        value={formatIndianCurrency(ele?.cy)}
                                        className='w-100 h-100 text-right pr-3 mt-0 border-0 bg-light font-size-14 text-muted'
                                        onChange={(e) => HandleChange(i, e)}
                                    />
                                    :
                                    <div className='text-right pr-3'> {formatIndianCurrency(ele?.cy)} </div>
                                }
                                </td>
                                <td className={`border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`} >{
                                    ele?.manualPy ?
                                        <input
                                            name='py'
                                            type='text'
                                            value={formatIndianCurrency(ele?.py)}
                                            className='w-100 h-100 text-right pr-3 mt-0 border-0 font-size-14 text-muted bg-light'
                                            onChange={(e) => HandleChange(i, e)}
                                        /> :
                                        <div className='text-right pr-3'> {formatIndianCurrency(ele?.py)} </div>
                                }</td>

                                <td className={`text-left pl-3 border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>
                                    {
                                        ele?.isAdjustmentCashFlowStatement ? (
                                            <select 
                                                className='h-100 w-100 border-0 bg-color-inherit text-secondary' 
                                                name='adjustmentCashFlowStatement'
                                                value={ele?.adjustmentCashFlowStatement || ""}
                                                onChange={(e) => HandleSelect(e, index, i)} 
                                            >
                                                <option value="select">Select</option>
                                                {
                                                    workingOpt?.length > 0 && workingOpt.map((option, index) => 
                                                        <option key={index} value={option.faceValue}>{option.name}</option>
                                                    )
                                                }
                                                {/* <option 
                                                    className='cursor-pointer custom-common-theme-text-color text-center'
                                                    value="add-new-option"
                                                >
                                                    Add New Option +
                                                </option> */}
                                            </select>
                                        ) : ele?.adjustmentCashFlowStatement
                                    }
                                </td>

                                <td className={`text-right pr-3 border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>{formatIndianCurrency(ele?.cy_amount)}</td>
                                <td className={`text-right pr-3 border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>{formatIndianCurrency(ele?.py_amount)}</td>

                                <td className={`text-left pl-3 border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>
                                    { 
                                        ele?.isNameOfItemCashFlowStatement ? (
                                            <select 
                                                className='h-100 w-100 border-0 bg-color-inherit text-secondary' 
                                                name='nameOfItemCashFlowStatement'
                                                value={ele?.nameOfItemCashFlowStatement || ""}
                                                onChange={(e) => HandleSelect(e, index, i)} 
                                            >
                                                <option value="select" className='text-secondary'>Select</option>
                                                { RenderOptions(ele) }
                                                <option 
                                                    className='cursor-pointer custom-common-theme-text-color text-center'
                                                    value="add-new-option"
                                                >
                                                    Add New Option +
                                                </option>
                                            </select>
                                        ) :
                                        ele?.nameOfItemCashFlowStatement &&
                                        ele?.nameOfItemCashFlowStatement.length > 25 ? (
                                            <div className="rtooltip font-sans cursor-pointer" >{ele?.nameOfItemCashFlowStatement.substring(0, 25).concat('...')}
                                                <span className="rtooltiptext font-sans">{ele?.nameOfItemCashFlowStatement}</span>
                                            </div>
                                        ) : ele?.nameOfItemCashFlowStatement
                                    }
                                </td>
                                {/* <td className={`text-center border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>
                                        <CommonToolTip variant="primary" placement="top" title={ele?.internalFormulaReference}>
                                            <VisibilityIcon style={{ cursor:"pointer" }} />
                                        </CommonToolTip>
                                    </td>
                                */}
                                {/* <td className={`text-left pl-3 border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>{ele?.natureOfAdjustment}</td> */}
                                <td className={`border-top-0 border-bottom-0 font-size-14 text-muted ${ele.isColored ? "custom-bg-gray-200" : ""}`}>
                                    <textarea
                                        className='w-100 p-1 border-0 bg-color-inherit text-secondary'
                                        value={ele?.remarks}
                                        name='remarks'
                                        onChange={(e) => HandleSelect(e, index, i)}
                                    />
                                </td>
                                <td className={`border-top-0 border-bottom-0 ${ele.isColored ? "custom-bg-gray-200" : ""}`}>
                                    {
                                        CheckSignReturn(ele?.cy, ele?.py, index, i) &&
                                        <CommonToolTip
                                            variant="primary"
                                            title={"Value sign not matched."}
                                            placement="top"
                                        >
                                            <ErrorIcon
                                                style={{
                                                    color: "darkorange",
                                                    cursor: "pointer",
                                                }}
                                                className="financialInstrument-hoverIcon"
                                            />
                                        </CommonToolTip>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default CashFlowWorkingTable