import { dateTimeMapping, dateTimeMappingEPS, dateTimeMappingNotes } from "./dateTimeMap";

export function pyTransformDisclosure48(input, projectDetails=null) {
  let output = [];
  let labels = {
    particular: {
      value: input.label,
      style: { size: 10, width: 45, bold: true, fill_color: "f2e2b5" },
    },
    py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
    cash_flow: { value: null, style: { width: 25, fill_color: "f2e2b5" } },
    others: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
    cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
  };
  // Iterating over disclosure data
  input.disclosure_data.forEach((disclosure) => {
    let rowsData = [
      {
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 40,
            fill_color: "808080",
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cash_flow: {
          value: "As represented in Cash Flow Statement",
          style: {
            size: 10,
            width: 25,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        others: {
          value: "Others #",
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
      },
    ]; // For holding multiple arrays of rows

    // Pushing sub-disclosures
    disclosure.sub_disclosure.forEach((sub) => {
      rowsData.push({
        particular: {
          value: sub.header,
          style: {
            size: 10,
            width: 40,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        py: {
          value: sub.py,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        cash_flow: {
          value: sub.represented_cash_flow,
          style: {
            size: 10,
            width: 25,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        others: {
          value: sub.others,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        cy: {
          value: sub.cy,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
      });
    });

    // Pushing data for the current disclosure
    output.push(rowsData);
  });
  output[0].unshift(labels);

  return output;
}

export function pyTransformEPS(input, projectDetails=null) {
  let output = [];

  let labels = {
    particular: {
      value: input.label,
      style: { size: 10, width: 45, bold: true, fill_color: "f2e2b5" },
    },
    cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
    py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
  };

  // Iterating over disclosure data
  input.disclosure_data.forEach((disclosure) => {
    let rowsData = [
      {
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 40,
            fill_color: "808080",
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMappingEPS(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        py: {
          value: dateTimeMappingEPS(projectDetails, 'py'),
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
      },
    ]; // For holding multiple arrays of rows
    // Pushing sub-disclosures
    disclosure.sub_disclosure.forEach((sub) => {
      rowsData.push({
        particular: {
          value: sub.header,
          style: {
            size: 10,
            width: 40,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: sub.cy,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        py: {
          value: sub.py,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
      });
    });

    // Pushing data for the current disclosure
    output.push(rowsData);
  });
  output[0].unshift(labels);
  return output;
}

// Retirement benefit plans
export function pyTransformRBP(input, projectDetails=null) {
  let output = [];
  // Iterating over disclosure data
  input.disclosure_data.forEach((disclosure) => {
    const labels = [];
    if (disclosure.isMainHeading) {
      // Pushing main heading
      labels.push({
        particular: {
          value: input.label,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
      });
      labels.push({
        particular: {
          value: disclosure.heading,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
      });
    } else if (disclosure.isHeading) {
      // Pushing sub-heading
      labels.push({
        particular: {
          value: disclosure.headerName,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
      });
    }
    // Pushing sub-disclosure
    let columns = [];
    let rowsData = [];

    disclosure.sub_disclosure.forEach((sub) => {
      let rowData = {
        particular: {
          value: sub.header,
          style: {
            size: 10,
            bold: sub.isHeading ? true : false,
            width: 45,
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
            fill_color: "FFFFFF",
          },
        },
      };
      if (sub.gratuity_cy !== undefined) {
        rowData["gratuity_cy"] = {
          value: sub.gratuity_cy,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.gratuity_py !== undefined) {
        rowData["gratuity_py"] = {
          value: sub.gratuity_py,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.compensate_cy !== undefined) {
        rowData["compensate_cy"] = {
          value: sub.compensate_cy,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.compensate_py !== undefined) {
        rowData["compensate_py"] = {
          value: sub.compensate_py,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.dr_inc !== undefined) {
        rowData["dr_inc"] = {
          value: sub.dr_inc,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.dr_dec !== undefined) {
        rowData["dr_dec"] = {
          value: sub.dr_dec,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.er_inc !== undefined) {
        rowData["er_inc"] = {
          value: sub.er_inc,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.er_dec !== undefined) {
        rowData["er_dec"] = {
          value: sub.er_dec,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.cy_allocation !== undefined) {
        rowData["cy_allocation"] = {
          value: sub.cy_allocation,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.py_allocation !== undefined) {
        rowData["py_allocation"] = {
          value: sub.py_allocation,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.cy !== undefined) {
        rowData["cy"] = {
          value: sub.cy,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.py !== undefined) {
        rowData["py"] = {
          value: sub.py,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      rowsData.push(rowData);
    });

    // Adding columns based on data presence
    if (disclosure.sub_disclosure[0].gratuity_cy !== undefined) {
      if (disclosure.isMainHeading) {
        // Pushing main heading
        columns.push({
          particular: {
            value: input.label,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          gratuity_cy: { value: null, style: { fill_color: "f2e2b5" } },
          gratuity_py: { value: null, style: { fill_color: "f2e2b5" } },
          cy_allocation: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });

        columns.push({
          particular: {
            value: disclosure.heading,
            style: {
              size: 10,
              bold: true,
              width: 45,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          gratuity_cy: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          gratuity_py: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          cy_allocation: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });
      } else if (disclosure.isHeading) {
        // Pushing sub-heading
        columns.push({
          particular: {
            value: disclosure.headerName,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          gratuity_cy: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          gratuity_py: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          cy_allocation: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });
      }
      columns.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 45,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        gratuity_cy: {
          value: "07 February 2024 (Gratuity)",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        gratuity_py: {
          value: "07 February 2023 (Gratuity)",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_allocation: {
          value: "07 February 2024 (COMPENSATED ABSENCE)",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_allocation: {
          value: "07 February 2023 (COMPENSATED ABSENCE)",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    } else if (disclosure.sub_disclosure[0].dr_inc !== undefined) {
      if (disclosure.isMainHeading) {
        // Pushing main heading
        columns.push({
          particular: {
            value: input.label,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          dr_inc: { value: null, style: { fill_color: "f2e2b5" } },
          dr_dec: { value: null, style: { fill_color: "f2e2b5" } },
          er_inc: { value: null, style: { fill_color: "f2e2b5" } },
          er_dec: { value: null, style: { fill_color: "f2e2b5" } },
        });

        columns.push({
          particular: {
            value: disclosure.heading,
            style: {
              size: 10,
              bold: true,
              width: 45,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          dr_inc: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          dr_dec: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          er_inc: { value: null, style: { fill_color: "f2e2b5" } },
          er_dec: { value: null, style: { fill_color: "f2e2b5" } },
        });
      } else if (disclosure.isHeading) {
        // Pushing sub-heading
        columns.push({
          particular: {
            value: disclosure.headerName,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          dr_inc: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          dr_dec: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          er_inc: { value: null, style: { fill_color: "f2e2b5" } },
          er_dec: { value: null, style: { fill_color: "f2e2b5" } },
        });
      }
      columns.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 45,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        dr_inc: {
          value: "PVO DR +1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        dr_dec: {
          value: "PVO DR -1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        er_inc: {
          value: "PVO ER +1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        er_dec: {
          value: "PVO ER -1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    } else if (disclosure.sub_disclosure[0].cy_allocation !== undefined) {
      if (disclosure.isMainHeading) {
        // Pushing main heading
        columns.push({
          particular: {
            value: input.label,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { fill_color: "f2e2b5" } },
          cy_allocation: { value: null, style: { fill_color: "f2e2b5" } },
          py: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });

        columns.push({
          particular: {
            value: disclosure.heading,
            style: {
              size: 10,
              bold: true,
              width: 45,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          cy_allocation: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          py: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });
      } else if (disclosure.isHeading) {
        // Pushing sub-heading
        columns.push({
          particular: {
            value: disclosure.headerName,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          cy_allocation: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          py: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });
      }
      columns.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 45,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: "07 February 2024",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_allocation: {
          value: "PVO DR -1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: "07 February 2023",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_allocation: {
          value: "% Allocation",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    } else {
      if (disclosure.isMainHeading) {
        // Pushing main heading
        columns.push({
          particular: {
            value: input.label,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        });
        columns.push({
          particular: {
            value: disclosure.heading,
            style: {
              size: 10,
              bold: true,
              width: 45,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        });
      } else if (disclosure.isHeading) {
        // Pushing sub-heading
        columns.push({
          particular: {
            value: disclosure.headerName,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        });
      }
      columns.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 45,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMappingEPS(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMappingEPS(projectDetails, 'py'),
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    // Pushing data for the current disclosure
    rowsData.unshift(columns);
    // Pushing footer if available
    if (disclosure.isFooter) {
      rowsData.push({
        ...columns[0],
        particular: {
          value: disclosure.footer,
          style: { size: 10, width: 45, wrap_text: true, fill_color: "FFFFFF" },
        },
      });
    }
    output.push(rowsData.flat());
  });

  return output;
}

//Capital Management
export function pyTransformdis52(input, projectDetails) {
  const output = [];
  // [{columns: [{title: input.label}], data: [[{value: ""}]]}];

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
      });
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 15, fill_color: "FFFFFF" } },
        py: { value: null, style: { width: 15, fill_color: "FFFFFF" } },
      });
    }

    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
      });
    }

    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      const columns = {
        particular: {
          value: "Particular",
          style: {
            size: 10,
            width: 45,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: {
          value: dateTimeMappingEPS(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 15,
            fill_color: "808080",
            horizontal_align: "center",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMappingEPS(projectDetails, 'py'),
          style: {
            size: 10,
            width: 15,
            fill_color: "808080",
            horizontal_align: "center",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      };
      const data = item.sub_disclosure.map((subItem) => ({
        particular: {
          value: subItem.header,
          style: {
            size: 10,
            width: 45,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: {
          value: subItem.cy,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        py: {
          value: subItem.py,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
      }));

      labels.push(columns, data);
    }
    output.push(labels.flat());
  });

  return output;
}

//Fair value hierarchy
export function pyTransformdis53(input, projectDetails) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
            height: 250,
          },
        },
      });
    }

    if (item.isMainHeading === false && item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
    }

    if (item.dis_index_id === 2 || item.dis_index_id === 3) {
      if (item.sub_disclosure && item.sub_disclosure.length > 0) {
        const columns = [
          {
            particular: {
              value: "Particulars",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            carrying_value: {
              value: "Carrying Value",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            fair_value: {
              value: "Fair Value",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            fair_value_lv1: {
              value: "Fair Value (Level 1)",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            fair_value_lv2: {
              value: "Fair Value (Level 2)",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            fair_value_lv3: {
              value: "Fair Value (Level 3)",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
          },
        ];
        const data = item.sub_disclosure.map((subItem) => {
          if (subItem.isHeading) {
            return {
              particular: {
                value: subItem.header,
                style: {
                  merge_cells: 6,
                  size: 10,
                  bold: true,
                  width: "30",
                  color: "03565A",
                  fill_color: "f2e2b5",
                  horizontal_align: "left",
                  vertical_align: "top",
                  wrap_text: true,
                },
              },
            };
          } else {
            return {
              particular: {
                value: subItem.header,
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "left",
                  vertical_align: "center",
                  wrap_text: true,
                },
              },
              carrying_value: {
                value: subItem.carrying_value,
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value: {
                value: subItem.fair_value,
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv1: {
                value: subItem.fair_value_lv1,
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv2: {
                value: subItem.fair_value_lv2,
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv3: {
                value: subItem.fair_value_lv3,
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
            };
          }
        });
        columns.push(data);
        labels.push(columns.flat());
      }
    }

    if (item.dis_index_id === 4) {
      const labels2 = [
        {
          title1: {
            value: item.heading,
            style: {
              merge_cells: 5,
              width: 30,
              size: 10,
              bold: true,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "top",
            },
          },
        },
      ];
      if (item.sub_disclosure.length > 0) {
        labels2.push({
          title1: {
            value: "Each class of financial assets/liabilities",
            style: {
              size: 10,
              width: 30,
              bold: true,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          title2: {
            value: "Name of Related Party",
            style: {
              merge_cells: 5,
              size: 10,
              width: 15,
              bold: true,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
        });

        const data = item.sub_disclosure.map((subItem) => ({
          title1: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          title2: {
            value: subItem.name_of_related_party,
            style: {
              merge_cells: 5,
              size: 10,
              width: 15,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        }));
        labels2.push(data);
        labels.push(labels2.flat());
      }
    }

    if (item.dis_index_id === 5) {
      const labels3 = [];
      labels3.push({
        title1: {
          value: item.heading,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
      labels3.push({
        title1: {
          value: item.headerName,
          style: {
            merge_cells: 6,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
      labels.push(labels3);
    }
    if (item.dis_index_id === 6) {
      const labels4 = [];
      labels4.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });

      if (item.sub_disclosure.length > 0) {
        labels4.push({
          particular: {
            value: "Nature of Transactions",
            style: {
              size: 10,
              bold: true,
              width: 30,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: dateTimeMappingEPS(projectDetails, 'cy'),
            style: {
              size: 10,
              bold: true,
              width: 15,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          py: {
            value: dateTimeMappingEPS(projectDetails, 'py'),
            style: {
              size: 10,
              bold: true,
              width: 15,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        });

        const data = item.sub_disclosure.map((subItem) =>
          // [{value: subItem.header}, {value: subItem.cy}, {value: subItem.py}]
          ({
            particular: {
              value: subItem.header,
              style: {
                size: 10,
                width: 30,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            cy: {
              value: subItem.cy,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
            py: {
              value: subItem.py,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
          })
        );
        labels4.push(data);
        labels.push(labels4.flat());
      }
    }
    output.push(labels.flat());
  });

  return output;
}

// Maturity Analysis of Assets and Liabilities
export function pyTransformdis54(input, projectDetails=null) {
  const output = [];
  // {columns: [{title: input.label}], data: [[{value: ""}]]}
  input.disclosure_data.forEach((item, index) => {
    const labels = [];
    if (index === 0) {
      labels.push({
        particular: {
          value: input.label,
          style: {
            merge_cells: 7,
            size: 10,
            bold: true,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            merge_cells: 7,
            size: 10,
            fill_color: "f2e2b5",
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 7,
            size: 10,
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 7,
            height: 50,
            fill_color: "FFFFFF",
            size: 10,
            bold: true,
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: item.dis_index_id === 1 ? "Assets" : "Liabilities",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy_within_1_year: {
          value: `Within 1 year ${dateTimeMappingNotes(projectDetails, 'cy')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_after_1_year: {
          value: `After 1 year ${dateTimeMappingNotes(projectDetails, 'cy')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        total_cy: {
          value: `Total ${dateTimeMappingNotes(projectDetails, 'cy')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_within_1_year: {
          value: `Within 1 year ${dateTimeMappingNotes(projectDetails, 'py')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_after_1_year: {
          value: `After 1 year ${dateTimeMappingNotes(projectDetails, 'py')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        total_py: {
          value: `Total ${dateTimeMappingNotes(projectDetails, 'py')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        if (subItem.isHeading) {
          return {
            particular: {
              value: subItem?.header,
              style: {
                bold: true,
                size: 10,
                merge_cells: 7,
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
          };
        } else {
          return {
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 30,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
              },
            },
            cy_within_1_year: {
              value: subItem?.cy_within_1_year,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            cy_after_1_year: {
              value: subItem?.cy_after_1_year,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            total_cy: {
              value: subItem?.total_cy,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            py_within_1_year: {
              value: subItem?.py_within_1_year,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            py_after_1_year: {
              value: subItem?.py_after_1_year,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            total_py: {
              value: subItem?.total_py,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
          };
        }
      });
      labels.push(data);
      output.push(labels.flat());
    }
  });

  return output;
}

// Segment Information
export function pyTransformdis55(input, projectDetails=null) {
  const output = [];
  // {columns: [{title: input.label}], data: [[{value: ""}]]}

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            merge_cells: 9,
            size: 10,
            fill_color: "f2e2b5",
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 9,
            size: 10,
            height: 50,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Business Segments",
          style: {
            bold: true,
            size: 10,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        distribution_cy: {
          value: `Distribution ${dateTimeMappingEPS(projectDetails, 'cy')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        distribution_py: {
          value: `Distribution ${dateTimeMappingEPS(projectDetails, 'py')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        retail_finance_cy: {
          value: `Retail Finance ${dateTimeMappingEPS(projectDetails, 'cy')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        retail_finance_py: {
          value: `Retail Finance ${dateTimeMappingEPS(projectDetails, 'py')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        wholesale_finance_cy: {
          value: `Wholesale Finance ${dateTimeMappingEPS(projectDetails, 'cy')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        wholesale_finance_py: {
          value: `Wholesale Finance ${dateTimeMappingEPS(projectDetails, 'py')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        total_cy: {
          value: `Total ${dateTimeMappingEPS(projectDetails, 'cy')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        total_py: {
          value: `Total ${dateTimeMappingEPS(projectDetails, 'py')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });

      const data = item.sub_disclosure.map((subItem) => ({
        particular: {
          value: subItem?.header,
          style: {
            size: 10,
            width: 30,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        distribution_cy: {
          value: subItem?.distribution_cy,
          style: {
            size: 10,
            width: 12,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        distribution_py: {
          value: subItem?.distribution_py,
          style: {
            size: 10,
            width: 12,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        retail_finance_cy: {
          value: subItem?.retail_finance_cy,
          style: {
            size: 10,
            width: 12,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        retail_finance_py: {
          value: subItem?.retail_finance_py,
          style: {
            size: 10,
            width: 12,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        wholesale_finance_cy: {
          value: subItem?.wholesale_finance_cy,
          style: {
            size: 10,
            width: 12,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        wholesale_finance_py: {
          value: subItem?.wholesale_finance_py,
          style: {
            size: 10,
            width: 12,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        total_cy: {
          value: subItem?.total_cy,
          style: {
            size: 10,
            width: 12,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        total_py: {
          value: subItem?.total_py,
          style: {
            size: 10,
            width: 12,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      }));

      labels.push(data);

      if (item.isFooter) {
        labels.push({
          particular: {
            value: item.footer,
            style: {
              merge_cells: 9,
              size: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "top",
              wrap_text: true,
            },
          },
        });
      }
    }
    labels.push({
      particular: {
        value: null,
        style: {
          size: 10,
          width: 30,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      distribution_cy: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      distribution_py: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      retail_finance_cy: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      retail_finance_py: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      wholesale_finance_cy: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      wholesale_finance_py: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      total_cy: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      total_py: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
    });
    output.push(labels.flat());
  });

  return output;
}

// Revenue from contracts with customers
export function pyTransformdis56(input, projectDetails=null) {
  const output = [];

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 3,
            size: 10,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }

    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            bold: true,
            width: 30,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "808080",
          },
        },
        cy: {
          value: dateTimeMappingEPS(projectDetails, 'cy'),
          style: {
            size: 10,
            bold: true,
            width: 15,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "808080",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMappingEPS(projectDetails, 'py'),
          style: {
            size: 10,
            bold: true,
            width: 15,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "808080",
            wrap_text: true,
          },
        },
      });

      const data = item.sub_disclosure.map((subItem) => {
        if (subItem.hasOwnProperty("isHeading") && subItem.isHeading) {
          return {
            particular: {
              value: subItem?.header,
              style: {
                merge_cells: 3,
                size: 10,
                bold: true,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        } else {
          return {
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 30,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            cy: {
              value: subItem?.cy,
              style: {
                size: 10,
                width: 15,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            py: {
              value: subItem?.py,
              style: {
                size: 10,
                width: 15,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        }
      });
      labels.push(data);
      output.push(labels.flat());
    }
  });

  return output;
}

// Trade & Other Receivables
export function pyTransformdis58(input, projectDetails=null) {
  const output = [];

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 7, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 7,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
          },
        },
      });
    }

    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 30,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        trade: {
          value: "Trade & Other receivable days past due",
          style: {
            size: 10,
            width: 30,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        zn_days: {
          value: "0-90 days",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        no_days: {
          value: "91-180 days",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        ot_days: {
          value: "181-360 days",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        mt_days: {
          value: "more than 360 days",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        total: {
          value: "Total",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          particular: {
            value:
              subItem.hasOwnProperty("table") && subItem?.table === "cy"
                ? dateTimeMapping(projectDetails, 'cy')
                : subItem?.table === "py"
                ? dateTimeMapping(projectDetails, 'py')
                : "ECL Rate",
            style: {
              size: 10,
              width: 30,
              horizontal_align: "left",
              vertical_align: "center",
              bold: !subItem.hasOwnProperty("table") ? true : false,
              fill_color: "FFFFFF",
            },
          },
          trade: {
            value: subItem?.header,
            style: {
              size: 10,
              width: 30,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          zn_days: {
            value: subItem["0_90_days"],
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          no_days: {
            value: subItem["91_180_days"],
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          ot_days: {
            value: subItem["181_360_days"],
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          mt_days: {
            value: subItem["more_than_360_days"],
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          total: {
            value: subItem["total"],
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
        };
      });
      labels.push(data);
      output.push(labels.flat());
    }
  });

  return output;
}

//Accounting for Employee Share based Payments
export function pyTransformdis59(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 3, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 3,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 3,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 40,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        option: {
          value: "Options",
          style: {
            size: 10,
            width: 20,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        weighted_avg: {
          value: "Weighted Average Exercise Price",
          style: {
            size: 10,
            width: 20,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          particular: {
            value: subItem?.header || null,
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
          option: {
            value: subItem?.options || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          weighted_avg: {
            value: subItem?.weighted_average || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: { merge_cells: 3, size: 10, fill_color: "FFFFFF" },
        },
      });
    }
    labels.push({
      particular: { value: null, style: { width: 40, fill_color: "FFFFFF" } },
      options: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
      weighted_avg: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
    });
    output.push(labels.flat());
  });
  return output;
}

//Leases
export function pyTransformdis60(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 3, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 3,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 3,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 40,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 20,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            width: 20,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          particular: {
            value: subItem?.header || null,
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem?.cy || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          py: {
            value: subItem?.py || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: { merge_cells: 3, size: 10, fill_color: "FFFFFF" },
        },
      });
    }
    labels.push({
      particular: { value: null, style: { width: 40, fill_color: "FFFFFF" } },
      cy: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
      py: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
    });
    output.push(labels.flat());
  });
  return output;
}

//"Disclosure in compliance with RBI circular 2020-21/17 DOR.No.BP.BC/4/21.04.048/2020-21"
export function pyTransformdis62(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      if (item.sub_disclosure[0].hasOwnProperty("no_of_accounts")) {
        labels.push({
          particular: {
            value: "As At",
            style: {
              size: 10,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          acc_restrict: {
            value: "No. of accounts restructured",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          amt_outstanding: {
            value: "Amount outstanding",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          test: { value: null, style: { fill_color: "808080" } },
          test2: { value: null, style: { fill_color: "808080" } },
          test3: { value: null, style: { fill_color: "808080" } },
        });
      } else if (
        item.sub_disclosure[0].hasOwnProperty("npa_during_the_half_year")
      ) {
        labels.push(
          {
            particular: {
              value: "Format-B",
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
              },
            },
            exposure_acc: {
              value: null,
              style: {
                size: 10,
                width: 25,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            aggregate_debt: {
              value: null,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            amount_written: {
              value: null,
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
              },
            },
            amount_paid: {
              value: null,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            exposure_classified: {
              value: dateTimeMapping(projectDetails, 'cy'),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
          },
          {
            particular: {
              value: "Type of borrower",
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
              },
            },
            exposure_acc: {
              value:
                "Exposure to accounts classified as Standard consequent to implementation of resolution plan – Position as at the end of the previous half-year (A)",
              style: {
                size: 10,
                width: 25,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            aggregate_debt: {
              value:
                "Of (A), aggregate debt that slipped into NPA during the half-year",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            amount_written: {
              value: "Of (A) amount written off during the half-year",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            amount_paid: {
              value: "Of (A) amount paid by the borrowers during the half-year",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            exposure_classified: {
              value:
                "Exposure to accounts classified as Standard consequent to implementation of resolution plan – Position as at the end of this half-year",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
          }
        );
      }

      const data = item.sub_disclosure.map((subItem) => {
        if (subItem.hasOwnProperty("no_of_accounts")) {
          return {
            particular: {
              value: "As At",
              style: {
                size: 10,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "FFFFFF",
              },
            },
            acc_restrict: {
              value: subItem?.no_of_accounts,
              style: {
                size: 10,
                horizontal_align: "right",
                vertical_align: "center",
                bold: true,
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            amt_outstanding: {
              value: subItem?.amount,
              style: {
                size: 10,
                horizontal_align: "right",
                vertical_align: "center",
                bold: true,
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            test: { value: null, style: { fill_color: "FFFFFF" } },
            test2: { value: null, style: { fill_color: "FFFFFF" } },
            test3: { value: null, style: { fill_color: "FFFFFF" } },
          };
        }
        return {
          particular: {
            value: subItem?.header || null,
            style: {
              size: 10,
              width: 30,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
          exposure_acc: {
            value: subItem?.position_end_previous_half_year || null,
            style: {
              size: 10,
              width: 25,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          aggregate_debt: {
            value: subItem?.npa_during_the_half_year || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          amount_written: {
            value: subItem?.amount_written_during_half_year || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          amount_paid: {
            value: subItem?.amount_paid_during_half_year || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          exposure_classified: {
            value: subItem?.position_end_this_half_year || null,
            style: {
              size: 10,
              width: 25,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 6,
            height: item.footer.length > 200 ? 150 : 80,
            size: 10,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
    }
    if (!item.sub_disclosure[0].hasOwnProperty("no_of_accounts")) {
      labels.push({
        particular: {
          value: null,
          style: {
            size: 10,
            width: 30,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "FFFFFF",
            wrap_text: true,
          },
        },
        exposure_acc: {
          value: null,
          style: {
            size: 10,
            width: 25,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
        aggregate_debt: {
          value: null,
          style: {
            size: 10,
            width: 20,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
        amount_written: {
          value: null,
          style: {
            size: 10,
            width: 20,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
        amount_paid: {
          value: null,
          style: {
            size: 10,
            width: 20,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
        exposure_classified: {
          value: null,
          style: {
            size: 10,
            width: 25,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
      });
    }

    output.push(labels.flat());
  });
  return output;
}

// disclosure63
export function pyTransformdis63(input) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

//LCR Disclosure
export function pyTransformdis64(input) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 5, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 5,
            height: item.headerName.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 5,
            height: item.heading.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push(
        // {
        //     particular: {value: 'Particulars', style: {size: 10, horizontal_align: 'left', vertical_align: 'center', bold: true, fill_color: '808080'}},
        //     cy: {value: 'Total Unweighted Value(average)', style: {size: 10, horizontal_align: 'left', vertical_align: 'center', bold: true, fill_color: '808080', wrap_text: true}},
        //     py: {value: 'Total Weighted Value (average)', style: {size: 10, horizontal_align: 'left', vertical_align: 'center', bold: true, fill_color: '808080', wrap_text: true}},
        // },
        {
          particular: {
            value: "Particulars",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          total_unweighted_cy: {
            value: "Total Unweighted Value(average)",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          total_weighted_cy: {
            value: "Total Weighted Value (average)",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          total_unweighted_py: {
            value: "Total Unweighted Value(average)",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          total_weighted_py: {
            value: "Total Weighted Value (average)",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        }
      );
      const data = item.sub_disclosure.map((subItem) => {
        return {
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: subItem.isHeading ? true : false,
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
          total_unweighted_cy: {
            value: subItem?.cy_total_unweighted,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          total_weighted_cy: {
            value: subItem?.cy_total_weighted,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          total_unweighted_py: {
            value: subItem?.py_total_unweighted,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          total_weighted_py: {
            value: subItem?.py_total_weighted,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: { merge_cells: 5, size: 10, fill_color: "FFFFFF" },
        },
      });
    }
    labels.push({
      particular: { value: null, style: { width: 40, fill_color: "FFFFFF" } },
      total_unweighted_cy: {
        value: null,
        style: { width: 20, fill_color: "FFFFFF" },
      },
      total_weighted_cy: {
        value: null,
        style: { width: 20, fill_color: "FFFFFF" },
      },
      total_unweighted_py: {
        value: null,
        style: { width: 20, fill_color: "FFFFFF" },
      },
      total_weighted_py: {
        value: null,
        style: { width: 20, fill_color: "FFFFFF" },
      },
    });
    output.push(labels.flat());
  });
  return output;
}

// disclosure65
export function pyTransformdis65(input) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

// disclosure66
export function pyTransformdis66(input) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 5, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 5,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 5,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Nature of Assets",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy_sold: {
          value: "CY Carrying amount of sold assets",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_associate: {
          value: "CY Carrying amount of sold assets",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_sold: {
          value: "PY Carrying amount of sold assets",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_associate: {
          value: "PY Carrying amount of sold assets",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          cy_sold: {
            value: subItem.cy_carrying_amount_sold,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          cy_associate: {
            value: subItem.cy_carrying_amount_associated,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          py_sold: {
            value: subItem.py_carrying_amount_sold,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          py_associate: {
            value: subItem.py_carrying_amount_associated,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 5,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}

//disclosure67
export function pyTransformdis67(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 4, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 4,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        sl_no: {
          value: "Sr. No",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          sl_no: {
            value: subItem.sr_no,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem.cy,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          py: {
            value: subItem.py,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}

//disclosure68
export function pyTransformdis68(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 4, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 4,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        sl_no: {
          value: "Sr. No",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          sl_no: {
            value: subItem.sr_no,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem.cy,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          py: {
            value: subItem.py,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}

//disclosure69
export function pyTransformdis69(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            merge_cells: 10,
            size: 10,
            fill_color: "f2e2b5",
            bold: true,
          },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 10,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 10,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        sl_no: {
          value: "Sr. No",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        ratio: {
          value: "Ratios",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy_numerator: {
          value: "CY Numerator",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy_denominator: {
          value: "CY Denomirator",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_ratio: {
          value: "CY Ratio",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_numerator: {
          value: "PY Numerator",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_denominator: {
          value: "PY Denomirator",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_ratio: {
          value: "PY Ratio",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        variance: {
          value: "Variance",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        reason_for_variance: {
          value: "Reason for Variance (if above 25%)",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          sl_no: {
            value: subItem.sr_no,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          ratio: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          cy_numerator: {
            value: subItem.cy_numerator,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          cy_denominator: {
            value: subItem.cy_denominator,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          cy_ratio: {
            value: subItem.cy_ratio,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          py_numerator: {
            value: subItem.py_numerator,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          py_denominator: {
            value: subItem.py_denominator,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          py_ratio: {
            value: subItem.py_ratio,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          variance: {
            value: subItem.variance,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          reason_for_variance: {
            value: subItem.reason_for_variance,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 10,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}

// disclosure70
export function pyTransformdis70(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

// disclosure71
export function pyTransformdis71(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

// disclosure72
export function pyTransformdis72(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

//disclosure73
export function pyTransformdis73(input, projectDetails=null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 4, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: item.headerName,
          style: {
            merge_cells: 4,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        sl_no: {
          value: "Sr. No",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          sl_no: {
            value: subItem.sr_no,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem.cy,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          py: {
            value: subItem.py,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: item.footer,
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}
