import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

export default function AddNewAdjustmentModal({setOpen, open, HandleSubmit}) {

    const [value, setValue] = useState("")
    const [err, setErr] = useState(true);
    const shadow = {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        height: "2.5rem", border:"none", outline:"none"
    }
    const shadowErr = {
        boxShadow: "red 0px 0px 5px 0px, red 0px 0px 1px 0px",
        height: "2.5rem", border:"none", outline:"none"
    }
    const btn = {
        border:"none",
        outline:"none",
        backgroundColor:"white",
        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    }

    const SubmitData = () => {
        if(!value){
            setErr(false);
            return
        }
        HandleSubmit(value);
    }

    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Add new option name</DialogTitle>
                <DialogContent style={{minWidth:"27rem", minHeight:"10rem"}}>
                    <div>
                        <p>{"New Option Name"}</p>
                        <input className='w-100 p-1' style={err ? shadow : shadowErr} value={value} onChange={(e) => {setValue(e.target.value); setErr(true);}} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button style={btn} className='px-4 py-2 mb-2 c-modal-create' onClick={() => setOpen(false)}>Cancel</button>
                    <button style={btn} className='px-4 py-2  mr-3 mb-2 c-modal-create' onClick={SubmitData}>{"Save"}</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}