import React, { useEffect, useState } from 'react';
import BookcluosureNav from './BookcluosureNav';
import Button from '@mui/material/Button';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getBCEntries } from '../../redux/actions/BookClosure/actionBookClosure';
import { getOneBookClosure, savePreset } from '../Services/ApiHandler';
import swal from "sweetalert";
import GetPresetNameModal from '../Components/GetPresetNameModal';
import { toast } from 'react-toastify';

const BookClosureEntries = () => {
    const { workspaceId, companyId } = useParams();
    const dispatch = useDispatch();

    const {
        bookClosureId,
    } = useSelector((state) => state.BookClosureReducer);

    const {
        entries,
        sceLoading,
        bceErrorMessage,
        bceApiFetched
    } = useSelector((state) => state.BookClosureReducer);

    // Use states for preset name
    const [name, setName] = useState("");
    const [isNameModal, setIsNameModal] = useState(false);
    const [isSavePresetLoading, setIsSavePresetLoading] = useState(false);

    useEffect(() => {
        getBookClosureData();
        if (!bceApiFetched) {
            dispatch(getBCEntries(bookClosureId, companyId));
        }
    }, [dispatch]);

    const getBookClosureData = () => {
        const data = {
            // companies: [],
            // assignees: [],
            // approvers: [userId]
        }
        if (!bookClosureId) {
            getOneBookClosure(workspaceId, data).then(response => {
                dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
                const book_closure_id = response.data.bookClosure._id;
                if (book_closure_id) {
                    dispatch(getBCEntries(book_closure_id, companyId));
                }
            }).catch(error => {
                swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
            })
        }
    }

    const SaveAsPreset = () => {
        setIsSavePresetLoading(true);
        savePreset({
            name,
            bookClosureID: bookClosureId
        }).then(response => {
            setIsNameModal(false);
            setName("");
            toast.success("Preset saved successfully.")
        }).catch(error => {
            swal("Error", `Save Preset Error: ${error.response?.data?.error}`, 'error');
        }).finally(() => setIsSavePresetLoading(false))
    }

    return (
        <>
            <BookcluosureNav bookClosureId={bookClosureId} />
            <div>
                {
                    sceLoading ? <p>Loading ....</p> :
                        bceErrorMessage ? <p className='text-danger' > {bceErrorMessage} </p> :
                            entries && entries.length > 0 ? <>
                                <table>
                                    <thead style={{ backgroundColor: "#E5E7E9" }}>
                                        <tr>
                                            <th className='text-right text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>S NO</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Ledger Code</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Ledger Name</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Amount</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Preparer</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Approver</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>remarks</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Modules</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entries.map((entry, ex) => (
                                            <React.Fragment key={ex}>
                                                {/* Loop through addedLedgers array */}
                                                {entry.addedLedgers?.map((ledger, index) => (
                                                    <tr key={ledger._id}>
                                                        <td>{ex + 1}.{index + 1}</td>
                                                        <td className='text-left'>
                                                            <div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>
                                                                {ledger.ledgerCode}
                                                            </div>
                                                        </td>
                                                        <td className='text-left'>
                                                            <div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>
                                                                {ledger.ledgerName}
                                                            </div>
                                                        </td>
                                                        <td className='text-left'>
                                                            <div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>
                                                                {ledger.adjustmentAmount}
                                                            </div>
                                                        </td>
                                                        {/* Assignee and Approver will be displayed for the main entry */}
                                                        {index === 0 && (
                                                            <>
                                                                <td className='text-left' rowSpan={entry.addedLedgers.length}>
                                                                    <div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>
                                                                        {entry.assignees.map(a => a.name).join(", ")}
                                                                    </div>
                                                                </td>
                                                                <td className='text-left' rowSpan={entry.addedLedgers.length}>
                                                                    <div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>
                                                                        {entry.approver?.name}
                                                                    </div>
                                                                </td>
                                                                <td className='text-left' rowSpan={entry.addedLedgers.length}>
                                                                    <div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>
                                                                        {entry.remarks}
                                                                    </div>
                                                                </td>
                                                                <td className="text-center align-middle" rowSpan={entry.addedLedgers.length}>
                                                                    <div className="d-flex p-3 justify-content-center align-items-center w-100">
                                                                        <p className="mt-0 mb-0 font-size-16 " style={{ fontWeight: "400px" }}>
                                                                            {entry.module}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                                : <></>
                }
                <div className='d-flex justify-content-center align-items-center' style={{ height: "60vh" }}>
                    <Button
                        variant="contained"
                        className='custom-common-theme-bg-color text-capitalize font-size-16 custom-font-600 font-sans text-white'
                        style={{ width: "200px", height: "55px", borderRadius: "7px" }}
                        onClick={() => { setIsNameModal(true) }}
                    >
                        Save as preset
                    </Button>
                </div>
            </div>

            <GetPresetNameModal
                isNameModal={isNameModal}
                setIsNameModal={setIsNameModal}
                SaveAsPreset={SaveAsPreset}
                isSavePresetLoading={isSavePresetLoading}
                name={name}
                setName={setName}
            />
        </>
    );
}

export default BookClosureEntries;
