import React from 'react';
import Navbarv3 from '../Navbarv3';
import LeaseSidebar from './LeaseSidebar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    outline: "none",
    backgroundColor: "#F5F5F5",
    borderRadius: "10px",
    p: 4,
};


function Repository() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <div>
                <Navbarv3 />
            </div>
            <div className="d-flex">
                <div>
                    <LeaseSidebar />
                </div>
                <div className='px-4 w-100 mt-4'>
                    <div className='font-weight-bold'>Repository Overview</div>
                    <div>
                        <table class="table table-bordered mt-2">
                            <thead>
                                <tr>
                                    <th scope="col" >ID</th>
                                    <th scope="col">MYFINALYST ID</th>
                                    <th scope="col">LESSOR NAME</th>
                                    <th scope="col">ATTACHMENTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td >Mark</td>
                                    <td >Otto</td>
                                    <td>see all</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td >Mark</td>
                                    <td >Otto</td>
                                    <td >see all</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td >Mark</td>
                                    <td >Otto</td>
                                    <td ><Button onClick={handleOpen} style={{ width: "200px", backgroundColor: "white", border: "2px solid #03565A", color: "#03565A", textTransform: "none", fontWeight: "lighter" }}>Upload attachment</Button>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style}>
                                                <div className='d-flex justify-content-between'>
                                                    <div>
                                                        <div className='font-weight-bold h2'>Furniture</div>
                                                        <div style={{ fontSize: "14px", lineHeight: "27px", fontWeight: "400", color: "#717171" }}>Why we collect attachments needs<br /> to be mentioned here</div>
                                                    </div>
                                                    {/*  */}
                                                    <div className='d-flex'>
                                                        <div><Button style={{ color: "#03565A", textTransform: "none", marginRight: "10px", fontWeight: "bolder" }}>Cancel</Button></div>
                                                        <div><Button style={{ backgroundColor: "#03565A", color: "white", textTransform: "none" }}>Save Details</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mt-2' style={{ height: "250px", border: "dotted", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", color: "#03565A" }}>
                                                    <div>
                                                        <FolderIcon style={{ width: "60px", height: "60px", marginLeft: "80px" }} />
                                                        <br />
                                                        <span className='ml-4' style={{ fontSize: "14px" }}> Upload multiple files here</span>
                                                        <br />
                                                        <span style={{ fontSize: "14px" }}> Supported formats are xlsx, and pdf</span>
                                                    </div>


                                                </div>
                                                {/*  */}
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <div style={{ fontWeight: "inherit" }}>lessee_agreement.pdf</div>
                                                    <div><DeleteIcon style={{ color: "#03565A" }} /></div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <div>lease_agreement.xslx</div>
                                                    <div><DeleteIcon style={{ color: "#03565A" }} /></div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <div>lessee_agreement.pdf</div>
                                                    <div><DeleteIcon style={{ color: "#03565A" }} /></div>
                                                </div>
                                            </Box>
                                        </Modal>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" >4</th>
                                    <td >Mark</td>
                                    <td >Otto</td>
                                    <td ><Button style={{ width: "200px", backgroundColor: "white", border: "2px solid #03565A", color: "#03565A", textTransform: "none", fontWeight: "lighter" }}>Upload attachment</Button></td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td >Mark</td>
                                    <td >Otto</td>
                                    <td >@mdo</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*  */}
                </div>
            </div>
        </div>
    );
}
export default Repository;