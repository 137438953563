import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState } from "react";
import {
  AllWorkspace,
  DuplicateWorkspace,
  GetUser,
  WorkspaceUpdate,
  accessWorkspacedata,
  checkDeleteAccess,
  createWorkspaceName,
  deleteWorkspace,
  getAllWorkspace,
  getAllWorkspaces,
} from "../ApiServices/apiHandler";
import Card from "react-bootstrap/Card";
import CardContent from "@mui/material/CardContent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import Button from "react-bootstrap/Button";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Navbar } from "../../../Components/Navbar";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { red } from "@mui/material/colors";
import swal from "sweetalert";
import { fetchWorkspace } from "../../../redux/actions/workspace/actionAllWrokspace";
import { useSelector, useDispatch } from "react-redux";
import "../../../WorkSpace/SignUp2.css";
import addTeamMemberIcon from "./../../../../src/assets/Image/workspaceIcon/addtmember.svg"
import duplicateIcon from "./../../../../src/assets/Image/workspaceIcon/duplicate.svg"
import renameIcon from "./../../../../src/assets/Image/workspaceIcon/rename.svg"
import deleteIcon from "./../../../../src/assets/Image/workspaceIcon/delete.svg"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SearchIcon from '@mui/icons-material/Search';
import RenameSVGComponent from "../../../Components/Common/SVGComponents/RenameSVGComponent";
import SendApproval from "../Modal/sendApproval";
import SendRequest from "../Modal/sendRequest";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 789,
  height: 451,
  bgColor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const AllWorkspaceData = (props) => {
  const users = JSON.parse(localStorage.getItem('user'));
  const permissions = JSON.parse(localStorage.getItem('permission'));
  const [allWorkspace, setWorkspace] = useState([]);
  const [allAccessWorkspace, setAllAccessWorkspace] = useState([]);
  const [workspaceAll, setWorkspaceAll] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [anchorE2, setAnchorE2] = useState(null);
  const [workspaceDelete, setWorkspaceData] = useState("");
  const openTab = Boolean(anchorE2);
  const idTab = openTab ? "simple-popover" : undefined;
  const [WsPid, setWSp] = useState("");
  const dispatch = useDispatch();
  let history = useHistory();
  const [rename, setName] = useState("");
  const [renameId, setRenameID] = useState("");
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [search, setSearch] = useState('');
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [approverId, setApproverId] = useState("");
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [WID, setWID] = useState("");
  const [companyId,setCompanyIds]=useState([]);

  const handleClickWsp = (event, id, data) => {
    setAnchorE2(event.currentTarget);
    setWSp(id);
    setWorkspaceData(data);
  };
  const handleCloseTab = () => {
    setAnchorE2(null);
  };
  const handleClose = () => {
    setOpenRenameModal(false);
  };

  const handleOpenApprovalModal = () => setOpenApproveModal(true);
  const handleCloseApprovalModal = () => setOpenApproveModal(false);

  const handleOpenRequestModal = () => setOpenRequestModal(true);
  const handleCloseRequestModal = () => setOpenRequestModal(false);

  const handleApproverChange = (event) => {
    setApproverId(event.target.value);
  };

  useEffect(() => {
    accessWorkspace();
    allWorkSpace();
    WorkSpaceAll();
    GetAllUser();
  }, []);
  const allWorkSpace = async () => {
    const res = await getAllWorkspaces();
    if (res.status === 200) {
      console.log(res.data.getWorkspace, "all workspace");
      setWorkspace(res.data.workspaces);
    }
  };
  const nextStep = () => {
    if(users.responsibilities==="approver"){
      swal("Warning", "You Don't have Access to Create Workspace", "warning");
    }else{
      props.history.push("/workspacelayout");
    }
  };
  const WorkSpaceAll = async () => {
    const res = await AllWorkspace();
    if (res.status === 200) {
      setWorkspaceAll(res.data.workspace);
    }
  };
  const accessWorkspace = async () => {
    const res = await accessWorkspacedata();
    if (res.status === 200) {
      setAllAccessWorkspace(res.data.getAccessWorkspace);
    }
  };
  const handleClickData = (w_id) => {
    const statusSteps = workspaceAll.filter((adj) => adj._id === w_id);
    if (statusSteps[0]?.steps_status === "step1") {
      props.history.push(`/workspacesetup/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step2") {
      props.history.push(`/addCompanies/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step3") {
      props.history.push(`/importregister/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step4") {
      props.history.push(`/importPreset/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step5") {
      props.history.push(`/inviteteammember/${w_id}`);
    } else {
      props.history.push(`/financialstatement/${w_id}`);
    }
  };

  const workspace = (wid, data) => {
    try {
      const nameWorkspace = workspaceAll.filter((adj) => adj._id === wid);
      const result = nameWorkspace[0]?.workspace_name
        ? nameWorkspace[0]?.workspace_name
        : "";
      if (data === "id") {
        return result;
      }
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  const workspaceCy = (wid, data) => {
    try {
      const cyWorkspace = workspaceAll.filter((adj) => adj._id === wid);
      const result = cyWorkspace[0]?.standalone_cy
        ? cyWorkspace[0]?.standalone_cy
        : "";
      if (data === "id") {
        return result;
      }
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  const workspacePy = (wid, data) => {
    try {
      const cyWorkspace = workspaceAll.filter((adj) => adj._id === wid);
      const result = cyWorkspace[0]?.standalone_py
        ? cyWorkspace[0]?.standalone_py
        : "";
      if (data === "id") {
        return result;
      }
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  const GetAllUser = async () => {
    const res = await GetUser();
    if (res.status === 200) {
      setAllUser(res.data.fetchUser);
    }
  };

  const UserName = (uid) => {
    try {
      const user = allUser.filter((adj) => adj._id === uid);
      const result = user[0]?.email ? user[0]?.email : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  const handleViewOnly = async (workspace_id, check) => {
    if (check === "on") {
      let data = {
        access_status: "view",
      };
      const res = await WorkspaceUpdate(data, workspace_id);
      if (res.status === 200) {
        console.log("success");
        swal("", "View Access On", "success");
        setAnchorE2(false);
        dispatch(fetchWorkspace());
        allWorkSpace();
      }
    } else if (check === "off") {
      let data = {
        access_status: "off",
      };
      const res = await WorkspaceUpdate(data, workspace_id);
      if (res.status === 200) {
        console.log("success");
        swal("", "View Access Off", "success");
        setAnchorE2(false);
        dispatch(fetchWorkspace());
        allWorkSpace();
      }
    }
  };
  const handleChangeData = (e) => {
    setName(e.target.value);
  };
  const submitCompany = async () => {
    const data = {
      workspaceName: rename,
    };
    const result = await createWorkspaceName(renameId, data);
    if (result.status === 200) {
      setAnchorE2(false);
      setOpenRenameModal(false);
      WorkSpaceAll().then(res => {
        dispatch(fetchWorkspace());
      }).catch(err=>{})
    }
    console.log(result.data);
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  };
  const handleDuplicate = async (workspace_id) => {
    const res = await DuplicateWorkspace(workspace_id);
    if (res.status === 200) {
      dispatch(fetchWorkspace());
    }
    handleCloseTab();
  };
  const handleAddTeam = (workspace_id) => {
    history.push(`/inviteteammember/${workspace_id}`);
  };
  const handleRename = (workspace_id) => {
    setOpenRenameModal(true);
    setRenameID(workspace_id);
  };
  const shareUser = (id) => {
    try {
      const nameAccess = allAccessWorkspace.filter(
        (adj) => adj.workspace_id === id
      );
      const result = nameAccess?.email ? nameAccess?.email : "";
      return result.slice(0, 2);
    } catch (error) {
      return 0;
    }
  };

  const handleDelete = async (w_id) => {
    swal({
      title: "Are you sure",
      text: "you want to delete this Workspace ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, archive it!",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then(async (result) => {
      if (result === true) {
        const res = await deleteWorkspace(w_id);
        if (res.status === 200) {
          allWorkSpace();
          setAnchorE2(false);
        }
      }
    });
  };

  const checkingPermission=async(w_id)=>{
    const workspace_id = w_id;
    checkDeleteAccess(workspace_id)
        .then(response => {
          if(response.data.missingCompanies.length === 0){
            handleOpenApprovalModal();
            setWID(w_id);
          }else if(response.data.missingCompanies.length===response.data.approvedCompanies.length){
            handleOpenApprovalModal();
            setWID(w_id);
          }else{
            swal("Success", "You Don't have full access to delete Workspace", "success");
            handleOpenRequestModal();
            setWID(w_id);
            setCompanyIds(response.data.missingCompanies);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
  };

  //search bar//
  const handleChange = (event) => {
    const searchData = event.target.value.toLowerCase() ;
    setSearch(searchData);
    if(searchData === ''){
      allWorkSpace();
    }else{
    const filerData = allWorkspace.filter(workspace => workspace?.workspace_name?.toLowerCase().includes(searchData));
    setWorkspace(filerData)
    }
  };

  return (
    <>
      <div className="pb-2">
        <div>
          <Navbar />
        </div>
        <div className="dahboardHeaderV2">
          <div className="row">
            <div className="col-md-6">
              <h5 className="dbwcardhead">My Workspaces</h5>
            </div>
            <div className="col-6 text-right d-flex align-items-center justify-content-end" style={{ gap: "1rem" }}>
              <div className="search-btn d-flex flex-row jusify-content-center align-items-center">
                <span>
                  <SearchIcon />
                </span>
                <input
                className="border-0 mt-0 p-0"
                  type="text"
                  value={search}
                  onChange={handleChange}
                  placeholder="Search workspace name..."
                />
              </div>
              <div style={{ cursor: "pointer"}} onClick={() => history.goBack()}>
                <div className="myDIV">
                  <ArrowBackIcon />
                </div>

              </div>
              <div>
                {permissions?.companyData[0]?.components[0]?.permission?.create  === true || permissions === null ? (
                      <button className="rwp-btn"  onClick={nextStep}>
                      <span>
                        <AddIcon />
                      </span>
                      Create new workspace
    
                    </button>
                    ) : (
                      <button className="rwp-btn" disabled>
                        <span>
                          <AddIcon />
                        </span>
                        Create new workspace
                      </button>
                    )
                    }
              </div>
            </div>


          </div>
          <div className="row" style={{ marginTop: "20px" }}>
            {allWorkspace.length >0 && allWorkspace.map((det) => {
              return (
                <>
                  <div className="col-3 mb-4">
                    <Card
                      style={{ boxShadow: "0 8px 8px -4px lightblue", border: "none", borderRadius: "20px" }}
                    >
                      <CardContent>
                        <div className="d-flex justify-content-between">
                          <div
                            onClick={() => handleClickData(det._id)}
                            style={{ fontWeight: "600" }}
                          >
                            {det.workspace_name ? det.workspace_name?.length > 18 ? (
                              <div class="rtooltip" >{det.workspace_name.substring(0, 18).concat('...')}
                                <span class="rtooltiptext">{det.workspace_name}</span>
                              </div>
                            ) : (
                              <span>{det.workspace_name}</span>
                            )
                          :  <div style={{ fontWeight: "600" }}>
                           {workspace(det.workspace_id, "id")}
                           </div>
                          }
                          </div>
                          
                          <div className="d-flex flex-row">
                          <div>
                          {det.access?det.access === "Edit" ?
                           <span className="font-inter rename-icons">
                              <RenameSVGComponent height={14} width={14} color={'#32a899'} style={{marginBottom:"2px", marginRight:"2px"}} />
                              CAN EDIT
                            </span>:
                             <span className="font-inter view-icons">
                              <VisibilityIcon style={{ fontSize:"14",color:"#5d615f", marginBottom:"1px", marginRight:"2px"}}/>VIEW ONLY</span>
                          :""}
                          </div>
                            {/* {det.access_status === "view" ? (
                              <VisibilityIcon />
                            ) : (
                              // <VisibilityOffIcon/>
                              ""
                            )} */}
                            <MoreHorizIcon
                              onClick={(e) => handleClickWsp(e, det._id, det)}
                            />

                            <Popover
                              style={{ left: "-103px" }}
                              id={idTab}
                              open={openTab}
                              anchorEl={anchorE2}
                              onClose={handleCloseTab}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography className="mt-2" sx={{}}>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "12px",
                                  }}
                                  onClick={() =>
                                    handleViewOnly(WsPid, "off")
                                  }
                                >

                                  <div className="viewIcon">
                                    <VisibilityIcon className="mt-1 ml-3" style={{ width: "18px", height: "18px", color: "black" }} />
                                    <span className="mt-1 ml-1 font-sans">View Only</span>
                                  </div>
                                </span>
                                <br />
                                {workspaceDelete.access_status === "view" ? (
                                  <span
                                  className="font-sans "
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "12px",
                                      color: "grey",
                                    }}
                                  >
                                    {"Add Team Member"}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => handleAddTeam(WsPid)}
                                  >
                                    <div className="view-options">
                                      <img
                                        className="icon-img ml-3"
                                        src={addTeamMemberIcon}
                                        alt="Add Team Member Icon"
                                        style={{
                                          filter: "grayscale(100%)",
                                        }}
                                      />
                                      <span className="mt-1 ml-1 font-sans ">Add Team Member</span>
                                    </div>
                                  </span>
                                )}
                                <br />
                                {workspaceDelete.access_status === "view" ? (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "12px",
                                      color: "grey",
                                    }}
                                  >
                                    {"Duplicate"}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => handleDuplicate(WsPid)}
                                  >
                                    <div className="view-options">
                                      <img className="icon-img ml-3"
                                        src={duplicateIcon}
                                        alt="Duplicate icon"
                                      />
                                      <span className="mt-1 ml-1 font-sans ">Duplicate</span>
                                    </div>
                                  </span>
                                )}
                                <br />
                                {workspaceDelete.access_status === "view" ? (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "12px",
                                      color: "grey",
                                    }}
                                  >
                                    {"Rename"}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "12px",
                                    }}
                                    // onClick={() => handleRename(WsPid)}
                                  >
                                    <div className="view-options">
                                      <img className="icon-img ml-3"
                                        src={renameIcon}
                                        alt="rename icon"
                                      />
                                      <span className="mt-1 ml-1 font-sans ">Rename</span>
                                    </div>
                                  </span>
                                )}
                                <br />
                                {workspaceDelete.access_status === "view" ? (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "12px",
                                      color: "grey",
                                    }}
                                  >
                                    {"Delete"}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "12px",
                                    }}
                                    // disabled={users.responsibilities==="approver"}
                                    onClick={() => {
                                      if (users.responsibilities === "preparer") {
                                        checkingPermission(WsPid)
                                      }else if(users.responsibilities==="approver"){
                                        return
                                      } else {
                                        handleDelete(WsPid);
                                      }
                                    }}
                                  >
                                    <div className="view-options"
                                    >
                                      <img className="icon-img ml-3"
                                        src={deleteIcon}
                                        alt="rename icon"
                                      />
                                      <span className="mt-1 ml-1 font-sans">Delete</span>
                                    </div>
                                  </span>
                                )}
                              </Typography>
                            </Popover>
                          </div>
                        </div>
                        <div
                          onClick={() => handleClickData(det._id)}
                          style={{ fontSize: "12px",color:"#8B909A" }}
                        >
                          <>
                          {det?.workspace_name ?
                         <span>Created {moment(det.createdAt).fromNow()}</span>  :
                          <div className="font-inter" style={{fontWeight: "400", fontSize: "12px"}}>
                            Shared by{" "}
                            {UserName(det?.user_id).substring(
                                  0,
                                  UserName(det?.user_id).lastIndexOf("@")
                             )}
                            </div>}
                            </>
                        </div>
                        {/* <div
                          onClick={() => handleClickData(det._id)}
                          style={{ marginTop: "15px", fontWeight: "600" }}
                        >
                          Team Members
                        </div> */}

                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {shareUser(det._id) === "" ? (
                            ""
                          ) : (
                            <div
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "25px",
                                backgroundColor: "yellow",
                              }}
                              onClick={() => handleClickData(det._id)}
                            >
                              <div
                                style={{
                                  marginTop: "14px",
                                  marginLeft: "15px",
                                }}
                              >
                                {shareUser(det._id)}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="fs-inter"
                          style={{
                            marginTop: "8px",
                            fontWeight: "600",
                            fontSize: "12px",
                            color:"#4F4F4F"
                          }}
                          onClick={() => handleClickData(det._id)}
                        >
                         
                          {det.standalone_cy ?  <span className="font-inter">
                            CY :<span className="font-sans">{det.standalone_cy}</span>
                            </span>  :
                          <span className="font-inter">
                          CY :<span className="font-sans">{workspaceCy(det.workspace_id, "id")}</span>
                          </span>
                          }
                        </div>
                        <div className="fs-inter"
                          style={{
                            marginTop: "8px",
                            fontWeight: "600",
                            fontSize: "12px",
                            color:"#4F4F4F"
                          }}
                          onClick={() => handleClickData(det._id)}
                        >
                         
                          {det.standalone_py ?  <span className="font-inter">
                          PY :<span className="font-sans">{det.standalone_py}</span> 
                          </span> :
                           <span className="font-inter">
                          PY : <span className="font-sans">{workspacePy(det.workspace_id, "id")}</span>
                          </span>
                          }
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <SendRequest
       openRequestModal={openRequestModal}
       handleCloseRequestModal={handleCloseRequestModal}
       companyId={companyId}
       WID={WID}
       WSPDATA={workspaceDelete}
       />
      <SendApproval
       user={users}
       approverId={approverId}
       openApproveModal={openApproveModal}
       handleCloseApprovalModal={handleCloseApprovalModal}
       handleApproverChange={handleApproverChange}
       WID={WID}
       WSPDATA={workspaceDelete}
       />
       
      <Modal
        open={openRenameModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="col-12">
            <h5>Rename Workspace</h5>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  defaultValue={workspace(renameId,"id")}
                  onChange={(e) => handleChangeData(e)}
                />
              </div>
              <input
                type="button"
                className="btn btn-primary1"
                value="Submit"
                onClick={submitCompany}
              />
              <input
                type="button"
                className="btn btn-defult"
                value="Close"
                onClick={handleClose}
              />
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AllWorkspaceData;
