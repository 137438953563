import { Box, Button, FormControl, MenuItem, Modal, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getCompany,permittedPreparerIds,createRequest,getOneRequest } from '../ApiServices/apiHandler';
import swal from 'sweetalert';
import { socket } from '../../../services/socket/socket';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

const SendRequest = ({ openRequestModal, handleCloseRequestModal, companyId, WID,WSPDATA }) => {
  const [company, setAllCompany] = useState([]);
  const [cmpId,setCmpid] =useState('');
  const [preparer,setPreparer] =useState([]);
  const [preparerId,setPreparerId]=useState([]);
  const [requestData,setRequestData]=useState([]);
  const [openCancelModal,setOpenCancelModal]=useState(false);
  useEffect(() => {
    const fetchAllCompany = async () => {
      const res = await getCompany();
      setAllCompany(res.data.getCompany);
    };

    fetchAllCompany();
  }, []);
  useEffect(() => {
    if (companyId.length > 0 && WID) {
        PermitPreparers();
      }
    }, [companyId, WID]);
    useEffect(() => {
        if (companyId.length > 0) {
            getRequests();
        }
    }, [companyId]);

    const handleChange = (event) => {
      setPreparerId(event.target.value);
    };

    const handleOpenCancelModal = () => setOpenCancelModal(true);
    const handleCloseCancelModal = () => setOpenCancelModal(false);

  const PermitPreparers = async()=>{
    const workspace_id = WID;
    const data={
        companyIds:companyId
    }
    const response = await permittedPreparerIds(workspace_id,data);
    if(response.status === 200){
        setPreparer(response.data.response);
    }
  }

  const createRequstForAccess=async(c_id)=>{
    const data={
        workspace_id:WID,
        workspace_name:WSPDATA.workspace_name,
        company_id:c_id,
        status:"waiting",
        preparer_id:preparerId
    }
    const response = await createRequest(data)
    if(response.status === 200){
        getRequests();
        swal("Success", "Successfully request sent", "success");
        socket.emit("add-notification", {
          receiver_id: preparerId,
          description: "Deletion of workspace",
          type:"send request",
          path:"/Notification"
        });
        socket.emit('get-notification');
    }
  }

  const getRequests = async()=>{
    const data={
        company_id:companyId,
        workspace_id:WID,
    }
    const response = await getOneRequest(data);
    if(response.status === 200){
        setRequestData(response.data.response);
    }
  }

  const getCompanyName = (c_id) => {
    const companyData = company.find((adj) => adj._id === c_id);
    return companyData?.company_name || 'Unknown';
  };

  return (
    <div>
    <Modal
      open={openRequestModal}
      onClose={handleCloseRequestModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="d-flex justify-content-end">
          <Button onClick={handleCloseRequestModal} className='m-0 text-capitalize' style={{color:"#03565a"}}>Close</Button>
        </div>
        {companyId && companyId.length > 0 && companyId.map((company) => (
          <div key={company} style={{ marginBottom: '16px' }}>
            <div>{getCompanyName(company)}:</div>
            <FormControl fullWidth>
              <Select
                labelId="preparer-select-label"
                id={`preparer-select-${company}`}
                value={preparerId} 
                onChange={handleChange} 
                disabled={requestData.some(data => data.company_id === company && (data.status === "approved" || data.status === "waiting"))}
              >
                {preparer&&preparer.length>0&&preparer.map((user)=>
                <MenuItem value={user._id}>{user.name}</MenuItem>
                 )}
              </Select>
            </FormControl>
            <div className='d-flex justify-content-center'>
              <Button className='custom-common-theme-bg-color font-sans text-white m-1 w-50 text-capitalize' 
              onClick={() => createRequstForAccess(company)}
              disabled={requestData.some(data => data.company_id === company && (data.status === "approved" || data.status === "waiting"))}>
               {requestData.some(data => data.company_id === company && data.status === "approved") ? "Approved" : requestData.some(data => data.company_id === company && data.status === "waiting")? "Already Sent Request":"Send Request"}
              </Button>
              <Button className='custom-common-theme-bg-color font-sans text-white m-1 w-50 text-capitalize'
              onClick={()=>{handleOpenCancelModal()
                setCmpid(company)
              }}
              >Remark</Button> 
            </div>
          </div>
        ))}
      </Box>
    </Modal>
     <Modal
     open={openCancelModal}
     onClose={handleCloseCancelModal}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
     >
     <Box sx={style}>
         <div style={{maxHeight:"150px",overflowY:"auto"}}>
          {requestData.find(data => data.company_id === cmpId)?.cancel_reason?
           <p>{requestData.find(data => data.company_id === cmpId)?.cancel_reason}</p>:
           <center> There are no remarks </center>}
         </div>
         <div className="d-flex  justify-content-center mt-3 ">
           <Button
             style={{
             backgroundColor: "#03565a",
             color: "white",
             textTransform: "none",
             width:"50%",
             marginTop:"10px"
             }}
             onClick={handleCloseCancelModal}
           >
             Close
           </Button>
       </div>
     </Box>
    </Modal>
</div>
  );
};

export default SendRequest;
