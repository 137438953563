import axios from "axios"

const auth = localStorage.getItem("auth_token");
const headers = {
    "x-auth-token": auth,
};

export const getFS = async (project_id, tb_id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/v1/fs-classification/get-fs/${project_id}/${tb_id}`, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const saveCOA = async (project_id, data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/v1/fs-classification/save-coa/${project_id}`, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};