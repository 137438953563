import LeaseSidebar2 from "./LeaseSidebar2";
import "../../../WorkSpace/font.css";
import "../Lease/leasesidebar.css";
import "../../../../src/WorkSpace/font.css";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PercentIcon from "@mui/icons-material/Percent";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddBoxIcon from "@mui/icons-material/AddBox";
const Financialparameters = () => {
  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <LeaseSidebar2 />
          </div>
          <div className="d-flex flex-column w-100">
            <div className="px-5 mt-5 fin-main-inp-w">
              <p className="fs-inter createstep">STEP 2 OF 4</p>
              <h4 className="fs-inter createhead">Financial parameters</h4>
              <p className="dm-sans create-para">
                Why for this needs to be mentioned
              </p>

              <div className="row">
                <div className="col-md-6">
                  <p className="dm-sans fin-lab" style={{ marginTop: "12px" }}>
                    When does your lease start?
                  </p>
                  <input
                    type="date"
                    name=""
                    id=""
                    style={{
                      height: "60px",
                      border: "1px solid gray",
                      marginTop: "-3px",
                      borderRadius: "7px",
                      minWidth: "426px",
                      // color: "transparent",
                      fontSize: "22px",
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <p className="dm-sans fin-lab" style={{ marginTop: "12px" }}>
                    When does your lease end?
                  </p>
                  <input
                    type="date"
                    name=""
                    placeholder=""
                    id=""
                    style={{
                      height: "60px",
                      border: "1px solid gray",
                      marginTop: "-3px",
                      borderRadius: "7px",
                      minWidth: "426px",
                      // color: "transparent",
                      fontSize: "22px",
                    }}
                  />
                </div>
              </div>
              {/* second row */}
              <div className="row">
                <div className="col-md-6 fin-lab-div">
                  <span className="dm-sans fin-lab">
                    Percentage of discount
                  </span>

                  <div className="d-flex align-items-center fin-inp-div">
                    <input
                      style={{
                        border: "none",
                        outline: "none",
                        marginTop: "0px",
                      }}
                      type="text"
                      name=""
                    />
                    <PercentIcon style={{ marginRight: "17px" }} />
                  </div>
                </div>
                <div className="col-md-6 fin-drop">
                  <p
                    className="dm-sans create-inp-lab"
                    style={{ marginTop: "14px" }}
                  >
                    How would you like to discount your payments?
                  </p>

                  <select
                    className="createsel create-inp"
                    style={{ marginTop: "-3px" }}
                  >
                    <option></option>
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div>
              <hr />
              {/* end second row */}
              {/* start 3rd row */}
              <Accordion className="fin-acco-dv ">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="dm-sans fin-acco-head">
                    Lease payment 1
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="row">
                      <div className="col-md-6">
                        <p
                          className="dm-sans fin-lab"
                          style={{ marginLeft: "0px", marginTop: "12px" }}
                        >
                          From date
                        </p>
                        <input
                          type="date"
                          name=""
                          id=""
                          style={{
                            height: "60px",
                            border: "1px solid gray",
                            marginTop: "-4px",
                            borderRadius: "7px",
                            minWidth: "426px",
                            // color: "transparent",
                            fontSize: "22px",
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <p
                          className="dm-sans fin-lab"
                          style={{ marginTop: "12px" }}
                        >
                          To date
                        </p>
                        <input
                          type="date"
                          name=""
                          placeholder=""
                          id=""
                          style={{
                            height: "60px",
                            border: "1px solid gray",
                            marginTop: "-4px",
                            borderRadius: "7px",
                            minWidth: "426px",
                            // color: "transparent",
                            fontSize: "22px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6" style={{ marginTop: "37px" }}>
                        <label
                          className="dm-sans fin-lab"
                          style={{ marginTop: "-27pxpx" }}
                        >
                          Value of lease payment
                        </label>
                        <input
                          type="text"
                          name=""
                          placeholder=""
                          style={{ marginTop: "7px", minWidth: "426px" }}
                        />
                      </div>
                      <div className="col-md-6 fin-drop">
                        <p className="dm-sans create-inp-lab">
                          Frequency of payment
                        </p>

                        <select className="createsel create-inp">
                          <option></option>
                          <option>1</option>
                          <option>2</option>
                        </select>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* end 3rd row */}

              {/* 4th row */}
              <Accordion className="fin-acco-dv mt-3">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="dm-sans fin-acco-head">
                    <div className="d-flex">
                      <div>Lease payment 2</div>
                      <div>
                        <DeleteOutlineIcon
                          style={{ color: "red", marginLeft: "678px" }}
                        />
                      </div>
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {/* <div className="row">
                      <div className="col-md-6">
                        <p className="dm-sans fin-lab">From date</p>
                        <input
                          type="date"
                          name=""
                          id=""
                          style={{
                            height: "63px",
                            border: "1px solid gray",
                            marginTop: "-11px",
                            borderRadius: "7px",
                            minWidth: "426px",
                            // color: "transparent",
                            fontSize: "22px",
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <p className="dm-sans fin-lab">To date</p>
                        <input
                          type="date"
                          name=""
                          placeholder=""
                          id=""
                          style={{
                            height: "63px",
                            border: "1px solid gray",
                            marginTop: "-11px",
                            borderRadius: "7px",
                            minWidth: "426px",
                            // color: "transparent",
                            fontSize: "22px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6" style={{ marginTop: "37px" }}>
                        <label className="dm-sans fin-lab">
                          Value of lease payment
                        </label>
                        <input
                          type="text"
                          name=""
                          placeholder=""
                          style={{ marginTop: "0px" }}
                        />
                      </div>
                      <div className="col-md-6 fin-drop">
                        <p className="dm-sans create-inp-lab">
                          Frequency of payment
                        </p>

                        <select className="createsel create-inp">
                          <option></option>
                          <option>1</option>
                          <option>2</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-6">
                        <p
                          className="dm-sans fin-lab"
                          style={{ marginLeft: "0px", marginTop: "12px" }}
                        >
                          From date
                        </p>
                        <input
                          type="date"
                          name=""
                          id=""
                          style={{
                            height: "60px",
                            border: "1px solid gray",
                            marginTop: "-4px",
                            borderRadius: "7px",
                            minWidth: "426px",
                            // color: "transparent",
                            fontSize: "22px",
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <p
                          className="dm-sans fin-lab"
                          style={{ marginTop: "12px" }}
                        >
                          To date
                        </p>
                        <input
                          type="date"
                          name=""
                          placeholder=""
                          id=""
                          style={{
                            height: "60px",
                            border: "1px solid gray",
                            marginTop: "-4px",
                            borderRadius: "7px",
                            minWidth: "426px",
                            // color: "transparent",
                            fontSize: "22px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6" style={{ marginTop: "37px" }}>
                        <label
                          className="dm-sans fin-lab"
                          style={{ marginTop: "-27pxpx" }}
                        >
                          Value of lease payment
                        </label>
                        <input
                          type="text"
                          name=""
                          placeholder=""
                          style={{ marginTop: "7px", minWidth: "426px" }}
                        />
                      </div>
                      <div className="col-md-6 fin-drop">
                        <p className="dm-sans create-inp-lab">
                          Frequency of payment
                        </p>

                        <select className="createsel create-inp">
                          <option></option>
                          <option>1</option>
                          <option>2</option>
                        </select>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* end 4th row */}
              <AddBoxIcon
                style={{
                  color: "#03565A",
                  height: "48px",
                  width: "48px",
                  marginLeft: "-4px",
                  marginTop: "12px",
                }}
              />
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <p className="dm-sans fin-lab">Enter security deposit</p>
                  <input
                    type="text"
                    name=""
                    placeholder=""
                    style={{ marginTop: "-3px", minWidth: "426px" }}
                  />
                </div>
                <div className="col-md-6" style={{ marginTop: "-16px" }}>
                  <div className="col-md-6 fin-lab-div">
                    <span className="dm-sans fin-lab">
                      Percentage of discount
                    </span>

                    <div className="d-flex align-items-center fin-inp-div">
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          marginTop: "0px",
                        }}
                        type="text"
                        name=""
                      />
                      <PercentIcon style={{ marginRight: "17px" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between createbtn px-5">
              <div style={{ cursor: "pointer" }}>
                <ArrowBackIcon style={{ color: "#03565A" }} />
                &nbsp; &nbsp;
                <span style={{ color: "#03565A", fontWeight: "bold" }}>
                  Back to the previous step
                </span>
              </div>
              <div>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#03565A",
                    textTransform: "none",
                    width: "200px",
                    border: "2px solid #03565A",
                  }}
                >
                  Create lease
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Financialparameters;
