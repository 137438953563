import axios from "axios"

const auth = localStorage.getItem("auth_token");
const headers = {
    "x-auth-token": auth,
};

const postAPI = async (url, data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

const updateAPI = async (url, data) => {
    return new Promise((resolve, reject) => {
        axios
            .put(url, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export {postAPI, updateAPI};