import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import "../Disclosure.css";
import swal from "sweetalert";
import { ConvertLocaleString } from "../../../helper/ConvertValue";

const FinancialTable = ({ data, HandleChange, title, table, onchangeProp, HandleDelete, handleSelect, isDeleted }) => {

    const HandleDeleteAlert = (ind, onchangeData, element, key_val) => {
        swal({
            title: "Are you sure?",
            text: isDeleted ? "This item will be restored!" : "This item will be deleted and stored in the deleted items list!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((isConfirm) => {
            if (isConfirm) {
                HandleDelete(ind, onchangeData, element, key_val);
            }
        })
    }

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="p-2 m-0 d-flex justify-content-between"
                    style={{ margin: "10px" }}
                >
                    <p className="p-0 m-0">{title}</p>
                </AccordionSummary>
                <AccordionDetails className="p-0 m-0">
                    <div className="d-flex justify-content-end mr-2" style={{ gap: "1rem" }} >
                        <div className="my-2 rounded deleteBtn" onClick={() => HandleDeleteAlert("", onchangeProp, data.length > 0 && data, "multi")} >
                            <div className="px-4 py-2" style={{ color: "#03565a", fontWeight: "600" }} >
                                {isDeleted ? "Restore All Item" : "Delete All Item"}
                            </div>
                        </div>
                    </div>
                    <table>
                        <tbody>
                            {data.length > 0 &&
                                data.map((ele, i) => {
                                    const nodeNo =
                                        i === 0 ? (
                                            <td
                                                style={{ width: "9%" }}
                                                rowSpan={data.length + 1}
                                                className="text-center bg-light"
                                                onClick={() => handleSelect(ele?.note_no === 12 ? 7 : ele?.note_no === 13 ? 8 : ele?.note_no === 14 ? 9 : ele?.note_no === 26 ? 22 : 23)}
                                            >
                                                <p className="financial-instrument-note">{ele?.note_no}</p>
                                            </td>
                                        ) : null;

                                    return (
                                        <tr className="" key={i}>
                                            <td
                                                style={{ width: "35%" }}
                                                rowSpan={1}
                                                className="pl-2 text-left bg-light"
                                            >
                                                {ele?.header}
                                            </td>
                                            {/* <th style={{ width: "9%" }} rowSpan={data.length} className='text-left bg-light'>{ele.note_no}</th> */}
                                            {table === 1 ? (
                                                nodeNo
                                            ) : (
                                                <td
                                                    style={{ width: "9%" }}
                                                    className="text-center bg-light"
                                                >
                                                    {ele?.carryingAmountCy}
                                                </td>
                                            )}
                                            <td
                                                style={{ width: "9%" }}
                                                className="pl-2 text-left bg-light"
                                            >
                                                {table === 1 ? ConvertLocaleString(ele?.level_1) : ele.fairValueCy}
                                            </td>
                                            <td
                                                style={{ width: "9%" }}
                                                className={`pl-2 ${table === 1 ? "text-left" : "text-center"
                                                    } bg-light`}
                                            >
                                                {table === 1 ? ConvertLocaleString(ele?.level_2) :
                                                    <input type="checkbox" checked={ele?.setFairValueCy} onChange={() => HandleChange(!ele?.setFairValueCy, i, onchangeProp, "table3_cy")} />}
                                            </td>
                                            <td
                                                style={{ width: "9%" }}
                                                className="pl-2 text-left bg-light"
                                            >
                                                {table === 1 ? ConvertLocaleString(ele?.level_3) : ele?.carryingAmountPy}
                                            </td>
                                            <td
                                                style={{ width: "9%" }}
                                                className="pl-2 text-left bg-light"
                                            >
                                                {table === 1 ? ConvertLocaleString(ele?.total) : ele.fairValuePy}
                                            </td>
                                            <td
                                                style={{ width: "14%" }}
                                                className={`${table === 1 ? "text-left" : "text-center"
                                                    } bg-light p-0`}
                                            >
                                                {table === 1 ? (
                                                    <select
                                                        onChange={(e) =>
                                                            HandleChange(e.target.value, i, onchangeProp, "")
                                                        }
                                                        value={ele?.hierarchy}
                                                        className="pl-2 w-100 h-100 p-2 bg-light border-0"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="level_1">Level 1</option>
                                                        <option value="level_2">Level 2</option>
                                                        <option value="level_3">Level 3</option>
                                                    </select>
                                                ) : (
                                                    <input type="checkbox" checked={ele?.setFairValuePy} onChange={() => HandleChange(!ele?.setFairValuePy, i, onchangeProp, "table3_py")} />
                                                )}
                                            </td>
                                            <td style={{ width: "6%" }} className="bg-light">
                                                {table === 1 ? (
                                                    <div
                                                        className="d-flex justify-content-center align-items-center"
                                                        style={{ gap: "0.5rem" }}
                                                    >
                                                        {!ele?.hierarchy && (
                                                            <Tooltip
                                                                title={"Not mapped yet."}
                                                                placement="top"
                                                            >
                                                                <ErrorIcon
                                                                    style={{
                                                                        color: "darkorange",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    className="financialInstrument-hoverIcon"
                                                                />
                                                            </Tooltip>
                                                        )}
                                                        <div
                                                            style={{
                                                                cursor: "pointer",
                                                                height: "1rem",
                                                                width: "1rem",
                                                            }}
                                                            className="d-flex justify-content-center align-items-center"
                                                            onClick={() => HandleDeleteAlert(i, onchangeProp, ele, "single")}
                                                        >
                                                            {isDeleted ?
                                                                <RestoreFromTrashIcon className="financialInstrument-restoreIconHover" />
                                                                :
                                                                <div className="financialInstrument-deleteIconHover fas fa-trash-alt" />
                                                            }
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        className="h-100 w-100 d-flex justify-content-center align-items-center"
                                                        onClick={() => HandleDeleteAlert(i, onchangeProp, ele, "single")}
                                                    >
                                                        {isDeleted ?
                                                            <RestoreFromTrashIcon className="financialInstrument-restoreIconHover" />
                                                            :
                                                            <div className="financialInstrument-deleteIconHover fas fa-trash-alt" />
                                                        }
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default FinancialTable;
