const AllWSTypes = {
    AllWS_ACTION_START:"AllWS_ACTION_START",
    AllWS_FETCH_DATA:"AllWS_FETCH_DATA",
    AllWS_FETCH_DATA_FAILED:"AllWS_FETCH_DATA_FAILED",
    AllWS_ADD_DATA:"AllWS_ADD_DATA",
    AllWS_DELETE_DATA:"AllWS_DELETE_DATA",
    AllWS_UPDATE_DATA:"AllWS_UPDATE_DATA",

    WS_BACK_FROM_QUERY:"WS_BACK_FROM_QUERY",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: "",

    beckFrom: null,
}

const AllWSReducer = (state=Initial_state, action) => {
    switch(action.type){
        case AllWSTypes.AllWS_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case AllWSTypes.AllWS_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload,
            }

        case AllWSTypes.AllWS_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        case AllWSTypes.WS_BACK_FROM_QUERY: 
        console.log("WS_BACK_FROM_QUERY", action.payload)
            return {
                ...state,
                beckFrom: action.payload
            }

        default:
            return state;
    }
};

export default AllWSReducer;