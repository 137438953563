const GTypes = {
    TG_ACTION_START:"TG_ACTION_START",
    TG_FETCH_DATA:"TG_FETCH_DATA",
    TG_FETCH_DATA_FAILED:"TG_FETCH_DATA_FAILED",
    TG_ADD_DATA:"TG_ADD_DATA",
    TG_SOCKET_UPDATE_DATA:"TG_SOCKET_UPDATE_DATA",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
    set_payload: ""
}

const TTGroupingReducer = (state=Initial_state, action) => {
    switch(action.type){
        case GTypes.TG_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case GTypes.TG_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                set_payload: action.payload.set_payload
            }
        
        case GTypes.TG_SOCKET_UPDATE_DATA:
            const { data, set_payload } = action.payload;
            if(set_payload !== state.set_payload){
                return {
                    ...state
                }
            }
            return {
                ...state,
                response: {
                    ...state.response,
                    fs_grp: data
                }
            }

        case GTypes.TG_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        default:
            return state;
    }
};

export default TTGroupingReducer;