import React from 'react'

const Folder = ({
    height,
    width,
    color
}) => {
    return (
        <svg 
            width={width} 
            height={height}
            viewBox="0 0 46 36" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M7.23021 0.598378C7.49451 0.466106 7.89096 0.325017 8.11121 0.280926C8.33146 0.236835 11.5559 0.201563 15.2914 0.192745C20.9826 0.183927 22.1015 0.201563 22.2513 0.30738C22.3482 0.377925 23.1411 1.28619 25.5991 4.23144H33.9245C41.1664 4.24026 42.3205 4.25789 42.7786 4.37253C43.0693 4.45189 43.4834 4.61944 43.7037 4.75171C43.9239 4.87516 44.2763 5.15734 44.4877 5.38661C44.6992 5.60706 44.9987 6.03915 45.1397 6.3566C45.3071 6.71814 45.4392 7.17669 45.4833 7.59114C45.5273 7.95268 45.5449 12.7145 45.5361 18.1729C45.5097 27.8199 45.5009 28.1109 45.3335 28.5782C45.2366 28.8516 45.034 29.2396 44.893 29.4512C44.752 29.6629 44.4701 29.9803 44.2675 30.1567C44.0737 30.333 43.6948 30.5799 43.4305 30.7034C43.1662 30.8269 42.8227 30.9591 42.3822 31.0473V21.4797C42.3822 14.919 42.3557 11.7709 42.2852 11.4535C42.2324 11.1977 42.0298 10.651 41.8359 10.2366C41.554 9.65457 41.3338 9.34594 40.8492 8.86976C40.4616 8.48177 39.9946 8.12904 39.607 7.92623C39.2634 7.74986 38.7348 7.53823 38.4176 7.46768C37.9243 7.34423 36.7261 7.32659 21.6346 7.31778L20.3131 5.73052C19.5818 4.84871 18.833 3.99335 18.6304 3.81699C18.4365 3.63181 18.1194 3.43781 17.9256 3.3849C17.6701 3.29672 16.0578 3.27026 5.292 3.27026L5.34486 3.02336C5.37129 2.89109 5.52106 2.52954 5.67083 2.21209C5.8206 1.89464 6.12895 1.46255 6.3492 1.2421C6.56945 1.02165 6.96591 0.73065 7.23021 0.598378Z" 
                fill={color}
            />
            <path 
                d="M2.16445 5.49243C2.30541 5.39543 2.649 5.22788 2.9133 5.13089C3.38023 4.96334 3.64453 4.95452 17.4058 4.9457L17.7229 5.27198C17.8991 5.45716 18.6656 6.34778 20.7976 8.91385L29.2729 8.94031C37.0961 8.96676 37.7921 8.97558 38.1974 9.11667C38.4353 9.20485 38.7789 9.35476 38.9463 9.46058C39.1136 9.56639 39.422 9.8133 39.6334 10.0073C39.8449 10.2101 40.118 10.5452 40.2413 10.7657C40.3647 10.9861 40.5321 11.3388 40.6113 11.5593C40.7259 11.912 40.7523 13.1642 40.7787 21.8765C40.7875 27.3349 40.7699 32.0967 40.7259 32.4582C40.6818 32.8727 40.5497 33.3312 40.3911 33.6927C40.2325 34.0278 39.9418 34.4423 39.6863 34.7068C39.4396 34.9449 39.0255 35.2535 38.7612 35.3858C38.4969 35.5181 38.0653 35.668 37.8009 35.7209C37.4838 35.7826 31.6604 35.8091 20.6214 35.8091C9.58248 35.8091 3.75906 35.7826 3.4419 35.7209C3.1776 35.668 2.74591 35.5181 2.48161 35.3858C2.21731 35.2535 1.80324 34.9449 1.55656 34.7068C1.30107 34.4423 1.01034 34.0278 0.851757 33.6927C0.693177 33.3312 0.561027 32.8727 0.516977 32.4582C0.472927 32.0967 0.455307 26.4178 0.464117 19.8483C0.490547 8.71104 0.508167 7.87332 0.649127 7.45887C0.737227 7.21196 0.922237 6.8416 1.0632 6.62115C1.20416 6.40069 1.45084 6.10088 1.60942 5.95097C1.768 5.80106 2.01468 5.59825 2.16445 5.49243Z" 
                fill={color} 
            />
        </svg>
    )
}

export default Folder