const SDTypes = {
    SD_ADD_DATA:"SD_ADD_DATA",
    SD_ADD_MULTI_ROW_DATA:"SD_ADD_MULTI_ROW_DATA",
    SD_FILTER_ROW_DATA: "SD_FILTER_ROW_DATA",
    SD_FS_GRP_FOR_NOTE_ADD: "SD_FS_GRP_FOR_NOTE_ADD",
    SD_CHECK_YEARLY: "SD_CHECK_YEARLY"
}

const Initial_state = {
    selectedRow: null,
    multiSelectedRows: [],
    filterEnable: [],
    AddNoteFsGroup: {
        name: '',
        type: ''
    },
    checkYearly: false
}

const selectedDragReducer = (state=Initial_state, action) => {
    switch(action.type){
        case SDTypes.SD_ADD_DATA: 
            return {
                ...state,
                selectedRow: action.payload
            }
            
        case SDTypes.SD_ADD_MULTI_ROW_DATA:
            return {
                ...state,
                multiSelectedRows: action.payload
            }

        case SDTypes.SD_FILTER_ROW_DATA:
            return {
                ...state,
                filteredData: action.payload
            }
        
        case SDTypes.SD_FS_GRP_FOR_NOTE_ADD:
            return {
                ...state,
                AddNoteFsGroup: action.payload
            }
        
        case SDTypes.SD_CHECK_YEARLY:
            return {
                ...state,
                checkYearly: action.payload
            }

        default:
            return state;
    }
};

export default selectedDragReducer;