import { getMapTBGroupingData } from "../../../services/api/apiHandler"


export const fetchTTGroupData = (project_id, tb_id) => {
    return dispatch => {
        dispatch({type: "TG_ACTION_START"})
        getMapTBGroupingData(project_id, tb_id).then((result)=>{
            dispatch({
                type: "TG_FETCH_DATA",
                payload: {data: result.data, set_payload: JSON.stringify({project_id, tb_id})}
            })
        }).catch((err)=>{
            dispatch({
                type:"TG_FETCH_DATA_FAILED",
                payload: err.response.data.error
            })
        })        
    }
}


export const socketTTGroupUpdate = (response, payload) => {
    return dispatch => {
        dispatch({
            type: "TG_SOCKET_UPDATE_DATA", payload: {
                data: response,
                set_payload: payload
            }
        })
    }
}