import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import cross from "../../assets/cross.svg";
import "./../../Bookclosure/Styles/Bookclosure.css";
import {
  removeAdjustmentFromTable,
  setAdjustments,
  setIsAddToTackModalOpen,
  setIsEditLedgerModal,
  setNewAdjustmentToTable,
  setOnchangeAdj,
  setTaskComments,
} from "../../redux/actions/BookClosure/actionBookClosure";
import AddLedgerToTaskModal from "./AddLedgerToTaskModal";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditLedgerTaskModal from "./EditLedgerTaskModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AddAdjustmentToTask, addCommentToTask, getOneTask, updateAdjustmentToTask } from "../Services/ApiHandler";
import swal from "sweetalert";
import { toast } from "react-toastify";
import LoaderTwo from "../../Components/Common/Loader/LoaderTwo";

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#F1F2F5",
    borderColor: state.isFocused ? "gray" : provided.borderColor,
    boxShadow: state.isFocused ? "0 0 0 0" : provided.boxShadow,
    "&:hover": {
      borderColor: state.isFocused ? "gray" : provided["&:hover"].borderColor,
    },
    minHeight: "50px",
    height: "50px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
};

const AddTaskModal = ({
  isAddTaskModal,
  setAddTaskForm,
  addTaskForm,
  AddTask,
  handleTaskModalClose,
  ReturnTask,
  isTaskAction,
  userData,
  AddLedgerToTask,
  UpdateLedgerInTask,
}) => {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const { assignOptions, approveOptions, comments, adjustments, ledgers } = useSelector(
    (state) => state.BookClosureReducer
  );

  const [ledgerDetails, setLedgerDetails] = useState({
    remark: "",
    amount: "",
    ledgerId: "",
  });
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (selectedOptions, { name }) => {
    if (name === "assigneeID") {
      setAddTaskForm((prev) => ({
        ...prev,
        selectedAssignId: selectedOptions,
      }));
      setAddTaskForm((prev) => ({
        ...prev,
        [name]: selectedOptions.map((option) => option.value),
      }));
    } else {
      setAddTaskForm((prev) => ({
        ...prev,
        selectedApproverId: selectedOptions,
      }));
      setAddTaskForm((prev) => ({ ...prev, [name]: selectedOptions.value }));
    }
  };

  const AddComment = () => {
    if (!addTaskForm.taskId) {
      toast.warning("Please Save The Task.");
      return;
    }
    setLoading(true);
    addCommentToTask(addTaskForm.taskId, {
      text
    }).then(res => {
      // toast.success("Comment added successfully.");
      getOneTask(addTaskForm.taskId).then(response => {
        const taskData = response.data.bookClosureTask;
        dispatch(setTaskComments(taskData.comments));
        setText("");
      }).catch(error => {
        swal("Error", `Error: ${error.response?.data?.error}` || "Task not found!", 'error');
      })
    }).catch(error => {
      swal("Error", `Error: ${error.response?.data?.error}` || "Comment Send Failed!", 'error');
    }).finally(() => setLoading(false))
  }

  const SaveSingleAdj = (adjData) => {
    if (adjData?._id) {
      updateAdjustmentToTask(addTaskForm.taskId, adjData?._id, {
        date: dayjs(adjData.date).format("DD-MM-YYYY"),
        addedLedgers: adjData.addedLedgers.map(({ _id, ...rest }) => rest),
        remarks: adjData.remarks
      }).then(res => {
        toast.success("Adjustment updated successfully.");

        getOneTask(addTaskForm.taskId).then(response => {
          const taskData = response.data.bookClosureTask;
          dispatch(setAdjustments(taskData.adjustments));
        }).catch(error => {
          swal("Error", `Error: ${error.response?.data?.error}` || "Task not found!", 'error');
        });

      }).catch(error => {
        swal("Error", `Error: ${error.response?.data?.error}` || "Adjustment Update Failed!", 'error');
      })
    } else {
      AddAdjustmentToTask(addTaskForm.taskId, {
        date: dayjs(adjData.date).format("DD-MM-YYYY"),
        addedLedgers: adjData.addedLedgers,
        remarks: adjData.remarks
      }).then(res => {
        toast.success("Adjustment added successfully.");

        getOneTask(addTaskForm.taskId).then(response => {
          const taskData = response.data.bookClosureTask;
          dispatch(setAdjustments(taskData.adjustments));
        }).catch(error => {
          swal("Error", `Error: ${error.response?.data?.error}` || "Task not found!", 'error');
        });

      }).catch(error => {
        swal("Error", `Error: ${error.response?.data?.error}` || "Adjustment Save Failed!", 'error');
      })
    }
  }

  return (
    <>
      <Modal open={isAddTaskModal} onClose={handleTaskModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
            backgroundColor: "#F0DBC8",
            borderRadius: "10px",
          }}
        >
          <div>
            <div className="d-flex justify-content-between mt-1 p-2">
              <p className="mt-0 mb-0 font-size-24 custom-font-600">Template</p>
              <div className="d-flex">
                <button
                  className={`border-0 radius-6px bg-transparent py-1 px-3 font-size-16 custom-font-500
                                    ${userData?.responsibilities === "approver"
                      ? "text-success"
                      : "custom-common-theme-text-color"
                    }
                                `}
                  onClick={AddTask}
                >
                  {userData?.responsibilities === "approver"
                    ? "Approve"
                    : userData?.responsibilities === "preparer"
                      ? "Submit"
                      : isTaskAction
                        ? "Update"
                        : "Save"}
                </button>
                {userData?.responsibilities === "approver" && (
                  <button
                    className={`border-0 radius-6px bg-transparent py-1 px-3 font-size-16 custom-font-500 text-danger`}
                    onClick={ReturnTask}
                  >
                    Return
                  </button>
                )}
                <button
                  className={`border-0 radius-6px bg-transparent py-1 px-3 font-size-16 custom-font-500`}
                  onClick={handleTaskModalClose}
                >
                  Close
                </button>
              </div>
            </div>
            <div>
              <Card style={{ overflow: "scroll", height: "600px" }}>
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <h3 className="font-size-14 custom-font-500 mt-0 mb-0 text-secondary">
                        Task Name <span className="text-danger">*</span>
                      </h3>
                      <input
                        style={{ width: "250px" }}
                        type={"text"}
                        className="custom-bg-gray-300 radius-6px"
                        placeholder="Enter task name"
                        name="name"
                        value={addTaskForm.name}
                        onChange={(e) =>
                          setAddTaskForm((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <h3 className="font-size-14 custom-font-500 mt-0 mb-0 text-secondary">
                        Due date <span className="text-danger">*</span>
                      </h3>
                      <div className="d-flex justify-content-center align-items-center ml-1">
                        <div className="link-icon">
                          <CalendarMonthIcon
                            className="mt-2 ml-2 calndr"
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "black",
                            }}
                          />
                        </div>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DatePicker", "MobileDatePicker"]}
                            >
                              <MobileDatePicker
                                className="datepicker-color"
                                value={dayjs(addTaskForm.dueDate)}
                                onChange={(value) => {
                                  setAddTaskForm((prev) => ({
                                    ...prev,
                                    dueDate: value,
                                  }));
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                        <div className="link-iconn">
                          <ExpandMoreIcon className="mt-2 arrow-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="d-flex flex-column">
                      <h3 className="font-size-14 custom-font-500 mt-0 mb-0 text-secondary">
                        Module Name
                      </h3>
                      <input
                        style={{ width: "250px" }}
                        type={"text"}
                        className="custom-bg-gray-300 radius-6px"
                        placeholder="Enter module name"
                        name="module"
                        value={addTaskForm.module}
                        onChange={(e) =>
                          setAddTaskForm((prev) => ({
                            ...prev,
                            module: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div
                      className="d-flex flex-column "
                      style={{ width: "50%" }}
                    >
                      <h3 className="font-size-14 custom-font-500 mt-0 mb-0 text-secondary">
                        Task Description <span className="text-danger">*</span>
                      </h3>
                      <textarea
                        style={{
                          height: "96px",
                          textAlign: "start",
                          resize: "none",
                        }}
                        className="custom-bg-gray-300 radius-6px w-100 p-2 mt-1"
                        placeholder="Enter task description"
                        name="description"
                        value={addTaskForm.description}
                        onChange={(e) =>
                          setAddTaskForm((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex mt-4 "
                    style={{ gap: "10px", width: "50%" }}
                  >
                    <div
                      className="d-flex flex-column  "
                      style={{ width: "50%" }}
                    >
                      <h3 className="font-size-14 custom-font-500 mt-0 mb-0 text-secondary">
                        Assignee <span className="text-danger">*</span>
                      </h3>
                      <Select
                        isMulti
                        placeholder="Assign..."
                        name="assigneeID"
                        value={addTaskForm.selectedAssignId}
                        onChange={handleSelectChange}
                        options={assignOptions}
                        className="basic-multi-select w-100 mt-1 custom-bg-gray-300"
                        classNamePrefix="select"
                        components={animatedComponents}
                        closeMenuOnSelect={false}
                        styles={customStyles}
                      />
                    </div>
                    <div
                      className="d-flex flex-column "
                      style={{ width: "50%" }}
                    >
                      <h3 className="font-size-14 custom-font-500 mt-0 mb-0 text-secondary">
                        Approver <span className="text-danger">*</span>
                      </h3>
                      <Select
                        placeholder="Approve..."
                        name="approverID"
                        value={addTaskForm.selectedApproverId}
                        onChange={handleSelectChange}
                        options={approveOptions}
                        className="basic-multi-select w-100 mt-1 custom-bg-gray-300"
                        classNamePrefix="select"
                        components={animatedComponents}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="mt-3">
                    <label className="mt-1 mb-2 ml-1 custom-font-500 font-size-14 text-secondary">
                      Workings and Remarks
                    </label>
                    <Card
                      className="w-100 radius-6px"
                      style={{ border: "2px solid #F2F3F4" }}
                    >
                      <div className="w-100 p-1 text-secondary">
                        <CKEditor
                          editor={ClassicEditor}
                          data={addTaskForm?.workingsAndRemarks || ""}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setAddTaskForm((prev) => ({
                              ...prev,
                              workingsAndRemarks: data,
                            }));
                          }}
                          config={{
                            placeholder: "Add your remarks here",
                          }}
                        />
                      </div>
                    </Card>
                  </div>
                  {/*  */}

                  {/*ledgers table */}
                  {isTaskAction && (
                    <div className="mt-4">
                      <Card
                        className="w-100 radius-6px"
                        style={{ border: "2px solid #F2F3F4" }}
                      >
                        <div className="p-1">
                          <table className="table rounded">
                            <thead style={{ backgroundColor: "#F2F3F4" }}>
                              <tr>
                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">
                                  Ledger Code
                                </th>
                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">
                                  Ledger Name
                                </th> 
                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">
                                  Amount
                                </th>
                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">
                                  Date
                                </th>
                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">
                                  Remarks
                                </th>
                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {adjustments?.length > 0 &&
                                adjustments.map((adjustment, ax) => (
                                  <React.Fragment key={ax}>
                                    {adjustment?.addedLedgers?.length > 0 &&
                                      adjustment.addedLedgers.map((ledger, lx) => (
                                        <tr key={lx}>
                                          <td className="text-left">
                                            <select
                                              value={ledger?.ledgerCode}
                                              name="ledgerCode"
                                              onChange={(e) => {
                                                dispatch(setOnchangeAdj(ax, lx, e.target, adjustments,ledgers))
                                              }}
                                              className="form-control"
                                            >
                                              <option value="" disabled>
                                                Select Ledger Code
                                              </option>
                                              {ledgers.map((ledgerOption, idx) => (
                                                <option key={idx} value={ledgerOption.ledgerCode}>
                                                  {ledgerOption.ledgerCode}
                                                </option>
                                              ))}
                                            </select>
                                          </td>
                                          <td className="text-left">
                                          <div className="d-flex flex-row">
                                            <input
                                              type="text"
                                              name="ledgerName"
                                              value={ledger?.ledgerName}
                                              onChange={(e) => {
                                                dispatch(setOnchangeAdj(ax, lx, e.target, adjustments,ledgers))
                                              }}
                                              disabled
                                              className="form-control"
                                            />
                                            <div onClick={() => dispatch(removeAdjustmentFromTable(ax,lx,adjustments))} className="mt-2">
                                             <img src={cross} />
                                            </div>
                                            </div>
                                          </td>
                                          <td className="text-left">
                                            <input
                                              type="number"
                                              value={ledger?.adjustmentAmount}
                                              name="adjustmentAmount"
                                              onChange={(e) => {
                                                dispatch(setOnchangeAdj(ax, lx, e.target, adjustments,ledgers))
                                              }}
                                              className="form-control"
                                            />
                                          </td>
                                          {lx === 0 && (
                                            <>
                                              <td className="text-left" rowSpan={adjustment?.addedLedgers.length}>
                                                <input
                                                  type="date"
                                                  // value={dayjs(adjustment?.date).format("YYYY-MM-DD")}
                                                  value={adjustment?.date}
                                                  name="date"
                                                  onChange={(e) => {
                                                    dispatch(setOnchangeAdj(ax, null, e.target, adjustments,ledgers));
                                                  }}
                                                  className="form-control"
                                                />
                                              </td>
                                              <td className="text-left" rowSpan={adjustment?.addedLedgers.length}>
                                                <textarea
                                                  value={adjustment?.remarks}
                                                  name="remarks"
                                                  onChange={(e) => {
                                                    dispatch(setOnchangeAdj(ax, null, e.target, adjustments,ledgers));
                                                  }}
                                                  className="form-control"
                                                />
                                              </td>
                                              <td rowSpan={adjustment?.addedLedgers.length}>
                                                <div>
                                                  <button
                                                    onClick={() => SaveSingleAdj(adjustment)}
                                                    className="primary-border px-2 py-1 bg-white rounded custom-common-theme-text-color"
                                                  >
                                                    {adjustment?._id ? "Update" : "Save"}
                                                  </button>
                                                </div>
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      ))}
                                  </React.Fragment>
                                ))}
                              <tr>
                                <th scope="row" colSpan={6}>
                                  <button
                                    className="w-100 d-flex align-items-center justify-content-center border-0 bg-color-inherit"
                                    style={{ gap: "6px" }}
                                    onClick={() => {
                                      dispatch(setNewAdjustmentToTable(adjustments));
                                    }}
                                  >
                                    <span>
                                      <AddIcon
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          color: "#717171",
                                        }}
                                      />
                                    </span>
                                    <p className="mt-1 mb-0 font-size-12 custom-font-500 text-secondary">
                                      Add adjustment
                                    </p>
                                  </button>
                                </th>
                              </tr>
                            </tbody>

                          </table>

                        </div>
                      </Card>
                    </div>
                    //
                  )}
                  <div className="mt-3">
                    <label className="mt-1 mb-2 ml-1 custom-font-500 font-size-14 text-secondary">
                      Comments
                    </label>
                    <Card
                      className="w-100 radius-6px"
                      style={{ border: "2px solid #F2F3F4", background: "#f8f8f8" }}
                    >
                      <div className="w-100 d-flex flex-column p-1">
                        <div className="mx-2 d-flex flex-column" style={{ gap: "0.3rem" }}>
                          {
                            comments && comments.length > 0 &&
                            comments.map((comment, cmi) => (
                              <div key={cmi} className="p-2 bg-white rounded d-flex flex-column">
                                <div className="d-flex align-items-center">
                                  <div style={{ height: "35px", width: "35px", backgroundColor: "#6C6C6C" }} className="rounded-circle d-flex justify-content-center align-items-center text-uppercase text-white font-size-16 custom-font-700" >
                                    {(comment.createdByData?.name && comment.createdByData?.name?.charAt(0)) || ""}
                                  </div>
                                  <div>
                                    <p className="ml-2 m-0 p-0 font-size-16 font-inter custom-font-700 text-capitalize" >{comment.createdByData?.name}</p>
                                    <small className="ml-2 font-size-12 text-secondary font-weight-lighter" >{dayjs(comment?.createdAt).format("MMM D, YYYY h:mm A")}</small>
                                  </div>
                                </div>
                                <p className="p-0 m-0 mt-1 w-100 text-start ml-2 font-size-16 font-inter text-black" >{comment?.text}</p>
                              </div>
                            ))
                          }
                        </div>
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={text || ""}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setText(data);
                          }}
                          config={{
                            placeholder: "Type......",
                          }}
                        /> */}
                        <textarea
                          placeholder="Add your comments here"
                          className="bg-white border-0 rounded p-2 mt-2 text-secondary custom-font-600"
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                        />
                        <div className="mt-1 d-flex justify-content-center">
                          <button
                            className="bg-color-inherit px-3 py-2 rounded primary-border custom-common-theme-text-color font-size-18 custom-font-600"
                            style={{ width: "9rem", height: "3rem" }}
                            onClick={AddComment}
                            disabled={loading}
                          >
                            {loading ? <LoaderTwo /> : "Send"}
                          </button>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                {/*  */}
              </Card>
              {/*  */}
            </div>
          </div>
        </Box>
      </Modal>
      <AddLedgerToTaskModal AddLedgerToTask={AddLedgerToTask} />
      <EditLedgerTaskModal
        UpdateLedgerInTask={() =>
          UpdateLedgerInTask(
            ledgerDetails.ledgerId,
            ledgerDetails.remark,
            ledgerDetails.amount
          )
        }
        ledgerDetails={ledgerDetails}
        setLedgerDetails={setLedgerDetails}
      />
    </>
  );
};

export default AddTaskModal;
