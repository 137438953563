// import { formatIndianCurrency } from "../../helper/ConvertValue";
import {
  CurrentProjectCy,
  CurrentProjectPy,
} from "../../helper/pnlCalculation";
import cheerio from 'cheerio';

export const calculateGroupSum = (grp, input) => {
  let totalCy = 0;
  let totalPy = 0;
  input[grp].forEach((item) => {
    totalCy += item.cy || 0;
    totalPy += item.py || 0;
  });
  return { totalCy, totalPy };
};

export const calCulateProfitBefore = (pnlData, company_type) => {
  const income = company_type === "NBFC" ? "Revenue from operations" : "Income";
  const expenses = "Expenses";
  const pb_cy =
    CurrentProjectCy(pnlData, income) - CurrentProjectCy(pnlData, expenses);
  const pb_py =
    CurrentProjectPy(pnlData, income) - CurrentProjectPy(pnlData, expenses);
  return { pb_cy, pb_py };
};

export const calProfitBeforeTax = (pnlData, company_type) => {
  const calculateProfit = calCulateProfitBefore(pnlData, company_type);
  const pbt_cy =
    calculateProfit.pb_cy -
    CurrentProjectCy(pnlData, "Profit before exceptional items and tax");
  const pbt_py =
    calculateProfit.pb_py -
    CurrentProjectPy(pnlData, "Profit before exceptional items and tax");
  return { pbt_cy, pbt_py };
};

export const calProfitContinuing = (pnlData, company_type) => {
  const profitBeforeTax = calProfitBeforeTax(pnlData, company_type);
  const pc_cy =
    profitBeforeTax.pbt_cy - CurrentProjectCy(pnlData, "Tax expense");
  const pc_py =
    profitBeforeTax.pbt_py - CurrentProjectPy(pnlData, "Tax expense");
  return { pc_cy, pc_py };
};

const header = (name) => {
  return {
    columns: [
      {
        title: "Company Name : <To be appear here>",
        width: { wch: 45 },
        style: {
          font: { sz: "10", bold: true },
          alignment: { horizontal: "left", vertical: "top" },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
    ],
    data: [
      [
        {
          value: name,
          style: {
            font: { sz: "10", bold: true },
            alignment: { horizontal: "left", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        }
      ],
      [
        {
          value:
            "(All amounts are in Indian rupees (₹) lakhs except share data and otherwise stated)",
          style: {
            font: { sz: "10", italic: true },
            alignment: { horizontal: "left", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
    ],
  }
}

const footer1 = () => {
  return  {
    ySteps: 2,
    columns: [
      { title: "", width: { wch: 45 } },
      { title: "", width: { wch: 15 } },
    ],
    data: [
      [
        {
          value:
            "The notes from 1 to 48 form an integral part of the standalone financial statements.",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "As per our report of even date attached",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "for Chartered accountant's Firm",
          style: {
            font: {
              sz: "10",
              bold: true,
              italic: true,
              color: { rgb: "FF0000" },
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "for and on behalf of the board of directors of",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "Firm Registration Number : XXXXX / XXXXX",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "Name of the company which the user enters",
          style: {
            font: {
              sz: "10",
              bold: true,
              italic: true,
              color: { rgb: "FF0000" },
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "Chartered Accountants",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
    ],
  }
}

const footer2 = () => {
  return {
    ySteps: 5,
    columns: [
      {
        title: "XXXX",
        width: { wch: 45 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
      {
        title: "XXXX",
        width: { wch: 15 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
      {
        title: "XXXX",
        width: { wch: 15 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
    ],
    data: [
      [
        {
          value: "Partner",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "Chairman",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "Executive Vice Chairman",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "Membership No. XXXXXX",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "DIN: 00000",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "DIN: 0000000",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "Place: XXXX",
          style: { font: { sz: "10" } },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      ],
      [
        {
          value: "Data : dd/mm/yyyy",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
    ],
  }
}

const footer3 = () => {
  return {
    ySteps: 2,
    columns: [
      {
        title: "",
        width: { wch: 45 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
      {
        title: "XXXX",
        width: { wch: 15 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
      {
        title: "XXXX",
        width: { wch: 15 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
    ],
    data: [
      [
        {
          value: "",  
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "Managing Director",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "Chief Financial Officer",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "",  
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "DIN: 000000",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
    ]
  }
}

const footer4 = (type) => {
  const numberOfTimes = type === 'socie' ? 10 : 1
  return {
    ySteps: 2,
    columns: [
      {
        title: "",
        width: { wch: type === 'socie' ? 20: 45 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
      {
        title: "",
        width: { wch: type === 'socie' ? 10: 15 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
      {
        title: "XXXX",
        width: { wch: type === 'socie' ? 10: 15 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
      [...Array(numberOfTimes)].map(() => ({ ...{
        title: "",
        width: { wch: type === 'socie' ? 10: 15 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      } }))
    ].flat(),
    data: [
      [
        {
          value: "",  
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "Company Secretary",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "",  
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "",  
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "Place: XXXX",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
      [
        {
          value: "",  
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: "Date: DD/MM/YYYY",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ],
    ]
  }
}

export const transformBSData = (data, company_type) => {
  try{
  let result = [];
  let index = 0;
  for (const key in data) {
    const groupTitle = key;
    result.push([
      {
        value: groupTitle,
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
          alignment: { horizontal: "left", vertical: "top" },
        },
      },
      {
        value: "",
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
          alignment: { horizontal: "center", vertical: "top" },
        },
      },
      {
        value: "",
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
          alignment: { horizontal: "right", vertical: "top" },
        },
      },
      {
        value: "",
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
          alignment: { horizontal: "right", vertical: "top" },
        },
      },
    ]);

    const groupData = data[key];
    const totalCy = groupData.reduce((acc, curr) => acc + curr.cy, 0);
    const totalPy = groupData.reduce((acc, curr) => acc + curr.py, 0);

    groupData.forEach((item) => {
      result.push([
        {
          value: item.notes_grp,
          style: {
            font: { sz: "10" },
            alignment: { horizontal: "left", vertical: "top", wrapText: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: parseFloat(item.notes_index),
          style: {
            font: { sz: "10" },
            alignment: { horizontal: "center", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: item.cy,
          style: {
            font: { sz: "10" },
            alignment: { horizontal: "right", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: item.py,
          style: {
            font: { sz: "10" },
            alignment: { horizontal: "right", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]);
    });

    result.push([
      {
        value: `Total ${groupTitle}`,
        style: {
          font: { sz: "10", bold: true },
          alignment: { horizontal: "left", vertical: "top", wrapText: true },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
      { value: "" },
      {
        value: totalCy,
        style: {
          font: { sz: "10", bold: true },
          alignment: { horizontal: "right", vertical: "top" },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
      {
        value: totalPy,
        style: {
          font: { sz: "10", bold: true },
          alignment: { horizontal: "right", vertical: "top" },
          fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
        },
      },
    ]);
    if (index === 1) {
      if (company_type === "NBFC") {
        const f_assets = calculateGroupSum("Financial assets", data);
        const nf_assets = calculateGroupSum("Non-financial Assets", data);
        result.push([
          {
            value: `Total Financial and Non Financial Assets`,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "left", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
          { value: "" },
          {
            value: f_assets.totalCy + nf_assets.totalCy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
          {
            value: f_assets.totalPy + nf_assets.totalPy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
        ]);
      } else {
        const c_assets = calculateGroupSum("Current Assets", data);
        const nc_assets = calculateGroupSum("Non Current Assets", data);
        result.push([
          {
            value: `Total Current and Non Current Assets`,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "left", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
          { value: "" },
          {
            value: c_assets.totalCy + nc_assets.totalCy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
          {
            value: c_assets.totalPy + nc_assets.totalPy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
        ]);
      }
    } else if (index === 4) {
      if (company_type === "NBFC") {
        const f_liabilities = calculateGroupSum("Financial liabilities", data);
        const nf_liabilities = calculateGroupSum(
          "Non-financial liabilities",
          data
        );
        const equity = calculateGroupSum("Equity", data);
        result.push([
          {
            value: `Total Financial and Non-Financial liabilities`,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          { value: "" },
          {
            value: f_liabilities.totalCy + nf_liabilities.totalCy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
            },
          },
          {
            value: f_liabilities.totalPy + nf_liabilities.totalPy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
            },
          },
        ]);
        result.push([
          {
            value: `Total Equity & Liabilities`,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "left", vertical: "top" },
            },
          },
          { value: "" },
          {
            value:
              f_liabilities.totalCy + nf_liabilities.totalCy + equity.totalCy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
            },
          },
          {
            value:
              f_liabilities.totalPy + nf_liabilities.totalPy + equity.totalPy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
            },
          },
        ]);
      } else {
        const c_liabilities = calculateGroupSum("Current Liabilities", data);
        const nc_liabilities = calculateGroupSum(
          "Non Current Liabilities",
          data
        );
        result.push([
          {
            value: `Total Current and Non-Current Liabilities`,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
          { value: "" },
          {
            value: c_liabilities.totalCy + nc_liabilities.totalCy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
          {
            value: c_liabilities.totalPy + nc_liabilities.totalPy,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
        ]);
      }
    }
    index += 1;
  }
  return [
    header("Standalone Balance Sheet Data"),
    {
      ySteps: 2,
      columns: [
        {
          title: "Particulars",
          width: { wch: 45 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          },
        }, //pixels width
        {
          title: "Note No",
          width: { wch: 15 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "center", vertical: "top" },
          },
        }, //char width
        {
          title: "As at 10 November 2023",
          width: { wch: 15 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "center", vertical: "top", wrapText: true },
          },
        },
        {
          title: "As at 10 November 2022",
          width: { wch: 15 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "center", vertical: "top", wrapText: true },
          },
        },
      ],
      data: result,
    },
    footer1(),
    footer2(),
    footer3(),
    footer4()
  ];
}catch(err){
  return [{
    columns: [
      {title: "Issues", width: {wpx: 80}, style: {font: {color: { rgb: "FFFF0000" }}}},//pixels width 
    ],
    data: [
      [
        {value: `Problem with the data: ${err.message}`, style: {font: {sz: "10"}}},
      ]
    ]
  }]
}
};

export const transformPNLData = (data, company_type) => {
  try{
  let result = [];
  let index = 0;
  for (const key in data) {
    const groupTitle = key;
    const { pb_cy, pb_py } = calCulateProfitBefore(data, company_type);
    const { pbt_cy, pbt_py } = calProfitBeforeTax(data, company_type);
    const { pc_cy, pc_py } = calProfitContinuing(data, company_type);
    if ([2, 3, 5].includes(index)) {
      result.push([
        {
          value: groupTitle,
          style: {
            font: { sz: "10", bold: true },
            alignment: { horizontal: "left", vertical: "top", wrapText: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        { value: "" },
        {
          value:
            index === 2
              ? pb_cy
              : index === 3
              ? pbt_cy
              : index === 5
              ? pc_cy
              : 0,
          style: {
            font: { sz: "10", bold: true },
            alignment: { horizontal: "right", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value:
            index === 2
              ? pb_py
              : index === 3
              ? pbt_py
              : index === 5
              ? pc_py
              : 0,
          style: {
            font: { sz: "10", bold: true },
            alignment: { horizontal: "right", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]);
    } else {
      result.push([
        {
          value: groupTitle,
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
            alignment: { horizontal: "left", vertical: "top", wrapText: true },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
            alignment: { horizontal: "center", vertical: "top" },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
            alignment: { horizontal: "right", vertical: "top" },
          },
        },
        {
          value: "",
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
            alignment: { horizontal: "right", vertical: "top" },
          },
        },
      ]);
    }

    const groupData = data[key];
    const totalCy = groupData.reduce((acc, curr) => acc + curr.cy, 0);
    const totalPy = groupData.reduce((acc, curr) => acc + curr.py, 0);

    groupData.forEach((item) => {
      result.push([
        {
          value: item.notes_grp,
          style: {
            font: { sz: "10" },
            alignment: { horizontal: "left", vertical: "top", wrapText: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: parseFloat(item.notes_index),
          style: {
            font: { sz: "10" },
            alignment: { horizontal: "center", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: item.cy || 0,
          style: {
            font: { sz: "10" },
            alignment: { horizontal: "right", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: item.py || 0,
          style: {
            font: { sz: "10" },
            alignment: { horizontal: "right", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]);
    });
    if (![2, 3, 5].includes(index)) {
      result.push([
        {
          value: `Total ${groupTitle}`,
          style: {
            font: { sz: "10", bold: true },
            alignment: { horizontal: "left", vertical: "top", wrapText: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        { value: "" },
        {
          value: totalCy,
          style: {
            font: { sz: "10", bold: true },
            alignment: { horizontal: "right", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: totalPy,
          style: {
            font: { sz: "10", bold: true },
            alignment: { horizontal: "right", vertical: "top" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]);
    }

    index += 1;
  }
  return [
    header("Standalone PNL statement Data"),
    {
      ySteps: 2,
      columns: [
        {
          title: "Particulars",
          width: { wch: 45 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          },
        }, //pixels width
        {
          title: "Note No",
          width: { wch: 15 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "center", vertical: "top" },
          },
        }, //char width
        {
          title: "As at 10 November 2023",
          width: { wch: 15 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "center", vertical: "top", wrapText: true },
          },
        },
        {
          title: "As at 10 November 2022",
          width: { wch: 15 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "center", vertical: "top", wrapText: true },
          },
        },
      ],
      data: result,
    },
    footer1(),
    footer2(),
    footer3(),
    footer4()
  ];
}catch(err){
  return [{
    columns: [
      {title: "Issues", width: {wpx: 80}, style: {font: {color: { rgb: "FFFF0000" }}}},//pixels width 
    ],
    data: [
      [
        {value: `Problem with the data: ${err.message}`, style: {font: {sz: "10"}}},
      ]
    ]
  }]
}
};

export function transformNotesData(input) {
  try {
    const output = [];

    input.forEach((item, index) => {
      // Process fs_grp data
      output.push({
        ySteps: index !== 0 ? 3 : 0,
        columns: [
          {
            title: item?.data[0]?.sub_grp[0]?.fs_grp || 'Undefined FSGrp',
            width: { wch: 45 },
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
        ],
        data: [
          [
            {
              value: `NOTE No. ${item?.notes_no}`,
              width: { wch: 15 },
              style: {
                font: { sz: "10", bold: true },
                alignment: { horizontal: "left", vertical: "top" },
                fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
              },
            },
          ],
          [
            {
              value: item?.notes_grp || "",
              width: { wch: 15 },
              style: {
                font: { sz: "10", bold: true },
                alignment: { horizontal: "left", vertical: "top" },
                fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
              },
            },
          ],
        ],
      });

      // Process sub_grp data
      let subGroupDataCollection = [];
      item?.data[0]?.sub_grp.forEach((subGroup, index2) => {
        const subGroupData = [];
        subGroupData.push([
          {
            value: subGroup?.sub_grp || "",
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
          {
            value: subGroup.cy_amt || 0,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
          {
            value: subGroup.py_amt || 0,
            style: {
              font: { sz: "10", bold: true },
              alignment: { horizontal: "right", vertical: "top" },
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
            },
          },
        ]);

        if (subGroup?.disclosures?.length > 0) {
          subGroup.disclosures.forEach((disclosure) => {
            const disclosureValues = [
              { value: disclosure.disclosures || "" },
              { value: disclosure.cy_amt || 0 },
              { value: disclosure.py_amt || 0 },
            ];
            subGroupData.push(disclosureValues);
          });

          subGroupData.push(
            [
              {
                value: "Closing Gross Carrying Amount",
                style: {
                  font: { sz: "10", bold: true },
                  alignment: { horizontal: "left", vertical: "top" },
                },
              },
              {
                value: 0,
                style: {
                  font: { sz: "10", bold: true },
                  alignment: { horizontal: "right", vertical: "top" },
                },
              }, // Assuming it's always 0 in this case
              {
                value: 0,
                style: {
                  font: { sz: "10", bold: true },
                  alignment: { horizontal: "right", vertical: "top" },
                },
              }, // Assuming it's always 0 in this case
            ],
            [
              {
                value: "Net Difference",
                style: {
                  font: { sz: "10", bold: true, color: { rgb: "696f79" } },
                  alignment: { horizontal: "left", vertical: "top" },
                },
              },
              {
                value: 0,
                style: {
                  font: { sz: "10", bold: true, color: { rgb: "696f79" } },
                  alignment: { horizontal: "right", vertical: "top" },
                },
              }, // Assuming it's always 0 in this case
              {
                value: 0,
                style: {
                  font: { sz: "10", bold: true, color: { rgb: "696f79" } },
                  alignment: { horizontal: "right", vertical: "top" },
                },
              }, // Assuming it's always 0 in this case
            ]
          );
        }
        if (
          item?.data[0]?.sub_grp?.length - 1 === index2 &&
          subGroup?.disclosures?.length === 0
        ) {
          // Calculate total amount
          const totalCyAmt = item?.data[0]?.sub_grp.reduce(
            (acc, subGroup) => acc + subGroup.cy_amt,
            0
          );
          const totalPyAmt = item?.data[0]?.sub_grp.reduce(
            (acc, subGroup) => acc + subGroup.py_amt,
            0
          );
          const totalAmountRow = [
            {
              value: "Total amount",
              style: {
                font: { sz: "10", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
                alignment: { horizontal: "left", vertical: "top" },
              },
            },
            {
              value: totalCyAmt || 0,
              style: {
                font: { sz: "10", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
                alignment: { horizontal: "right", vertical: "top" },
              },
            },
            {
              value: totalPyAmt || 0,
              style: {
                font: { sz: "10", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
                alignment: { horizontal: "right", vertical: "top" },
              },
            },
          ];
          subGroupData.push(totalAmountRow);
        }
        subGroupDataCollection.push(subGroupData);
      });
      output.push({
        columns: [
          {
            title: "Particulars",
            width: { wch: 45 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top" },
            },
          },
          {
            title: "October 15, 2023",
            width: { wch: 15 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "center", vertical: "top" },
            },
          },
          {
            title: "October 15, 2022",
            width: { wch: 15 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "center", vertical: "top" },
            },
          },
        ],
        data: subGroupDataCollection.flat(),
      });
    });

    return output;
  } catch (err) {
    return [{
      columns: [
        {title: "Issues", width: {wpx: 80}, style: {font: {color: { rgb: "FFFF0000" }}}},//pixels width 
      ],
      data: [
        [
          {value: `Problem with the data: ${err.message}`, style: {font: {sz: "10"}}},
        ]
      ]
    }]
  }
}

export const AddNewKeystoSocie = (newRowIndex, start, end, socieData) => {
  let keysToSum = Object.keys(socieData[0]).filter(key => !['row_header'].includes(key));
  const newObj = { row_header: newRowIndex };

  // Initialize sum variables for each key
  keysToSum.forEach(key => newObj[key] = 0);

  // Calculate the sum for each key within the specified range
  for (let i = start; i <= end; i++) {
      // eslint-disable-next-line no-loop-func
      keysToSum.forEach(key => newObj[key] += socieData[i][key] || 0);
  }

  return newObj;
}

export const transformSocieData = (input) => {
  try {
    const output = [];
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        // Empty row for spacing
        output.push({
          columns: [{ title: "" }],
          data: [
            [
              {
                value:
                  key === "previous_row_header"
                    ? "Previous Year"
                    : "Current Year",
              },
            ],
          ],
        });
        let newData = AddNewKeystoSocie(`Restated balance at the beginning of the ${key.split("_")[0]} reporting period`, 0, 1, input[key]);
        input[key].splice(2, 0, newData)
        let newData2 = AddNewKeystoSocie(`Total Comprehensive Income for the ${key.split("_")[0]} year`, 3, 4, input[key]);
        input[key].splice(5, 0, newData2)
        let newData3 = AddNewKeystoSocie(`Total`, 0, 1, [newData, newData2]);
        input[key].push(newData3);
        let newData4 = AddNewKeystoSocie(`Balance at the end of the ${key.split("_")[0]} reporting period`, 0, 1, [newData, newData2]);
        input[key].push(newData4);
        const rows = input[key].map((row, sIndex) => {
          const rowData = [];
          let sum = 0;
          let totalRow = (key === 'previous_row_header') ? 12 : 11;
          let balanceAttheEnd = (key === 'previous_row_header') ? 13 : 12;

          for (const colKey in row) {
            if (colKey === "row_header") {
              rowData.push({
                value: row[colKey],
                style: {
                  font: { sz: "10", bold: [2, 5, totalRow, balanceAttheEnd].includes(sIndex) ? true : false},
                  alignment: { horizontal: "left", vertical: "top", wrapText: true },
                  fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
                },
              });
            } else if (colKey === 'refData' || colKey === 'sub_grp') {
              continue
            }
            else {
              let value = row[colKey] || 0;
              sum += value;
              rowData.push({ value: row[colKey], style: {
                font: { sz: "10" },
                alignment: { horizontal: "left", vertical: "top", wrapText: true },
                fill: { patternType: "solid", fgColor: { rgb: [2,5, totalRow].includes(sIndex) ? "C8FFDB" : [balanceAttheEnd].includes(sIndex) ? "DDDDFF" : [3,4].includes(sIndex) ? "FFDDF6"  : "FFFFFF" } },
              } });
            }
          }

          rowData.push({
            value: sum.toLocaleString('en-In'),
            style: {
                font: { sz: "10", bold: true },
                alignment: { horizontal: "left", vertical: "top" },
                fill: { patternType: "solid", fgColor: { rgb: [2,5, totalRow].includes(sIndex) ? "C8FFDB" : [balanceAttheEnd].includes(sIndex) ? "DDDDFF" : [3,4].includes(sIndex) ? "FFDDF6" : "FFFFFF"  } },
            },
        });

          return rowData;
        });

        const columns = [
          {
            title: "row_header",
            width: { wch: 20 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Share application money pending allotment",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Equity component of compounded financial instruments",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Capital reserve",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Securities premium",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Other reserves",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Retained Earnings",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Debt instruments through Other Comprehensive Income",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Equity Instruments through Other Comprehensive Income",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Effective portion of Cash Flow Hedges",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Revaluation Surplus",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title:
              "Exchange differences on translating the financial statements of a foreign operation",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Money received against share warrants",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
          {
            title: "Total",
            width: { wch: 10 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top", wrapText: true },
            },
          },
        ];

        output.push({
          columns,
          data: rows,
        });
      }
    }
    output.unshift(header("Standalone Socie Data"));
    output.push(footer1(), footer2(), footer3(), footer4("socie"));

    return output;
  } catch (err) {
    return [{
      columns: [
        {title: "Issues", width: {wpx: 80}, style: {font: {color: { rgb: "FFFF0000" }}}},//pixels width 
      ],
      data: [
        [
          {value: `Problem with the data: ${err.message}`, style: {font: {sz: "10"}}},
        ]
      ]
    }]
  }
};

export const calculateTotalSub = (cashflowData, faceValueIds, key) => {
  const copy = [...cashflowData];
  const filterByFaceValueId = copy.filter(ele => faceValueIds.includes(ele.faceValueId));
  const mergedArray = filterByFaceValueId.map(({ faceValueData }) => faceValueData).flat();

  const total = mergedArray.reduce((acc, item) => {
      const value = item[key];

      if (value !== null && value !== undefined) {
          if (typeof value === 'number' || (Array.isArray(value) && value.every(v => typeof v === 'number'))) {
              return acc + (Array.isArray(value) ? value.reduce((sum, num) => sum + num, 0) : value);
          }
      }

      return acc;
  }, 0);

  return total;
};

export const cashFlowTransformData = (input) => {
  try{
  const columns = [
    {
      title: "Particulars",
      width: { wch: 45 },
      style: {
        font: { sz: "10", bold: true },
        fill: { patternType: "solid", fgColor: { rgb: "808080" } },
        alignment: { horizontal: "left", vertical: "top" },
      },
    },
    {
      title: "Year Ended CY",
      width: { wch: 15 },
      style: {
        font: { sz: "10", bold: true },
        fill: { patternType: "solid", fgColor: { rgb: "808080" } },
        alignment: { horizontal: "left", vertical: "top" },
      },
    },
    {
      title: "Year Ended PY",
      width: { wch: 15 },
      style: {
        font: { sz: "10", bold: true },
        fill: { patternType: "solid", fgColor: { rgb: "808080" } },
        alignment: { horizontal: "left", vertical: "top" },
      },
    },
  ];
  const result = [];
  input.forEach((rowData)=> {
    rowData.faceValueData.forEach((row)=>{
      result.push([[
        {
          value: row.particular,
          style: {
            font: {
              sz: "10",
              bold:
                row.hasOwnProperty("isHeading") && row?.isHeading === true
                  ? true
                  : false,
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: row.cy || "-",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: row.py || "-",
          style: {
            font: { sz: "10" },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]])
    });
    if(rowData.faceValueId === 2){
      result.push([[
        {
          value:  "Operating profit before working capital changes",
          style: {
            font: {
              sz: "10",
              bold: true,
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [1,2], "cy"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [1,2], "py"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]])
    }
    if(rowData.faceValueId === 3){
      result.push([[
        {
          value:  "Operating profit after working capital changes",
          style: {
            font: {
              sz: "10",
              bold: true,
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [1,2,3], "cy"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [1,2,3], "py"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]])
    }
    if(rowData.faceValueId === 4){
      result.push([[
        {
          value:  "Net cash flow from / (used) in operating activities (A)",
          style: {
            font: {
              sz: "10",
              bold: true,
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [1,2,3,4], "cy"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [1,2,3,4], "py"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]])
    }
    if(rowData.faceValueId === 5){
      result.push([[
        {
          value:  "Net cash  flow from / (used) in investing activities (B)",
          style: {
            font: {
              sz: "10",
              bold: true,
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [5], "cy"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [5], "py"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]])
    }
    if(rowData.faceValueId === 6){
      result.push([[
        {
          value:  "Net Cash flow from / (used) in financing activities (C)",
          style: {
            font: {
              sz: "10",
              bold: true,
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [6], "cy"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [6], "py"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]])
    }
    if(rowData.faceValueId === 8){
      result.push([[
        {
          value:  "Total Cash and Cash Equivalents",
          style: {
            font: {
              sz: "10",
              bold: true,
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [8], "cy"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
        {
          value: calculateTotalSub(input, [8], "py"),
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
          },
        },
      ]])
    }
  })

  return [
    header("Standalone Cashflow Data"),
    { ySteps: 2, columns: columns, data: result.flat() },
    footer1(), 
    footer2(), 
    footer3(), 
    footer4()
  ];
}catch(err){
  return [{
    columns: [
      {title: "Issues", width: {wpx: 80}, style: {font: {color: { rgb: "FFFF0000" }}}},//pixels width 
    ],
    data: [
      [
        {value: `Problem with the data: ${err.message}`, style: {font: {sz: "10"}}},
      ]
    ]
  }]
}
};


// export const transformGeneralNotes = (generalNotes) => {
//   const transformedData = [{
//     columns: [{ title: '' }, { title: 'Corporate information' }, { title: 'Ind AS 1.10(e) Ind AS 1.49 Ind AS 1.113 Ind AS 1.51(a) Ind AS 1.51(b) Ind AS 1.51(c) Ind 1.138(a) Ind AS1.138(b) Ind AS 1.138(C )' }],
//     data: generalNotes.data.map(item => {
//       return [
//         { value: item.name },
//         { value: item.message },
//         { value: '' } // Placeholder value
//       ];
//     })
//   }];
//   return transformedData
// }

export function transformDisclosureCheckList(input) {
  try{
    let output = [];
  
    input.forEach(item => {
        item.disclosure.forEach(dis => {
            let rowData = [
                {value: item.name, style: { alignment: { vertical: "top", horizontal: "left" }, font: { sz: "10", bold: true, color: { rgb: "64789F" }}}},
                {value: dis?.reference || "NA", style: { alignment: { vertical: "top", horizontal: "left" }, font: { sz: "10", color: { rgb: "03565A" }}}},
                {value: dis?.notes || "NA", style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left", wrapText: true }}},
                {value: dis?.value === "yes" ? "yes" : "no", style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left" }}},
                {value: dis?.value === "no" ? "yes" : "no", style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left" }}},
                {value: dis?.value === "na" ? "yes" : "no", style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left" }}},
                {value: dis?.remark || "NA", style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left" }}}
            ];
            output.push(rowData);
        });
    });

    return [
      header("Standalone Disclosures Checklist"),
      {
        ySteps: 2,
        columns: [
          { title: "Name", 
            width: { wch: 25 },
            style: {
              font: { sz: "10", bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "808080" } },
              alignment: { horizontal: "left", vertical: "top" },
            }
        }, 
        {title: "Paragraph reference",
        width: { wch: 25 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "808080" } },
          alignment: { horizontal: "left", vertical: "top" },
        }}, 
        {title: "Disclosure",
          width: { wch: 45 },
        style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "808080" } },
          alignment: { horizontal: "left", vertical: "top" },
        }}, 
        {title: "Yes",
        width: { wch: 15 }, style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "808080" } },
          alignment: { horizontal: "left", vertical: "top" },
        }}, 
        {title: "No", width: { wch: 15 }, style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "808080" } },
          alignment: { horizontal: "left", vertical: "top" },
        }}, 
        {title: "NA", width: { wch: 15 }, style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "808080" } },
          alignment: { horizontal: "left", vertical: "top" },
        }}, 
        {title: "REMARKS", width: { wch: 15 }, style: {
          font: { sz: "10", bold: true },
          fill: { patternType: "solid", fgColor: { rgb: "808080" } },
          alignment: { horizontal: "left", vertical: "top" },
        }}
      ],
        data: output
    }];
  }catch(err) {
    return [{
      columns: [
        {title: "Issues", width: {wpx: 80}, style: {font: {color: { rgb: "FFFF0000" }}}},//pixels width 
      ],
      data: [
        [
          {value: `Problem with the data: ${err.message}`, style: {font: {sz: "10"}}},
        ]
      ]
    }]
  }
}


export const convertHtmlToString = (input) => {

  const div = cheerio.load(input);
  return div.text();
}

export function transformGeneralNotes(input) {
  try{
    let output = [];
    output.push(header("Standalone General notes."))
    
    // Processing data from 'data' array
    let dataOutput = [];
    input.data.forEach(item => {
        let rowData = [
            {value: item.name, style: { alignment: { vertical: "top", horizontal: "left" }, font: { sz: "10", bold: true, color: { rgb: "64789F" }}}},
            {value: convertHtmlToString(item.message), style: { alignment: { vertical: "top", horizontal: "left" }, font: { sz: "10", color: { rgb: "03565A" }}}},
            {value: item.label, style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left", vertical: "top", wrapText: true }}},
            {value: item.value, style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left", vertical: "top", wrapText: true }}},
            {value: item.disclosure || "NA", style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left", vertical: "top", wrapText: true }}}
        ];
        dataOutput.push(rowData);
    });
    output.push({
        columns: [
          {title: "Notes", width: { wch: 25 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          }}, 
          {title: "Message", width: { wch: 45 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          }}, 
          {title: "Label", width: { wch: 35 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          }}, 
          {title: "Value", width: { wch: 35 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top", wrapText: true },
          }}, 
          {title: "Disclosure", width: { wch: 40 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          }}
        ],
        data: dataOutput
    });
    
    // Processing data from 'noteOne' array
    let noteOneOutput = [];
    input.noteOne.forEach(item => {
        let rowData = [
            {value: item.name, style: { alignment: { vertical: "top", horizontal: "left" }, font: { sz: "10", bold: true, color: { rgb: "64789F" }}}},
            {value: item.nodeIndex, style: { alignment: { vertical: "top", horizontal: "left" }, font: { sz: "10", color: { rgb: "03565A" }}}},
            {value: convertHtmlToString(item.message), style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left", wrapText: true }}},
            {value: item.text1 || "NA", style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left", wrapText: true }}},
            {value: item.text2 || "NA", style: {font: { sz: "10" }, alignment: { vertical: "top", horizontal: "left", wrapText: true }}}
        ];
        noteOneOutput.push(rowData);
    });
    output.push({
        ySteps: 2,
        columns: [
          {title: "Notes", width: { wch: 25 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          }}, 
          {title: "Node Index",width: { wch: 45 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          }}, 
          {title: "Message", width: { wch: 35 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          }}, 
          {title: "Text1", width: { wch: 35 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          }}, 
          {title: "Text2", width: { wch: 40 },
          style: {
            font: { sz: "10", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "808080" } },
            alignment: { horizontal: "left", vertical: "top" },
          }}
        ],
        data: noteOneOutput
    });

    return output;
  } catch(err) {
    return [{
      columns: [
        {title: "Issues", width: {wpx: 80}, style: {font: {color: { rgb: "FFFF0000" }}}},//pixels width 
      ],
      data: [
        [
          {value: `Problem with the data: ${err.message}`, style: {font: {sz: "10"}}},
        ]
      ]
    }]
  }
}


export function transformCashflowWorking(input) {
  let output = [];
  
  input.forEach(item => {
      // Pushing category name
      output.push({
          columns: [{title: ""}, {title: "", width: { wch: 35 }}, {title: "", width: { wch: 25 }}, {title: "", width: { wch: 25 }}, {title: "", width: { wch: 45 }}, {title: "", width: { wch: 35 }}, {title: "", width: { wch: 35 }}, {title: "", width: { wch: 35 }}, {title: "", width: { wch: 45 }}],
          data: [[{value: item.name, width: { wch: 35 }, style: {font: {sz: "10", bold: true},fill: { patternType: "solid", fgColor: { rgb: "f2e2b5" } }, alignment: { horizontal: "left", vertical: "top" }}}, {value: "", width: { wch: 25 }, style: {fill: { patternType: "solid", fgColor: { rgb: "f2e2b5" } }}},{value: "", width: { wch: 25 }, style: {fill: { patternType: "solid", fgColor: { rgb: "f2e2b5" } }}},{value: "", style: {fill: { patternType: "solid", fgColor: { rgb: "f2e2b5" } }}},{value: "", style: {fill: { patternType: "solid", fgColor: { rgb: "f2e2b5" } }}},{value: "", style: {fill: { patternType: "solid", fgColor: { rgb: "f2e2b5" } }}},{value: "", style: {fill: { patternType: "solid", fgColor: { rgb: "f2e2b5" } }}},{value: "", style: {fill: { patternType: "solid", fgColor: { rgb: "f2e2b5" } }}},{value: "", style: {fill: { patternType: "solid", fgColor: { rgb: "f2e2b5" } }}}]]
      });
      
      // Pushing adjustments data
      output.push({
          columns: [
              {title: "S.no"}, 
              {title: "Adjustments Name"}, 
              {title: "Current Year"}, 
              {title: "Previous Year"}, 
              {title: "Adjustment in Cash flow statement"}, 
              {title: "Amount to be reflected in Cash Flow (CY)"}, 
              {title: "Amount to be reflected in Cash Flow (PY)"}, 
              {title: "Name of the item in cash flow statement"}, 
              {title: "Remarks"}
          ],
          data: item.data.map((adjustment, index) => [
              {value: index + 1, style: {font: {sz: '10'}, fill: { patternType: 'solid', fgColor: { rgb: adjustment.isColored ? 'e0e0e0' : 'FFFFFF' } }, alignment: { horizontal: "left", vertical: "top" }}}, 
              {value: adjustment.adjustmentsName, style: {font: {sz: '10'}, fill: { patternType: 'solid', fgColor: { rgb: adjustment.isColored ? 'e0e0e0' : 'FFFFFF' } }, alignment: { horizontal: "left", vertical: "top", wrapText: true }}}, 
              {value: adjustment.cy_amount, style: {font: {sz: '10'}, fill: { patternType: 'solid', fgColor: { rgb: adjustment.isColored ? 'e0e0e0' : 'FFFFFF' } }, alignment: { horizontal: "right", vertical: "top", wrapText: true }}}, 
              {value: adjustment.py_amount, style: {font: {sz: '10'}, fill: { patternType: 'solid', fgColor: { rgb: adjustment.isColored ? 'e0e0e0' : 'FFFFFF' } }, alignment: { horizontal: "right", vertical: "top", wrapText: true }}}, 
              {value: adjustment.adjustmentCashFlowStatement, style: {font: {sz: '10'}, fill: { patternType: 'solid', fgColor: { rgb: adjustment.isColored ? 'e0e0e0' : 'FFFFFF' } }, alignment: { horizontal: "left", vertical: "top", wrapText: true }}}, 
              {value: adjustment.cy, style: {font: {sz: '10'}, fill: { patternType: 'solid', fgColor: { rgb: adjustment.isColored ? 'e0e0e0' : 'FFFFFF' } }, alignment: { horizontal: "right", vertical: "top", wrapText: true }}}, 
              {value: adjustment.py, style: {font: {sz: '10'}, fill: { patternType: 'solid', fgColor: { rgb: adjustment.isColored ? 'e0e0e0' : 'FFFFFF' } }, alignment: { horizontal: "right", vertical: "top", wrapText: true }}}, 
              {value: adjustment.nameOfItemCashFlowStatement, style: {font: {sz: '10'}, fill: { patternType: 'solid', fgColor: { rgb: adjustment.isColored ? 'e0e0e0' : 'FFFFFF' } }, alignment: { horizontal: "left", vertical: "top", wrapText: true }}}, 
              {value: adjustment.remarks, style: {font: {sz: '10'}, fill: { patternType: 'solid', fgColor: { rgb: adjustment.isColored ? 'e0e0e0' : 'FFFFFF' } }, alignment: { horizontal: "left", vertical: "top", wrapText: true }}}
          ])
      });
  });

  return output;
}
