import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';












function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// 




const Tabsystem = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // 
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;



  return (
    <>
      <div>
        <div className="d-flex">
          <div className="container pl-0 pr-0">
            <div className="w-100">
              <Box>
                <Card style={{ marginTop: "10px" }} className="tabtoggle">
                  <CardContent>
                    <Box>
                      <Tabs style={{ backgroundColor: "#EAECEE", width: "100%", borderRadius: "5px", }} value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Box sx={{}}>
                          <Tabs sx={{
                            "& button": { borderRadius: 1, color: "black", fontSize: "15px", fontWeight: "500" },
                            "& button.Mui-selected": { backgroundColor: "#03565A", color: "white" }
                          }} value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab sx={{ textTransform: "none", fontWeight: "400" }} label="Sales" {...a11yProps(0)} />
                            <Tab sx={{ textTransform: "none", fontWeight: "400" }} label="Purchase" {...a11yProps(1)} />
                            <Tab sx={{ textTransform: "none", fontWeight: "400" }} label="Lease" {...a11yProps(2)} />
                            <Tab sx={{ textTransform: "none", fontWeight: "400" }} label="Investment" {...a11yProps(3)} />
                            <Tab sx={{ textTransform: "none", fontWeight: "400" }} label="Far" {...a11yProps(4)} />
                            <Tab sx={{ textTransform: "none", fontWeight: "400", width: "228px" }} label="Receivable and payable" {...a11yProps(5)} />
                            <Tab sx={{ textTransform: "none", fontWeight: "400", width: "228px" }} label="Presets" {...a11yProps(6)} />

                          </Tabs>
                        </Box>
                      </Tabs>
                    </Box>
                  </CardContent>
                </Card>
                <CustomTabPanel value={value} index={0}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>REGISTER NAME</TableCell>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center">CURRENT YEAR</TableCell>
                          <TableCell align="center">PREVIOUS YEAR</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>Reg_1</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="left">ABC</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center"> May 1 2023</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">April 31 2022</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">
                            <MoreVertIcon onClick={handleClick} />
                            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                              <Typography sx={{ p: 2 }}>Edit <br />Delete</Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>YOUR NAME </TableCell>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center">YOUR AGE</TableCell>
                          <TableCell align="center">YOUR NUMBER</TableCell>
                          <TableCell align="center">YOUR CITY</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>DEEPAK</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="left">RIXOSYS</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">23</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">4567890</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">
                            <MoreVertIcon onClick={handleClick} />
                            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left", }}>
                              <Typography sx={{ p: 2 }}>Edit<br />Delete</Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>YOUR SCHOOL NAME</TableCell>
                          <TableCell align="left">COLLAGE NAME</TableCell>
                          <TableCell align="center"> YEAR</TableCell>
                          <TableCell align="center">PASSOUT YEAR</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>Reg_1</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">May 1 2023</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">April 31 2022</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">
                            <MoreVertIcon onClick={handleClick} />
                            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left", }}>
                              <Typography sx={{ p: 2 }}>Edit<br /> Delete</Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>PARENT COMPANY</TableCell>
                          <TableCell align="left">INVESTEES</TableCell>
                          <TableCell align="center"> NO OF SHARES (CY)</TableCell>
                          <TableCell align="center">NO OF SHARES (PY)</TableCell>
                          <TableCell align="center">PERCENTAGE HOLDING</TableCell>
                          <TableCell align="center">
                            NATURE OF RELATIONSHIP
                          </TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>hdhd</TableCell>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>hdhd</TableCell>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>09</TableCell>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>76</TableCell>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>90</TableCell>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>Subsidiary Company</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">
                            <MoreVertIcon onClick={handleClick} />
                            <Popover style={{ marginInline: "-21px" }} id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left", }}>
                              <Typography sx={{ p: 2 }}>Edit<br />Delete</Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>PARENT COMPANY</TableCell>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center"> YEAR</TableCell>
                          <TableCell align="center">PREVIOUS YEAR</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>Reg_1</TableCell>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>Reg_1</TableCell>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>Reg_1</TableCell>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>Reg_1</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">
                            <MoreVertIcon onClick={handleClick} />
                            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left", }}>
                              <Typography sx={{ p: 2 }}>Edit<br />Delete</Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>REGISTER NAME</TableCell>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center"> YEAR</TableCell>
                          <TableCell align="center">PREVIOUS YEAR</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow><TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>Reg_</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="left">ABC</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="left">ABC</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="left">ABC</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">
                            <MoreVertIcon onClick={handleClick} />
                            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                              <Typography sx={{ p: 2 }}>Edit<br />Delete</Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={6}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>REGISTER NAME</TableCell>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center">CURRENT YEAR</TableCell>
                          <TableCell align="center">PREVIOUS YEAR</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ backgroundColor: "white" }}>Reg_1</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="left">ABC</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center"> May 1 2023</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">April 31 2022</TableCell>
                          <TableCell style={{ backgroundColor: "white" }} align="center">
                            <MoreVertIcon onClick={handleClick} />
                            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                              <Typography sx={{ p: 2 }}>Edit <br />Delete</Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Tabsystem;

