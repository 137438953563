import React from 'react'

const DotIndicatorSVGComponent = ({ color, height, width }) => {
  return (
    <svg 
      width={width} 
      height={height}
      viewBox="0 0 13 21" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="2.4976" cy="2.01613" rx="2.08539" ry="2.01613" fill={color} />
      <ellipse cx="2.4976" cy="10.0484" rx="2.08539" ry="2.01613" fill={color} />
      <ellipse cx="2.4976" cy="18.0806" rx="2.08539" ry="2.01613" fill={color} />
      <ellipse cx="10.8057" cy="2.01613" rx="2.08539" ry="2.01613" fill={color} />
      <ellipse cx="10.8057" cy="10.0484" rx="2.08539" ry="2.01613" fill={color} />
      <ellipse cx="10.8057" cy="18.0806" rx="2.08539" ry="2.01613" fill={color} />
    </svg>
  )
}

export default DotIndicatorSVGComponent