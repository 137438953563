import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export default function CleanupWrapper() {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if(pathname !== "/financialstatement"){
            dispatch({ type: "DISCLOSURE_CHECKLIST_DATA", payload: [] });
            dispatch({ type: "NOTE_KEY", payload: 0 });
            dispatch({ type: "NOTE_BS_NUMBER", payload: 0 });
            dispatch({ type: "STANDALONE_SOCIE_DATA", payload: {} });
            dispatch({
                type:"TT_BS_FETCH_DATA_FAILED_FINANCIAL_STATEMENT",
                payload: null
            })
        }
        if(pathname === "/dashboard"){
            dispatch({ type: "SA_ADJ_CLEANUP", payload: {} });
            dispatch({ type: "PROJECT_DATA", payload: {} });
            dispatch({ type: "BOOK_cLOSURE_CLEANUP", payload: {} });
        }
    }, [pathname]);

    return null;
}