import React, { useEffect } from "react";
import "./../Workspace-v2/sidebarv2.css";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Button from "@mui/material/Button";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Overview from "../Components/FSPreparetion/Overview";
import FinancialStatementv2 from "../Components/FSPreparetion/Overview";
import { useHistory } from "react-router";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import { getWorkspace } from "../Components/ApiServices/apiHandler";
import { useState } from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import { fetchWSData } from "../../redux/actions/workspace/actionWorkspace";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";

const Sidbarv2 = (props) => {
  const [workspaceData, SetWorkspaceData] = useState("");
  const workspace_data = useSelector((state) => state.WSReducer);
  const { w_id } = props;
  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    if (workspace_data.isSuccess === false) {
      dispatch(fetchWSData(w_id[2]));
    }
    workSpaceData();
  }, []);

  const workSpaceData = async () => {
    const res = await getWorkspace(w_id[2]);
    if (res.status === 200) {
      dispatch(fetchWSData(w_id[2]));
    }
  };
  const handleClick = (name) => {
    if (name === "Overview") {
      // history.push(`/overview/${w_id[2]}`);
    } else if (name === "FinancialStatement") {
      history.push(`/financialstatement/${w_id[2]}`);
    } else if (name === "WorkSpaceSetting") {
      history.push(`/workspacedeatils/${w_id[2]}`);
    } else if (name === "Consolidation") {
      history.push(`/consolidation/${w_id[2]}`);
    } else if (name === "TeamMember") {
      history.push(`/TeamMemebers/${w_id[2]}`);
    } else if (name === "WorkSpaceRegister") {
      history.push(`/workspaceregister/${w_id[2]}`);
    } else if (name === "WorkSpacePreset") {
      history.push(`/workspacePreset/${w_id[2]}`);
    } else if (name === "bookclosure") {
      history.push(`/bookclosure/${w_id[2]}`);
    } else {
      toast.warning("Route Not Found")
    }
  };

  return (
    <>
      <div className="sidebar_v0">
        <div className="fs_v021">
          <Button className="fsbtn_v00 font-inter">{workspace_data.response[0]?.workspace_name?.slice(0, 2)}</Button>
          {/* <MenuOpenIcon
            style={{ height: "50px", width: "30px", marginTop: "20px" }}
          /> */}
        </div>
        <div className="fstitle_v4">
          <div className='font-inter' style={{ fontWeight: "600", wordWrap: "break-word" }}>{workspace_data.response[0]?.workspace_name?.toUpperCase()}</div>
          <div
            className='font-inter'
            style={{ fontSize: "13px", color: "#03565A", fontWeight: "550", textTransform: "uppercase" }}
          >
            {w_id[1] === "financialstatement" ? "FINANCIAL STATEMENT" : w_id[1] === "workspacedeatils" ? "WORKSPACE DETAILS" : w_id[1] === "TeamMemebers" ? "TEAM MEMBERS" : w_id[1] === "workspaceregister" ? "WORKSPACE REGISTER" : w_id[1]}

          </div>
        </div>
        <hr style={{ width: "85%", marginLeft: "20px" }} />
        <div style={{}}>
          {w_id[1] === "overview" ? (
            <div className="department" onClick={() => handleClick("Overview")}>
              <InsertPhotoIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px", cursor: "pointer" }}>Overview</div>
            </div>
          ) : (
            <div
              className="department_inactive"
              onClick={() => handleClick("Overview")}
            >
              <InsertPhotoIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px", cursor: "pointer" }}>Overview</div>
            </div>
          )}
          {/*  */}
          {workspace_data.loading && !workspace_data.response ?
            ""
            :
            workspace_data.response[0]?.book_closure_as_on_date === null ||
              workspace_data.response[0]?.book_closure_as_on_date === "" ? (
              ""
            ) : (
              <>
                {w_id[1] === "bookclosure" ? (
                  <div className="department" onClick={() => handleClick("bookclosure")}>
                    <DescriptionIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>Book closure</div>
                  </div>
                ) : (
                  <div
                    className="department_inactive"
                    onClick={() => handleClick("bookclosure")}
                  >
                    <DescriptionIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>Book closure</div>
                  </div>
                )}
              </>
            )}


          {/*  */}


          {workspace_data.loading && !workspace_data.response ?
            ""
            :
            workspace_data.response[0]?.standalone_cy === null ||
              workspace_data.response[0]?.standalone_cy === "" ? (
              ""
            ) : (
              <>
                {w_id[1] === "financialstatement" ? (
                  <div
                    className="department"
                    onClick={() => handleClick("FinancialStatement")}
                  >
                    <TextSnippetIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>Standalone</div>
                  </div>
                ) : (
                  <div
                    className="department_inactive"
                    onClick={() => handleClick("FinancialStatement")}
                  >
                    <TextSnippetIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>Standalone</div>
                  </div>
                )}
              </>
            )}

          {/*  */}
          {workspace_data.loading && !workspace_data.response ?
            //  <p><CircularProgress /></p> 
            ""
            :
            workspace_data.response[0]?.console_cy === null ||
              workspace_data.response[0]?.console_cy === "" ? (
              ""
            ) : (
              <>
                {w_id[1] === "consolidation" ? (
                  <div
                    className="department"
                    onClick={() => handleClick("Consolidation")}
                  >
                    <SnippetFolderIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>Consolidation</div>
                  </div>
                ) : (
                  <div
                    className="department_inactive"
                    onClick={() => handleClick("Consolidation")}
                  >
                    <SnippetFolderIcon style={{ marginRight: "5px" }} />
                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>Consolidation</div>
                  </div>
                )}
              </>
            )}

          {w_id[1] === "workspacedeatils" ? (
            <div
              className="department"
              onClick={() => handleClick("WorkSpaceSetting")}
            >
              <SettingsIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px", cursor: "pointer" }}>Workspace Settings</div>
            </div>
          ) : (
            <div
              className="department_inactive"
              onClick={() => handleClick("WorkSpaceSetting")}
            >
              <SettingsIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px", cursor: "pointer" }}>Workspace Settings</div>
            </div>
          )}

          {w_id[1] === "TeamMemebers" ? (
            <div
              className="department"
              onClick={() => handleClick("TeamMember")}
            >
              <PeopleAltIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px", cursor: "pointer" }}>Team Member</div>
            </div>
          ) : (
            <div
              className="department_inactive"
              onClick={() => handleClick("TeamMember")}
            >
              <PeopleAltIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px", cursor: "pointer" }}>Team Member</div>
            </div>
          )}
          <hr style={{ width: "85%", marginLeft: "20px" }} />
          {/*  */}
          {w_id[1] === "workspaceregister" ? (
            <div
              className="department"
              onClick={() => handleClick("WorkSpaceRegister")}
            >
              <TextSnippetIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px", cursor: "pointer" }}>Workspace Registers</div>
            </div>
          ) : (
            <div
              className="department_inactive"
              onClick={() => handleClick("WorkSpaceRegister")}
            >
              <TextSnippetIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px", cursor: "pointer" }}>Workspace Registers</div>
            </div>
          )}

          {/* {w_id[1] === "workspacePresetV2" ? (
            <div
              className="department"
              onClick={() => handleClick("WorkSpacePreset")}
            >
              <PeopleAltIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px" }}>WorkSpace presets</div>
            </div>
          ) : (
            <div
              className="department_inactive"
              onClick={() => handleClick("WorkSpacePreset")}
            >
              <PeopleAltIcon style={{ marginRight: "5px" }} />
              <div style={{ marginLeft: "5px" }}>WorkSpace presets</div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Sidbarv2;
