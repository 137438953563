import { React, useEffect, useState } from "react";
import NavBarV2 from "../../NavBarV2";
import Card from "react-bootstrap/Card";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import TableContainer from "@mui/material/TableContainer";
import Tab from "@mui/material/Tab";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Fade from "@material-ui/core/Fade";
import Radio from "@mui/material/Radio";
import moment from "moment";
import Worklogo from "../../../assets/dashboardworkspace.svg";
import CardContent from "@mui/material/CardContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputAdornment, TextField } from "@mui/material";
import Table from "react-bootstrap/Table";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import "../../../WorkSpace/SignUp2.css";
// import AddCompanyModal from "../Modal/editCompanyModal";
import "../../../WorkSpace/font.css";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tabsystem from "../../Workspace-v2/Tabsystem";
import Tabs from "@mui/material/Tabs";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Navbar } from "../../../Components/Navbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IrRegister } from "../../../Console/Component/services/apiHandler";
import {
  DuplicateWorkspace,
  GetAccess,
  TeamMember,
  WorkspaceUpdate,
  createWorkspaceName,
  deleteIr,
} from "../ApiServices/apiHandler";
import { useHistory } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { red } from "@mui/material/colors";
import swal from "sweetalert";
import { fetchWorkspace } from "../../../redux/actions/workspace/actionAllWrokspace";
import { useSelector, useDispatch } from "react-redux";

import "../../../WorkSpace/RixoWP.css";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 789,
  height: 451,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const WorkspaceAllData = (props) => {
  const {
    allWorkspace,
    nextStep,
    handleClickData,
    allAcessWorkspace,
    workspace,
    UserName,
    // handleClick,
    allIr,
    nextWorkspace,
    handleOpenInvestment,
    company,
    a11yProps,
    handleClicktab,
    CustomTabPanel,
    setAllIR,
  } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [Irid, setId] = useState("");
  const [WSPID, setWSp] = useState("");
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [rename, setName] = useState("");
  const [renameId, setRenameID] = useState("");


  useEffect(() => {
    // if(allWorkspace.isSuccess === false){
    //   dispatch(fetchWorkspace());
    // }
  }, []);
  let history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenRenameModal(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const opentab = Boolean(anchorE2);
  const idtab = opentab ? "simple-popover" : undefined;

  const handleDeleteIr = async (Ir_id) => {
    const res = await deleteIr(Ir_id);
    if (res.status === 200) {
      setAllIR();
      setAnchorEl(null);
    }
  };
  const handleClosetab = () => {
    setAnchorE2(null);
  };
  const handleEditIr = (ir_id) => {
    console.log(ir_id, "uuuu");
    history.push(`/investmentregisterv2/${ir_id}`);
  };

  const handleSelectIr = (ir_id) => {
    history.push(`/insideworkspace/${ir_id}`);
  };

  const CompanyName = (c_id) => {
    const result = company.filter((adj) => adj._id === c_id);
    return result[0]?.company_name;
  };

  const handleClickWsp = (event, id) => {
    setAnchorE2(event.currentTarget);
    setWSp(id);
  };

  const handleViewOnly = async (workspace_id, check) => {
    if (check === "on") {
      let data = {
        access_status: "view",
      };
      const res = await WorkspaceUpdate(data, workspace_id);
      if (res.status === 200) {
        console.log("success");
        swal("", "View Access On", "success");
        setAnchorE2(false);
        dispatch(fetchWorkspace());
      }
    } else if (check === "off") {
      let data = {
        access_status: "off",
      };
      const res = await WorkspaceUpdate(data, workspace_id);
      if (res.status === 200) {
        console.log("success");
        swal("", "View Access Off", "success");
        setAnchorE2(false);
        dispatch(fetchWorkspace());
      }
    }
  };
  const handleChangeData = (e) => {
    setName(e.target.value);
  };
  const submitCompany = async () => {
    const data = {
      workspaceName: rename,
    };
    const result = await createWorkspaceName(renameId, data);
    if (result.status === 200) {
      setAnchorE2(false);
      setOpenRenameModal(false);
      dispatch(fetchWorkspace());
    }
    console.log(result.data);
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  };
  const handleDuplicate = async (workspace_id) => {
    const res = await DuplicateWorkspace(workspace_id);
    if (res.status === 200) {
      dispatch(fetchWorkspace());
    }
  };
  const handleAddTeam = (workspace_id) => {
    history.push(`/inviteteammember/${workspace_id}`);
  };
  const handleRename = (workspace_id) => {
    setOpenRenameModal(true);
    setRenameID(workspace_id);
  };

  const shareUser = (id) => {
    try {
      const nameAcess = allAcessWorkspace.filter(
        (adj) => adj.workspace_id === id
      );
      const result = nameAcess?.email ? nameAcess?.email : "";
      return result.slice(0, 2);
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  return (
    <>
      <div className="rixo-container">
        <div className="row mt-3">
          <div className="col-6"> <h2 className="rwp-title">My Workspace</h2></div>
          <div className="col-6 text-right"> <button className="rwp-btn" onClick={nextStep}> <span>
            <AddIcon />
          </span> Create workspace</button> </div>
        </div>

        <div className="row mt-4">
          <div className="col-10">
            <div className="row">
              {allWorkspace.slice(0, 3).map((det) => {
                return (
                  <>
                    <div className="col-4 mb-4">
                      <Card>
                        <CardContent>
                          <div className="d-flex justify-content-between">
                            <div
                              onClick={() => handleClickData(det._id)}
                              style={{ fontWeight: "600" }}
                            >
                              {det.workspace_name}
                            </div>
                            <div>
                              <MoreHorizIcon
                                onClick={(e) => handleClickWsp(e, det._id)}
                              />
                              {det.access_status === "view" ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              <Popover
                                style={{}}
                                id={idtab}
                                open={opentab}
                                anchorEl={anchorE2}
                                onClose={handleClosetab}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  {det.access_status === "view" ? (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleViewOnly(WSPID, "off")}
                                    >
                                      {"View Only Off"}
                                    </span>
                                  ) : (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleViewOnly(WSPID, "on")}
                                    >
                                      {"View Only"}
                                    </span>
                                  )}

                                  <br />
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDuplicate(WSPID)}
                                  >
                                    {"Duplicate"}
                                  </span>
                                  <br />
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleAddTeam(WSPID)}
                                  >
                                    {"Add Team Member"}
                                  </span>
                                  <br />
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRename(WSPID)}
                                  >
                                    {"Rename"}
                                  </span>
                                </Typography>
                              </Popover>
                            </div>
                          </div>
                          <div
                            onClick={() => handleClickData(det._id)}
                            style={{ fontSize: "12px" }}
                          >
                            Created {moment(det.createdAt).fromNow()}
                          </div>
                          <div
                            onClick={() => handleClickData(det._id)}
                            style={{ marginTop: "15px", fontWeight: "600" }}
                          >
                            Team Members
                          </div>

                          <div style={{ display: "flex", flexDirection: "row" }}>
                            {shareUser(det._id) === "" ? (
                              ""
                            ) : (
                              <div
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "25px",
                                  backgroundColor: "yellow",
                                }}
                                onClick={() => handleClickData(det._id)}
                              >
                                <div style={{ marginTop: "14px", marginLeft: "15px" }}>
                                  {shareUser(det._id)}
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              marginTop: "8px",
                              fontWeight: "600",
                              color: "gray",
                            }}
                            onClick={() => handleClickData(det._id)}
                          >
                            {"Standalone CY"} &nbsp;
                            {det.standalone_cy ? det.standalone_cy : "-"}
                          </div>
                          <div
                            style={{
                              marginTop: "8px",
                              fontWeight: "600",
                              color: "gray",
                              // width: "100px",
                            }}
                            onClick={() => handleClickData(det._id)}
                          >
                            {"Standalone PY"} &nbsp;
                            {det.standalone_py ? det.standalone_py : "-"}
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </>
                );
              })}

              {allAcessWorkspace.length > 0 &&
                allAcessWorkspace.slice(0, 1).map((det) => {
                  return (
                    <>
                      <div className="col-4 mb-3">
                        <Card
                          onClick={() => handleClickData(det.workspace_id)}
                        >
                          <CardContent>
                            <div className="d-flex justify-content-between">
                              <div style={{ fontWeight: "600" }}>
                                {workspace(det.workspace_id, "id")} 111
                              </div>
                              <div>
                                <MoreHorizIcon />
                              </div>
                            </div>
                            <div style={{ fontSize: "12px" }}>
                              {/* Created {moment(det.workspace(det.workspace_id,"created_at")).fromNow()} */}
                            </div>
                            <div style={{ marginTop: "15px", fontWeight: "600" }}>
                              Team Members
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "600",
                              }}
                            >
                              Shared By{" "}
                              {UserName(det.user_id).substring(
                                0,
                                UserName(det.user_id).lastIndexOf("@")
                              )}
                            </div>
                            <div className="font-inter"
                              style={{
                                marginTop: "8px",
                                fontWeight: "600",
                                color: "gray",
                              }}
                            >
                              LINKED REGISTERS
                            </div>
                            <div
                              style={{
                                marginTop: "8px",
                                fontWeight: "600",
                                color: "gray",
                                width: "100px",
                              }}
                            >
                              {det.access}
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </>
                  );
                })}

            </div>
          </div>
          <div className="col-2 text-right">
            {allWorkspace.slice(0, 3).length > 2 ? (
              <div className="hover_view"
                onClick={nextWorkspace}
              >
                <div className="myDIV"><span> <ArrowForwardIcon /></span>
                </div>
                <div className="hide">View all</div>
              </div>
            ) : (
              ""
            )}

          </div>
        </div>

        <div className="row mt-3">
          <div className="col-6"> <h2 className="rwp-title">Data Register</h2></div>
          <div className="col-6 text-right"> <button className="rwp-btn" onClick={handleOpenInvestment}><span>
            <AddIcon />
          </span> Create New Register</button> </div>
        </div>
        <Box>
          <Card style={{ marginTop: "10px" }} className="tabtoggle">
            <CardContent>
              <Box>

                <Box sx={{}}>
                  <Tabs
                    sx={{
                      "& button": {
                        borderRadius: 1,
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "700",
                      },
                      "& button.Mui-selected": {
                        backgroundColor: "#03565A",
                        color: "white",
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                  >
                    <Tab
                      sx={{

                        textTransform: "none",
                        fontWeight: "700",
                      }}
                      label="Sales"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        textTransform: "none",
                        fontWeight: "700",
                      }}
                      label="Purchase"
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        textTransform: "none",
                        fontWeight: "700",
                      }}
                      label="Lease"
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        textTransform: "none",
                        fontWeight: "700",
                      }}
                      label="Investment"
                      {...a11yProps(3)}
                    />
                    <Tab
                      sx={{
                        textTransform: "none",
                        fontWeight: "700",
                      }}
                      label="Far"
                      {...a11yProps(4)}
                    />
                    <Tab
                      sx={{
                        textTransform: "none",
                        fontWeight: "700",
                        width: "228px",
                      }}
                      label="Receivable & Payable"
                      {...a11yProps(5)}
                    />
                    <Tab
                      sx={{
                        textTransform: "none",
                        fontWeight: "700",
                        width: "228px",
                      }}
                      label="Preset"
                      {...a11yProps(6)}
                    />
                  </Tabs>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <CustomTabPanel value={value} index={0}>
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell className="dm-sans">Register Name</TableCell>
                    <TableCell align="left" className="dm-sans">Company Name</TableCell>
                    <TableCell align="center" className="dm-sans">Current Year</TableCell>
                    <TableCell align="center" className="dm-sans">Previous Year</TableCell>
                    <TableCell align="center" className="dm-sans">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ backgroundColor: "white" }}
                      >
                        Reg_1
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="left"
                      >
                        ABC
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        {" "}
                        May 1 2023
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        April 31 2022
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        <MoreVertIcon onClick={handleClicktab} />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            Edit <br />
                            Delete
                          </Typography>
                        </Popover>
                      </TableCell>
                    </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell className="dm-sans">Your Name </TableCell>
                    <TableCell align="left" className="dm-sans">Company Name</TableCell>
                    <TableCell align="center" className="dm-sans">Your Age</TableCell>
                    <TableCell align="center" className="dm-sans">Your Number</TableCell>
                    <TableCell align="center" className="dm-sans">Your City</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ backgroundColor: "white" }}
                      >
                        DEEPAK
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="left"
                      >
                        RIXOSYS
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        23
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        4567890
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        <MoreVertIcon onClick={handleClick} />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            Edit
                            <br />
                            Delete
                          </Typography>
                        </Popover>
                      </TableCell>
                    </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell className="dm-sans">Your School Name</TableCell>
                    <TableCell align="left" className="dm-sans">Collage Name</TableCell>
                    <TableCell align="center" className="dm-sans"> Year</TableCell>
                    <TableCell align="center" className="dm-sans">Passout Year</TableCell>
                    <TableCell align="center" className="dm-sans">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ backgroundColor: "white" }}
                      >
                        Reg_1
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        May 1 2023
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        April 31 2022
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        <MoreVertIcon onClick={handleClick} />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            Edit
                            <br /> Delete
                          </Typography>
                        </Popover>
                      </TableCell>
                    </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell className="dm-sans">IR Name </TableCell>
                    <TableCell className="dm-sans">Parent Company </TableCell>
                    <TableCell align="left" className="dm-sans">Investees</TableCell>
                    <TableCell align="center" className="dm-sans"> No Of Shares (CY)</TableCell>
                    <TableCell align="center" className="dm-sans">No Of Shares (PY)</TableCell>
                    <TableCell align="center" className="dm-sans">Percentage Holding</TableCell>
                    <TableCell align="center" className="dm-sans" >
                      Nature Of Relationship
                    </TableCell>
                    <TableCell align="center" className="dm-sans">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allIr?.length > 0 &&
                    allIr?.map((invest) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                backgroundColor: "white",
                                color: "blue",
                                cursor: "pointer",
                              }}
                              onClick={(e) => handleSelectIr(invest.ir_id)}
                            >
                              {invest.ir_name}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ backgroundColor: "white" }}
                            >
                              {CompanyName(invest.parent_company_id)}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ backgroundColor: "white" }}
                            >
                              {CompanyName(invest.company)}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ backgroundColor: "white" }}
                            >
                              {invest.no_of_shares}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ backgroundColor: "white" }}
                            >
                              76
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ backgroundColor: "white" }}
                            >
                              90
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ backgroundColor: "white" }}
                            >
                              {invest.nature_of_relationship}
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              <MoreVertIcon
                                onClick={(e) => handleClick(e, invest._id)}
                              />
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleEditIr(Irid)}
                                  >
                                    Edit
                                  </span>{" "}
                                  <br />
                                  <span
                                    onClick={() => handleDeleteIr(Irid)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Delete
                                  </span>
                                  <br />
                                  <span style={{ cursor: "pointer" }}>
                                    Extract
                                  </span>
                                </Typography>
                              </Popover>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell className="dm-sans">Parent Company</TableCell>
                    <TableCell align="left" className="dm-sans">Company Name</TableCell>
                    <TableCell align="center" className="dm-sans"> Year</TableCell>
                    <TableCell align="center" className="dm-sans">Previous Year</TableCell>
                    <TableCell align="center" className="dm-sans">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ backgroundColor: "white" }}
                      >
                        Reg_1
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ backgroundColor: "white" }}
                      >
                        Reg_1
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ backgroundColor: "white" }}
                      >
                        Reg_1
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ backgroundColor: "white" }}
                      >
                        Reg_1
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        <MoreVertIcon onClick={handleClick} />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            Edit
                            <br />
                            Delete
                          </Typography>
                        </Popover>
                      </TableCell>
                    </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell className="dm-sans">Register Name</TableCell>
                    <TableCell align="left" className="dm-sans">Company Name</TableCell>
                    <TableCell align="center" className="dm-sans">Year</TableCell>
                    <TableCell align="center" className="dm-sans">Previous Year</TableCell>
                    <TableCell align="center" className="dm-sans">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell className="dm-sans">Register Name</TableCell>
                    <TableCell align="left" className="dm-sans"> Company Name</TableCell>
                    <TableCell align="center" className="dm-sans">Current Year</TableCell>
                    <TableCell align="center" className="dm-sans">Previous Year</TableCell>
                    <TableCell align="center" className="dm-sans">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ backgroundColor: "white" }}
                      >
                        Reg_1
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="left"
                      >
                        ABC
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        {" "}
                        May 1 2023
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        April 31 2022
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        <MoreVertIcon onClick={handleClicktab} />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            Edit <br />
                            Delete
                          </Typography>
                        </Popover>
                      </TableCell>
                    </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
        </Box>
      </div>

      {/* modal rename */}

      <Modal
        open={openRenameModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="col-12">
            <h5>Rename Workspace</h5>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={(e) => handleChangeData(e)}
                />
              </div>
              <input
                type="button"
                className="btn btn-primary1"
                value="Submit"
                onClick={submitCompany}
              />
              <input
                type="button"
                className="btn btn-defult"
                value="Close"
                onClick={handleClose}
              />
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default WorkspaceAllData;
