import React from "react";
import HeaderIndAs1 from "../../Components/Library/HeaderIndAs1";
import IndAs1Content from "../../Components/Library/IndAs1Content";
import { Navbar } from "../../Components/Navbar";

const LibraryIndAs1 = () => {
  return (
    <>
        <div>
          <Navbar />
          {/* <HeaderIndAs1 /> */}
          <IndAs1Content />
        </div>
    </>
  )
}

export default LibraryIndAs1
