import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useState,useEffect } from "react";
import { cities, state, getApprover } from "../ApiServices/apiHandler";


const stylemodal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

const AddNewCompany = (props) => {
  const { open, handleClose, handleChangeDataSubmit ,submitCompany,searchTerm,handleApproverChange} = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [allcities, setCitiesData] = useState([]);
  const [allstate, setStateData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [approverId,setApproverId]=useState([]);
  

  useEffect(() => {
    getApproverIds();
  }, []);

  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };
  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };
  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };
  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const getApproverIds = async()=>{
    const parent_id = user.parent_id;
      getApprover(parent_id)
        .then(response => {
          setApproverId(response.data.result);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }
  
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="wp-modal-box wp-modal-box-add-company-form">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h5 class="dbwcardhead">Create new company</h5>
          </Typography>
          <div style={{ marginTop: "30px" }}>
            <label>Enter your organization name</label>
            <TextField
              className="wp-add-comp-modal-input"
              onChange={(e) =>
                handleChangeDataSubmit(e.target.value, "org_name")
              }
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>CIN No.</label>
            <TextField
              className="wp-add-comp-modal-input"
              onChange={(e) => handleChangeDataSubmit(e.target.value, "ci_no")}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>PAN/TAN No.</label>
            <TextField
              className="wp-add-comp-modal-input"
              onChange={(e) => handleChangeDataSubmit(e.target.value, "pan_no")}
            />
          </div>
          <div className="row">
            <div className="col-12" style={{ marginTop: "20px" }}>
              <label>Nature of company</label>
              
              <select
                className="wp-add-comp-modal-input"
                onChange={(e) =>
                  handleChangeDataSubmit(e.target.value, "nature_of_company")
                }
                style={{
                  height: "60px",
                  borderRadius:"6px"
                }}
              >
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Select..."}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Manufacturing"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"NBFC"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Banking"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Trading"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Other Specify"}
                </option>
              </select>
            </div>
            <div className="col-12" style={{ marginTop: "20px" }}>
              <label>Functional currency</label>
              <select
                className="wp-add-comp-modal-input"
                onChange={(e) =>
                  handleChangeDataSubmit(e.target.value, "functional")
                }
                style={{
                  height: "60px",
                  borderRadius:"6px"
                }}
              >
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Select..."}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"INR"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"USD"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"LKR"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"AUD"}
                </option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-6" style={{ marginTop: "20px" }}>
              <label>Select City</label>
              {/* <TextField
                  className="wp-add-comp-modal-input"
                  placeholder="Type a company name or search"
                  multiline
                /> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                // options={allcities?allcities:[]}
                options={!allcities ? [] : allcities}
                onChange={(e, value) => handleChangeDataSubmit(value, "city")}
                onInputChange={onInputChangecity}
                sx={{ width: 300, backgroundColor: "white" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=<LocationOnIcon />
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="col-6" style={{ marginTop: "20px" }}>
              <label>Select State</label>
              {/* <TextField
                className="wp-add-comp-modal-input"
                placeholder="Type a company name or search"
                multiline
              /> */}
              <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={!allstate ? [] : allstate}
              onChange={(e, value) => handleChangeDataSubmit(value, "state")}
              onInputChange={onInputChange}
              className="wp-add-comp-modal-input"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=<LocationOnIcon />
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      border: "none",
                    },
                  }}
                />
              )}
            />
            </div>
          </div>
          <div className="blankspace30"></div>
          {
          user.responsibilities==="approver"?
          <>
          <Button className="wp-btn-fill" >Approve</Button>
          &nbsp; &nbsp;
          <Button className="wp-btn-no-fill" >
            Return
          </Button>
          </>:
          user.responsibilities==="preparer" && user?.unrestrictedaccess===false ?<>
           <Button className="wp-btn-fill" onClick={handleOpenModal}>Send for Approval</Button>
          &nbsp; &nbsp;
          <Button className="wp-btn-no-fill"onClick={handleClose}>
            Cancel
          </Button>
          </>:
          <>
          <Button className="wp-btn-fill" onClick={submitCompany}>Save</Button>
          &nbsp; &nbsp;
          <Button className="wp-btn-no-fill" onClick={handleClose}>
            Cancel
          </Button>
          </>
          }
        </Box>
      </Modal>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={stylemodal}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => handleApproverChange(e.target.value, "approver_id")}
          >
          {approverId && approverId.length>0 && approverId.map((data)=>
            <MenuItem key={data._id} value={data._id}>{data.name}</MenuItem>
          )}
          </Select>
          </FormControl>
          <div className="d-flex flex-row justify-content-center mt-3 ">
          <Button style={{
              backgroundColor: "#03565a",
              color: "white",
              textTransform: "none",
              margin:"10px",
              height:"36px",
              width:"50%"
            }}
            onClick={handleCloseModal}
            >
              Return
          </Button>
          <Button
            style={{
              backgroundColor: "#03565a",
              color: "white",
              textTransform: "none",
              width:"50%",
              height:"36px",
              marginTop:"10px"
            }}
            onClick={() => {
              submitCompany();
              setOpenModal(false);
            }}
          >
            Send for approval
          </Button>
        </div>
        </Box>
      </Modal>
    </>
  );
};
export default AddNewCompany;
