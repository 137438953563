import React from 'react';
import Card from '@mui/material/Card';
import TaskSVGComponent from './SvgComponents/BookClosureTask';

const BookClosureProgressCard = ({ icon, noOfTask, name}) => {
    return (
        <div className="col-md-4">
            <div className='px-3 ml-1 p-3 w-100 h-100 custom-bg-white-150 radius-8px shadow-sm d-flex justify-content-between align-items-center'>
                <div style={{width:"50px", height:"50px"}} className="bg-warning-400 rounded-circle d-flex justify-content-center align-items-center">
                    {icon}
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <p className='font-size-24 custom-font-600 font-inter mt-1 mb-0'>
                        {noOfTask}
                    </p>
                    <p className='mt-0 mb-0 ml-2 font-size-16 custom-font-500 font-sans text-secondary'>
                        {name}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BookClosureProgressCard