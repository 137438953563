import { Navbar } from "../../../Components/Navbar";
import Button from "react-bootstrap/Button";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import Card from "react-bootstrap/Card";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import DashboardLogo from "../../../assets/dashboard1.svg";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { createlogs } from "../../../helper/logs";
import {
  deleteRoleData,
  deleteUserData,
  getMeber,
  getRolePermission,
  saveRolePermission,
  saveUserData,
} from "../ApiServices/apiHandler";
import { allPermission } from "../../../Console/Component/services/mockHandler";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 789,
  height: 700,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AddTeam = () => {
  const [teamModal, setTeam] = useState(false);
  const [roleModal, setRoleModel] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [allRole, setAllRole] = useState([]);
  const [allUser, setMember] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [allPermissiondata, setAllPermissionData] = useState([]);
     const history = useHistory();
  // example


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open ? "simple-popover" : undefined;
  const [roleId, setRoleID] = useState("");
  const [userID, setUserID] = useState("");
  const [Value, setUser] = useState({
    org_name: "",
    nameL: "",
    email: "",
    password: "",
    role: "",
  });

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };
  const handleClick = () => {
    if (allRole.length > 0) {
      setTeam(true);
    } else {
      swal("", "Please Create Role", "error");
    }
  };
  const handleClosetab = () => {
    setTeam(false);
    setRoleModel(false);
  };
  const handleClicktabRole = (event, r_id) => {
    setRoleID(r_id);
    setAnchorEl(event.currentTarget);
  };
  const handleClicktab = (event, u_id) => {
    setUserID(u_id);
    setAnchorEl2(event.currentTarget);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickRole = () => {
    setRoleModel(true);
  };

  useEffect(() => {
    fetchRole();
    fetchMember();
    alldataPermission();
  }, []);

  const allPermissionName = [
    {
      type: "IR",
    },
    {
      type: "Workspace",
    },
  ];
  let Permission = {"role": [
    {
      "subject": "IR",
      "permissions": []
    },
    {
      "subject": "Workspace",
      "permissions": []
    }
  ]}
  const handleChangePermission = (e, data,field) => {
    if (field === "IR") {
      Permission.role[0].permissions.push(data);
    } else {
      Permission.role[1].permissions.push(data);
    }
    // setSelectedPermission(Permission);
  };

  const handleChangeRole = (e) => {
    setRoleName(e);
  };

  const handleSaveRole = async () => {
    const data = {
      role: roleName,
      permissions: Permission.role,
    };
    
    const result = await saveRolePermission(data);
    
    if (result.status === 200) {
      swal("", "Saved Successfuly", "success");
      setRoleModel(false);
      fetchRole();
    }
  };

  const fetchRole = async () => {
    const res = await getRolePermission();
    if (res.status === 200) {
      setAllRole(res.data.rolePermission);
    }
  };

  const handleChangeUser = (field, value) => {
    setUser({ ...Value, [field]: value });
  };

  const saveUser = async () => {
    const data = {
      org_name: Value.org_name,
      name: Value.name,
      email: Value.email,
      password: Value.password,
      isAdmin: "No",
      role: Value.role,
    };

    const res = await saveUserData(data);
    if (res.status === 200) {
      swal("", "Saved Successfully", "success");
      setTeam(false);
      fetchMember();
    }
  };

  const fetchMember = async () => {
    const res = await getMeber();
    if (res.status === 200) {
      setMember(res.data.fetchMember);
    }
  };

  const deleteRole = async (r_id) => {
    const checkRole = allUser.filter((find) => find.role === r_id);
    if (checkRole.length > 0) {
      swal("", "This Role Already Assigned User", "error");
      setAnchorEl(null);
    } else {
      const res = await deleteRoleData(r_id);
      if (res.status === 200) {
        swal("", "Deleted Successfully", "success");
        fetchRole();
      }
    }
  };

  const deleteUser = async (u_id) => {
    handleClose();
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      closeOnClickOutside: false,
    }).then(async (result) => {
      if (result) { // Check if 'Yes, delete it!' button is clicked
        const res = await deleteUserData(u_id);
        if (res.status === 200) {
          swal("Deleted!", "Your imaginary file has been deleted.", "success");
          fetchMember(); // Move fetchMember inside the if block
        } else {
          swal("Error!", "Unable to delete the imaginary file.", "error");
        }
      } else {
        // Handle the case when 'Cancel' button is clicked
        // For example, display a message to the user
        console.log("Deletion canceled by the user");
      }
    });
  };
  

  const resetPassword = async () =>{
    handleClose();
    const confirmed = await swal({
      title: "Are you sure to Reset your password?",
      text: "You will get a Reset-password option in your Mail Box!",
      icon: "warning",
      buttons: ["Cancel", "Yes, Reset it!"], // Buttons setup
      closeOnClickOutside: false,
    })
   
  }

  const alldataPermission = async () => {
    const res = await allPermission();
    if (res.status === true) {
      setAllPermissionData(res.data);
    }
  };

  const addlog = ()=>{
    return createlogs('65d6d8d0d3f7a730dccf9d47','wallaaworkspaceastik','65d5ef5d516fd4342485d9d3','happening','waytorunClick')
  }

  return (
    <>
      <div className="pb-2">
        <div>
          <Navbar />
        </div>

        <>
          <div className="d-flex justify-content-between h-100 w-100 px-5 mt-4">
            <div className="font-inter" style={{ fontSize: "25px", fontWeight: "600" }}>IAM</div>
            <div>
              {allUser.length > 0 ? (
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#03565A",
                    border: "2px solid #03565A",
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                  variant="contained"
                  onClick={()=> history.push("/addyourteam")}
                >
                  <span>
                    <AddIcon />
                  </span>
                  Add Your Team
                </Button>
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              {/* temporary add log button */}
              <Button
                  style={{
                    backgroundColor: "white",
                    color: "#03565A",
                    border: "2px solid #03565A",
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                  variant="contained"
                  onClick={()=> addlog()}
                >
                  <span>
                    <AddIcon />
                  </span>
                  Addlogs
                </Button>
            </div>
          </div>
          <div className=" h-100 w-100 px-5 mt-4">
            <Box>
              <CustomTabPanel value={value} index={0}>
                <TableContainer component={Paper}>
                  <Table sx={{}} aria-label="caption table">
                    {allUser.length > 0 ? (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" className="font-sans">
                              User Name
                            </TableCell>
                            <TableCell align="center" className="font-sans">
                              Email
                            </TableCell>
                            <TableCell align="center" className="font-sans">
                              Role
                            </TableCell>
                            <TableCell align="center" className="font-sans">
                            Responsibilities
                            </TableCell>
                            <TableCell align="center" className="font-sans">
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allUser.map((det) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell className="font-sans"
                                    style={{ backgroundColor: "white" }}
                                    align="left"
                                    onClick={()=>history.push(`/edit-user/${det._id}`)}
                                  >
                                    {det.name}
                                  </TableCell>
                                  <TableCell className="font-sans" 
                                    style={{ backgroundColor: "white" }}
                                    align="center"
                                  >
                                    {" "}
                                    {det.email}
                                  </TableCell>
                                  <TableCell className="font-sans"
                                    style={{ backgroundColor: "white" }}
                                    align="center"
                                  >
                                    {det.role}
                                  </TableCell>
                                  <TableCell className="font-sans"
                                    style={{ backgroundColor: "white" }}
                                    align="center"
                                  >
                                    {det.responsibilities}
                                  </TableCell>
                                  <TableCell
                                  className="font-sans"
                                    style={{ backgroundColor: "white" }}
                                    align="center"
                                  >
                                    <MoreVertIcon
                                      onClick={(e) =>
                                        handleClicktab(e, det._id)
                                      }
                                    />
                                    <Popover
                                      id={id2}
                                      open={open2}
                                      anchorEl={anchorEl2}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                    >
                                      <Typography sx={{ p: 2 }}>
                                        <span 
                                          style={{ cursor: "pointer" }}
                                          onClick={()=>history.push(`/edit-user/${userID}`)}
                                        >
                                          Edit
                                        </span>{" "}
                                        <br />
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => deleteUser(userID)}
                                        >
                                          Delete
                                        </span>
                                        <br/>
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => resetPassword(userID)}
                                        >
                                          Reset Password
                                        </span>
                                      </Typography>
                                    </Popover>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </>
                    ) : (
                      <Card.Body>
                        <Card.Title style={{ textAlign: "center" }}>
                          <img src={DashboardLogo} alt="logo" />
                        </Card.Title>
                        <Card.Text style={{ textAlign: "center" }}>
                          There are no User created
                        </Card.Text>
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "#03565A",
                              color: "white",
                              border: "2px solid #03565A",
                              textTransform: "none",
                            }} onClick={()=> history.push("/addyourteam")}>              
                            <AddIcon />
                            Add Your Team
                          </Button>
                        </div>
                      </Card.Body>
                    )}
                  </Table>
                </TableContainer>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <TableContainer component={Paper}>
                  <Table sx={{}} aria-label="caption table">
                    {allRole.length > 0 ? (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" className="dm-sans">
                              ROLE
                            </TableCell>
                            <TableCell align="center" className="dm-sans">
                              PERMISSION
                            </TableCell>
                            <TableCell align="center" className="dm-sans">
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allRole.map((det) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell
                                    style={{ backgroundColor: "white" }}
                                    align="left"
                                  >
                                    <p>{det.roleName}</p>
                                  </TableCell>
                                  <TableCell
                                    style={{ backgroundColor: "white" }}
                                    align="center"
                                  >
                                    {det.role.map((dat) => {
                                      return (
                                        <>
                                        <p style={{textAlign:"left"}}>{dat.subject}{" :"}</p>
                                        {dat?.permissions?.map((per)=>{
                                          return(<>
                                          <p>{per}</p>
                                          </>)
                                        })}
                                        </>
                                      );
                                    })}
                                  </TableCell>
                                  <TableCell
                                    style={{ backgroundColor: "white" }}
                                    align="center"
                                  >
                                    <MoreVertIcon
                                      onClick={(e) =>
                                        handleClicktabRole(e, det._id)
                                      }
                                    />
                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                    >
                                      <Typography sx={{ p: 2 }}>
                                        <span style={{ cursor: "pointer" }}>
                                          Edit
                                        </span>{" "}
                                        <br />
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => deleteRole(roleId)}
                                        >
                                          Delete
                                        </span>
                                      </Typography>
                                    </Popover>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </>
                    ) : (
                      <Card.Body>
                        <Card.Title style={{ textAlign: "center" }}>
                          <img src={DashboardLogo} alt="logo" />
                        </Card.Title>
                        <Card.Text className="font-sans" style={{ textAlign: "center" }}>
                          There are no Role created
                        </Card.Text>
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Button className="font-sans"
                            style={{
                              backgroundColor: "#03565A",
                              color: "white",
                              border: "2px solid #03565A",
                              textTransform: "none",
                            }}
                            onClick={handleClickRole}
                          >
                            <AddIcon />
                            Add Your Role
                          </Button>
                        </div>
                      </Card.Body>
                    )}
                  </Table>
                </TableContainer>
              </CustomTabPanel>
            </Box>
          </div>
        </>
      </div>
      <Modal
        open={teamModal}
        onClose={handleClosetab}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 className="font-weight-bold">Add Team Member</h4>
          </Typography>
          <div className="password-signin">
            <label>Enter Team Member organization name</label>
            <input
              name="org_name"
              placeholder=""
              type="text"
              onChange={(e) => handleChangeUser("org_name", e.target.value)}
            />
          </div>
          <div className="password-signin">
            <label>Enter Team Member Name</label>
            <input
              name="name"
              placeholder=""
              type="text"
              onChange={(e) => handleChangeUser("name", e.target.value)}
            />
          </div>
          <div className="password-signin">
            <label>Enter Team Member Email</label>
            <input
              name="email"
              placeholder=""
              type="text"
              onChange={(e) => handleChangeUser("email", e.target.value)}
            />
          </div>
          <div className="password-signin">
            <label>Enter password</label>
            <input
              name="password"
              placeholder=""
              type="text"
              onChange={(e) => handleChangeUser("password", e.target.value)}
            />
          </div>
          <div
            className="password-signin"
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#696F79",
            }}
          >
            <strong>Select Role</strong>
            <select
              style={{
                height: "60px",
              }}
              onChange={(e) => handleChangeUser("role", e.target.value)}
            >
              <option style={{ color: "black", backgroundColor: "white" }}>
                {"Select..."}
              </option>
              {allRole.map((det) => {
                return (
                  <>
                    <option
                      style={{ color: "black", backgroundColor: "white" }}
                      value={det._id}
                    >
                      {det.roleName}
                    </option>
                  </>
                );
              })}
            </select>
          </div>

          <div className="dbbtdiv">
            <Button className="dashboardButton1" onClick={saveUser}>
              Save
            </Button>
            <Button className="dashboardButton2" onClick={handleClosetab}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={roleModal}
        onClose={handleClosetab}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 className="font-weight-bold font-sans">Add Team Role</h4>
          </Typography>
          <div className="password-signin">
            <label>Enter Role</label>
            <input
              placeholder=""
              type="text"
              onChange={(e) => handleChangeRole(e.target.value)}
            />
          </div>
          <div className="password-signin">
            <label>Enter Permissions</label>
            {/* {allPermissiondata && allPermissiondata["IR"].map((det) => {
              return (
                <>
                  <div className="d-flex ">
                    <Checkbox
                      onChange={(event) =>
                        handleChangePermission(event, det.Permission)
                      }
                    />
                    <span className="d-flex justify-content-between w-100 mt-2">
                      <h6>{det}</h6>
                    </span>
                  </div>
                </>
              );
            })} */}
            {allPermissionName.map((det) => {
              return (
                <>
                  <div className="d-flex ">
                    <h6 style={{ lineHeight: "30px" }}>{det.type}</h6>&nbsp;&nbsp;&nbsp;&nbsp;
                    {allPermissiondata[det.type]?.map((ten) => {
                      return (
                        <>
                          <label>
                            <Checkbox
                              onChange={(event) =>
                                handleChangePermission(event, ten.Permission,det.type)
                              }
                            />{" "}
                            {ten?.Permission}
                          </label>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>

          <div className="dbbtdiv">
            <Button className="dashboardButton1" onClick={handleSaveRole}>
              Save
            </Button>
            <Button className="dashboardButton2" onClick={handleClosetab}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default AddTeam;
