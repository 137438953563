import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import FormControl from "@mui/material/FormControl";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";


import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./AddYourTeam.css";

import { useHistory } from "react-router";
import {
  addCompanyToPermission,
  adminAddUserPermission,
  createChildUser,
  updateChildUser,
  deleteCompanyFromPermission,
  getMeber,
  updateUserPermission,
  getActivePlan,
  getCompany
} from "../../ApiServices/apiHandler";

import swal from "sweetalert";

const label = { inputProps: { "aria-label": "Checkbox demo" } };


const Container = ({ userData, setUserData }) => {
  const history = useHistory();
  const [password ,setPassword] = useState('');
  const [data, setData] = useState({
    name: "",
    email: "",
    role: "",
    temp_password:"",
    responsibilities: "preparer",
    unrestrictedaccess: false
  });

  const [singleValue, setSingleValue] = React.useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [company, setCompany] = useState([]);
  const [permissionData, setPermissionData] = useState([]);
  const [ permittedCompany, setPermittedCompany ] = useState([]);
  const [ companyRoleData, setCompanyRoleData ] = useState([]);
  const [ restrictedData, setRestrictedData ] = useState([]);
  const [unrestrictedData_, setUnrestrictedData_] = useState([]);
  const [userId ,setUserId] = useState(null);
  const [users,setUsers] = useState(0)
  const [childUserCount, setChildUserCount] = useState(0);
  const [created,setCreated] = useState(1);
  const [activeplan,setActiveplan] = useState('');
  const [total,setTotal] = useState(false);
  

  const generatePassword = (length = 9) =>{
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@";
    let generatedPassword = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      generatedPassword += charset[randomIndex];
    }
    setPassword(generatedPassword);
    return generatedPassword;

  }

  // const tempopassword = () =>{
  //     const newpassword = generatePassword();
  //     setPassword(newpassword);
  // }



  useEffect(() => {
    if (userData) {
      const { isSuccess, user, userPermission, unrestrictedAccess } = userData;
      if (isSuccess) {
        setData((prev) => {
          return {
            ...prev,
            name: user.name,
            email: user.email,
            responsibilities: user.responsibilities,
            role: user.role,
            unrestrictedaccess:user.unrestrictedaccess
          }
        });
        if(company){
          const singleType = userPermission.filter(data => data.type === 'single');
          const bundleType = userPermission.filter(data => data.type === 'bundle');
          const singleTypeCompany = company.filter(item => singleType.map(data => data.company).includes(item._id)).map((item)=>({
            selectedType: 'single',
            company: item
          }));
          const bundleTypeCompany = company.filter(item => bundleType.map(data => data.company).includes(item._id));
          if(singleTypeCompany.length){
            setPermissionData([...singleTypeCompany])
          }
          if(bundleTypeCompany.length){
            setPermissionData((prevData)=>([
              ...prevData,
              {selectedType: 'bundle', company: bundleTypeCompany}
            ]))
          }
        }if(unrestrictedAccess){
          setUnrestrictedData_([...unrestrictedAccess])
        }
      }
    }
  }, [userData, company])


  useEffect(() => {
    (async () => {
      if (userData) {
        const { user, userPermission } = userData;
        const newRecord = companyRoleData.filter((item)=> !userPermission.map(item=>item.company).includes(item.company)).filter((item) => item.company !== undefined);
        try {
          if(newRecord.length){
            await addCompanyToPermission(user._id, {'companyData': newRecord});
            if(SelectType === 'single'){
              setUserData((prevData)=>({
                ...prevData,
                userPermission: [...prevData.userPermission, newRecord[0]]
              }))
            }else{
              setUserData((prevData)=>({
                ...prevData,
                userPermission: [...prevData.userPermission, newRecord].flat()
              }))
            }
            
            swal("Success", "New Company permission details added", "success");     
          }
        } catch (error) {
          setPermissionData([...permissionData.filter(item => item.company !== newRecord[0].company)])
          swal("Error", error.message, "error");
        }
      }
    })()
  }, [companyRoleData])


  useEffect(()=>{
    const singleCompany = permissionData.filter(ele => ele.selectedType === 'single').map(item => item.company._id);
    const bundleCompany = permissionData.filter(ele => ele.selectedType === 'bundle')[0]?.company.map(item => item._id);
    const permittedCompanies = singleCompany.concat(bundleCompany);
    setPermittedCompany([...permittedCompanies]);
  }, [permissionData])
  

  useEffect(() => {
   activePlandata();
  }, []);

  useEffect(()=>{
    countallmember();
  },[created])

  const countallmember = async() => {
     try {
      const res = await getMeber();
       if(res.status == 200){
        setChildUserCount(res.data.fetchMember.length);
       }
      
     } catch (error) {
         console.log(error)
     }
  }

  const newActivePlanhandle = async()=>{

     try{
           const res = await getActivePlan(userId);
           if(res.status === 200){
             if(res.data.plan_id !== activeplan){
                setUsers(res.data.users);
                setTotal(true)
             }
             else{
              swal("You have to renew your plan")
             }
           }
     }
     catch {
        swal("Limit is over")
     }
  }

 
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeSingle = (event) => {
    const singleCompany = company.find( 
      (elem) => elem._id === event.target.value
    );
    setSingleValue(singleCompany._id);
  };

  // const accessCheck = () => {
  //   setData(prevData => ({
  //     ...prevData,
  //     unrestrictedaccess: true
  //   }));
  //   console.log("===>",data.unrestrictedaccess);
  // };
  
  const handleChangeMultiple = (event) => {
    const selectedIds = event.target.value;
    setSelectedValues(selectedIds);
  };

  const activePlandata = async() => {
    try {
          const res = await getActivePlan()
          
          setUsers(res.data.categorizedBenefits.noOfUsers[0]);
          
    } catch (error) {
       console.log(error,'errorr')
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };



  const createData = async() => {


    if (childUserCount >= users) {
      swal("Error", "Maximum number of team members reached!", "error");

      // setTotal(false)
      //  await newActivePlanhandle();
       return;
    }

    const generatedPassword = await generatePassword();

    setData(prevData => ({ ...prevData, temp_password: generatedPassword }));

    createChildUser({ ...data, temp_password: generatedPassword })
      .then((response) => {
        const user = response.data.data._id;
        swal("Success", "Team Member Added Successfully.", "success").then(() => {
          setCreated((prev) => prev + 1);
          adminAddUserPermission({ user: user, companyData: companyRoleData,unrestrictedAcessData:restrictedData })
            .then(() => {
              swal(
                "Success",
                "Team Member Permission added Successfully.",
                "success"
              );
            })
            .catch((err) => {
              swal(
                "Error",
                err.response?.data?.error ||
                "Team Member Permission addition Failed!",
                "error"
              );
            });
        })

      })
      .catch((error) => {
        swal(
          "Error",
          error.response?.data?.error || "Team Member Added Failed!",
          "error"
        );
      });
  
  };

  const updateUserData = async () => {
  
    try {
      const { user,userPermission } = userData;
      const updatedata = {
        name:data?.name,
        email:data?.email,
        role: data?.role,
        responsibilities:data?.responsibilities,
        unrestrictedaccess:data?.unrestrictedaccess
      }

      const updatePayload = {
        userPermissionData: companyRoleData.map((role) => ({
          company_id: role.company,
          components: role.components
        })),
        unrestrictedAccessData: restrictedData
      }

      updateChildUser(user._id, updatedata).then(response => {

        swal("Success", "User data updated!", 'success');
        updateUserPermission(user._id, updatePayload).then(res => {
          swal("Success", "User permission data updated!", 'success');
        }).catch(err => {
          console.log("uer permission error===========", err)
        })

      }).catch(error => {
        console.log("error===========", error)
      })

    } catch (error) {
      swal("Error", error.message, 'error');
    }
  }

  const [value, setValue] = useState("approver");

  const handleChangeradio = (event) => {
    setValue(event.target.value);
  };

  const [SelectType, setSelectType] = useState("single");
  const handleChangeradion = (e) => {
    const data = e.target.value;
    setSelectType(data);
  };

  const getCompanyData = async () => {
    try {
      const res = await getCompany();
      if (res.status === 200) {
        setCompany(res.data.getCompany);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  useEffect(() => {
    if (!company.length) {
      getCompanyData();
    }
  }, []);

  const handleSubmit = () => {
    try {
      if (SelectType === "single") {
        const data = {selectedType: SelectType, company: company.find((elem) => elem._id === singleValue)};
        setPermissionData([...permissionData, data]);
      } else {
        const selectiveValue = selectedValues.map((elem) => elem);
        const selectiveData = {selectedType: SelectType, company: company.filter((elem) =>
          selectiveValue.includes(elem._id)
        )};
        const perm_data = [...permissionData, selectiveData];
        setPermissionData(perm_data);
      }

      setSingleValue("");
      setSelectedValues([]);
    } catch (error) {
      console.log(error);
    }
    closeModal();
  };

  const handleDelete = async (id, type) => {
    if(type === 'single'){
      const deleteCompany = permissionData.filter((elem) => elem.selectedType === type && elem.company._id !==id).concat(permissionData.filter((elem) => elem.selectedType === 'bundle'));;
      setPermissionData(deleteCompany);
    }else{
      const filterBundleData = permissionData.filter(data => data.selectedType === 'bundle')[0].company.filter(item => item._id !== id).concat(permissionData.filter((elem) => elem.selectedType === 'single'));
      setPermissionData(filterBundleData);
    }
    const deletedRoleCompany = companyRoleData.filter((ele) => ele.company !== id);
    setCompanyRoleData(deletedRoleCompany);
    setSingleValue("");
    if (userData) {
      try {
        const { user } = userData;
        await deleteCompanyFromPermission(user._id, id);
        swal("Success", "permission revoked", "success");
      } catch (error) {
        swal("Error", error.message, "error");
      }
    }
  };


  const findCompanyName = (comp_ids) => {
    
    const company_detail = company.filter((comp)=>comp_ids.includes(comp._id)).map(item => item.company_name);
    
    if(company_detail.length){
      return company_detail
    }
    return comp_ids;
  }

  return (
    <>
      <div>
        <div className="d-flex justify-content-between mt-2 align-items-center pl-4 pr-4 mt-3 ">
          {/* <div className="fs-4 font-weight-bold">Back To Home</div> */}

          <div>
            {/* <Button
              style={{
                backgroundColor: "#03565A",
              }}
              className="text-white font-weight-500 text-transform-none  "
              variant="contained"
              onClick={()=>{history.push('/viewlog')}}
            >
              VIEW LOGS
            </Button> */}

            {/* <Button
              style={{
                backgroundColor: " #03565A",
                width: "110px",
              }}
              className="text-white ml-2 font-weight-500 text-transform-none  "
              variant="contained"
              onClick={userData ? updateUserData : createData}
            >
              {userData ? 'UPDATE' : 'CREATE'}
            </Button> */}
          </div>
        </div>

        <div>
          <div
            className="d-flex  align-items-center  pl-4 mt-2"
            style={{ gap: "6rem" }}
          >
            <span
              className="fs-4 font-weight-bold"
              style={{ minWidth: "12rem" }}
            >
              My Finalyst mail id:
            </span>

            <TextField
              fullWidth
              className="wp-add-comp-modal-input w-50"
              name="email"
              value={data.email}
              onChange={handleInputChange}
            />
          </div>

          <div
            className="d-flex  align-items-center  pl-4 mt-1"
            style={{ gap: "6rem" }}
          >
            <span
              className="fs-4 font-weight-bold"
              style={{ minWidth: "12rem" }}
            >
              Name of the user:
            </span>
            <TextField
              fullWidth
              className="wp-add-comp-modal-input w-50"
              name="name"
              value={data.name}
              onChange={handleInputChange}
            />
          </div>
         {/* {userData?.user?.password?null:(
          <div
            className="d-flex  align-items-center  pl-4 mt-1"
            style={{ gap: "6rem" }}
          >
            <span
              className="fs-4 font-weight-bold"
              style={{ minWidth: "12rem" }}
            >
              Password:
            </span>
            <TextField
              type="password"
              fullWidth
              className="wp-add-comp-modal-input w-50"
              name="password"
              value={data.password}
              onChange={handleInputChange}
            />
          </div>
         )} */}
          

          <div
            className="d-flex  align-items-center  pl-4 mt-1"
            style={{ gap: "6rem" }}
          >
            <span
              className="fs-4 font-weight-bold"
              style={{ minWidth: "12rem" }}
            >
              Role:
            </span>

            <TextField
              fullWidth
              className="wp-add-comp-modal-input w-50"
              name="role"
              value={data.role} 
              onChange={handleInputChange}     
            />
          </div>
          <div
            className="d-flex  align-items-center  pl-4 mt-4"
          >
          <span
            className="fs-4 font-weight-bold"
            style={{ minWidth: "12rem" }}
          >
          Unrestricted Access:
          </span>
          <Checkbox 
            name="unrestrictedaccess"
            onChange={(e)=> {
              setData((prev) => ({ ...prev, [e.target.name]: !data.unrestrictedaccess }));
            }}
            checked={data.unrestrictedaccess} 
          />
         </div>
         {!data.unrestrictedaccess?
          <>
          <div className="d-flex   pl-4 mt-3" style={{ gap: "6.5rem" }}>
            <span
              className="fs-4 font-weight-bold"
              style={{ minWidth: "12rem" }}
            >
              Responsibilities:
            </span>

            <FormControl>
              <RadioGroup
                row
                name="responsibilities"
                value={data.responsibilities}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="preparer"
                  checked={data.responsibilities === "preparer"}
                  control={<Radio />}
                />
                <span>Preparer</span>
                <FormControlLabel
                  value="approver"
                  checked={data.responsibilities === "approver"}
                  control={<Radio />}
                  sx={{ marginLeft: "1rem" }}
                />
                <span>Approver</span>
              </RadioGroup>
            </FormControl>
          </div>

          <div
            className="d-flex  align-items-center  pl-4 mt-4"
            style={{ gap: "4rem" }}
          >
            <span
              className="fs-4 font-weight-bold"
              style={{ minWidth: "12rem" }}
            >
              Access Granted
            </span>
          </div>
          </>
        :<></>}
        </div>
        {!data.unrestrictedaccess?
        <>
        <div>
          <AccessGrantedTable
          setRestrictedData={setRestrictedData}
          unrestrictedAccess = {unrestrictedData_}
          />
        </div>
        <div
          className="d-flex align-items-center pl-4 pt-2 mt-4"
          style={{ gap: "100px" }}
        >
          <div className="fs-4">
            <Button
              onClick={openModal}
              className="custom-common-theme-bg-color text-white"
            >
              {" "}
              Add Companies
            </Button>
            <div>
              <Modal open={isModalOpen} onClose={closeModal}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    height: 250,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 3,
                    d: "flex",
                    flexDirection: "column",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <form>
                    <div className="d-flex flex-row-reverse" onClick={closeModal} style={{cursor:"pointer"}}>Close</div>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          name="row-radio-buttons-group"
                          value={SelectType}
                          onChange={handleChangeradion}
                        >
                          <FormControlLabel
                            value="single"
                            control={<Radio />}
                            sx={{ marginLeft: "5px", marginRight: "5px" }}
                          />{" "}
                          Single
                          <FormControlLabel
                            value="bundle"
                            control={<Radio />}
                            sx={{ marginLeft: "1rem", marginRight: "5px" }}
                          />
                          Bundle
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {SelectType === "single" ? (
                      <div>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            value={singleValue}
                            onChange={handleChangeSingle}
                          >
                            {company.filter(item => !permittedCompany.includes(item._id)).map((elem) => {
                              return (
                                <MenuItem value={elem._id}>
                                  {elem.company_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    ) : (
                      <div>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            value={selectedValues}
                            multiple
                            onChange={handleChangeMultiple}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select</em>;
                              }
                              return findCompanyName(selected).join(", ");
                            }}
                          >
                            <MenuItem disabled>
                              <em>Select</em>
                            </MenuItem>
                            {company.filter(ele => !permittedCompany.includes(ele._id)).map((elem) => {
                              return (
                                <MenuItem value={elem._id}>
                                  {elem.company_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </form>
                  <Button
                    variant="contained"
                    className="border-0 custom-common-theme-bg-color text-white mt-5"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
        </>
        :<></>}
      </div>
      {!data.unrestrictedaccess?
      <div className="pl-4 pt-1 px-2">
        {permissionData.map((elem, key) => (
          <>
            { elem.selectedType === 'single'
              ? 
              <div key={key}>
                  <div className="d-flex justify-content-between mt-2">
                  <p>Permission for Company: {elem.company.company_name}</p>
                  <Button
                    className="border-0 bg-danger text-white mr-4 h-25"
                    onClick={() => handleDelete(elem.company._id, 'single')}
                    style={{width:"180px"}}
                  >
                    {"Delete"}
                  </Button>
                  </div>
                    <RoleBasedTable 
                      company={elem.company} 
                      userPermission={userData && userData?.userPermission.find(item => item.company === elem.company._id)} 
                      companyRoleData={companyRoleData} 
                      setCompanyRoleData={setCompanyRoleData} 
                      selectedType={elem.selectedType}
                      key={key}
                    />
              </div>
              : elem.selectedType === 'bundle'
              ?
              <div>
                  <ul>
                    {elem.company.map((comp, key)=>(
                      <li key={key}>
                        <div className="d-flex justify-content-between mt-2">
                            <p>Permission for Company: {comp.company_name}</p>
                            <Button
                              className="border-0 bg-danger text-white mr-4 h-25"
                              onClick={() => handleDelete(comp._id, 'bundle')}
                              style={{width:"180px"}}
                            >
                              {"Delete"}
                            </Button>
                        </div>
                    </li>
                    ))}
                  </ul>
                  <RoleBasedTable 
                  company={elem.company} 
                  userPermission={userData && userData?.userPermission.find(item => item.company === elem.company[0]._id)} 
                  companyRoleData={companyRoleData} 
                  setCompanyRoleData={setCompanyRoleData} 
                  selectedType={elem.selectedType}
                  key={key}
                  />
              </div>
              : ''
            }
          </>
        ))}
      </div>:<></>}

      <div className="m-3 d-flex justify-content-between">
      <Button className="custom-common-theme-bg-color text-white" onClick={()=>{history.push('/IAM')}} style={{width:"180px",marginLeft:"300px"}}>
        Back
      </Button>
      <Button className="custom-common-theme-bg-color text-white" onClick={userData ? updateUserData : createData}style={{width:"180px",marginRight:"270px"}}>
      {userData ? 'UPDATE' : 'CREATE'}
      </Button>
    </div>
    </>
  );
};

export default Container;


export const RoleBasedTable = ({company, companyRoleData, setCompanyRoleData, userPermission, selectedType}) => {

  const rawCompanyData = [
    {
      "title":"Workspace",
      "function":"creationOfWorkspace",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
    {
      "title":"Investment Register",
      "function": "investmentRegister",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
    {
      "title":"Far",
      "function": "far",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
    {
      "title":"Leases",
      "function": "leases",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
    {
      "title":"Loan Register",
      "function": "loanRegister",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
    {
      "title":"Payable",
      "function": "payable",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
    {
      "title":"Receivable",
      "function": "receivable",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
    {
      "title":"Sales",
      "function": "sales",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
    {
      "title":"Purchases",
      "function": "purchases",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
  ]

  const [companyData, setCompanyData] = useState(userPermission?.components ?? rawCompanyData);

  useEffect(()=>{
    if(selectedType === 'single'){
      singleTypeCompany()
    }else{
      bundleTypeCompany()
    }
  }, [companyData])


  const singleTypeCompany = () => {
    const eleIndex = companyRoleData.findIndex(item => item.company === company._id)
    if (eleIndex !== -1) {
      setCompanyRoleData((prevData) => [
        ...prevData.slice(0, eleIndex),
        {
          company: company._id,
          type: selectedType,
          components: companyData.map((item) => {
            return {
              ...item
            };
          })
        },
        ...prevData.slice(eleIndex + 1)
      ])
    } else {
      setCompanyRoleData((comp) => {
        return [
          ...comp,
          {
            company: company._id,
            type: selectedType,
            components: companyData.map((item) => {
              return {
                ...item
              };
            })
          }
        ]
      })
    }
  }

  const bundleTypeCompany = () => {
    const companyIndexes = company.map((comp) => ({company_id: comp._id, eleIndex: companyRoleData.findIndex(item => item.company === comp._id)}));
    
    companyIndexes.forEach((cIndex)=>{
      if(cIndex.eleIndex !== -1){
        setCompanyRoleData((prevData)=>[
          ...prevData.slice(0, cIndex.eleIndex),
          {
            company: cIndex.company_id,
            type: selectedType,
            components: companyData.map((item) => {
              return {
                ...item
              };
            }) 
          },
          ...prevData.slice(cIndex.eleIndex + 1)
        ])
      }else{
        setCompanyRoleData((comp)=>{
          return [
            ...comp,
            {
              company: cIndex.company_id,
              type: selectedType,
              components: companyData.map((item) => {
                  return {
                    ...item
                  };
                })
            }
          ]
        })
      }
    })
  }

  const HandleChange = (e, index, company) => {
    const companyRawData = [...companyData];
    const { name } = e.target;
    companyRawData[index]['permission'][name] = !companyRawData[index]['permission'][name];
    if (name === "all_access_granted") {
      companyRawData[index]['permission']["create"] = !companyRawData[index]['permission']["create"];
      companyRawData[index]['permission']["delete"] = !companyRawData[index]['permission']["delete"];
      companyRawData[index]['permission']["share"] = !companyRawData[index]['permission']["share"];
    } else {
      companyRawData[index]['permission']["all_access_granted"] = false;
    }

    if (
      companyRawData[index]['permission']["create"] &&
      companyRawData[index]['permission']["share"] &&
      companyRawData[index]['permission']["delete"]
    ) {
      companyRawData[index]['permission']["all_access_granted"] = true;
    }

    setCompanyData(companyRawData);
  };


  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <table className="pl-4 pr-4 mt-4">
        <thead>
          <tr>
            <th className="w-25 border-0">Data set creation</th>
            <th className="w-auto border-0">Create</th>
            <th className="w-auto border-0">Delete</th>
            <th className="w-auto border-0">Share</th>
            <th className="w-auto border-0">
              All Access 
            </th>
          </tr>
        </thead>

        <tbody>
          {companyData &&
            companyData.length > 0 &&
            companyData.map((company, i) => (
              <tr>
                <td className="border-0 text-right">
                  {company.title}
                </td>
                <td className="border-0">
                  <Checkbox
                    {...label}
                    name="create"
                    checked={company.permission.create}
                    onChange={(e) => HandleChange(e, i, company)}
                  />
                </td>
                <td className="border-0">
                  <Checkbox
                    {...label}
                    name="delete"
                    checked={company.permission.delete}
                    onChange={(e) => HandleChange(e, i)}
                  />
                </td>
                <td className="border-0">
                  <Checkbox
                    {...label}
                    name="share"
                    checked={company.permission.share}
                    onChange={(e) => HandleChange(e, i)}
                  />
                </td>
                <td className="border-0">
                  <Checkbox
                    {...label}
                    name="all_access_granted"
                    checked={company.permission.all_access_granted}
                    onChange={(e) => HandleChange(e, i)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
};

export const AccessGrantedTable = ({setRestrictedData, unrestrictedAccess}) => {
  const unrestrictedAccess_ = [
    {
      "title":"Creation of company",
      "function": "creationOfCompany",
      "permission": {
        "create": false,
        "delete": false,
        "share": false,
        "all_access_granted": false,
      }
    },
   
  ] 

  const [unrestrictedAccessData, setUnrestrictedAccessData] = useState(unrestrictedAccess_);

  useEffect(()=> {
    if(unrestrictedAccess.length > 0){
      setUnrestrictedAccessData([...unrestrictedAccess])
    }
  }, [unrestrictedAccess])

  useEffect(()=> {
    setRestrictedData([...unrestrictedAccessData]);
  }, [unrestrictedAccessData])

  const HandleChange = (e, index) => {
    const unrestrictedData = [...unrestrictedAccessData];
    const { name } = e.target;
    unrestrictedData[index]['permission'][name] = !unrestrictedData[index]['permission'][name];
    if (name === "all_access_granted") {
      unrestrictedData[index]['permission']["create"] = !unrestrictedData[index]['permission']["create"];
      unrestrictedData[index]['permission']["delete"] = !unrestrictedData[index]['permission']["delete"];
      unrestrictedData[index]['permission']["share"] = !unrestrictedData[index]['permission']["share"];
    } else {
      unrestrictedData[index]['permission']["all_access_granted"] = false;
    }

    if (
      unrestrictedData[index]['permission']["create"] &&
      unrestrictedData[index]['permission']["share"] &&
      unrestrictedData[index]['permission']["delete"]
    ) {
      unrestrictedData[index]['permission']["all_access_granted"] = true;
    }

    setUnrestrictedAccessData(unrestrictedData);
  };
  return (
    <>
    
    <div style={{ width: "100%", overflow: "auto" }}>
      <table className="pl-4 pr-4 mt-4">
        <thead>
          <tr>
            <th className="w-25 border-0"></th>
            <th className="w-auto border-0">Create</th>
            <th className="w-auto border-0">Delete</th>
            <th className="w-auto border-0">Share</th>
            <th className="w-auto border-0">
              All Access 
            </th>
          </tr>
        </thead>

        <tbody>
          {unrestrictedAccessData &&
            unrestrictedAccessData.length > 0 &&
            unrestrictedAccessData.map((data, i) => (
              <tr>
                <td className="border-0 text-right">
                  {data.title}
                </td>
                <td className="border-0">
                  <Checkbox
                   {...label}
                   name="create"
                   checked={data.permission.create}
                   onChange={(e) => HandleChange(e, i)}
                  />
                </td>
                <td className="border-0">
                  <Checkbox
                  {...label}
                  name="delete"
                  checked={data.permission.delete}
                  onChange={(e) => HandleChange(e, i)}
                  />
                </td>
                <td className="border-0">
                  <Checkbox
                  {...label}
                  name="share"
                  checked={data.permission.share}
                  onChange={(e) => HandleChange(e, i)}
                  />
                </td>
                <td className="border-0">
                  <Checkbox
                  {...label}
                  name="all_access_granted"
                  checked={data.permission.all_access_granted}
                  onChange={(e) => HandleChange(e, i)}/>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
    </>
  )
};