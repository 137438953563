import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import tick from '../../assets/confirmTb.svg'
import { Typography } from '@mui/material';
import uploadFile from '../../assets/fileFormat.svg'
import CloseIcon from '@mui/icons-material/Close';

export default function SingleFIleFormat({singleFormat,setsingleFormat}) {
    const HandleClose = () => {
        setsingleFormat(false);
    }
    return (
        <React.Fragment>
            <Dialog
                maxWidth={'lg'}
                open={singleFormat}
                onClose={()=>setsingleFormat(false)}
            >
                <DialogTitle className='d-flex justify-content-between'>
                    <Typography fontSize={"1.7rem"}> Trial Balance Format </Typography>
                    <CloseIcon onClick={HandleClose} style={{cursor:"pointer"}} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography ><img alt='tick' src={tick}/> Please Ensure the header columns are in the first row.</Typography>
                        <Typography ><img alt='tick' src={tick}/> Remove Sub-total values in between or at the end.</Typography>
                        <Typography ><img alt='tick' src={tick}/> Mandatory columns must include Ledger Codes,Ledger Names & Amount.</Typography>
                    </DialogContentText>
                    <img alt='upload file' className='mt-3'  src={uploadFile} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={HandleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}