import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { useHistory } from "react-router-dom";



const SupportNav = ({handleBack}) => {
    
    // const history = useHistory(); 
    // const handleNavigate = () => {
    //   history.push("/addmin-2");
    // };
    
    
   
  return (
    <div>
                <div className='custom-common-theme-bg-color' style={{ height: "70px" }}>
                    <div className='d-flex  px-3 py-2'>
                        <div className='d-flex align-items-center p-3' style={{cursor:"pointer"}} onClick={()=>handleBack()}>
                            <ArrowBackIcon  className='text-white'/>
                            <p className='mt-0 mb-0 font-size-16 custom-font-600 text-white ml-1' >Back</p>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default SupportNav