import { GetLoan } from "../../../WorkSpace/Components/ApiServices/apiHandler";

export const fetchAllLoans = () => {
    return dispatch => {
        dispatch({type:"LOANS_ACTION_START"})
        GetLoan().then((result)=>{
            dispatch({
                type: "LOANS_FETCH_DATA",
                payload: result.data.loans
            }) 
        }).catch((error)=>{
            dispatch({
                type: "LOANS_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}