import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

export default function AdjustmentLogsEditModal({ setOpen, open, element, HandleSubmit }) {
    const [value, setValue] = useState({
        index:element?.index,
        workingId:element?.workingId,
        workingId1:element?.workingId1,
        description:element?.description,
        header:element?.header,
        header1:element?.header1,
        subHeader:element?.subHeader,
        subHeader1:element?.subHeader1,
        amount:element?.amount,
        amount1:element?.amount1
    })
    const shadow = {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        height: "2.5rem", border: "none", outline: "none", borderRadius:"5px"
    }
    const btn = {
        border: "none",
        outline: "none",
        backgroundColor: "white",
        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    }

    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Add new option name</DialogTitle>
                <DialogContent style={{ minWidth: "35rem", minHeight: "13rem" }}>
                    <div>
                        <p className='my-1'>Description: <span> {value.description} </span></p>
                        <div className='d-flex justify-content-between'>
                            <div className='w-50' >
                                <p className='my-2 adjustment-p'>{value.header}</p>
                                <p className='my-2 adjustment-p'>{value.subHeader}</p>
                                <input type='number' className='w-100 p-1 mt-1' value={value.amount} onChange={(e) => setValue({...value, amount:Number(e.target.value)})} style={shadow} />
                            </div>
                            <div className='ml-3 w-50' >
                                <p className='my-2 adjustment-p'>{value.header1}</p>
                                <p className='my-2 adjustment-p'>{value.subHeader1}</p>
                                <input type='number' className='w-100 p-1 mt-1' value={value.amount1} onChange={(e) => setValue({...value, amount1:Number(e.target.value)})} style={shadow} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button style={btn} className='px-4 py-2 mb-2 c-modal-create' onClick={() => setOpen(false)}>Cancel</button>
                    <button style={btn} className='px-4 py-2  mr-3 mb-2 c-modal-create' onClick={()=>HandleSubmit(value)}>Save</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}