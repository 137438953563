export const ConvertValueChange = (value) => {
  let val = value
  let val2 = parseInt(val) + 0.5;
  let val3 = 0;

  if (val2 <= val) {
    return val3 = parseInt(val) + 1;
  } else {
    return val3 = parseInt(val);
  }
}

export const ConvertLocaleString = (value) => {
  return value ? value.toLocaleString("en-IN") : 0;
}

export const ConvertDecimal = (num1, decimal) => {
  let decimal_val = parseFloat(num1).toFixed(decimal)
  return decimal_val ? decimal_val : 0
}

export const ConvertSign = (n, sign) => {
  let value = sign === "-" ? -1 * n : 1 * n;
  return value ? value : 0
}

export const CheckSign = (n) => {
  if (n > 0) {
    return true
  } else if (n < 0) {
      return false
  } else {
      return n
  }
}


export const formatIndianCurrency = (number, decimalNum = null, roundup = false) => {
  if (typeof number !== "number") return number

  let num = number;

  if(roundup) { num = Math.round(number) }

  const n = num < 0 ? (num * -1) : num;
  if (decimalNum !== null) {
    const formattedNumber = n.toLocaleString('en-IN', {
      minimumFractionDigits: decimalNum,
      maximumFractionDigits: decimalNum
    });
    return num < 0 ? `(${formattedNumber})` : formattedNumber;
  } else {
    const formattedNumber = n.toLocaleString('en-IN');
    return num < 0 ? `(${formattedNumber})` : formattedNumber;
  }
};

export const convertStrToNumber = (str) => {
  try {
    let isNegative = false;
    if (str.startsWith('(') && str.endsWith(')')) {
      isNegative = true;
      str = str.slice(1, -1); // remove brackets
    }else if (str.startsWith('(') && !str.endsWith(')')){
      isNegative = false;
      str = str.slice(1);
    }else if (!str.startsWith('(') && str.endsWith(')')){
      isNegative = false;
      str = str.slice(0, -1);
    }else if (str.startsWith('-')){
      isNegative = true;
    }

    str = str.replace(/,/g, ''); // remove commas
    str = str.replace(/[^0-9]/g, ''); // remove all non-digit characters
    
    let num = Number(str);
    
    if (isNaN(num)) {
      throw new Error('Invalid number');
    }
    
    return isNegative ? -num : num;
  } catch (error) {
    console.log("error:", error)
  }
}

// export {ConvertValueChange, ConvertLocaleString, ConvertDecimal, ConvertSign, CheckSign}
