import { Navbar } from "../../../../src/Components/Navbar";
// import Sidbarv2 from "../../Workspace-v2/Sidbarv2";
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Logo1 from "../../Workspace-v2/Lease/leaselogo/logo1.svg";
// import { Chart } from "react-google-charts";
import LeaseSidebar from "./LeaseSidebar";
import "../../../WorkSpace/font.css";
import "../Lease/leasesidebar.css";
// 1st piechart
const dataOld = [
  ["Name", "Popularity"],
  ["Cesar", 250],
  ["Rachel", 4200],
  ["Patrick", 2900],
  ["Eric", 8200],
];
const dataNew = [
  ["Name", "Popularity"],
  ["Opening", 370],
  ["Additions", 600],
  ["Interest", 700],
  ["Lease payment", 1500],
];
export const diffdata = {
  old: dataOld,
  new: dataNew,
};
// 2nd piechart
export const data2 = [
  ["Task", "Hours per Day"],
  ["Table", 4],
  ["Airplane", 4],
  ["Furniture", 4],
  // ["Watch TV", 2],
  // ["Sleep", 7],
  // CSS-style declaration
];

export const options2 = {
  title: "₹19,718.19",
  pieHole: 0.4,
  is3D: false,

  colors: ["#C5FFDA", "#FFE3BF", "#C2DEFB"],
};
const LeaseProject = () => {
  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>

        <div className="d-flex">
          <div>
            <LeaseSidebar />
          </div>
          <div className="mt-3">
            <p className="dm-sans leasehead">Assets overview</p>
            <div className="row ml-2 mt-2">
              <div className="col-md-3 ml-2">
                <Card sx={{ minWidth: 295 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <div>
                        <div className="d-flex">
                          <div>
                            <img
                              className="img-fluid for-light"
                              src={Logo1}
                              alt=""
                            />
                          </div>
                          <div className="leaseh5div">
                            <h5 className="fs-inter leaseh5">3</h5>

                            <p className="dm-sans leasep">
                              Total number of assets
                            </p>
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-3 ml-5">
                <Card sx={{ minWidth: 295, maxHeight: 128 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <div>
                        <div className="d-flex">
                          <div>
                            <img
                              className="img-fluid for-light"
                              src={Logo1}
                              alt=""
                            />
                          </div>
                          <div className="leaseh5div">
                            <h5 className="fs-inter leaseh5">1</h5>

                            <p className="dm-sans leasep">
                              Assets leased during the year
                            </p>
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-3 ml-5">
                <Card sx={{ minWidth: 295, maxHeight: 128 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <div>
                        <div className="d-flex">
                          <div>
                            <img
                              className="img-fluid for-light"
                              src={Logo1}
                              alt=""
                            />
                          </div>
                          <div className="leaseh5div">
                            <h5 className="fs-inter leaseh5">0</h5>

                            <p className="dm-sans leasep">
                              Leases ending during the next year
                            </p>
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="row ml-3 mt-5">
              <div className="col-md-8">
                <p className="dm-sans leasepie1"> Movement during the period</p>

                {/* <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  diffdata={diffdata}
                /> */}
                <span>
                  <select>
                    <option>dd</option>
                  </select>
                </span>
              </div>
              <div className="col-md-4 ">
                <p className="dm-sans leasepie1">
                  ROU asset composition as of Jul 10, ‘23
                </p>

                {/* <Chart
                  chartType="PieChart"
                  width="100%"
                  height="400px"
                  data={data2}
                  options={options2}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LeaseProject;
