import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";
import CashFlowTable from './CashFlowTable'
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import { cashflowReferenceUpdate, getCashflow } from '../../services/api/StandAlone/cashFlow';
import Loading from '../Common/Loader/Loading';
import NbfcCashflowFaceValue from './NbfcCashflowFaceValue';
import PrimaryButton from "../../Components/Common/PrimaryButton";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCashFlowData } from '../../redux/actions/PopulateFs/actionCashflow';
import { getChecklistData } from '../../services/api/StandAlone/disclosureChecklist';

const CashFlow = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const project_id = localStorage.getItem('project_id');
    const tb_id = localStorage.getItem('tb_id');
    const { isSuccess, loading, response, error_message } = useSelector((state)=>state.PFSCashFlow);
    const reduxChecklistData = useSelector((initialState) => initialState.DisclosureChecklistReducer.disclosureChecklistData);


    const cashFlow = response?.data || [];
    const workspaceDate = response?.workspace_date || [];
    const companyType = response?.project?.company_type || [];

    useEffect(()=>{
        // if(!isSuccess){
            dispatch(fetchCashFlowData(project_id, tb_id));
        // }
    }, [])

    useEffect(() => {
        if (reduxChecklistData.length <= 0) {
            getChecklistData(project_id, tb_id).then(response => {
                dispatch({ type: "DISCLOSURE_CHECKLIST_DATA", payload: response?.data?.data });
            }).catch(error => {
                swal("Error", error, "error");
            });
        }
    }, [reduxChecklistData])

    const HandleReference = (event, dataIndex, faceValueDataIndex) => {
        const data = {
            dataIndex,
            faceValueDataIndex,
            value : event.target.value
        }
        cashflowReferenceUpdate(project_id, tb_id, data).then(response => {
            dispatch({
                type: "PFCf_FETCH_DATA",
                payload: {
                    data: response.data
                }
            })
        }).catch(error => {
            swal("Error", error.response?.data?.error || "Reference update failed !" , "error");
        })

    }

    const SwitchReferenceTab = (element) => {
        if(element?.refData?.isReference === "ind_as_reference"){
            dispatch({ type: "IND_AS_1_FROM_BS_PNL", payload: element.refData.name });
            history.push("/library-indas-1")
        }
        if(element?.refData?.isReference === "checklist_reference"){
            const index = reduxChecklistData.findIndex((item) => item.name === element.refData.name);
            dispatch({ type: "NOTE_KEY", payload: 8 });
            dispatch({ type: "NOTE_INDEX", payload: index });
        }
    }

    return (
        <>
        {
            loading ? <div><Loading /></div>
            :
                (<motion.div
                    initial={{ y: "-5vh" }}
                    animate={{ y: 0 }}
                >
                    <div className='d-flex mr-2 justify-content-end' style={{ gap: "1rem" }}>
                         <PrimaryButton name="Cash Flow workings" variant={"outlined"} onClick={() => { history.push(`/financialstatement/cashflow/working`) }}/>
                    </div>
                    {
                        cashFlow.length > 0 && 
                        companyType === "NBFC" ?
                            <NbfcCashflowFaceValue 
                                workspaceDate={workspaceDate} 
                                data={cashFlow} 
                                HandleReference={HandleReference} 
                                SwitchReferenceTab={SwitchReferenceTab} 
                            />
                        : <CashFlowTable workspaceDate={workspaceDate} data={cashFlow} />
                    }
                </motion.div>)
            }
        </>
    )
}

export default CashFlow