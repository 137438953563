import LeaseSidebar from "./LeaseSidebar";
import Navbarv3 from "../Navbarv3";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    overflow: "none",
    p: 4,
};






const LeaserOverview = () => {

    const [isopen, setIsOpen] = React.useState(false);
    const handleIsOpen = () => setIsOpen(true);
    const handleIsClose = () => setIsOpen(false);
    // 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <div>
                <div>
                    <Navbarv3 />
                </div>
                <div className="d-flex">
                    <div>
                        <LeaseSidebar />
                    </div>
                    <div className="w-100 h-100 px-4">
                        <div className="d-flex justify-content-between mt-4">
                            <div className="font-weight-bold h6">Leases overview</div>
                            <div>
                                <Button style={{ textTransform: "none", backgroundColor: "#03565A", color: "white" }} onClick={handleIsOpen}>
                                    <span><AddIcon /></span>Create a new lease</Button>
                                <Modal
                                    open={isopen}
                                    onClose={handleIsClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div className=" mt-1">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <div className="font-weight-bold h3">Lease assessment</div>
                                                    <div className="text-secondary">Does your lease fall under any of the categories?</div>
                                                </div>
                                                {/*  */}
                                                <div className="d-flex">
                                                    <div><Button style={{ backgroundColor: "white", color: "#03565A", textTransform: "none", width: "120px" }}>Cancel</Button>
                                                    </div>
                                                    <div><Button style={{ backgroundColor: "#03565A", color: "white", textTransform: "none", width: "120px" }}>Submit</Button>
                                                    </div>
                                                </div>



                                            </div>





                                        </div>
                                        <div className="mt-2 font-weight-bold">
                                            Exceptions
                                        </div>
                                        <div className="d-flex mt-2" style={{ width: "100%" }}>
                                            <div>
                                                <Card style={{ width: "28rem" }}>
                                                    <CardContent style={{ marginTop: "10px" }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group">
                                                                <FormControlLabel value="female" control={<Radio />} label="Exploration or usage of minerals, oil, natural gas and similar non-regenerative resources" />



                                                            </RadioGroup>
                                                        </FormControl>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                            {/*  */}
                                            <div>
                                                <Card style={{ width: "29rem", marginLeft: "20px" }}>
                                                    <CardContent style={{ marginTop: "10px" }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group">
                                                                <FormControlLabel value="female" control={<Radio />} label="Lease of any living animal or plant" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                        {/*  */}
                                        <div className="d-flex mt-3" style={{ width: "100%" }}>
                                            <div>
                                                <Card style={{ width: "28rem" }}>
                                                    <CardContent style={{ marginTop: "10px" }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group">
                                                                <FormControlLabel value="female" control={<Radio />} label="Service concession arrangements" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                            {/*  */}
                                            <div c>
                                                <Card style={{ width: "29rem", marginLeft: "20px" }}>
                                                    <CardContent style={{ marginTop: "10px" }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group">
                                                                <FormControlLabel value="female" control={<Radio />} label="Licences of intellectual property" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                        {/*  */}
                                        <div className="d-flex mt-3" style={{ width: "100%" }}>
                                            <div>
                                                <Card style={{ width: "28rem" }}>
                                                    <CardContent style={{ marginTop: "10px" }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group">
                                                                <FormControlLabel value="female" control={<Radio />} label="Rights held under a licensing agreements for motion picture films, video recordings, plays, manuscripts, patents and copyrights." />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className="mt-2 font-weight-bold">
                                            Exceptions
                                        </div>
                                        <div className="d-flex mt-2" style={{ width: "100%" }}>
                                            <div>
                                                <Card style={{ width: "28rem" }}>
                                                    <CardContent style={{ marginTop: "10px" }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group">
                                                                <FormControlLabel value="female" control={<Radio />} label="Short term lease" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                            {/*  */}
                                            <div>
                                                <Card style={{ width: "29rem", marginLeft: "20px" }}>
                                                    <CardContent style={{ marginTop: "10px" }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group">
                                                                <FormControlLabel value="female" control={<Radio />} label="Leases for which the underlying asset is of low value" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                    </Box>
                                </Modal>
                            </div>
                        </div>
                        <div>
                            <table class="table table-bordered mt-4">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">MYFINALYST ID</th>
                                        <th scope="col">LESSOR NAME</th>
                                        <th scope="col">ASSET CLASS</th>
                                        <th scope="col">SECURITY DEPOSIT</th>
                                        <th scope="col">DISCOUNT IN SECURITY DEPOSIT</th>
                                        <th scope="col">FIXED LEASE PAYMENT</th>
                                        <th scope="col">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                        <td><MoreVertIcon onClick={handleClick} /></td>
                                    </tr>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <div className="d-flx justify-content-center" style={{ width: "60px", }}>
                                            <div style={{ textAlign: "center", fontSize: "15px" }}>Edit</div>
                                            <div style={{ textAlign: "center", fontSize: "15px" }}>Modify</div>
                                            <div style={{ textAlign: "center", fontSize: "15px" }}>Delete</div>

                                        </div>
                                    </Popover>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                        <td><MoreVertIcon onClick={handleClick} /></td>
                                    </tr>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <div className="d-flx justify-content-center" style={{ width: "60px", }}>
                                            <div style={{ textAlign: "center", fontSize: "15px" }}>Edit</div>
                                            <div style={{ textAlign: "center", fontSize: "15px" }}>Modify</div>
                                            <div style={{ textAlign: "center", fontSize: "15px" }}>Delete</div>

                                        </div>
                                    </Popover>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                        <td><MoreVertIcon onClick={handleClick} /></td>
                                    </tr>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <div className="d-flx justify-content-center" style={{ width: "60px", }}>
                                            <div style={{ textAlign: "center", fontSize: "15px" }}>Edit</div>
                                            <div style={{ textAlign: "center", fontSize: "15px" }}>Modify</div>
                                            <div style={{ textAlign: "center", fontSize: "15px" }}>Delete</div>
                                        </div>
                                    </Popover>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default LeaserOverview;
