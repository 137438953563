import {getCompany } from "../../../WorkSpace/Components/ApiServices/apiHandler";

export const fetchAllCompany = () => {
    return dispatch => {
        dispatch({type:"Company_ACTION_START"})
        getCompany().then((result)=>{
            dispatch({
                type: "Company_FETCH_DATA",
                payload: result.data.getCompany
            }) 
        }).catch((error)=>{
            dispatch({
                type: "Company_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}