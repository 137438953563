import { getMapTbPNLStatement } from "../../../services/api/apiHandler"


export const fetchTTPNLData = (project_id, tb_id) => {
    return dispatch => {
        dispatch({type:"TT_PNL_ACTION_START"})
        getMapTbPNLStatement(project_id, tb_id).then((result)=>{
            dispatch({
                type: "TT_PNL_FETCH_DATA",
                payload: {data: result.data, set_payload: JSON.stringify({project_id, tb_id})}
            })
        }).catch((err)=>{
            dispatch({
                type:"TT_PNL_FETCH_DATA_FAILED",
                payload: err.response.data.error
            })
        })        
    }
}

export const socketTTPNLUpdate = (response, payload) => {
    return dispatch => {
        dispatch({
            type: "TT_PNL_SOCKET_UPDATE_DATA", 
            payload: { 
                data: response, 
                set_payload: payload 
            }
        })
    }
}