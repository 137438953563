const BSTypes = {
    SA_ADJ_ACTION_START:"SA_ADJ_ACTION_START",
    SA_ADJ_FETCH_DATA:"SA_ADJ_FETCH_DATA",
    SA_ADJ_FETCH_DATA_FAILED:"SA_ADJ_FETCH_DATA_FAILED",

    SA_ADJ_LEDGER_START:"SA_ADJ_LEDGER_START",
    SA_ADJ_LEDGER_DATA:"SA_ADJ_LEDGER_DATA",
    SA_ADJ_LEDGER_FAILED:"SA_ADJ_LEDGER_FAILED",

    DLT_ADJ_START:"DLT_ADJ_START",
    DLT_ADJ_DATA:"DLT_ADJ_DATA",
    DLT_ADJ_FAILED:"DLT_ADJ_FAILED",

    ADJ_LOG_START:"ADJ_LOG_START",
    ADJ_LOG_DATA:"ADJ_LOG_DATA",
    ADJ_LOG_FAILED:"ADJ_LOG_FAILED",
    ADJ_LOG_FILTER: "ADJ_LOG_FILTER",

    SA_ADJ_FILTER:"SA_ADJ_FILTER",
    SA_ADJ_CLEANUP:"SA_ADJ_CLEANUP",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    adjustmentData: [],
    copyAdjData: [],
    projectData: {},

    ledgerLoading: false,
    ledger_error_message: null,
    ledgerIsSuccess: false,
    ledgerData: [],

    dltAdjLoading: false,
    dle_adj_error_message: null,
    dltAdjIsSuccess: false,
    dleAdjData: [],

    logLoading: false,
    log_error_message: null,
    logIsSuccess: false,
    logData: [],
    copyLogData: [],

}

const ADJStandaloneReducer = (state=Initial_state, action) => {
    switch(action.type){
        case BSTypes.SA_ADJ_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case BSTypes.SA_ADJ_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                adjustmentData: action.payload?.adjustments ?? [],
                copyAdjData: action.payload?.adjustments ?? [],
                projectData: action.payload?.project ?? [],
            }

        case BSTypes.SA_ADJ_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        // ? For ledger code
        case BSTypes.SA_ADJ_LEDGER_START:
            return {
                ...state,
                ledgerLoading: true,
            }

        case BSTypes.SA_ADJ_LEDGER_DATA: 
            return {
                ...state,
                ledgerLoading: false,
                ledgerIsSuccess: true,
                ledgerData: action.payload?.ledger_list ?? [],
            }

        case BSTypes.SA_ADJ_LEDGER_FAILED: 
            return {
                ...state,
                ledgerLoading: false,
                ledgerIsSuccess: false,
                ledger_error_message: action.payload
            }

        // ? deleted adjustment items
        case BSTypes.DLT_ADJ_START:
            return {
                ...state,
                dltAdjLoading: true,
            }
        case BSTypes.DLT_ADJ_DATA: 
            return {
                ...state,
                dltAdjLoading: false,
                dltAdjIsSuccess: true,
                dleAdjData: action.payload?.logs || [],
            }
        case BSTypes.DLT_ADJ_FAILED: 
            return {
                ...state,
                dltAdjLoading: false,
                dltAdjIsSuccess: false,
                dle_adj_error_message: action.payload
            }

        // ? log adjustment items
        case BSTypes.ADJ_LOG_START:
            return {
                ...state,
                logLoading: true,
            }
        case BSTypes.ADJ_LOG_DATA: 
            return {
                ...state,
                logLoading: false,
                logIsSuccess: true,
                logData: action.payload?.logs?.adjustment_logs || [],
                copyLogData: action.payload?.logs?.adjustment_logs || [],
            }
        case BSTypes.ADJ_LOG_FAILED: 
            return {
                ...state,
                logLoading: false,
                logIsSuccess: false,
                log_error_message: action.payload
            }

        case BSTypes.ADJ_LOG_FILTER:
            return {
                ...state,
                logData: action.payload
            }
        case BSTypes.SA_ADJ_FILTER:
            return {
                ...state,
                adjustmentData: action.payload
            }
        case BSTypes.SA_ADJ_CLEANUP:
                return Initial_state

        default:
            return state;
    }
};

export default ADJStandaloneReducer;