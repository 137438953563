import React, { useEffect, useState } from 'react'
import FinancialTable from './FinancialTable'

const TableManagement = ({ tableData, title, table, HandleChange, onChangeYT, HandleDelete, handleSelect, isDeleted }) => {

    const [financialData, setFinancialData] = useState({})
    const [total, setTotal] = useState({
        table1:{},
        table2:{},
        table3:{}
    })

    useEffect(() => {
        if (tableData) {
            setFinancialData(tableData)
        }
    }, [tableData])

    const calculateValue = (data, key) => {
        let header = Object.keys(data);
        let newArr = []
        header.forEach(ele => {
            newArr = newArr.concat(data[ele])
        })
        let total = newArr.reduce((totalCy, i) => totalCy + Number(i[key]),0) 
        return total ? total : 0
    }

    return (
        <>
            <div className={`w-100 ${table === 1 && "mt-3"}`} style={{height:"fit-content"}}>

                {title && <p className='mt-0 ml-2 custom-common-theme-text-color custom-font-500'>{title}</p>}
                <p className='text-left custom-font-600 h5 mt-0 mb-0 px-2 py-3' style={{ backgroundColor: "#dddddd79" }}>{"Financial assets"}</p>
                {
                    financialData?.financialAssets?.investments.length > 0 &&
                    <FinancialTable isDeleted={isDeleted} handleSelect={handleSelect} HandleDelete={HandleDelete} table={table} data={financialData?.financialAssets?.investments} HandleChange={HandleChange} title={"Investments"} onchangeProp={{ YT:onChangeYT, name:"investments", key:"assets"}} />
                }

                {
                    financialData?.financialAssets?.loans.length > 0 &&
                    <FinancialTable isDeleted={isDeleted} handleSelect={handleSelect} HandleDelete={HandleDelete} table={table} data={financialData?.financialAssets?.loans} HandleChange={HandleChange} title={"Loans"} onchangeProp={{ YT:onChangeYT, name:"loans", key:"assets"}} />
                }

                {
                    financialData?.financialAssets?.otherFinancialAssets.length > 0 &&
                    <FinancialTable isDeleted={isDeleted} handleSelect={handleSelect} HandleDelete={HandleDelete} table={table} data={financialData?.financialAssets?.otherFinancialAssets} HandleChange={HandleChange} title={"Other Financial Assets"} onchangeProp={{ YT:onChangeYT, name:"otherFinancialAssets", key:"assets"}} />
                }

                {
                    !isDeleted && 
                    <table className=''>
                        <tbody>
                            <tr style={{ backgroundColor: "#ffdb6d" }}>
                                <td style={{ width: "35%" }} className='h6 font-weight-bolder text-left pl-2'>Financial assets total</td>
                                <td style={{ width: "9%" }} >{onChangeYT === "table3" && calculateValue(tableData?.financialAssets, "carryingAmountCy")}</td>
                                <td style={{ width: "9%" }} className='h6 font-weight-bolder text-left pl-2'>{
                                    onChangeYT === "table3" ? calculateValue(tableData?.financialAssets, "fairValueCy")
                                    :
                                    calculateValue(tableData?.financialAssets, "level_1")
                                }</td>
                                <td style={{ width: "9%" }} className='h6 font-weight-bolder text-left pl-2'>{
                                    onChangeYT === "table3" ? "" :
                                    calculateValue(tableData?.financialAssets, "level_2")
                                }</td>
                                <td style={{ width: "9%" }} className='h6 font-weight-bolder text-left pl-2'>{
                                    onChangeYT === "table3" ? calculateValue(tableData?.financialAssets, "carryingAmountPy") :
                                    calculateValue(tableData?.financialAssets, "level_3")
                                }</td>
                                <td style={{ width: "9%" }} className='h6 font-weight-bolder text-left pl-2'>{
                                    onChangeYT === "table3" ? calculateValue(tableData?.financialAssets, "fairValuePy") :
                                    calculateValue(tableData?.financialAssets, "total")
                                }</td>
                                <td style={{ width: "14%" }}></td>
                                <td style={{ width: "6%" }} ></td>
                            </tr>
                        </tbody>
                    </table>
                }

                <p className='text-left custom-font-600 h5 mt-0 mb-0 px-2 py-3' style={{ backgroundColor: "#dddddd79" }}>{"Financial Liabilities"}</p>
                {
                    financialData?.financialLiabilities?.borrowings.length > 0 &&
                    <FinancialTable isDeleted={isDeleted} handleSelect={handleSelect} HandleDelete={HandleDelete} table={table} data={financialData?.financialLiabilities?.borrowings} HandleChange={HandleChange} title={"Financial Liabilities - Borrowings"} onchangeProp={{ YT:onChangeYT, name:"borrowings", key:"lability"}} />
                }

                {
                    financialData?.financialLiabilities?.otherFinancialLiabilities.length > 0 &&
                    <FinancialTable isDeleted={isDeleted} handleSelect={handleSelect} HandleDelete={HandleDelete} table={table} data={financialData?.financialLiabilities?.otherFinancialLiabilities} HandleChange={HandleChange} title={"Financial liabilities - Other Financial Liabilities"} onchangeProp={{ YT:onChangeYT, name:"otherFinancialLiabilities", key:"lability"}} />
                }
                {
                    !isDeleted && 
                    <table className=''>
                        <tbody>
                            <tr style={{ backgroundColor: "#ffdb6d" }}>
                                <td style={{ width: "35%" }} className='h6 font-weight-bolder text-left pl-2'>Financial liabilities total</td>
                                <td style={{ width: "9%" }} >{onChangeYT === "table3" && calculateValue(tableData?.financialLiabilities, "carryingAmountCy")}</td>
                                <td style={{ width: "9%" }} className='h6 font-weight-bolder text-left pl-2'>{
                                    onChangeYT === "table3" ? calculateValue(tableData?.financialLiabilities, "fairValueCy") :
                                    calculateValue(tableData?.financialLiabilities, "level_1")
                                }</td>
                                <td style={{ width: "9%" }} className='h6 font-weight-bolder text-left pl-2'>{
                                    onChangeYT === "table3" ? "" :
                                    calculateValue(tableData?.financialLiabilities, "level_2")
                                }</td>
                                <td style={{ width: "9%" }} className='h6 font-weight-bolder text-left pl-2'>{
                                    onChangeYT === "table3" ? calculateValue(tableData?.financialLiabilities, "carryingAmountPy") :
                                    calculateValue(tableData?.financialLiabilities, "level_3")
                                }</td>
                                <td style={{ width: "9%" }} className='h6 font-weight-bolder text-left pl-2'>{
                                    onChangeYT === "table3" ? calculateValue(tableData?.financialLiabilities, "fairValuePy") :
                                    calculateValue(tableData?.financialLiabilities, "total")
                                }</td>
                                <td style={{ width: "14%" }}></td>
                                <td style={{ width: "6%" }} ></td>
                            </tr>
                        </tbody>
                    </table>
                }

            </div>
        </>
    )
}

export default TableManagement