import { responsiveProperty } from "@mui/material/styles/cssUtils";

const APATypes = {
    APA_ACTION_START:"APA_ACTION_START",
    APA_FETCH_DATA:"APA_FETCH_DATA",
    APA_FETCH_DATA_FAILED:"APA_FETCH_DATA_FAILED",
    APA_ADD_UPDATE_DATA:"APA_ADD_UPDATE_DATA",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    adjustment_id: "",
    response: [],
}

const APAReducer = (state=Initial_state, action) => {
    switch(action.type){
        case APATypes.APA_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case APATypes.APA_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                adjustment_id: action.payload.aid,
            }

        case APATypes.APA_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }
        
        case APATypes.APA_ADD_UPDATE_DATA: {
            let newData = state.response;
            const updatedData = action.payload;
            updatedData.map(item => {
                const matchIndex = newData.findIndex(data => data.ledger_name === item.ledger_name)
                if(matchIndex !== -1){
                    newData[matchIndex] = item;
                }else{
                    newData.push(item);
                }
            })
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: newData
            }
        }

        default:
            return state;
    }
};

export default APAReducer;