import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { setIsEditLedgerModal } from '../../redux/actions/BookClosure/actionBookClosure';
import { useDispatch, useSelector } from 'react-redux';

export default function EditLedgerTaskModal({
    ledgerDetails,
    setLedgerDetails,
    UpdateLedgerInTask
}){
    const dispatch = useDispatch();
    const {
        isEditLedgerModal
    } = useSelector((state) => state.BookClosureReducer);

    const handleClose = () => {
        dispatch(setIsEditLedgerModal(false))
    };

    

    const HandleChange = (e) => {
        const { name, value } = e.target;
        setLedgerDetails(prev => ({...prev, [name]: value}))
    }

    return (
        <React.Fragment>
            <Dialog
                open={isEditLedgerModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Update Ledger In Task"}
                </DialogTitle>
                <DialogContent>
                    <div style={{ width: "24rem" }} >
                        <div>
                            <p className='m-0' >Amount</p>
                            <input
                                className='w-100 rounded px-3 py-2 '
                                value={ledgerDetails.amount}
                                name='amount'
                                onChange={HandleChange}
                            />
                        </div>
                        <div>
                            <p className='m-0' >Remark</p>
                            <textarea
                                className='w-100 rounded px-3 py-2'
                                value={ledgerDetails.remark}
                                name='remark'
                                onChange={HandleChange}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='error' className='text-danger' >Close</Button>
                    <Button onClick={UpdateLedgerInTask} autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
