import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
// import Logo from "../assets/navlogo.png";
import LogoWhite from "../assets/navlogo.png";
import LockOpenSharpIcon from '@mui/icons-material/LockOpenSharp';
import styled from "styled-components";

const Nav = styled.div`
  width: 100%;
  background-color: #03565a;
  height: 80px;
  color: white;
  font-family: inherit;
  display: flex;
  padding: 0 2rem 0 2rem;
  align-items: center;
  position: sticky;
  top: 0%;
  z-index: 99;
`;
const Navlinks = styled.div`
  display: flex;
  margin-left: calc(40vw);
  & > ul {
    display: flex;
    align-items: center;
    margin: 0;
  }
  & > ul > li {
    margin-left: 2.8rem;
    text-decoration: none;
    text-emphasis: none;
    list-style: none;
    font-weight: 300;
    font-size: 15px;
    font-stretch: expanded;
  }
  & a {
    color: white;
  }

  & div {
    position: absolute;
  }
  & div img {
    border: 1px solid white;
  }
`;
// const Title = styled.h1`
//     font-size: 1.5rem;
//     font-weight: 700;
//     line-height: 36px;
//     font-size: 24px;

// `
const Profile = styled.div`
  margin-left: auto;
  display: flex;
  /* margin-right:2rem; */
  /* width: 3.5rem; 
    height: 3.5rem;
    border-radius: 50%; */
  /* background-color:black;   */
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const navbar = () => {
  // return (
  //   <AppBar style={{ backgroundColor: "#03565A", height: "90px" }}>
  //     <Link to="/" style={{ color: "white" }}>
  //       {" "}
  //       <h5
  //         style={{
  //           display: "flex",
  //           justifyContent: "flex-end",
  //           marginTop: 28,
  //           marginRight: "80px",
  //           color: "white",
  //         }}
  //       >
  //         {/* <span style={{ color: "#FFFFFF99", marginRight: "10px" }}>
  //           Already have an account?
  //         </span>{" "} */} 
  //        <LockOpenSharpIcon/>&nbsp;SignIn
  //       </h5>
  //     </Link>
  //     <img
  //       src={Logo}
  //       alt="Logo"
  //       style={{ width: "100px", marginTop: "-40px", marginLeft: "70px" }}
  //     />
  //   </AppBar>
  // );

  return (
    <header style={{ backgroundColor: "#03565A", height: "80px" }}>
    <div className="container-fluid">
      <div className="row px-3">
        <div className="col-md-4">
        <img
          src={LogoWhite}
          alt="Logo"
          className="logo-img"
        />
        </div>
        <div className="col-md-8 navbarend-link">
        <Link  to="/" style={{ color: "white"}} className ="font-size-16">
           <LockOpenSharpIcon />&nbsp;Already have an account ? Sign in
           </Link>
        </div>
      </div>
      </div>
    </header>
  
  );
};
export default navbar;
