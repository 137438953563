import React from 'react'
import Popover from '@mui/material/Popover';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';


const SearchPopover = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <div>
                <div>
                    <div style={{ width: "130px" }} className="form mt-0" aria-describedby={id} variant="contained" onClick={handleClick}>
                        <div className="search_box">
                            <SearchIcon style={{ width: "23px", height: "23px", color: "white" }} />
                            <span className="ml-2" style={{ fontFamily: "DM Sans", color: "white" }}>Search</span></div>
                        <input style={{ backgroundColor: "inherit", border: "1px solid white" }} type="button" class="form-control form-input" placeholder="Search..." />
                    </div>
                    <Popover className="mr-5"
                        style={{ marginTop: "30px", width: "500px", }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="d-flex container " style={{ width: "450px", height: "80px", padding: "16px" }}>
                            <div className="search_bttn mt-2">
                                <SearchIcon style={{ width: "23px", height: "23px" }} />
                            </div>
                            <input type="text" style={{ width: "220px", height: "50px", backgroundColor: "#D6D6D6" }} className="form-control form-input mt-0" placeholder="Search by words" />
                            <div>
                                <p className='mt-3 ml-4' style={{ fontSize: "15px" }}>1 of 3</p>
                            </div>
                            <div className='d-flex ml-4'>
                                <div className='mt-2 mr-1'>
                                    <NorthIcon style={{ width: "32px", height: "32px" }} />
                                </div>
                                <div className='mt-2 mr-1'>
                                    <SouthIcon style={{ width: "32px", height: "32px" }} />
                                </div>
                                <div onClick={handleClose}>
                                    <div className='mt-2 ml-2' style={{ border: "2px solid #222222", borderRadius: "5px" }}>
                                        <CloseIcon style={{ width: "27px", height: "27px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popover>
                </div>
            </div>

        </>
    )
}

export default SearchPopover