import {getWorkspace } from "../../../WorkSpace/Components/ApiServices/apiHandler";
// export const fetchWorkspace = (aid) => {
//     return dispatch => {
//         dispatch({type:"WS_ACTION_START"})
//         getAllWorkspace().then((result)=>{
//             dispatch({
//                 type: "WS_FETCH_DATA",
//                 payload: result.data.getWorkspace
//             }) 
//         }).catch((error)=>{
//             dispatch({
//                 type: "WS_FETCH_DATA_FAILED",
//                 payload: error.response.data.error
//             }) 
//         });
//     }
// }

export const fetchWSData = (w_id) => {
    return dispatch => {
        dispatch({type:"WS_ACTION_START"})
        getWorkspace(w_id).then((result)=>{
            dispatch({
                type: "WS_FETCH_DATA",
                payload: result.data.getworkspace
            }) 
        }).catch((error)=>{
            dispatch({
                type: "WS_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}

