import { getProjects } from "../../../Console/Component/services/apiHandler";

export const fetchAllPresets = (wid) => {
    return dispatch => {
        dispatch({type:"PRESETS_ACTION_START"})
        const workspace_id=wid;
        getProjects(workspace_id).then((result)=>{
            dispatch({
                type: "PRESETS_FETCH_DATA",
                payload: result.data.project
            }) 
        }).catch((error)=>{
            dispatch({
                type: "PRESETS_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}