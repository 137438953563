const GTypes = {
    G_ACTION_START:"G_ACTION_START",
    G_FETCH_DATA:"G_FETCH_DATA",
    G_FETCH_DATA_FAILED:"G_FETCH_DATA_FAILED",
    G_ADD_DATA:"G_ADD_DATA",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
    set_payload: ""
}

const GroupingReducer = (state=Initial_state, action) => {
    switch(action.type){
        case GTypes.G_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case GTypes.G_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                set_payload: action.payload.set_payload
            }

        case GTypes.G_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        default:
            return state;
    }
};

export default GroupingReducer;