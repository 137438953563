export const storeDragSelected = (rowData) => {
    return dispatch => {
        dispatch({type:"DS_ADD_VALUE", payload: rowData})       
    }
}

export const storeDragMultiSelected = (selectedRows) => {
    return dispatch => {
        dispatch({type:"SD_ADD_MULTI_ROW_DATA", payload: selectedRows})       
    }
}

export const storeFilterData = (filterRows) => {
    return dispatch => {
        dispatch({type:"SD_FILTER_ROW_DATA", payload: filterRows})       
    }
}

export const addFsGrpForNoteGrp = (fsGrp) => {
    return dispatch => {
        dispatch({type:"SD_FS_GRP_FOR_NOTE_ADD", payload: fsGrp})       
    }
}

export const toggleCheckYearly = (data) => {  //data is boolean
    return dispatch => { 
        dispatch({type:"SD_CHECK_YEARLY", payload: data})       
    }
}