const DStoreTypes = {
    DS_ADD_VALUE:"DS_ADD_VALUE",
}

const Initial_state = {
    selectedRow: null,
}

const selectedDragRow = (state=Initial_state, action) => {
    switch(action.type){
        case DStoreTypes.DS_ADD_VALUE: 
            return {
                ...state,
                selectedRow: action.payload
            }
        default:
            return state;
    }
};

export default selectedDragRow;