import React,{useState,useEffect} from 'react'
import {Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../assets/Logo-my.svg'
import close from '../assets/times.svg'
import './authStyles.css' 
import {Link} from 'react-router-dom'
import { Submit } from '../Components/Button';
import axios from 'axios'
import swal from 'sweetalert';
import {useHistory} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux'
import * as actionTypes from '../redux/actionTypes'
import { createNewPassword } from '../WorkSpace/Components/ApiServices/apiHandler';


const CreatePwd = (props) => {
    let history = useHistory()
    const dispatch = useDispatch();

    const [password, setPassword] = useState('')
    const [confrmpwd ,setConfrmpwd] = useState('')
    const [Show, setShow] = useState(false)
    // console.log('Show modal',Show)
    // console.log('props',props)

    function modalshow(){
        // console.log('modal closed')
        setShow(false)
        props.setModalOpen(false)
    }

    useEffect(() => {
        // console.log('inside the usestate', props.Show)
        setShow(props.modalopen)

    }, [props.modalopen])

    console.log('api call',password)


   async function handleCreatePassword () {
        console.log('api call',password)
        const fd = {
            password:password,
            confrmpwd:confrmpwd,
            userId:props.userId,
        }
        console.log(fd);
        if(!fd.password && !fd.confrmpwd){
            return swal("All fields should be filled!")
        }
        if(fd.password.length < 6){
           return swal("You should create password with atleast 7 characters")
        }

        if(fd.password === fd.confrmpwd){

          await  axios.post('api/v1/superadmin/createpassword',fd)
            .then(res =>{
                console.log(res,'api call')
                setPassword('')
                setConfrmpwd('')
                swal("Your Password Created Successfully,Now you can login with new password! Go to Signin").then(() => {
                    // history.push("/");
                });
            })
            .catch(err=>{
                console.log(err.response)
                swal(err.response)
                // swal(err.response.data.error)
                // swal("", err.response.data.error , "error");
      
            })

        }
        else{
             
            swal("Password and Confirm password not match!Try again carefully")
            return (
                setConfrmpwd('')
            )
        }


    }


    return (
        <div>

            <Modal
                show={Show}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="modal-90w"
                centered
                size="lg"

            >

                <Modal.Body>
                    <div style={{minHeight:'85vh',minWidth:'600px',padding:'6rem 6rem 0 6rem'}}>
                        <div className="title" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}><img src={Logo} style={{height:'5.5rem'}}></img><img onClick={modalshow} style={{cursor:'pointer'}} src={close}/></div>
                        <div className="Modalemail" style={{display:'flex',flexDirection:'column'}}>
                            <h1 style={{textAlign:'center'}}>Create Password</h1>
                            <p>Create a new password for your application!</p>
                            <div style={{padding:'1rem 4rem '}}>
                            <form >
                            <input name="newpassword" style={{textAlign:'center'}} onChange={event => setPassword(event.target.value)} type="text" id="fname"  value={password} placeholder="New password"/>
                                <input name="confirmpassword" style={{textAlign:'center'}} onChange={event => setConfrmpwd(event.target.value)} type="text" id="fname"  value={confrmpwd} placeholder="Confirm password"/>
                            </form>
                            {/* {error.email ? <p style={{color:'red',marginRight:'auto'}}>Email not registered</p> : null} */}

                            <div className='sendLink' onClick={()=> handleCreatePassword()}><Submit value="Continue" /></div>
                            <div className='goback' style={{display:'flex', alignItems:'center',justifyContent:'center'}}><Link to="/" onClick={modalshow}>Go back to<span>  Sign In</span></Link></div>
                            </div>
                        </div>  

                    </div>
                </Modal.Body>
            </Modal>
        

        </div>
    )
}

export default CreatePwd
