import { getConsoleGroupingData } from "../../Console/Component/services/apiHandler";


export const fetchGroupData = (project_ids, browse_fs_ids, currentCompProjectId) => {
    return dispatch => {
        dispatch({type: "G_ACTION_START"})
        getConsoleGroupingData(project_ids, browse_fs_ids, currentCompProjectId).then((result)=>{
            dispatch({
                type: "G_FETCH_DATA",
                payload: {data: result.data, set_payload: JSON.stringify({project_ids, browse_fs_ids, currentCompProjectId})}
            })
        }).catch((err)=>{
            dispatch({
                type:"G_FETCH_DATA_FAILED",
                payload: err.response.data.error
            })
        })        
    }
}