import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../helper/DarkModeContext";
import dateFormat from "dateformat";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { total, tableDataCSs, totalStyle } from "../Styles/style";
import {getProjectDetailById} from "../services/apiHandler";
import { useSelector } from "react-redux";
import { getRandomKey, getCyPyData, getGroupName, prepareAdjustmentData, getScenario, prepareAutoPopulationData, manualAutoAdjustment } from "../../../helper/calculation";


const PopulateFsPattern3 = (props) => {
  const { currentCompany, headingData, groupingData, note_no, subsiaryData,current_tab,currentCompProjectId ,tabData} = props;
  const { loading, isSuccess, response, error_message } = groupingData;
  const { theme } = useContext(ThemeContext);
  const [ projectGroupData, setProjectsGroupData ] = useState({currentProject:[], Projects: [],BrowsFsData:[]});
  const [groupName, setGroupName] = useState([]);
  const [currentProjectName,setCurrentProjectName] = useState("");
  const [adjustmentData, setAdjustmentData] = useState([]);
  const [autoPopulateData, setAutoPopulateData] = useState([]);


  const preAdjustment = useSelector((state)=>state.PADJReducer.response);
  const preAdjustmentOther = useSelector((state)=>state.PADJReducer.otherResponse);
  const AdjData = useSelector((state)=>state.ADJReducer);
  const APAdjustment = useSelector((state) => state.APAReducer);


  useEffect(()=>{
    if(isSuccess === true){
      const GroupData = response.data;
      const result = getCyPyData(GroupData.current_company_grouping.fs_grp, note_no);
      setGroupName([...getGroupName(GroupData.current_company_grouping.fs_grp, note_no)])
      const projectsGroup = GroupData.project_ids_grouping.map((data)=> getCyPyData(data.fs_grp, note_no))
      const browsFsDAta = GroupData.browse_fs_grouping.map((data)=> getCyPyData(data.fs_grp, note_no))
      setProjectsGroupData({...projectGroupData, currentProject: result, Projects: projectsGroup ,BrowsFsData:browsFsDAta});
      getCurrentDataName(currentCompProjectId)
      setAdjustmentData([...prepareAdjustmentData(AdjData, note_no)]);
      setAutoPopulateData([...prepareAutoPopulationData(APAdjustment, note_no)]);
    }
  },[])

  const getCurrentDataName=async(currentCompProjectId)=>{
    const res = await getProjectDetailById(currentCompProjectId);
    setCurrentProjectName(res.data.project.project_name);
  }


  const projectCy = (sub_index, group_index, project_index) => {
    try {
      const { first_scenario, second_scenario } = getScenario(currentCompany,subsiaryData,sub_index);
      const checkObj = projectGroupData.Projects[sub_index][group_index][project_index];
      const result_brows = projectGroupData.BrowsFsData[sub_index][group_index][project_index]?projectGroupData.BrowsFsData[sub_index][group_index][project_index].cy_amt:0;
      const result = checkObj.cy_amt ?? 0;
      
      if (current_tab === 'bs'|| tabData === 'bs') { //bs tab
        if(first_scenario){
          return result;
        }else if(second_scenario){
          return result;
        }else{
          return result;
        }
      }else{  //pnl tab
        if(first_scenario){
          return result - result_brows;
        }else if(second_scenario){
          return result;
        }else{
          return result;
        }
      }
    } catch (error) {
      console.log("error", error.message)
      return 0
    }
  }
  const projectPy = (sub_index, group_index,project_index) => {
    try {
      const { first_scenario, second_scenario } = getScenario(currentCompany,subsiaryData,sub_index);
      const checkObj = projectGroupData.Projects[sub_index][group_index][project_index];
      const result_brows = projectGroupData.BrowsFsData[sub_index][group_index][project_index]?projectGroupData.BrowsFsData[sub_index][group_index][project_index].cy_amt:0;
      
      const result = checkObj.py_amt ?? 0;
      if (current_tab === 'bs'|| tabData === 'bs') { //bs tab
        if(first_scenario){
          return 0;
        }else if(second_scenario){
          return result;
        }else{
          return result;
        }
      } else {
        if(first_scenario){
          return 0;
        }else if(second_scenario){
          return result - result_brows;
        }else{
          return result;
        }
      }
    } catch (error) {
      console.log("error", error.message)
      return 0
    }
  }

  const sumOfCy = (groupData) => {
    try {
      return groupData.reduce((group, acc) => group + acc.cy_amt, 0)
    } catch (error) {
      console.log("error sumOfCy", error.message)
      return 0
    }
  };

  const sumOfPy = (groupData) => {
    try {
      return groupData.reduce((group, acc) => group + acc.py_amt, 0)
    } catch (error) {
      console.log("error sumOfPy", error.message)
      return 0
    }
  };

  const projectCyTotal = (sub_index, group_index) => {
    try {
      return projectGroupData.Projects[sub_index][group_index].map((data, project_index) => projectCy(sub_index,group_index,project_index)).reduce((num, acc)=>num+acc,0)
    } catch (error) {
      console.log("err", error.message) 
      return 0
    }
  }

  const projectPyTotal = (sub_index, group_index) => {
    try {
      return projectGroupData.Projects[sub_index][group_index].map((data, project_index) => projectPy(sub_index,group_index,project_index)).reduce((num, acc)=>num+acc,0)
    } catch (error) {
      console.log("err", error.message) 
      return 0
    }
  }

  const calculateProjectCy = (group_index, project_index) => {
    const totalProjectCy = subsiaryData.map((sub_data, sub_index) => projectCy(sub_index,group_index,project_index))
    const result = totalProjectCy.reduce((data,  num) => data + num, 0)
    return result
  }
  
  const calculateProjectPy = (group_index, project_index) => {
    const totalProjectPy = subsiaryData.map((sub_data, sub_index) => projectPy(sub_index,group_index,project_index))
    const result = totalProjectPy.reduce((data,  num) => data + num, 0)
    return result
  }
  
  const calculateTotalProjectCy = (group_index) => {
    const totalProjectCy = subsiaryData.map((sub_data, sub_index) => projectCyTotal(sub_index,group_index))
    const result = totalProjectCy.reduce((data,  num) => data + num, 0)
    return result
  }
  
  const calculateTotalProjectPy = (group_index) => {
    const totalProjectPy = subsiaryData.map((sub_data, sub_index) => projectPyTotal(sub_index,group_index))
    const result = totalProjectPy.reduce((data,  num) => data + num, 0)
    return result
  }

  const adjustmentIr = (group_index,project_index,scenario) =>{
    try {
      const scenarioResult = subsiaryData.map((sub_data, sub_index) => { return {
         scenario: getScenario(currentCompany,subsiaryData,sub_index),
         brows_fs: projectGroupData.BrowsFsData[sub_index][group_index][project_index],
         goodwill:sub_data?.all_data?.console_entry[0]?.goodwill?sub_data?.all_data?.console_entry[0]?.goodwill:0,
         non_controlling_interest:sub_data?.all_data?.console_entry[0]?.non_controlling_interest?sub_data?.all_data?.console_entry[0]?.non_controlling_interest:0,
         investment:sub_data?.all_data?.console_entry[0]?.investment?sub_data?.all_data?.console_entry[0]?.investment:0,
         otherinputs:sub_data?.all_data?.console_entry[0]?.otherinputs?sub_data?.all_data?.console_entry[0]?.otherinputs:0,
         adjustment: adjustmentData?.filter((adj)=>adj.company_id === sub_data?.all_data?._id),
         autoPopulate: autoPopulateData?.filter((adj)=> adj.company_id === sub_data?.all_data?._id)
      }});
      const browsDataRes = scenarioResult.filter(data=>data.brows_fs !== undefined);
      // console.log("scenario result", browsDataRes)
      const browsDataRes_other = scenarioResult.filter(data=>data);
      if(browsDataRes.length === 0){
        return 0
      }
      const pre_adjustment =  getPreAdjustment(scenarioResult, browsDataRes, browsDataRes_other, scenario, group_index);
      const manual_auto_adjustment = manualAutoAdjustment(scenarioResult, browsDataRes_other, scenario);
      const signChangeGroup = ["Equity", "Non Current Liabilities", "Current Liabilities", "Income"];
      if(signChangeGroup.includes(groupName[group_index])){
        return pre_adjustment + (manual_auto_adjustment * -1);
      }
      return pre_adjustment + manual_auto_adjustment;
    } catch (error) {
      console.log("err",error.message);
      return 0
    }
  }

  const getPreAdjustment = (scenarioResult, browsDataRes, browsDataRes_other, scenario, group_index) => {
    let first_scenario_data = 0;
    let second_scenario_data = 0;
    let third_scenario_data = 0;
    let currentSet = ["Current Assets", "Current Liabilities"];
    if(!currentSet.includes(groupName[group_index]) && preAdjustment.includes(browsDataRes[0].brows_fs.group)){
      const first_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario === true);
      const first_result =  first_scenario_res.map(data=>data.brows_fs.cy_amt).reduce((data, num)=>data + num, 0);
      first_scenario_data += first_result;
    
      const second_scenario_res = scenarioResult.filter((data)=>data.scenario.second_scenario === true);
      const second_result = second_scenario_res.map(data=>data.brows_fs.cy_amt).reduce((data, num)=>data + num, 0);
      second_scenario_data += second_result;
      first_scenario_data += second_result;
    
      const third_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario !== true && data.scenario.second_scenario !== true);
      const third_result = third_scenario_res.map(data=>data.brows_fs.cy_amt).reduce((data, num)=>data + num, 0);
      third_scenario_data += third_result;
      second_scenario_data += third_result;
      first_scenario_data += third_result;
    }else if (!currentSet.includes(groupName[group_index]) && preAdjustmentOther.map(item=>item.sub_group).includes(browsDataRes[0].brows_fs.group)){
      const cal_data = preAdjustmentOther.find(item=>item.sub_group === browsDataRes[0].brows_fs.group).cal_data;
      const first_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario === true);
      const first_result = first_scenario_res.map(data=>Number(data[cal_data])).reduce((data, num)=>data + num, 0);
      first_scenario_data += first_result;
    
      const second_scenario_res = scenarioResult.filter((data)=>data.scenario.second_scenario === true);
      const second_result = second_scenario_res.map(data=>Number(data[cal_data])).reduce((data, num)=>data + num, 0);
      second_scenario_data += second_result;
      first_scenario_data += second_result;
    
      const third_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario !== true && data.scenario.second_scenario !== true);
      const third_result = third_scenario_res.map(data=>Number(data[cal_data])).reduce((data, num)=>data + num, 0);
      third_scenario_data += third_result;
      second_scenario_data += third_result;
      first_scenario_data += third_result;
    }
    const signChangeGroup = ["Equity", "Non Current Liabilities", "Current Liabilities", "Income"]
    switch(scenario){
        case 1:{
          if(signChangeGroup.includes(groupName)){
            return -1 * first_scenario_data;
          }else{
            return first_scenario_data
          }
        }
        case 2:{
          if(signChangeGroup.includes(groupName)){
            return -1 * second_scenario_data;
          }else{
            return second_scenario_data
          }
        }
        case 3:{
          if(signChangeGroup.includes(groupName)){
            return -1 * third_scenario_data;
          }else{
            return third_scenario_data
          }
        }
        default:
          return 0
      }
}

  const totalAdjustment=(group_index, projectGroup,scenario)=>{
    const result = projectGroup.map((data,project_index)=>adjustmentIr(group_index,project_index,scenario))
    return result.reduce((data,  num) => Number(data) + num, 0);
  }

  return (
    <div>
       {loading ? (
        <CircularProgress disableShrink />
      ) : isSuccess ?
      <table>
        <TableHeader currentCompany={currentCompany} subsiaryData={subsiaryData} currentProjectName={currentProjectName}/>
        <tbody style={{
            background: `${theme ? "" : "#363535"}`,
            color: `${theme ? "" : "#fff"}`,
          }}
        >
          { projectGroupData.currentProject.length !== 0 && projectGroupData.currentProject.map((projectGroup, group_index) => (
            <>
            <React.Fragment key={getRandomKey()}>
              <tr key={group_index} style={totalStyle}>
                <td style={tableDataCSs}>{ groupName[group_index] ?? ""}</td>
                <td>{""}</td>
                <td>{""}</td>
              </tr>
            </React.Fragment>
             <React.Fragment key={getRandomKey()}>
                {projectGroup.map((data, project_index)=>(
                  <tr key={getRandomKey()}>
                   <td style={tableDataCSs}>{data.group} </td>
                   
                   <td>{ (data.cy_amt + calculateProjectCy(group_index, project_index) + (adjustmentIr(group_index,project_index,1))).toLocaleString("en-IN") }</td>
                   <td>{ (data.cy_amt + calculateProjectPy(group_index, project_index) + (adjustmentIr(group_index,project_index,2))).toLocaleString("en-IN") }</td>
                 </tr>
                ))}
            </React.Fragment>
            <React.Fragment key={getRandomKey()}>
                <tr key={getRandomKey()}>
                <td style={total}>TOTAL</td>
                <td style={total}>{(sumOfCy(projectGroupData.currentProject[group_index]) + calculateTotalProjectCy(group_index) + (totalAdjustment(group_index,projectGroup,1) * -1) ).toLocaleString("en-IN")}</td>
                <td style={total}>{(sumOfPy(projectGroupData.currentProject[group_index]) + calculateTotalProjectPy(group_index) + (totalAdjustment(group_index,projectGroup,2) * -1)).toLocaleString("en-IN")}</td>
              </tr>
            </React.Fragment>
            </>
          )) }
        </tbody>
      </table>
      : (
        <p className="text-danger">
          {error_message || "Something went wrong!!!"}
        </p>
      )
      }
    </div>
  )
}
export default PopulateFsPattern3;


const TableHeader = (props) => {
  const {subsiaryData, currentCompany,currentProjectName} = props;

  const colStyle = { 
    width: "25%",
    textTransform: "capitalize" 
  }
  const particularTab = { 
    width: "300px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem"
  }

  const thBgColor = { backgroundColor: "#A2C0C2" }

  return (
     <thead>
        <tr style={thBgColor} key={getRandomKey()}>
          <th rowSpan="2" style={particularTab}><strong>Particulars</strong></th>
          <th style={particularTab}>
              {dateFormat(currentCompany?.current_financial_year, "mmmm dS, yyyy")}
              {/* {"1/2/3"} */}
          </th>
          <th style={particularTab}>
              {dateFormat(currentCompany?.previous_financial_year, "mmmm dS, yyyy")}
          </th>
        </tr>
    </thead>
  )
}