export const total = {
    lineHeight: "22px",
    letterSpacing: "0px",
    fontSize: "18px",
    fontWeight: "600",
    backgroundColor: "#e0e0e0",
    borderBottom: "1px solid #cfcccc",
    paddingRight: "10px",
    // height:'30px'
  };
 export const level2 = {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "500",
    // color:'green'
  };
  export const total2 = {
    lineHeight: "22px",
    letterSpacing: "0px",
    fontSize: "18px",
    fontWeight: "600",
    backgroundColor: "#f2e2b6",
    borderBottom: "1px solid #cfcccc",
  };
  export const Note = {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "5   00",
    color: "var(--clr-accent)",
    cursor: "pointer",
  };
  export const bsModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    height:"100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  export const APModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  } 

  export const lineGroupStyle = {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "500",
  };
  
  export const totalStyle = {
    lineHeight: "22px",
    letterSpacing: "0px",
    fontSize: "18px",
    fontWeight: "600",
    backgroundColor: "#e0e0e0",
    borderBottom: "1px solid #cfcccc",
  };
  
  export const totaledStyle = {
    lineHeight: "22px",
    letterSpacing: "0px",
    fontSize: "18px",
    fontWeight: "600",
    backgroundColor: "#f2e2b6",
    borderBottom: "1px solid #cfcccc",
  };
  
  export const NoteStyle = {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "5   00",
    color: "var(--clr-accent)",
    cursor: "pointer",
  };

export const tableDataCSs = {
    width: "30%",
    textAlign: "left",
    paddingLeft: "1rem",
    color: "black",
};

export const shortcut = {
  color: "var(--clr-accent)",
  fontWeight: "600",
  fontSize: "1rem",
  marginLeft: "1rem",
  cursor: "pointer",
  borderRadius: "50%",
  border: "2px solid var(--clr-accent)",
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const shortcut1 = {
color: "#fff",
fontWeight: "600",
fontSize: "1rem",
marginLeft: "1rem",
cursor: "pointer",
borderRadius: "50%",
border: "2px solid var(--clr-accent)",
width: "40px",
height: "40px",
display: "flex",
alignItems: "center",
justifyContent: "center",
background:"#03565a"
};