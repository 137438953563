import { getAllIR } from "../../WorkSpace/Components/ApiServices/apiHandler";


export const fetchIR = () => {
    return dispatch => {
        dispatch({type:"IR_ACTION_START"})
        getAllIR().then((result)=>{
            dispatch({
                type: "IR_FETCH_DATA",
                payload: {data: result.data}
            })
        }).catch((err)=>{
            dispatch({
                type:"IR_FETCH_DATA_FAILED",
                payload: err.response.data.error
            })
        })        
    }
}