import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import financialAsset from "../../assets/financialAsset.svg" 
// import arrow from "./../assets/Image/arrow-2.png"
import arrow from "./../../assets/Image/arrow-2.png"
import { colors } from "@mui/material";

const buttonArrow = {
  backgroundColor: "#D1E0E0",
  width: "30px",
  height: "30px",
  borderRadius: "6px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "auto",
  color:"#03565A"
};

const Header = {
  marginTop: "10px",
  fontSize: "20px",
  fontWeight: "700",
  // marginBottom: "2rem",
};

const amount = {
  display: "flex",
  alignItems: "center",
  height: "1rem",
  margin: "1rem 0",
  marginTop:"3px"
};

const paraAmount = {
  color: "#4F4F4F",
  fontSize: "16px",
  fontWeight: "bold",
  // marginTop:"-4px"
};

const actualAmount = {
  color: "#4F4F4F",
  fontSize: "14px",
  fontWeight: "normal",
  display: "flex",

  // marginTop:'1rem'
};

const block = {
  display: "flex",
  flexDirection: "column",
};

const PreviewCardV2 = (props) => {
 

  return (
    <motion.div
      className="previewCardv2"
      // onClick={() => {
      //   handlePreview();
      // }}
      whileHover={{
        scale: 1.02,
        // transition: { duration: 0.1 },
      }}
    >
      <div>
        <img src={financialAsset} />
      </div>
      <div style={Header} className="mb-2">Current Assets</div>
      <div style={block}>
        <div style={amount}>
          <p style={paraAmount}>
            Current Year:
            <span style={actualAmount}>
             
            </span>
          </p>
        </div>
        <div style={amount}>
          <p className="mt-0 mb-0" style={paraAmount}>
            Previous Year:
            <span style={actualAmount}>
             
            </span>
          </p>
        </div>
      </div>
      <div>
        <div style={buttonArrow}>
          <img src={arrow}/>
        </div>
      </div>
    </motion.div>
  );
};

export default PreviewCardV2;
