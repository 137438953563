import React, { useState, useEffect } from "react";
import Pagination from '@mui/material/Pagination';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const PaginationRounded = ({ data, setData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage= event.target.value;
        const newTotalPages = Math.ceil(data.length / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(prevPage => {
            const lastPage = Math.max(newTotalPages, 1);
            return Math.min(prevPage, lastPage);
        }); 
    };


    useEffect(() => {
        if(data.length > 0){
            const startIndex = (currentPage - 1) * rowsPerPage;
            const endIndex = startIndex + rowsPerPage;
            const dataOnCurrentPage = data.slice(startIndex, endIndex);
            setData(dataOnCurrentPage);
        }
    }, [currentPage, rowsPerPage, data, setData]);

    const totalPages = Math.ceil(data.length / rowsPerPage);
    
    return (
        <>
            {totalPages > 0 && (
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex align-items-center">
                        <div>
                            <Select
                                style={{width:"65px",height:"30px"}}
                                value={rowsPerPage}
                                onChange={handleRowsPerPageChange}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                            </Select>
                        </div>
                        <div className="pl-2 mt-1">
                           Rows per pages
                        </div>
                    </div>
                <div>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                            "& .MuiPaginationItem-page.Mui-selected":{
                                backgroundColor: "#03565A",
                                color: "white"
                            }
                        }}
                    />
                </div>
            </div>
            
            )}
        </>
    );
}

export default PaginationRounded;
