import { getCashflow } from "../../../services/api/StandAlone/cashFlow";
import { createAllBalanceNote } from "../../../services/api/apiHandler";

export const fetchCashFlowData = (project_id, tb_id) => {
    createAllBalanceNote(project_id, tb_id).then(()=>{
        console.log("created");
    }).catch((err)=>{
        console.log("er", err.message)
    })
    return dispatch => {
        dispatch({type: "PFCf_ACTION_START"})
        getCashflow(project_id, tb_id).then((response) => {
            dispatch({
                type: "PFCf_FETCH_DATA",
                payload: {
                    data: response.data
                }
            })
        }).catch((err)=>{
            dispatch({
                type: "PFCf_FETCH_DATA_FAILED",
                error_message: err.message
            })
        })
    }
}