import React, { useEffect, useState } from 'react';
import Card from "react-bootstrap/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import swal from 'sweetalert';
import { approveDataRegisterLog, getGetDataRegisterLogs, returnDataRegisterLog } from '../../ApiServices/apiHandler';
import { toast } from 'react-toastify';
import ReturnDataRegisterLogModal from './ReturnDataRegisterLogModal';

const DataRegisterPanel = ({
    label,
    responsibilities
}) => {
    const [logs, setLogs] = useState([]);
    const [returnLogModal, setReturnLogModal] = useState({
        isOpen: false,
        returnText: "",
        title:"",
        registerId:""
    });

    useEffect(() => {
        getGetDataRegisterLogs(label.value).then(response => {
            setLogs(response.data?.logs);
        }).catch(error => {
            swal("Error", error.response?.data?.error || "Getting Data Register Logs Failed!" , "error");
        })
    }, []);

    const ApproveDataRegisterLog = (registerId) => {
        approveDataRegisterLog(registerId).then(response => {
            toast.success(`${label.label} register approved successfully.`);
        }).catch(error => {
            swal("Error", error.response?.data?.error || `Approved ${label.value} Register Logs Failed!` , "error");
        })
    }

    const ReturnDataRegisterLog = () => {
        returnDataRegisterLog({
            returnReason: returnLogModal.returnText
        },
        returnLogModal.registerId
        ).then(response => {
            toast.success(`${label.label} register returned successfully.`);
            setReturnLogModal(prev => ({...prev, isOpen: false, returnText: "", registerId: "", title:"" }))
        }).catch(error => {
            swal("Error", error.response?.data?.error || `Return ${label.value} Register Logs Failed!` , "error");
            setReturnLogModal(prev => ({...prev, isOpen: false, returnText: "", registerId: "", title:"" }))
        })
    }

    return (
        <Card>
            <CardContent>
                <Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='custom-font-500'>Title</TableCell>
                                    <TableCell className='custom-font-500'>Approver</TableCell>
                                    <TableCell className='custom-font-500'>Preparer</TableCell>
                                    <TableCell className='custom-font-500 text-center'>Status</TableCell>
                                    <TableCell className='custom-font-500 text-center'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    logs.length > 0 ? logs.map((log, index) => (
                                        <TableRow key={index}>
                                            <TableCell className='text-secondary'> {log?.title} </TableCell>
                                            <TableCell className='text-secondary'>{log?.approverData?.email}</TableCell>
                                            <TableCell className='text-secondary'>{log?.preparerData?.email}</TableCell>
                                            <TableCell align='center'>
                                                <span 
                                                    class={log?.status === "approved" ? "badge badge-success py-1 px-2" 
                                                        : log?.status === "returned" ? "badge badge-danger py-1 px-2" : "badge badge-primary py-1 px-2"
                                                    }
                                                >
                                                    {log?.status}
                                                </span>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <a href={log?.url} className='font-size-12' > View </a>
                                                {
                                                    responsibilities === "approver" && (
                                                        <>
                                                            <button 
                                                                className='ml-3 border-0 bg-color-inherit text-success font-size-12'
                                                                onClick={() => ApproveDataRegisterLog(log._id)}
                                                            >
                                                                Approve
                                                            </button>
                                                            <button 
                                                                className='ml-3 border-0 bg-color-inherit text-danger font-size-12'
                                                                onClick={() => setReturnLogModal(prev => ({...prev, 
                                                                    isOpen:true,
                                                                    title: log?.title,
                                                                    registerId: log._id
                                                                }))}
                                                            >
                                                                Return
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )) : <TableRow> <TableCell colSpan={4} align='center' className='text-secondary custom-font-500'> No Data Found </TableCell> </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </CardContent>
            <ReturnDataRegisterLogModal 
                returnLogModal={returnLogModal}
                setReturnLogModal={setReturnLogModal}
                OnClick={ReturnDataRegisterLog}
            />
        </Card>
    )
}

export default DataRegisterPanel;