import React,{useEffect,useState} from "react";
import ReactExport from "react-export-excel";
import {useSelector} from 'react-redux'
import download from '../../assets/download.svg'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({projectName}) => {
    const conventionModeData = useSelector(
        (state) => state.ConventionModeReducer
      );
    
    const [tableData, setTableData] = useState([]);

    useEffect(()=>{
        const transformedData = conventionModeData?.response?.data?.line_items?.map((item)=>({
            ...item, 
            cy_fs_grp: item?.map_data?.cy_map?.fs_grp || null,
            cy_note_grp: item?.map_data?.cy_map?.note_grp || null,
            cy_sub_grp: item?.map_data?.cy_map?.sub_grp || null,
            py_fs_grp: item?.map_data?.py_map?.fs_grp || null,
            py_note_grp: item?.map_data?.py_map?.note_grp || null,
            py_sub_grp: item?.map_data?.py_map?.sub_grp || null,
        }))
        setTableData(transformedData);
    }, [conventionModeData?.response])

    const exportText = {
        marginLeft:'auto',
        color:'#03565A',
        fontSize:'1rem',
        fontWeight:'600',
        width:'5rem',
        display:'flex',
        cursor:'pointer'
    }
    
    return (
        <ExcelFile
        filename={projectName ? projectName : 'Export project'}
        // eslint-disable-next-line jsx-a11y/alt-text
        element={<div style={exportText}><img src={download} style={{marginRight:'5px'}}/>Export</div>}>

            <ExcelSheet data={tableData} name="Ledger Data">
                <ExcelColumn label="Ledger Code" value="ledger_code"/>
                <ExcelColumn label="ledger_name" value="ledger_name"/>
                <ExcelColumn label="Current Year" value="cy"/>
                <ExcelColumn label="Net Adjusted Amount" value="net_adjusted_amount"/>
                <ExcelColumn label="Previous Year" value="py"/>
                <ExcelColumn label="CY fS Group" value="cy_fs_grp"/>
                <ExcelColumn label="CY Notes Group" value="cy_note_grp"/>
                <ExcelColumn label="CY Sub Group" value="cy_sub_grp"/>
                <ExcelColumn label="PY fS Group" value="py_fs_grp"/>
                <ExcelColumn label="PY Notes Group" value="py_note_grp"/>
                <ExcelColumn label="PY Sub Group" value="py_sub_grp"/>
            </ExcelSheet>
        </ExcelFile>
    );
}

export default ExportExcel

