export const type = {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0px",
  };
export const level1 = {
    lineHeight: "22px",
    letterSpacing: "0px",
    fontSize: "18px",
    fontWeight: "600",
    backgroundColor: "#f2e2b6",
    borderBottom: "1px solid #cfcccc",
  };
export const total = {
    lineHeight: "22px",
    letterSpacing: "0px",
    fontSize: "18px",
    fontWeight: "600",
    backgroundColor: "#e0e0e0",
    borderBottom: "1px solid #cfcccc",
    paddingRight: "10px",
  };
export const level2 = {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "500",
  };
export const Note = {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "5   00",
    color: "var(--clr-accent)",
    cursor: "pointer",
  };
  
export const level4 = {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "400",
  };