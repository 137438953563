import { Tabs, Tab, Accordion } from "react-bootstrap";
import NewConsoleBalanceSheet from "../consoleTabs/ConsoleBalanceSheet";
import NewConsolePnlStatement from "../consoleTabs/newConsolePnlStatement";
import PopulateFsBalancesheet from "./populateFSBalancesheet";
import PopulateFsPnlstatement from "./populateFsPnlStatement";
import PopulateFsSocie from "./populateFsSocie";
import PopulateFsPnlNotes from "./populateFsPnlNotes";
import PopulateFsBalanceNote from "./populateFsBalanceNotes";
import PopulateFsPnlStatementNotes from "./populateFsPnlStatementNotes";
import { useState } from "react";
import Disclosures from "../../../Components/Disclosures/Disclosures";
import PopulateFsCashFlow from "./populateFsCashFlow";

const PopulateFSConsole = (props) => {
  const {
    setTempValue,
    balanceSheetData,
    groupingData,
    currentCompany,
    headingData,
    notes,
    currentCompProjectId,
    subsiaryData,
    pnlStatementData,
    allcheckdata,
    consoleNotes,
    setCurrentProfit,
    setCurrentYearProfit,
    setComYearProfit,
    currentProfit,
    setComProfit,
    comYearProfit,
    comProfit,
    currentYearProfit,
    consoleData,
    FindIr,
    setCheckData,
  } = props;
  const [key, setKey] = useState("Balance Sheets");
  const [checkKey, setDataKey] = useState("");
  const [recentTab, setCurrentTab] = useState("");
  const [tabCheck, setTab] = useState("");

  const main = {
    padding: "2rem 4rem",
    backgroundColor: "rgb(229, 229, 229)",
    minHeight: "calc(100vh - 80px)",
    minWidth: "1045px",
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div>
        <Tabs
          // defaultActiveKey="Balance Sheets"
          id="uncontrolled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab className="font-sans" eventKey="Balance Sheets" title="Balance Sheets">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <PopulateFsBalancesheet
                setTempValue={setTempValue}
                balanceSheetData={balanceSheetData}
                groupingData={groupingData}
                currentCompany={currentCompany}
                //   headingData={headingData.map((data) => data?.all_data?.company)}
                notes={notes}
                currentCompProjectId={currentCompProjectId}
                subsiaryData={subsiaryData}
                browsFs={[]}
                setCheckData={setCheckData}
                setKey={setKey}
                setDataKey={setDataKey}
                setCurrentTab={setCurrentTab}
                FindIr={FindIr}
              />
            </div>
          </Tab>
          <Tab className="font-sans" eventKey="P&L Statement1" title="P&L Statement">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <PopulateFsPnlstatement
                setTempValue={setTempValue}
                pnlStatementData={pnlStatementData}
                currentCompany={currentCompany}
                //   headingData={headingData.map((data) => data.all_data.company)}
                currentCompProjectId={currentCompProjectId}
                subsiaryData={allcheckdata}
                consoleNotes={consoleNotes}
                groupingData={groupingData}
                setCurrentProfit={setCurrentProfit}
                setCurrentYearProfit={setCurrentYearProfit}
                setComYearProfit={setComYearProfit}
                currentProfit={currentProfit}
                setComProfit={setComProfit}
                setKey={setKey}
                setDataKey={setDataKey}
                setCurrentTab={setTab}
                FindIr={FindIr}

              />
            </div>
            {/* <Sonnet /> */}
          </Tab>
          <Tab className="font-sans" eventKey="cashFlow" title="CashFlow">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              {/* <PopulateFsCashFlow 
              subsiaryData={allcheckdata}
              /> */}
            </div>
          </Tab>
          <Tab eventKey="P&L Statement3" title="SOCIE">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <PopulateFsSocie
                allcheckdata={allcheckdata}
                subsiaryData={allcheckdata}
                currentCompProjectId={currentCompProjectId}
                currentProfit={currentProfit}
                comProfit={comProfit}
                currentYearProfit={currentYearProfit}
                comYearProfit={comYearProfit}
                currentCompany={currentCompany}
                pnlStatementData={pnlStatementData}
                headingData={allcheckdata.map((data) => data.company)}
                groupingData={groupingData}
                consoleNotes={consoleNotes}
                FindIr={FindIr}
              />
            </div>
          </Tab>
          <Tab eventKey="generalNotes" title="General Notes">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <PopulateFsPnlNotes />
            </div>
          </Tab>
          <Tab eventKey="Notes" title="Balance Sheet Notes">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <PopulateFsBalanceNote
                consoleNotes={consoleNotes}
                balanceSheetData={balanceSheetData}
                groupingData={groupingData}
                consoleData={consoleData}
                allcheckdata={allcheckdata}
                tab={recentTab}
                pnlStatementData={pnlStatementData}
                currentCompProjectId={currentCompProjectId}
                checkKey={checkKey}
                subsiaryData={subsiaryData}
              />
            </div>
          </Tab>
          <Tab eventKey="Notes2" title="PNL Statement Notes">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <PopulateFsPnlStatementNotes
                pnlStatementData={pnlStatementData}
                currentCompany={consoleData}
                currentCompProjectId={currentCompProjectId}
                subsiaryData={allcheckdata}
                consoleNotes={consoleNotes}
                groupingData={groupingData}
                setCurrentProfit={setCurrentProfit}
                setCurrentYearProfit={setCurrentYearProfit}
                setComYearProfit={setComYearProfit}
                currentProfit={currentProfit}
                setComProfit={setComProfit}
                headingData={headingData}
                allcheckdata={allcheckdata}
                tab={tabCheck}
                checkKey={checkKey}
              />
            </div>
          </Tab>

          <Tab eventKey="disclosures" title="Disclosures">
            <div style={{ minHeight: "100%", backgroundColor: "white" }}>
              <Disclosures
                scrollToTop={scrollToTop}
                setKey={setKey}
                setNoteName={checkKey}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default PopulateFSConsole;
