import React, { useEffect, useState } from 'react'
import { Navbar } from '../../../Components/Navbar';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

import adjustment from "../../../assets/adjustment.svg";
import arrow from "../../../assets/arrowLeftGreen.svg";
import filter from "../../../assets/filter.svg";
import download from "../../../assets/download.svg";
import trash from "../../../assets/trash.svg";
import AddIcon from '@mui/icons-material/Add';

import { CSVLink } from "react-csv";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

const AdjustmentHeader = ({
    adjustmentData,
    filterShow,
    setFilterShow,
    handleOptions
}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const history = useHistory();
    const dispatch = useDispatch();
    const template_action = localStorage.getItem("templateAction");
    const project_id = localStorage.getItem("project_id");

    const [exportAdjustments, setExportAdjustments] = useState([]);

    useEffect(() => {
        if (adjustmentData.length > 0) {
            adjustmentData.forEach((element) => {
                let id = Object.keys(element)[0];
                element[id].forEach((row, index) => {
                    setExportAdjustments((exportAdjustments) => [
                        ...exportAdjustments,
                        {
                            SlNo: adjustmentData.length,
                            Date: element.date ? element.date : "",
                            ledgerCode: element[id][index].ledger_code,
                            ledgerName: element[id][index].ledger_name,
                            branch: element[id][index].branch,
                            cy: element[id][index].cy,
                            adjustment: element[id][index].adjusted_amount,
                            remarks: element.remarks,
                        },
                    ]);
                });
            });
        } else {
            setExportAdjustments([]);
        }

        return () => {
            <ExcelFile>
                <ExcelSheet name="Ledger Data">
                    <ExcelColumn label="Ledger Code" value="ledger_code" />
                    <ExcelColumn label="ledger_name" value="ledger_name" />
                    <ExcelColumn label="Current Year" value="cy" />
                    <ExcelColumn
                        label="Net Adjusted Amount"
                        value="net_adjusted_amount"
                    />
                    <ExcelColumn label="Previous Year" value="py" />
                    <ExcelColumn label="fS Group" value="fs_grp" />
                    <ExcelColumn label="Notes Group" value="note_grp" />
                    <ExcelColumn label="Sub Group" value="sub_grp" />
                </ExcelSheet>
            </ExcelFile>;
        };

    }, [adjustmentData]);

    const csvLink = {
        data: exportAdjustments,
        filename: "Adjustment.csv",
    };

    return (
        <div>
            <Navbar text="Adjustments" />
            <div className="adjustmentsMenu">
                <div
                    className="ml-0"
                    onClick={() => { history.push(`/${template_action}/${project_id}`) }}
                >
                    <img
                        src={arrow}
                        alt="arrow"
                    />
                    <p className="custom-font-600 custom-common-theme-text-color" >Back</p>
                </div>

                <div
                    onClick={() => setFilterShow(!filterShow)}
                >
                    <img src={filter} alt="filter" />
                    <p>Filter</p>
                </div>
                <div>
                    <img src={download} alt="export" />
                    <CSVLink {...csvLink}>
                        <p style={{ color: "black" }}>Export</p>
                    </CSVLink>
                </div>
                <div
                    onClick={() => { history.push("/deleted-adjustments") }}
                >
                    <img src={trash} alt="trash" />
                    <p>Deleted items</p>
                </div>
                <div
                    onClick={() => { history.push("/adjustments-log") }}
                >
                    <img src={adjustment} alt="adjustment" />
                    <p>Adjustment Logs</p>
                </div>

                <div
                    onClick={() =>user.responsibilities === "approver" ? null : handleOptions()}
                >
                    <AddIcon />
                    <p>New Adjustment</p>
                </div>
            </div>
        </div>
    )
}

export default AdjustmentHeader