import { GetPayable } from "../../../WorkSpace/Components/ApiServices/apiHandler";

export const fetchAllPayables = () => {
    return dispatch => {
        dispatch({type:"PAYABLES_ACTION_START"})
        GetPayable().then((result)=>{
            dispatch({
                type: "PAYABLES_FETCH_DATA",
                payload: result.data.payables
            }) 
        }).catch((error)=>{
            dispatch({
                type: "PAYABLES_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}