import React, { useState } from "react";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import { CheckCircle } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";

function ImageUpload({uploadImages, headerNumber}) {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  // Handle image selection
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    setImageName(selectedImage ? truncateFileName(selectedImage.name) : "");
  };

  function truncateFileName(name, maxLength = 12) {
    if (name.length <= maxLength) {
      return name;
    }
    return name.slice(0, 9) + "...";
  }

  const removeImage = () => {
    setImage(null);
    setImageName("");
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!image) {
      alert("Please select an image.");
      return;
    }
    setUploading(true); // Start showing progress icon
    const formData = new FormData();
    formData.append("image", image);

    // Replace 'your-api-endpoint' with your actual API endpoint
    const url = process.env.REACT_APP_PYTHON_URL + "upload_image";
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setUploadSuccess(true); // Show success icon
        uploadImages(data.image_url, headerNumber);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setUploading(false); // Hide progress icon
      });
  };

  return (
    <>
      {imageName ? (
        <div
          className="mt-2 rounded d-flex align-items-center justify-content-center "
          style={{
            height: "55px",
            width: "180px",
            backgroundColor: "#F8F8F8",
            borderRadius: "10px",
          }}
        >
          <div className="d-flex align-items-center">
            <DescriptionIcon />
            <p className="ml-2 font-size-16 custom-font-500">{imageName}</p>
          </div>
          {uploading && (
            <CachedIcon size={24} style={{animation: 'rotate 2s linear infinite'}} />
          )}
          {uploadSuccess && <CheckCircle color="success" />}
          {!uploading && !uploadSuccess ?
            <>
              <DoneIcon color="success" style={{width: "15px", height: "15px", cursor: "pointer" }} onClick={handleSubmit} />
              <CloseIcon
                className="custom-redcolor ml-1 mt-1"
                style={{ width: "15px", height: "15px", cursor: "pointer" }}
                onClick={removeImage}
              />
            </>
            :""
            }
        </div>
      ) : (
        <div
          className="mt-3 d-flex align-items-center justify-content-center w-100 "
          style={{
            height: "90px",
            backgroundColor: "#F8F8F8",
            border: "1px dashed #C3C8D3",
            borderRadius: "10px",
          }}
        >
          <input
            id="imageInput"
            type="file"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <div
            className="d-flex align-items-center"
            onClick={() => document.getElementById("imageInput").click()}
            style={{ cursor: "pointer" }}
          >
            <ImageIcon style={{ color: "#717171" }} />
            <p className="ml-2 font-size-16 custom-font-500">Add image</p>
          </div>
        </div>
      )}
    </>
  );
}

export default ImageUpload;
