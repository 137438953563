/*
    Author - DJ Parida
    This reducer is meant for the soft deletion of Notes Data
*/

const SDNTypes = {
    ADD_NOTES_FOR_SOFT_DELETE:"ADD_NOTES_FOR_SOFT_DELETE",
    REMOVE_NOTES_FOR_SOFT_DELETE:"REMOVE_NOTES_FOR_SOFT_DELETE",
    CLEAR_SOFT_DELETE: "CLEAR_SOFT_DELETE"
}

const Initial_state = {
    selectedNotes: [],
}

const NotesSoftDelete = (state=Initial_state, action) => {
    switch(action.type){
        case SDNTypes.ADD_NOTES_FOR_SOFT_DELETE: 
            const isUnique = !state.selectedNotes.some(note => note.notes_no === action.payload.notes_no);
            return {
                ...state,
                selectedNotes: isUnique ? [...state.selectedNotes, action.payload] : state.selectedNotes
            }
        case SDNTypes.REMOVE_NOTES_FOR_SOFT_DELETE:
            return {
                ...state,
                selectedNotes: state.selectedNotes.filter(note => note.notes_no !== action.payload.notes_no && note.type === action.payload.type)
            }
        case SDNTypes.CLEAR_SOFT_DELETE:
            return {
                ...state,
                selectedNotes: []
            }
        default:
            return state;
    }
};

export default NotesSoftDelete;