import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

const SidebarTeam = () => {
  return (
    <div className="container custom-container d-flex flex-column justify-content-between">
      <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4  pt-4">View access Controls</div>
      <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 ">Contact Support</div>
      <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 ">
        Version details and
        <br />
        <span className='ml-4'>updates</span>
      </div>
    </div>
  );
};

export default SidebarTeam;
