const TableHeader = (props) => {
  const { showTotal } = props;
  const colStyle = {
    width: "25%",
    textTransform: "capitalize"
  }
  // const particlarTab = { 
  //   width: "45%",
  //   height: "50px",
  //   textAlign: "left",
  //   paddingLeft: "2rem"
  // }
  const particlarTab = {
    width: "300px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem"
  }

  const thBgColor = { backgroundColor: "#A2C0C2" }
  const thBgColor2 = { backgroundColor: "#FFFF00" }
  return (
    <thead>
      <tr>
        <th>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {"Attributable to parent company"}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        {showTotal ? <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th> : ""}
      </tr>
      <tr style={thBgColor}>
        <th
          style={particlarTab}
          rowSpan={2}
        >
          {/* OTHER EQUITY */}
        </th>
        <th style={particlarTab} rowSpan={2}>
          Share application money pending allotment
        </th>
        <th style={particlarTab} rowSpan={2}>
          Equity component of compounded financial instruments
        </th>
        <th style={particlarTab} colSpan={4}>
          Reserves and surplus
        </th>
        <th style={particlarTab} rowSpan={2}>
          Debt instruments through Other Comprehensive Income
        </th>
        <th style={particlarTab} rowSpan={2}>
          Equity Instruments through Other Comprehensive Income
        </th>
        <th style={particlarTab} rowSpan={2}>
          Effective portion of Cash Flow Hedges
        </th>
        <th style={particlarTab} rowSpan={2}>
          Revaluation Surplus
        </th>
        <th style={particlarTab} rowSpan={2}>
          Exchange differences on translating the financial statements of a
          foreign operation
        </th>
        <th
        style={particlarTab}
          rowSpan={2}
        >
          Money received against share warrants
        </th>
        <th style={particlarTab} rowSpan={2}>Non-controlling interest</th>
        {showTotal ? <th style={particlarTab} rowSpan={2}> Total </th> : ""}

      </tr>
      <tr style={thBgColor}>
        <th style={{}}>Capital Reserve</th>
        <th style={{}}>Securities Premium</th>
        <th style={{}}>Other Reserves (specify nature)</th>
        <th style={{}}>Retained earnings</th>
      </tr>
    </thead>
  )
}

export default TableHeader;