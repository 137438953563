import { Link, useHistory } from "react-router-dom";
import Header from "../header";
import logo from "../../assets/images/logo/Logo.svg";
import CompareFsTab from "./compareFsTabs";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ProgressBar from "@ramonak/react-progress-bar";
import Select from "react-select";
import { Submit } from "../../../Components/Button";
import arrowLeftGreen from "../../../assets/arrowLeftGreen.svg";
import { useLocation } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetProject,
  getAllIR,
  getStandaloneFS,
} from "../../../WorkSpace/Components/ApiServices/apiHandler";
import { consoleNotesData, filterOptionsData } from "../services/mockHandler";
import { fetchGroupData } from "../../../redux/actions/actionGrouping";
import { fetchBSData } from "../../../redux/actions/actionBS";
import { fetchPNLData } from "../../../redux/actions/actionPNL";
import { fetchSocieData } from "../../../redux/actions/actionSocie";
import { getPrepareConsole, getProjects } from "../services/apiHandler";
import { fetchPCData } from "../../../redux/actions/actionPreset";
import { CircularProgress } from "@material-ui/core";
import { fetchStandaloneGroupData } from "../../../redux/actions/actionStandaloneGroping";

const header = {
  height: "80px",
  width: "100%",
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  padding: "0rem 1rem",
  borderRadius: "3px",
  marginBottom: "3rem",
  marginTop: "40px",
};
const name = {
  fontSize: "15px",
  fontWeight: "500",
  paddingLeft: "1%",
  paddingRight: "2%",
};
const button = {
  padding: "8px",
  width: "10rem",
  // marginLeft:'auto'
};
const button3 = {
  padding: "8px",
  width: "15rem",
  marginLeft: "283px",
};
const button2 = {
  padding: "8px",
  width: "15em",
  marginLeft: "auto",
};
const backNav = {
  fontSize: "1.2rem",
  fontWeight: "600",
  color: "#03565A",
  cursor: "pointer",
};

const mainTag = {
  fontSize: "1.15rem",
  fontWeight: "700",
};
const line = {
  height: "70%",
  width: "2px",
  backgroundColor: "rgb(229, 229, 229)",
  marginRight: "2%",
};
const main = {
  padding: "2rem 4rem",
  backgroundColor: "rgb(229, 229, 229)",
  minHeight: "calc(100vh - 80px)",
  minWidth: "1045px",
};

const Comparefs = () => {
  let history = useHistory();
  const location = useLocation();
  let a = location.pathname;
  let b = a.split("/");
  const [consoldata, setConsolData] = useState("");
  const [projectPreset, setProjectPreset] = useState(null);
  const [projectsData, setProjectsData] = useState([]);
  const [allcheckdata, setConsolidate] = useState([]);
  const [selectedPresetProject, setSelectedPresetProject] = useState(null);
  //   const AllCheckData = useSelector((state) => state.APAReducer);
  const [filterData, setFilterData] = useState([]);
  const [notes, setNotes] = useState([]);
  const balanceSheet = useSelector((state) => state.BSReducer);
  const pnlStatement = useSelector((state) => state.PNLReducer);
  const getSocie = useSelector((state) => state.SoccieReducer);
  const groupingData = useSelector((state) => state.GroupingReducer);
  const standaloneGroupingData = useSelector((state)=>state.StandaloneGroupingReducer);
  const dispatch = useDispatch();
  const currentCompProjectId = selectedPresetProject?.project_id?selectedPresetProject?.project_id:projectPreset;
  const [allIrList, setAllIr] = useState([]);
  const fetchPrepareConsole = useSelector((state) => state.PCReducer);
  const [currentProfit, setCurrentProfit] = useState("");
  const [currentYearProfit, setCurrentYearProfit] = useState("");
  // const [tab, setTab] = useState("bs");
  const [comProfit, setComProfit] = useState("");
  const [comYearProfit, setComYearProfit] = useState("");
  const [allProject, setAllProject] = useState([]);
  const [bsData,setBSData] = useState("");
  const [pnlData,setPNLData] = useState("");
  const [bsNotes,setBSNotes] = useState([]);
  
  useEffect(() => {
    // dispatch(fetchPCData(id.id));
    AllIrData();
    fetchPreConsole();
    getCompanyData();
    AllProject();
  }, []);
  const AllProject = async () => {
    const res = await GetProject();
    if (res.status === 200) {
      setAllProject(res.data.project);
    }
  };
  const AllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIr(res.data.fetchIR);
    }
  };
  const FindIr = (ir_id) => {
    const IrData = allIrList.filter((data) => data._id === ir_id);
    return IrData;
  };
  useEffect(() => {
    (async () => {
      if (allcheckdata && allIrList) {
        fetchStandaloneData();
        if (filterData.length === 0) {
          const filter_res = await filterOptionsData();
          setFilterData(filter_res.data);
        }
        if (notes.length === 0) {
          const notes_data = await consoleNotesData();
          setNotes(notes_data.data);
        }
        if (allcheckdata.length > 0) {
            const project_ids = allcheckdata.map((data) => data.project_id);
          const browse_fs = allcheckdata.map((data) => FindIr(data.Ir_id));
          const browse_fs_ids = browse_fs.map(
            (data) => data[0]?.browse_control_dateFS
          );
          if (
            !groupingData.isSuccess ||
            groupingData.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchGroupData(project_ids, browse_fs_ids, currentCompProjectId)
            );
            dispatch(
              fetchStandaloneGroupData(project_ids, browse_fs_ids, b[2])
            );
          }
          if (
            !balanceSheet.isSuccess ||
            balanceSheet.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchBSData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
          if (
            !pnlStatement.isSuccess ||
            pnlStatement.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchPNLData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
          const payload = {
            project_ids: project_ids,
            browse_fs_ids: browse_fs_ids,
            current_comp_project_id: currentCompProjectId,
          };
          if (
            !getSocie.isSuccess ||
            getSocie.payload !== JSON.stringify(payload)
          ) {
            dispatch(fetchSocieData(payload));
          }

          if (
            !standaloneGroupingData.isSuccess ||
            standaloneGroupingData.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchStandaloneGroupData(project_ids, browse_fs_ids, b[2])
            );
          }
         
            
        }
      }
    })();
  }, [allcheckdata, allIrList]);

  useEffect(() => {
    if (projectPreset) {
      getProjectName(projectPreset);
    }
  }, [projectPreset, projectsData]);

  const getCompanyData = async () => {
    try {
      const response = await getProjects();
      setProjectsData(response.data.project);
    } catch (err) {
      console.log("err", err.response);
    }
  };

  const getProjectName = async (p_id) => {
    try {
      const project = projectsData.find((data) => data._id === p_id);
      setSelectedPresetProject({
        project_name: project.project_name,
        project_id: project._id,
      });
    } catch (error) {
      console.log("error", error.message);
      return "None";
    }
  };
  const fetchPreConsole = async () => {
    try {
      const checkData = allProject.filter((det) => det._id === b[3]);
      console.log(checkData, "checkData");
      if (checkData.length > 0) {
        console.log("standalone");
      } else {
        const response = await getPrepareConsole(b[3]);
        if (response.status === 200) {
          dispatch(fetchPCData(b[3]));
          setConsolData(response.data.getOnePreCompany[0]);
          setProjectPreset(response.data.getOnePreCompany[0]?.upload_preset);
          setConsolidate(response.data.getOnePreCompany[0]?.consolidation);
        }
        console.log("console", response);
      }
    } catch (err) {
      console.log("err", err.response);
    }
  };
  const fetchStandaloneData=async()=>{
    const res =await getStandaloneFS(b[2]);
    if (res.status === 200) {
        console.log(res.data.fs_grp.grp,"dddddddd");
        setBSData(res.data.fs_grp.bs);
        setPNLData(res.data.fs_grp.pnl);
        setBSNotes(res.data.fs_grp.grp);
    }
  }

  return (
    <>
      <div className="tap-top">
        <i data-feather="chevrons-up"></i>
      </div>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          {/* Page Sidebar Start */}
          <div className="sidebar-wrapper">
            <div>
              <div className="logo-wrapper">
                <Link href="index.html">
                  <img className="img-fluid for-light" src={logo} alt="" />
                </Link>
              </div>
              <div className="logo-icon-wrapper">
                <Link href="index.html">
                  {/* <img className="img-fluid" src={logo_icon} alt="" /> */}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={main}>
          <div style={header}>
            <div style={name}>
              <div
                style={backNav}
                onClick={() => {
                  history.push(`/preview`);
                }}
              >
                <img src={arrowLeftGreen} style={{ marginRight: "10px" }} />
              </div>
            </div>
            <div style={line}></div>
            <div style={mainTag}>
              <input
                placeholder="Consolidated Financial Statement"
                style={{ border: "0", width: "20rem" }}
                // defaultValue={headerdata}
                // onChange={(e) => handleHeader(e)}
              />
            </div>
            <div style={button3} title="units conversion of the tb amount">
              <Select
                placeholder=""
                as="select"
                // options={units}
                isClearable
                // onChange={(e) => {
                //   setPrecision(e.value);
                // }}
                defaultInputValue="Units"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "6px",
                  cursor: "pointer",
                  colors: {
                    ...theme.colors,
                    backgroundColor: "white",
                    primary25: "#03565a98",
                    primary: "#03565A",
                  },
                  spacing: {
                    ...theme.spacing,
                    controlHeight: 50,
                  },
                })}
              />
            </div>
            <div style={button2}>
              <Select
                placeholder="0"
                as="select"
                // options={decimal}
                isClearable
                // onChange={(e) => {
                //   setFloat(e.value);
                // }}
                defaultInputValue="2"
              />
            </div>
            <div style={button}>
              <div>
                <Submit value="Save to preset" variant="secondary" sm />
              </div>
            </div>
            <div style={button}>
              <div>
                <Submit value="Export Fs" sm />
              </div>
            </div>
          </div>
          <div id="report">
          {balanceSheet.loading ? (
                <CircularProgress disableShrink />
              ) : balanceSheet.isSuccess ? (
                <div class="card">
                  <div style={{ maxHeight: "77vh", overflowY: "scroll" }}>
                    <div style={{ minHeight: "77vh", width: "100vw" }}>
                      {balanceSheet.isSuccess ? (
                       <CompareFsTab
                       balanceSheetData={balanceSheet}
                       groupingData={groupingData}
                       currentCompany={consoldata}
                       // headingData={allcheckdata.map((data) => data.company)}
                       notes={notes}
                       currentCompProjectId={currentCompProjectId}
                       subsiaryData={allcheckdata}
                       browsFs={[]}
                       pnlStatementData={pnlStatement}
                       allcheckdata={allcheckdata}
                       consoleNotes={notes}
                       setCurrentProfit={setCurrentProfit}
                       setCurrentYearProfit={setCurrentYearProfit}
                       setComYearProfit={setComYearProfit}
                       currentProfit={currentProfit}
                       setComProfit={setComProfit}
                       comProfit={comProfit}
                       currentYearProfit={currentYearProfit}
                       comYearProfit={comYearProfit}
                       consoleData={consoldata}
                       FindIr={FindIr}
                       bsData={bsData}
                       pnlData={pnlData}
                       bsNotes={bsNotes}
                       standaloneGroupingData={standaloneGroupingData}
                     />
                      ) : (
                        "-------"
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <p className="text-danger">{balanceSheet.error_message}</p>
              )}
          </div>
          <div style={{ marginTop: "20px" }}>
            <CKEditor
              style={{ minHeight: "70vh", zIndex: "0" }}
              editor={ClassicEditor}
              // data={fotterdata}
              onReady={(editor) => {}}
            />
            <h4 style={{ marginLeft: "2rem" }}>
              <strong></strong>
            </h4>
          </div>
          <div
            style={{
              position: "sticky",
              // display: "flex",
              bottom: "0rem",
              width: "100%",
              padding: "5px 20px",
              backgroundColor: "var(--clr-font-light)",
            }}
          >
            <ProgressBar
              bgColor="#D96B62"
              //   completed={finalData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Comparefs;
