const WSTypes = {
    WS_NAME:"WS_NAME",
    WS_IMPORT_COMPANY:"WS_IMPORT_COMPANY",
    WS_IMPORT_REGISTER:"WS_IMPORT_REGISTER",
    WS_IMPORT_PRESET:"WS_IMPORT_PRESET",
    WS_DATE:"WS_DATE",

    WS_ACCORDION:"WS_ACCORDION",
}

const Initial_state = {
    isWSName: false,
    isWSImportCompany: false,
    isWSImportRegister: false,
    isWSImportPreset: false,
    isWSDate: false,

    isWSAccordion : false,
}

const WSSettingReducer = (state=Initial_state, action) => {
    switch(action.type){
        case WSTypes.WS_NAME: 
            return {
                ...state,
                isWSName: action.payload,
            }

        case WSTypes.WS_ACCORDION: 
            return {
                ...state,
                isWSAccordion: action.payload,
            }

        case WSTypes.WS_IMPORT_COMPANY: 
            return {
                ...state,
                isWSImportCompany: action.payload,
            }
        
        case WSTypes.WS_IMPORT_REGISTER: 
            return {
                ...state,
                isWSImportRegister: action.payload,
            }
        case WSTypes.WS_IMPORT_PRESET: 
            return {
                ...state,
                isWSImportPreset: action.payload,
            }
        case WSTypes.WS_DATE: 
            return {
                ...state,
                isWSDate: action.payload,
            }
        default:
            return state;
    }
};

export default WSSettingReducer;