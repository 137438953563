export const screenStyle = {
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: "999",
    backgroundColor: "#00000036",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
   
  };
  export const containerStyle = {
    width: "50%",
    minHeight: "40vh",
    borderRadius: "10px",
    maxHeight: "70vh",
    minWidth: "560px",
    padding: "2rem",
    marginBottom:"210px",
    zIndex: "15",
    backgroundColor: "white",
  };
  
  export const headStyle = {
    display: "flex",
  };
  
  export const headerStyle = {
    fontSize: "2rem",
    fontWeight: "600",
    textAllign: "center",
  };
  export const paraStyle = {
    fontSize: "14px",
    fontWeight: "400",
    marginTop: "0px",
    color: "#696F79",
  };
  
  export const footSelect = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  };
  
  export const inputGrid = {
    display: "grid",
    gridTemplateColumns: "32% 32% 32%",
    gridGap: "1rem",
    marginTop: "1rem",
    // overflowX:'scroll',
    // overflowY:'none'
  };
  
  export const para2 = {
    fontSize: "14px",
    fontWeight: "600",
    margin: "0 0 10px 0",
    color: "var(--clr-font-mid)",
  };
  
  export const details = {
    maxHeight: "20rem",
    overflowY: "scroll",
  };
  
  export const input = {
    height: "48px",
    width: "100%",
    padding: "10px",
    outline: "none",
    border: "1px solid #cacacabf",
    borderRadius: "6px",
  
    ":focus-visible": {
      border: "1px solid black",
    },
  };
  
  export const span = {
    color: "rgba(207, 7, 7, 0.815) ",
  };
  