import React from 'react'

const XlxsSvgComponent = ({
    height,
    width,
    color
}) => {
    return (
        <svg 
            width={width}
            height={height} 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M23.1489 6.98389L17.2769 1"
                stroke={color} 
                stroke-width="1.5" 
                stroke-linecap="round" 
            />
            <path 
                d="M5.75 2C5.75 1.30964 6.30964 0.75 7 0.75H16.5C17.1904 0.75 17.75 1.30964 17.75 2V4.5C17.75 5.4665 18.5335 6.25 19.5 6.25H22C22.6904 6.25 23.25 6.80964 23.25 7.5V22C23.25 22.6904 22.6904 23.25 22 23.25H7C6.30964 23.25 5.75 22.6904 5.75 22V2Z" 
                stroke={color} 
                stroke-width="1.5" 
            />
            <g clipPath="url(#clip0_551_2208)">
                <rect 
                    y="9" 
                    width="16.0991" 
                    height="10.2449" 
                    rx="1" 
                    fill={color} 
                />
                <path 
                    d="M4.87703 11.4814L5.72048 12.9775H5.76065L6.61414 11.4814H8.1705L6.63422 14.0519L8.23075 16.6224H6.63422L5.76065 15.0962H5.72048L4.84691 16.6224H3.26042L4.83687 14.0519L3.31063 11.4814H4.87703ZM8.77234 16.6224V11.4814H10.168V15.4978H12.2466V16.6224H8.77234Z" 
                    fill="#F8F8F8" 
                />
            </g>
            <defs>
                <clipPath id="clip0_551_2208">
                    <rect y="9" width="16.0991" height="10.2449" rx="1" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default XlxsSvgComponent