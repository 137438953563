export const dataRegisterLabels = [
    {
        label: "Sales",
        value: 'sales'
    },
    {
        label: "Purchases",
        value: 'purchases'
    },
    {
        label: "Leases",
        value: 'lease'
    },
    {
        label: "Investments",
        value: 'investments'
    },
    {
        label: "Receivables",
        value: 'receivable'
    },
    {
        label: "Payables",
        value: 'payable'
    },
    {
        label: "Loans",
        value: 'loan'
    }
]

export const notificationCenterLabels = [
    {
        label: "Company creation",
        value: 'company creation'
    },
    {
        label: "Workspace related",
        value: 'workspace related'
    },
    {
        label: "Deletion Of Workspace",
        value: 'deletion of workspace'
    },
    {
        label: "Data set related approvals",
        value: 'data set related approvals'
    },
    {
        label: "LOGS",
        value: 'logs'
    }
]