import React, { useState } from "react";
import Sidebar from "../../Workspace2/Sidebar";
import "../../Workspace2/sidebar.css";
import Button from "@mui/material/Button";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useLocation, useParams } from "react-router";
import { addworkspaceteamMember, createWorkspaceName, getWorkspace } from "../ApiServices/apiHandler";
import WorkspaceHeader from "./WorkspaceHeader";
import swal from "sweetalert";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


const WorkspaceSetup = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const { beckFrom } = useSelector((state) => state.AllWSReducer);

  const [workspaceName, setWorkspaceName] = useState("");
  const [workspace, setWorkspace] = useState("");
  const [oneworkspace, setOneWorkspace] = useState([]);
  const [hasChanged,setHasChanged]=useState(false);

  useEffect(() => {
    getWorkspaceDetails();
  }, [])
  const handleChange = (e) => {
    const { value } = e.target;
    setWorkspaceName(value);
    setHasChanged(true);
    if(value === workspace){
      dispatch({ type: "WS_BACK_FROM_QUERY", payload: "addCompanies" });
    }else{
      dispatch({ type: "WS_BACK_FROM_QUERY", payload: null });
    }
  };
  const handleSave = async () => {
    if (workspaceName  === "" && workspace === "" ) {
      return swal("Warning","Please Enter Workspace Name", "warning")
    }
     const data = {
        workspaceName: workspaceName ? workspaceName : workspace,
        steps:"step2",
        has_published:"draft",
        status:oneworkspace.status
      };
    if(beckFrom === "addCompanies"){
      history.push(`/addCompanies/${id}`);
    }else if(user.responsibilities==="approver"){
      history.push(`/addCompanies/${id}`);
    }else if(hasChanged===false){
      history.push(`/addCompanies/${id}`);
    }
    else{
      createWorkspaceName(id, data).then(res => {
        const datas = {
        workspace_id:id,
        user_id:user._id
        };
        addworkspaceteamMember(datas).then(res => { 
        history.push(`/addCompanies/${id}`);
      }).catch(error => {
        swal("Error", "Workspace Team creation Failed", "error")
      });
      }).catch(error => {
        swal("Error", error.response.data.error || "Please Enter Workspace Name", "error")
      });
    }
  };

  const getWorkspaceDetails = async ()=>{
    const res =await getWorkspace(id);
    if (res.status === 200) {
      setWorkspace(res.data.getworkspace[0].workspace_name)
      setOneWorkspace(res.data.getworkspace[0]);
    }
  }

  return (
    <>
      <div className="row mr-0 ml-0">
        <div className="col-3 bg-white">
          <Sidebar activePage = "2" id={id}/>
        </div>
        <div className="col-9 main-workspace-content">
          <WorkspaceHeader currentStep = "2" title="Create your workspace" />
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="wp-setup-form-label"> Name of your workspace{" "}
              <span style={{ color: "red" }}>*</span></div>
              
              <div className="inputbox">
                <input
                  style={{ height: "50px" }}
                  className="wp-setup-form-input"
                  type="text"
                  disabled={user.responsibilities==="approver"||oneworkspace.status==="waiting"}
                  defaultValue={workspace ? workspace : workspaceName}
                  onChange={handleChange}
                />
              </div>               
            </div>
          </div>
          <div className="blankspace80"></div>
          <div className="row mt-5">
            
            <div className="col-md-6">
              <div
                className="backicon"
                onClick={() => history.push(`/workspacelayout/${id}`)}
                style={{ cursor: "pointer" }}
              >
                <ArrowBackIcon />
                <div>Back to the Previous step </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="nextbtn-v2 text-right">
              {workspaceName || workspace ? (
                  <Button
                    className="datebtn font-sans"
                    style={{
                      backgroundColor: "#03565A",
                      color: "white",
                      textTransform: "none",
                    }}
                    onClick={handleSave}
                  >
                    Next Step
                  </Button>
                ) : (
                  <Button
                    className="datebtn"
                    style={{
                      backgroundColor: "#B3D9D9",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    Next Step
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspaceSetup;
