import { Box, Button, FormControl, MenuItem, Modal, Select } from '@mui/material';
import React, { useEffect, useState } from "react";
import { getApproverForDeleteWorkspace,addDeleteDraftWorkspace,getSingleDeleteWorkspacedraft} from '../ApiServices/apiHandler';
import swal from 'sweetalert';
import { socket } from '../../../services/socket/socket';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

const SendApproval = ({user,approverId,openApproveModal,handleCloseApprovalModal,handleApproverChange,WID,WSPDATA}) => {
    const [approvalIds, setApprovalIds] = useState([]);
    const [workspaceData,setWorkspaceData] = useState([]);
    const [openCancelModal,setOpenCancelModal]=useState(false);
    useEffect(() => {
        if (WID) {
          getApproverIds();
          getsingleWorkspace();
        }
      }, [WID]);  

      const handleOpenCancelModal = () => setOpenCancelModal(true);
      const handleCloseCancelModal = () => setOpenCancelModal(false);

    const getApproverIds = async()=>{
        const parent_id = user.parent_id;
        const workspace_id = WID;
        getApproverForDeleteWorkspace(parent_id,workspace_id)
            .then(response => {
              setApprovalIds(response.data.result);
            })
            .catch(error => {
              console.error('Error:', error);
            });
      }

  const handleSendApproval = () => {
    let data={};
    if (workspaceData[0]?._id){
      data={
        draft_id:workspaceData[0]?._id,
        approver_id:approverId,
        status:"waiting",
        cancel_reason:""
      }
    }else{
      data={
        workspace_id:WID,
        workspace_name:WSPDATA.workspace_name,
        approver_id:approverId,
        status:"waiting",
        cancel_reason:""
      }
    }
     
    addDeleteDraftWorkspace(data)
      .then(response => {
        swal("Success", "Approval sent successfully", "success");
        getsingleWorkspace();
        handleCloseApprovalModal();
        socket.emit("add-notification", {
          receiver_id: approverId,
          description: "Deletion of workspace",
          type:"send approval",
          path:"/Notification"
        });
        socket.emit('get-notification');
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const getsingleWorkspace = async()=>{
    const workspace_id = WID;
    getSingleDeleteWorkspacedraft(workspace_id)
        .then(response => {
          setWorkspaceData(response.data.response);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }
  return (
    <div>
      <Modal
        open={openApproveModal}
        onClose={handleCloseApprovalModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="d-flex flex-row-reverse ml-5" style={{marginTop:"-30px"}}>
          <Button  onClick={handleCloseApprovalModal} className='m-0 text-capitalize' style={{color:"#03565a"}}>Close</Button>
        </div>
          <FormControl fullWidth>
            <Select
              labelId="approver-select-label"
              id="approver-select"
              value={approverId}
              onChange={handleApproverChange}
              disabled={workspaceData&&workspaceData.length>0&&(workspaceData[0].status === "waiting" ||
              workspaceData[0].status === "approved")}
            >
              {approvalIds.length > 0 && approvalIds.map((data) =>
                <MenuItem key={data._id} value={data._id}>{data.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <div className="d-flex flex-row justify-content-center">
            <Button
              className="mt-3 w-50 h-25 text-capitalize custom-common-theme-bg-color text-white"
              disabled={workspaceData&&workspaceData.length>0&&(workspaceData[0].status === "waiting" ||
              workspaceData[0].status === "approved")}
              onClick={()=>handleSendApproval()}
            >
              {workspaceData && workspaceData.length > 0
              ? workspaceData[0].status === "waiting"
                ? "Sent for Approval"
                : workspaceData[0].status === "approved"
                ? "Approved"
                : "Send Approval"
              : "Send Approval"}
            </Button>
            <Button className='custom-common-theme-bg-color font-sans text-white w-50 h-25 mt-3 ml-2 text-capitalize'
            onClick={handleOpenCancelModal}
              >Remark
            </Button> 
          </div>
        </Box>
      </Modal>
      <Modal
     open={openCancelModal}
     onClose={handleCloseCancelModal}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
     >
     <Box sx={style}>
         <div style={{maxHeight:"150px",overflowY:"auto"}}>
          {workspaceData&&workspaceData.length>0&&(workspaceData[0].cancel_reason)?
           <p>{workspaceData&&workspaceData.length>0&&(workspaceData[0].cancel_reason)}</p>:
           <center> There are no remarks </center>}
         </div>
         <div className="d-flex  justify-content-center mt-3 ">
           <Button
             style={{
             backgroundColor: "#03565a",
             color: "white",
             textTransform: "none",
             width:"50%",
             marginTop:"10px"
             }}
             onClick={handleCloseCancelModal}
           >
             Close
           </Button>
       </div>
     </Box>
    </Modal>
    </div>
  );
};

export default SendApproval;
