import React from 'react';
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const NewTeammateDetail = ({
    newUserData,
    setNewUserData,
    onClick,
    childUserCount,
    users
}) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'unrestrictedaccess') {
            setNewUserData(prev => ({ ...prev, [name]: !prev.unrestrictedaccess }));
        } else {
            setNewUserData(prev => ({ ...prev, [name]: value }));
        }
    };

    return (
        <>
            <div className="mt-3">
                <label className="mt-2  font-size-12 custom-font-500 font-sans text-dark">
                    Mail ID <span className="text-danger">*</span>
                </label>
                <input
                    type="text"
                    className='radius-8px border-gray-300'
                    name="email"
                    value={newUserData.email}
                    onChange={handleInputChange}
                />
                <label className="mt-2 font-size-12 custom-font-500 font-sans text-dark">
                    User Name <span className="text-danger">*</span>
                </label>
                <input
                    type="text"
                    className='radius-8px border-gray-300'
                    name="name"
                    value={newUserData.name}
                    onChange={handleInputChange}
                />
                <label className="mt-2 font-size-12 custom-font-500 font-sans text-dark">
                    Role: <span className="text-danger">*</span>
                </label>
                <input
                    type="text"
                    className='radius-8px border-gray-300'
                    name="role"
                    value={newUserData.role}
                    onChange={handleInputChange}
                />
            </div>
            <div className="mt-3 d-flex align-items-center" >
                <label className='mb-0 font-size-12 custom-font-500 font-sans text-dark' > Unrestricted Access: </label>
                <input
                    className='m-0 ml-2'
                    type='checkbox'
                    name='unrestrictedaccess'
                    checked={newUserData.unrestrictedaccess}
                    onChange={handleInputChange}
                />
            </div>
            {
                !newUserData.unrestrictedaccess &&
                <div className=" mt-1" >
                    <FormControl>
                        <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            className="font-size-12 custom-font-500 font-sans text-dark mt-3"
                        >
                            Responsibilities:
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="responsibilities"
                            className="p-1"
                            value={newUserData.responsibilities}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel
                                className="font-size-16 custom-font-500 font-sans"
                                value="preparer"
                                control={
                                    <Radio
                                        sx={{
                                            color: "#D6D6D6",
                                            "&.Mui-checked": { color: "#03565A" },
                                        }}
                                    />
                                }
                                checked={newUserData.responsibilities === "preparer"}
                                label="Preparer"
                            />
                            <FormControlLabel
                                className="font-size-16 custom-font-500 font-sans"
                                value="approver"
                                control={
                                    <Radio
                                        sx={{
                                            color: "#D6D6D6",
                                            "&.Mui-checked": { color: "#03565A" },
                                        }}
                                    />
                                }
                                checked={newUserData.responsibilities === "approver"}
                                label="Approver"
                            />
                            {/* <FormControlLabel
                            className="font-size-16 custom-font-500 font-sans"
                            value="Unrestricted Access"
                            control={
                                <Radio
                                    sx={{
                                        color: "#D6D6D6",
                                        "&.Mui-checked": { color: "#03565A" },
                                    }}
                                />
                            }
                            label="Unrestricted Access"
                        /> */}
                        </RadioGroup>
                    </FormControl>
                </div>
            }
            <div className="d-flex justify-content-end">
                {childUserCount >= users ? (<Button
                    variant="contained"
                    className="font-size-16 custom-font-500 text-capitalize font-sans radius-10px custom-common-theme-bg-color"
                    style={{
                        width: "330px",
                        height: "55px",
                    }}
                    onClick={onClick}
                    disabled
                >
                    { newUserData.unrestrictedaccess ? "Create" : "Next"}
                </Button>)
                :
                (<Button
                    variant="contained"
                    className="font-size-16 custom-font-500 text-capitalize font-sans radius-10px custom-common-theme-bg-color"
                    style={{
                        width: "330px",
                        height: "55px",
                    }}
                    onClick={onClick}
                >
                    { newUserData.unrestrictedaccess ? "Create" : "Next"}
                </Button>)}
                
            </div>
        </>
    )
}

export default NewTeammateDetail