import React from "react";
import "./../Workspace2/sidebar.css";
import logov2 from "../../assets/signin.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useHistory } from "react-router";
import { getWorkspace } from "../Components/ApiServices/apiHandler";
import { useState } from "react";
import { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";

const Sidebar = (props) => {
  let history = useHistory();
  const [workspace, setWorkspace] = useState("");

  useEffect(() => {
    if (typeof props?.id !== 'undefined' && props?.id !== null) {
      getWorkspaceDetails();
    }

  }, []);

  const stepChange = (field) => {
    if (typeof props?.id == 'undefined' && props?.id == null) {
      console.log("not work")
      return false;
    }
    if (field === "getting_started") {
      history.push(`/workspacelayout`);
    } else if (field === "workspace_setup") {
      history.push(`/workspacesetup/${props?.id}`);
    } else if (field === "add_companies") {
      history.push(`/addCompanies/${props?.id}`);
    } else if (field === "import_register") {
      history.push(`/importregister/${props?.id}`);
    } else if (field === "import_presets") {
      history.push(`/importPreset/${props?.id}`);
    }
     else if (field === "invite_team") {
      history.push(`/inviteteammember/${props?.id}`);
    }
  };
 
  const getWorkspaceDetails = async () => {
    const res = await getWorkspace(props?.id);
    console.log(res);
    if (res.status === 200) {
      setWorkspace(res?.data?.getworkspace[0]);
    }
  };
  const DashbordPage = () => {
    history.push(`/dashboard`)
  }
  return (
    <>
      <div className="sidebar_v2">
        <div>
          <img className="ml-4"
            src={logov2}
            alt="logo"
            onClick={DashbordPage}
            style={{ width: '170px', marginTop: "25px", }}
          />
        </div>
        <div className="workspace-v2 mt-4">
          {workspace?.steps_status === "step1" ||
            workspace?.steps_status === "step2" ||
            workspace?.steps_status === "step3" ||
            workspace?.steps_status === "step4" ||
            workspace?.steps_status === "step5" ||
            workspace?.steps_status === "step6" ? (
            <div className="circle-v2"  style={{backgroundColor:"#03565A"}}>
              {props.activePage == 1 ? (
                 <div className="sidbar-active">
                 <div className="active-rounded">
                </div>
              </div>
              ) : (
                <span>
                  <CheckIcon style={{ color: "white" }} />
                </span>
              )}
            </div>
          ) : (
            <div className="circle-v2 ">
              {props.activePage == 1 ?  (
                  <div className="sidbar-active">
                  <div className="active-rounded">
                 </div>
               </div>
              ) : (
                "1"
              )}
            </div>
          )}

          <div
            className="layout-v2"
            onClick={() => stepChange("getting_started")}
            style={{ cursor: "pointer" }}
          >
            Getting started  
          </div>
        </div>

        <div className="vl-v2 "></div>
        <div style={{ marginTop: "5px" }} className="vl-v2  "></div>

 
        <div className="workspace-v2">
          {workspace.steps_status === "step2" ||
            workspace.steps_status === "step3" ||
            workspace.steps_status === "step4" ||
            workspace.steps_status === "step5" ||
            workspace.steps_status === "step6" ? (
            <div className="circle-v2 " style={{backgroundColor:"#03565A"}}>
              {props.activePage == 2 ? (
                  <div className="sidbar-active">
                  <div className="active-rounded">
                 </div>
               </div>
              ) : (
                <CheckIcon style={{ color: "white" }} />
              )}
            </div>
          ) : (
            <div className="circle-v2 ">
              {props.activePage == 2 ? (
                 <div className="sidbar-active">
                 <div className="active-rounded">
                </div>
              </div>
              ) : (
                "2"
              )}
            </div>
          )}
          <div
            className="layout-v2"
            onClick={() => stepChange("workspace_setup")}
            style={{ cursor: "pointer" }}
          >
            Workspace setup
          </div>
        </div>

        <div className="vl-v2 "></div>
        <div style={{ marginTop: "7px" }} className="vl-v2  "></div>
    
        <div className="workspace-v2">
          {workspace.steps_status === "step3" ||
            workspace.steps_status === "step4" ||
            workspace.steps_status === "step5" ||
            workspace.steps_status === "step6" ? (
            <div className="circle-v2 "  style={{backgroundColor:"#03565A"}}  >
              {props.activePage == 3 ? (
                 <div className="sidbar-active">
                 <div className="active-rounded">
                </div>
              </div>
              ) : (
                <CheckIcon style={{ color: "white" }} />
              )}
            </div>
          ) : (
            <div className="circle-v2 ">
              {props.activePage == 3 ? (
                  <div className="sidbar-active">
                  <div className="active-rounded">
                 </div>
               </div>
              ) : (
                "3"
              )}
            </div>
          )}
          <div
            className="layout-v2"
            onClick={() => stepChange("add_companies")}
            style={{ cursor: "pointer" }}
          >
            Add companies
          </div>
        </div>

        <div className="vl-v2 "></div>
        <div style={{ marginTop: "7px" }} className="vl-v2"></div>
     
        <div className="workspace-v2">
          {workspace.steps_status === "step4" ||
            workspace.steps_status === "step5" ||
            workspace.steps_status === "step6" ? (
            <div className="circle-v2 "  style={{backgroundColor:"#03565A"}}>
              {props.activePage == 4 ? (
                 <div className="sidbar-active">
                 <div className="active-rounded">
                </div>
              </div>
              ) : (
                <CheckIcon style={{ color: "white" }} />
              )}
            </div>
          ) : (
            <div className="circle-v2 ">
              {props.activePage == 4 ? (
                  <div className="sidbar-active">
                  <div className="active-rounded">
                 </div>
               </div>
              ) : (
                "4"
              )}
            </div>
          )}

          <div
            className="layout-v2"
            onClick={() => stepChange("import_register")}
            style={{ cursor: "pointer" }}
          >
            Import registers
          </div>
        </div>

        <div className="vl-v2 "></div>
        <div style={{ marginTop: "7px" }} className="vl-v2"></div>

        <div className="workspace-v2">
          {workspace.steps_status === "step5" ||
            workspace.steps_status === "step6" ? (
            <div className="circle-v2 "  style={{backgroundColor:"#03565A"}}>
              {props.activePage == 5 ? (
                  <div className="sidbar-active">
                  <div className="active-rounded">
                 </div>
               </div>
              ) : (
                <CheckIcon style={{ color: "white" }} />
              )}
            </div>
          ) : (
            <div className="circle-v2 ">
              {props.activePage == 5 ? (
                  <div className="sidbar-active">
                  <div className="active-rounded">
                 </div>
               </div>
              ) : (
                "5"
              )}
            </div>
          )}

          <div
            className="layout-v2"
            onClick={() => stepChange("import_presets")}
            style={{ cursor: "pointer" }}
          >
            Import presets
          </div>
        </div>

        <div className="vl-v2 "></div>
        <div style={{ marginTop: "7px" }} className="vl-v2"></div>
        
        <div className="workspace-v2">
          {workspace.steps_status === "step6" ? (
            <div className="circle-v2 "  style={{backgroundColor:"#03565A"}}>
              {props.activePage == 6 ? (
              <div className="sidbar-active">
              <div className="active-rounded">
             </div>
           </div>
              ) : (
                <CheckIcon style={{ color: "" }} />
              )}
            </div>
          ) : (
            <div className="circle-v2 ">
              {props.activePage == 6 ? (
               <div className="sidbar-active">
               <div className="active-rounded">
              </div>
            </div>
              ) : (
                "6"
              )}
            </div>
          )}

          <div
            className="layout-v2"
            onClick={() => stepChange("invite_team")}
            style={{ cursor: "pointer" }}
          >
            Invite team members
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Sidebar;
