import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React, { useState } from "react";
import NewConsoleBalanceSheet from "../consoleTabs/newConsoleBalanceSheet";
import NewConsolePnlStatement from "../consoleTabs/newConsolePnlStatement";
import NewConsoleSoccie from "../Socie/newConsoleSoccie";
import AdjustmentConsole from "../Adjustment/adjustment";
import { Tabs, Tab, } from "react-bootstrap";
import ConsoleBalanceNotes from "../consoleTabs/ConsoleBalanceNotes";
import ConsolePnlNotes from "../consoleTabs/ConsolePnlNotes";

function BSModalView(props) {
  const {
    consoleData,
    allcheckdata,
    currentCompProjectId,
    consoleNotes,
    balanceSheetData,
    pnlStatementData,
    groupingData,
    id,
    allAssociateCheckData,
    associateStatement,
    CompanyName,
    FindIr
  } = props;
  // const [rows, setRows] = useState([]);
  const [currentProfit, setCurrentProfit] = useState("");
  const [currentYearProfit, setCurrentYearProfit] = useState("");
  // const [tab, setTab] = useState("bs");
  const [comProfit, setComProfit] = useState("");
  const [comYearProfit, setComYearProfit] = useState("");
  const [key, setKey] = useState("Balance Sheets");

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const [value, setValue] = React.useState(0);
  // const [checkFsData, setCheckData] = useState(0);
  const [recentTab, setCurrentTab] = useState("");
  const [tabCheck, setTab] = useState("");

  return (
    <div style={{ width: "100%" }}>
      <Tabs
        defaultActiveKey="Balance Sheets"
        id="uncontrolled-tab-example"
          activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="Balance Sheets" title="Balance Sheets">
          <div
            style={{
              minHeight: "100vh",
              backgroundColor: "white",
            }}
          >
            <NewConsoleBalanceSheet
              setTempValue={setValue}
              balanceSheetData={balanceSheetData}
              groupingData={groupingData}
              currentCompany={consoleData}
              // headingData={allcheckdata.map((data) => data[0].company)}
              notes={consoleNotes}
              currentCompProjectId={currentCompProjectId}
              subsiaryData={allcheckdata}
              browsFs={[]}
              setKey={setKey}
              setCurrentTab={setCurrentTab}
              CompanyName={CompanyName}
            />
          </div>
        </Tab>
        <Tab eventKey="P&L Statement1" title="P&L Statement">
          <div
            style={{
              minHeight: "100vh",
              backgroundColor: "white",
            }}
          >
            <NewConsolePnlStatement
              setTempValue={setValue}
              pnlStatementData={pnlStatementData}
              currentCompany={consoleData}
              // headingData={allcheckdata.map((data) => data[0].company)}
              currentCompProjectId={currentCompProjectId}
              subsiaryData={allcheckdata}
              consoleNotes={consoleNotes}
              groupingData={groupingData}
              setCurrentProfit={setCurrentProfit}
              setCurrentYearProfit={setCurrentYearProfit}
              setComYearProfit={setComYearProfit}
              currentProfit={currentProfit}
              setComProfit={setComProfit}
              setTab={setTab}
              setKey={setKey}
              allAssociateCheckData={allAssociateCheckData}
              associateStatement={associateStatement}
              CompanyName={CompanyName}
            />
          </div>
        </Tab>
       
        <Tab eventKey="P&L Statement3" title="SOCIE">
          <div
            style={{
              minHeight: "100vh",
              backgroundColor: "white",
            }}
          >
            <NewConsoleSoccie
              allcheckdata={allcheckdata}
              subsiaryData={allcheckdata}
              currentCompProjectId={currentCompProjectId}
              currentProfit={currentProfit}
              comProfit={comProfit}
              currentYearProfit={currentYearProfit}
              comYearProfit={comYearProfit}
              currentCompany={consoleData}
              pnlStatementData={pnlStatementData}
              // headingData={allcheckdata.map((data) => data.company)}
              groupingData={groupingData}
              consoleNotes={consoleNotes}
            />
          </div>
        </Tab>
        <Tab eventKey="Notes" title="Balance Sheet Notes">
          <div
            style={{
              minHeight: "100vh",
              backgroundColor: "white",
            }}
          >
            <ConsoleBalanceNotes
              consoleNotes={consoleNotes}
              balanceSheetData={balanceSheetData}
              groupingData={groupingData}
              consoleData={consoleData}
              allcheckdata={allcheckdata}
              tab={recentTab}
              pnlStatementData={pnlStatementData}
              currentCompProjectId={currentCompProjectId}
              checkKey={value}
              subsiaryData={allcheckdata}
              id={id}
              FindIr={FindIr}
              CompanyName={CompanyName}
            />
          </div>
        </Tab>
        <Tab eventKey="Notes2" title="PNL Statement Notes">
          <div
            style={{
              minHeight: "100vh",
              backgroundColor: "white",
            }}
          >
            <ConsolePnlNotes
              pnlStatementData={pnlStatementData}
              consoleData={consoleData}
              currentCompProjectId={currentCompProjectId}
              subsiaryData={allcheckdata}
              consoleNotes={consoleNotes}
              groupingData={groupingData}
              setCurrentProfit={setCurrentProfit}
              setCurrentYearProfit={setCurrentYearProfit}
              setComYearProfit={setComYearProfit}
              currentProfit={currentProfit}
              setComProfit={setComProfit}
              // headingData={headingData}
              allcheckdata={allcheckdata}
              tab={tabCheck}
              checkKey={value}
              CompanyName={CompanyName}
            />
          </div>
        </Tab>
        <Tab eventKey="adjustment" title="adjustment">
          <div
            style={{
              maxHeight: "100%",
              backgroundColor: "white",
            }}
          >
            {/* <AdjustmentConsole
              currentCompany={consoleData}
              subsiaryData={allcheckdata}
              groupingData={groupingData}
              notes={consoleNotes}
              id={id}
            /> */}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default BSModalView;
