import filterOptions from "../mockData/filterOptions.json";
import consoleNotes from "../mockData/consoleNotes.json";
import permission from "../mockData/permission.json"

export const filterOptionsData = async () => {
    return {
        status: true,
        data: filterOptions
    }
}

export const consoleNotesData = async () => {
    return {
        status: true,
        data: consoleNotes
    }
}

export const allPermission = async()=>{
    return {
        status: true,
        data: permission
    }
}