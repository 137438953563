import React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Table } from 'react-bootstrap';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const KeyTable = () => {
    const [shares, setShares] = React.useState('');
    const handleChangeShares = (event) => {
        setShares(event.target.value);
    };

    // const [classification, setClassification] = React.useState('');
    // const handleChangeClassification = (event) => {
    //     setClassification(event.target.value);
    // };

    return (
        <>
            <div className=' mb-3'>
                <div className='d-flex justify-content-between w-100 mt-3'>
                    <p className='mt-0 mb-0 ml-2 font-size-16 custom-font-600'>Key Managerial Personnel</p>
                    <Button
                        variant="contained"
                        className='bg-white custom-border  custom-common-theme-text-color  font-size-16 custom-font-600 text-capitalize radius-6px font-sans'
                        style={{ width: "102px", height: "40px" }}
                    >
                        Add/Edit
                    </Button>
                </div>
                <div className='mt-2 w-100'>
                    <Card className='w-100 radius-10px' style={{ border: "1px solid #D6D6D6",height:"120px" }}>
                        <div>
                            <Table>
                                <thead style={{ backgroundColor: "#F8F8F8" }}>
                                    <tr>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter w-25' >name</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter w-25' >designation</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter w-25' >date of appointment</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter w-25' >date of appointment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ backgroundColor: "white" }}>
                                        <td className='text-left font-size-12 custom-font-600'>
                                            <input type='text' placeholder='Type...' style={{ border: "none" }} />
                                        </td>
                                        <td className='text-left font-size-12 custom-font-600'>
                                                <Select
                                                    value={shares}
                                                    onChange={handleChangeShares}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                            textAlign: "start",
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        <em className="text-secondary text-start">Select shares</em>
                                                    </MenuItem>
                                                    <MenuItem value={10}>CCPS</MenuItem>
                                                    <MenuItem value={20}>Class A</MenuItem>
                                                    <MenuItem value={30}>Class B</MenuItem>
                                                    <MenuItem value={40}>Class C</MenuItem>
                                                    <MenuItem value={50}>Others</MenuItem>
                                                </Select>
                                        </td>
                                        <td className='text-left font-size-12 custom-font-600'>
                                            {/* <FormControl className='w-100 mt-1'>
                                                <Select
                                                    value={classification}
                                                    onChange={handleChangeClassification}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        <em className='text-secondary'>Select Classification</em>
                                                    </MenuItem>
                                                    <MenuItem value={10}>Equity</MenuItem>
                                                    <MenuItem value={20}>Liability</MenuItem>
                                                    <MenuItem value={30}>Compound Financial Instrument</MenuItem>
                                                </Select>
                                            </FormControl> */}
                                        </td>
                                        <td className='text-left font-size-12 custom-font-600'>
                                                <input
                                                    style={{ height: "48px" }}
                                                    type="date"
                                                    className="form-control"
                                                />
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default KeyTable;
