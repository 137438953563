import swal from 'sweetalert';
import { referenceMappingDisclosure } from "../../../services/api/apiHandler";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useEffect } from 'react';
import { getChecklistData } from '../../../services/api/StandAlone/disclosureChecklist';

export const DisclosureNBFCTableHead = ({
    isEmpty
}) => {
    return (
        <>
            <th className="border-top-0 border-bottom-0">{isEmpty ? "" : "References"}</th>
            <th className="border-top-0 border-bottom-0">{isEmpty ? "" : "IND AS References"}</th>
            <th className="border-top-0 border-bottom-0">{isEmpty ? "" : "Checklist References"}</th>
        </>
    )
}

export const DisclosureNBFCTableBody = ({
    isEmpty,
    refData,
    isReference,
    keyValue,
    index,
    sub_index
}) => {
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");
    const dispatch = useDispatch();
    const history = useHistory();
    const reduxDisclosureFilterData = useSelector((initialState) => initialState.DisclosureReducer.filterDisclosureData);
    const reduxChecklistData = useSelector((initialState) => initialState.DisclosureChecklistReducer.disclosureChecklistData);

    useEffect(() => {
        if (reduxChecklistData.length <= 0) {
            getChecklistData(project_id, tb_id).then(response => {
                dispatch({ type: "DISCLOSURE_CHECKLIST_DATA", payload: response?.data?.data });
            }).catch(error => {
                swal("Error", error, "error");
            });
        }
    }, [reduxChecklistData])

    const HandleReference = (event) => {
        const { value } = event.target;
        referenceMappingDisclosure({
            project_id,
            value,
            index,
            sub_index,
            key: keyValue
        }).then(response => {
            const disclosureData = response.data?.result?.data
            dispatch({ type: "DISCLOSURE_DATA", payload: disclosureData });
            const filterData = disclosureData.find(ele => ele?.disclosureId === reduxDisclosureFilterData?.disclosureId)
            dispatch({ type: "FILTER_DISCLOSURE_DATA", payload: filterData});
        }).catch(error => {
            swal("Error", (error.response.data?.error || "Reference Mapping Failed!"), "error")
        })
    }

    const SwitchReferenceTab = (isReference, refData) => {
        if (isReference === "ind_as_reference") {
            dispatch({ type: "IND_AS_1_FROM_BS_PNL", payload: refData[0].name });
            history.push("/library-indas-1")
        }
        if (isReference === "checklist_reference") {
            const index = reduxChecklistData.findIndex((item) => item.name.toLowerCase() === refData[0].name.toLowerCase());
            dispatch({ type: "NOTE_KEY", payload: 8 });
            dispatch({ type: "NOTE_INDEX", payload: index === -1 ? 0 : index });
        }
    }

    return (
        <>
            <td className={`${isEmpty === true ? 'custom-bg-gray-200' : 'border-top-0 border-bottom-0'}`}>
                {
                    <RenderChecklistReference
                        data={refData}
                        isReference={isReference}
                        SwitchReferenceTab={() => {
                            SwitchReferenceTab(isReference, refData)
                        }}
                    />
                }
            </td>
            <td className={`${isEmpty === true ? 'custom-bg-gray-200' : 'border-top-0 border-bottom-0'}`}>
                {isEmpty ? "" :
                    <input
                        type="checkbox"
                        value={"ind_as_reference"}
                        disabled={!refData}
                        onChange={(e) => HandleReference(e)}
                        checked={isReference === "ind_as_reference"}
                    />
                }
            </td>
            <td className={`${isEmpty === true ? 'custom-bg-gray-200' : 'border-top-0 border-bottom-0'}`}>
                {
                    isEmpty ? "" :
                        <input
                            type="checkbox"
                            value={"checklist_reference"}
                            disabled={!refData}
                            onChange={(e) => HandleReference(e)}
                            checked={isReference === "checklist_reference"}
                        />
                }
            </td>
        </>
    )
}

export const RenderChecklistReference = ({ data, isReference, SwitchReferenceTab }) => {
    if (!data || !Array.isArray(data)) return null;

    const joinedData = data
        .map(ele => ele?.reference)
        .filter(reference => reference)
        .join(', ');

    if (joinedData.length > 12) {
        return (
            <div
                className={`rtooltip font-sans cursor-pointer ${isReference ? 'hyper-link-ref' : ''}`}
                onClick={SwitchReferenceTab}
            >
                {joinedData.substring(0, 12).concat('...')}
                <span className="rtooltiptext font-sans">{joinedData}</span>
            </div>
        );
    } else {
        return <span>{joinedData}</span>;
    }
};