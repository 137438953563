const STypes = {
    S_ACTION_START:"S_ACTION_START",
    S_FETCH_DATA:"S_FETCH_DATA",
    S_FETCH_DATA_FAILED:"S_FETCH_DATA_FAILED",
    S_ADD_DATA:"S_ADD_DATA",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
    set_payload: ""
}

const StandaloneGroupingReducer = (state=Initial_state, action) => {
    switch(action.type){
        case STypes.S_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case STypes.S_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                set_payload: action.payload.set_payload
            }

        case STypes.S_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        default:
            return state;
    }
};

export default StandaloneGroupingReducer;