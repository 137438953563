import React from "react";
import { Avatar, Badge, Box, Button, Modal } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router";
import { socket } from '../../../services/socket/socket';

const style = {
  position: "absolute",
  top: "55%",
  left: "85%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 560,
  bgcolor: "background.paper",
  border: "2px solid #03565A",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const PushNotification = ({
  openModal,
  handleCloseModal,
  notifications,
  userName,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  const getNotificationsIds = () => {
    const ids = notifications.map(notification => notification._id);
    return ids;
  };
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div>
              <div className="d-flex justify-content-between">
                    <div>
                        <h4>Notifications</h4>
                    </div>
                    <div>
                        <Button
                          className="custom-common-theme-bg-color text-white text-capitalize"
                          disabled={user.isAdmin==="Yes"}
                          onClick={() => {
                            const notificationIds = getNotificationsIds();
                            socket.emit("read-notification", {
                              notification_id: notificationIds,
                            });
                            socket.emit('get-unread-notification');
                            history.push("/Notification");
                            handleCloseModal();
                          }}
                        >
                          View All
                        </Button>
                    </div>
              </div>
              
              {notifications?.length > 0 ?(
                notifications.slice(0, 8).map((notification) => (
                  <div>
                    <div className="d-flex flex-row  align-items-center">
                      <div>
                        <Avatar className="custom-common-theme-bg-color">
                          {userName(
                            notification.sender_id === user._id
                              ? notification.sender_id
                              : notification.sender_id
                          ).charAt(0)}
                        </Avatar>
                      </div>
                      <div className="ml-2 font-size-14">
                        {notification?.sender_id === user._id
                          ? "You"
                          : userName(notification?.sender_id)}{" "}
                        {notification?.type} for {notification?.description}.
                      </div>
                    </div>
                    <span className="d-flex flex-row-reverse font-size-12 font-weight-bold custom-common-theme-text-color">
                      {moment(notification.createdAt).fromNow()}
                    </span>
                  </div>
                ))
              ) : (
                <div className="text-center mt-4">
                  <p>There are no notifications.</p>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PushNotification;
