import React, { useEffect, useState } from 'react';
import { Checkbox } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { softDeleteLedger } from '../../Services/ApiHandler';
import swal from 'sweetalert';
import { getBCTbRows, getDeletedLedgersRows, setIsDeletedLedgerModal, setSelectedFlatRows, splitMappingChecked } from '../../../redux/actions/BookClosure/actionBookClosure';
import DeletedLedgerModal from './DeletedLedgerModal';
import FilterLedger from './FilterLedgerModal';
import switchModeSvg from "../../../assets/switchMode.svg";
import filterSvg from "../../../assets/filter.svg";
import trashSvg from "../../../assets/trash.svg";
import adjustmentSvg from "../../../assets/adjustment.svg";
import SwitchModeModal from './SwitchModeModal';

const TbcHeader = ({ lineItems,currentMode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { bookClosureTbId, workspaceId } = useParams();
  const [filterShow, setFilterShow] = useState(false);
  const [object, setObject] = useState({});
  const [filterEnable, setFilterEnable] = useState(false);
  const [showSwitchMode, setSwitchMode] = useState(false);



  const {
    selectedFlatRows
  } = useSelector((initialState) => initialState.BookClosureTbcReducer);

  const HandleDeleteLedgers = () => {
    if (selectedFlatRows.length <= 0) {
      toast.warning("Please Select Any Ledger Foe Delete.")
      return;
    }

    if (selectedFlatRows.length > 1) {
      toast.warning("Multiple delete ledgers not allowed..")
      return;
    }

    softDeleteLedger(bookClosureTbId, {
      ledgerID: selectedFlatRows[0]._id
    }).then(response => {
      toast.success("Ledger deleted successfully.");
      dispatch(getBCTbRows(bookClosureTbId));
      dispatch(setSelectedFlatRows([]));
    }).catch(error => {
      swal("Error", error?.response?.data?.error || "Soft delete ledger failed", "error")
    })
  }

  return (
    <>
      <div className="conventionalMenu" style={{ zIndex: "9" }}>
     
        <div
          className="delete"
          onClick={HandleDeleteLedgers}
        >
          <img src={trashSvg} alt="trash" />
          <p>Delete record</p>
        </div>

        <div
          className="switchMode"
          // onClick={() => {
          //   setSwitchMode(!showSwitchMode);
          //  }}
        >
          <img src={switchModeSvg} />
          <p>Switch Mode</p>
        </div>
        <div
          className="filter"
          onClick={() => { setFilterShow(!filterShow); }}
        >
          <img src={filterSvg} />
          <p>Filter</p>
        </div>

        <div
          className="deletedItem cursor-pointer"
          onClick={() => {
            dispatch(getDeletedLedgersRows(bookClosureTbId));
            dispatch(setIsDeletedLedgerModal(true));
          }}
        >
          <img src={trashSvg} />
          <p>Deleted items</p>
        </div>

        {/* <div
          className="adjustment"
          onClick={() => { }}
        >
          <img src={adjustmentSvg} />
          <p>Adjustment</p>
        </div> */}

        <div className="checkYearly" >
          <Checkbox
            label={"Split mapping"}
            sx={{
              '&.Mui-checked': {
                color: '#03565a',
              }
            }}
            onChange={(e) => {
              console.log("checked",e.target.checked);
              dispatch(splitMappingChecked(e.target.checked));
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <p>Split mapping</p>
        </div>
        <div className="uploadConventional">
          <div className="addTb" >
            <h4>Upload trial balance</h4>
          </div>
          <div>
            <Button className="custom-common-theme-bg-color text-white font-size-16 custom-font-600 font-sans" variant="contained" onClick={() => history.push(`/bookclosure/${workspaceId}`)} style={{ width: "200px", borderRadius: "5px" }}>Confirm Grouping</Button>
          </div>
        </div>
       
      </div>
      <div>
      {showSwitchMode ? (
        <SwitchModeModal
          close={setSwitchMode}
          name="Choose a Mapping Mode"
          currentMode={currentMode}
          bookClosureTbId={bookClosureTbId}
          workspaceId={workspaceId}
        />
      ) : null}
      </div>
      
      <DeletedLedgerModal />
      <FilterLedger
        show={filterShow}
        setObject={setObject}
        setFilterShow={setFilterShow}
        filterHandler={setFilterEnable}
        lineItems={lineItems}
      />
    </>
  )
}

export default TbcHeader