import moment from "moment";

export const dateTimeMapping = (projectDetails, type) => {
 try {
  if(type === 'cy') {
    return `As at ${moment(projectDetails?.current_year).format("DD MMMM YYYY")}`;
  }else if(type === 'py') {
    return `As at ${moment(projectDetails?.previous_year).format("DD MMMM YYYY")}`;
  }
 } catch (error) {
  console.log("err date time mapp", error)
  return `As at 10 November 2023`
 }
}

export const dateTimeMappingCashflow = (projectDetails, type) => {
    try {
     if(type === 'cy') {
       return `FOR THE PERIOD ENDED ${moment(projectDetails?.current_year).format("DD MMMM YYYY")}`;
     }else if(type === 'py') {
       return `FOR THE PERIOD ENDED ${moment(projectDetails?.previous_year).format("DD MMMM YYYY")}`;
     }
    } catch (error) {
     console.log("err date time mapp", error)
     return `FOR THE PERIOD ENDED 10 November 2023`
    }
}

export const dateTimeMappingNotes = (projectDetails, type) => {
    try {
     if(type === 'cy') {
       return `${moment(projectDetails?.current_year).format("DD MMMM YYYY")}`;
     }else if(type === 'py') {
       return `${moment(projectDetails?.previous_year).format("DD MMMM YYYY")}`;
     }
    } catch (error) {
     console.log("err date time mapp", error)
     return `10 November 2023`
    }
}

export const dateTimeMappingEPS = (projectDetails, type) => {
    try {
     if(type === 'cy') {
       return `Year ended ${moment(projectDetails?.current_year).format("DD MMMM YYYY")}`;
     }else if(type === 'py') {
       return `Year ended ${moment(projectDetails?.previous_year).format("DD MMMM YYYY")}`;
     }
    } catch (error) {
     console.log("err date time mapp", error)
     return `Year ended 10 November 2023`
    }
}

function formatNumberWithDecimal(number, decimalPlaces) {
  /**
   * Convert a number to a decimal based on the specified decimal places.
   *
   * Parameters:
   * - number: The number to format.
   * - decimalPlaces: The number of decimal places (can be 0, 1, 2, 3, etc.).
   *
   * Returns:
   * - A formatted number with the specified number of decimal places.
   */
  if(decimalPlaces === null || decimalPlaces === undefined) return number;
  if (typeof decimalPlaces !== 'number' || decimalPlaces < 0) {
      throw new Error("Decimal places must be a non-negative integer.");
  }

  // Use toFixed to format the number with the specified decimal places
  return number.toFixed(decimalPlaces);
}

export const formatNumberWithUnit = (number, unit, decimal) => {
  /**
   * Format the given number based on the unit provided.
   * 
   * Parameters:
   * - number: The number to be formatted.
   * - unit: The unit for formatting. It can be 'hundreds', 'thousands', 'lakhs', 'millions', 'crores', or 'whole number'.
   * 
   * Returns:
   * - A formatted string representing the number with the specified unit.
   */
  try {
  if(!unit) return number;
  if(isNaN(number)) return number;
  unit = unit.toLowerCase()
  const validUnits = ['whole number', 'hundreds', 'thousands', 'lakhs', 'millions', 'crores'];
  
  if (!validUnits.includes(unit)) {
    console.error("Unit must be one of 'whole number', 'hundreds', 'thousands', 'lakhs', 'millions', or 'crores'");
    return number;
  }

  let formattedNumber;
  let unitStr;

  switch (unit) {
      case 'hundreds':
          formattedNumber = number / 100;
          // unitStr = 'H';
          break;
      case 'thousands':
          formattedNumber = number / 1000;
          // unitStr = 'K';
          break;
      case 'lakhs':
          formattedNumber = number / 100000;
          // unitStr = 'L';
          break;
      case 'millions':
          formattedNumber = number / 1000000;
          // unitStr = 'M';
          break;
      case 'crores':
          formattedNumber = number / 10000000;
          // unitStr = 'Cr';
          break;
      case 'whole number':
          formattedNumber = Math.round(number);
          // unitStr = '';
          break;
      default:
        return number
  }
  return `${formatNumberWithDecimal(formattedNumber, decimal)}`;
  } catch (error) {
    console.error("error in unit transform", error)
    return number
  }
  
}
