const presetType = {
    DISCLOSURE_CHECKLIST_DATA: "DISCLOSURE_CHECKLIST_DATA",
    NOTE_INDEX: "NOTE_INDEX",
}

const Initial_state = {
    disclosureChecklistData: [],
    noteIndex :0,
};

const DisclosureChecklistReducer = (state=Initial_state, action) => {
    switch(action.type){
        case presetType.DISCLOSURE_CHECKLIST_DATA:
            return {
                ...state,
                disclosureChecklistData:action.payload
            }
        case presetType.NOTE_INDEX:
            return {
                ...state,
                noteIndex:action.payload
            }
        default:
            return state;
    }
};

export default DisclosureChecklistReducer;
