import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cross from "../../../assets/cross.svg";
import arrow from "../../../assets/arrow.svg";
import plus from "../../../assets/plus.svg";
import Select from "react-select";
import { addFilterLineItems } from "../../../redux/actions/BookClosure/actionBookClosure";

const popup = {
  position: "Fixed",
  minHeight: "10rem",
  minWidth: "10rem",
  width: "66%",
  zIndex: "10",
  backgroundColor: "#F1F2F5",
  top: "7.5rem",
  left: "8%",
  borderRadius: "8px",
  boxShadow: "4px 6px 8px 0px #C0C0C060",
  padding: "20px",
  transform: "scale(0.9)",
};
const flexContainer = {
  display: "flex",
  alignItems: "center",
};
const close = {
  height: "43px",
  width: "43px",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItem: "center",
  borderRadius: "6px",
  fontWeight: "normal",
  marginRight: "15px",
};
const noClose = {
  height: "43px",
  width: "43px",
  backgroundColor: "#cacacabf",
  display: "flex",
  justifyContent: "center",
  alignItem: "center",
  borderRadius: "6px",
  fontWeight: "normal",
  marginRight: "15px",
};
const field = {
  width: "25%",
  marginRight: "15px",
};
const contains = {
  width: "20%",
  marginRight: "15px",
};
const inputs = {
  width: "33%",
  marginRight: "15px",
};
const input = {
  height: "46px",
  border: "1px solid #cacacabf",
  padding: "10px",
  borderRadius: "6px",

  ":focusVisible": {
    outline: "none",
    border: "2px solid #03565A",
  },
};

const search = {
  width: "85px",
  height: "46px",
  backgroundColor: "#03565A",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
};
const noSearch = {
  width: "46px",
  height: "46px",
  backgroundColor: "#03565a98",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
};

const addFilter = {
  marginTop: "1rem",
  color: "#03565A",
  fontSize: "1.1rem",
  fontWeight: "600",
  display: "flex",
  alignItems: "center",
};

const text = {
  fontWeight: "500",
  fontSize: "1rem",
  color: "#696F79",
};

const textBox = {
  marginRight: "15px",
  width: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const columnName = [
  { value: "ledgerCode", label: "LEDGER CODE" },
  { value: "ledgerName", label: "LEDGER NAME" },
  { value: "periodBeginingBalance", label: "PERIOD BEGINNING BALANCE" },
  { value: "periodEndingBalance", label: "PERIOD ENDING BALANCE" },
];
const type = [
  { value: "Contains", label: "Contains" },
  { value: "is empty", label: "Is empty" },
];

const finalAmountOptions = [
  { value: "Less than", label: "Less than" },
  { value: "Greater than", label: "Greater than" },
  { value: "Equal to", label: "Equal to" },
];

export default function FilterLedger({
  show,
  setObject,
  setFilterShow,
  filterHandler,
  lineItems
}) {
  const dispatch = useDispatch();
  const [state, setstate] = useState([
    { column: "", select: "", change: "", startAmount: "", endAmount: "" },
  ]);
  const [render, setRender] = useState("");
  const [isFilter, setFilter] = useState(false);



  function applyFilters(data, filters) {
    return data.filter(item => {
      let includeItem = true;
      filters.forEach(filter => {
        const { column, select, change, startAmount } = filter;
        if (select === "Contains") {
          if (item[column] && !item[column].toLowerCase().includes(change.toLowerCase())) {
            includeItem = false;
          }
        } else if (select === "is empty") {
          if (item[column] && item[column] !== "") {
            includeItem = false;
          }
        } else if (select === "Less than") {
          if (item[column] && !(item[column] < parseInt(startAmount))) {
            includeItem = false;
          }
        } else if (select === "Greater than") {
          if (item[column] && !(item[column] > parseInt(startAmount))) {
            includeItem = false;
          }
        } else if (select === "Equal to") {
          if (!(item[column] === parseInt(startAmount))) {
            includeItem = false;
          }
        }
      });
      return includeItem;

    });
  }
  const handleFilter = () => {
    const filteredData = applyFilters(lineItems, state);
    dispatch(addFilterLineItems(filteredData));
    filterHandler(true);
    setFilterShow(false);
  };

  const handleChange = (field, i, e) => {
    if (
      field === "change" ||
      field === "startAmount" ||
      field === "endAmount"
    ) {
      let search = state;
      search[i][field] = e.target.value;
      setstate(search);
      setRender(Math.random());
    } else {
      let temp = state;
      temp[i][field] = e.value;
      if (field === "select" && e.value === "is empty") {
        temp[i]["change"] = "";
      }
      setstate(temp);
      setRender(Math.random());
    }
  };
  useEffect(() => {
    setObject(state);
    setRender(Math.random());
  }, [state]);

  function handleDelete(i) {
    const temp = state;
    const newarr = temp.filter((val, ix) => ix !== i);
    setstate(newarr);
    setFilter(false);
    if (newarr.length == 0) {
      setFilterShow(false);
      filterHandler(false);
    }
  }

  function handleNew() {
    setstate((oldArray) => [
      ...oldArray,
      { column: "", select: "", change: "", startAmount: "", endAmount: "" },
    ]);
    setFilter(true);
  }

  if (!show) return null;

  return (
    <>
      <div style={popup}>
        {state.map((val, i) => {
          const last = state.length - 1;
          const istrue = true;
          return (
            <div style={flexContainer} key={i}>
              {i === state.length - 1 ? (
                <div
                  style={close}
                  onClick={() => {
                    handleDelete(i);
                  }}
                >
                  <img src={cross} style={{ transform: "scale(0.75)" }} />
                </div>
              ) : (
                <div style={noClose}>
                  <img src={cross} style={{ transform: "scale(0.75)" }} />
                </div>
              )}
              {i === 0 ? (
                <div style={textBox}>
                  <p className="text-dark" style={text}>where</p>
                </div>
              ) : (
                <div style={textBox}>
                  <p className="text-dark" style={text}>as</p>
                </div>
              )}

              <div style={field}>
                <Select
                  value={columnName.filter(function (option) {
                    return option.value === val.column;
                  })}
                  options={columnName}
                  onChange={(e) => handleChange("column", i, e)}
                  placeholder="Field"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: "6px",
                    colors: {
                      ...theme.colors,
                      backgroundColor: "white",
                      primary25: "#03565a98",
                      primary: "#03565A",
                    },
                  })}
                />
              </div>

              {val.column === "ledgerName" || val.column === "ledgerCode" ? (
                <React.Fragment>
                  <div style={contains}>
                    <Select
                      value={type.filter(function (option) {
                        return option.value === val.select;
                      })}
                      options={type}
                      onChange={(e) => handleChange("select", i, e)}
                      placeholder="Select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: "6px",
                        colors: {
                          ...theme.colors,
                          backgroundColor: "white",
                          primary25: "#03565a98",
                          primary: "#03565A",
                        },
                      })}
                    />
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              {val.column !== "ledgerCode" && val.column !== "ledgerName" && (
                <React.Fragment>
                  <div style={contains}>
                    <Select
                      value={finalAmountOptions.filter(function (option) {
                        return option.value === val.select;
                      })}
                      options={finalAmountOptions}
                      onChange={(e) => handleChange("select", i, e)}
                      placeholder="Select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: "6px",
                        colors: {
                          ...theme.colors,
                          backgroundColor: "white",
                          primary25: "#03565a98",
                          primary: "#03565A",
                        },
                      })}
                    />
                  </div>
                </React.Fragment>
              )}

              {val.column === "ledgerCode" || val.column === "ledgerName" ? (
                <div style={inputs}>
                  {val.select === "is empty" ? (
                    <input
                      style={input}
                      value={val.change}
                      disabled={true}
                      onChange={(e) => handleChange("change", i, e)}
                    />
                  ) : (
                    <input
                      style={input}
                      value={val.change}
                      onChange={(e) => handleChange("change", i, e)}
                    />
                  )}
                </div>
              ) : (
                ""
              )}

              {val.column !== "ledgerCode" &&
                val.column !== "ledgerName" &&
                val.select === "Equal to" ? (
                <React.Fragment>
                  <div style={inputs}>
                    <input
                      placeholder="Amount"
                      name=""
                      value={val.startAmount}
                      style={input}
                      onChange={(e) => handleChange("startAmount", i, e)}
                    ></input>
                  </div>
                </React.Fragment>
              ) : val.column !== "ledgerCode" &&
                val.column !== "ledgerName" &&
                val.select === "Greater than" ? (
                <React.Fragment>
                  <div style={inputs}>
                    <input
                      placeholder="Amount"
                      name=""
                      value={val.startAmount}
                      style={input}
                      onChange={(e) => handleChange("startAmount", i, e)}
                    ></input>
                  </div>
                </React.Fragment>
              ) : val.column !== "ledgerCode" &&
                val.column !== "ledgerName" &&
                val.select === "Less than" ? (
                <React.Fragment>
                  <div style={inputs}>
                    <input
                      placeholder="Amount"
                      name=""
                      value={val.startAmount}
                      style={input}
                      onChange={(e) => handleChange("startAmount", i, e)}
                    ></input>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              {i === state.length - 1 ? (
                <div style={search} onClick={handleFilter}>
                  <img src={arrow} />
                </div>
              ) : (
                <div style={noSearch} onClick={(e) => e.preventDefault}>
                  <img src={arrow} />
                </div>
              )}
            </div>
          );
        })}

        <div style={addFilter}>
          <img
            alt="plus"
            src={plus}
            style={{ marginRight: "5px" }}
            onClick={() => {
              handleNew();
            }}
          />
          Add Filter
        </div>
      </div>
    </>
  );
}
