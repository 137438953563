import { GetLease } from "../../../WorkSpace/Components/ApiServices/apiHandler";

export const fetchAllLease = () => {
    return dispatch => {
        dispatch({type:"LEASE_ACTION_START"})
        GetLease().then((result)=>{
            dispatch({
                type: "LEASE_FETCH_DATA",
                payload: result.data.projects
            }) 
        }).catch((error)=>{
            dispatch({
                type: "LEASE_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}