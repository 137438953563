import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Navbar } from "../../Components/Navbar";
// import PreviewCardV2 from "../Components/PreviewCardV2";
import PreviewCardV2 from "./../../Bookclosure/Components/PreviewCardV2" 
import  Download from '../../assets/Image/download1.png'
import PreviewCardV3 from "../../Bookclosure/Components/PreviewCardV3" 





const buttonContainer = {
    display: 'flex',
    alignItems: 'center',
};

const main = {
    padding: "1rem 4rem",
    minWidth: "1045px",
    backgroundColor: "#E5E5E5",
    minHeight: "calc(100vh - 80px)",
};



const ClassificationPreview = () => {
    
    const history = useHistory();

    return (
        <>
            <Navbar text="Classification Preview" />
            <div style={main}>
                <div className="d-flex align-items-center ml-4 mb-4">
                    <span><ArrowBackIcon className="custom-common-theme-text-color" /></span>
                    <p className="mb-0 mt-0 ml-2 custom-common-theme-text-color custom-font-600 font-size-16 font-sans" onClick={() => history.push('/tbc')} style={{cursor:"pointer"}}>Back</p>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="p-3 ml-3">
                        <h2 className="custom-font-600 font-size-24 font-sans">
                            Classification Preview
                        </h2>
                        <p className="mt-0 mb-0 custom-font-500 font-size-16 text-secondary font-sans">"Preview your mapped ledgers"</p>
                    </div>
                    <div style={buttonContainer}>
                        <Button
                            className=" text-white custom-common-theme-bg-color font-size-16 custom-font-500 text-capitalize d-flex align-items-center radius-6px"
                            variant="contained"
                            style={{
                                width: '130px',
                                height: "50px"
                            }}
                        >
                            <img src={Download} alt="Download Icon" style={{ marginRight: "8px", fill: 'white', width: "22px", height: "22px" }} />
                            <span className="font-sans">Export</span>
                        </Button>
                    </div>
                </div>
                <div id="gridScroll" className="grid">
                    <div>
                        <PreviewCardV2 />
                    </div>
                    <div>
                        <PreviewCardV2 />
                    </div>
                    <div>
                        <PreviewCardV3 />
                    </div>
                    <div>
                        <PreviewCardV3 />
                    </div>
                    <div>
                        <PreviewCardV2 />
                    </div>
                    <div>
                        <PreviewCardV2 />
                    </div>
                    <div>
                        <PreviewCardV3 />
                    </div>
                    <div>
                        <PreviewCardV3 />
                    </div>
                </div>
            </div>




        </>
    );
};

export default ClassificationPreview;
