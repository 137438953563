import React, { useEffect,useState } from 'react'
import { Navbar } from '../../Components/Navbar'
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "react-bootstrap/Table";
import moment from 'moment';
import { getSubscriptionById,createSubscriptionInvoice,getInvoiceById,} from '../../WorkSpace/Components/ApiServices/apiHandler';
import { getProfile } from '../../services/api/apiHandler';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { Typography } from '@mui/material';

const SubscriptionDetails = ({match}) => {
         const { id } = match.params;
         console.log(match,"match----------->");

         const [userId,setUserId] = useState('')
         
         const [subscriptionData ,setSubscriptiondata] = useState({});
         const [invoice , setInvoice] = useState('')


         useEffect(() => {
          const fetchData = async () => {
            try {
              await getProfileId();
              await subscriptionbyId();
              // await  invoicebyId(id);
            } catch (error) {
              console.error("Error fetching data", error);
            }
          };
      
          fetchData();
        }, []);

    
    

    const getProfileId = async (id) => {
        try {
            const response = await getProfile();
            console.log(response.data.user.data._id)
            if(response.status === 200){
              setUserId(response.data.user.data._id);
            } // Return the data from the response
        } catch (error) {
            console.error('Error fetching invoice:', error);
            throw error; // Rethrow the error to be handled by the caller
        }
    };


     const subscriptionbyId = async() => {
         const details = await getSubscriptionById(id)
         if(details.status === 200){
           console.log(details,'detailsdata-------')
           setSubscriptiondata(details.data.data);
           await invoicebyId()
          }

     } 

     const invoicebyId = async(ids)=>{
        console.log(invoice,'invoice----')
        try {
            const res = await getInvoiceById()
            console.log(res,'response from invoiceid');
            setInvoice(res.data.data)
        } catch (error) {
            console.log(error)
        }
        
     }

    //  const createInvoice = async (ids) => {
    //     try {
    //         // Assuming subscriptionData is available from the surrounding scope
    //         const bodyData = {
    //             subscription_id: id,
    //             plan_name:subscriptionData.plan_name,
    //             customer_id:ids,
    //             invoice_no: 'MYFNLST-0001',
    //             invoice_date: new Date(),
    //             due_date: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000), // Set due date 3 days from now
    //             invoice_subtotal: subscriptionData.plan_price,
    //             invoice_tax: 0,
    //             invoice_total: Number(subscriptionData.plan_price), // No need to add 0
    //             status: subscriptionData.payment_status === 'success' ? 'paid' : 'unpaid'
    //         };
    
    //         // Assuming createSubscriptionInvoice is an asynchronous function that creates the invoice
    //         const res = await createSubscriptionInvoice(bodyData);
            
            
            
    //         // Check if the invoice creation was successful
    //         if (!res) {
    //           throw new Error('Failed to create invoice');
    //         }
            
            
    //         // Invoice created successfully
    //         console.log('Invoice created:', res.data);
    
    //         // Call invoicebyId function after successful invoice creation
    //     } catch (error) {
    //         console.error('Error creating invoice:', error);
    //         // Handle the error, if needed
    //     }
    // };
    
    


  return (
    <>
      <Navbar/>
      <div className='mr-5 ml-5 mt-3'>
            <TableContainer>
              <Table sx={{}} aria-label="caption table">
                {/* {users.length > 0 ? ( */}
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="font-sans">
                        Plan Name
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Plan Price
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Start Date
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        End Date
                      </TableCell>
                      {/* <TableCell align="center" className="font-sans">
                        Payment
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Payment via
                      </TableCell> */}
                      <TableCell align="center" className="font-sans">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {subscriptions.map((det, index) => {
                      return ( */}
                        <>
                          <TableRow>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {" "}
                              {subscriptionData.plan_name}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {subscriptionData.plan_price}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {moment(subscriptionData.subscription_start_date).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {moment(subscriptionData.subscription_end_date).format('DD-MM-YYYY')}
                            </TableCell>
                            {/* <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {subscriptionData.payment_status}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {subscriptionData.payment_via}
                            </TableCell> */}
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {subscriptionData.status}
                            </TableCell>

                          </TableRow>
                        </>
                      {/* ) */}
                    {/* } */}
                    {/* )} */}
                  </TableBody>
                </>
                {/* ):""} */}
              </Table>
            </TableContainer>
          </div>

          <div className='ml-5'>
            <Typography>Payments:</Typography>
          </div>
         
         { invoice ?<div className='mr-5 ml-5 mt-3'>
            <TableContainer>
              <Table sx={{}} aria-label="caption table">
                {/* {users.length > 0 ? ( */}
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="font-sans">
                        Invoice No
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Plan Name
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Invoice Date
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Due Date
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Invoice Subtotal
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Invoice Tax 
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Invoice Total
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                       Payment Status
                      </TableCell>
                      <TableCell align="center" className="font-sans">
                        Payment By
                      </TableCell>
                      {/* <TableCell align="center" className="font-sans">
                        Status
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice.map((invoice, index) => {
                       return ( 
                        <>
                          <TableRow >
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {"MyFnlst001 "}
                              {/* {invoice.invoice_no} */}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {invoice.plan_name?invoice.plan_name:invoice.subscription_id.plan_name}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {moment(invoice.createdAt).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {moment(invoice.createdAt).add(3, 'days').format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                            {invoice.plan_price?invoice.plan_price:invoice.subscription_id.plan_price}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                              >0
                            {/* {invoice.invoice_tax} */}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                              >
                                {(invoice.plan_price?invoice.plan_price:invoice.subscription_id.plan_price)+0}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {invoice.invoice_status}
                            </TableCell>
                            <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {invoice.payment_via}
                            </TableCell>
                            {/* <TableCell className="font-sans"
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {invoice.subscription_id.status}
                            </TableCell> */}

                          </TableRow>
                        </>
                      ) 
                      }  
                      )}  
                  </TableBody>
                </>
                {/* ):""} */}
              </Table>
            </TableContainer>
          </div> : ''}
          
         

    </>
  )
}

export default SubscriptionDetails