import React, { useState, useEffect } from "react";
import { Navbar } from "../../../Components/Navbar";
import "./Addyour/AddYourTeam.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SidebarTeam from "./Addyour/SidebarTeam";
import Container from "./Addyour/Container";
import { Card } from "@mui/material";
import { useParams } from "react-router-dom";
import { getUserPermission } from "../ApiServices/apiHandler";
import Loader from "../../../helper/loader";

const EditIamUser = () => {
  const { user_id } = useParams();
  const [userData, setUserData] = useState({
    loading: false,
    isSuccess: false,
    user: null,
    userPermission: null,
    unrestrictedAccess: null,
    error_message: null
  });

  useEffect(()=>{
    (async()=>{
      try {
        setUserData((prevData)=>{return {
          ...prevData, 
          loading: true
        }})
        const res = await getUserPermission(user_id);
        setUserData((prevData)=>{return {
          ...prevData, 
          loading: false,
          isSuccess: true,
          user: res?.data?.result?.user,
          userPermission: res?.data?.result?.companyData,
          unrestrictedAccess: res?.data?.result?.unrestrictedAcessData
        }})
      } catch (error) {
        setUserData((prevData)=>{return {
          ...prevData, 
          loading: false,
          isSuccess: false,
          error_message: error.message
        }})
      }
    })()
  },[])


  return (
    <>
      <Navbar/>
      <div className="d-flex">
        <div>
          <SidebarTeam />
        </div>
        <Card className="w-100 ml-3 mt-2 ">
          <div>
            { 
            userData.loading 
              ? <Loader/> 
              :
            <Container userData={userData} setUserData={setUserData} />
            }
          </div>
        </Card>
      </div>
    </>
  );
};

export default EditIamUser;
