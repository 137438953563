import React from 'react'
import { Navbar } from '../../Navbar'
import AddNewAdjustment from './AddNewAdjustment'
import AdjustmentCashflowWorking from './AdjustmentCashflowWorking'

const CashFlowAdjustment = () => {
    return (
        <>
            <Navbar text="Financial Year: 2021 - 2022" />

            {/* <AddNewAdjustment /> */}
            <AdjustmentCashflowWorking />
        </>
    )
}

export default CashFlowAdjustment