const AddArrayElement = (arr) => {
    let total = 0;
    if(arr && arr.length > 0){
        arr.forEach((element,index) => {
            total += Number(element)
        });
        return total ? total : 0
    }else{
        return 0
    }
}



const Variances = (item1, item2) => {
    let item = (( Number(item1) - Number(item2)) / Number(item2)) * 100
    return item ? item : 0
}

// ? code for generate alphaBit array
// [...Array(26)].map((_, i) => String.fromCharCode(i + 97))
const alphaBitArr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

export {AddArrayElement, Variances, alphaBitArr}