const CompanyTypes = {
    RECEIVABLES_ACTION_START:"RECEIVABLES_ACTION_START",
    RECEIVABLES_FETCH_DATA:"RECEIVABLES_FETCH_DATA",
    RECEIVABLES_FETCH_DATA_FAILED:"RECEIVABLES_FETCH_DATA_FAILED",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: [],
}

const ReceivablesReducer = (state=Initial_state, action) => {
    switch(action.type){
        case CompanyTypes.RECEIVABLES_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case CompanyTypes.RECEIVABLES_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload,
            }

        case CompanyTypes.RECEIVABLES_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default ReceivablesReducer;