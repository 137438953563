import ProgressBar from '@ramonak/react-progress-bar';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchConventionalMode } from '../../redux/actions/TemplateTabs/actionCM';


const CommonProgressBar = () => {
    const dispatch = useDispatch();
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");

    const conventionModeData = useSelector(
        (state) => state.ConventionModeReducer
    );

    useEffect(() => {
        getConventionalMode();
    }, [])
    
    const getConventionalMode = async () => {
        if (
            !conventionModeData.isSuccess ||
            conventionModeData.set_payload !== JSON.stringify({ project_id, tb_id })
        ) {
            dispatch(fetchConventionalMode(project_id, tb_id));
        }
    };

    let completedValue = 0;
    if(conventionModeData.isSuccess){
        const wholeValue = conventionModeData?.response?.records;
        const partValue = conventionModeData?.response?.data?.line_items.reduce((count, lineItem) => {
            if (lineItem?.map_data?.cy_map?.fs_grp || lineItem?.map_data?.py_map?.fs_grp) {
                return count + 1;
            }
            return count;
        }, 0) || 0;
        completedValue = Math.round( ((partValue / wholeValue) * 100) || 0 );
    }

    const ChooseColor = (percentage) => percentage < 20 ? "#FF5959" :
        percentage >= 20 && percentage <= 50 ? "#FFA04C" :
        percentage > 50 && percentage <= 80 ? "#FAEB65" :
        percentage > 80 && percentage <= 100 ? "#0FC755" : '';

    return (
        <ProgressBar bgColor={ChooseColor(completedValue)} completed={completedValue} />
    );
};

export default CommonProgressBar;

