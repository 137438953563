import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import swal from "sweetalert";
import NewTeammateDetail from "./Components/NewTeammateDetail";
import { generatePassword } from "../helper/accessibility";
import { createChildUser, getUserById, updateChildUser,getActivePlan,getMeber } from "../WorkSpace/Components/ApiServices/apiHandler";
import AddminNav from "./AddminNav";
import { toast } from "react-toastify";

const AddTeamate = () => {
  const history = useHistory();
  const { childUserId } = useParams();
  const [childUserCount, setChildUserCount] = useState(0);
  const [users,setUsers] = useState(0)
  const [newUserData, setNewUserData] = useState({
    email:"",
    name: "",
    responsibilities:"preparer",
    role:"",
    unrestrictedaccess: false,
  });

  useEffect(() => {
    if(childUserId){
      GetUserById(childUserId);
    }
    activePlanData();
    countAllMember();
  }, [])

  const GetUserById = (userId) => {
    getUserById(userId).then(response =>{
      const userData = response.data?.user
      setNewUserData(prev => ({ ...prev, 
        email:userData.email,
        name: userData.name,
        responsibilities:userData.responsibilities,
        role:userData.role,
        unrestrictedaccess: userData.unrestrictedaccess,
      }));
    }).catch(error => {
      swal(
        "Error",
        error.response?.data?.error || "Member Data Fetched Failed!",
        "error"
      );
    })
  }
  
  const HandleNewUserSubmit = async () => {
    const generatedPassword = generatePassword();

    createChildUser({
      email: newUserData.email,
      name: newUserData.name,
      responsibilities: newUserData.responsibilities,
      role: newUserData.role,
      unrestrictedaccess: newUserData.unrestrictedaccess,
      temp_password: generatedPassword
    }).then((response) => {
      toast.success("Team Member Added Successfully.");
      const childUserId = response.data.data._id;
      if(newUserData.unrestrictedaccess){
        history.push(`/admin`);
      }else{
        history.push(`/select-company/${childUserId}`);
      }
    }).catch(error => {
      swal(
        "Error",
        error.response?.data?.error || "Team Member Added Failed!",
        "error"
      );
    })
  }

  const UpdateMember = () => {
    updateChildUser(childUserId, {
      name: newUserData?.name,
      email: newUserData?.email,
      role: newUserData?.role,
      responsibilities: newUserData?.responsibilities,
      unrestrictedaccess: newUserData?.unrestrictedaccess
    }).then(response => {
      toast.success("Member Updated Successfully.");
      history.push(`/select-company/${childUserId}/update`);
    }).catch(error => {
      swal(
        "Error",
        error.response?.data?.error || "Member Data Fetched Failed!",
        "error"
      );
    })
  }


  const countAllMember = async() => {
    getMeber().then(response => {
      setChildUserCount(response.data?.fetchMember?.length);
    }).catch(error => {
      swal(
        "Error",
        error.response?.data?.error || "Get All Member Data Failed!",
        "error"
      );
    });
  }

  const activePlanData = async() => {
    getActivePlan().then(response => {
      setUsers(response.data?.categorizedBenefits?.noOfUsers[0]);
    }).catch(error => {
      swal(
        "Error",
        error.response?.data?.error || "Get Active Data Failed!",
        "error"
      );
    });
  }

  return (
    <>
      <div>
        <AddminNav />
        <div className="px-4 py-3">
          <Card className="w-100 radius-12px px-3 py-3">
            <div>
              <p className="mt-0 mb-0 font-size-18 custom-font-600 font-sans ">
                Add Teamate
              </p>
              <p className="mt-0 mb-2 font-size-16 custom-font-500 font-sans">
                Fill out all the requirements to create new Teammate
              </p>
            </div>
            <div
              className="mt-3 general-box">
              <div className="d-flex flex-wrap p-2">
                <div
                  className="d-flex justify-content-center align-items-center mt-1 orange-circle">
                  <p className="mt-0 mb-0 font-size-12 custom-font-600 font-sans">
                    1
                  </p>
                </div>
                <div className="flex-grow-1">
                  <p className="mt-2 p-1 mb-0 font-size-12 custom-font-600 font-sans" style={{ color:"Brown" }}>
                    General
                    Details<span className="text-secondary">-------------------------------------------------------</span>
                  </p>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center mt-1 white-circle" style={{ border:"1px solid #8692A6" }}>
                  <p className="mt-0 mb-0 font-size-12 custom-font-600 font-sans">
                    2
                  </p>
                </div>
                <div className="flex-grow-1">
                  <p className="mt-2 p-1 mb-0 font-size-12 custom-font-600 font-sans text-secondary">
                    Select
                    Companies-------------------------------------------------------
                  </p>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center mt-1 white-circle"
                  style={{
                    border:"1px solid #8692A6"
                  }}
                >
                  <p className="mt-0 mb-0 font-size-12 custom-font-600 font-sans">
                    3
                  </p>
                </div>
                <div className="flex-grow-1">
                  <p className="mt-2 p-1 mb-0 font-size-12 custom-font-600 font-sans text-secondary">
                    Responsibilities
                  </p>
                </div>
              </div>
            </div>

            <NewTeammateDetail
              newUserData={newUserData}
              setNewUserData={setNewUserData}
              users={users}
              childUserCount={childUserCount}
              onClick={() => {
                if(childUserId){
                  UpdateMember()
                }else{
                  HandleNewUserSubmit()
                }
              }}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddTeamate;
