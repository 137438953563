const presetType = {
    BOOK_cLOSURE_DATA: "BOOK_cLOSURE_DATA",
    BOOK_CLOSURE_COMPANIES: "BOOK_CLOSURE_COMPANIES",
    SET_BC_COMPANY: "SET_BC_COMPANY",
    TASK_OPTIONS: "TASK_OPTIONS",
    BOOK_cLOSURE_CLEANUP: "BOOK_cLOSURE_CLEANUP",
    SET_BC_TBS: "SET_BC_TBS",

    BC_ENTRIES_ACTION_START: "BC_ENTRIES_ACTION_START",
    BC_ENTRIES_FETCH_DATA: "BC_ENTRIES_FETCH_DATA",
    BC_ENTRIES_FETCH_DATA_FAILED: "BC_ENTRIES_FETCH_DATA_FAILED",

    BC_MATRIX_ACTION_START: "BC_MATRIX_ACTION_START",
    BC_MATRIX_FETCH_DATA: "BC_MATRIX_FETCH_DATA",
    BC_MATRIX_FETCH_DATA_FAILED: "BC_MATRIX_FETCH_DATA_FAILED",

    SET_VIEW_MORE_LEDGERS: "SET_VIEW_MORE_LEDGERS",
    CLEAR_VIEW_MORE_LEDGERS: "CLEAR_VIEW_MORE_LEDGERS",
    SET_ADD_TO_TASK_MODAL_OPEN: "SET_ADD_TO_TASK_MODAL_OPEN",
    SET_IS_EDIT_LEDGER_TO_TASK_MODAL:"SET_IS_EDIT_LEDGER_TO_TASK_MODAL",
    SET_ADDED_LEDGERS: "SET_ADDED_LEDGERS",
    SET_TASK_COMMENTS: "SET_TASK_COMMENTS",
    SET_ADJUSTMENTS: "SET_ADJUSTMENTS",
    SET_NEW_ADJUSTMENT_TO_TABLE: "SET_NEW_ADJUSTMENT_TO_TABLE",
    SPLIT_MAPPING_CHECKED:"SPLIT_MAPPING_CHECKED",
}

const Initial_state = {
    bookClosureData: {},
    bookClosureId: "",
    bookClosureCompanies: [],
    BCCompany: "",
    bookClosureModules: [],
    assignOptions: [],
    approveOptions: [],

    entries: [],
    sceLoading: null,
    bceErrorMessage: null,
    bceApiFetched: false,

    matrices: [],
    bcmLoading: null,
    bcmErrorMessage: null,
    bcmApiFetched: false,

    ledgers: [],
    isAddToTaskModalOpen : false,
    addedLedgers: [],
    isEditLedgerModal: false,
    comments: [],
    adjustments: [],
    mappingChecked: false
};

const BookClosureReducer = (state = Initial_state, action) => {
    switch (action.type) {
        case presetType.BOOK_cLOSURE_DATA:
            return {
                ...state,
                bookClosureData: action.payload,
                // bookClosureCompanies: action.payload.companyDetails || [],
                bookClosureModules: action.payload.modules || [],
                bookClosureId: action.payload._id || "",
            }
        case presetType.BOOK_CLOSURE_COMPANIES:
            return {
                ...state,
                bookClosureCompanies: action.payload,
            }
        case presetType.SET_BC_COMPANY:
            return {
                ...state,
                BCCompany: action.payload,
            }
        case presetType.TASK_OPTIONS:
            return {
                ...state,
                assignOptions: action.payload.assign || [],
                approveOptions: action.payload.approver || []
            }
        case presetType.BOOK_cLOSURE_CLEANUP:
            return {
                ...Initial_state,
                bookClosureData: {},
                bookClosureId: "",
                bookClosureCompanies: []
            }
        case presetType.BC_ENTRIES_ACTION_START:
            return {
                ...state,
                entries: [],
                sceLoading: true,
                bceErrorMessage: null,
                bceApiFetched: false
            }
        case presetType.BC_ENTRIES_FETCH_DATA:
            return {
                ...state,
                entries: action.payload.entries || [],
                sceLoading: false,
                bceErrorMessage: null,
                bceApiFetched: true
            }
        case presetType.BC_ENTRIES_FETCH_DATA_FAILED:
            return {
                ...state,
                entries: [],
                sceLoading: false,
                bceErrorMessage: action.payload,
                bceApiFetched: false
            }

        case presetType.BC_MATRIX_ACTION_START:
            return {
                ...state,
                matrices: [],
                bcmLoading: true,
                bcmErrorMessage: null,
                bcmApiFetched: false
            }
        case presetType.BC_MATRIX_FETCH_DATA:
            return {
                ...state,
                matrices: action.payload.matrix || [],
                bcmLoading: false,
                bcmErrorMessage: null,
                bcmApiFetched: true
            }
        case presetType.BC_MATRIX_FETCH_DATA_FAILED:
            return {
                ...state,
                matrices: [],
                bcmLoading: false,
                bcmErrorMessage: action.payload,
                bcmApiFetched: false
            }

        case presetType.SET_VIEW_MORE_LEDGERS:
            return {
                ...state,
                ledgers: action.payload
            }
        case presetType.CLEAR_VIEW_MORE_LEDGERS:
            return {
                ...state,
                ledgers: []
            }
        case presetType.SET_ADD_TO_TASK_MODAL_OPEN:
            return {
                ...state,
                isAddToTaskModalOpen: action.payload
            }
        case presetType.SET_IS_EDIT_LEDGER_TO_TASK_MODAL:
            return {
                ...state,
                isEditLedgerModal: action.payload
            }
        case presetType.SET_ADDED_LEDGERS:
            return {
                ...state,
                addedLedgers: action.payload
            }
        case presetType.SET_ADJUSTMENTS:
            return {
                ...state,
                adjustments: action.payload
            }
        case presetType.SET_TASK_COMMENTS:
            return {
                ...state,
                comments: action.payload
            }
        case presetType.SPLIT_MAPPING_CHECKED:
        return {
            ...state,
            mappingChecked: action.payload
        }
        default:
            return state;
    }
};

export default BookClosureReducer;
