import { getConventionalMode, mapBatchConventionalMode, mapConventionalMode } from "../../../services/api/apiHandler" 
import { socket } from "../../../services/socket/socket";
export const fetchConventionalMode = (project_id, tb_id) => {
    return dispatch => {
        dispatch({type:"CM_ACTION_START"})
        getConventionalMode(project_id, tb_id).then((result)=>{
            dispatch({
                type: "CM_FETCH_DATA",
                payload: {data: result.data, set_payload: JSON.stringify({project_id, tb_id})}
            });
        }).catch((err)=>{
            dispatch({
                type:"CM_FETCH_DATA_FAILED",
                payload: err.response.data.error
            })
        })        
    }
}


export const updateConventionalMode = (setPayload, updatedData) => {
    return dispatch => {
        const {project_id, tb_id} = JSON.parse(setPayload);
        mapConventionalMode(project_id, tb_id, {updatedData}).then((response)=>{
            dispatch({
                type: "CM_UPDATING_DATA",
                payload: {data: response.data.data.line_items}
            });
            socket.emit("request-balance-sheet", {project_id, tb_id});
            socket.emit("request-mapped-grouping", {project_id, tb_id});
            socket.emit("request-pnl-statement", {project_id, tb_id})
        }).catch((err)=>{
            dispatch({
                type:"CM_FETCH_DATA_FAILED",
                payload: err.response.data.message
            })
        })
    }
}

export const batchUpdateConventionalMode = (setPayload, updatedData) => {
    return dispatch => {
        const {project_id, tb_id} = JSON.parse(setPayload);
        mapBatchConventionalMode(project_id, tb_id, {updatedData}).then((response)=>{
            dispatch({
                type: "CM_UPDATING_DATA",
                payload: {data: response.data.data.line_items}
            });
            socket.emit("request-balance-sheet", {project_id, tb_id})
            socket.emit("request-mapped-grouping", {project_id, tb_id})
            socket.emit("request-pnl-statement", {project_id, tb_id})
        }).catch((err)=>{
            dispatch({
                type:"CM_FETCH_DATA_FAILED",
                payload: err.response.data.message
            })
        })
    }
}

export const deleteLineItems = (deletedItems, project_id, tb_id) => {
    return dispatch => {
        dispatch({
            type: "CM_DELETE_LINE_ITEM",
            payload: {data: deletedItems}
        });
        socket.emit("request-balance-sheet", {project_id, tb_id})
        socket.emit("request-mapped-grouping", {project_id, tb_id})
        socket.emit("request-pnl-statement", {project_id, tb_id})
    }
}

export const restoreLineItems = (restoreData, project_id, tb_id) => {
    return dispatch => {
        dispatch({
            type: "CM_RESTORE_LINE_ITEM",
            payload: {data: restoreData}
        });
        socket.emit("request-balance-sheet", {project_id, tb_id})
        socket.emit("request-mapped-grouping", {project_id, tb_id})
        socket.emit("request-pnl-statement", {project_id, tb_id})
    }
}

export const restoreMultiLineItems = (project_id, tb_id) => {
    return dispatch => {
        socket.emit("request-balance-sheet", {project_id, tb_id})
        socket.emit("request-mapped-grouping", {project_id, tb_id})
        socket.emit("request-pnl-statement", {project_id, tb_id})
    }
}

export const addFilterLineItems = (filterDatas) => {
    return dispatch => {
        dispatch({
            type: "CM_FILTERED_LINE_ITEM",
            payload: {data:filterDatas}
        })
    }
}

export const socketCMUpdate = (response, payload) => {
    return dispatch => {
        dispatch({
            type: "CM_SOCKET_UPDATE_DATA", 
            payload: { 
                data: response, 
                set_payload: payload 
            }
        })
    }
}
