import React, { useState, useContext, useMemo } from "react";
import { TextareaAutosize } from "@material-ui/core";
import * as actionTypes from "../../../redux/actionTypes";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { toast } from "react-toastify";
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from "react-datepicker";
import { DropdownButton, Dropdown } from "react-bootstrap";

import cross from "../../../assets/cross.svg";
import "../../../resource/style/extra.css";
import { Submit } from "../../../Components/Button";
import { socket } from "../../../services/socket/socket";
import {
    AccessProjectContext,
    ThemeContext,
} from "../../../helper/DarkModeContext";
import AddLedger from "../../../Components/addLedger/AddLedger";
import { fetchDeletedAdj, fetchSAAdjustment } from "../../../redux/actions/actionStandaloneAdj";
import { deleteAdjustment } from "../../../services/api/apiHandler";
import Loading from "../../../Components/Common/Loader/Loading";

const adjustmentsTable = {
    maxHeight: "71vh",
    overflow: "scroll",
    minHeight: "71vh",
    width: "100vw"
};

const AdjustmentsTable = (props) => {
    const { loading, adjustmentData, ledgerData, projectData } = props;

    const dispatch = useDispatch();
    const project_id = localStorage.getItem("project_id");
    const tb_id = useSelector((initialState) => initialState.reducer.tb_id) || localStorage.getItem("tb_id");

    const [line, setLine] = useState([]);
    const [edit, setEdit] = useState(null);
    const [editval, setEditval] = useState(null);
    const [options, setOptions] = useState([]);
    const [remark, setRemark] = useState("");
    const [render, setRender] = useState("");
    const [value, setValue] = useState({});
    const [updateValue, setupdateValue] = useState("");
    const [codeoptions, setCodeOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [select, setSelect] = useState({});
    const [isUpdate, setUpdate] = useState(false);
    const span = line.length;
    const { theme } = useContext(ThemeContext);

    const { access } = useContext(AccessProjectContext);

    useMemo(() => {
        ledgerData.map((val) => {
            setOptions((options) => [
                ...options,
                { value: val.lid, label: `${val.ledger_name}` },
            ]);

            setCodeOptions((codeoptions) => [
                ...codeoptions,
                {
                    value: val.lid,
                    label: `${val.ledger_code}`,
                },
            ]);
        });

        return () => {
            setOptions([]);
            setCodeOptions([]);
        };
    }, [ledgerData]);

    function handleSelect(e) {
        if (e) {
            ledgerData.map((tab) => {
                if (line.some((user) => user.lid === tab.lid)) {
                    // swal("", "Ledger Item already selected", "warning")
                    return;
                }
                if (tab.ledger_name === e.target.textContent) {
                    const content = tab;
                    content.isAdjusted = true;
                    content.adjusted_amount = 0;
                    let newList = line.concat(content);
                    setLine([...line, content]);
                }
            });
        }
    }

    function handleSelectcode(e) {
        if (e) {
            ledgerData.map((tab) => {
                if (line.some((user) => user.lid === tab.lid)) {
                    return;
                }
                if (tab.ledger_code === e.target.textContent) {
                    const content = tab;
                    content.isAdjusted = true;
                    content.adjusted_amount = 0;
                    let newList = line.concat(content);
                    setLine([...line, content]);
                }
            });
        }
    }

    function handleChange(i, e, field) {
        if (field === "adjusted_amount") {
            let newLine = line;
            newLine[i].adjusted_amount = e.target.value;
            newLine[i].net_adjusted_amount = line[i].cy + Number(e.target.value);
            let val = Math.random();
            setLine(newLine);
            setEditval(val);
        }
    }

    function handlePost() {
        line.map((li) => {
            if ((li.adjusted_amount === "") | (li.adjusted_amount === 0)) {
                swal("", "Adjustments cant be empty", "warning");
                return;
            }
        });
        if (line.length === 0) {
            swal("", "Adjustments cant be empty", "warning");
            return;
        }
        const sum = line.reduce(
            (totalpy, i) => totalpy + Number(i.adjusted_amount),
            0
        );
        if (sum == 0) {
            let ledger_items = [];
            adjustmentData.map((adj, i) => {
                var sortedKeys = Object.keys(adj).sort();
                var first = adj[sortedKeys[0]];
                first.map((val, ix) => {
                    if (
                        ledger_items.length > 0 &&
                        ledger_items.some((user) => user.lid === val.lid)
                    ) {
                        let index1 = ledger_items.findIndex((x) => x.lid === val.lid);
                        ledger_items[index1].adjusted_amount =
                            Number(ledger_items[index1].adjusted_amount) +
                            Number(val.adjusted_amount);
                    } else {
                        ledger_items.push({
                            lid: val.lid,
                            ledger_name: val.ledger_name,
                            ledger_code: val.ledger_code,
                            branch: val.branch,
                            adjusted_amount: Number(val.adjusted_amount),
                            cy: Number(val.cy),
                        });
                    }
                });
            });

            line.map((itm) => {
                if (
                    ledger_items.length > 0 &&
                    ledger_items.some((user) => user.lid === itm.lid)
                ) {
                    let index1 = ledger_items.findIndex((x) => x.lid === itm.lid);
                    ledger_items[index1].adjusted_amount =
                        Number(ledger_items[index1].adjusted_amount) +
                        Number(itm.adjusted_amount);
                } else {
                    ledger_items.push({
                        lid: itm.lid,
                        ledger_name: itm.ledger_name,
                        ledger_code: itm.ledger_code,
                        branch: itm.branch,
                        adjusted_amount: Number(itm.adjusted_amount),
                        cy: Number(itm.cy),
                    });
                }
            });
            const auth = localStorage.getItem("auth_token");
            let headers = { "x-auth-token": auth };
            const line_dat = line;
            const string = {};
            string.data = line_dat;
            string.remarks = remark;
            string.date = value.date;
            let stringAdjustment = JSON.stringify(string);
            let stringItems = JSON.stringify(ledger_items);
            const fd = new FormData();
            fd.append("adjustments", stringAdjustment);
            fd.append("ledger_items", stringItems);
            axios
                .post(
                    `/api/v1/adjustments/save-adjustment-line-item/${project_id}/${tb_id}`,
                    fd,
                    { headers }
                )
                .then((res) => {
                    dispatch(fetchSAAdjustment(project_id, tb_id));
                    setLine([]);
                    setRemark("");
                    setValue({});
                    socket.emit("request-conventional-mode", { project_id, tb_id })
                    socket.emit("request-mapped-grouping", { project_id, tb_id });
                    socket.emit("request-balance-sheet", { project_id, tb_id })
                    socket.emit("request-pnl-statement", { project_id, tb_id });
                    console.log("socket emit")
                })
                .catch((err) => {
                    setRemark("");
                    setValue({});
                    dispatch(fetchSAAdjustment(project_id, tb_id));
                    setLine([]);
                });
        }
        if (sum != 0) {
            swal("", "Adjustments sum should be 0", "warning");
        }
    }

    const handleDelete = (item, index) => {
        const data = {
            item,
            index
        }

        swal({
            title: "Are you sure?",
            text: "Click Ok to delete adjustment item!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    deleteAdjustment(project_id, tb_id, data).then(response => {
                        toast.success(response.data?.message || "Item Deleted Successfully");
                        dispatch(fetchSAAdjustment(project_id, tb_id));
                        dispatch(fetchDeletedAdj(project_id, tb_id));
                        socket.emit("request-conventional-mode", { project_id, tb_id })
                        socket.emit("request-mapped-grouping", { project_id, tb_id });
                        socket.emit("request-balance-sheet", { project_id, tb_id })
                        socket.emit("request-pnl-statement", { project_id, tb_id });
                    }).catch(error => {
                        swal("Error", error.response?.data?.error || "Internal Server Error", "error")
                    })
                }
            });
    }

    function handleEdit(i, first, value, date) {
        setEdit(i);
        setLine(first);
        setRemark(value);
        setupdateValue({ updatedate: date });
        props.setShowCreate(false);
        props.setEditOn(true);
    }

    const nf = new Intl.NumberFormat();

    function handleSaveEdit(id) {
        line.map((li) => {
            if ((li.adjusted_amount === "") | (li.adjusted_amount === 0)) {
                swal("", "Adjustments cant be empty", "warning");
                return;
            }
        });
        if (line.length === 0) {
            swal("", "Adjustments cant be empty", "warning");
            return;
        }
        const sum = line.reduce(
            (totalpy, i) => totalpy + Number(i.adjusted_amount),
            0
        );
        if (sum == 0) {
            const value = id[0];
            let ledger_items = [];
            adjustmentData.map((adj, i) => {
                var sortedKeys = Object.keys(adj).sort();
                var first = adj[sortedKeys[0]];
                first.map((val, ix) => {
                    if (
                        ledger_items.length > 0 &&
                        ledger_items.some((user) => user.lid === val.lid)
                    ) {
                        let index1 = ledger_items.findIndex((x) => x.lid === val.lid);
                        ledger_items[index1].adjusted_amount =
                            Number(ledger_items[index1].adjusted_amount) +
                            Number(val.adjusted_amount);
                    } else {
                        ledger_items.push({
                            lid: val.lid,
                            ledger_name: val.ledger_name,
                            ledger_code: val.ledger_code,
                            branch: val.branch,
                            adjusted_amount: Number(val.adjusted_amount),
                            cy: Number(val.cy),
                        });
                    }
                });
            });

            const auth = localStorage.getItem("auth_token");
            let headers = { "x-auth-token": auth };
            const string = {};
            string.data = line;
            string.remarks = remark;
            string.date = updateValue.updatedate;
            let stringAdjustment = JSON.stringify(string);
            let stringItems = JSON.stringify(ledger_items);
            const fd = new FormData();
            fd.append("adjustment", stringAdjustment);
            fd.append("ledger_items", stringItems);
            axios
                .post(
                    `/api/v1/adjustments/edit-adjustment/${project_id}/${tb_id}/${value}`,
                    fd,
                    { headers }
                )
                .then((res) => {
                    setEdit(null);
                    props.setEditOn(false);
                    props.setShowCreate(false);
                    dispatch(fetchSAAdjustment(project_id, tb_id));

                    setLine([]);
                    setRemark("");
                    setValue({});

                    socket.emit("request-conventional-mode", { project_id, tb_id })
                    socket.emit("request-mapped-grouping", { project_id, tb_id });
                    socket.emit("request-balance-sheet", { project_id, tb_id })
                    socket.emit("request-pnl-statement", { project_id, tb_id });
                })
                .catch((err) => {
                    dispatch(fetchSAAdjustment(project_id, tb_id));
                    props.setShowCreate(false);
                    props.setEditOn(false);
                    setLine([]);
                    setRemark("");
                    setValue({});
                });
        }
        if (sum != 0) {
            swal("", "Adjustments sum should be 0", "warning");
        }
    }

    function handleCancel() {
        setEdit(null);
        setLine([]);
        props.setShowCreate(false);
        props.setEditOn(false);
    }

    function handleAddOption() {
        setOpen(true);
    }

    function handleRemark(e) {
        setRemark(e.target.value);
    }

    function handleDrop(i) {
        var arr1 = line;
        delete arr1[i];
        var arr2 = line.splice(i, 1);
        // const result = line.filter((row,ix) => ix !== i )
        setLine(arr1);
        setRender(Math.random());
    }


    return (
        <React.Fragment>
            {open && (
                <AddLedger close={setOpen} name="Add Ledger Below" select={select} tb_id={tb_id} setUpdate={setUpdate} />
            )}

            <div className="adjustmentsTable" style={adjustmentsTable}>
                {
                    loading ? <Loading /> :

                        <table
                            className={`${access.access === "View" ? "disabled-table" : ""}`}
                        >
                            <thead>
                                <tr>
                                    <th
                                        className="text-left font-inter"
                                        style={{
                                            minWidth: "200px",
                                            height: "62px",
                                        }}
                                    >
                                        S NO
                                    </th>
                                    <th
                                        className="text-left font-inter"
                                        style={{
                                            minWidth: "200px",
                                            height: "62px",
                                        }}
                                    >
                                        DATE
                                    </th>
                                    <th className="text-left font-inter" style={{ minWidth: "200px" }}>LEDGER CODE</th>
                                    <th className="text-left font-inter" style={{ minWidth: "400px" }}>LEDGER NAME</th>
                                    <th className="text-left font-inter" style={{ minWidth: "200px" }}>
                                        BRANCH
                                    </th>
                                    <th
                                        className="text-left font-inter"
                                        style={{
                                            minWidth: "200px",
                                            height: "62px",
                                        }}
                                    >
                                        31 MAR 2021
                                    </th>
                                    <th className="text-left font-inter" style={{ minWidth: "200px" }}>ADJUSTMENT VALUE</th>
                                    <th className="text-left font-inter" style={{ minWidth: "400px" }}>REMARKS</th>
                                    <th
                                        className="text-left font-inter"
                                        style={{
                                            minWidth: "200px",
                                        }}
                                    >
                                        ACTION
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {adjustmentData?.length > 0 && adjustmentData.map((adj, i) => {
                                    var sortedKeys = Object.keys(adj).sort();
                                    var first = adj[sortedKeys[0]];
                                    if (i == edit) {
                                        return (
                                            <>
                                                {line.map((lines, i) => {
                                                    if (i === 0) {
                                                        return (
                                                            <tr
                                                                style={{
                                                                    background: `${theme ? "" : "#363535"}`,
                                                                }}
                                                                key={i}
                                                            >
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                    rowSpan={span + 1}
                                                                >
                                                                    {adjustmentData.length}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                    rowSpan={span + 1}
                                                                >
                                                                    <DatePicker dateFormat="dd/MM/yyyy" selected={moment(updateValue.updatedate).toDate()}
                                                                        onChange={(e) => {
                                                                            setupdateValue({
                                                                                ...updateValue,
                                                                                ["updatedate"]: e,
                                                                            });
                                                                        }}
                                                                        minDate={moment(projectData.previous_year).toDate()}
                                                                        maxDate={moment(projectData.current_year).toDate()} />
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign: "center",
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {lines.ledger_code}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign: "left",
                                                                        color: "GrayText",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <div>{lines.ledger_name}</div>
                                                                    <div
                                                                        style={{ marginLeft: "auto" }}
                                                                        onClick={() => {
                                                                            handleDrop(i);
                                                                        }}
                                                                    >
                                                                        <img src={cross} />
                                                                        {/* {lines.ledger_name} */}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    {lines.branch}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign: "right",
                                                                        paddingRight: "10px",
                                                                    }}
                                                                >
                                                                    {nf.format(lines.cy)}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    <input
                                                                        type="number"
                                                                        value={lines.adjusted_amount}
                                                                        onChange={(e) => {
                                                                            handleChange(i, e, "adjusted_amount");
                                                                        }}
                                                                        style={{
                                                                            maxWidth: "140px",
                                                                            border: "none",
                                                                            textAlign: "right",
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                    rowSpan={span + 1}
                                                                >
                                                                    {line.length === 0 ? null : (
                                                                        <TextareaAutosize
                                                                            style={{
                                                                                paddingLeft: "10px",
                                                                                width: "100%",
                                                                                border: "none",
                                                                            }}
                                                                            aria-label="maximum height"
                                                                            value={remark}
                                                                            onChange={(e) => {
                                                                                handleRemark(e);
                                                                            }}
                                                                            maxRows={line.length}
                                                                            placeholder="Enter your remarks here"
                                                                        />
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "left" }}
                                                                    rowSpan={span + 1}
                                                                >
                                                                    {line.length === 0 ? null : (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-evenly",
                                                                                paddingLeft: "10px",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                onClick={() => {
                                                                                    handleSaveEdit(sortedKeys);
                                                                                }}
                                                                            >
                                                                                <Submit value="  save  " sm />
                                                                            </div>
                                                                            <div
                                                                                onClick={() => {
                                                                                    handleCancel();
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        fontWeight: "600",
                                                                                        color: "#03565A",
                                                                                        fontSize: "1.1rem",
                                                                                        margin: "0",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    return (
                                                        <tr
                                                            style={{
                                                                background: `${theme ? "" : "#363535"}`,
                                                            }}
                                                            key={i}
                                                        >
                                                            <td style={{ textAlign: "center" }}>
                                                                {lines.ledger_code}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign: "left",
                                                                    color: "GrayText",
                                                                    paddingLeft: "10px",
                                                                    paddingRight: "10px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <div>{lines.ledger_name}</div>
                                                                <div
                                                                    style={{ marginLeft: "auto" }}
                                                                    onClick={() => {
                                                                        handleDrop(i);
                                                                    }}
                                                                >
                                                                    <img src={cross} />
                                                                    {/* {lines.ledger_name} */}
                                                                </div>
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {lines.branch}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign: "right",
                                                                    paddingRight: "10px",
                                                                }}
                                                            >
                                                                {nf.format(lines.cy)}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <input
                                                                    type="number"
                                                                    value={lines.adjusted_amount}
                                                                    onChange={(e) => {
                                                                        handleChange(i, e, "adjusted_amount");
                                                                    }}
                                                                    style={{
                                                                        maxWidth: "140px",
                                                                        border: "none",
                                                                        textAlign: "right",
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr
                                                    style={{
                                                        background: `${theme ? "" : "#363535"}`,
                                                    }}
                                                >
                                                    <td style={{ textAlign: "center" }}>
                                                        <DropdownButton
                                                            id="dropdown-item-button"
                                                            onClick={(e) => {
                                                                handleSelectcode(e);
                                                            }}
                                                            title={
                                                                // row.original.sub_grp
                                                                //   ? `${row.original.sub_grp.slice(0, 16)}` + `...`
                                                                //   :
                                                                "Select Ledger Code"
                                                            }
                                                        >
                                                            {codeoptions.map((opt, ix) => {
                                                                return (
                                                                    <Dropdown.Item as="button" key={ix}>
                                                                        {opt.label}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                        </DropdownButton>
                                                    </td>

                                                    <td
                                                        style={{
                                                            textAlign: "center",
                                                            color: "black",
                                                        }}
                                                    >
                                                        {/* <Select
                            placeholder=""
                            as="select"
                            options={options}
                            // placeholder='select to add'
                            isClearable
                            onChange={(e) => {
                            handleSelect(e);
                            }}
                            theme={(theme) => ({
                            ...theme,
                            borderRadius: "0px",
                            colors: {
                                ...theme.colors,
                                borderColor: "white",
                                backgroundColor: "white",
                                primary25: "#03565a98",
                                primary: "#03565A",
                            },
                            spacing: {
                                ...theme.spacing,
                                controlHeight: 20,
                            },
                            })}
                        /> */}

                                                        <DropdownButton
                                                            //   value={row.note_grp}
                                                            id="dropdown-item-button"
                                                            // onClick={(e) =>
                                                            //   handleChanges(e, ind, row, "sub_grp")
                                                            // }
                                                            onClick={(e) => {
                                                                handleSelect(e);
                                                            }}
                                                            title={
                                                                // row.original.sub_grp
                                                                //   ? `${row.original.sub_grp.slice(0, 16)}` + `...`
                                                                //   :
                                                                "Select Ledger Name"
                                                            }
                                                        >
                                                            {options.map((opt, ix) => {
                                                                return (
                                                                    <Dropdown.Item as="button" key={ix}>
                                                                        {opt.label}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                        </DropdownButton>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}></td>

                                                    <td style={{ textAlign: "center" }}></td>
                                                    <td style={{ textAlign: "center" }}></td>
                                                    <td style={{ textAlign: "center" }}></td>
                                                </tr>
                                            </>
                                        );
                                    }

                                    return first.map((val, ix) => {
                                        const inx = first.length;
                                        const height = inx * 45 - 10;
                                        const value = adj["remarks"];
                                        const date = adj["date"];
                                        if (ix === 0) {
                                            return (
                                                <tr
                                                    style={{
                                                        background: `${theme ? "" : "#363535"}`,
                                                    }}
                                                    key={ix}
                                                >
                                                    <td
                                                        style={{ textAlign: "center" }}
                                                        rowSpan={first.length}
                                                    >
                                                        {i + 1}
                                                    </td>
                                                    <td
                                                        style={{ textAlign: "center", padding: "10px" }}
                                                        rowSpan={first.length}
                                                    >
                                                        <div
                                                            style={{
                                                                maxHeight: `${height}px`,
                                                                // ,maxHeight:'80px'
                                                                overflowY: "scroll",
                                                            }}
                                                        >
                                                            {moment(date).format("DD/MM/yy")}
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {val.ledger_code}
                                                    </td>
                                                    <td
                                                        style={{

                                                            textAlign: "left",
                                                            paddingLeft: "10px",
                                                        }}
                                                    >
                                                        {val.ledger_name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {val.branch}
                                                    </td>
                                                    <td
                                                        style={{

                                                            textAlign: "right",
                                                            paddingRight: "10px",
                                                        }}
                                                    >
                                                        {nf.format(val.cy)}
                                                    </td>
                                                    <td
                                                        style={{

                                                            textAlign: "right",
                                                            paddingRight: "10px",
                                                        }}
                                                    >
                                                        {nf.format(val.adjusted_amount)}
                                                    </td>
                                                    <td
                                                        style={{ textAlign: "center", padding: "10px" }}
                                                        rowSpan={first.length}
                                                    >
                                                        <div
                                                            style={{
                                                                maxHeight: `${height}px`,
                                                                // ,maxHeight:'80px'
                                                                overflowY: "scroll",
                                                            }}
                                                        >
                                                            {value}
                                                        </div>
                                                    </td>
                                                    <td className="text-left" rowSpan={first.length}>
                                                        <div
                                                            className="d-flex justify-content-center align-items-center"
                                                        >
                                                            <div
                                                                onClick={() => {
                                                                    handleEdit(i, first, value, date);
                                                                }}
                                                            >
                                                                <Submit value="  Edit  " sm />
                                                            </div>
                                                            <div
                                                                className="cursor-pointer ml-2"
                                                                onClick={() => {
                                                                    handleDelete(adj, i);
                                                                }}
                                                            >
                                                                <p
                                                                    className="custom-font-600 custom-common-theme-text-color m-0 font-size-18"
                                                                >
                                                                    Delete
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            return (
                                                <tr
                                                    style={{
                                                        background: `${theme ? "" : "#363535"}`,
                                                    }}
                                                    key={ix}
                                                >
                                                    <td style={{ textAlign: "center" }}>
                                                        {val.ledger_code}
                                                    </td>
                                                    <td
                                                        style={{

                                                            textAlign: "left",
                                                            paddingLeft: "10px",
                                                        }}
                                                    >
                                                        {val.ledger_name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {val.branch}
                                                    </td>
                                                    <td
                                                        style={{

                                                            textAlign: "right",
                                                            paddingRight: "10px",
                                                        }}
                                                    >
                                                        {nf.format(val.cy)}
                                                    </td>
                                                    <td
                                                        style={{

                                                            textAlign: "right",
                                                            paddingRight: "10px",
                                                        }}
                                                    >
                                                        {nf.format(val.adjusted_amount)}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    });
                                })}

                                {props.showCreate && line.length > 0 && (
                                    <>
                                        {props.showCreate &&
                                            line.map((lines, i) => {
                                                if (i === 0) {
                                                    return (
                                                        <tr
                                                            style={{
                                                                background: `${theme ? "" : "#363535"}`,
                                                            }}
                                                            key={i}
                                                        >
                                                            <td
                                                                style={{ textAlign: "center" }}
                                                                rowSpan={span + 1}
                                                            >
                                                                {adjustmentData.length + 1}
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center" }}
                                                                rowSpan={span + 1}
                                                            >
                                                                {/* <input
                                type="date"
                                maxDate={moment().toDate()}
                                value={value.date}
                                // onChange={(e) => {
                                //   setValue({
                                //     ...value,
                                //     ["date"]: e.target.value,
                                //   });
                                // }}
                                ></input> */}
                                                                <DatePicker dateFormat="dd/MM/yyyy" selected={value.date}
                                                                    placeholderText="Select Date"
                                                                    onChange={(e) => {
                                                                        setValue({ ...value, ["date"]: e });
                                                                    }}
                                                                    minDate={moment(projectData.previous_year).toDate()}
                                                                    maxDate={moment(projectData.current_year).toDate()} />
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {lines.ledger_code}
                                                            </td>
                                                            <td
                                                                style={{

                                                                    textAlign: "left",
                                                                    color: "GrayText",
                                                                    paddingLeft: "10px",
                                                                    paddingRight: "10px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <div>{lines.ledger_name}</div>
                                                                <div
                                                                    style={{ marginLeft: "auto" }}
                                                                    onClick={() => {
                                                                        handleDrop(i);
                                                                    }}
                                                                >
                                                                    <img src={cross} />
                                                                    {/* {lines.ledger_name} */}
                                                                </div>
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {lines.branch}
                                                            </td>
                                                            <td
                                                                style={{

                                                                    textAlign: "right",
                                                                    paddingRight: "10px",
                                                                }}
                                                            >
                                                                {nf.format(lines.cy)}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <input
                                                                    type="number"
                                                                    onChange={(e) => {
                                                                        handleChange(i, e, "adjusted_amount");
                                                                    }}
                                                                    style={{
                                                                        maxWidth: "140px",
                                                                        border: "none",
                                                                        textAlign: "right",
                                                                    }}
                                                                />
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center" }}
                                                                rowSpan={span + 1}
                                                            >
                                                                {line.length === 0 ? null : (
                                                                    <TextareaAutosize
                                                                        style={{
                                                                            paddingLeft: "10px",
                                                                            width: "100%",
                                                                            border: "none",
                                                                        }}
                                                                        aria-label="maximum height"
                                                                        maxRows={3}
                                                                        value={remark}
                                                                        onChange={(e) => {
                                                                            handleRemark(e);
                                                                        }}
                                                                        placeholder="Enter your remarks here"
                                                                    />
                                                                )}
                                                            </td>
                                                            <td style={{ textAlign: "left" }} rowSpan={span + 1}>
                                                                {line.length === 0 ? null : (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-evenly",
                                                                            paddingLeft: "10px",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            onClick={() => {
                                                                                handlePost();
                                                                            }}
                                                                        >
                                                                            <Submit value="  save  " sm />
                                                                        </div>
                                                                        <div
                                                                            onClick={() => {
                                                                                handleCancel();
                                                                            }}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    fontWeight: "600",
                                                                                    color: "#03565A",
                                                                                    fontSize: "1.1rem",
                                                                                    margin: "0",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                Cancel
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                                return (
                                                    <tr
                                                        style={{
                                                            background: `${theme ? "" : "#363535"}`,
                                                        }}
                                                        key={i}
                                                    >
                                                        <td style={{ textAlign: "center" }}>
                                                            {lines.ledger_code}
                                                        </td>

                                                        <td
                                                            style={{

                                                                textAlign: "left",
                                                                color: "GrayText",
                                                                paddingLeft: "10px",
                                                                paddingRight: "10px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div>{lines.ledger_name}</div>
                                                            <div
                                                                style={{ marginLeft: "auto" }}
                                                                onClick={() => {
                                                                    handleDrop(i);
                                                                }}
                                                            >
                                                                <img src={cross} />
                                                                {/* {lines.ledger_name} */}
                                                            </div>
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {lines.branch}
                                                        </td>
                                                        <td
                                                            style={{

                                                                textAlign: "right",
                                                                paddingRight: "10px",
                                                            }}
                                                        >
                                                            {nf.format(lines.cy)}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <input
                                                                type="number"
                                                                onChange={(e) => {
                                                                    handleChange(i, e, "adjusted_amount");
                                                                }}
                                                                style={{
                                                                    maxWidth: "140px",
                                                                    border: "none",
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        <tr
                                            style={{
                                                background: `${theme ? "" : "#363535"}`,
                                            }}
                                        >
                                            <td
                                                style={{

                                                    textAlign: "center",
                                                    color: "black",
                                                }}
                                            >
                                                {/* <p
                        style={{
                            fontSize: "1.1rem",
                            fontSize: "500",
                            padding: "0",
                            margin: "5px",
                            color: "var(--clr-accent)",
                            cursor: "pointer",
                        }}
                        >
                        + add options
                        </p> */}
                                                {/* <Select
                        placeholder=""
                        as="select"
                        options={codeoptions}
                        // placeholder='select to add'
                        isClearable
                        onChange={(e) => {
                            handleSelectcode(e);
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: "0px",
                            zIndex: "0",
                            colors: {
                            ...theme.colors,
                            borderColor: "white",
                            backgroundColor: "white",
                            primary25: "#03565a98",
                            primary: "#03565A",
                            },
                            spacing: {
                            ...theme.spacing,
                            controlHeight: 20,
                            // zIndex:0
                            },
                        })}
                        /> */}

                                                {/* dropdown */}
                                                <DropdownButton
                                                    id="dropdown-item-button"
                                                    onClick={(e) => {
                                                        handleSelectcode(e);
                                                    }}
                                                    title={
                                                        // row.original.sub_grp
                                                        //   ? `${row.original.sub_grp.slice(0, 16)}` + `...`
                                                        //   :
                                                        "Select Ledger Code"
                                                    }
                                                >
                                                    {codeoptions.map((opt, ix) => {
                                                        return (
                                                            <Dropdown.Item as="button" key={ix}>
                                                                {opt.label}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item>
                                                        <p
                                                            style={{
                                                                fontSize: "1.1rem",
                                                                fontSize: "500",
                                                                padding: "0",
                                                                margin: "5px",
                                                                color: "var(--clr-accent)",
                                                            }}
                                                            onClick={() => {
                                                                handleAddOption();
                                                            }}
                                                        >
                                                            + add options
                                                        </p>
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            <td
                                                style={{

                                                    textAlign: "center",
                                                    color: "black",
                                                }}
                                            >
                                                {/* <p
                        style={{
                            fontSize: "1.1rem",
                            fontSize: "500",
                            padding: "0",
                            margin: "5px",
                            color: "var(--clr-accent)",
                            cursor: "pointer",
                        }}
                        >
                        + add options
                        </p> */}
                                                {/* <Select
                        placeholder=""
                        as="select"
                        options={options}
                        // placeholder='select to add'
                        isClearable
                        onChange={(e) => {
                            handleSelect(e);
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: "0px",
                            zIndex: "0",
                            colors: {
                            ...theme.colors,
                            borderColor: "white",
                            backgroundColor: "white",
                            primary25: "#03565a98",
                            primary: "#03565A",
                            },
                            spacing: {
                            ...theme.spacing,
                            controlHeight: 20,
                            // zIndex:0
                            },
                        })}
                        /> */}

                                                {/* dropdown */}
                                                <DropdownButton
                                                    //   value={row.note_grp}
                                                    id="dropdown-item-button"
                                                    // onClick={(e) =>
                                                    //   handleChanges(e, ind, row, "sub_grp")
                                                    // }
                                                    onClick={(e) => {
                                                        handleSelect(e);
                                                    }}
                                                    title={
                                                        // row.original.sub_grp
                                                        //   ? `${row.original.sub_grp.slice(0, 16)}` + `...`
                                                        //   :
                                                        "Select Ledger Name"
                                                    }
                                                >
                                                    {/* {groupings.grp.map((opt, ix) => {
                            if (row.original.fs_grp === opt.fs_grp) {
                                return opt.notes_grp.map((notes) => {
                                if (
                                    notes.notes_grp === row.original.note_grp
                                ) {
                                    return notes.sub_grp.map((sub, ix) => {
                                    return (
                                        <Dropdown.Item as="button" key={ix}>
                                        {sub.sub_grp}
                                        </Dropdown.Item>
                                    );
                                    });
                                }
                                });
                            }
                            })} */}

                                                    {options.map((opt, ix) => {
                                                        return (
                                                            <Dropdown.Item as="button" key={ix}>
                                                                {opt.label}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                    <Dropdown.Divider />
                                                </DropdownButton>
                                            </td>
                                            <td style={{ textAlign: "center" }}></td>
                                            <td style={{ textAlign: "center" }}></td>
                                            <td style={{ textAlign: "center" }}></td>
                                            {/* <td style={{textAlign:'center',}}></td> */}
                                            {/* <td style={{textAlign:'center'}} rowSpan={span + 1}>
                                {line.length === 0? null : 
                                <TextareaAutosize  
                                style={{paddingLeft:'10px',width:'100%',border:'none'}} 
                                aria-label="maximum height" 
                                maxRows={1} 
                                value={remark}
                                onChange={(e)=>{handleRemark(e)}}
                                placeholder="enter your remark here ..." />
                                }
                            </td>  */}
                                        </tr>
                                    </>
                                )}
                                {props.showCreate && line.length === 0 && (
                                    <>
                                        <tr
                                            style={{
                                                background: `${theme ? "" : "#363535"}`,
                                            }}
                                        >
                                            <td style={{ textAlign: "center" }}></td>
                                            <td style={{ textAlign: "center" }}>
                                                {/* <input
                        type="date"
                        maxDate={moment().toDate()}
                        onChange={(e) => {
                            setValue({ ...value, ["date"]: e.target.value });
                        }}
                        ></input> */}
                                                <DatePicker dateFormat="dd/MM/yyyy"
                                                    placeholderText="Select Date"
                                                    selected={value.date}
                                                    onChange={(e) => {
                                                        setValue({ ...value, ["date"]: e });
                                                    }}
                                                    minDate={moment(projectData.previous_year).toDate()}
                                                    maxDate={moment(projectData.current_year).toDate()} />
                                            </td>
                                            <td
                                                style={{

                                                    textAlign: "center",
                                                    color: "black",
                                                }}
                                            >
                                                {/* <p
                        style={{
                        fontSize: "1.1rem",
                        fontSize: "500",
                        padding: "0",
                        margin: "5px",
                        color: "var(--clr-accent)",
                        cursor: "pointer",
                        }}
                    >
                        + add options
                    </p> */}
                                                {/* <Select
                        placeholder=""
                        as="select"
                        options={codeoptions}
                        // placeholder='select to add'
                        isClearable
                        onChange={(e) => {
                        handleSelectcode(e);
                        }}
                        theme={(theme) => ({
                        ...theme,
                        borderRadius: "0px",
                        zIndex: "0",
                        colors: {
                            ...theme.colors,
                            borderColor: "white",
                            backgroundColor: "white",
                            primary25: "#03565a98",
                            primary: "#03565A",
                        },
                        spacing: {
                            ...theme.spacing,
                            controlHeight: 20,
                            // zIndex:0
                        },
                        })}
                    /> */}

                                                {/* dropdown */}
                                                <DropdownButton
                                                    id="dropdown-item-button"
                                                    onClick={(e) => {
                                                        handleSelectcode(e);
                                                    }}
                                                    title={
                                                        // row.original.sub_grp
                                                        //   ? `${row.original.sub_grp.slice(0, 16)}` + `...`
                                                        //   :
                                                        "Select Ledger Code"
                                                    }
                                                >
                                                    {codeoptions.map((opt, ix) => {
                                                        return (
                                                            <Dropdown.Item as="button" key={ix}>
                                                                {opt.label}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item>
                                                        <p
                                                            style={{
                                                                fontSize: "1.1rem",
                                                                fontSize: "500",
                                                                padding: "0",
                                                                margin: "5px",
                                                                color: "var(--clr-accent)",
                                                            }}
                                                            onClick={() => {
                                                                handleAddOption();
                                                            }}
                                                        >
                                                            + add options
                                                        </p>
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            <td
                                                style={{

                                                    textAlign: "center",
                                                    color: "black",
                                                }}
                                            >
                                                {/* <p
                        style={{
                        fontSize: "1.1rem",
                        fontSize: "500",
                        padding: "0",
                        margin: "5px",
                        color: "var(--clr-accent)",
                        cursor: "pointer",
                        }}
                        onClick={() => {
                        handleAddOption();
                        }}
                    >
                        + add options
                    </p> */}
                                                {/* <Select
                        placeholder=""
                        as="select"
                        options={options}
                        // placeholder='select to add'
                        isClearable
                        onChange={(e) => {
                        handleSelect(e);
                        }}
                        theme={(theme) => ({
                        ...theme,
                        borderRadius: "0px",
                        zIndex: "0",
                        colors: {
                            ...theme.colors,
                            borderColor: "white",
                            backgroundColor: "white",
                            primary25: "#03565a98",
                            primary: "#03565A",
                        },
                        spacing: {
                            ...theme.spacing,
                            controlHeight: 20,
                            // zIndex:0
                        },
                        })}
                    /> */}

                                                {/* dropdown */}
                                                <DropdownButton
                                                    //   value={row.note_grp}
                                                    id="dropdown-item-button"
                                                    // onClick={(e) =>
                                                    //   handleChanges(e, ind, row, "sub_grp")
                                                    // }
                                                    onClick={(e) => {
                                                        handleSelect(e);
                                                    }}
                                                    title={
                                                        // row.original.sub_grp
                                                        //   ? `${row.original.sub_grp.slice(0, 16)}` + `...`
                                                        //   :
                                                        "Select Ledger Name"
                                                    }
                                                >
                                                    {options.map((opt, ix) => {
                                                        return (
                                                            <Dropdown.Item as="button" key={ix}>
                                                                {opt.label}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                    <Dropdown.Divider />
                                                </DropdownButton>
                                            </td>
                                            <td style={{ textAlign: "center" }}></td>
                                            <td style={{ textAlign: "center" }}></td>
                                            <td style={{ textAlign: "center" }}></td>
                                            <td style={{ textAlign: "center" }}></td>
                                            <td style={{ textAlign: "center" }}></td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                }
                {adjustmentData?.length <= 0 &&
                    <p className="w-100 text-center text-secondary" > Adjustment data is empty. </p>
                }
            </div>
        </React.Fragment>
    );
};

export default AdjustmentsTable;
