import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import Header from './header'
import axios from "axios";
import swal from "sweetalert";

// img
import logo from '../assets/images/logo/Logo.svg';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Select } from '@mui/material';
import InvestAdjustModal from './InvestAdjustmentModal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const style4 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 950,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AddInvestment = () => {
    const auth = localStorage.getItem("auth_token");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    let history = useHistory();
    const location = useLocation();
    console.log(location.pathname);
    const [company, setName] = useState({})
    let a = location.pathname;
    let b = a.split('/')
    const [allotherdata,setAllOtherData] = useState([]);
    const [data, setData] = useState("");
    const [totaldata, setDataTotal] = useState("");
    const [interest, setInterest] = useState("");
    const [natureinvest, setNatureInvest] = useState("");
    const [entryopen, setEntryOpen] = useState(false);
    const [optionentry,setOptionEntry] = useState("");
    const [browseOpen,setBrowseOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [eqityshare,setEquityShare] = useState([]);
    const[totaleqity,setTotalEquity] = useState(eqityshare * -1);
    const [investvalue,setInvestValue] = useState("");
    const[otherequityshare,setOtherEquityShare] = useState("");
    const[otherreservesurplus,setOtherReserveSurplus] = useState("");
    const[othereserve,setOtherReserve] = useState("");
    const [setfinalsum,setFinalSum] = useState("");
    const [datavalue,setDataValue] = useState("");
    const[finalconsoleentry,setFinalConsoleEntry] = useState({});
    const [getoneProject,setOneProject] = useState("");
    const [otherdetails,setOtherDetails] = useState([]);
    const [dateofinvest,setDateOfInvest] = useState("");
    const [newDetails,setNewDetails] = useState(false);
    const [otherallData,setDataother] = useState("");
    const [state, setstate] = useState([
        { column: "", select: ""}
    ]);
    const[detailsdata,setDetailsData] = useState("");
    const[selectedData,setSelectedData] = useState("");
    // let totalAmount = totaleqity +(otherequityshare * -1)+(otherreservesurplus * -1)+(othereserve * -1)+(investvalue * -1)+(setfinalsum * -1) + (interest * -1);
    const [companydate, setCompanyDate] = useState({
        investment_date: "",
        company: "",
        investment_value: "",
        shares_bought: "",
        tnositc: "",
        holding: "",
        non_controlling_interest: "",
        browse_control_dateFS: "",
        donotdoc: "",
        wticacct: "",
        // add_other_details:"",
    });
    const close = {
        height: "43px",
        width: "43px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        borderRadius: "6px",
        fontWeight: "normal",
        marginRight: "15px",
      };
    useEffect(() => {
        let headers = {
            "x-auth-token": auth,
            // 'Content-Type' : 'application/json'
        };
        axios
            .get(`api/v1/company/get-onecomapny/${b[2]}`, {
                headers,
            })
            .then((response) => {
                console.log(
                    response.data.getOneCompany,
                    "dat dat dat datd ddat dat dat dat dat dta dat d"
                );
                setName(response.data.getOneCompany[0])
            });
            fetchProject();
            
    }, [])

    const handleClose = () => {
        setOpen(false);
        setEntryOpen(false)
        setBrowseOpen(false)
    }
    const handleCloseData = ()=>{
        setNewDetails(false)
    }
    const handleChange = (field, e) => {

        if (field === "shares_bought") {
            let data = e.target.value;
            setData(data)
            if(datavalue){
                let holding = Number(data) / datavalue * 100;
                let noOfInterest = 100 - holding;
                console.log(holding,"/",noOfInterest,"/",data,"/",datavalue,"==================");
                setDataTotal(Number(holding))    
            setInterest(noOfInterest)
            }
           
        }
        if (field === "tnositc") {
            let data2 = e.target.value;
            console.log(data2);
            setDataValue(e.target.value);
            let holding = data / datavalue * 10;//if error dn 10 to change 100
            let noOfInterest = 100 - holding;
            setDataTotal(holding)
            setInterest(noOfInterest)

            if (holding > 50) {
                setNatureInvest("Subsidiary Company")
            } else if (holding <= 50 && holding >= 20) {
                setNatureInvest("Associate Company")
            } else {
                setNatureInvest("Investment")
            }
        }
        if (field === "investment_value") {
            let investvalue = Number(e.target.value);
            setInvestValue(investvalue)
        }
        // // 
        if (field === "investment_date") {
            setDateOfInvest(e.target.value)
        }
        setCompanyDate({ ...companydate, [field]: e.target.value })
    }
    function isEmpty(object) {
        return Object.keys(object).length === 0;
      }
    const handleSubmit = () => {
    console.log(finalconsoleentry,"1234567890",selectedOption);
    if (selectedOption) {
        if (isEmpty(finalconsoleentry)) {
        console.log("empty field");
        swal("", "Please Submit Create Console Entry", "warning");
        }else{
            console.log("submit");
            let headers = {
                "x-auth-token": auth,
                // 'Content-Type' : 'application/json'
            };
            const data = {
                cid: b[2],
                investment_date: companydate.investment_date,
                company: companydate.company,
                investment_value: companydate.investment_value,
                shares_bought: companydate.shares_bought,
                tnositc: companydate.tnositc,
                holding: totaldata,
                non_controlling_interest: interest,
                browse_control_dateFS: selectedOption,
                donotdoc: companydate.donotdoc,
                wticacct: companydate.wticacct,
                nature_of_relationship: companydate.nature_of_relation?companydate.nature_of_relation:natureinvest,
                add_other_details: state,
                console_entry:finalconsoleentry,
                final_no_of_shares:companydate.shares_bought
            }
            axios.post('api/v1/company-data/register-company-data', data, { headers })
                .then(res => {
                    console.log(res, 'res')
                    history.push(`/create-investment/${b[2]}`)
                })
                .catch(err => {
                    console.log(err.response)
                })
        }
    } else {
        let headers = {
            "x-auth-token": auth,
            // 'Content-Type' : 'application/json'
        };
        const data = {
            cid: b[2],
            investment_date: companydate.investment_date,
            company: companydate.company,
            investment_value: companydate.investment_value,
            shares_bought: companydate.shares_bought,
            tnositc: companydate.tnositc,
            holding: totaldata,
            non_controlling_interest: interest,
            browse_control_dateFS: selectedOption,
            donotdoc: companydate.donotdoc,
            wticacct: companydate.wticacct,
            nature_of_relationship: companydate.nature_of_relation?companydate.nature_of_relation:natureinvest,
            add_other_details: state,
            console_entry:finalconsoleentry,
            final_no_of_shares:companydate.shares_bought
        }
        axios.post('api/v1/company-data/register-company-data', data, { headers })
            .then(res => {
                console.log(res, 'res')
                history.push(`/create-investment/${b[2]}`)
            })
            .catch(err => {
                console.log(err.response)
            })
    }
    return "0";


       
    }

    const entryOpen = () => {
        if (selectedOption) {
        setEntryOpen(true)
        } else {
            swal("", "Please Upload Browse control date FS", "error");
        }
    }

    function fetchProject() {
        let headers = {
            "x-auth-token": auth,
            // 'Content-Type' : 'application/json'
        };
        axios.get('/api/v1/project/get-project', { headers })
        .then(res => {
            console.log(res.data.project, 'res')
            setOptionEntry(res.data.project);
        })
        .catch(err => {
            console.log(err.response)
        })
    }

    function browseFs() {
        setBrowseOpen(true);
    }
    const handleChangeFs=(e,data)=>{
        console.log(e.target.value);
        setSelectedOption(e.target.value)

    }   
    const handleSubmitFs=()=>{

        let headers = {
            "x-auth-token": auth,
            // 'Content-Type' : 'application/json'
        };
        axios.get(`/api/v1/grouping/fs/${selectedOption}`, { headers })
        .then(res => {
            console.log(res.data.fs_grp[9].notes_grp,'grouping data')
            setEquityShare(res.data.fs_grp[9].notes_grp);
            // setOtherEquityShare(res.data.fs_grp[2].notes_grp[1].sub_grp[0].cy_amt);
            // setOtherReserveSurplus(res.data.fs_grp[2].notes_grp[2].sub_grp[0].cy_amt);
            // setOtherReserve(res.data.fs_grp[2].notes_grp[3].sub_grp[0].cy_amt);
            findProject(selectedOption);
            setBrowseOpen(false)
        })
        .catch(err => {
            console.log(err.response)
        })
    }
    function handleDelete(i) {
        const temp = state;
        const newarr = temp.filter((val, ix) => ix !== i);
        setstate(newarr);
        if (newarr.length == 0) {
        }
      }

    function handleNew() {
        setstate((oldArray) => [
          ...oldArray,
          { column: "", select: ""},
        ]);
      }

      function handleChangeDetails(e,field,i) {
        console.log(e.target.value,"Add_newAdd_new");
        if (e.target.value === "Add_new") {
            setNewDetails(true);
        }else{

            const newArray = state.map((item, index) => {
                if (i === index) {
                    return { ...item, [e.target.name]: e.target.value};
                } else {
                  return item;
                }
            });
    
            setstate(newArray)
        }
        

        
      }

      const handleSave=()=>{
        console.log(state,"gfgdgshshsj");
        let sum = 0;
        setOpen(false);
        const newData = state.map((item, index) => {
            let someData = Number(item.select);
            sum += someData;
        })
        console.log(sum);
        setFinalSum(sum)
      }

     



      function findProject(project_id){
            let headers = {
                "x-auth-token": auth,
                // 'Content-Type' : 'application/json'
            };
            axios
                .get(`api/v1/project/get-project-details/${project_id}`, {
                    headers,
                })
                .then((response) => {
                    console.log(
                        response.data,
                        "fffffff"
                    );
                    setOneProject(response.data);
                });
      }
      const newData =(e)=>{
        setDataother(e.target.value)
    }
    
    const saveData = ()=>{
        console.log(otherallData,"6567474");
        // AllOtherDAta.push(otherallData);
        var dataAll= [...allotherdata,otherallData];
        setAllOtherData(dataAll)
        handleCloseData()
    }
console.log(allotherdata,"hihnsmms");
    return (
        <div>
            <div className="tap-top"><i data-feather="chevrons-up"></i></div>
            <div className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    {/* Page Sidebar Start */}
                    <div className="sidebar-wrapper">
                        <div>
                            <div className="logo-wrapper">
                                <Link href="index.html">
                                    <img className="img-fluid for-light" src={logo} alt="" />
                                    {/* <img className="img-fluid for-dark" src={logo_dark} alt="" /> */}
                                </Link>

                            </div>
                            <div className="logo-icon-wrapper">
                                <Link href="index.html">
                                    {/* <img className="img-fluid" src={logo_icon} alt="" /> */}
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Page Sidebar Ends */}
                    </div>
                    <div className="page-body mt-5">
                        <div className="container-fluid">
                            <div className="page-title">
                                <div className="row">
                                    <div className="col-6">
                                        <h3>Add Investment</h3>
                                    </div>
                                    <div className="col-6">
                                        <ol className="breadcrumb">
                                            {/* <li className="breadcrumb-item"><Link to="index.html"><i data-feather="home"></i></Link></li> */}
                                            <li class="breadcrumb-item">
                                                <Link to="index.html">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home">
                                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item">Dashboard</li>
                                            <li className="breadcrumb-item active">Add Investment</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts */}
                        <div className="container-fluid">
                            <div className="row second-chart-list third-news-update">
                                <div className="col-sm-12">
                                    <div className="card1">
                                        <form>
                                            <div className="card-body1" style={{ paddingBottom: '20px' }}>
                                                <div className="row">
                                                    <div className="col-6 form-group mt-3">
                                                        <label>Date of Investment</label>
                                                        <input onChange={(e) => handleChange('investment_date', e)} type="date" className="form-control" />
                                                        
                                                    </div>
                                                    <div className="col-5 offset-1 form-group">
                                                        <label>Company Name</label>
                                                        <input type="text" className="form-control" placeholder="Enter company name" onChange={(e) => handleChange('company', e)} />
                                                    </div>
                                                </div>
                                                <div className="row  mt-3">
                                                    <div className="col-6 form-group">
                                                        <label>Company Code</label>
                                                        <input type="text" className="form-control" value={company.company_code} disabled />
                                                    </div>
                                                    <div className="col-5 offset-1 form-group">
                                                        <label>Value of Investment</label>
                                                        <input onChange={(e) => handleChange('investment_value', e)} type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row  mt-3">
                                                    <div className="col-6 form-group">
                                                        <label>No. of shares bought</label>
                                                        <input onChange={(e) => handleChange('shares_bought', e)} type="text" className="form-control" />
                                                    </div>
                                                    <div className="col-5 offset-1 form-group">
                                                        <label>Total no. of shares in the company</label>
                                                        <input onChange={(e) => handleChange('tnositc', e)} type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-6 form-group">
                                                        <label>% Holding</label>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <input onChange={(e) => handleChange('holding', e)} type="text" className="form-control" placeholder="%" value={totaldata.toLocaleString("en-IN")} disabled />
                                                            </div>
                                                            {totaldata > 50 &&
                                                                <div className="col-md-6 text-center" onClick={() => entryOpen()}>
                                                                    <input type="button" className="btn btn-primary1 w-100" value="Create consol entry" />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-5 offset-1 form-group">
                                                        <div className="row">
                                                            <div className="col-md-6">

                                                                <label>Non-controlling interest %</label>
                                                                <input onChange={(e) => handleChange('non_controlling_interest', e)} type="text" className="form-control" value={interest.toLocaleString("en-IN")} disabled />
                                                            </div>
                                                            {totaldata > 50 &&
                                                                //
                                                                <div className="col-md-6 text-center mt-4" onClick={() => browseFs()}>
                                                                    <input type="button" className="btn btn-primary1 w-100" value="Browse control date FS" />
                                                                    <small>{getoneProject.project?getoneProject.project.project_name:''}</small>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {totaldata > 50 &&
                                                    <div className="row  mt-3">
                                                        <div className="col-6 form-group">
                                                            <label>Details of networth on the date of control</label>
                                                            <input onChange={(e) => handleChange('donotdoc', e)} type="text" className="form-control" />
                                                        </div>
                                                        <div className="col-5 offset-1 form-group">
                                                            <label>Add other details &nbsp; &nbsp;</label>
                                                            <div onClick={handleOpen} className="btn btn-primary1" data-bs-toggle="modal" data-bs-target="#Intangible">+</div>
                                                        </div>
                                                    </div>
                                                }
                                                {totaldata > 50 &&
                                                    <div className="row">
                                                        <div className="col-6 form-group">
                                                            <label>Whether transaction is covered as common control transaction</label>
                                                            <select onChange={(e) => handleChange('wticacct', e)} className="form-control">
                                                                <option>Yes</option>
                                                                <option>No</option>
                                                            </select>
                                                        </div>
                                                       
                                                            <div className="col-5 offset-1 form-group">
                                                                <div class="table-responsive table-bordered">
                                                                    <table class="table" style={{marginBottom:"0"}}>
                                                                        <tbody>
                                                                        {(state.length >  0  && state.map((item,v)=>(
                                                                                <tr>
                                                                                    <td>{item.column}</td>
                                                                                    <td>{item.select}</td>
                                                                                    {/* <td>X{v}</td> */}
                                                                                </tr>  
                                                                            )))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                    </div>
                                                }
                                                <div className="row mt-3">
                                                    <div className="col-6 form-group">
                                                        <label>Nature Of Relationship</label>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <select className="form-control" name='nature_of_relation' onChange={(e) => handleChange('nature_of_relation', e)} defaultValue={natureinvest}>
                                                                    <option>Select</option>
                                                                    <option value="Investment" selected={natureinvest === 'Investment'}>Investment</option>
                                                                    <option value="Associate Company" selected={natureinvest === 'Associate Company'}>Associate Company</option>
                                                                    <option value="Subsidiary Company" selected={natureinvest === 'Subsidiary Company'}>Subsidiary Company</option>
                                                                    <option value="Joint Venture" selected={natureinvest === 'Joint Venture'}>Joint Venture</option>
                                                                </select>
                                                            </div>
                                                            {/* <h6>{natureinvest}</h6> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="card-body1" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                                <input type="button" className="btn btn-primary1" value="Submit" onClick={handleSubmit} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  Container-fluid Ends */}
                    </div>

               
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                <Box sx={style}>
                    <div className="col-12">
                        <button type="button" onClick={handleClose} class="btn-close float-end"></button>
                        <div className="mb-3">
                            <div className="row">
                            {state.map((val, i) => {
                                return (
                                    <>
                                        <div className="col-md-6 mt-3">
                                                <select className="form-control" name='column' onChange={(e) => handleChangeDetails(e,'select',i)} style={{border:'1px'}}>
                                                    <option>Select</option>
                                                    <option value="Brand_trademarks">Brands/trademarks</option>
                                                    {allotherdata.map((data,i)=>(
                                                        <option key={i} value={data}>
                                                            {data}
                                                        </option>
                                                    ))
                                                    }
                                                    <option value="Add_new">Add New</option>
                                                </select>
                                        </div>
                                        <div className="col-md-4">
                                            <input type="text" name='select'  className="form-control" onChange={(e) => handleChangeDetails(e,'input',i)} />
                                        </div>
                                        <div  style={close} onClick={() => {handleDelete(i)}}>
                                            <button type="button" class="btn-close float-end" style={{color:"red"}}>X</button>
                                        </div>
                                    </>
                                );
                            })}
                                <div className="col-md-2 mt-2">
                                    <button className="btn btn-primary1 w-100" data-bs-toggle="modal" data-bs-target="#Intangible"onClick={() => {handleNew()}} >+</button>
                                </div>
                            </div>
                        </div>
                        <input type="button" className="btn btn-primary1" value="Submit" onClick={() => {handleSave()}}/>
                        {/* <input type="button" className="btn btn-defult" value="Close" onClick={handleClose} /> */}
                    </div>
                </Box>
                </>
               
            </Modal>

           
               <InvestAdjustModal
                    handleClose={handleClose}
                        entryopen={entryopen} 
                        eqityshare={eqityshare}
                        otherequityshare={otherequityshare} 
                        investvalue={investvalue}
                        totaleqity={totaleqity}
                        interest={interest}
                        setfinalsum={setfinalsum}
                        setFinalConsoleEntry={setFinalConsoleEntry}
                        otherreservesurplus={otherreservesurplus}
                        othereserve={othereserve}
                        setEntryOpen={setEntryOpen}
                        dateofinvest={dateofinvest}
                />

           
            <Modal
                open={browseOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <div className="col-12">
                        {/* <button type="button" onClick={handleClose} class="btn-close float-end"></button> */}
                        <div className="col-md-12 form-group">
                            <label>Browse control date FS</label>
                            <select className='form-control mt-2' onChange={(e) => handleChangeFs(e)} value={selectedOption}>
                                <option>select</option>
                                {(optionentry.length >  0  && optionentry.map((item=>(
                                    <option key={item._id} value={item._id}>{item.project_name}</option>
                                ))))};
                            </select>
                        </div>
                        <input type="button" className="btn btn-primary float-end" value="Submit" onClick={handleSubmitFs}/>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={newDetails}
                onClose={handleCloseData}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                    <Box sx={style4}>
                        <div className="col-12">
                            <h5>Add New Data</h5>
                            <form>
                                <div className="mb-3">
                                    <input type="text" className="form-control" placeholder="" onChange={(e)=>newData(e)}/>
                                </div>
                                <input type="button" className="btn btn-primary1" value="Submit" onClick={saveData}/>
                                <input type="button" className="btn btn-defult" value="Close" onClick={handleCloseData} />
                            </form>
                        </div>
                    </Box>
            </Modal>
        </div>
    )
}

export default AddInvestment
