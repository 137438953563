import React, { useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getBCTbRows, getDeletedLedgersRows, setIsDeletedLedgerModal } from '../../../redux/actions/BookClosure/actionBookClosure';
import { useParams } from 'react-router';
import { restoreLedger } from '../../Services/ApiHandler';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

export default function DeletedLedgerModal() {
    const dispatch = useDispatch();
    const { bookClosureTbId } = useParams();

    const {
        isDeletedLedgerModalOpen,
        dLedgerLoading,
        dLedgerErrorMessage,
        deletedLedgerItems,
    } = useSelector((initialState) => initialState.BookClosureTbcReducer);

    const handleClose = () => {
        dispatch(setIsDeletedLedgerModal(false));
    }

    const RestoreItem = (ledgerID) => {
        restoreLedger(bookClosureTbId, {
            ledgerID
        }).then(response => {
            toast.success("Ledger Restore Successfully.");
            dispatch(getDeletedLedgersRows(bookClosureTbId));
            dispatch(getBCTbRows(bookClosureTbId));
        }).catch(error => {
            swal("Error", error?.response?.data?.error || "Deleted Ledger Failed", "error");
        })
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={isDeletedLedgerModalOpen}
                onClose={handleClose}
            >
                <DialogTitle className='d-flex justify-content-between' >
                    <p className='p-0 m-0'> Deleted Ledger Items </p>

                    <div>
                        {/* <button className='ml-2 border-0 bg-color-inherit custom-common-theme-text-color' > Restore </button> */}
                        <button className='ml-2 border-0 bg-color-inherit text-danger ' onClick={handleClose} > Close </button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        {
                            dLedgerErrorMessage ?
                                <p className='text-danger' > {dLedgerErrorMessage} </p> :
                                dLedgerLoading ? <p>Loading.....</p> :
                                    deletedLedgerItems.length > 0 ?
                                        (
                                            <>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>LEDGER CODE</th>
                                                            <th>LEDGER NAME</th>
                                                            <th>Period Beginning Balance</th>
                                                            <th>Period Ending Balance</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            deletedLedgerItems.map(ledger => (
                                                                <tr key={ledger._id} >
                                                                    <td>{ledger?.ledgerCode}</td>
                                                                    <td>{ledger?.ledgerName}</td>
                                                                    <td>{ledger?.periodBeginingBalance}</td>
                                                                    <td>{ledger?.periodEndingBalance}</td>
                                                                    <td>
                                                                        <button
                                                                            className='border-0 custom-common-theme-text-color bg-color-inherit font-size-14'
                                                                            onClick={() => RestoreItem(ledger._id)}
                                                                        >
                                                                            Restore
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr></tr>
                                                    </tbody>
                                                </table>
                                            </>
                                        )
                                        :
                                        <p> Deleted Ledger Items Not Found </p>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}