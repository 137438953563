import React from 'react';
import { Modal, Button } from '@material-ui/core';
import Box from '@mui/material/Box';

const guideData = ["Upload TB", "Map Columns", "Confirm grouping"]

const styleModal = {
    position: 'absolute',
    top: '39%',
    left: '89%',
    transform: 'translate(-50%, -50%)',
    width: 200,
    bgcolor: 'background.paper',
    border: 'none ',
    borderRadius: 2,
    p: 2,
};

export default function Guide({
    isGuidTb,
    setIsGuidTb
}) {
    const handleClose = () => setIsGuidTb(false);
    return (
        <>
            <Modal
                open={isGuidTb}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <p className='mt-0 mb-0 font-size-18 font-sans'>Upload Guide</p>
                    {
                        guideData.map((name, index) => (
                            <div className='d-flex align-items-center mt-3'>
                                <div className='d-flex justify-content-center align-items-center rounded-circle custom-common-theme-bg-color font-sans' style={{ width: '21px', height: '21px' }}>
                                    <span className='font-size-12 custom-font-500 text-white font-sans'>{index + 1}</span>
                                </div>
                                <p className='ml-2 mb-0 mt-0 font-size-16 custom-font-500 font-sans'>{name}</p>
                            </div>
                        ))
                    }
                    <Button
                        className='w-100 mt-3 custom-common-theme-bg-color text-white text-capitalize font-sans'
                        onClick={handleClose}
                    >
                        Okay
                    </Button>
                </Box>
            </Modal>
        </>
    );
}
