import { GetAdjustment, DeleteAdjustment } from "../../Console/Component/services/apiHandler";

export const fetchAdjustment = (aid) => {
    return dispatch => {
        dispatch({type:"ADJ_ACTION_START"})
        GetAdjustment(aid).then((result)=>{
            dispatch({
                type: "ADJ_FETCH_DATA",
                payload: {data: result.data, aid: aid}
            }) 
        }).catch((error)=>{
            dispatch({
                type: "ADJ_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}

export const AddAdjustment = (data) => {
    console.log("action", data)
    return dispatch => {
        dispatch({type:"ADJ_ACTION_START"})
        dispatch({type:"ADJ_ADD_DATA", payload: data})
    }
}

export const editAdjustment = (aid, data) => {
    return dispatch => {
        dispatch({type:"ADJ_ACTION_START"})
        dispatch({type:"ADJ_UPDATE_DATA", payload: {aid: aid, data: data}})
    }
}

export const delAdjustment = (data) => {
    console.log("action", data)
    return dispatch => {
        dispatch({type:"ADJ_ACTION_START"})
        dispatch({type:"ADJ_DELETE_DATA", payload: data})
    }
}

