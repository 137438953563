import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setAddedLedgers, setIsAddToTackModalOpen } from '../../redux/actions/BookClosure/actionBookClosure';

export default function AddLedgerToTaskModal({
    AddLedgerToTask
}) {
    const dispatch = useDispatch();
    const {
        isAddToTaskModalOpen,
        ledgers,
        addedLedgers
    } = useSelector((state) => state.BookClosureReducer);

    const handleClose = () => {
        dispatch(setIsAddToTackModalOpen(false))
    };

    const [selectedLedger, setSelectedLedger] = React.useState("");

    return (
        <React.Fragment>
            <Dialog
                open={isAddToTaskModalOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add Ledger To Task"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ width: "25rem" }}>
                        Select Ledger
                    </DialogContentText>
                    <div>
                        {
                            ledgers?.length > 0 ?
                                <select
                                    className='w-100 px-3 rounded py-2 text-secondary mt-2'
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        setSelectedLedger(value)
                                        const findElement = ledgers.find(item => item?._id === value);
                                        console.log("findElement", findElement)
                                        if (findElement) {
                                            dispatch(setAddedLedgers([...addedLedgers, findElement]));
                                        }
                                    }}
                                    value={selectedLedger}
                                >
                                    <option>Select Ledger</option>
                                    {
                                        ledgers?.map(ledger => (
                                            <option key={ledger?._id} value={ledger?._id} >{ledger?.ledgerName}</option>
                                        ))
                                    }
                                </select>
                                : <p> No Ledgers Found </p>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className='' color='error' onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => AddLedgerToTask(selectedLedger)}>Add Ledger</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
