import { getAutoPopulationData } from "../../Console/Component/services/apiHandler";

export const FetchAutoPopulationData = (additional_preset_id) => {
    return dispatch => {
        dispatch({type:"APA_ACTION_START"})
        getAutoPopulationData(additional_preset_id).then((result)=>{
            dispatch({
                type: "APA_FETCH_DATA",
                payload: {data: result.data.data, aid: additional_preset_id}
            })
        }).catch((err)=>{
            dispatch({
                type:"APA_FETCH_DATA_FAILED",
                payload: err.response.data.error
            })
        })        
    }
}

export const AddUpdateAutoPopulation = (data) => {
    return dispatch => {
        dispatch({type:"ADJ_ACTION_START"})
        dispatch({type:"APA_ADD_UPDATE_DATA", payload: data})
    }
}