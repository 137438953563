const BSTypes = {
    ADJ_ACTION_START:"ADJ_ACTION_START",
    ADJ_FETCH_DATA:"ADJ_FETCH_DATA",
    ADJ_FETCH_DATA_FAILED:"ADJ_FETCH_DATA_FAILED",
    ADJ_ADD_DATA:"ADJ_ADD_DATA",
    ADJ_DELETE_DATA:"ADJ_DELETE_DATA",
    ADJ_UPDATE_DATA:"ADJ_UPDATE_DATA",
}

const Initial_state = {
    loading: false,
    error_message: null,
    isSuccess: false,
    response: "",
    adjustment_id: ""
}

const ADJReducer = (state=Initial_state, action) => {
    switch(action.type){
        case BSTypes.ADJ_ACTION_START:
            return {
                ...state,
                loading: true,
            }

        case BSTypes.ADJ_FETCH_DATA: 
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: action.payload.data,
                adjustment_id: action.payload.aid
            }

        case BSTypes.ADJ_FETCH_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: action.payload
            }

        case BSTypes.ADJ_ADD_DATA:
            let newData = state.response;
            newData.getConsoleAdjustment.push(action.payload);
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: newData
            }

        case BSTypes.ADJ_UPDATE_DATA:
            let updateData = state.response;
            let index = updateData.getConsoleAdjustment.findIndex(data=>data._id === action.payload.aid);
            if(index === -1)return;
            updateData.getConsoleAdjustment[index] = action.payload.data;
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: updateData
            }

        case BSTypes.ADJ_DELETE_DATA:
            return {
                ...state,
                loading: false,
                isSuccess: true,
                response: {...state.response, getConsoleAdjustment:state.response.getConsoleAdjustment.filter(data=>data._id !== action.payload)}
            }

        default:
            return state;
    }
};

export default ADJReducer;