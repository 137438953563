import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from "@mui/icons-material/Add";

export default function AddOtherDetailsModal({
    isOtherInputOpen,
    setIsOtherInputOpen,
    allOtherDetailsInputs,
    allotherdata,
    handleChangeDetails,
    handleNew,
    handleDelete,
    handleSave
}) {

    const handleClose = () => {
        setIsOtherInputOpen(false);
    };
    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={isOtherInputOpen}
                onClose={handleClose}
            >
                <DialogTitle>Add Other Details</DialogTitle>
                <DialogContent>
                    {allOtherDetailsInputs&&allOtherDetailsInputs.length>0&&allOtherDetailsInputs.map((val, i) =>
                    (
                        <div key={i} className='d-flex align-items-center'>
                            <select
                                className='border'
                                name="select"
                                value={val.select}
                                onChange={(e) => {handleChangeDetails(e, "select", i)}}
                            >
                                <option>Select</option>
                                {allotherdata&&allotherdata.length>0 &&allotherdata?.map((data, index) => (
                                    <option key={index} value={data.value}>
                                        {data.label}
                                    </option>
                                ))}
                                <option value="Add_new">Add New</option>
                            </select>
                            <div className="col-md-4">
                                <input
                                    type="text"
                                    name="value"
                                    className="form-control"
                                    value={val.value}
                                    onChange={(e) => {handleChangeDetails(e, "value", i)}}
                                />
                            </div>
                            <div
                                onClick={() => {
                                    handleDelete(i);
                                }}
                            >
                                <button
                                    type="button"
                                    class="btn-close float-end"
                                    style={{ color: "red" }}
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="col-md-2 mt-2">
                        <AddIcon
                            onClick={handleNew}
                            data-bs-toggle="modal"
                            data-bs-target="#Intangible"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className="bg-white  shadow rounded custom-border custom-common-theme-text-color text-capitalize w-20"
                    >
                        Close
                    </Button>
                    <Button
                     className="text-white shadow rounded custom-border custom-common-theme-bg-color text-capitalize w-20 mr-3"
                     onClick={handleSave}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
