import React from 'react'

const LoaderTwo = () => {
    return (
        <>
            <div className="spinner">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </>
    )
}

export default LoaderTwo