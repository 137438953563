const CashflowTypes = {
    CASHFLOW_ADJUSTMENT_DATA:"CASHFLOW_ADJUSTMENT_DATA",
    CASHFLOW_FV_RAW_DATA:"CASHFLOW_FV_RAW_DATA",
}

const Initial_state = {
    cashflowAdjustmentData: {},
    fcRawData: [],
}

const CashflowReducer = (state=Initial_state, action) => {
    switch(action.type){
        case CashflowTypes.CASHFLOW_ADJUSTMENT_DATA: 
            return {
                ...state,
                cashflowAdjustmentData: action.payload
            }
        case CashflowTypes.CASHFLOW_FV_RAW_DATA: 
            return {
                ...state,
                fcRawData: action.payload
            }

        default:
            return state;
    }
};

export default CashflowReducer;