import React from 'react'
const CaseFlowTableRow = ({ data, index }) => {
    

    return (
        <>
            {data?.title &&
                <tr>
                    <td className='font-weight-bold'>{data?.title}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            }
            {data?.header && 
                <tr>
                    <td className='font-weight-bold'>{data?.header}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            }
            {
                data?.data && data.data.length > 0 && data.data.map((ele, e) => (
                    <tr key={e}>
                        <td>{ele?.particular}</td>
                        <td>{ele?.note}</td>
                        <td>{ele?.cy}</td>
                        <td>{ele?.py}</td>
                    </tr>
                ))
            }
            {/* {[1, 2, 4, 5, 7].includes(index) &&
                <tr>
                    <td>
                        <button className='my-2 rounded deleteBtn' style={{ border: "none", backgroundColor: "white" }}
                            onClick={() => { }}
                        >
                            <div className='px-3 py-1' style={{ color: "#03565a", fontWeight: "600" }}>Create New Input +</div>
                        </button>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            } */}
            {data?.total &&
                <tr>
                    <td className='font-weight-bold'>{data?.total?.name}</td>
                    <td></td>
                    <td>{data?.total?.cy}</td>
                    <td>{data?.total?.py}</td>
                </tr>
            }
        </>
    )
}

export default CaseFlowTableRow