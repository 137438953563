const InitialState = {
  loading: false,
  response: [
    "Equity share capital",
    "share",
    "Instruments entirely equity in nature",
    "Share application money pending allotment",
    "Equity component of compound financial instruments",
    "Reserves and surplus - Capital Reserve",
    "Reserves and surplus - Securities Premium Reserve",
    "Reserves and surplus - Other reserves",
    "Reserves and surplus - Retained earnings",
    "Debt instruments through Other Comprehensive Income",
    "Equity instruments through Other Comprehensive Income",
    "Effective portion of Cash Flow Hedges",
    "Revaluation Surplus",
    "Non-controlling interest",
    "Exchange differences on translating the financial statements of a foreign operation",
    "Money received against share warrant",
    "Attributable to owners of the parent"
  ],
  otherResponse: [
    {"sub_group":"Goodwill","cal_data":"goodwill"},
    {"sub_group":"Non-controlling interests","cal_data":"non_controlling_interest"},
    {"sub_group":"Investments in Equity Instruments - Unquoted","cal_data":"investment"},
    {"sub_group":"Brands/trademarks","cal_data":"otherinputs"},
  ],
  autoPopulate: [
    {
        "group": "goodwill",
        "ledger_name": "Goodwill",
        "fs_grp":"Non Current Assets"
    },
    {
        "group": "otherinputs",
        "ledger_name": "Brands/trademarks",
        "fs_grp":"Non Current Assets"
    },
    {
        "group": "Investment",
        "ledger_name": "Investments in Equity Instruments - Unquoted",
        "fs_grp":"Current Assets"
    },
    {
        "group": "non_controlling_interest",
        "ledger_name": "Non-controlling interests",
        "fs_grp":"Equity"
    }
]

};

const PADJReducer = (state=InitialState) => {
    return state;
};

export default PADJReducer;