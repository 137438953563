import axios from 'axios';
const auth = localStorage.getItem("auth_token");

const headers = {
    "x-auth-token": auth,
};

export const getOneBookClosure = async (id, data) => {
    return axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosure/get?id=${id}`, data);
}
export const createBookClosure = async (data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosure/create`, data);
}
export const addCompany = async (id, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosure/addCompany?id=${id}`, data);
}
export const deleteBookClosure = async (id) => {
    return await axios.delete(`${process.env.REACT_APP_FSCP_URL}bookClosure/delete?id=${id}`);
}

export const getBookClosureByCompanyIds = async (companyIds) => {
    return await axios.get(`api/v1/workspace/get-book-closure-company?companyIds=${companyIds}`, { headers })
}
export const getApproverUserOptions = async (companyId) => {
    return await axios.get(`api/v1/workspace/get-book-closure-user?companyId=${companyId}`, { headers })
}

export const addTask = async (data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/add`, data);
}
export const updateTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/update?id=${taskId}`, data);
}
export const getOneTask = async (taskId) => {
    return axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/get?id=${taskId}`);
}
export const deleteTask = async (taskId) => {
    return await axios.delete(`${process.env.REACT_APP_FSCP_URL}bookClosure/delete?id=${taskId}`);
}

export const getLibraryList = async (bookClosureId, companyID) => {
    return axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosureLibrary/get?id=${bookClosureId}&companyID=${companyID}`);
}
export const importTasksFromLibrary = async (bookClosureId, data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/import?id=${bookClosureId}`, data);
}
export const addTaskInLibrary = async (bookClosureId, data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosureLibrary/add`, data);
}
export const updateTaskInLibrary = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureLibrary/update?id=${taskId}`, data);
}
export const deleteTaskInLibrary = async (taskId) => {
    return await axios.delete(`${process.env.REACT_APP_FSCP_URL}bookClosureLibrary/delete?id=${taskId}`);
}

// ? Submit task, when the preparer wants to submit an "open" or "returned" task.
export const submitTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/submit?id=${taskId}`, data);
}
// ? Save and submit task, when the preparer wants to save and submit a new task.
export const saveAndSubmitTask = async (data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/saveAndSubmit`, data);
}
// ? Approve task, when approver wants to approve "submitted" task.
export const approveTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/approve?id=${taskId}`, data);
}
// ? Return task, when approver wants to return "submitted" task.
export const returnTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/return?id=${taskId}`, data);
}
export const uploadTB = async (data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/upload`, data)
}
export const getTbHeaders = async (bookClosureTbId) => {
    return await axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/getTBHeaders?id=${bookClosureTbId}`)
}
export const setTbHeaders = async (bookClosureTbId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/setTBHeaders?id=${bookClosureTbId}`, data)
}
export const getTbRows = async (bookClosureTbId) => {
    return await axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/getTBRows?id=${bookClosureTbId}`)
}
export const getTbDetails = async (bookClosureTbId) => {
    return await axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/getTBDetails?id=${bookClosureTbId}`)
}
export const addTBNote = async (bookClosureTbId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/addNote?id=${bookClosureTbId}`, data)
}
export const softDeleteLedger = async (bookClosureTbId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/deleteLedger?id=${bookClosureTbId}`, data)
}
export const restoreLedger = async (bookClosureTbId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/restoreLedger?id=${bookClosureTbId}`, data)
}
export const mapLedger = async (bookClosureTbId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/mapLedger?id=${bookClosureTbId}`, data)
}
export const getDeletedLedgers = async (bookClosureTbId) => {
    return await axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosureTB/getDeletedLedgers?id=${bookClosureTbId}`)
}

export const addLedgerToTask = async (data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/addLedgerToTask`, data)
}
export const updateLedgerInTask = async (data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/updateLedgerIntask`, data)
}
export const AddAdjustmentToTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/addAdjustmentToTask?id=${taskId}`, data, { headers })
}
export const updateAdjustmentToTask = async (taskId, adjustmentID, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/updateAdjustmentInTask?id=${taskId}&adjustmentID=${adjustmentID}`, data, { headers })
}
export const deleteAdjustmentToTask = async (taskId, adjustmentID, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/deleteAdjustmentFromTask?id=${taskId}&adjustmentID=${adjustmentID}`, data, { headers })
}

export const getEntries = async (bookClosureId, companyId) => {
    return await axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosure/getEntries?id=${bookClosureId}&companyID=${companyId}`)
}
export const getEntriesNew = async (bookClosureId, companyId, format) => {
    const baseUrl = `${process.env.REACT_APP_FSCP_URL}bookClosure/getEntriesNew?id=${bookClosureId}&companyID=${companyId}`;
    const url = format ? `${baseUrl}&format=${format}` : baseUrl;

    return await axios.get(url, { headers });
};

export const getEntriesExport = async (bookClosureId, companyId, format) => {
    const url = `${process.env.REACT_APP_FSCP_URL}bookClosure/getEntriesNew?id=${bookClosureId}&companyID=${companyId}&format=excel`;

    return await axios.get(url, {
        headers: {
            "x-auth-token": auth,
        },
        responseType: 'blob'
    });
};

export const getMatrix = async (bookClosureId, companyId) => {
    return await axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosure/getMatrix?id=${bookClosureId}&companyID=${companyId}`)
}

export const addCommentToTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/addCommentToTask?id=${taskId}`, data, { headers })
}
export const deleteCommentInTask = async (taskId, commentID) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/deleteCommentFromTask?id=${taskId}&commentID=${commentID}`, { headers })
}
// ! Presets
export const getAllPreset = async (page) => {
    return await axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosurePreset/all?page=${page}&limit=${20}&sortBy=createdAt&sort=-1`, { headers })
}
export const savePreset = async (data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosurePreset/save`, data, { headers })
}
export const importTaskFormPreset = async (data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosurePreset/import`, data, { headers })
}