import React, { useState } from "react";
import { Navbar } from "../../Components/Navbar";
import "./support.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import { Card } from "react-bootstrap";
import backgroungimage from "../../../src/assets/backgroungimg.png"
import SupportNav from "./SupportNav";
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SupportTicketView from "./SupportTicketView";
import useBackNavigation from "./CustomBackhook";




export default function SupportVpage() {

    const [anchorEl, setAnchorEl] = useState(null);
    const [data,setData] = useState(false)

    const history = useHistory();

    const handleNavigate = () => {
        history.push("/support-contact");
    };

    const handleBack = useBackNavigation('/dashboard');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (menuItem) => {
        console.log(`Clicked on ${menuItem}`);
        handleClose();
    };

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const myStyle = {
        backgroundImage: `url(${backgroungimage})`,
        height: "200px",
        width: "600px",
        fontSize: "50px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "20px",
        marginTop: "50px"
    };

    return (
        <>

           
            <>
            <SupportNav handleBack={handleBack}/>
            <div className="d-flex justify-content-center mt-4" style={{ marginBottom: "50px" }}>

                <div className="mt-4">

                    <div className="d-flex justify-content-between" >

                        <div>
                            <p className="font-sans custom-font-700 font-size-20 text-dark">FAQ</p>
                        </div>

                        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                            <span className="font-sans custom-font-700 font-size-14 custom-font-color">
                                Select Module:
                            </span>
                            <Box sx={{ display: 'flex', width: "160px",height:"40px", justifyContent: "center", alignItems: 'center', border: '1px solid #D6D6D6', borderRadius: '10px', padding: '4px 8px' }}>

                                <FormControl sx={{ minWidth: 150 }} size="small">
                                 
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        IconComponent={ExpandMoreIcon}
                                        sx={{
                                            '.MuiSelect-icon': {
                                                right: 0,
                                                backgroundColor: '#D6D6D6',
                                                borderRadius: "3px",
                                                width: "24px",
                                                height: "24px",

                                            },
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="">
                                            <span>none</span>
                                        </MenuItem>
                                        
                                    </Select>

                                </FormControl> 
                            </Box>
                        </div>
                    </div>


                    <div>
                        <div className="mt-4">
                            <Accordion style={{ width: "600px", borderRadius: "10px" }} className="no-shadow">
                                <AccordionSummary
                                    className=" font-inter custom-font-500 font-size-16 text-dark "
                                    expandIcon={
                                        <ExpandMoreIcon
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "48px",
                                                backgroundColor: "#D6D6D6",
                                                color: "#717171",
                                            }}
                                        />
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    What if the product doesn’t work?
                                </AccordionSummary>
                                <hr />
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                        eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>

                        <div className="mt-3">
                            <Accordion style={{ width: "600px", borderRadius: "10px" }} className="no-shadow">
                                <AccordionSummary
                                    className=" font-inter custom-font-500 font-size-16 text-dark "
                                    expandIcon={
                                        <ExpandMoreIcon
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "48px",
                                                backgroundColor: "#D6D6D6",
                                                color: "#717171",
                                            }}
                                        />
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    How to add Team member?
                                </AccordionSummary>
                                <hr />
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                        eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>

                        <div className="mt-3" >
                            <Accordion style={{ width: "600px", borderRadius: "10px" }} className="no-shadow">
                                <AccordionSummary
                                    className=" font-inter custom-font-500 font-size-16 text-dark "
                                    expandIcon={
                                        <ExpandMoreIcon
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "48px",
                                                backgroundColor: "#D6D6D6",
                                                color: "#717171",
                                            }}
                                        />
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Can my team member delete my admin access?
                                </AccordionSummary>
                                <hr />
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                        eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>

                    <div style={myStyle}>

                        <div className="m-0" >
                            <p className="font-sans custom-font-700  font-size-24 text-white text-center m-2 p-2 ">Can’t find you’re looking for?</p>
                            <p className=" text-center font-sans custom-font-500 font-size-16  m-0" style={{ color: "#D6D6D6" }}>Few lines to describe about contact us, like how fast <br /> will i get the reply from support etc..</p>
                            <div className="text-center m-0">
                                <button style={{ width: "120px", height: "50px", }} className="font-size-16 custom-common-theme-text-color custom-font-700 radius-6px border-0" onClick={handleNavigate}>Contact Us</button>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            </>
            
           
            




        </>
    );
}
