import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getBCTbRows, setIsAddSubGroupModal } from '../../../redux/actions/BookClosure/actionBookClosure';
import { addTBNote } from '../../Services/ApiHandler';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

export default function AddSubGroupModal({
    requirements,
    setRequirements
}) {
    const dispatch = useDispatch();
    const { bookClosureTbId } = useParams();

    const {
        isAddSubGroupModalOpen
    } = useSelector((initialState) => initialState.BookClosureTbcReducer);

    const [subGroupName, setSubGroupName] = useState("");


    const handleClose = () => {
        dispatch(setIsAddSubGroupModal(false));
        setRequirements();
        setSubGroupName("");
    };

    const AddSubGroup = () => {
        const data = {
            level: requirements?.level,
            levelOneName: requirements?.levelOneName,
            name: subGroupName
        }

        addTBNote(bookClosureTbId, data).then(response => {
            dispatch(getBCTbRows(bookClosureTbId));
            toast.success("Note Added Successfully");
            handleClose();
        }).catch(error => {
            console.error("error", error)
            swal("Error", error?.response?.data?.error || "New Note Data Added Failed", "error")
        })
    }

    return (
        <React.Fragment>
            <Dialog
                open={isAddSubGroupModalOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add Sub Group"}
                </DialogTitle>
                <DialogContent>
                    <div style={{ width: "24rem" }} >
                        <div>
                            <p className='m-0' >Sub Group Name</p>
                            <input
                                className='w-100 rounded px-3 py-2 mt-1'
                                placeholder='Enter Your Sub Group NAme'
                                value={subGroupName}
                                name='name'
                                onChange={(e) => {
                                    setSubGroupName(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='error' className='text-danger' >Close</Button>
                    <Button onClick={() => {
                        AddSubGroup();
                    }} autoFocus>
                        Add  +
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
