import { useEffect, useState } from "react";
import {
  CreateAdjustment,
  GetAdjustment,
  getSubGrpFs,
} from "../../Component/services/apiHandler";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TableHeaderAdjustment from "./adjustmentTableHeader";
import { useLocation, useParams } from "react-router";
import AdjustmentData from "./adjustmentData";
import AdjustmentTab from "./adjustmentTab";
import AutoPopulate from "./adjustmentAutoPopulate";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import { fetchAdjustment, AddAdjustment } from "../../../redux/actions/actionAdjustment";
import { fetchSubGroups } from "../../../redux/actions/actionSG";

const AdjustmentConsole = (props) => {
  const { currentCompany, subsiaryData, groupingData, notes, id } = props;
  const dispatch = useDispatch();

  // const [allsubdata, setAllSubData] = useState([]);
  const allsubdata = useSelector((state) => state.SGReducer);
  const [hideNewMOdal, setNewModalHide] = useState("");
  // const [adjustmentData, setAdjustmentData] = useState([]);
  const adjustmentData = useSelector((state) => state.ADJReducer);
  const [dataRowOpen, setDataRowOpen] = useState(false);

  const [adjCompanyData, setAdjCompanyData] = useState([
    {
      company_id: "",
      notes_no: "",
      fs_grp: "",
      ledger_name: null,
      adjustment_value: "",
      scenario: "",
    }
  ]);
  const [adjustment, setAdjustment] = useState({
    adjustment_date: "",
    remark: "",
  });
  const heading = {
    color: "var(--clr-accent)",
    fontWeight: "700",
  };

  useEffect(() => {
    if (!adjustmentData.isSuccess || adjustmentData.adjustment_id !== currentCompany._id) {
      dispatch(fetchAdjustment(currentCompany._id));
    }
    if (!allsubdata.isSuccess || allsubdata.project_id !== currentCompany.upload_preset) {
      dispatch(fetchSubGroups(currentCompany.upload_preset));
    }
  }, [])

  const handleChange = (field, e) => {
    try {

      setAdjustment({ ...adjustment, [field]: e.target.value })
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleChangeDropDown = (field, value, index) => {
    try {
      if (value.notes_no === "35" || value.notes_no === "36" || value.notes_no === "38") {
        setDataRowOpen(true)
      }
      const temp = {
        company_id: adjCompanyData[0].company_id,
        notes_no: "",
        fs_grp: "",
        ledger_name: null,
        adjustment_value: "",
        scenario: adjCompanyData[0].scenario,
      }
      if (field === "ledger_name") {
        adjCompanyData[index][field] = value.sub_grp;
        adjCompanyData[index]["notes_no"] = value.notes_no;
        adjCompanyData[index]["fs_grp"] = value.fs_grp;
        setAdjCompanyData(adjCompanyData);
        if (adjCompanyData[index + 1] === undefined) {
          setAdjCompanyData((prevData) => [...prevData, temp]);
        }
      }
      else if (field === "company_id") {
        setAdjCompanyData(adjCompanyData.map((item) => { return { ...item, company_id: value } }));
      }
      else if (field === "scenario") {
        setAdjCompanyData(adjCompanyData.map((item) => { return { ...item, scenario: value } }));
      }
      else {
        adjCompanyData[index][field] = value;
        setAdjCompanyData(adjCompanyData);
      }
    } catch (err) {
      console.log("err", err.message)
    }
  };

  const handleSave = async (scenario) => {
    const otherData = adjCompanyData.splice(-1);
    const checkAdjustment = adjCompanyData.map((adjData, n) => adjData.adjustment_value)
    const checkSum = checkAdjustment.reduce((group, acc) => Number(group) + Number(acc), 0);
    try {
      if (checkSum === 0) {
        const data = {
          additional_preset_id: currentCompany._id,
          adjustment_date: adjustment.adjustment_date,
          adjustment_other_data: adjCompanyData,
          remark: adjustment.remark,
          status: scenario
        }
        const result = await CreateAdjustment(data);
        if (result.data.status === true) {
          setNewModalHide(false);
          swal("", "Data Saved", "success");
          setAdjCompanyData([{
            company_id: "",
            notes_no: "",
            fs_grp: "",
            ledger_name: null,
            adjustment_value: "",
            scenario: "",
          }])
          dispatch(AddAdjustment(result.data.data));
        } else {
          console.log("failed");
        }
      } else {
        swal("", "Adjustments Value sum should be 0", "warning");
      }

    } catch (error) {
      console.log(error);
      return 0
    }
  };
  function handleDelete(i) {
    const temp = adjCompanyData;
    const newarr = temp.filter((val, ix) => ix !== i);
    setAdjCompanyData(newarr);
    if (newarr.length == 0) {
    }
  }

  const setAdjustmentData = (data) => {
    console.log(data)
  }
  return (

    <>
      <div class="row">
        <div
          class="col-md-12"
          style={{ overflow: "auto", whiteSpace: "nowrap" }}
        >
          <div style={{ marginLeft: "1rem" }}>
            <p style={heading}>Manual Entry</p>
          </div>
          <AdjustmentTab
            adjustmentData={[...[], adjustmentData.response.getConsoleAdjustment]}
            allsubdata={allsubdata.adjResponse}
            handleSave={handleSave}
            adjustment={adjustment}
            handleChange={handleChange}
            dataRowOpen={dataRowOpen}
            currentCompany={currentCompany}
            adjCompanyData={adjCompanyData}
            handleDelete={handleDelete}
            subsiaryData={subsiaryData}
            handleChangeDropDown={handleChangeDropDown}
            hideNewMOdal={hideNewMOdal}
            setAdjustmentData={setAdjustmentData}
            groupingData={groupingData}
            notes={notes}
            id={id}
          />
        </div>
      </div>
    </>
  );
};

export default AdjustmentConsole;
